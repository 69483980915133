import { Component, EventEmitter, Input, OnInit, Output, ViewChild, NgModule } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { ProgramService } from 'src/app/services/program.service';
import { PromotionConfigService } from 'src/app/services/promotion-config.service';
import { PromotionService } from 'src/app/services/promotion.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { ActivatedRoute } from '@angular/router';
import { PromotionTemplate } from 'src/app/data/model';
import { Promotion } from 'src/app/data/model';
import * as $ from 'jquery';
import {Observable, Subject} from 'rxjs';
import { RegexSearchModalComponent } from 'src/app/regex-search-modal/regex-search-modal.component';
import { fromEvent, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-gatekeeper-threshold',
  templateUrl: './gatekeeper-threshold.component.html',
  styleUrls: ['./gatekeeper-threshold.component.scss']
})
export class GatekeeperThresholdComponent implements OnInit {
    @Input() gatekeepers: any;
    periods: any;
    inclusionValues: any;
    trueFalse: any;
    faTimes = faTimes;
    faPlus = faPlus;
    @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
    debouncer: Subject<string> = new Subject<string>();
    @ViewChild(RegexSearchModalComponent) regexSearchModalComponent: RegexSearchModalComponent;

  constructor(
    private router: ActivatedRoute,
    private formBuilder: FormBuilder,
    private formsModule: FormsModule,
    private modalHelper: NgbModal,
  ) {
      this.debouncer
        .pipe(debounce(() => interval(1000)))
        .subscribe((value) => this.inputChangeEmitter.emit(value));
    }

  ngOnInit(): void {
    this.periods = [
      {'value' : 'CAMPAIGN',     'text': 'CAMPAIGN'},
      {'value' : 'YEAR',     'text': 'YEAR'},
      {'value' : 'QUARTER',     'text': 'QUARTER'},
      {'value' : 'MONTH',    'text': 'MONTH'},
      {'value' : 'WEEK',     'text': 'WEEK'},
      {'value' : 'DAY',    'text': 'DAY'},
      { 'value' : 'PLANYEAR',     'text': 'PLANYEAR'},
      {'value' : 'CUSTOM',    'text': 'CUSTOM'}
    ];
  }

  regexSearchModal(searchFor: string, item: any, size: string, limitToOne: boolean, setValue: boolean, itemIndex: number): void {
    this.openRegexSearchModal(searchFor, item, size, limitToOne, setValue, itemIndex);
  }

  openRegexSearchModal(searchFor: string, item: any, size: string, limitToOne: boolean, setValue: boolean, itemIndex: number): void {
    const instance = this.modalHelper.open(RegexSearchModalComponent);
    instance.componentInstance.searchFor = searchFor;
    instance.componentInstance.item = item;
    instance.componentInstance.size = size;
    instance.componentInstance.limitToOne = limitToOne;
    instance.componentInstance.setValue = setValue;
    instance.componentInstance.itemIndex = itemIndex;
    instance.componentInstance.successEvent.subscribe((text: any) => {
      console.log(text);
      console.log(item);
      this.gatekeepers.gatekeeper[itemIndex][item.name] = text;
      this.updateThresholds();
      instance.close();
    });
  }

  initThresholdObject(): void {
    console.log(this.gatekeepers);
    if(!this.gatekeepers){
      this.gatekeepers = {class: "com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules$Thresholds$Gatekeepers" };
    }
    var gatekeeper = { class: "com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules$Thresholds$Gatekeepers$Gatekeeper" };
    if(this.gatekeepers.gatekeeper){
      this.gatekeepers.gatekeeper.push(gatekeeper);
    } else {
      this.gatekeepers.gatekeeper = [];
      this.gatekeepers.gatekeeper.push(gatekeeper);
    }
  }

  updateThresholds() {
    this.debouncer.next(this.gatekeepers);
  }

  removeThreshold(threshold) {
    this.gatekeepers.gatekeeper.splice(this.gatekeepers.gatekeeper.indexOf(threshold), 1);
    this.updateThresholds();
  }
}
