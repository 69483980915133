<form [formGroup]="commSegmentForm" novalidate>
    <div class="modal-header">
      <h1>Add communication to segments</h1>
    </div>
    <div class="modal-body">
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{errorMsg}}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="form-group">
        <label>Available segments:</label>
          <ng-select [items]="segments" bindLabel="code" bindValue="id" formControlName="segment"></ng-select>
          <div class="m-t-xs" [hidden]="commSegmentForm.controls['segment'].valid">
            <small class="text-danger">Select a valid segment</small>
          </div>
      </div>
      <div class="row">
        <div class="col-md-6">
          <div class="form-group">
            <div class="input-group date">
              <input class="form-control cursor-pointer" readonly="readonly" (click)="fromDate.toggle()" placeholder="From Date" name="fromDate" ngbDatepicker
                #fromDate="ngbDatepicker" formControlName="fromDate" [footerTemplate]="footerTemplateFromDate">
                <ng-template #footerTemplateFromDate>
                  <div class="width-100 datePickerTemplate">
                  <button class="buttonDateTemplate"  (click)="clearDateInput('fromDate'); fromDate.toggle()">Clear</button>
                  </div>
                </ng-template>
                
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="fromDate.toggle()" type="button">
                  <fa-icon [icon]="faCalendar"></fa-icon>
                </button>
              </div>
            </div>
            <div class="m-t-xs" [hidden]="commSegmentForm.controls['fromDate'].valid">
              <small class="text-danger">Select start date</small>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="form-group">
            <div class="input-group date">
              <input class="form-control cursor-pointer" readonly="readonly" (click)="toDate.toggle()"  placeholder="To Date" name="toDate" ngbDatepicker [footerTemplate]="footerTemplateToDate"
                #toDate="ngbDatepicker" formControlName="toDate">
                <ng-template #footerTemplateToDate>
                  <div class="width-100 datePickerTemplate">
                  <button class="buttonDateTemplate"  (click)="clearDateInput('toDate'); toDate.toggle()">Clear</button>
                  </div>
                </ng-template>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="toDate.toggle()" type="button">
                  <fa-icon [icon]="faCalendar"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-dark-blue" type="submit" [disabled]="!commSegmentForm.valid" (click)="ok()" title="Save">
          <fa-icon [icon]="faSave"></fa-icon>
        </button>
        <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
    </div>
</form>