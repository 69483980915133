import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormGroup, FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ReportsService } from '../services/reports.service';
import { HttpClient } from '@angular/common/http';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-parameter-modal',
  templateUrl: './parameter-modal.component.html',
  styleUrls: ['./parameter-modal.component.scss']
})
export class ParameterModalComponent implements OnInit {

  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public parameter: any = {};
  @Input() public additionalAttributes: any;
  @Input() public defaultValues: any;
  @Input() public autocomplete: string[];
  @Input() public  isSystem: boolean;
  faTimes = faTimes;
  parameterForm: UntypedFormGroup;
  errorMsg: string;
  autocompleteData: any[];
  selectedDataArray: any[];
  allDataArray: any[];
  reportId: number;
  clientParams: any;
  systemParams: any;
  disabledType: string;
  inputFilter = ['INTEGER', 'VARCHAR', 'FLOAT', 'NUMERIC'];
  paramTypes = ['INTEGER', 'VARCHAR', 'FLOAT', 'NUMERIC', 'BIGINT', 'TIMESTAMP', 'ARRAY INTEGER', 'ARRAY VARCHAR'];
  param: any = {systemParameter: {}};

  constructor(private formBuilder: FormBuilder,
              private reportsService: ReportsService,
              private http: HttpClient,
              private responseHelperService: ResponseHelperService,
              private modalHelper: NgbModal) { }


  ngOnInit(): void {
    this.param.systemParameter = this.isSystem;
    this.autocompleteData = this.additionalAttributes.autocompleteData;
    this.selectedDataArray = this.additionalAttributes.selectedDataArray;
    this.allDataArray = this.additionalAttributes.allDataArray;
    this.reportId = this.additionalAttributes.reportId;
    this.clientParams = this.additionalAttributes.clientParams;
    this.systemParams = this.additionalAttributes.systemParams;
    this.parameterForm = this.formBuilder.group({
      paramName: [''],
      paramCode: [''],
      paramSeqNum: [''],
      paramType: [''],
      autocompleteAction: [''],
      defaultValue: [],
      requiredParam: [false]
    });
  }

  ok(): void {
    const param = this.parameterForm.value;
    param.systemParameter = this.isSystem;
    if (!param.paramType) {
      param.paramType = this.disabledType;
    }
    if (param.paramType.startsWith('ARRAY')) {
      param.arrayElementType = param.paramType.split(' ').pop();
      param.paramType = 'ARRAY';
    }
    if (this.selectedDataArray[param.paramCode]) {
      param.defaultValue = '';
      if (param.arrayElementType === 'VARCHAR') {
        this.selectedDataArray[param.paramCode].forEach((elem: any) => {
          param.defaultValue += elem.text + ',';
        });
      }
      if (param.arrayElementType === 'INTEGER') {
        this.selectedDataArray[param.paramCode].forEach((elem: any) => {
          param.defaultValue += elem.id + ',';
        });
      }
    }
    this.reportsService.addParam(this.reportId, param).subscribe((response) => {
      this.successEvent.emit(response);
      this.responseHelperService.success('Report client parameter successfully created', true);
    }, (data: any) => {
      this.errorMsg = data.message;
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  getDataForParams(param: any): void {
    if ((param.paramType === 'BIGINT' || param.paramType === 'VARCHAR') && param.autocompleteAction !== null) {
      this.http.get('/cheetah/api' + param.autocompleteAction).subscribe((response: any) => {
        if (parseResponse(response.data)) {
          this.autocompleteData[param.paramCode] = parseResponse(response.data);
        } else {
          if (response.parseResponse(response.data)[0] && typeof response.parseResponse(response.data)[0] === 'string') {
            const autocompletePrepared = [];
            response.parseResponse(response.data).forEach((item: any) => {
              autocompletePrepared.push({
                name: item
              });
            });
            this.autocompleteData[param.paramCode] = autocompletePrepared;
          } else {
            this.autocompleteData[param.paramCode] = response.parseResponse(response.data);
          }
        }
      }, (response: any) => {
        console.log(response);
      });
    } else if (this.isArray(param) && param.autocompleteAction !== null) {
      this.reportsService.getReportArrayParamsData(param.autocompleteAction).subscribe((response: any) => {
        this.allDataArray[param.paramCode] = parseResponse(response);
       
        if (param.defaultValue) {
          const defaultArray = param.defaultValue.split(',').map((elem: any) => {
            return elem.trim();
          });
          this.selectedDataArray[param.paramCode] = [];
          this.allDataArray[param.paramCode].forEach((elem: any) => {
            if (defaultArray.indexOf(elem.id.toString()) !== -1 || defaultArray.indexOf(elem.text.toString()) !== -1) {
              this.selectedDataArray[param.paramCode].push(elem);
            }
          });
        }
      });
    }
  }

  isArray(param: any): boolean {
    return param.paramType && param.paramType.startsWith('ARRAY');
  }

  isInput(paramName: string): boolean {
    return this.inputFilter.indexOf(paramName) !== -1;
  }

  changedAuto(param: any): void {
    if (this.parameterForm.value.autocompleteAction) {
      this.parameterForm.get('paramType').disable();
    } else {
      this.parameterForm.get('paramType').enable();
    }
    this.disabledType = param.autocompleteAction === '/integrations/customers' ? 'VARCHAR' : 'BIGINT';
    param.paramType = this.disabledType;
  }
}
