<div class="wrapper wrapper-content" *ngIf="selectedPerson">
  <div class="ibox">
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="m-b-md">
            <a class="btn btn-dark-blue btn-sm float-right" (click)="edit(selectedPerson.id)" title="Edit">
              <fa-icon [icon]="faPencilAlt"></fa-icon>
            </a>
            <h2>{{selectedPerson.name}}</h2>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3">
          <dl class="dl-horizontal">
            <div>
              <dt>Status:</dt>
              <dd>
                <span class="badge" [ngClass]="statusHelper[selectedPerson.status]">{{selectedPerson.status}}</span>
              </dd>
            </div>
            <div>
              <dt>Username:</dt>
              <dd>{{selectedPerson.username}}</dd>
            </div>
            <div *ngIf="selectedPerson.primaryEmail">
              <div [hidden]="!selectedPerson.primaryEmail.emailAddress">
                <dt>Email:</dt>
                <dd>{{selectedPerson.primaryEmail.emailAddress}}</dd>
              </div>
            </div>
            <div [hidden]="!selectedPerson.yearStart">
              <dt>Year Start:</dt>
              <dd>{{selectedPerson.yearStart}}</dd>
            </div>
          </dl>
        </div>
        <div class="col-lg-9" *ngIf="selectedPerson.notes">
          <strong>Notes:</strong>
          <pre class="notes">
            <span class="notes float-left">{{selectedPerson.notes}}</span>
          </pre>
        </div>
      </div>
      <div class="tabs-container">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
          <li [ngbNavItem]="1">
            <a ngbNavLink>Sponsorship Groups</a>
            <ng-template ngbNavContent>
              <app-sponsorship-groups-tab [personId]="selectedPerson.id" [sponsorName]="selectedPerson.name"></app-sponsorship-groups-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>Promotions</a>
            <ng-template ngbNavContent>
              <app-promotions-tab [personId]="selectedPerson.id" [sponsorName]="selectedPerson.name"></app-promotions-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="3">
            <a ngbNavLink>Segments</a>
            <ng-template ngbNavContent>
              <app-segments-tab [personId]="personId" [halfSize]="true"></app-segments-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="4">
            <a ngbNavLink>Contacts</a>
            <ng-template ngbNavContent>
              <app-contacts-tab [personId]="personId"></app-contacts-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="5">
            <a ngbNavLink>Attributes</a>
            <ng-template ngbNavContent>
              <app-party-attributes-tab [personId]="personId"></app-party-attributes-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="6">
            <a ngbNavLink>Promotion Category</a>
            <ng-template ngbNavContent>
              <app-promotion-category-tab [sponsorId]="personId" [sponsor]="selectedPerson"></app-promotion-category-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="7">
            <a ngbNavLink>Campaigns</a>
            <ng-template ngbNavContent>
              <app-campaign-tab [personId]="personId"></app-campaign-tab>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</div>
