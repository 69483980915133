<div>
  <form>
    <label>The number of previous behaviors (rewarded or not rewarded):</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="behaviorCountCap !== null">
        <div *ngFor="let behCntCap of behaviorCountCap; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeCap(behCntCap)" title="Remove Threshold">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label>for the current:</label>
              <select name="period_{{behCntCap.capValue}}" class="form-control" [(ngModel)]="behCntCap.capPeriod" (ngModelChange)="updateCaps()">
                <option *ngFor="let period of periods" [selected]="behCntCap.capPeriod === period.value" [value]="period.value">{{period.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="thresholdVal_{{behCntCap.capValue}}">caps at (maximum number):</label>
              <input class="form-control m-b" type="number" id="thresholdVal_{{behCntCap.capValue}}" name="capValue" [(ngModel)]="behCntCap.capValue" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <a
                class="control-label"
                ng-class="{ 'disabled-link': readOnly }"
                (click)="
                  regexSearchModal(
                    'BEHAVIORS',
                    { object: behCntCap, name: 'behaviorCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                title="Search for Behaviors"
                >limited to Behaviors with Codes (leave blank for any or use a comma separated list):</a
              >
              <input
                class="form-control form-readonly m-b"
                type="text"
                id="behCodes_{{ behCntCap.capValue }}"
                name="behaviorCodes"
                [(ngModel)]="behCntCap.behaviorCodes"
                (ngModelChange)="updateCaps()"
                (click)="
                  regexSearchModal(
                    'BEHAVIORS',
                    { object: behCntCap, name: 'behaviorCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                readonly
              />
            </div>
            <div class="form-group">
              <label class="control-label" for="previousPeriodCount_{{behCntCap.capValue}}">How many previous periods should be included in the time span (leave empty for only the current period):</label>
              <input class="form-control m-b" type="number" id="previousPeriodCount_{{behCntCap.capValue}}" name="previousPeriodCount" [(ngModel)]="behCntCap.previousPeriodCount" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="relationshipName_{{behCntCap.capValue}}">Include counts from members related by (leave blank for current member only or use a comma separated list):</label>
              <input class="form-control m-b" type="text" id="relationshipName_{{behCntCap.capValue}}" name="relationshipName" [(ngModel)]="behCntCap.relationshipName" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="friendlyMessage_{{behCntCap.capValue}}">Message:</label>
              <input class="form-control m-b" type="text" id="friendlyMessage_{{behCntCap.capValue}}" name="friendlyMessage" [(ngModel)]="behCntCap.friendlyMessage" (ngModelChange)="updateCaps()" />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initCapObject()"><fa-icon [icon]="faPlus"></fa-icon> Add Behavior Count Cap</button>
      </div>
    </div>
  </form>
</div>
