import { Component, EventEmitter, Input, OnInit, Output, ViewChild, NgModule } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { ProgramService } from 'src/app/services/program.service';
import { PromotionConfigService } from 'src/app/services/promotion-config.service';
import { PromotionService } from 'src/app/services/promotion.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FormBuilder, UntypedFormGroup, Validators, FormsModule } from '@angular/forms';
import { faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { ActivatedRoute } from '@angular/router';
import { PromotionTemplate } from 'src/app/data/model';
import { Promotion } from 'src/app/data/model';
import * as $ from 'jquery';
import {Observable, Subject} from 'rxjs';
import { RegexSearchModalComponent } from 'src/app/regex-search-modal/regex-search-modal.component';
import { fromEvent, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-add-attribute-consequence',
  templateUrl: './add-attribute-consequence.component.html',
  styleUrls: ['./add-attribute-consequence.component.scss']
})
export class AddAttributeConsequenceComponent implements OnInit {
    @Input() addAttribute: any;
    faTimes = faTimes;
    faPlus = faPlus;
    @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
    debouncer: Subject<string> = new Subject<string>();
    dateOptions: any;
    periods: any;

  constructor(
    private router: ActivatedRoute,
    private formBuilder: FormBuilder,
    private formsModule: FormsModule,
    private modalHelper: NgbModal
  ) {
      this.debouncer
        .pipe(debounce(() => interval(1000)))
        .subscribe((value) => this.inputChangeEmitter.emit(value));
    }

  ngOnInit(): void {
    this.periods = [
      {'value' : 'YEAR',     'text': 'YEARS'},
      {'value' : 'MONTH',    'text': 'MONTHS'},
      {'value' : 'WEEK',     'text': 'WEEKS'},
      {'value' : 'DAY',    'text': 'DAYS'},
      {'value' : 'QUARTER',    'text': 'QUARTERS'}
    ];


    this.dateOptions = [
      {'value' : 'txnDate',     'text': 'Transaction Date'},
      {'value' : 'activityDate',     'text': 'Activity Date'}
    ];
  }

  initConsequenceObject(): void {
    console.log(this.addAttribute);
    var tmpObj = { class: "com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Consequences$AddAttribute" };
    if(this.addAttribute){
      this.addAttribute.push(tmpObj);
    } else {
      this.addAttribute = [];
      this.addAttribute.push(tmpObj);
    }
    console.log(this.addAttribute);
  }

  updateConsequences() {
    this.debouncer.next(this.addAttribute);
  }

  removeConsequence(consequence) {
    this.addAttribute.splice(this.addAttribute.indexOf(consequence), 1);
    this.updateConsequences();
  }
}
