<div>
  <form>
    <label>Relate to location</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="relateToLocation !== null">
        <div *ngFor="let addRel of relateToLocation; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeConsequence(addRel)" title="Remove Consequence">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label>Relationship Name:</label>
              <select name="relationship_{{addRel.relationshipName}}" class="form-control" [(ngModel)]="addRel.relationshipName" (ngModelChange)="updateConsequences()">
                <option *ngFor="let relationship of partyRelationships" [selected]="addRel.relationshipName === relationship.relationshipName" [value]="relationship.relationshipName">{{relationship.relationshipName}}</option>
              </select>
            </div>
            <div class="form-group">
              <label>From Party Role: </label>
              <select name="fromRole_{{addRel.relationshipName}}" class="form-control" [(ngModel)]="addRel.fromPartyRole" (ngModelChange)="updateConsequences()">
                <option *ngFor="let role of partyRoles" [selected]="addRel.fromPartyRole === role.roleName" [value]="role.roleName">{{role.roleName}}</option>
              </select>
            </div>
            <div class="form-group">
              <label>To Party Role:</label>
              <select name="toRole_{{addRel.relationshipName}}" class="form-control" [(ngModel)]="addRel.toPartyRole" (ngModelChange)="updateConsequences()">
                <option *ngFor="let role of partyRoles" [selected]="addRel.toPartyRole === role.roleName" [value]="role.roleName">{{role.roleName}}</option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initConsequenceObject()"><fa-icon [icon]="faPlus"></fa-icon> Add Relationship</button>
      </div>
    </div>
  </form>
</div>
