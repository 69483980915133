import { Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RedemptionService } from '../services/redemption.service';
import { RewardService } from '../services/reward.service';
import { Attribute } from 'src/app/data/model';
import { DatePipe } from '@angular/common';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-more-details-modal',
  templateUrl: './more-details-modal.component.html',
  styleUrls: ['./more-details-modal.component.scss'],
  providers: [DatePipe]
})
export class MoreDetailsModalComponent implements OnInit {

  @Input() selectedTxn: any; // TODO: assign type
  @Input() attributeDetails: any; // TODO: assign type
  title = 'More Details';
  rewardDetails: any; // TODO: assign type
  redemptionItems: any[]; // TODO: assign type
  reverseDetails: any; // TODO: assign type
  content = '';
  description: string;
  notes: any; // TODO: assign type
  errorMsg: string;
  faTimes = faTimes;

  constructor(
    private modalHelper: NgbModal,
    private rewardService: RewardService,
    private redemptionService: RedemptionService,
    private datePipe: DatePipe,
  ) { }

  ngOnInit(): void {
    if (this.selectedTxn) {
      switch (this.selectedTxn.accountTxnSelector) {
        case 'REWARD':
          this.title = 'Reward Details';
          this.rewardService.getRewardDetails(this.selectedTxn.id).subscribe((data: any) => {
            if (data.success) {
              this.rewardDetails = parseResponse(data);
              if (parseResponse(data) === null) {
                this.content = 'No reward details to show';
              }
            }
          });
          break;
        case 'REDEMPTION':
          this.description = this.selectedTxn.description;
          this.redemptionService.getRedemptionItems(this.selectedTxn.id).subscribe((data: any) => {
            if (data.success) {
              this.redemptionItems = parseResponse(data);
              if (parseResponse(data) === null) {
                this.content = 'No items to show';
              }
            }
          });
          break;
        case 'REVERSE':
          this.title = 'Reverse Details';
          this.rewardService.getReverseDetailsForReward(this.selectedTxn.id).subscribe((data: any) => {
            if (data.success) {
              this.reverseDetails = parseResponse(data);
              if (parseResponse(data) === null) {
                this.content = 'No reverse details to show';
              }
            }
          });
          break;
        case 'EXPIRY':
        case 'ADJUSTMENT':
          this.notes = this.selectedTxn.txnNotes;
          if (this.selectedTxn.txnNotes === null) {
            this.content = 'No notes to show';
          }
          break;
        default:
          this.content = 'Not supported transaction type';
      }
    }

    if (this.attributeDetails) {
      this.title = 'Attribute Details';
      this.content = '';
    }
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  getEffectiveToDisplay(attr: Attribute): string {
    if (!attr.effectiveTo) {
      return '';
    }
    const effectiveToDateStr = new Date(attr.effectiveTo);
    if (effectiveToDateStr > new Date('2099-12-31')) {
      return '';
    } else {
      return attr.effectiveTo;
    }
  }

}
