import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Choice, Filter, SearchFilters } from '../../data/class';
import { TableHelperService } from '../../services/helpers/table-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { JobService } from '../../services/job.service';
import { ResponseHelperService } from '../../services/helpers/response-helper.service';
import { ExternalJob } from '../../data/model';
import { faPlus, faTimes, faUpload, faPlay, faPencilAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../../app-data.service';
import { JobManagementModalComponent } from '../job-management-modal/job-management-modal.component';
import { JobArgumentsModalComponent } from '../job-arguments-modal/job-arguments-modal.component';
import { ToastrService } from 'ngx-toastr';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-job-management-tab',
  templateUrl: './job-management-tab.component.html',
  styleUrls: ['./job-management-tab.component.scss']
})
export class JobManagementTabComponent implements OnInit {

  filteredStatuses: any[] = [];
  limit = 20;
  filter = new Filter();
  disableActions = false;
  statuses: Choice[];
  jobs: ExternalJob[];
  lengthToCompare: number;
  lockedTables = false;
  lock: boolean;
  isLoading = false;
  show = false;
  faPlus = faPlus;
  faTimes = faTimes;
  faUpload = faUpload;
  faPlay = faPlay;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  subject: Subject<any> = new Subject<any>();
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  searchFilters: SearchFilters;

  constructor(
    private tableHelperService: TableHelperService,
    private modalHelper: NgbModal,
    private jobService: JobService,
    private responseHelperService: ResponseHelperService,
    private http: HttpClient,
    private globalData: AppDataService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'job-managment-search',
      searchCallback: () => { this.initNewSearch(); },
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...'
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        }
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'New Job',
          if: true,
          callback: () => { this.jobManagementModal(); },
          title: 'New Job'
        },
      ],
    };
    this.initFilter();
    this.getStatuses();
    this.show = true;

    this.subject.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(item => {
        if (item.value !== '') {
          this.filter[item.name] = item.value;
        } else {
          delete this.filter[item.name];
        }
        this.initNewSearch();
      });
  }

  initNewSearch(): void {
    this.initFilter();
    this.getJobs(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  jobManagementModal(id?: number): void {
    if (id) {
      this.jobService.getJob(id).subscribe((data: any) => {
        if (data.success) {
          const instance = this.modalHelper.open(JobManagementModalComponent);
          instance.componentInstance.jobManagement = parseResponse(data);
          instance.componentInstance.successEvent.subscribe(() => {
            this.initFilter();
            this.getJobs(false);
            instance.close();
          });
        }
      });
    } else {
      const instance = this.modalHelper.open(JobManagementModalComponent);
      instance.componentInstance.successEvent.subscribe(() => {
        this.initFilter();
        this.getJobs(false);
        instance.close();
      });
    }
  }

  uploadFile(element: any): void {
    const fd = new FormData();
    fd.append('file', element.files[0]);
    const id = element.attributes.getNamedItem('id-el').value;
    this.http.post<any>(this.globalData.baseUrl + 'jobs/' + id + '/job-jar/', fd).subscribe((data) => {
      this.responseHelperService.success('Successfully uploaded file', false);
    }, (data) => {
      this.responseHelperService.error(this, data.error.error);
    });
  }

  deleteJob(id: number): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent);
    instance.componentInstance.text = 'Delete job with ID: ' + id + '?';
    instance.componentInstance.header = 'Delete job?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.jobService.deleteJob(id).subscribe((data: any) => {
        this.responseHelperService.success('Reward type successfully removed', false);
        this.initFilter();
        this.getJobs(false);
        instance.close();
      }, (data: any) => {
        this.responseHelperService.error(this, data.error.error);
      });
    });
  }

  getJobs(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.isLoading = true;
    this.jobService.getJobs(this.filter).subscribe((data: any) => {
      if (concat) {
        this.jobs = this.jobs.concat(parseResponse(data));
      } else {
        this.jobs = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
    }, () => {
      this.lockedTables = false;
      this.lock = false;
      this.isLoading = false;
      this.toastr.error('Error occured!');
    });
  }

  setStatuses(): void {
    this.initFilter();
    this.filter.statuses = this.tableHelperService.extractData(this.filteredStatuses);
    if (!this.filter.statuses) {
      delete this.filter.statuses;
    }
    this.getJobs(false);
  }

  jobArgumentsModal(name: string): void {
    const instance = this.modalHelper.open(JobArgumentsModalComponent);
    instance.componentInstance.name = name;
    instance.componentInstance.successEvent.subscribe(() => {
      this.initFilter();
      this.getJobs(false);
      instance.close();
    });
  }
}
