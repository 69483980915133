import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Campaign } from '../data/model';
import { FormArray, FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCalendar, faPlus, faSave, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Choice } from '../data/class';
import { TableHelperService } from '../services/helpers/table-helper.service';
import { CampaignService } from '../services/campaign.service';
import { DateHelperService } from '../services/helpers/date-helper.service';
import { CommunicationStrategyService } from '../services/communications-strategy.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-campaign-modal',
  templateUrl: './campaign-modal.component.html',
  styleUrls: ['./campaign-modal.component.scss']
})
export class CampaignModalComponent implements OnInit {

  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public campaign: Campaign;
  communications: any[] = [];
  title: string;
  isEdit: boolean;
  campaignForm: UntypedFormGroup;
  errorMsg: string;
  faSave = faSave;
  faTimes = faTimes;
  statuses: Choice[];
  faCalendar = faCalendar;
  faPlus = faPlus;
  faTrash = faTrash;
  comm: any[];
  intervals = ['', 'YEAR', 'QUARTER', 'MONTH', 'WEEK', 'DAY'];

  constructor(
    private formBuilder: FormBuilder,
    private tableHelperService: TableHelperService,
    private campaignService: CampaignService,
    private communicationStrategyService: CommunicationStrategyService,
    private responseHelperService: ResponseHelperService,
    private dateHelperService: DateHelperService,
    private modalHelper: NgbModal) { }


  ngOnInit(): void {
    this.setStatuses();
    if (this.campaign) {
      this.title = 'Edit Campaign';
      this.isEdit = true;
      this.campaignForm = this.formBuilder.group({
        name: [this.campaign.name, Validators.required],
        code: [this.campaign.code, Validators.required],
        status: [this.campaign.status, Validators.required],
        expirationDate: [this.dateHelperService.stringToNgbDate(this.campaign.expirationDate)],
        description: [this.campaign.description],
        fromDate: [this.dateHelperService.stringToNgbDate(this.campaign.fromDate), Validators.required],
        thruDate: [this.dateHelperService.stringToNgbDate(this.campaign.thruDate)],
        cap: [this.campaign.cap],
        capRewardCodes: [this.campaign.capRewardCodes],
        sponsorCap: [this.campaign.sponsorCap],
        communicationStrategyId: [this.campaign.communicationStrategyId],
        rolloverInterval: [this.campaign.rolloverInterval],
        campaignAttribs: this.formBuilder.array([]),
      });
      if (this.campaign.campaignAttribs) {
        this.campaign.campaignAttribs.forEach(attibute => {
          this.addAttrib(attibute.attrName, attibute.attrValue);
        });
      }
    } else {
      this.title = 'New Campaign';
      this.isEdit = false;
      this.campaignForm = this.formBuilder.group({
        name: ['', Validators.required],
        code: ['', Validators.required],
        status: [null, Validators.required],
        expirationDate: [null],
        description: [''],
        fromDate: [null, Validators.required],
        thruDate: [],
        cap: [],
        capRewardCodes: [],
        sponsorCap: [],
        communicationStrategyId: [],
        rolloverInterval: [],
        campaignAttribs: this.formBuilder.array([]),
      });
    }
    this.communicationStrategyService.getAllCommunicationStrategy().subscribe(data => {
      if (data.success) {
        this.communications = parseResponse(data);
      }
    });
  }

  campaignAttribs(): FormArray {
    return this.campaignForm.controls.campaignAttribs as FormArray;
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  ok(): void {
    const formValue = Object.assign({}, this.campaignForm.value);
    formValue.expirationDate = this.dateHelperService.ngbDateToString(formValue.expirationDate);
    formValue.fromDate = this.dateHelperService.ngbDateToString(formValue.fromDate);
    formValue.thruDate = this.dateHelperService.ngbDateToString(formValue.thruDate);
    this.campaignService.createOrUpdateCampaign(this.campaign && this.campaign.id, formValue).subscribe(() => {
      this.successEvent.emit('success');
    }, (data: any) => {
      this.responseHelperService.error(this, data.error.error, false);
    });
  }

  clearDate(name: string): void {
    this.campaignForm.patchValue({
      [name]: null
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  newAttribute(attrName?: string, attrValue?: string): UntypedFormGroup {
    return this.formBuilder.group({
      attrName: [attrName || '', Validators.required],
      attrValue: [attrValue || '', Validators.required],
    });
  }

  addAttrib(attrName?: string, attrValue?: string): void {
    this.campaignAttribs().push(this.newAttribute(attrName, attrValue));
  }

  removeAttrib(index: number): void {
    this.campaignAttribs().removeAt(index);
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
