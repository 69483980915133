import { ResponseHelperService } from './../../services/helpers/response-helper.service';
import { SecurityService } from './../../services/security/security.service';
import { SponsorsService } from './../../services/sponsors.service';
import { UsersService } from './../../services/users.service';
import { MembersService } from './../../services/members.service';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { Component, OnInit, Input } from '@angular/core';
import { AppDataService } from 'src/app/app-data.service';
import { RedemptionService } from 'src/app/services/redemption.service';

@Component({
  selector: 'app-status-select',
  templateUrl: './status-select.component.html',
  styleUrls: ['./status-select.component.scss']
})
export class StatusSelectComponent implements OnInit {

  @Input() type: string;
  @Input() selectedModel: any;
  @Input() permission: boolean;
  service: any;
  allowedToUpdate: any;
  faPencilAlt = faPencilAlt;

  constructor(
    public statusHelperService: StatusHelperService,
    public globalData: AppDataService,
    private membersService: MembersService,
    private usersService: UsersService,
    private sponsorsService: SponsorsService,
    private redemptionService: RedemptionService,
    public securityService: SecurityService,
    private responseHelperService: ResponseHelperService
  ) { }

  statusHelper = this.statusHelperService.getStatus('badge');
  dropdownStatuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
  }

  showDropdown(): void {
    const dropdown = $('#myDropdown' + this.type + this.selectedModel.id);
    $('div[id^="myDropdown"]').not('#myDropdown' + this.type + this.selectedModel.id).removeClass('status-dropdown-show');
    if (dropdown.hasClass('status-dropdown-show')) {
      dropdown.removeClass('status-dropdown-show');
    } else {
      dropdown.addClass('status-dropdown-show');
    }
  }

  updateStatus(newStatus): void {
    const dropdown = $('#myDropdown' + this.type + this.selectedModel.id);
    dropdown.toggleClass('status-dropdown-show');
    let data = this.selectedModel;
    data.status = newStatus;

    const dataJson = JSON.stringify(data, this.replacer);
    data = JSON.parse(dataJson);

    if (this.type === 'members' || this.type === 'member') {
      this.membersService.updateMember(data.id, data).subscribe(() => { },
        (response: any) => {
          this.responseHelperService.error(this, response.error.error);
        });
    } else if (this.type === 'users' || this.type === 'user') {
      this.usersService.createOrUpdateUser(data.id, data).subscribe(() => { },
        (response: any) => {
          this.responseHelperService.error(this, response.error.error);
        });
    } else if (this.type === 'accounts') {
      this.membersService.insertOrUpdateAccount(data.partyId, data.id, data).subscribe(() => { },
        (response: any) => {
          this.responseHelperService.error(this, response.error.error);
        });
    } else if (this.type === 'sponsorship' || this.type === 'packagedSponsorship') {
      this.sponsorsService.createOrUpdatePromotionSponsor(data.id, data, 'json').subscribe(() => { },
        (response: any) => {
          this.responseHelperService.error(this, response.error.error);
        });
    } else if (this.type === 'redemptionItem') {
      this.redemptionService.updateRedemptionItem(data.redemptionId, data.id, data.status).subscribe(() => { },
        (response: any) => {
          this.responseHelperService.error(this, response.error.error);
        });
    }
  }

  replacer(key: string, value: any): any {
    const props = ['accounts', 'segments', 'email', 'address', 'phones', 'primaryInstrument', '$$hashKey', 'category'];
    if (props.indexOf(key) !== -1) {
      return undefined;
    } else {
      return value;
    }
  }
}
