import {EventEmitter, Injectable, Output} from '@angular/core';
import {Subject, Subscription} from "rxjs";
import {filter, map} from "rxjs/operators";

export enum TabTypes{
  'member',
  'user',
  'sponsor',
  'comms',
  'promos',
  'system',
  'custom'
}

export class TabSwapEvent{
  destination: string
  params: any
  tabType: TabTypes
}


/**
 * Tab Controller Service
 *
 * This is intended to provide a simple way for deeply nested components to talk with a parent
 * and trigger something to happen (currently just swapping tabs).
 * TODO: It may be useful to rename this as AdminEventManagerService or something
 * TODO: and add some more generic event types along with a TabSwap event.
 *
 */

@Injectable({
  providedIn: 'root'
})
export class TabControllerService {
  private subject$ = new Subject()

  swapTab(tabSwapEvent: TabSwapEvent) {
    this.subject$.next(tabSwapEvent);
  }

  onTabSwap(tabSwapEvent: TabSwapEvent, action: any): Subscription {
    return this.subject$.pipe(
      filter((e: TabSwapEvent) => e.tabType == tabSwapEvent.tabType),
      map((e: TabSwapEvent) => e)).subscribe(action);
  }
}
