import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { MembersService } from '../services/members.service';
import {Observable} from "rxjs";
import { parseResponse } from '../data/parseResponseFunction';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  constructor(private router: ActivatedRoute,
              private route: Router,
              private memberService: MembersService) { }

  ngOnInit(): void {
    let params = this.router.snapshot.queryParams;
    console.log("DEBUG: query params: " + params);
    let instrumentType = params['identifierType'];

    if (instrumentType) {
      console.log("DEBUG: instrument type: " + instrumentType);
    } else {
      // no instrument type was passed in, so we need to check the configuration
      // TODO: get the default instrument type from the configuration
    }

    let instrument = params[instrumentType];
    if (instrument) {
      console.log("DEBUG: instrument: " + instrument);
      this.memberService.getMemberIdByInstrument(instrument, instrumentType).subscribe(
        data => {
          console.log("DEBUG: member id: " + data);
          if (data && parseResponse(data)) {
            this.route.navigate(['/members/' + parseResponse(data)]);
          } else {
            this.route.navigate(['/not-found']);
          }
        });

    } else {
      // send 'em to the not found page
      console.log("DEBUG: no instrument found, redirecting to not found page");
      this.route.navigate(['not-found']);
    }
  }
}
