// import { ChartDataSets } from 'chart.js';
// import { SingleDataSet, Label } from 'ng2-charts';
import { CommunicationType, EndpointType, Email } from './class';
//import { EntityPartyRoleName } from '../organizations/services/url-builder.service';
// export interface SearchTerm {
//   mod: Mod;
//   value: string;
//   readOnly?: boolean;
// }

export class AuditEntity {
  createdOn: string;
  createdBy: string;
  updatedOn?: string;
  updatedBy?: string;
}

export class AuditEntityWithStatus {
  status: number;
}

export enum EntityStatus {
  ACTIVE = 'ACTIVE',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  INACTIVE = 'INACTIVE',
  PENDING = 'PENDING',
  SUSPENDED = 'SUSPENDED',
  TERMINATED = 'TERMINATED',
  RETIRED = 'RETIRED',
  EXPIRED = 'EXPIRED',
  DELETED = 'DELETED',
  FAILED = 'FAILED',
}
export function getEntityStatusValue(status: EntityStatus): number {
  return 1 + Object.keys(EntityStatus).indexOf(status);
}

export const entityStatusList = Object.keys(EntityStatus).map((key) => EntityStatus[key]);

export class PartyRoleType {
  id?: number;
  roleName: string;
}

export class PartyRole {
  partyId?: number;
  roleName: string;
  id?: number;
  description?: string;
}

export class HierarchicalOrganization {
  parentCode: string;
  parentId: number;
  partyRoles: PartyRole[];
  id: number;
  username: string;
  password: string;
  notes: string;
  loginAttemptCounter: number;
  lastLogin: Date;
  segmentsCollection: Segment[];
  authorities: any; // Authority[]
  partyAttributeCollection: any; // PartyAttribute[]
  name: string;
  workingHours: string;
  url: string;
  yearStart: string;
  attrs: any;
  segments: any;
  emails: any;
  status: string;
}

export class Organization {
  id: number;
  username: string;
  password: string;
  notes: string;
  loginAttemptCounter: number;
  lastLogin: Date;
  segmentsCollection: Segment[];
  authorities: any; // Authority[]
  partyAttributeCollection: any; // PartyAttribute[]
  name: string;
  workingHours: string;
  url: string;
  yearStart: string;
  attrs: any;
  segments: any;
  emails: any;
  status: EntityStatus;
  updatedOn: Date;
  parentCode: string;
  parentId?: number;
  partyRoles: { roleName: any }[];
  logoUrl: string;
  setExternalOrgCode: string;
}

export class OrganizationPartyId {
  sponsorPartyId: number;
}

export class OrganizationStatistics {
  campaignCount?: number;
  promotionConfigCount?: number;
  promotionSponsorCount?: number;
}

export class Member {
  id: number;
  status: number;
  username: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  activityInstruments: object;
  partyEmails: object;
  middleInitial?: string;
  instrumentNumber?: string;
  instrumentType?: string;
  gender?: Gender;
}

export class Report {
  id: number;
  name: string;
  description: string;
  renderTemplateSource: string;
  sqlQuery: string;
  status: any;
  interactiveReport: boolean;
  dashboardReport: boolean;
  outboundReport: boolean;
  forStaging: boolean;
  clientParams: any;
  systemParams: any;
  reportAttributes: any;
  color: string;
  isLoading: boolean;
  chartLabels: any[];
  chartSeries: string[];
  chartData: any[];
  chartDataSets: any[];
  attributes: any;
}

export class Segment {
  id: number;
  name: string;
  description: string;
  code: string;
  parentId: number;
  checked: boolean;
  status?: string;
  level?: number;
  assigned?: boolean;
}

export class SponsorshipGroup {
  sponsorPartyId: number;
  sponsorshipTag: string;
  packages: ProgramPackage[];
}

export class ProgramPackage {
  id: number;
  packageName: string;
  programId: number;
  startDate: Date;
  endDate: Date;
  createdOn: Date;
  createdBy: string;
  updatedOn: Date;
  updatedBy: string;
}

export class Program {
  id: number;
  name: string;
  status?: number;
  code?: number;
  description?: string;
  imageUrl?: string;
}

export class Campaign {
  id: number;
  name: string;
  description: string;
  fromDate: string;
  thruDate: string;
  rolloverInterval: string;
  cap: number;
  capRewardCodes: string;
  expirationDate: string;
  sponsorCap: number;
  campaignAttribs: CampaignAttribute[];
  campaignEligibilityCondition: any[];
  rolloverDate: Date;
  communicationStrategyName: string;
  promotions: Promotion[];
  externalProgramId: String;
  status: string;
  code: number;
  communicationStrategyId: number;
  sponsorPartyId: number;
}

export class OrgConfiguration {
  id: number;
  orgId: number;
  roleScope: string;
  cfgCode: string;
  cfgValue: string;
  effectiveDate: Date;
  expirationDate: Date;
  description: string;
}

export class CommunicationStrategy {
  id: number;
  name: string;
  priority: string;
  activityRewardId: number;
  noActivityId: number;
  expiringPromotionsId: number;
  newPromotionsId: number;
  status: string;
}

export class CommunicationStrategyConfiguration {
  id: number;
  fromTime: string;
  toTime: string;
  optInOptOut: boolean;
  endpointTypeName: string;
  blackOutPeriods: string;
  commStrategyId: number;
  commCaps: any[];
  filters: any[];
}

export class PromotionType {
  id: number;
  name: string;
  status: string;
}

export class PromotionCategory {
  id: number;
  name: string;
  status: string;
  sponsorPartyId: number;
  sort: number;
}

export class CampaignAttribute {
  id: number;
  campaignId: number;
  attrName: string;
  attrValue: string;
  displayName: string;
  createdOn: Date;
  createdBy: string;
}

export class Promotion {
  id: number;
  promotionSponsorId: number;
  createdOn: Date;
  updatedOn: Date;
  createdBy: string;
  updatedBy: string;
  status: string;
  communicationStrategyId: number;
  code: string;
  name: string;
  description: string;
  fromDate: Date;
  thruDate: Date;
  rolloverInterval: any;
  cap: number;
  capRewardCodes: number;
  expirationDate: Date;
  sponsorCap: number;
  campaignAttribs: any;
  rolloverDate: Date;
  communicationStrategyName: string;
  checked?: boolean;
  isSelected?: boolean;
  hasSelectedPackage: boolean;
  typeId?: number;
  parentId?: number;
  programId?: number;
  children?: Promotion[];
  typeName?: string;
  typeColor?: string;
  sponsorPartyId?: number;
}

export class Package {
  id: number;
  packageName: string;
  programId: string;
  startDate: string;
  endDate: string;
  createdOn: string;
  createdBy: string;
}

export enum DeliveryProtocolName {
  INIT = 'INIT',
  IRIDIUM_VOICE = 'IRIDIUM_VOICE',
}

export class Communication {
  id: number;
  name: string;
  endpointType: string;
  deliveryProtocol: any;
  segments: Segment[];
  status: any;
  requirePreference: boolean;
  communicationDeliveryProtocols: any[];
  communicationType: CommunicationType;
  segmentIds: number[];
  communicationId?: number;
  partyId?: number;
  phone?: Phone;
  optOut?: boolean;
  phoneId?: number;
  emailId?: number;
  addressId?: number;
  jSONAddressDTO?: any;
  partyEmailDTO?: any;
  parentId?: number;
  commTypeId?: number;
  children?: Communication[];
  rules?: string;
  contentGroupId?: number;
}

export class Person {
  id: number;
  firstName: string;
  middleInitial: string;
  lastName: string;
  segments?: Segment[];
  dateOfBirth: string;
  createdOn: string;
  lastLogin: string;
  gender: Gender;
  loginAttemptCounter: number;
  attrs: Attribute[];
  partyEmails: Email[];
  password: string;
  address: Address;
  phones?: Phone[];
  status?: any;
  activityInstruments: any[];
  partyId?: number;
  redemptionId?: number;
  email?: Email;
  accounts?: any;
  username: string;
}

export class Integration {
  id: number;
  name: string;
  integrationVersion: number;
  customerName: string;
  fileType: string;
  fileLayoutVersion: number;
  fileLayoutSchema: string;
  fileNamePattern: string;
  recordDelimiter: string;
  dateFormat: string;
  manifest: string;
  fieldCount: number;
  addsAndUpdates: boolean;
  autoImport: boolean;
  activateOnImport: boolean;
  acceptUploads: boolean;
  activationRealm: string;
  preprocessJob: string;
  postprocessJob: string;
  server: IntegrationServer;
  key: IntegrationKey;
  postUploadJobName: string;
  recordTransformer: number[];
  recordTransformerName: string;
  openAccess: boolean;
  status: string;
  ignoreHeader: number;
  ignoreTrailer: number;
  encodingCharset: string;
  recordLength: number;
  importType: string;
}

export class IntegrationServer {
  id: number;
  name: string;
  url: string;
  protocolType: any;
  credentialType: any;
  username: string;
  password: string;
  sshKey: string;
  status: string;
  importType: string;
}

export class IntegrationKey {
  id: number;
  name: string;
  publicKey: number[];
  privateKey: number[];
  keyPassword: number[];
  keyFormat: any;
  status?: string;
}

export class OutboundIntegration {
  id: number;
  name: string;
  recordDelimiter: string;
  reportId: number;
  reportName: string;
  status?: string;
}

export class Currency {
  id: number;
  name: string;
  description: string;
  displayFormat: string;
  // @Pattern(regexp = "\\d{3}",message = "Numeric code must contain exactly 3 digits")
  numericCode: string;
  // @Pattern(regexp = "[A-Za-z]{3}",message = "Alpha code must contain exactly 3 letters")
  alphaCode: string;
}

export class RewardType {
  id: number;
  code: string;
  name: string;
  description: string;
  currencyId: number;
  isExpiring: boolean;
  expiryMonths: number;
  isAutoredemptionReversible: boolean;
  status?: string;
}
export class Attachment {
  id: number;
  communicationId: number;
  name: string;
  mimeType?: string;
  attachmentBytes?: any;
}

export class AccountModel {
  id: number;
  accountType: string;
  rewardType: string;
  status: string;
}

export class Adjustment {
  id: number;
  account: any;
  amount: number;
  txnNotes: string;
}

export class InsrumentType {
  id: number;
  code: string;
  extCode: string;
  description: string;
  status: string;
}

export class Behavior extends AuditEntityWithStatus {
  id: number;
  code: string;
  name: string;
  hierarchySourceId: number;
  description: string;
}

export class WorldCurrency {
  id: number;
  name: string;
  description: string;
  displayFormat: string;
  // @Pattern(regexp = "\\d{3}", message = "Numeric code must contain exactly 3 digits")
  numericCode: string;
  // @Pattern(regexp = "[A-Za-z]{3}", message = "Alpha code must contain exactly 3 letters")
  alphaCode: string;
}

export class DeliveryProtocol {
  id: number;
  name: string;
  deliveryOptions: DeliveryOption[];
  description: string;
  endpointType: EndpointType;
  // emailType: string;
}

export class ChannelConfiguration {
  id: number;
  campaignId: number;
  promotionId: number;
  communicationId: number;
  deliveryProtocolId: number;
  name: string;
  value: string;
  createdOn: string;
  createdBy: string;
  updatedOn: string;
  updatedBy: string;
  sensitive: boolean;
}

export class CommunicationMessage {
  communicationId: number;
  partyId: number;
  instanceAttributes?: InstanceAttribute[];
  attachments?: GeneratedCommAttachment[];
}

export class GeneratedCommAttachment {
  id?: number;
  communicationHistoryId: number;
  name?: string;
  mimeType?: string;
  attachmentBytes?: number[];
}

export class InstanceAttribute {
  attrName: string;
  attrValue: string;
}

export class FilterModel {
  id: number;
  name: string;
  className: string;
  status: string;
  description: string;
  enabled: boolean;
  jobSelector: string;
}

export class Location extends AuditEntityWithStatus {
  id: number;
  name: string;
  code: string;
  description: string;
  hierarchySourceId: number;
  parentCode: string;
  parentId?: number;
  coordinate1?: number;
  coordinate2?: number;
  coordinate3?: number;
  version?: number;
}

// todo assign type in member-activity-modal
export class Activity extends AuditEntityWithStatus {
  id: number;
  activitySelector: string;
  party: Party;
  date: Date;
  refNum: string;
  refNum2: string;
  originalRefNum: string;
  location: Location;
  address: any;
  description: string;
  agentId: number;
  importChannel: any;
  isReturn: boolean;
  originalActivityId: number;
  stageLoadId: number;
  stageImportId: number;
  createdBy: string;
  createdOn: Date;
  version: number;
}

export class Party extends AuditEntityWithStatus {
  id: number;
  partySelector: string;
  username: string;
  password: string;
  doNotEmail: boolean;
  description: string;
  notes: string;
  activationDate: Date;
  loginAttemptCounter: number;
  roles: string[];
  lastLogin: Date;
  segmentsCollection: Segment[];
  authorities: any[];
  partyAttributeCollection: PartyAttribute[];
}

export class PartyAttribute {
  id: number;
  attribValueId: number;
  attribHxCount: number;
  status: number;
  statusCode: any;
  partyId: number;
  attrName: string;
  attrValue: string;
  credential: boolean;
  effectiveTo: Date;
  createdOn: Date;
  createdBy: string;
}

export class Product {
  id: number;
  status: string;
  name: string;
  description: string;
  code: string;
  averageUsage: string;
  barCode: string;
  dimensions: string;
  ean: string;
  lenght: string;
  manufacturer: string;
  price: number;
  pricePerUnit: number;
  productCategoryId: number;
  quantityPerPackage: number;
  rrp: number;
  serialNum: string;
  unitOfMeasure: string;
  upc: string;
  weight: string;
}

export class ExternalJob {
  id: number;
  name: string;
  description: string;
  jobSelector: string;
  enabled: boolean;
  version: number;
  queueName: string;
  second: string;
  minute: string;
  hour: string;
  month: string;
  year: string;
  dayOfWeek: string;
  dayOfMonth: string;
  cron: string;
  beanName: string;
  startDate: Date;
  endDate: Date;
  nextTimeout: Date;
  className: string;
  classPath: string;
  args: string;
  lastRunStarted: Date;
  lastRunEnded: Date;
  lastRunError: Date;
  status?: string;
}
export class Attribute {
  id?: number;
  attrValueId?: number;
  attribValueId?: number;
  attribHxCount?: number;
  attrName: string;
  attrValue: any;
  effectiveFrom?: any;
  effectiveTo?: any;
  displayName?: any;
  status?: string;
  statusCode?: string;
  createdOn?: string;
  updatedOn?: string;
  partyId?: number;
  credential?: boolean;
  value?: any;
  commandType?: string;
}

export class Language {
  id: number;
  languageCode: string;
  languageName: string;
}

export class ContactReason {
  id: number;
  code: string;
  value: string;
  status: string;
  surveyPath: string;
}

export class NutritionTag {
  id: number;
  localName: string;
  remoteId: number;
  remoteName: string;
  remoteCategory: string;
}

export class AddressModel {
  id?: number;
  addressId: number;
  address1: string;
  address2: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  postalCode: string;
  addressType: string;
  isPrimary: boolean;
  createdOn: string;
  updatedOn: string;
  updatedBy: string;
  status: string;
  defaultComm: boolean;
}

export class EmailModel {
  id: number;
  emailAddress: string;
  addressName: string;
  isPrimary: boolean;
}

export class Phone {
  id: number;
  phoneNumber: string;
  phoneTypeId: number;
  status: string;
  isPrimary: boolean;
  phoneTypeName: string;
}

export class ActivityInstrument extends AuditEntityWithStatus {
  id: number;
  instrSelector: string;
  partyId: number;
  instrumentType: InstrumentType;
  instrumentNumber: string;
  isPrimary: boolean;
  holderName: string;
  expiryDate: string;
  activationDate: string;
  activationDescription: string;
  country: string;
  account: string;
  cancelationDate: string;
  closeDate: string;
  closedBy: string;
  closeDescription: string;
  notes: string;
  version: number;
}

export class InstrumentType {
  id: number;
  code: string;
  extCode: string;
  description: string;
  version: number;
  createdBy: string;
  createdOn: string;
  status?: string;
  inputMaskRegex?: string;
  displayFormat?: string;
}

export class Role {
  description: string;
  parentRoleId: string;
  roleName: string;
}

export class MemberInteraction {
  id: number;
  startedBy: number;
  memberPartyId: number;
  subject: string;
  status: string;
  contactMethod: string;
  contactReason: number;
  contactReasonCode: string;
  created: string;
  createdBy: string;
  entityList: any;
  escalated: boolean;
  lastModified: string;
  memberFirstName: string;
  memberLastName: string;
  preview: string;
  updates: number;
}

export class Partner {
  id: number;
  name: string;
  partyRoleTypes: any[];
  username: string;
  url: string;
  status: string;
  segmentsCollection: any[];
  workingHours: string;
  yearStart: string;
  notes: string;
}

export class HierarchySource extends AuditEntityWithStatus {
  id: number;
  code: string;
  name: string;
  description?: string;
  version: number;
}

export class User {
  id: number;
  firstName: string;
  lastName: string;
  notes: string;
  partyEmails: Email[];
  password: string;
  segmentsCollection: any[];
  status: string;
  username: string;
  accessRoleCollection: any[];
  dateOfBirth: string;
  gender: any;
  emailsList: any[];
  partyAccessRolesList: any[];
  segmentsList: any[];
  lastLogin: string;
  email: string;
  authorities: any;
}

export class DeliveryOption {
  id: number;
  communicationId: number;
  protocolId: number;
  optionName: string;
  optionValue: string;
  sensitive: boolean;
}

export class Gender {
  id: number;
  name: string;
  description: string;
}

export class PromotionTemplate {
  id: number;
  name: string;
  description: string;
  ruleSource: string;
  requiredSteps: string; // Deprecated
  configurationXml: string;
  updatedBy: string;
  updatedOn: Date;
  createdBy: string;
  createdOn: Date;
  promotionId: number;
  packageId: number;
  sponsorCount: number;
  programPackage: any; // type: ProgramPackage
}

export class PromotionConfiguration {
  cfgRef: string;
  configuration: string;
  configurationSchemaName: string;
  id: number;
  promotionSponsor: any;
  promotionTemplateId: number;
}

export class EventSource {
  id: number;
  code: string;
  description: string;
  activityPassthrough: boolean;
  measuresOnly: boolean;
  currentOnNextEvent: boolean;
}

export class Address {
  address1: string;
  address2?: string;
  city?: string;
  state?: string;
  postalCode?: string;
}

export class PromotionSponsorReminder {
  id: number;
  promotionSponsorId: number;
  communicationReminder?: any;
  outreachSequence: OutreachSequence;

  constructor() {
    this.id = null;
    this.promotionSponsorId = null;
    this.outreachSequence = new OutreachSequence();
  }
}

export class OutreachSequence {
  promotion: string;
  numberOfPeriods: number;
  period: string;
  outreachId: number;
}

export class CommunicationReminder {
  daysAfterPromotionStart: PromotionDays;
  daysBeforePromotionExpires: PromotionDays;
  noEligibleActivityForPeriod: PromotionDays;
  singleMessagePerPeriod: string;
  multipleMessagePerPeriod: MessagesPerPeriod;

  constructor() {
    this.daysAfterPromotionStart = new PromotionDays();
    this.daysBeforePromotionExpires = new PromotionDays();
    this.noEligibleActivityForPeriod = new PromotionDays();
    this.singleMessagePerPeriod = null;
    this.multipleMessagePerPeriod = new MessagesPerPeriod();
  }
}

export class PromotionDays {
  days: number;
  period: string;
  promotionDate: string;
  memberRepeating: number;

  constructor() {
    this.days = null;
    this.period = null;
    this.promotionDate = null;
    this.memberRepeating = null;
  }
}

export class MessagesPerPeriod {
  numberOfMessages: number;
  period: string;

  constructor() {
    this.numberOfMessages = null;
    this.period = null;
  }
}

export class Survey {
  id: string;
  title: string;
  allTags: string[];
  dateOverride: string;
  displayType: string;
  imageUrl: string;
  name: string;
  returnUrl: string;
  tags: string[];
  questions: Question[];
}

export class Question {
  id: string;
  name: string;
  order: string;
  question: string;
  required: boolean;
  answers: Answer[];
}

export class Answer {
  id: string;
  label: string;
  value: string;
  order: number;
}

export class ProspectiveMember {
  id: number;
  createdOn: string;
  createdBy: string;
  notes: string;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  middleInitial: string;
  segmentsCollection: any[];
  activityInstruments: ActivityInstrument[];
  gender: Gender;
}

export class LiveAgentSurvey {
  segmentId?: number;
  memberId: number;
  memberFirstName: string;
  memberLastName: string;
  assessmentDate?: Date;
  primaryInstrumentNumber: string;
  surveyStatus: string;
  completedByUserId: number;
  completedByFirstName: string;
  completedByLastName: string;
  submittedAt?: Date;
}

export class AllowedContactInfo {
  id: number;
  value: string;
  createdOn: string;
  createdBy: string;
  type: string;
  description: string;
}

export class ContentGroup {
  id: number;
  name: string;
  deliveryProtocolId?: number;
  deliveryProtocolName?: string;
  inUse?: boolean;
}

export class OutreachContent {
  id?: number;
  contentGroup: ContentGroup;
  endpointType: string;
  language: any;
  template?: any;
  subject?: string;
  segment?: any;
  status?: string;
  brandCode?: string;

  constructor(contentGroupId: number) {
    this.contentGroup = new ContentGroup();
    this.contentGroup.id = contentGroupId;
  }
}