<form [formGroup]="worldCurrencyForm">
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Name</label>
          <input type="text" formControlName="name" class="form-control" [appTrim]="!currency">
          <div class="mt-sm" [hidden]="worldCurrencyForm.controls['name'].valid">
            <small class="text-danger">Input name</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Alpha Code</label>
          <input type="text" formControlName="alphaCode" class="form-control" [appTrim]="!currency">
          <div class="mt-sm" [hidden]="!worldCurrencyForm.controls['alphaCode'].errors?.required">
            <small class="text-danger">Input alpha code</small>
          </div>
          <div class="mt-sm" [hidden]="!worldCurrencyForm.controls['alphaCode'].errors?.pattern">
            <small class="text-danger">Alpha code must contain exactly 3 letters</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Numeric Code</label>
          <input type="text" formControlName="numericCode" class="form-control" [appTrim]="!currency">
          <div class="mt-sm" [hidden]="!worldCurrencyForm.controls['numericCode'].errors?.required">
            <small class="text-danger">Input numeric code</small>
          </div>
          <div class="mt-sm" [hidden]="!worldCurrencyForm.controls['numericCode'].errors?.pattern">
            <small class="text-danger">Numeric code must contain exactly 3 digits</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Display Format</label>
          <input type="text" formControlName="displayFormat" class="form-control" [appTrim]="!currency">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Description</label>
          <textarea formControlName="description" class="form-control vertical-expand" [appTrim]="!currency"></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" (click)="ok()" [disabled]="!worldCurrencyForm.valid" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>