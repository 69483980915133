import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommunicationStrategyService } from '../services/communications-strategy.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AddFilterDialogComponent } from '../add-filter-dialog/add-filter-dialog.component';
import { faPlus, faSave, faTimes, faTrash, faAngleDoubleUp, faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-endpoint-tab',
  templateUrl: './endpoint-tab.component.html',
  styleUrls: ['./endpoint-tab.component.scss']
})
export class EndpointTabComponent implements OnInit {

  constructor(private communicationStrategyService: CommunicationStrategyService,
              private modalHelper: NgbModal,
              private toastr: ToastrService) { }

  paramTypes = ['INTEGER', 'letCHAR', 'FLOAT', 'NUMERIC', 'BIGINT', 'TIMESTAMP', 'ARRAY INTEGER', 'ARRAY letCHAR'];
  @Input() commStrategyId: number;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  endpoints: any[] = [];
  filters: any[] = [];
  selectedEndpoint: any;
  lock = false;
  lengthToCompare: number;
  limit: number;
  isLoading = false;
  lockedTables = false;
  offset = 20;
  showParams: boolean[] = [];
  faPlus = faPlus;
  faSave = faSave;
  faTimes = faTimes;
  faTrash = faTrash;
  faAngleDoubleDown = faAngleDoubleDown;
  faAngleDoubleUp = faAngleDoubleUp;

  ngOnInit(): void {
    this.getEndpoints(false);
  }

  getEndpoints(concat: boolean): void {
    this.isLoading = true;
    this.communicationStrategyService.getCommStrategyConfigurations(this.commStrategyId).subscribe((data: any) => {
      if (concat) {
        this.endpoints = this.endpoints.concat(parseResponse(data));
      } else {
        this.endpoints = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.offset += this.limit;
      this.isLoading = false;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
    }, () => {
      this.lockedTables = false;
      this.lock = false;
      this.isLoading = false;
      this.toastr.error('Error occured!');
    });
  }

  addParameter(filter: any): void {
    filter.parameters.push({});
  }


  removeParameter(filter: any, param: any, index: number): void {
    if (this.selectedEndpoint.commStrategyId && filter.id && param.id) {
      this.communicationStrategyService.removeFilterParam
        (this.selectedEndpoint.commStrategyId, filter.id, param.id).subscribe(() => {
          this.toastr.success('Report parameters successfully removed');
          filter.parameters = filter.parameters.filter((p: any) => p.id !== param.id);
        }, () => {
          this.toastr.error('Error occured!');
        });
    } else if (filter.parameters.length > 1) {
      filter.parameters.splice(index, 1);
    }
  }

  saveParams(filter: any): void {
    const params = [];
    let canSubmit = true;
    filter.parameters.forEach((param: any) => {
      if (!canSubmit) {
        canSubmit = !!param.paramCode && !!param.paramValue && !!param.paramType;
      }
      params.push({
        commStrategyFilterId: filter.commStrategyFilterId,
        paramCode: param.paramCode,
        paramValue: param.paramValue,
        paramType: param.paramType,
        id: param.id,
        description: param.description
      });
    });
    if (canSubmit) {
      this.communicationStrategyService.insertFilterParams(this.selectedEndpoint.commStrategyId, filter.id, params)
        .subscribe(() => {
          this.toastr.success('Filter Parameters successfully updated!');
        }, () => {
          this.toastr.error('Filter Parameters failed to update!');
        });
    } else {
      this.toastr.error('Please fill in necessary fields!');
    }
  }

  showInfo(endpoint: any, event: any): void {
    $('tr.active').removeClass('active');
    $(event.currentTarget).toggleClass('active');
    this.getFilters(endpoint);
  }

  getFilters(endpoint: any): void {
    this.communicationStrategyService.getCommStrategyFilters(endpoint.commStrategyId, { endpointTypeName: endpoint.endpointTypeName })
      .subscribe((resp: any) => {
        this.filters = resp.entity;
        this.showParams = [];
        this.filters.forEach((filter: any) => {
          this.showParams.push(false);
          if (!filter.parameters) {
            filter.parameters = [{}];
          }

        });
        this.selectedEndpoint = endpoint;
      }, () => {
        this.toastr.error('Error occured!');
      });
  }

  addNewFilter(endpoint: any): void {
    const instance = this.modalHelper.open(AddFilterDialogComponent);
    instance.componentInstance.endpointTypeName = endpoint.endpointTypeName;
    instance.componentInstance.commStrategyId = this.commStrategyId;
    instance.componentInstance.ordernum = this.filters.length + 1 ;
    instance.componentInstance.successEvent.subscribe(() => {
      this.getFilters(endpoint);
    });
  }

}
