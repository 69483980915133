import { Component, Input, OnInit } from '@angular/core';
import { faCalendar, faTrash, faUserPlus, faUsers } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppDataService } from 'src/app/app-data.service';
import { Segment } from 'src/app/data/model';
import { CommunicationsService } from 'src/app/services/communications.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { PersonsService } from 'src/app/services/persons.service';
import { CommunicationSegmentModalComponent } from '../communication-segment-modal/communication-segment-modal.component';

@Component({
  selector: 'app-communication-segments-tab',
  templateUrl: './communication-segments-tab.component.html',
  styleUrls: ['./communication-segments-tab.component.scss']
})
export class CommunicationSegmentsTabComponent implements OnInit {

  @Input() commId: number;
  filtered: Segment[] = [];
  allSegments: Segment[] = [];
  query: string;
  faUserPlus = faUserPlus;
  faCalendar = faCalendar;
  faUsers = faUsers;
  faTrash = faTrash;

  constructor(
    private communicationsService: CommunicationsService,
    private personsService: PersonsService,
    private modalHelper: NgbModal,
    private responseHelperService: ResponseHelperService,
    private appDataService: AppDataService
  ) { }

  ngOnInit(): void {
    this.filtered = [];
    this.allSegments = [];

    this.communicationsService.getCommunicationSegmentsTree(this.commId).subscribe((segments: any) => {
      if (segments.success) {
        // scope.segments = segments.entity;
        this.allSegments = segments.entity;
        this.filtered = segments.entity;
      }
    });
  }

  addUserToSegment(): void {
    const self = this;
    this.personsService.getPersonSegments(this.appDataService.userId, true).subscribe((res: any) => {
      if (res.success) {
        self.filtered.forEach((existingSegment: any) => {
          res.entity = res.entity.filter((segment: any) => {
            if (existingSegment.code === segment.code) {
              return false;
            } else {
              return true;
            }
          });
        });
        const instance = this.modalHelper.open(CommunicationSegmentModalComponent);
        instance.componentInstance.segments = res.entity;
        instance.componentInstance.commId = this.commId;
        instance.componentInstance.successEvent.subscribe(() => {
          this.communicationsService.getCommunicationSegmentsTree(this.commId).subscribe((segments: any) => {
          this.responseHelperService.success('Segment tree refreshed', true);
          this.filtered = segments.entity;
          }, (data: any) => {
            this.responseHelperService.error(this, data.error.error);
          });
          instance.close();
        });
      }
    });
  }

  removeSegmentFromUser(segmentId: number): void {
    this.communicationsService.removeCommunicationSegments(this.commId, segmentId).subscribe(() => {
      this.responseHelperService.success('Segment successfully removed');
      this.communicationsService.getCommunicationSegmentsTree(this.commId).subscribe((segments: any) => {
        this.responseHelperService.success('Segment tree refreshed');
        this.filtered = segments.entity;
      }, (data: any) => {
        this.responseHelperService.error(this, data.error.error);
      });
    }, (data: any) => {
      this.responseHelperService.error(this, data.error.error);
    });
  }

  applyFilter(query: string): void {
    this.filtered = [];
    const self = this;
    if (query && query !== '' && query.length > 2) {
      // Add all that have code as filter
      this.allSegments.forEach((segment: Segment) => {
        if (segment.code.toUpperCase().indexOf(query.toUpperCase()) !== -1) {
          self.filtered.push(segment);
        }
      });
      // Add parents
      for (let i = this.allSegments.length - 1; i >= 0; i--) {
        if (isParent(this.allSegments[i].id, this)) {
          const idx = getIdx(this.allSegments[i].id);
          this.filtered.splice(idx, 0, this.allSegments[i]);
        }
      }
    } else {
      this.filtered = this.allSegments;
    }

    function getIdx(segmentId: number): number {
      const indexs = [];
      this.filtered.forEach((item: any, idx: number) => {
        if (item.parentId === segmentId) {
          indexs.push(idx);
        }
      });
      return Math.min.apply(null, indexs);
    }

    // inArray added for returning only parents that are not in array already
    function isParent(id: number, scope: any): boolean {
      let parent = false;
      let inArray = true;
      scope.filtered.forEach((segment: any) => {
        if (segment.parentId === id) {
          parent = true;
        }
        if (segment.id === id) {
          inArray = false;
        }
      });
      return parent && inArray;
    }
  }

}
