import { Subject, Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GlobalEventService {

  private subject = new Subject<any>();

  constructor() { }

  sendGlobalEvent(response: any): void {
    if (response) {
      this.subject.next(response);
    } else {
      this.subject.next();
    }
  }

  getGlobalEvent(): Observable<any> {
    return this.subject.asObservable();
  }
}
