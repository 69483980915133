<div class="wrapper wrapper-content">
    <div class="ibox">
        <div class="ibox-content">
            <div class="row">
                <div class="col-lg-12">
                    <div class="mb-3">
                        <h2 class="fullname">Reports</h2>
                    </div>
                </div>
            </div>
            <div class="tabs-container">
                <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>Reports Available</a>
                        <ng-template ngbNavContent>
                            <app-reports-available [isAdmin]="isAdmin"></app-reports-available>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>Reports Results</a>
                        <ng-template ngbNavContent>
                            <app-reports-results [isAdmin]="isAdmin"></app-reports-results>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
        </div>
    </div>
</div>