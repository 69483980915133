import { Component, Input, OnInit } from '@angular/core';
import { faPencilAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Attachment } from 'src/app/data/model';
import { CommunicationsService } from 'src/app/services/communications.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { CommunicationAttachmentModalComponent } from '../communication-attachment-modal/communication-attachment-modal.component';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-communication-attachments-tab',
  templateUrl: './communication-attachments-tab.component.html',
  styleUrls: ['./communication-attachments-tab.component.scss']
})
export class CommunicationAttachmentsTabComponent implements OnInit {

  @Input() commId: number;
  attachments: Attachment[] = [];
  faPlus = faPlus;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  attachment: Attachment;

  constructor(
    private communicationsService: CommunicationsService,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal
  ) { }

  ngOnInit(): void {
    this.setAttachments();
  }

  setAttachments(): void {
    this.communicationsService.getAttachments(this.commId).subscribe((res: any) => {
      if (res.success) {
        this.attachments = res.entity;
      }
    });
  }

  deleteAttachment(attachment: Attachment): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, {size: 'sm'});
    instance.componentInstance.text = 'Delete attachment with name: ' + attachment.name;
    instance.componentInstance.header = 'Delete attachment?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.attachment = null;
      this.communicationsService.deleteAttachment(attachment.id).subscribe(() => {
        this.responseHelperService.success('Attachment successfully deleted', true);
        this.setAttachments();
      });
    });
  }

  attachmentModal(id?: number): void {
    this.attachment = null;
    if (id) {
      this.communicationsService.getAttachment(id).subscribe((res) => {
        if (res.success) {
          this.attachment = res.entity;
          this.createAttachmentModal();
        }
      });
    } else {
      this.createAttachmentModal();
    }
  }

  createAttachmentModal(id?: number): void {
    const instance = this.modalHelper.open(CommunicationAttachmentModalComponent);
    instance.componentInstance.attachment = this.attachment;
    instance.componentInstance.commId = this.commId;
    instance.componentInstance.resourceType = 'promotion';
    instance.componentInstance.successEvent.subscribe(() => {
      this.setAttachments();
      instance.close();
    });
  }

}
