import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { faChevronUp, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { GlobalEventService } from '../services/global-event.service';

@Component({
  selector: 'app-sponsorship-promo-tree',
  templateUrl: './sponsorship-promo-tree.component.html',
  styleUrls: ['./sponsorship-promo-tree.component.scss']
})
export class SponsorshipPromoTreeComponent implements OnInit {

  @Input() programStructure: any;
  @Input() statusHelper: any;
  @Input() selectedPackage: any;
  @Input() selectedPromotion: any[];
  @Input() promotion: any;
  @Output() public selectedPromotionChange: EventEmitter<any> = new EventEmitter();
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  faTimes = faTimes;
  showChild = false;

  constructor(
    private globalEventService: GlobalEventService
  ) { }

  ngOnInit(): void { }

  toggleChildren(event): void {
    this.showChild = !this.showChild;
    $($($(event.currentTarget).parents('.list-wrapper')[0]).children('ul')[0]).toggleClass('display-none');
    event.stopPropagation();
  }

  removePromo(promo: any): void {
    this.selectedPromotion = this.selectedPromotion.filter((prom: any) => {
      return prom.id !== promo.id;
    });
    const dataToSend: any = {};
    dataToSend.data = this.selectedPromotion;
    dataToSend.toComponent = 'add-sponsorship-modal';
    this.globalEventService.sendGlobalEvent(dataToSend);
    this.globalEventService.sendGlobalEvent(this.selectedPromotion);
  }

  showPromo(promo: any): boolean {
    // Only show selected promos for programs that have a lot of promos; we don't want the user hunting to
    // make sure they selected the right promo.
    this.promotion = promo;
    if (this.selectedPackage) {
      return true;
    } else {
      if (this.programStructure?.promotions.length > 100) {
        return !!this.getPromoState(promo, 'isSelected');
      } else {
        return true;
      }
    }
  }

  // tslint:disable-next-line:adjacent-overload-signatures
  getPromoState(promo: any, type: string): string | undefined {
    let state;
    if (type === 'class') {
      let promoClass;
      if (this.selectedPromotion) {
        promoClass = 'promo-element-small faded-promo';
        this.selectedPromotion.forEach((promotion: any) => {
          if (promo.id === promotion.id) {
            promoClass = 'promo-element-small';
          }
        });
      } else {
        if (!promo.hasSelectedPackage) {
          promoClass = 'promo-element-small faded-promo';
        } else {
          promoClass = 'promo-element-small';
        }
      }
      state = promoClass;
    }

    if (type === 'isSelected') {
      let selected = false;
      if (this.selectedPromotion) {
        this.selectedPromotion.forEach((promotion: any) => {
          if (promo.id === promotion.id) {
            selected = true;
          }
        });
      }
      state = selected;
    }
    return state;
  }

  selectPromotion(event: any): void {
    if (!this.selectedPromotion) {
      this.selectedPromotion = [event];
    } else if (!this.selectedPromotion.find((pr: any) => pr.id === event.id)) {
      this.selectedPromotion = [...this.selectedPromotion, event];
    }

    this.selectedPromotionChange.emit(this.selectedPromotion);
  }
}
