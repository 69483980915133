import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatusHelperService {

  importStatusMap = {
    900: 'STAGING',
    901: 'STAGED',
    902: 'STAGING_ERROR',
    903: 'IMPORTING',
    904: 'IMPORTED',
    905: 'IMPORTING_ERROR',
    906: 'RETRY',
    907: 'POSTPROCESSED',
    908: 'COMPLETED'
  };

  constructor() { }

  getStatus(type: string): any {
    if (type === 'badge') {
      return {
        ACTIVE:           'badge-info',
        STARTED:          'badge-info',
        COMPLETED:        'badge-info',
        INACTIVE:         'badge-warning',
        PENDING:          'badge-warning',
        SUSPENDED:        'badge-danger',
        TERMINATED:       'badge-danger',
        RETIRED:          'badge-danger',
        EXPIRED:          'badge-danger',
        DELETED:          'badge-danger',
        FAILED:           'badge-danger',
        STAGING:          'badge-warning',
        STAGED:           'badge-info',
        STAGING_ERROR:    'badge-danger',
        IMPORTING:        'badge-warning',
        IMPORTED:         'badge-info',
        IMPORTING_ERROR:  'badge-danger',
        RETRY:            'badge-warning',
      };
    } else if (type === 'dropdown') {
      return [
        'ACTIVE',
        'STARTED',
        'COMPLETED',
        'INACTIVE',
        'PENDING',
        'SUSPENDED',
        'TERMINATED',
        'RETIRED',
        'EXPIRED',
        'DELETED',
        'FAILED'
      ];
    }
  }

  convertImportStatus(statusCode: string): any {
    return this.importStatusMap[statusCode];
  }
}
