<div class="panel-body">
  <div class="row">
    <div class="col-md-12" *ngIf="show">
      <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
      <div class="row">
        <table class="table table-pointable table-bordered" width="100%">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Url</th>
              <th>Username</th>
              <th>Status</th>
              <th>Protocol Type</th>
              <th>Credential Type</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let integrationServer of integrationServers">
              <td>{{integrationServer.id}}</td>
              <td>{{integrationServer.name}}</td>
              <td>{{integrationServer.url}}</td>
              <td>{{integrationServer.username}}</td>
              <td>{{integrationServer.status}}</td>
              <td>{{integrationServer.protocolType}}</td>
              <td>{{integrationServer.credentialType}}</td>
              <td>
                <div class="float-left actions inline-table">
                  <a class="btn btn-dark-blue btn-sm edit action"
                    (click)="integrationServerModal(integrationServer.id)">
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                  </a>
                  <button class="btn btn-attention btn-sm delete action"
                    [disabled]="integrationServer.status==='DELETED'"
                    (click)="deleteIntegrationServer(integrationServer.id)">
                    <fa-icon [icon]="faTrashAlt"></fa-icon>
                  </button>
                </div>
              </td>
            </tr>
            <tr app-is-there-more [numColumns]="8" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
      <app-add-more-data-to-list (onScroll)="getLocatons(true)" [lock]="lock" [isLoading]="isLoading">
      </app-add-more-data-to-list>
    </div>
  </div>
</div>