import { ConfirmDialogComponent } from './../confirm-dialog/confirm-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Filter } from 'src/app/data/class';
import { ResponseHelperService } from './../services/helpers/response-helper.service';
import { SponsorsService } from 'src/app/services/sponsors.service';
import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { faPlus, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { PromotionSponsorReminderModalComponent } from '../promotion-sponsor-reminder-modal/promotion-sponsor-reminder-modal.component';
import { PromotionSponsorReminder } from '../data/model';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-promotion-sponsor-reminders-tab',
  templateUrl: './promotion-sponsor-reminders-tab.component.html',
  styleUrls: ['./promotion-sponsor-reminders-tab.component.scss']
})
export class PromotionSponsorRemindersTabComponent implements OnInit, OnChanges {

  @Input() selectedPromSponsor: any;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  reminders: PromotionSponsorReminder[];
  lengthToCompare: number;
  lock = false;
  isLoading = false;
  lockedTables = false;
  limit = 20;
  filter = new Filter();

  // TODO: ???
  show = true;

  faPlus = faPlus;
  faEdit = faEdit;
  faTrash = faTrash;

  constructor(
    private sponsorsService: SponsorsService,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal
  ) { }

  ngOnInit(): void {
    this.initFilter();
    this.getReminders(false);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedPromSponsor && !changes.selectedPromSponsor.firstChange
      && changes.selectedPromSponsor.currentValue.id !== changes.selectedPromSponsor.previousValue.id) {
      this.reminders = null;
      this.initFilter();
      this.getReminders(false);
    }
  }

  initFilter(): void {
    this.limit = 20;
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getReminders(concat: boolean): void {
    this.isLoading = true;
    this.sponsorsService.getSponsorshipReminders(this.selectedPromSponsor.id).subscribe((data: any) => {
      if (concat) {
        this.reminders = this.reminders.concat(parseResponse(data));
      } else {
        this.reminders = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
    }, (data: any) => {
      console.log(data);
    });
  }

  removeReminder(reminderId: number): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent);
    instance.componentInstance.text = 'Remove reminder?';
    instance.componentInstance.header = 'Remove reminder';
    instance.componentInstance.successEvent.subscribe(() => {
      this.sponsorsService.deleteSponsorshipReminder(this.selectedPromSponsor.id, reminderId).subscribe((data: any) => {
        this.responseHelperService.success('Reminder successfully deleted', true);
        this.getReminders(false);
        instance.close();
      }, (data: any) => {
        this.responseHelperService.error(this, data.errorMsg);
      });
    });
  }

  reminderModal(promoSponsorReminder?: PromotionSponsorReminder): void {
    const instance = this.modalHelper.open(PromotionSponsorReminderModalComponent);
    instance.componentInstance.promotionSponsorshipId = this.selectedPromSponsor.id;
    if (promoSponsorReminder) {
      instance.componentInstance.item = promoSponsorReminder;
    }
    instance.componentInstance.successEvent.subscribe(() => {
      this.getReminders(false);
    });
  }

}
