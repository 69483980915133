<form>
  <div class="modal-header">
    <h1>Programs Associated to {{packageName}}</h1>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <ul class="list-group">        
        <li class="list-group-item pt-0 pb-0">
          <div class="row">               
            <div class="table mt-0">
              <table class='table table-pointable table-hover mb-0'>
                <thead>
                  <tr>
                    <th class="width-25">Code</th>
                    <th class="width-25 break-all">Name</th>
                    <th class="width-50">Description</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let program of programs">
                    <td class="break-all">{{program.code}}</td>
                    <td class="break-normal">{{program.name}}</td>
                    <td class="break-normal">{{program.description}}</td>
                  </tr>
                </tbody>
              </table>
            </div>   
          </div>
        </li>
      </ul>
    </div>
  </div>  
  <div class="modal-footer">
    <button class="btn btn-warning" type="button" (click)="cancel()"> Close </button>
  </div>
</form>
  