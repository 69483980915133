import { AppDataService } from './../app-data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CampaignService {

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  constructor(private http: HttpClient, private globalData: AppDataService) { }

  getAllCampaignsForSponsor(id: number, params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'campaigns/party/' + id, this.setParams(params));
  }

  getCampaign(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'campaigns/' + id);
  }

  getCampaignPromotions(id: number, partyId: number, limit: number, offsetParam?: number): any {
    const params: any = {limit , offset: offsetParam || 0};
    if (partyId){
      params.partyId = partyId;
    }
    return this.http.get<any>(this.globalData.baseUrl + 'campaigns/' + id + '/promotions',
      this.setParams(params));
  }

  getAllCampaigns(params?: any): any {
    if (params) {
      return this.http.get<any>(this.globalData.baseUrl + 'campaigns', this.setParams(params));
    }
    return this.http.get<any>(this.globalData.baseUrl + 'campaigns');
  }

  createOrUpdateCampaign(id: number, campaign: any): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'campaigns/' + id, campaign);
    }
    return this.http.post(this.globalData.baseUrl + 'campaigns', campaign);
  }

  deleteCampaign(id: number): any {
    return this.http.delete(this.globalData.baseUrl + 'campaigns/' + id);
  }
}
