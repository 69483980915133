import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppDataService } from '../app-data.service';
import { CommunicationsModalComponent } from '../communications-modal/communications-modal.component';
import { CommunicationsTestModalComponent } from '../communications-test-modal/communications-test-modal.component';
import { Tab } from '../data/class';
import { Communication, DeliveryProtocol } from '../data/model';
import { ChirpService } from '../services/chirp.service';
import { CommunicationsService } from '../services/communications.service';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { faPencilAlt, faClipboardCheck } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss']
})
export class CommunicationComponent implements OnInit {

  tabs: Tab[];
  selectedComm: Communication;
  commId: number;
  deliveryProtocols: DeliveryProtocol[];
  faPencilAlt = faPencilAlt;
  faClipboardCheck = faClipboardCheck;

  constructor(
    private title: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private communicationsService: CommunicationsService,
    private chirpService: ChirpService,
    private appDataService: AppDataService,
    private statusHelperService: StatusHelperService,
    private modalHelper: NgbModal,
    private globalData: AppDataService
  ) { }

  currentUser = this.globalData.username;
  statusHelper = this.statusHelperService.getStatus('badge');

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.params.id;
    if (id){
      this.communicationsService.getCommunication(id).subscribe((data: any) => {
        this.parseResponse(data);
      }, () => {
        this.router.navigate(['/communications']);
      });
    }

    this.tabs = [
      { title: 'Segments',           active: true  },
      { title: 'Attributes',         active: false },
      { title: 'Attachments',        active: false },
      { title: 'History',            active: false }
    ];
  }

  isSelected(name: string): boolean {
    const filteredTabs = new Array<Tab>();
    this.tabs.forEach((tab): void => {
      if (tab.title === name && tab.active) {
        filteredTabs.push(tab);
      }
    });

    if (filteredTabs.length > 0 && filteredTabs[0].active) {
      return true;
    } else {
      return false;
    }
  }

  select(name: string): void {
    this.tabs.forEach((tab) => {
      if (tab.title === name) {
        tab.active = true;
      } else {
        tab.active = false;
      }
    });
  }

  getCommunication(): void {
    this.communicationsService.getCommunication(this.activatedRoute.snapshot.params.id).subscribe((data: any) => {
      this.parseResponse(data);
    });
  }

  parseResponse(data: any): void {
    if (data.success) {
      this.selectedComm = data.entity;
      this.commId = this.selectedComm.id;
      this.title.setTitle(this.selectedComm.name);
      this.chirpService.getDeliveryProtocols(this.commId).subscribe((resp: any) => {
        this.deliveryProtocols = resp.entity;
      });
    }
  }

  edit(model: Communication): void {
    const instance = this.modalHelper.open(CommunicationsModalComponent);
    instance.componentInstance.communication = model;
    instance.componentInstance.successEvent.subscribe(() => {
      this.getCommunication();
      instance.close();
    });
  }

  test(model: Communication): void {
    const instance = this.modalHelper.open(CommunicationsTestModalComponent);
    instance.componentInstance.communication = model;
  }
}
