import { AppDataService } from 'src/app/app-data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Phone } from '../data/model';

@Injectable({
  providedIn: 'root'
})
export class PhoneService {

  constructor(
    private http: HttpClient,
    private globalData: AppDataService
  ) { }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getPartyPhones(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'phones/party/' + id);
  }

  createOrUpdatePhone(id: number, phone: Phone): any {
    if (id){
      return this.http.put<any>(this.globalData.baseUrl + 'phones/'  + id , phone);
    }
    return this.http.post<any>(this.globalData.baseUrl + 'phones/'  , phone);
  }

  deletePhone(phoneId: number, partyId: number): any {
    return this.http.delete<any>(this.globalData.baseUrl + 'phones/' +  phoneId, this.setParams({partyId}));
  }

  getPhone(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'phones/' + id);
  }

  getPhoneByIdAndParty(id: number, partyId: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'phones/' + id + '/party/' + partyId);
  }


  getPhoneTypes(): any {
    return this.http.get(this.globalData.baseUrl + 'phones/types');
  }
}
