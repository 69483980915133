import { SearchFilters } from 'src/app/data/class';
import { ProgramService } from './../services/program.service';
import { Campaign, SponsorshipGroup } from './../data/model';
import { CampaignService } from './../services/campaign.service';
import { SponsorsService } from './../services/sponsors.service';
import { Filter } from './../data/class';
import { StatusHelperService } from './../services/helpers/status-helper.service';
import { Component, OnInit, Input } from '@angular/core';
import * as $ from 'jquery';
import {
  faUserPlus,
  faCircle,
  faObjectGroup,
  faCog,
} from '@fortawesome/free-solid-svg-icons';
import { AddSponsorshipModalComponent } from '../add-sponsorship-modal/add-sponsorship-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-sponsorship-groups-tab',
  templateUrl: './sponsorship-groups-tab.component.html',
  styleUrls: ['./sponsorship-groups-tab.component.scss'],
})
export class SponsorshipGroupsTabComponent implements OnInit {
  @Input() personId: number;
  @Input() sponsorName: string;

  lockedTables = false;
  lock = false;
  isLoading = false;
  select: any = {};
  selectedCampaigns = null;
  offset: number;
  pLimit = 20;
  pOffset: number;
  colorCnt = -1;
  defaultStatuses = ['ACTIVE', 'PENDING', 'STARTED'];
  filter = new Filter();
  searchFilters: SearchFilters;
  colors = [
    'program-card-bottom-yellow',
    'program-card-bottom-red',
    'program-card-bottom-blue',
    'program-card-bottom-green',
  ];
  sponsorshipGroups: SponsorshipGroup[] = [];
  lengthToCompare: number;
  limit = 20;
  showMoreButton: any = {};
  selectedGroup: SponsorshipGroup;
  campaigns: Campaign[];
  faUserPlus = faUserPlus;
  faCircle = faCircle;
  faObjectGroup = faObjectGroup;
  faCog = faCog;
  showSearch = false;

  constructor(
    private statusHelper1: StatusHelperService,
    private sponsorsService: SponsorsService,
    private campaignService: CampaignService,
    private modalHelper: NgbModal,
    private programService: ProgramService,
    private toastr: ToastrService,
    private router: Router
  ) {}

  statusHelper = this.statusHelper1.getStatus('badge');

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'sponsorship_group_search',
      filters: [
        {
          name: 'search',
          placeholder: 'Sponsorship Tag...',
        },
        {
          name: 'campaigns',
          type: 'array',
          placeholder: 'Campaigns...',
          nameProp: 'name',
          valueProp: 'name',
          inputType: 'select',
          multiple: true,
        },
      ],
      addEntityButtons: [
        {
          icon: faUserPlus,
          callback: () => {
            this.openPromotionModal();
          },
          buttonText: 'Add Sponsorship',
          title: 'Add Sponsorship',
          if: true,
        },
      ],
      searchCallback: () => {
        this.initNewSearch(true);
      },
    };
    this.getAllCampaigns();
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  initNewSearch(firstCall:boolean = false): void {
    this.initFilter();
    this.getSponsorshipGroups(false, firstCall);
  }

  applyFilter(): void {
    this.searchFilters.applyFilters();
  }

  clearAllFilters(): void {
    this.searchFilters.clearAll();
  }

  getSponsorshipGroups(concat: boolean, firstCall: boolean = false): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    if(!firstCall) this.isLoading = true;
    this.sponsorsService
      .getSponsorshipGroups(this.personId, this.filter)
      .subscribe(
        (data: any) => {
          if (data.success) {
            this.colorCnt = -1;
            parseResponse(data).forEach((grp: any) => {
              grp.color = this.getNextColor();
            });
          }
          if (concat) {
            this.sponsorshipGroups = this.sponsorshipGroups.concat(
              parseResponse(data)
            );
          } else {
            this.campaigns = null;
            this.sponsorshipGroups = parseResponse(data);
          }
          this.lengthToCompare = parseResponse(data).length;
          this.filter.limit += this.limit;
          this.lockedTables = parseResponse(data).length !== this.limit;
          this.lock = this.lockedTables;
          this.isLoading = false;
        },
        () => {
          this.lockedTables = false;
          this.lock = false;
          this.isLoading = false;
          this.toastr.error('Error ocuured!');
        }
      );
  }

  getNextColor(): string {
    if (this.colorCnt > 2) {
      this.colorCnt = -1;
    }
    this.colorCnt++;
    return this.colors[this.colorCnt];
  }

  getSponsorshipGroupCampaigns(
    partyId: number,
    group: SponsorshipGroup,
    e: any
  ): void {
    $('tr.active').removeClass('active');
    $(e.currentTarget).toggleClass('active');
    this.selectedGroup = group;

    this.campaignService
      .getAllCampaignsForSponsor(partyId, {
        sponsorshipTag: group.sponsorshipTag,
      })
      .subscribe((data: any) => {
        if (data.success) {
          this.campaigns = parseResponse(data);
        }
      });
  }

  getAllCampaigns(): void {
    this.campaignService.getAllCampaigns().subscribe((data: any) => {
      if (data.success) {
        this.searchFilters.filters[1].choices = parseResponse(data);
        this.showSearch = true;
      }
    });
  }

  navigateToGroup(sponsorshipTag: string): void {
    //'/sponsors/{{ personId }}/packaged-sponsorship/{{
    //         sponsorshipGroups[i].sponsorshipTag
    //         }}
    var encodedTag = encodeURIComponent(sponsorshipTag);
    var groupUrl =
      '/sponsors/' + this.personId + '/packaged-sponsorship/' + encodedTag;
    this.router.navigate([groupUrl]);
  }

  clearCampaigns(name: string): void {
    if (name) {
      // this should only return one
      const campaignsToRemove = this.select.selectedCampaigns.filter(
        (camp: any) => {
          return camp.name === name;
        }
      );

      // but we'll remove them all anyways
      campaignsToRemove.forEach((toRemove) => {
        const toRemoveIndex = this.select.selectedCampaigns.indexOf(toRemove);
        this.select.selectedCampaigns.splice(toRemoveIndex, 1);
      });
    } else {
      this.select.selectedCampaigns = [];
    }
  }

  openPromotionModal(size?: string): void {
    this.filter = new Filter();
    this.filter.statuses = this.defaultStatuses;

    this.programService.getAllPrograms(this.filter).subscribe((data: any) => {
      if (data.success) {
        const instance = this.modalHelper.open(AddSponsorshipModalComponent, {
          windowClass: 'wider-modal-window',
          size,
        });
        instance.componentInstance.personId = this.personId;
        instance.componentInstance.sponsorName = this.sponsorName;
        instance.componentInstance.filter = { statuses: this.defaultStatuses };
        instance.componentInstance.item = {
          data: parseResponse(data),
          type: 'program',
        };
        instance.componentInstance.successEvent.subscribe(() => {
          this.getSponsorshipGroups(false);
          instance.close();
        });
      }
    });
  }
}
