import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Filter, Choice, SearchFilters } from '../../data/class';
import { TableHelperService } from '../../services/helpers/table-helper.service';
import { IntegrationServer } from '../../data/model';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faPlus, faTimes, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { IntegrationServerService } from 'src/app/services/integration-server.service';
import { IntegrationServerModalComponent } from '../integration-server-modal/integration-server-modal.component';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-integration-servers',
  templateUrl: './integration-servers.component.html',
  styleUrls: ['./integration-servers.component.scss']
})
export class IntegrationServersComponent implements OnInit {

  lockedTables: boolean;
  isLoading: boolean;
  lock: boolean;
  filteredStatuses: any[] = [];
  filter = new Filter();
  limit = 20;
  statuses: Choice[];
  integrationServers: IntegrationServer[] = [];
  hierarchySources: any[] = [];
  lengthToCompare: number;
  show: boolean;
  faPlus = faPlus;
  faTimes = faTimes;
  faPencilAlt = faPencilAlt;
  faTrashAlt = faTrashAlt;
  searchFilters: SearchFilters;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private tableHelperService: TableHelperService,
    private integrationServerService: IntegrationServerService,
    private modalHelper: NgbModal,
    private responseHelperService: ResponseHelperService,
    private toastr: ToastrService,
  ) { }

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'integration-servers-search',
      searchCallback: () => { this.initNewSearch(); },
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...'
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        }
      ], addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'New Integration Server',
          if: true,
          callback: () => { this.integrationServerModal(); },
          title: 'New Integration Server'
        },
      ],
    };
    this.initFilter();
    this.getStatuses();
    this.show = true;
  }

  initNewSearch(): void {
    this.initFilter();
    this.getIntegrationServers(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  integrationServerModal(id?: number): void {
    if (id) {
      this.integrationServerService.getIntegrationServer(id).subscribe((data: any) => {
        if (data.success) {
          this.openModal(data.entity);
        }
      });
    } else {
      this.openModal();
    }
  }

  openModal(integrationServer?: IntegrationServer): void {
    const instance = this.modalHelper.open(IntegrationServerModalComponent);
    instance.componentInstance.integrationServer = integrationServer;
    instance.componentInstance.hierarchySources = this.hierarchySources;
    instance.componentInstance.successEvent.subscribe(data => {
      if (data.success) {
        this.toastr.success(`Integration server successfully ${instance.componentInstance.isEdit ? 'updated' : 'created'}`);
        this.initFilter();
        this.getIntegrationServers(false);
        instance.close();
      }
    });
  }

  getIntegrationServers(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.isLoading = true;
    this.integrationServerService.getIntegrationServers(this.filter).subscribe((data: any) => {
      if (concat) {
        this.integrationServers = this.integrationServers.concat(parseResponse(data));
      } else {
        this.integrationServers = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.isLoading = false;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
    }, () => {
      this.lockedTables = false;
      this.lock = false;
      this.isLoading = false;
      this.toastr.error('Error occured!');
    });
  }

  deleteIntegrationServer(id: number): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent);
    instance.componentInstance.text = 'Delete integration server with ID: ' + id;
    instance.componentInstance.header = 'Delete integration server?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.integrationServerService.deleteIntegrationServer(id).subscribe(() => {
        this.responseHelperService.success('IntegrationServer successfully removed', true);
        this.integrationServerService.getIntegrationServer(id).subscribe((data: any) => {
          if (data.success) {
            this.integrationServers[this.integrationServers.findIndex(e => e.id === id)] = data.entity;
          }
        });
      }, (data: any) => {
        this.toastr.error(data.error.error);
      });
    });
  }

  setStatuses(): void {
    this.initFilter();
    this.filter.statuses = this.tableHelperService.extractData(this.filteredStatuses);
    if (!this.filter.statuses) {
      delete this.filter.statuses;
    }
    this.getIntegrationServers(false);
  }

}
