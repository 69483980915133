import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Segment } from '../data/model';
import { PersonsService } from '../services/persons.service';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-segment-filter',
  templateUrl: './segment-filter.component.html',
  styleUrls: ['./segment-filter.component.scss']
})
export class SegmentFilterComponent implements OnInit {

  @Input() class: string;
  @Input() ngbDropdownClass = 'btn-group filter';
  @Input() maxWidth;
  @Output() filterSegments: EventEmitter<any> = new EventEmitter(true);
  @Input() personId: number;
  segmentsFetched = false;
  filtered: Segment[];
  allSegments: Segment[];
  toFilter: number[] = [];
  toRemove: number[];
  toAdd: number[];
  query = '';
  classValue: string;
  subject: Subject<any> = new Subject<any>();
  faChevronDown = faChevronDown;

  constructor(
    private personsService: PersonsService
  ) { }

  ngOnInit(): void {
    this.filtered = [];
    this.toFilter = [];
    const self = this;

    this.classValue = this.class ? this.class + ' font-relative-width' : 'col-md-2 font-relative-width';

    this.subject.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe((item) => {
        this.filtered = [];
        if (item && item !== '' && item.length > 2) {
          // Add all that have code as filter
          this.allSegments.forEach((segment: Segment) => {
            if (segment.code && segment.code.toUpperCase().indexOf(item.toUpperCase()) !== -1) {
              this.filtered.push(segment);
            }
          });
          // Add parents
          for (let i = this.allSegments.length - 1; i >= 0; i--) {
            if (isParent(this.allSegments[i].id)) {
              const idx = getIdx(this.allSegments[i].id);
              this.filtered.splice(idx, 0, this.allSegments[i]);
            }
          }
        } else {
          this.filtered = this.allSegments;
        }

        function getIdx(segmentId: number): number {
          const indexs = [];
          self.filtered.forEach((seg: Segment, idx: number) => {
            if (seg.parentId === segmentId) {
              indexs.push(idx);
            }
          });
          return Math.min.apply(null, indexs);
        }

        // inArray added for returning only parents that are not in array already
        function isParent(id: number): boolean {
          let parent = false;
          let inArray = true;
          self.filtered.forEach((segment: Segment) => {
            if (segment.parentId === id) {
              parent = true;
            }
            if (segment.id === id) {
              inArray = false;
            }
          });
          return parent && inArray;
        }
      });
  }

  getClass(level: number): string {
    return 'level_' + level;
  }

  getAllSegments(): void {
    if (!this.segmentsFetched) {
      this.personsService.getAllSegmentsNodes(this.personId, false).subscribe((data: any) => {
        if (data.success) {
          this.allSegments = parseResponse(data);
          this.filtered = parseResponse(data);
          this.segmentsFetched = true;
          // scope.$applyAsync();
        }
      });
    }
  }

  addSegments(model: Segment): void {
    if (model.checked) {
      this.addChildren(model);
    } else {
      this.removeChildren(model);
    }
  }

  clearAll(): void {
    if (this.allSegments) {
      this.filtered = this.allSegments;
      this.filtered.forEach((seg: Segment) => {
        if (seg.checked) {
          seg.checked = !seg.checked;
        }
      });
      this.toFilter = [];
      this.toRemove = [];
      this.toAdd = [];
      this.query = '';
      this.filterSegments.emit(this.toFilter);
    }
  }

  addChildren(model: Segment): void {
    this.toAdd = [];
    this.toAdd.push(model.id);
    this.allSegments.forEach((item) => {
      if (this.toAdd.indexOf(item.parentId) !== -1 && this.toFilter.indexOf(item.id) === -1) {
        item.checked = model.checked;
        this.toAdd.push(item.id);
      }
    });
    this.toFilter = this.toFilter.concat(this.toAdd);
    this.filterSegments.emit(this.toFilter);
  }

  removeChildren(model: Segment): void {
    this.toRemove = [];
    this.toRemove.push(model.id);
    this.allSegments.forEach((item: Segment) => {
      if (this.toRemove.indexOf(item.parentId) !== -1 && this.toFilter.indexOf(item.id) !== -1) {
        item.checked = model.checked;
        this.toRemove.push(item.id);
      }
    });
    this.toRemove.forEach((remove) => {
      const idx = this.toFilter.indexOf(remove);
      this.toFilter.splice(idx, 1);
    });
    this.filterSegments.emit(this.toFilter);
  }

  applyFilter(query: string): void {
    this.subject.next(query);
  }
}
