<form [formGroup]="newIntegrationForm">
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Name</label>
          <input formControlName="name" type="text" class="form-control" name="name">
          <div class="mt-sm" *ngIf="!newIntegrationForm.controls.name.valid">
            <small class="text-danger">Please input name</small>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Customer Name</label>
          <select formControlName="customerName" class="form-control">
            <option *ngFor="let customer of customers" value="{{customer.name}}">{{customer.name}}</option>
          </select>
          <div class="mt-sm" *ngIf="!newIntegrationForm.controls.customerName.valid">
            <small class="text-danger">Select customer name</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Import Type</label>
          <select formControlName="importType" class="form-control">
            <option *ngFor="let importType of importTypes" value="{{importType}}">{{importType}}</option>
          </select>
          <div class="mt-sm" *ngIf="!newIntegrationForm.controls.importType.valid">
            <small class="text-danger">Select import type</small>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>File Type</label>
          <select formControlName="fileType" class="form-control">
            <option *ngFor="let fileType of fileTypes" value="{{fileType}}">{{fileType}}</option>
          </select>
          <div class="mt-sm" *ngIf="!newIntegrationForm.controls.fileType.valid">
            <small class="text-danger">Select file type</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>File Layout Schema</label>
          <input formControlName="fileLayoutSchema" type="text" class="form-control" name="fileLayoutSchema">
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>File Name Pattern</label>
          <input formControlName="fileNamePattern" type="text" class="form-control" name="fileNamePattern">
          <div class="mt-sm" *ngIf="!newIntegrationForm.controls.fileNamePattern.valid">
            <small class="text-danger">Please input a file name pattern</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Record Delimiter</label>
          <input formControlName="recordDelimiter" type="text" class="form-control" name="recordDelimiter">
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Status</label>
          <select formControlName="status" class="form-control">
            <option *ngFor="let status of statuses" value="{{status}}">{{status}}</option>
          </select>
          <div class="mt-sm" *ngIf="!newIntegrationForm.controls.status.valid">
            <small class="text-danger">Select status</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Date Format</label>
          <input formControlName="dateFormat" type="text" class="form-control" name="dateFormat">
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Field Count</label>
          <input formControlName="fieldCount" type="text" class="form-control" name="fieldCount">
          <div class="mt-sm" *ngIf="!newIntegrationForm.controls.fieldCount.valid">
            <small class="text-danger">Please input a field count</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Activation Realm</label>
          <input formControlName="activationRealm" type="text" class="form-control" name="activationRealm">
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Post Upload Job Name</label>
          <input formControlName="postUploadJobName" type="text" class="form-control" name="postUploadJobName" >
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Preprocessing Job</label>
          <input formControlName="preprocessJob" type="text" class="form-control" name="preprocessJob">
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Postprocessing Job</label>
          <input formControlName="postprocessJob" type="text" class="form-control" name="postprocessJob">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Integration Server</label>
          <ng-select [items]="servers" bindLabel="name" formControlName="server" [clearable]="true"></ng-select>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Integration Key</label>
          <ng-select [items]="keys" bindLabel="name" formControlName="key" [clearable]="true"></ng-select>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Ignore Header</label>
          <input formControlName="ignoreHeader" type="text" class="form-control" name="ignoreHeader" appNumbersOnly>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Ignore Trailer</label>
          <input formControlName="ignoreTrailer" type="text" class="form-control" name="ignoreTrailer" appNumbersOnly>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Encoding Charset</label>
          <input formControlName="encodingCharset" type="text" class="form-control" name="encodingCharset">
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label>Record Length</label>
          <input formControlName="recordLength" type="text" class="form-control" name="recordLength" appNumbersOnly>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <div class="checkbox checkbox-success">
            <input formControlName="addsAndUpdates" id="addsAndUpdates" class="mr-2" type="checkbox"
              name="addsAndUpdates">
            <label for="addsAndUpdates">
              Import Adds and Updates Only
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <div class="checkbox checkbox-success">
            <input formControlName="autoImport" id="autoImport" class="mr-2" type="checkbox" name="autoImport">
            <label for="autoImport">
              Auto-Import After Stage
            </label>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="integration">
      <div class="col-md-12">
        <div class="form-group">
          <div class="checkbox checkbox-success">
            <input formControlName="activateOnImport" id="activateOnImport" class="mr-2" type="checkbox"
              name="activateOnImport">
            <ng-container *ngIf="newIntegrationForm.controls.importType.value !== 'Transaction'">
              <label for="activateOnImport">
                Activate on Import
              </label>
            </ng-container>
            <ng-container *ngIf="newIntegrationForm.controls.importType.value === 'Transaction'">
              <label for="activateOnImport">
                Auto-process after Import
              </label>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <div class="checkbox checkbox-success">
            <input formControlName="acceptUploads" id="acceptUploads" class="mr-2" type="checkbox" name="acceptUploads">
            <label for="acceptUploads">
              Accept Uploads
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" [disabled]="!newIntegrationForm.valid" (click)="ok()" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>
