<div class="wrapper wrapper-content">
  <div class="ibox">
    <div class="row">
      <div class="col-md-6 border-right">
        <fieldset [disabled]="templatedConfig">
          <h3>Reward Rules Configuration Builder</h3>
          <div class="row">
            <div class="col-lg-12">
              <div class="ibox border-bottom">
                <div class="ibox-title collapse-link" (click)="toggleOpen()">
                  <h5 title="General information used by the system to identify a configuration.">Reference Information (Promotion: {{promotion.code}})</h5>
                  <div class="ibox-tools">
                    <a class="">
                      <fa-icon [icon]="faChevronDown"  [hidden]="!showRefSection"></fa-icon>
                      <fa-icon [icon]="faChevronUp" [hidden]="showRefSection"></fa-icon>
                    </a>
                  </div>
                </div>
                <form method="get" class="form-horizontal" [hidden]="!showRefSection">
                  <div class="ibox-content">
                    <div class="form-group">
                      <label class="col-sm-3 control-label-left">Configuration Reference</label>
                      <div class="col-sm-6">
                        <input type="text" class="form-control m-b" (change)="updateConfigRef()" [(ngModel)]="ruleSource.configRef" name="configRef" />
                      </div>
                    </div>
                    <div class="form-group" *ngIf="promotionConfiguration.promotionTemplateId && promotionTemplate">
                      <label class="col-sm-3 control-label-left">Template Name</label>
                      <div class="col-sm-6">
                        <input type="text" class="form-control m-b" [(ngModel)]="promotionTemplate.name" disabled="true" name="templateName" />
                      </div>
                    </div>
                    <div class="form-group">
                      <label class="col-sm-3 control-label-left">Sponsor Name</label>
                      <div class="col-sm-6">
                        <input type="text" class="form-control m-b" [(ngModel)]="ruleSource.sponsorName" disabled="true" name="sponsorName" />
                      </div>
                    </div>
                    <div class="form-group">
                      <div class="col-sm-6">
                        <div class="checkbox checkbox-success">
                         <input id="autoRedeem" [(ngModel)]="ruleSource.autoRedeem" type="checkbox" class="m-b mr-2" name="autoRedeem">
                          <label for="autoRedeem">Auto Redeem</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="ibox border-bottom">
                <app-config-section title="Activity/Event Matching" (inputChangeEmitter)="toggleSection($event)" sectionName="triggers"></app-config-section>
                <div [hidden]="!showTriggers">
                  <div class="ibox-content">
                  <div class="form-group">
                    <label>To what type of event should these rules apply?</label>
                    <select class="form-control" [(ngModel)]="rewardTrigger"
                            (ngModelChange)="setRewardTrigger(rewardTrigger)">
                      <option value="primaryBehavior" [selected]="rewardTrigger === 'primaryBehavior'">Receipt of An Activity/Behavior</option>
                      <option value="rewardedBehavior" [selected]="rewardTrigger === 'rewardedBehavior'">Generation of a Reward by another Ruleset</option>
                    </select>
                    <activity-trigger *ngIf="rewardTrigger === 'primaryBehavior'"
                                      [triggers]="getObjectFromPath('ruleSource.triggers')"
                                      (inputChangeEmitter)="inputPrimaryBehaviorChangeHandler($event)"></activity-trigger>
                    <reward-trigger *ngIf="rewardTrigger === 'rewardedBehavior'"
                                    [triggers]="getObjectFromPath('ruleSource.triggers')"
                                    (inputChangeEmitter)="inputPrimaryBehaviorChangeHandler($event)"></reward-trigger>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="ibox border-bottom">
                <form>
                <app-config-section  (inputChangeEmitter)="toggleSection($event)" title="Member Eligibility Restrictions" sectionName="memberEligibilityRestrictions"></app-config-section>
                <div [hidden]="!showEligibilityRestrictions">
                  <div class="ibox-content">
                    <app-party-attribute-restriction
                      [partyAttributeRestriction]="getObjectFromPath('ruleSource.restrictions.eligibility.partyAttributeRestriction')"
                      (inputChangeEmitter)="inputEligibilityRestrictionChangeHandler($event, 'partyAttributeRestriction')"></app-party-attribute-restriction>
                  </div>
                  <div class="ibox-content">
                    <app-age-restriction
                      [ageRestriction]="getObjectFromPath('ruleSource.restrictions.eligibility.ageRestriction')"
                      (inputChangeEmitter)="inputEligibilityRestrictionChangeHandler($event, 'ageRestriction')"></app-age-restriction>
                  </div>
                  <div class="ibox-content">
                    <app-gender-restriction
                      [genderRestriction]="getObjectFromPath('ruleSource.restrictions.eligibility.genderRestriction')"
                      (inputChangeEmitter)="inputEligibilityRestrictionChangeHandler($event, 'genderRestriction')"></app-gender-restriction>
                  </div>
                </div>
                </form>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="ibox border-bottom">
                <app-config-section  (inputChangeEmitter)="toggleSection($event)" title="Activity Eligibility Restrictions" sectionName="activityEligibilityRestrictions"></app-config-section>
                <div *ngIf="showActivityRestrictions">
                  <div class="ibox-content">
                    <app-activity-range-restriction
                      [restrictionDateString]="getObjectFromPath('ruleSource.restrictions.behavioral.activityRangeStart')"
                      [restrictionType]="'activityRangeStart'"
                      [title]="'The Activity Date must be after or equal to:'"
                      (inputChangeEmitter)="inputActivityRestrictionChangeHandler($event, 'activityRangeStart')">
                    </app-activity-range-restriction>
                    <app-activity-range-restriction
                      [restrictionDateString]="getObjectFromPath('ruleSource.restrictions.behavioral.activityRangeEnd')"
                      [restrictionType]="'activityRangeEnd'"
                      [title]="'The Activity Date must be before:'"
                      (inputChangeEmitter)="inputActivityRestrictionChangeHandler($event, 'activityRangeEnd')">
                    </app-activity-range-restriction>
                  </div>
                    <div class="ibox-content">
                      <app-activity-window-restriction
                        [activityWindow]="getObjectFromPath('ruleSource.restrictions.behavioral.activityWindow')"
                        (inputChangeEmitter)="inputActivityRestrictionChangeHandler($event, 'activityWindow')">
                      </app-activity-window-restriction>
                    </div>
                  <div class="ibox-content">
                    <app-activity-age-restriction
                      [activityAge]="getObjectFromPath('ruleSource.restrictions.behavioral.activityAge')"
                      (inputChangeEmitter)="inputActivityRestrictionChangeHandler($event, 'activityAge')">
                    </app-activity-age-restriction>
                  </div>
                  <div class="ibox-content">
                    <app-activity-amount-match-restriction
                      [amountMatch]="getObjectFromPath('ruleSource.restrictions.behavioral.amountMatch')"
                      (inputChangeEmitter)="inputActivityRestrictionChangeHandler($event, 'amountMatch')">
                    </app-activity-amount-match-restriction>
                  </div>
                  <div class="ibox-content">
                    <app-activity-amount-range-restriction
                      [amountRange]="getObjectFromPath('ruleSource.restrictions.behavioral.amountRange')"
                      (inputChangeEmitter)="inputActivityRestrictionChangeHandler($event, 'amountRange')">
                    </app-activity-amount-range-restriction>
                  </div>
                  <div class="ibox-content">
                    <app-activity-attribute-restriction
                      [activityAttributeRestriction]="getObjectFromPath('ruleSource.restrictions.behavioral.activityAttributeRestriction')"
                      (inputChangeEmitter)="inputActivityRestrictionChangeHandler($event, 'activityAttributeRestriction')">
                    </app-activity-attribute-restriction>
                  </div>
                  <div class="ibox-content">
                    <app-party-attribute-interval-restriction
                      [partyAttributeIntervalRestriction]="getObjectFromPath('ruleSource.restrictions.behavioral.partyAttributeInterval')"
                      (inputChangeEmitter)="inputActivityRestrictionChangeHandler($event, 'partyAttributeInterval')">
                    </app-party-attribute-interval-restriction>
                  </div>
                  <div class="ibox-content">
                    <app-activity-attribute-interval-restriction
                      [activityAttributeIntervalRestriction]="getObjectFromPath('ruleSource.restrictions.behavioral.activityAttributeInterval')"
                      (inputChangeEmitter)="inputActivityRestrictionChangeHandler($event, 'activityAttributeInterval')">
                    </app-activity-attribute-interval-restriction>
                  </div>
                  <div class="ibox-content">
                    <app-party-relationship-restriction
                      [relationshipToLocation]="getObjectFromPath('ruleSource.restrictions.behavioral.relationshipToLocation')"
                      [partyRoles]="partyRoles"
                      [partyRelationships]="partyRelationships"
                      (inputChangeEmitter)="inputActivityRestrictionChangeHandler($event, 'relationshipToLocation')">
                    </app-party-relationship-restriction>
                  </div>
                  <div class="ibox-content">
                    <app-location-attribute-restriction
                      [locationAttributeRestriction]="getObjectFromPath('ruleSource.restrictions.behavioral.locationAttributeRestriction')"
                      (inputChangeEmitter)="inputActivityRestrictionChangeHandler($event, 'locationAttributeRestriction')">
                    </app-location-attribute-restriction>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="ibox border-bottom">
                <app-config-section  (inputChangeEmitter)="toggleSection($event)" title="Thresholds for Previous Behavior" sectionName="thresholds"></app-config-section>
                <div [hidden]="!showThresholds">
                  <div class="ibox-content">
                    <app-behavior-count-threshold
                      [behaviorCountThreshold]="getObjectFromPath('ruleSource.schedules.thresholds.behaviorCountThreshold')"
                      (inputChangeEmitter)="inputThresholdsChangeHandler($event, 'behaviorCountThreshold')">
                    </app-behavior-count-threshold>
                  </div>
                  <div class="ibox-content">
                    <app-attribute-interval-behavior-count-threshold
                      [attrIntervalBehaviorCountThreshold]="getObjectFromPath('ruleSource.schedules.thresholds.attrIntervalBehaviorCountThreshold')"
                      (inputChangeEmitter)="inputThresholdsChangeHandler($event, 'attrIntervalBehaviorCountThreshold')">
                    </app-attribute-interval-behavior-count-threshold>
                  </div>
                  <div class="ibox-content">
                    <app-reward-count-threshold
                      [rewardCountThreshold]="getObjectFromPath('ruleSource.schedules.thresholds.rewardCountThreshold')"
                      (inputChangeEmitter)="inputThresholdsChangeHandler($event, 'rewardCountThreshold')">
                    </app-reward-count-threshold>
                  </div>
                  <div class="ibox-content">
                    <app-attribute-interval-reward-count-threshold
                      [attrIntervalRewardCountThreshold]="getObjectFromPath('ruleSource.schedules.thresholds.attrIntervalRewardCountThreshold')"
                      (inputChangeEmitter)="inputThresholdsChangeHandler($event, 'attrIntervalRewardCountThreshold')">
                    </app-attribute-interval-reward-count-threshold>
                  </div>
                  <div class="ibox-content">
                    <app-reward-amount-threshold
                      [rewardAmountThreshold]="getObjectFromPath('ruleSource.schedules.thresholds.rewardAmountThreshold')"
                      (inputChangeEmitter)="inputThresholdsChangeHandler($event, 'rewardAmountThreshold')">
                    </app-reward-amount-threshold>
                  </div>
                  <div class="ibox-content">
                    <app-tender-amount-threshold
                      [tenderAmountThreshold]="getObjectFromPath('ruleSource.schedules.thresholds.tenderAmountThreshold')"
                      (inputChangeEmitter)="inputThresholdsChangeHandler($event, 'tenderAmountThreshold')">
                    </app-tender-amount-threshold>
                  </div>
                  <div class="ibox-content">
                    <app-count-match-threshold
                      [countMatchThreshold]="getObjectFromPath('ruleSource.schedules.thresholds.countMatchThreshold')"
                      (inputChangeEmitter)="inputThresholdsChangeHandler($event, 'countMatchThreshold')">
                    </app-count-match-threshold>
                  </div>
                  <div class="ibox-content">
                    <app-candidate-communication-count-threshold
                      [commCandidateThreshold]="getObjectFromPath('ruleSource.schedules.thresholds.commCandidateThreshold')"
                      (inputChangeEmitter)="inputThresholdsChangeHandler($event, 'commCandidateThreshold')">
                    </app-candidate-communication-count-threshold>
                  </div>
                  <div class="ibox-content">
                    <app-gatekeeper-threshold
                      [gatekeepers]="getObjectFromPath('ruleSource.schedules.thresholds.gatekeepers')"
                      (inputChangeEmitter)="inputThresholdsChangeHandler($event, 'gatekeepers')">
                    </app-gatekeeper-threshold>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="ibox border-bottom">
                <app-config-section  (inputChangeEmitter)="toggleSection($event)" title="Caps and Maximums" sectionName="caps"></app-config-section>
                <div [hidden]="!showCaps">
                  <div class="ibox-content">
                    <app-behavior-count-cap
                      [behaviorCountCap]="getObjectFromPath('ruleSource.schedules.caps.behaviorCountCap')"
                      (inputChangeEmitter)="inputCapsChangeHandler($event, 'behaviorCountCap')">
                    </app-behavior-count-cap>
                  </div>
                  <div class="ibox-content">
                    <app-reward-count-cap
                      [countCap]="getObjectFromPath('ruleSource.schedules.caps.countCap')"
                      (inputChangeEmitter)="inputCapsChangeHandler($event, 'countCap')">
                    </app-reward-count-cap>
                  </div>
                  <div class="ibox-content">
                    <app-attribute-interval-count-cap
                      [attrIntervalCountCap]="getObjectFromPath('ruleSource.schedules.caps.attrIntervalCountCap')"
                      (inputChangeEmitter)="inputCapsChangeHandler($event, 'attrIntervalCountCap')">
                    </app-attribute-interval-count-cap>
                  </div>
                  <div class="ibox-content">
                    <app-reward-earn-cap
                      [earnCap]="getObjectFromPath('ruleSource.schedules.caps.earnCap')"
                      (inputChangeEmitter)="inputCapsChangeHandler($event, 'earnCap')">
                    </app-reward-earn-cap>
                  </div>
                  <div class="ibox-content">
                    <app-per-behavior-count-cap
                      [perBehaviorCountCap]="getObjectFromPath('ruleSource.schedules.caps.perBehaviorCountCap')"
                      (inputChangeEmitter)="inputCapsChangeHandler($event, 'perBehaviorCountCap')">
                    </app-per-behavior-count-cap>
                  </div>
                  <div class="ibox-content">
                    <app-candidate-communication-cap
                      [commCandidateCap]="getObjectFromPath('ruleSource.schedules.caps.commCandidateCap')"
                      (inputChangeEmitter)="inputCapsChangeHandler($event, 'commCandidateCap')">
                    </app-candidate-communication-cap>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="ibox border-bottom">
                <app-config-section  (inputChangeEmitter)="toggleSection($event)" title="Consequences" sectionName="consequences"></app-config-section>
                <div [hidden]="!showConsequences">
                  <div class="ibox-content">
                    <app-fixed-reward-consequence
                      [fixedReward]="getObjectFromPath('ruleSource.consequences.fixedReward')"
                      [rewardTypes]="rewardTypes"
                      (inputChangeEmitter)="inputConsequencesChangeHandler($event, 'fixedReward')">
                    </app-fixed-reward-consequence>
                  </div>
                  <div class="ibox-content">
                    <app-percentage-reward-consequence
                      [percentOfValue]="getObjectFromPath('ruleSource.consequences.percentOfValue')"
                      (inputChangeEmitter)="inputConsequencesChangeHandler($event, 'percentOfValue')">
                    </app-percentage-reward-consequence>
                  </div>
                  <div class="ibox-content">
                    <app-stepped-reward-consequence
                      [steppedReward]="getObjectFromPath('ruleSource.consequences.steppedReward')"
                      [rewardTypes]="rewardTypes"
                      (inputChangeEmitter)="inputConsequencesChangeHandler($event, 'steppedReward')">
                    </app-stepped-reward-consequence>
                  </div>
                  <div class="ibox-content">
                    <app-add-attribute-consequence
                      [addAttribute]="getObjectFromPath('ruleSource.consequences.addAttribute')"
                      (inputChangeEmitter)="inputConsequencesChangeHandler($event, 'addAttribute')">
                    </app-add-attribute-consequence>
                  </div>
                  <div class="ibox-content">
                    <app-remove-attribute-consequence
                      [removeAttribute]="getObjectFromPath('ruleSource.consequences.removeAttribute')"
                      (inputChangeEmitter)="inputConsequencesChangeHandler($event, 'removeAttribute')">
                    </app-remove-attribute-consequence>
                  </div>
                  <div class="ibox-content">
                    <app-candidate-communication-consequence
                      [sendCommunication]="getObjectFromPath('ruleSource.consequences.sendCommunication')"
                      [communications]="communications"
                      [communicationTypes]="communicationTypes"
                      (inputChangeEmitter)="inputConsequencesChangeHandler($event, 'sendCommunication')">
                    </app-candidate-communication-consequence>
                  </div>
                  <div class="ibox-content">
                    <app-reward-transfer-consequence
                      [transferRewards]="getObjectFromPath('ruleSource.consequences.transferRewards')"
                      [rewardTypes]="rewardTypes"
                      (inputChangeEmitter)="inputConsequencesChangeHandler($event, 'transferRewards')">
                    </app-reward-transfer-consequence>
                  </div>
                  <div class="ibox-content">
                    <app-tracking-reward-consequence
                      [trackingReward]="getObjectFromPath('ruleSource.consequences.trackingReward')"
                      [rewardTypes]="rewardTypes"
                      (inputChangeEmitter)="inputConsequencesChangeHandler($event, 'trackingReward')">
                    </app-tracking-reward-consequence>
                  </div>
                  <div class="ibox-content">
                    <app-relationship-consequence
                      [relateToLocation]="getObjectFromPath('ruleSource.consequences.relateToLocation')"
                      [partyRelationships]="partyRelationships"
                      [partyRoles]="partyRoles"
                      (inputChangeEmitter)="inputConsequencesChangeHandler($event, 'relateToLocation')">
                    </app-relationship-consequence>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-12">
              <div class="ibox border-bottom">
                <div class="ibox-title">
                  <div class="align-right">
                  <button class="btn btn-dark-blue" type="submit" (click)="save()" title="Save">
                      <fa-icon [icon]="faSave"></fa-icon>
                  </button>
                  <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
                    <fa-icon [icon]="faTimes"></fa-icon>
                  </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-md-6 float-right">
        <h3>Tools and Information</h3>
        <fieldset>
          <div class="row">
            <div class="col-lg-12">
              <div class="ibox border-bottom">
                <app-config-section  (inputChangeEmitter)="toggleSection($event)" title="Advanced View" sectionName="xmlPreview"></app-config-section>
                <div class="ibox-content xml-preview" [hidden]="!showJsonPreview">
                  <app-json-preview [ruleSource]="ruleSource"></app-json-preview>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <div class="row">
            <div class="col-lg-12">
              <div class="ibox border-bottom">
                <app-config-section  (inputChangeEmitter)="toggleSection($event)" title="Validation" sectionName="validation"></app-config-section>
                <div class="ibox-content" [hidden]="!showValidation">
                  <app-validation [promotionConfiguration]="promotionConfiguration" [configType]="'promoSponsor'"></app-validation>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
        <fieldset>
          <div class="row">
            <div class="col-lg-12">
              <div class="ibox border-bottom">
                <app-config-section  (inputChangeEmitter)="toggleSection($event)" title="Related Configurations" sectionName="relatedConfigs"></app-config-section>
                <div class="ibox-content" [hidden]="!showRelatedConfigs">
                  <app-related-configurations [cfgId]=configId [configType]="'promoSponsor'" [promotion]="promotion" [templateId]="promotionConfiguration.promotionTemplateId"></app-related-configurations>
                </div>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </div>

</div>
