import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faPencilAlt, faPlus, faTimes, faTrash, faHandshake, faCircle, faUser, faEnvelope, faGlobe, faUserTag, faCalendarCheck, faEye } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Choice, Filter, SearchFilters } from 'src/app/data/class';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { PartnerModalComponent } from '../partner-modal/partner-modal.component';
import { Partner } from '../data/model';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { PersonsService } from '../services/persons.service';
import { PartnerService } from '../services/partner.service';
import { PartyRoleService } from '../services/party-role.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { parseResponse } from '../data/parseResponseFunction';


@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {

  limit = 20;
  isLoading: boolean;
  filter = new Filter();
  statuses: Choice[] = [];
  lock: boolean;
  lengthToCompare: number;
  faPlus = faPlus;
  faTrash = faTrash;
  faPencilAlt = faPencilAlt;
  faHandshake = faHandshake;
  faCircle = faCircle;
  faUser = faUser;
  faEnvelope = faEnvelope;
  faGlobe = faGlobe;
  faUserTag = faUserTag;
  faCalendarCheck = faCalendarCheck;
  faEye = faEye;
  faTimes = faTimes;
  partners: any[] = [];
  promotions: any[] = [];
  selectedpartner: any;
  lockedTables: any = { state: false };
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  filteredStatuses: Choice[] = [];
  disabled: boolean;
  show: boolean;
  searchFilters: SearchFilters;
  selectedPartner: Partner;
  roles: any[] = [];
  statusHelper: any;

  constructor(private tableHelperService: TableHelperService,
              private responseHelper: ResponseHelperService,
              private partnerService: PartnerService,
              private statusHelperService: StatusHelperService,
              private partyRoleService: PartyRoleService,
              private personService: PersonsService,
              private title: Title,
              private modalHelper: NgbModal,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.title.setTitle('Partners');
    this.statusHelper = this.statusHelperService.getStatus('badge');
    this.partyRoleService.getAllRole().subscribe((roles: any) => {
      this.roles = roles.entity.filter((role: any) => role.id !== 1032);
      this.searchFilters = {
        formName: 'partnersSearch',
        searchCallback: () => { this.initNewSearch(); },
        filters: [
          {
            name: 'keyword',
            placeholder: 'keyword'
          },
          {
            name: 'statuses',
            placeholder: 'Status',
            type: 'array',
            choices: this.tableHelperService.getStatuses('ENTITY'),
            nameProp: 'value',
            valueProp: 'value',
            inputType: 'dropdown',
          },
          {
            name: 'roles',
            placeholder: 'Role',
            type: 'array',
            choices: this.roles,
            nameProp: 'roleName',
            valueProp: 'id',
            inputType: 'dropdown',
          }
        ],
        addEntityButtons: [
          {
            icon: faPlus,
            buttonText: 'New Partnet',
            if: true,
            callback: () => { this.newPartner(); },
            title: 'New Partner'
          }
        ]
      };
      this.show = true;
      this.statuses = this.tableHelperService.getStatuses('CONTACT_REASONS');
    });
  }

  clearKeyword(): void {
    this.initFilter();
    this.filter.search = '';
    this.getPartners(false);
  }

  initNewSearch(): void {
    this.initFilter();
    this.getPartners(false);
  }

  clearAllFilters(): void {
    this.searchFilters.clearAll();
  }

  getPartners(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.partnerService.getAllPartners(this.filter).subscribe((data: any) => {
      if (concat) {
        this.partners = this.partners.concat(parseResponse(data));
      } else {
        this.partners = parseResponse(data);
        this.selectedPartner = null;
      }
      this.lengthToCompare = parseResponse(data).length;
      this.filter.offset += this.limit;
      if(parseResponse(data).length < 1){
        this.lockedTables.state = true
        this.newLengthEvent.emit(this.lengthToCompare);
      }
      this.lock = this.lockedTables.state;
      this.isLoading = false;
    }, err => {
      this.lockedTables.state = false;
      this.isLoading = false;
      this.lock = false;
      this.toastr.error('Error occured!');
    });
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  newPartner(partner?: Partner): void {
    const instance = this.modalHelper.open(PartnerModalComponent);
    instance.componentInstance.partner = partner;
    instance.componentInstance.roles = this.roles;
    instance.componentInstance.successEvent.subscribe(data => {
      if (data.success) {
        this.responseHelper.success(`Partner successfully ${instance.componentInstance.isEdit ? 'updated' : 'created'}`, true);
        this.initNewSearch();
      }
    });
  }

  showPartner(event: any, partner: Partner): any {
    $('tr.active').removeClass('active');
    $(event.currentTarget).toggleClass('active');
    this.selectedPartner = partner;
    this.selectPartner();
  }

  selectPartner(): void {
    Promise.all([this.getAttributes(), this.getPersonSegments(), this.getPrimaryPartyEmail()]).then((result: any) => {
      const props = [
        'attrs',
        'segments',
        'email'
      ];
      props.forEach((attr: string, i: number) => {
        const data = result[i];
        if (data && data.success) {
          this.selectedPartner[attr] = parseResponse(data);
        }
      });
    });
  }

  getAttributes(): Promise<any[]> {
    return new Promise((resolve: any, reject: any) => {
      this.personService.getAttributes(this.selectedPartner.id).subscribe((result: any) => {
        resolve(result.entity);
      }, () => {
        reject([]);
      });
    });
  }

  getPersonSegments(): Promise<any[]> {
    return new Promise((resolve: any, reject: any) => {
      this.personService.getPersonSegments(this.selectedPartner.id, true).subscribe((result: any) => {
        resolve(result.entity);
      }, () => {
        reject([]);
      });
    });
  }

  getPrimaryPartyEmail(): Promise<any[]> {
    return new Promise((resolve: any, reject: any) => {
      this.personService.getPrimaryPartyEmail(this.selectedPartner.id).subscribe((result: any) => {
        resolve(result.entity);
      }, () => {
        reject([]);
      });
    });
  }
}
