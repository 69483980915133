<form [formGroup]="communicationStrategyForm" novalidate>
    <div class="modal-header">
      <h1>{{title}}</h1>
    </div>
    <div class="modal-body">
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{errorMsg}}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="form-group">
        <label>Name</label>
        <input type="text"  class="form-control" formControlName="name" autofocus>
        <div class="mt-2" [hidden]="communicationStrategyForm.controls['name'].valid">
          <small class="text-danger">Input a Name</small>
        </div>
      </div>

      <div class="form-group">
        <label>New Earning Opportunity</label>
        <ng-select [items]="comm" bindLabel="name" bindValue="id" formControlName="newPromotionsId" ></ng-select>
      </div>

      <div class="form-group">
        <label>Expiring Earning Opportunity</label>
        <ng-select [items]="comm" bindLabel="name" bindValue="id" formControlName="expiringPromotionsId" ></ng-select>
      </div>

      <div class="form-group">
        <label>No Activity Received</label>
        <ng-select [items]="comm" bindLabel="name" bindValue="id" formControlName="noActivityId"></ng-select>
      </div>

      <div class="form-group">
        <label>Earned Activity Reward</label>
        <ng-select [items]="comm" bindLabel="name" bindValue="id" formControlName="activityRewardId"></ng-select>
      </div>

      <div class="form-group">
        <label>Priority</label>
        <ng-select [items]="priority" formControlName="priority"></ng-select>
        <div class="mt-2" [hidden]="communicationStrategyForm.controls['priority'].valid">
          <small class="text-danger">Select priority</small>
        </div>
      </div>
  
    <div class="modal-footer">
      <button class="btn btn-dark-blue" type="submit" [disabled]="!communicationStrategyForm.valid" (click)="ok()" title="Save">
        <fa-icon [icon]="faSave"></fa-icon>
      </button>
      <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
        <fa-icon [icon]="faTimes"></fa-icon>
      </button>
    </div>
  </div>
</form>
  


 


