import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { faTimes, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-promotion-code-form',
  templateUrl: './promotion-code-form.component.html',
  styleUrls: ['./promotion-code-form.component.scss']
})
export class PromotionCodeFormComponent implements OnInit {

  @Input() promoCodeFormModel: any;
  @Input() index: any;
  @Output() promoCodeEvent = new EventEmitter();
  promoCodeForm: UntypedFormGroup;
  originalPromotionCode: string;
  faTimes = faTimes;
  faPlus = faPlus;
  faSave = faSave;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.index !== null) {
      this.promoCodeForm = this.formBuilder.group({
        promotionCode: [this.promoCodeFormModel.promotionCode],
      });
      this.originalPromotionCode = this.promoCodeFormModel.promotionCode;
    } else {
      this.promoCodeForm = this.formBuilder.group({
        promotionCode: [''],
      });
    }
  }

  emitEvent(type: string, index?: number): void {
    const value = this.promoCodeForm.value;
    this.promoCodeEvent.emit({type, index, value});
    if (type === 'add') {
      this.promoCodeForm.reset();
    }
  }

  resetEdit(): void {
    this.promoCodeForm.controls.promotionCode.setValue(this.originalPromotionCode);
  }
}
