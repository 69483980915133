import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { Validators, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { EventSourcesService } from 'src/app/services/event-sources.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-new-measure-function-dialog',
  templateUrl: './new-measure-function-dialog.component.html',
  styleUrls: ['./new-measure-function-dialog.component.scss']
})
export class NewMeasureFunctionDialogComponent implements OnInit {

  @Input() selectedSource: any;
  @Input() functions: any[] = [];
  @Input() temporalUnits: any[] = [];
  @Input() measure: any;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string;
  functionForm: UntypedFormGroup;
  errorMsg = '';
  faTimes = faTimes;
  sourceMeasure = { functionName: 'IDENT' };

  constructor(
    private responseHelper: ResponseHelperService,
    private modalHelper: NgbModal,
    private eventSourcesService: EventSourcesService,
    private formBuilder: FormBuilder,
  ) { }

  ngOnInit(): void {
    this.title = 'New Base Identity Measure';
    this.functionForm = this.formBuilder.group({
      functionName: [null, Validators.required],
      temporalUnitCode: [null, Validators.required],
    });
  }

  ok(): void {
    this.selectedSource.measuresCollection.push({
      ...this.functionForm.value,
      measureName: this.measure.measureName, unit: this.measure.unit
    });
    this.eventSourcesService.updateEventSource(this.selectedSource).subscribe((data: any) => {
      this.successEvent.emit(data);
    }, (data: any) => {
      this.responseHelper.error(this, data.error.error);
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

}
