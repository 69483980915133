import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { StatusHelperService } from '../../services/helpers/status-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormBuilder, Validators } from '@angular/forms';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { ProductService } from 'src/app/services/product.service';
import { Product } from 'src/app/data/model';

@Component({
  selector: 'app-product-modal',
  templateUrl: './product-modal.component.html',
  styleUrls: ['./product-modal.component.scss']
})
export class ProductModalComponent implements OnInit {

  @Input() product: Product;
  @Input() categories: any[];
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string;
  productForm: UntypedFormGroup;
  isEdit: boolean;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;

  constructor(
    private statusHelperService: StatusHelperService,
    private productService: ProductService,
    private modalHelper: NgbModal,
    private formBuilder: FormBuilder,
  ) { }

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.product) {
      this.title = 'Edit Product';
      this.isEdit = true;
      this.productForm = this.formBuilder.group({
        name: [this.product.name, Validators.required],
        code: [this.product.code, Validators.required],
        status: [this.product.status, Validators.required],
        description: [this.product.description],
        productCategoryId: [this.product.productCategoryId, Validators.required],
        averageUsage: [this.product.averageUsage],
        barCode: [this.product.barCode],
        dimensions: [this.product.dimensions],
        ean: [this.product.ean],
        lenght: [this.product.lenght],
        manufacturer: [this.product.manufacturer],
        price: [this.product.price],
        pricePerUnit: [this.product.pricePerUnit],
        quantityPerPackage: [this.product.quantityPerPackage],
        rrp: [this.product.rrp],
        serialNum: [this.product.serialNum],
        unitOfMeasure: [this.product.unitOfMeasure],
        upc: [this.product.upc],
        weight: [this.product.weight]
      });
    } else {
      this.title = 'New Product';
      this.isEdit = false;
      this.productForm = this.formBuilder.group({
        name: ['', Validators.required],
        code: ['', Validators.required],
        status: ['', Validators.required],
        productCategoryId: ['', Validators.required],
        description: [''],
        averageUsage: [''],
        barCode: [''],
        dimensions: [''],
        ean: [''],
        lenght: [''],
        manufacturer: [''],
        price: [],
        pricePerUnit: [null],
        quantityPerPackage: [null],
        rrp: [''],
        serialNum: [''],
        unitOfMeasure: [''],
        upc: [''],
        weight: ['']
      });
    }
  }

  ok(): void {
    this.productService.createOrUpdateProduct(this.product && this.product.id, this.productForm.value).subscribe((data: any) => {
      this.successEvent.emit(data);
    }, (data: any) => {
      this.errorMsg = data.error.error;
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

}
