import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommunicationContent } from 'src/app/data/class';
import { Language } from 'src/app/data/model';
import { CommunicationsService } from 'src/app/services/communications.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-communication-content-modal',
  templateUrl: './communication-content-modal.component.html',
  styleUrls: ['./communication-content-modal.component.scss']
})
export class CommunicationContentModalComponent implements OnInit {

  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() content: CommunicationContent;
  @Input() endpointType: string;
  @Input() commId: number;
  title: string;
  commContentForm: UntypedFormGroup;
  errorMsg: string;
  faSave = faSave;
  faTimes = faTimes;
  viewRadio = 2;
  isEdit = false;

  languages: Language[];

  constructor(
    private formBuilder: FormBuilder,
    private modalHelper: NgbModal,
    private languageService: LanguageService,
    private communicationsService: CommunicationsService,
    private responseHelperService: ResponseHelperService,
  ) { }

  ngOnInit(): void {
    this.languageService.getLanguages().subscribe((res: any) => {
      this.languages = res.entity;
    });

    if (this.content) {
      this.isEdit = true;
      this.title = 'Edit Content';
      this.commContentForm = this.formBuilder.group({
        plainTextView: ['htmlView', Validators.required],
        languageId: [this.content.languageId, Validators.required],
        subject: [this.content.subject, Validators.required],
        template: [this.content.template],

      });
    } else {
      this.title = 'New Content';
      this.commContentForm = this.formBuilder.group({
        plainTextView: ['htmlView', Validators.required],
        languageId: ['', Validators.required],
        subject: ['', Validators.required],
        template: ['']
      });
    }
  }

  ok(): void {
    let template = '';
    if (document.getElementById('content')) {
      template = document.getElementById('content').innerHTML;
    } else {
      template = this.commContentForm.get('template').value;
    }
    let params = {};
    if (this.content) {
      params = {
        id: this.content.id, communicationId: this.commId, languageId:
          this.commContentForm.get('languageId').value, subject: this.commContentForm.get('subject').value,
        template
      };
    } else {
      params = {
        communicationId: this.commId, languageId: this.commContentForm.get('languageId').value,
        subject: this.commContentForm.get('subject').value,
        template: this.commContentForm.get('template').value
      };
    }
    this.communicationsService.createOrUpdateContent(this.commId, params).subscribe(() => {
      this.responseHelperService.success('Attribute successfully created', true);
      this.successEvent.emit();
    }, () => {
      this.responseHelperService.error(this, this.errorMsg);
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
