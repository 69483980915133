<form [formGroup]="reversalForm" novalidate>
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Reversed By: {{reversedBy}}</label>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Reversed On</label>
          <div class="input-group date">
              <input class="form-control cursor-pointer" readonly="readonly" placeholder="yyyy-mm-dd" (click)="reversedOn.toggle()" name="reversedOn" ngbDatepicker #reversedOn="ngbDatepicker" formControlName="reversedOn" [footerTemplate]="footerTemplateReversedOn">
              <ng-template #footerTemplateReversedOn>
                <div class="width-100 datePickerTemplate">
                  <button class="buttonDateTemplate" (click)="clearDate('reversedOn'); reversedOn.toggle()">Clear</button>
                </div>
              </ng-template>
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="reversedOn.toggle()" type="button">
                  <fa-icon [icon]="faCalendar"></fa-icon>
                </button>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Notes</label>
          <textarea type="text" formControlName="txnNotes"  class="form-control vertical-expand"></textarea>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="ok()" [disabled]="!reversalForm.valid">Confirm</button>
      <button type="button" class="btn btn-warning" (click)="cancel()">Cancel</button>
  </div>
</form>

    
    
      
    
    
     
    

