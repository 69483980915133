<form>
  <div class="modal-header">
    <h1>Campaign Details</h1>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <ul class="list-group activity-list">
        <li class="list-group-item first-item" style="padding-top: 0px; padding-bottom: 0px;">
          <div class="row">
            <div class="table" style="margin-bottom: 0%">
              <table class='table table-pointable table-hover' style="margin-bottom: 0%">
                <thead>
                <tr>
                  <th id="campaign-details-component-id">ID</th>
                  <th id="campaign-details-component-code">Code</th>
                  <th id="campaign-details-component-cap">Cap</th>
                  <th id="campaign-details-component-from">From</th>
                  <th id="campaign-details-component-thru">Thru</th>
                </tr>
                </thead>
                <tbody>
                <tr ng-model="campaign">
                  <td>{{campaign.id}}</td>
                  <td style="word-break: break-all">{{campaign.code}}</td>
                  <td>{{campaign.cap}}</td>
                  <td>{{campaign.fromDate | date:'yyyy-MM-dd'}}</td>
                  <td>{{campaign.thruDate | date:'yyyy-MM-dd'}}</td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-warning" type="button" (click)="close()"> Close </button>
  </div>
</form>
