import { Component, OnInit } from '@angular/core';
import { faQuestionCircle, faTrash, faPlus, faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ReportsService } from '../services/reports.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ParameterModalComponent } from '../parameter-modal/parameter-modal.component';
import { ToastrService } from 'ngx-toastr';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  activeId = 1;
  faQuestionCircle = faQuestionCircle;
  faTrash = faTrash;
  faSyncAlt = faSyncAlt;
  faPlus = faPlus;
  report: any;
  attrToUpdate = {
    attrName: 'tableParams',
    reportId: null,
    attrValue: null
  };
  inputFilter = ['INTEGER', 'VARCHAR', 'FLOAT', 'NUMERIC'];
  clientParams = [{}];
  systemParams = [{}];
  attributes: any[] = [{}];
  paramTypes = ['INTEGER', 'VARCHAR', 'FLOAT', 'NUMERIC', 'BIGINT', 'TIMESTAMP', 'ARRAY INTEGER', 'ARRAY VARCHAR'];
  attrTypes = ['DATA', 'FIELDS', 'COLUMN', 'ROW'];
  aggtegation = ['', 'sum', 'count', 'min', 'max', 'avg', 'prod', 'var', 'varp', 'stdev', 'stdevp'];
  defaultValues = {
    TIMESTAMP: ['',
      'LAST YEAR BEGINNING',
      'LAST YEAR ENDING',
      'LAST QUARTER BEGINNING',
      'LAST QUARTER ENDING',
      'LAST MONTH BEGINNING',
      'LAST MONTH ENDING',
      'LAST WEEK BEGINNING',
      'LAST WEEK ENDING',
      'LAST DAY',
      'THIS YEAR BEGINNING',
      'THIS QUARTER BEGINNING',
      'THIS MONTH BEGINNING',
      'THIS WEEK BEGINNING',
      'NOW'
    ],
    BIGINT: ['', 'CURRENT USER']
  };
  autocomplete = ['',
    'party',
    'instruments',
    'segments',
    'attributes',
    'promotions',
    'reward types',
    'behaviors',
    'locations',
    'campaign',
    'communications'
  ];
  relatesTo = [];
  type = 'report';
  autocompleteData = {};
  allDataArray = [];
  selectedDataArray = {};
  externalAttr: any;
  externalAppUrl: any;
  renderForm = false;

  constructor(private http: HttpClient,
              private modalHelper: NgbModal,
              private title: Title,
              private activatedRoute: ActivatedRoute,
              private reportsService: ReportsService,
              private responseHelperService: ResponseHelperService,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.title.setTitle('Reports');
    const id = Number(this.activatedRoute.snapshot.params.id);
    if (id) {
      this.reportsService.getReport(id).subscribe((data: any) => {
        this.report = data.entity;
        if (!this.report.id){
          this.report.id = id;
        }
        this.getReportParams();
      });
    }
  }

  getReportParams(): void {
    this.reportsService.getReportParams(this.report.id).subscribe((paramData: any) => {
      this.clientParams = paramData.entity.clientParams;
      this.systemParams = paramData.entity.systemParams;
      this.externalAttr = ( paramData.entity.reportAttributes || []).filter((attr) => {
        return attr.attrName === 'EXTERNAL_APP_URL';
      });
      if (this.externalAttr && this.externalAttr.length > 0) {
        this.externalAppUrl = this.externalAttr[0].attrValue;
      }
      paramData.entity.reportAttributes = (paramData.entity.reportAttributes || []).filter((attr: any) => {
        return attr.attrName !== '_CHARTTYPE' && attr.attrName !== 'EXTERNAL_APP_URL';
      });
      this.attributes = paramData.entity.reportAttributes.length !== 0 ? JSON.parse(paramData.entity.reportAttributes[0].attrValue) : [{}];
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < this.attributes.length; i++){
        if (!this.attributes[i].dataSettings){
          this.attributes[i].dataSettings = {};
        }
      }
      this.reforgeParamType();
      this.updateDropdown();
      if(this.clientParams){
        this.clientParams.forEach((param: any) => {
          this.getDataForParams(param);
        });
      }
      this.renderForm = true;
    }, () => {
      this.toastr.error('Error occured!');
    });
  }

  reforgeParamType(): void {
    if(this.clientParams) {
      this.clientParams.forEach((param: any) => {
        if (param.paramType === 'ARRAY') {
          param.paramType += ' ' + param.arrayElementType;
        }
      });
    }

    if(this.systemParams) {
      this.systemParams.forEach((param: any) => {
        if (param.paramType === 'ARRAY') {
          param.paramType += ' ' + param.arrayElementType;
        }
      });
    }
  }

  updateDropdown(): void {
    if(this.attributes){
      this.attributes.forEach((attr: any) => {
        if (attr.type === 'DATA') {
          this.relatesTo.push(attr.name);
        }
      });
    }
  }

  getDataForParams(param: any): void {
    if ((param.paramType === 'BIGINT' || param.paramType === 'VARCHAR') && param.autocompleteAction !== null) {
      this.http.get('/cheetah/api' + param.autocompleteAction).subscribe((response: any) => {
        if (response.data.entity.aaData) {
          this.autocompleteData[param.paramCode] = response.data.entity.aaData;
        } else {
          if (response.data.entity[0] && typeof response.data.entity[0] === 'string') {
            const autocompletePrepared = [];
            response.data.entity.forEach((item: any) => {
              autocompletePrepared.push({
                name: item
              });
            });
            this.autocompleteData[param.paramCode] = autocompletePrepared;
          } else {
            this.autocompleteData[param.paramCode] = response.data.entity;
          }
        }
      }, (response: any) => {
        console.log(response);
      });
    } else if (this.isArray(param) && param.autocompleteAction !== null) {
      this.reportsService.getReportArrayParamsData(param.autocompleteAction).subscribe((response: any) => {
          this.allDataArray[param.paramCode] = parseResponse(response);
      
        if (param.defaultValue) {
          const defaultArray = param.defaultValue.split(',').map((elem: any) => {
            return elem.trim();
          });
          this.selectedDataArray[param.paramCode] = [];
          this.allDataArray[param.paramCode].forEach((elem: any) => {
            if (defaultArray.indexOf(elem.id.toString()) !== -1 || defaultArray.indexOf(elem.text.toString()) !== -1) {
              this.selectedDataArray[param.paramCode].push(elem);
            }
          });
        }
      });
    }
  }

  changedAuto(param: any): void {
    param.paramType = param.autocompleteAction === '/integrations/customers' ? 'VARCHAR' : 'BIGINT';
  }

  addAttr(type: any): void {
    if (type === 'attributes'){
       this[type].push({dataSettings: {}});
    }else{
      this[type].push({});
    }
  }

  removeAttr(type: any, index: number): void {
    this[type].splice(index, 1);
    if (this[type].length === 0) {
      this[type].push({});
    }
  }

  removeParam(id: number, param): void {
    this.reportsService.removeParam(this.report.id, id).subscribe((data: any) => {
      if (param === 'SystemParam') {
        this.systemParams = (this.systemParams || []).filter((param: any) => {
          return param.id !== id;
        });
      } else if(param === 'ClientParam') {
        this.clientParams = (this.clientParams || []) .filter((param: any) => {
          return param.id !== id;
        });
      }
      this.responseHelperService.success('Report parameters successfully removed');
    }, (data: any) => {
      this.responseHelperService.error(this, data.error.error);
    });
  }

  updateParam(param: any): void {
    if (param.paramType.startsWith('ARRAY')) {
      param.arrayElementType = param.paramType.split(' ').pop();
      param.paramType = 'ARRAY';
    }

    if (this.selectedDataArray[param.paramCode]) {
      param.defaultValue = '';
      if (param.arrayElementType === 'VARCHAR') {
        this.selectedDataArray[param.paramCode].forEach((elem) => {
          param.defaultValue += elem.text + ',';
        });
      }
      if (param.arrayElementType === 'INTEGER') {
        this.selectedDataArray[param.paramCode].forEach((elem) => {
          param.defaultValue += elem.id + ',';
        });
      }
    }

    this.reportsService.updateParam(this.report.id, param).subscribe(() => {
      this.responseHelperService.success('Report parameters successfully updated');
      this.reforgeParamType();
    }, (data: any) => {
      this.responseHelperService.error(this, data.error.error);
    });
  }

  updateAttributes(): void {
    this.attrToUpdate.reportId = this.report.id;
    this.attrToUpdate.attrValue = JSON.stringify(this.attributes);
    this.reportsService.updateAttributes(this.report.id, this.attrToUpdate).subscribe(() => {
      this.responseHelperService.success('Report attributes successfully updated');
    }, (data: any) => {
      this.responseHelperService.error(this, data.error.error);
    });
  }

  addParam(isSystem: boolean): void {
    const additionalAttributes = {
      autocompleteData: this.autocompleteData,
      selectedDataArray: this.selectedDataArray,
      allDataArray: this.allDataArray,
      reportId: this.report.id,
      systemParams: this.systemParams,
      clientParams: this.clientParams
    };
    const instance = this.modalHelper.open(ParameterModalComponent);
    instance.componentInstance.isSystem = isSystem;
    instance.componentInstance.additionalAttributes = additionalAttributes;
    instance.componentInstance.defaultValues = this.defaultValues;
    instance.componentInstance.autocomplete = this.autocomplete;
    instance.componentInstance.successEvent.subscribe((response) => {
      response.entity.systemParameter ? this.systemParams.push(response.entity) : this.clientParams.push(response.entity);
      this.reforgeParamType();
    });
  }

  isArray(param: any): boolean {
    return param.paramType && param.paramType.startsWith('ARRAY');
  }

  isInput(paramName: string): boolean {
    return this.inputFilter.indexOf(paramName) !== -1;
  }

  showAttributes(): boolean {
    return this.attributes && this.attributes[0] && Object.keys(this.attributes[0]).length > 0;
  }

}
