import { Component, Input, OnInit } from '@angular/core';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-communication-content-template-modal',
  templateUrl: './communication-content-template-modal.component.html',
  styleUrls: ['./communication-content-template-modal.component.scss']
})
export class CommunicationContentTemplateModalComponent implements OnInit {

  @Input() template: string;
  faTimes = faTimes;

  constructor(
    private modalHelper: NgbModal
  ) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

}
