import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../app-data.service';
import { IntegrationServer } from '../data/model';

@Injectable({
  providedIn: 'root'
})
export class IntegrationServerService {

  constructor(private http: HttpClient, private globalData: AppDataService) { }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getIntegrationServers(params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'integration-servers', this.setParams(params));
  }

  getIntegrationServer(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'integration-servers/' + id);
  }

  deleteIntegrationServer(id: number): any {
    return this.http.delete<any>(this.globalData.baseUrl + 'integration-servers/' + id);
  }

  createOrUpdateIntegrationServer(id: number, data: IntegrationServer): any {
    if(id){
      return this.http.put<any>(this.globalData.baseUrl + 'integration-servers/' + id, data);
    }
    return this.http.post<any>(this.globalData.baseUrl + 'integration-servers/', data);
  }
}
