<form>
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
    {{errorMsg}}
    <a (click)="clearErrorMessage()">
      <span class="float-right close-button">
        <fa-icon [icon]="faTimes"></fa-icon>
      </span>
    </a>
  </div>
  <div class="form-group client-detail ml-4" *ngIf="rewardDetails">
    <div class="full-height-scroll">
    <ul class="list-group clear-list redemption-list">
      <li class="list-group-item fist-item" [hidden]="!rewardDetails.activityDate">
        <div class="row">
          <div class="col-md-4">
            Activity Date:
          </div>
          <div class="col-md-8 float-right">
            {{rewardDetails.activityDate | date}}
          </div>
        </div>
      </li>
      <li class="list-group-item" [hidden]="!rewardDetails.activityCreatedOnDate">
        <div class="row">
          <div class="col-md-4">
            Created On:
          </div>
          <div class="col-md-8 float-right">
            {{rewardDetails.activityCreatedOnDate | date}}
          </div>
        </div>
      </li>
      <li class="list-group-item" [hidden]="!rewardDetails.submitedForProcessingDate">
        <div class="row">
          <div class="col-md-4">
            Submitted for (processing): </div>
          <div class="col-md-8 float-right">
            {{rewardDetails.submitedForProcessingDate | date}}
          </div>
        </div>
      </li>
      <li class="list-group-item" [hidden]="!rewardDetails.rewardDate">
        <div class="row">
          <div class="col-md-4">
            Reward Date:
          </div>
          <div class="col-md-8 float-right">
            {{rewardDetails.rewardDate | date}}
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
  <div class="form-group client-detail" *ngIf="redemptionItems">
    <div class="full-height-scroll">
      <div class="row">
        <h3 class="col-md-12 float-left">
          Redemption Description: {{description}}
        </h3>
      </div>
      <ul [hidden]="!(redemptionItems.length > 0)" *ngFor="let ri of redemptionItems"
        class="list-group clear-list redemption-list">
        <li class="list-group-item fist-item" [hidden]="!ri.id">
          <div class="row">
            <div class="col-md-4">
              ID:
            </div>
            <div class="col-md-8 float-right">
              {{ri.id}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!ri.redemptionId">
          <div class="row">
            <div class="col-md-4">
              Redemption ID:
            </div>
            <div class="col-md-8 float-right">
              {{ri.redemptionId}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!ri.linenum">
          <div class="row">
            <div class="col-md-4">
              Line Number:
            </div>
            <div class="col-md-8 float-right">
              {{ri.linenum}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!ri.itemId">
          <div class="row">
            <div class="col-md-4">
              Item ID:
            </div>
            <div class="col-md-8 float-right">
              {{ri.itemId}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!ri.itemName">
          <div class="row">
            <div class="col-md-4">
              Item Name:
            </div>
            <div class="col-md-8 float-right">
              {{ri.itemName}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!ri.itemCost">
          <div class="row">
            <div class="col-md-4">
              Item Cost:
            </div>
            <div class="col-md-8 float-right">
              {{ri.itemCost}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!ri.itemPoints">
          <div class="row">
            <div class="col-md-4">
              Item Points:
            </div>
            <div class="col-md-8 float-right">
              {{ri.itemPoints}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!ri.itemProfit">
          <div class="row">
            <div class="col-md-4">
              Item Profit:
            </div>
            <div class="col-md-8 float-right">
              {{ri.itemProfit}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!ri.itemSize">
          <div class="row">
            <div class="col-md-4">
              Item Size:
            </div>
            <div class="col-md-8 float-right">
              {{ri.itemSize}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!ri.imageUrl">
          <div class="row">
            <div class="col-md-4">
              Image Url:
            </div>
            <div class="col-md-8 float-right" style="word-break: break-all">
              <!-- <a href="{{ri.imageUrl}}">{{ri.imageUrl}}</a> -->
              <img src="{{ri.imageUrl}}" class="full-width" alt="">
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!ri.quantity">
          <div class="row">
            <div class="col-md-4">
              Item Quantity:
            </div>
            <div class="col-md-8 float-right">
              {{ri.quantity}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!ri.color">
          <div class="row">
            <div class="col-md-4">
              Item Color:
            </div>
            <div class="col-md-8 float-right">
              {{ri.color}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!ri.attr1">
          <div class="row">
            <div class="col-md-4">
              Attribute1:
            </div>
            <div class="col-md-8 float-right">
              {{ri.attr1}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!ri.attr2">
          <div class="row">
            <div class="col-md-4">
              Attribute2:
            </div>
            <div class="col-md-8 float-right">
              {{ri.attr2}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!ri.attr3">
          <div class="row">
            <div class="col-md-4">
              Attribute3:
            </div>
            <div class="col-md-8 float-right">
              {{ri.attr3}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!ri.status">
          <div class="row">
            <div class="col-md-4">
              Item Status:
            </div>
            <div class="col-md-8 float-right">
              {{ri.status}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!ri.convRate">
          <div class="row">
            <div class="col-md-4">
              Item Conversion Rate:
            </div>
            <div class="col-md-8 float-right">
              {{ri.convRate}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!ri.markupRate">
          <div class="row">
            <div class="col-md-4">
              Item Markup Rate:
            </div>
            <div class="col-md-8 float-right">
              {{ri.markupRate}}
            </div>
          </div>
        </li>
        <hr [hidden]="!(redemptionItems.length < $index)">
      </ul>
    </div>
  </div>
  <div class="form-group" *ngIf="redemptionItems && (redemptionItems.length === 0)" style="margin-left: 15px">
    No items to show.
  </div>
  <div class="form-group" *ngIf="notes" style="margin-left: 15px">
    {{notes}}
  </div>
  <div class="form-group client-detail" *ngIf="reverseDetails">
    <div class="full-height-scroll">
      <ul class="list-group clear-list redemption-list">
        <li class="list-group-item fist-item" [hidden]="!reverseDetails.rewardTypeName">
          <div class="row">
            <div class="col-md-4">
              Reward Type:
            </div>
            <div class="col-md-8 float-right">
              {{reverseDetails.rewardTypeName}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!reverseDetails.instrumentNumber">
          <div class="row">
            <div class="col-md-4">
              Instrument Number:
            </div>
            <div class="col-md-8 float-right">
              {{reverseDetails.instrumentNumber}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!reverseDetails.txnDateStr">
          <div class="row">
            <div class="col-md-4">
              Transaction Date:
            </div>
            <div class="col-md-8 float-right">
              {{reverseDetails.txnDateStr | date}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!reverseDetails.activityDate">
          <div class="row">
            <div class="col-md-4">
              Activity Date:
            </div>
            <div class="col-md-8 float-right">
              {{reverseDetails.activityDate | date}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!reverseDetails.amount">
          <div class="row">
            <div class="col-md-4">
              Reward Amount:
            </div>
            <div class="col-md-8 float-right">
              {{reverseDetails.amount}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!reverseDetails.activityId">
          <div class="row">
            <div class="col-md-4">
              Activity ID:
            </div>
            <div class="col-md-8 float-right">
              {{reverseDetails.activityId}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!reverseDetails.reversalNotes">
          <div class="row">
            <div class="col-md-4">
              Notes:
            </div>
            <div class="col-md-8 float-right">
              {{reverseDetails.reversalNotes}}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="form-group" *ngIf="reverseDetails && (reverseDetails.length === 0)" style="margin-left: 15px">
    No reverse details to show
  </div>
  <div class="form-group client-detail" *ngIf="attributeDetails" style="margin-left: 15px">
    <div class="full-height-scroll">
      <ul class="list-group clear-list redemption-list">
        <li class="list-group-item fist-item" [hidden]="!attributeDetails.originalEffectiveFrom">
          <div class="row">
            <div class="col-md-4">
              Original Effective From:
            </div>
            <div class="col-md-8 float-right">
              {{attributeDetails.originalEffectiveFrom | date: 'short'}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!attributeDetails">
          <div class="row">
            <div class="col-md-4">
              Original Effective To:
            </div>
            <div class="col-md-8 float-right">
              {{ getEffectiveToDisplay(attributeDetails)}}
            </div>
          </div>
        </li>
        <li class="list-group-item" [hidden]="!attributeDetails.updatedOn">
          <div class="row">
            <div class="col-md-4">
              Updated On:
            </div>
            <div class="col-md-8 float-right">
              {{attributeDetails.updatedOn | date: 'MM/dd/yyyy h:mm a': '+0000'}}
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <div class="form-group" [hidden]="!(content.length > 0)" style="margin-left: 15px;">
    {{content}}
  </div>
  <div class="modal-footer">
    <button class="btn btn-warning" type="button" (click)="cancel()"> Close </button>
  </div>
</form>
