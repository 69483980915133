<div>
  <form>
    <label>The REWARD history for the member must contain:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="rewardCountThreshold !== null">
        <div *ngFor="let rewCntThreshold of rewardCountThreshold; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeThreshold(rewCntThreshold)" title="Remove Threshold">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="thresholdVal_{{rewCntThreshold.thresholdValue}}">more than</label>
              <input class="form-control m-b" type="number" id="thresholdVal_{{rewCntThreshold.thresholdValue}}" name="thresholdValue" [(ngModel)]="rewCntThreshold.thresholdValue" (ngModelChange)="updateThresholds()" />
            </div>
            <div class="form-group">
              <a
                class="control-label"
                ng-class="{ 'disabled-link': readOnly }"
                (click)="
                  regexSearchModal(
                    'PROMOTIONS',
                    { object: rewCntThreshold, name: 'promotionCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                title="Search for Promotions"
                >rewards on Promotions with Code (blank for current promotion, or a comma separated list):</a
              >
              <input
                class="form-control form-readonly m-b"
                type="text"
                id="behCodes_{{ rewCntThreshold.promotionCodes }}"
                name="promotionCodes"
                [(ngModel)]="rewCntThreshold.promotionCodes"
                (ngModelChange)="updateThresholds()"
                (click)="
                  regexSearchModal(
                    'PROMOTIONS',
                    { object: rewCntThreshold, name: 'promotionCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                readonly
              />
            </div>
            <div class="form-group">
              <a
                class="control-label"
                ng-class="{ 'disabled-link': readOnly }"
                (click)="
                  regexSearchModal(
                    'BEHAVIORS',
                    { object: rewCntThreshold, name: 'behaviorCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                title="Search for Behaviors"
                >for Behaviors with Codes (comma separated list):</a
              >
              <input
                class="form-control form-readonly m-b"
                type="text"
                id="behCodes_{{ rewCntThreshold.thresholdValue }}"
                name="behaviorCodes"
                [(ngModel)]="rewCntThreshold.behaviorCodes"
                (ngModelChange)="updateThresholds()"
                (click)="
                  regexSearchModal(
                    'BEHAVIORS',
                    { object: rewCntThreshold, name: 'behaviorCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                readonly
              />
            </div>
            <div class="form-group">
              <label>submitted to the same location:</label>
              <select name="locationMatch_{{rewCntThreshold.thresholdValue}}" class="form-control" [(ngModel)]="rewCntThreshold.locationMatch" (ngModelChange)="updateThresholds()">
                <option *ngFor="let tf of trueFalse" [selected]="rewCntThreshold.locationMatch === tf.value" [value]="tf.value">{{tf.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label>for the current:</label>
              <select name="thresholdPeriod_{{rewCntThreshold.thresholdValue}}" class="form-control" [(ngModel)]="rewCntThreshold.thresholdPeriod.value" (ngModelChange)="updateThresholds()">
                <option *ngFor="let period of periods" [selected]="rewCntThreshold.thresholdPeriod.value === period.value" [value]="period.value">{{period.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label>Should the count include activities from later in the period than the current activity?</label>
              <select name="inclusion_{{rewCntThreshold.thresholdValue}}" class="form-control" [(ngModel)]="rewCntThreshold.thresholdPeriod.inclusion" (ngModelChange)="updateThresholds()">
                <option *ngFor="let inclusionVal of inclusionValues" [selected]="rewCntThreshold.thresholdPeriod.inclusion === inclusionVal.value" [value]="inclusionVal.value">{{inclusionVal.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="previousPeriodCount_{{rewCntThreshold.thresholdValue}}">How many previous periods should be included in the time span (leave empty for only the current period):</label>
              <input class="form-control m-b" type="number" id="previousPeriodCount_{{rewCntThreshold.thresholdValue}}" name="previousPeriodCount" [(ngModel)]="rewCntThreshold.previousPeriodCount" (ngModelChange)="updateThresholds()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="relationshipName_{{rewCntThreshold.thresholdValue}}">Include counts from members related by (leave blank for current member only or use a comma separated list):</label>
              <input class="form-control m-b" type="text" id="relationshipName_{{rewCntThreshold.thresholdValue}}" name="relationshipName" [(ngModel)]="rewCntThreshold.relationshipName" (ngModelChange)="updateThresholds()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="friendlyMessage_{{rewCntThreshold.thresholdValue}}">Message:</label>
              <input class="form-control m-b" type="text" id="friendlyMessage_{{rewCntThreshold.thresholdValue}}" name="friendlyMessage" [(ngModel)]="rewCntThreshold.friendlyMessage" (ngModelChange)="updateThresholds()" />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initThresholdObject()"><fa-icon [icon]="faPlus"></fa-icon> Add Reward Count Threshold</button>
      </div>
    </div>
  </form>
</div>
