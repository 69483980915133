    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">{{header}}</h4>
      </div>
      <div class="modal-body">
        <p>{{text || 'Are you sure?'}}</p>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="ok()">{{okButton || 'Ok'}}</button>
        <button type="button" class="btn btn-warning" (click)="cancel()">{{cancelButton || 'Cancel'}}</button>
      </div>
    </div>
    
 
