<div>
  <form>
    <label>Issue a reward as a stepped function of the submitted activity value (e.g. value 0-100 = 1 point, 100-200 - 2 points, etc.):</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="steppedReward !== null">
        <div *ngFor="let stepReward of steppedReward; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeConsequence(stepReward)" title="Remove Consequence">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="stepInterval_{{stepReward.rewardCode}}">Step Interval</label>
              <input class="form-control m-b" type="number" id="stepInterval_{{stepReward.rewardCode}}" name="stepInterval" [(ngModel)]="stepReward.stepInterval" (ngModelChange)="updateConsequences()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="rewardOffset_{{stepReward.rewardCode}}">Initial Amount (offset)</label>
              <input class="form-control m-b" type="number" id="rewardOffset_{{stepReward.rewardCode}}" name="rewardOffset" [(ngModel)]="stepReward.rewardOffset" (ngModelChange)="updateConsequences()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="tenderOffset_{{stepReward.rewardCode}}">Tender Offset</label>
              <input class="form-control m-b" type="number" id="tenderOffset_{{stepReward.rewardCode}}" name="tenderOffset" [(ngModel)]="stepReward.tenderOffset" (ngModelChange)="updateConsequences()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="rewardRate_{{stepReward.rewardCode}}">Reward Multiplier (rate)</label>
              <input class="form-control m-b" type="number" id="rewardRate_{{stepReward.rewardCode}}" name="rewardRate" [(ngModel)]="stepReward.rewardRate" (ngModelChange)="updateConsequences()" />
            </div>
            <div class="form-group">
              <label>Reward Type</label>
              <select name="rewardCode_{{stepReward.rewardCode}}" class="form-control" [(ngModel)]="stepReward.rewardCode" (ngModelChange)="updateConsequences()">
                <option *ngFor="let rewardType of rewardTypes" [selected]="stepReward.rewardCode === rewardType.code" [value]="rewardType.code">{{rewardType.code}}</option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initConsequenceObject()"><fa-icon [icon]="faPlus"></fa-icon> Add Stepped Reward</button>
      </div>
    </div>
  </form>
</div>
