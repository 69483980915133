import { Component, OnInit } from '@angular/core';
import { faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Segment } from '../data/model';
import { SegmentModalComponent } from '../segment-modal/segment-modal.component';
import { PersonsService } from '../services/persons.service';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-segments',
  templateUrl: './segments.component.html',
  styleUrls: ['./segments.component.scss']
})
export class SegmentsComponent implements OnInit {

  faPlus = faPlus;
  faEdit = faEdit;
  filtered: Segment[];
  query: string;
  allSegments: Segment[];
  subject: Subject<any> = new Subject<any>();

  constructor(private modalHelper: NgbModal,
              private title: Title,
              private toastr: ToastrService,
              private personService: PersonsService ) {
                this.subject.pipe(
                  debounceTime(300),
                  distinctUntilChanged())
                  .subscribe((query: any) => {
                    this.applyFilter(query);
                  });
              }

  ngOnInit(): void {
    this.title.setTitle('Segments');
    this.getAllSegmentsTree();
  }

  getAllSegmentsTree(): void {
    this.personService.getAllSegmentsTree().subscribe((data: any) => {
      if (data.success) {
        this.allSegments = parseResponse(data);
        this.filtered = [...this.allSegments];
      }
    }, () => {
      this.toastr.error('Error occured!');
    });
  }

  applyFilter(query: string): void {
    if (query && query.length > 2) {
      this.filtered = this.allSegments.filter((segment: Segment) => segment.code.toUpperCase().indexOf(query.toUpperCase()) !== -1);
      // Add parents
      for (let i = this.allSegments.length - 1; i >= 0; i--) {
        if (this.isParent(this.allSegments[i].id)) {
          const idx = this.getIdx(this.allSegments[i].id);
          this.filtered.splice(idx, 0, this.allSegments[i]);
        }
      }
    } else {
      this.filtered = [...this.allSegments];
    }
  }

  getIdx(segmentId: number): number {
    const indexs = [];
    this.filtered.forEach((item: Segment, idx: number) => {
      if (item.parentId === segmentId) {
        indexs.push(idx);
      }
    });
    return Math.min.apply(null, indexs);
  }

  // inArray added for returning only parents that are not in array already
  isParent(id: number): boolean {
    let parent = false;
    let inArray = true;
    this.filtered.forEach((segment: Segment) => {
      if (segment.parentId === id) {
        parent = true;
      }
      if (segment.id === id) {
        inArray = false;
      }
    });
    return parent && inArray;
  }

  segmentModal(segment: Segment, isNew: boolean): void {
    const instance = this.modalHelper.open(SegmentModalComponent);
    instance.componentInstance.segment = segment;
    instance.componentInstance.isNew = isNew;
    if (isNew){
      instance.componentInstance.parentId = segment.id;
    }
    instance.componentInstance.successEvent.subscribe((result: any) => {
      const seg = result.entity;
      if (isNew) {
        seg.level = segment.level + 1;
        this.filtered.splice(this.filtered.findIndex((segm: Segment) => segm.id === seg.parentId) + 1, 0, seg);
        this.allSegments.splice(this.allSegments.findIndex((segm: Segment) => segm.id === seg.parentId) + 1, 0, seg);
      } else {
        seg.level = segment.level;
        this.allSegments[this.allSegments.findIndex((segm: Segment) => segm.id === segment.id)] = seg;
        this.filtered[this.filtered.findIndex((segm: Segment) => segm.id === segment.id)] = seg;
      }
    });
  }

  getLevel(item: Segment): string {
    return 'level_' + item.level;
  }

  changeFilter(query: any): void {
    this.subject.next(query);
  }

}
