<form [formGroup]="attributePairForm">
  <div class="modal-header">
    <h1>Add Attribute Mappings</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <label>Attribute Name</label>
      <input type="text" class="form-control" formControlName="attrNameExpr" [appTrim]="!attributePair.id">
    </div>
    <div class="form-group">
      <label>Attribute Value</label>
      <input type="text" class="form-control" formControlName="attrValueExpr" [appTrim]="!attributePair.id">
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" [disabled]="!attributePairForm.valid" (click)="ok()" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>