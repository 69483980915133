<form [formGroup]="instrumentTypeForm" novalidate>
    <div class="modal-header">
      <h1>{{title}}</h1>
    </div>
    <div class="modal-body">
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{errorMsg}}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="form-group">
        <label>Code</label>
        <input type="text" class="form-control" formControlName="code" [appTrim]="!instrumentType">
        <div class="mt-1" [hidden]="instrumentTypeForm.controls.code.valid">
          <small class="text-danger">Input a Code</small>
        </div>
      </div>

      <div class="form-group">
        <label>External Code</label>
        <input type="text" class="form-control" formControlName="extCode" [appTrim]="!instrumentType">
      </div>
  
        <div class="form-group">
          <label>Status</label>
          <select formControlName="status" class="form-control" formControlName="status">
            <option *ngFor="let option of statuses" value="{{option}}">{{option}}</option>
          </select>
          <div class="mt-1" [hidden]="instrumentTypeForm.controls.status.valid">
            <small class="text-danger">Select status</small>
          </div>
        </div>
      <div class="form-group">
        <label>Description</label>
        <textarea formControlName="description" type="text"  class="form-control vertical-expand" [appTrim]="!instrumentType"></textarea>
      </div>
  
      <div class="modal-footer">
        <button class="btn btn-dark-blue" type="submit" [disabled]="!instrumentTypeForm.valid"  (click)="ok()" title="Save">
          <fa-icon [icon]="faSave"></fa-icon>
        </button>
        <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
      </div>
    </div>
  </form>
  
  
    
  
  
   
  
