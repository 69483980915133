export function createLink(
    href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
  ): void {
    const link = document.createElement('link');
    link.setAttribute('rel', 'stylesheet');
    link.setAttribute('href', href);
    document.head.append(link);
  }
  
  export function removeLink(
    href = 'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css'
  ): void {
    const links = document.head.getElementsByTagName('link');
    for (let i = 0; i < links.length; i++) {
      if (links[i].getAttribute('href') === href) {
        document.head.removeChild(links[i]);
      }
    }
  }