import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { Program } from '../data/model';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { ProgramService } from '../services/program.service';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from '../services/helpers/response-helper.service';

@Component({
  selector: 'app-program-modal',
  templateUrl: './program-modal.component.html',
  styleUrls: ['./program-modal.component.scss']
})
export class ProgramModalComponent implements OnInit {

  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public program: Program;
  @Input() public icon: any;
  title: string;
  isEdit: boolean;
  programForm: UntypedFormGroup;
  errorMsg: string;
  faSave = faSave;
  faTimes = faTimes;
  allIcons = fas;
  allIconKeys = Object.keys(this.allIcons);
  filterIconKeys: string[] = Object.keys(this.allIcons);
  everyThirdIconArray: any[];

  constructor(private formBuilder: FormBuilder,
              private statusHelperService: StatusHelperService,
              private programService: ProgramService,
              private modalHelper: NgbModal,
              private responseHelperService: ResponseHelperService) { }

  statuses = this.statusHelperService.getStatus('dropdown');
  @ViewChild('popOver') public popover: NgbPopover;

  ngOnInit(): void {
    this.allIconKeys.splice(this.allIconKeys.findIndex(e => e === 'faFontAwesomeLogoFull'), 1);
    this.filterIconKeys = [...this.allIconKeys];
    this.everyThirdIconArray = new Array(Math.floor(this.filterIconKeys.length / 3 ));
    if (this.program){
      this.title = 'Edit Program';
      this.isEdit = true;
      this.programForm = this.formBuilder.group({
        name: [this.program.name, Validators.required],
        code: [this.program.code, Validators.required],
        status: [this.program.status, Validators.required],
        description: [this.program.description],
        imageUrl: [this.program.imageUrl || 'faHeart']
      });
    } else {
      this.title = 'New Program';
      this.isEdit = false;
      this.programForm = this.formBuilder.group({
        name: ['', Validators.required],
        code: ['', Validators.required],
        status: ['', Validators.required],
        description: [''],
        imageUrl: ['faHeart']
      });
    }
  }

  ok(): void {
    this.programService.createOrUpdatePrograms(this.program && this.program.id, this.programForm.value).subscribe(() => {
      this.successEvent.emit('success');
    }, (data: any) => {
      this.responseHelperService.error(this, data.error.error);
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  filterIcons(regex: string): void {
    this.filterIconKeys = this.allIconKeys.filter((key: string) => key.toLocaleLowerCase().includes(regex.toLocaleLowerCase()));
    this.everyThirdIconArray = new Array(Math.floor(this.filterIconKeys.length / 3 ));
  }

  selectIcon(iconName: string): void {
    this.programForm.patchValue({imageUrl: iconName});
    this.icon = this.allIcons[iconName];
    this.popover.close();
  }

}
