<form [formGroup]="communicationTestForm" novalidate>
    <div class="modal-header">
        <h1>{{title}}</h1>
    </div>
    <div class="modal-body">
        <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
            {{errorMsg}}
            <a (click)="clearErrorMessage()">
              <span class="float-right close-button">
                <fa-icon [icon]="faTimes"></fa-icon>
              </span>
            </a>
          </div>
        <div class="form-group">
            <label>Member Profile ID</label>
            <input type="text" class="form-control" formControlName="partyId">
            <div class="m-t-xs" [hidden]="communicationTestForm.controls['partyId'].valid">
                <small class="text-danger">Please Input a Member Profile ID</small>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-dark-blue" type="submit" [disabled]="!communicationTestForm.valid" (click)="ok()"
            title="Save">
            <fa-icon [icon]="faPaperPlane"></fa-icon>
        </button>
        <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
            <fa-icon [icon]="faTimes"></fa-icon>
        </button>
    </div>
</form>