<form *ngIf="renderForm" [formGroup]="reportForm">
    <div class="panel-body">
        <div class="ibox-content">
            <div class="form-group">
                <label>Name</label>
                <input type="text" formControlName="name" name="name" class="form-control">
            </div>
            <div class="form-group">
                <label>Status</label>
                <select class="form-control" formControlName="status">
                    <option *ngFor="let option of statuses" value="{{option}}">{{option}}</option>
                </select>
            </div>
            <div class="form-group">
                <label>Description</label>
                <textarea type="text" formControlName="description" class="form-control vertical-expand"></textarea>
            </div>
            <div class="form-group">
                <label>Query</label>
                <textarea type="text" formControlName="sqlQuery" class="form-control vertical-expand"></textarea>
            </div>
            <div class="row">
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-group form-check">
                            <input formControlName="interactiveReport" type="checkbox" class="form-check-input">
                            <label for="interactiveReport">
                                Interactive
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-group form-check">
                            <input formControlName="dashboardReport" type="checkbox" class="form-check-input">
                            <label for="dashboardReport">
                                Dashboard
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-group form-check">
                            <input formControlName="outboundReport" type="checkbox" class="form-check-input">
                            <label for="outboundReport">
                                Outbound
                            </label>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="form-group">
                        <div class="form-group form-check">
                            <input formControlName="forStaging" type="checkbox" class="form-check-input">
                            <label for="forStaging">
                                Integrations
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" [hidden]="!reportForm.controls.dashboardReport.value">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Chart type</label>
                        <select formControlName="chartOpt" class="form-control mb-3"
                            (change)="updateChartType()">
                            <option *ngFor="let option of chartOpts" value="{{option}}">
                                {{option}}</option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="row" [hidden]="reportForm.controls.chartOpt.value !== 'BAR CHART'">
                <div class="col-md-1">
                    <fa-icon [icon]="faChartBar" class="fa-4x"></fa-icon>
                </div>
                <div class="col-md-10 col-md-offset-1">
                    <label>DATA</label> - The data shown in chart
                    <br>
                    <label>COLUMN</label> - Represents the X-axis
                    <br>
                    <label>ROW</label> - This option is not meant for (BAR ;charts, to ;connect )it with (proper )DATA
                    use Relates
                    To
                </div>
            </div>
            <div class="row" [hidden]="reportForm.controls.chartOpt.value !== 'LINE CHART'">
                <div class="col-md-1">
                    <fa-icon [icon]="faChartLine" class="fa-4x"></fa-icon>
                </div>
                <div class="col-md-10 col-md-offset-1">
                    <label>DATA</label> - The data shown in chart
                    <br>
                    <label>COLUMN</label> - Represents the X-axis
                    <br>
                    <label>ROW</label> - Represents the grouping of DATA in series, to connect it with (proper )DATA use
                    Relates To
                </div>
            </div>
            <div class="row" [hidden]="reportForm.controls.chartOpt.value !== 'PIE CHART'">
                <div class="col-md-1">
                    <fa-icon [icon]="faChartPie" class="fa-4x"></fa-icon>
                </div>
                <div class="col-md-10 col-md-offset-1">
                    <label>DATA</label> - The data shown in chart
                    <br>
                    <label>COLUMN</label> - Represents the X-axis
                    <br>
                    <label>ROW</label> - Represents the grouping of DATA in series
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>External App URL</label>
                        <input type="text" formControlName="externalAppUrl" class="form-control">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Renderer Template Source</label>
                        <textarea type="text" class="form-control vertical-expand"
                            formControlName="rendererTemplateSource"></textarea>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <input class="upload-input" id="upload-input-{{report.id}}" type="file" name="file"
                            [attr.id-el]="report.id" (change)="uploadFile($event.target)">
                        <label class="btn btn- btn-sm float-right" for="upload-input-{{report.id}}"
                            title="Upload Report template">
                            <fa-icon [icon]="faUpload"></fa-icon>
                            Upload Report template
                        </label>
                    </div>
                </div>
            </div>
            <div class="ibox-footer">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <a routerLink="/reports/orb/{{report.id}}" class="btn btn-sm btn-dark-blue ml-1 float-right"
                                title="Orb">
                                <fa-icon [icon]="faThList"></fa-icon>
                            </a>
                            <a target="_self" routerLink="/cheetah/api/reports/csv/{{report.id}}"
                                download="{{report.name}}.csv" class="btn btn-dark-blue btn-sm ml-1 float-right"
                                title="CSV">
                                <fa-icon [icon]="faFile"></fa-icon>
                            </a>
                            <a class="btn btn-sm btn-dark-blue ml-1 float-right" (click)="updateDetails()"
                                title="Update">
                                <fa-icon [icon]="faSyncAlt"></fa-icon>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>
