import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommunicationStrategyService } from '../services/communications-strategy.service';
import { CommunicationStrategy } from '../data/model';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-comm-strategy-tab',
  templateUrl: './comm-strategy-tab.component.html',
  styleUrls: ['./comm-strategy-tab.component.scss']
})
export class CommStrategyTabComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private tiltle: Title,
              private communicationStrategyService: CommunicationStrategyService) { }

  selectedCommStrategy: CommunicationStrategy = new CommunicationStrategy();
  activeId = 1;
  ngOnInit(): void {
    this.tiltle.setTitle('Communication Strategy');
    this.selectedCommStrategy.id = + this.route.snapshot.params.id;
    this.communicationStrategyService.getCommStrategy(this.selectedCommStrategy.id).subscribe((data: any) => {
      if (data.success) {
        this.selectedCommStrategy = data.entity;
      }
    }, () => {
      this.router.navigate(['/forbidden']);
    });
  }

}
