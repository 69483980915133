<div>
  <form>
    <label>Compare the activity date to a member attribute value:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="partyAttributeIntervalRestriction !== null">
        <div *ngFor="let partyAttributeInterval of partyAttributeIntervalRestriction; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeRestriction(partyAttributeInterval)" title="Remove Restriction">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="attribName_{{partyAttributeInterval.attrName}}">Attribute Name</label>
              <input class="form-control m-b" type="text" id="attribName_{{partyAttributeInterval.attrName}}" name="attribName" [(ngModel)]="partyAttributeInterval.attrName" (ngModelChange)="updateRestrictions()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="attribValFormat_{{partyAttributeInterval.attrName}}">Value Date Format</label>
              <input class="form-control m-b" type="text" id="attribValFormat_{{partyAttributeInterval.attrName}}" name="attribValFormat" [(ngModel)]="partyAttributeInterval.valueDateFormat" (ngModelChange)="updateRestrictions()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="attribIntLength_{{partyAttributeInterval.attrName}}">Interval Length</label>
              <input class="form-control m-b" type="number" id="attribIntLength_{{partyAttributeInterval.attrName}}" name="attribIntLength" [(ngModel)]="partyAttributeInterval.intervalLength" (ngModelChange)="updateRestrictions()" />
            </div>
            <div class="form-group">
              <label>Interval Unit</label>
              <select name="unit_{{partyAttributeInterval.attrName}}" class="form-control" [(ngModel)]="partyAttributeInterval.intervalUnit" (ngModelChange)="updateRestrictions()">
                <option *ngFor="let unit of units" [selected]="partyAttributeInterval.intervalUnit === unit.value" [value]="unit.value">{{unit.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label>Condition</label>
              <select name="condition_{{partyAttributeInterval.attrName}}" class="form-control" [(ngModel)]="partyAttributeInterval.condition" (ngModelChange)="updateRestrictions()">
                <option *ngFor="let condition of conditions" [selected]="partyAttributeInterval.condition === condition.value" [value]="condition.value">{{condition.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label>Inclusion</label>
              <select name="inclusion_{{partyAttributeInterval.attrName}}" class="form-control" [(ngModel)]="partyAttributeInterval.inclusion" (ngModelChange)="updateRestrictions()">
                <option *ngFor="let inclusion of inclusionValues" [selected]="partyAttributeInterval.inclusion === inclusion.value" [value]="inclusion.value">{{inclusion.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="friendlyMessage_{{partyAttributeInterval.attrName}}">Message:</label>
              <input class="form-control m-b" type="text" id="friendlyMessage_{{partyAttributeInterval.attrName}}" name="friendlyMessage" [(ngModel)]="partyAttributeInterval.friendlyMessage" (ngModelChange)="updateRestrictions()" />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initRestrictionObject()"><fa-icon [icon]="faPlus"></fa-icon> Add Attribute Interval</button>
      </div>
    </div>
  </form>
</div>
