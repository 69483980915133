import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(
    private http: HttpClient,
    private globalData: AppDataService
  ) { }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getLanguages = function (params?: any): any {
    if (params) {
      return this.http.get(this.globalData.baseUrl + 'languages/', this.setParams(params));
    } else {
      return this.http.get(this.globalData.baseUrl + 'languages/');
    }
  };

  getPrimaryLanguage = function (partyId: number, params: any): any {
     return this.http.get(this.globalData.baseUrl + 'party-languages/' + partyId, this.setParams(params));
  };
}
