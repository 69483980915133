<form [formGroup]="memberRelationshipForm">
    <div class="form-group">
        <div class="row">
            <div class="col-md-3">
                <label class="float-right control-label">Relationship Name</label>
            </div>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="relationshipName">
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <div class="col-md-3">
                <label for="bidirectional" class="float-right form-check-label">
                    Bidirectional
                </label>
            </div>
            <div class="col-md-3">
                <input id="bidirectional" type="checkbox" formControlName="bidirectional"/>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <div class="col-md-3">
                <label class="float-right control-label">From Role Name</label>
            </div>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="fromRoleName"/>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <div class="col-md-3">
                <label class="float-right control-label">To Role Name</label>
            </div>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="toRoleName"/>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <div class="col-md-3">
                <label class="float-right control-label">Attribute Name</label>
            </div>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="attrName"/>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <div class="col-md-3">
                <label class="float-right control-label">From Date</label>
            </div>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="fromDate">
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <div class="col-md-3">
                <label class="float-right control-label">To Date</label>
            </div>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="toDate">
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <div class="col-md-3">
                <label class="float-right control-label">Field Name</label>
            </div>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="fieldName"/>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <div class="col-md-3">
                <label class="float-right control-label">Field Index</label>
            </div>
            <div class="col-md-3">
                <input type="number" class="form-control" formControlName="fieldIndex">
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <div class="col-md-3">
                <label for="required" class="float-right form-check-label">
                    Required
                </label>
            </div>
            <div class="col-md-3">
                <input id="required" type="checkbox" formControlName="required"/>
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <div class="col-md-3">
                <label class="float-right control-label">Field Length</label>
            </div>
            <div class="col-md-3">
                <input type="number" class="form-control" formControlName="fieldLength">
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <div class="col-md-3">
                <label class="float-right control-label">Field Regex</label>
            </div>
            <div class="col-md-3">
                <input type="text" class="form-control" formControlName="fieldRegex">
            </div>
        </div>
    </div>
    <div class="form-group">
        <div class="row">
            <div class="col-md-4 offset-md-3">
                <span *ngIf="index !== null">
                    <button class="btn btn-attention" title="Cancel" (click)="emitEvent('reset', index)">
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </button>
                    <button id="create-member-relationship" class="btn btn-dark-blue" type="button" (click)="emitEvent('save', index)"
                        title="Add">
                        <fa-icon [icon]="faSave"></fa-icon>
                    </button>
                </span>
                <span *ngIf="index === null">
                    <button class="btn btn-attention" type="reset" title="Cancel">
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </button>
                    <button id="create-member-relationship" class="btn btn-dark-blue" type="button" (click)="emitEvent('add')"
                        title="Add">
                        <fa-icon [icon]="faPlus"></fa-icon>
                    </button>
                </span>
            </div>
        </div>
    </div>
</form>