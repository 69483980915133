<form [formGroup]="emailForm" novalidate>
    <div class="modal-header">
      <h1>{{title}}</h1>
    </div>
    <div class="modal-body">
        <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
            {{errorMsg}}
            <a (click)="clearErrorMessage()">
              <span class="float-right close-button">
                <fa-icon [icon]="faTimes"></fa-icon>
              </span>
            </a>
        </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>Email Address</label>
            <input type="email" name="email" formControlName="emailAddress"  class="form-control" autofocus/>
            <div class="mt-1" [hidden]="emailForm.controls.emailAddress.valid">
              <small class="text-danger">Input a valid email address</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>Email Address Name</label>
            <input type="text" name="email_name" formControlName="addressName"  class="form-control"/>
            <div class="mt-1" [hidden]="emailForm.controls.addressName.valid">
              <small class="text-danger">Input email name</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group form-check">
            <input type="checkbox" formControlName="isPrimary" id="isPrimary" class="form-check-input" />
            <label for="isPrimary" class="form-check-label">Primary</label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" type="submit" (click)="ok()" [disabled]="!emailForm.valid || !emailForm.dirty"> Save </button>
      <button class="btn btn-warning" type="button" (click)="cancel()"> Cancel </button>
    </div>
  </form>

