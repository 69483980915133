import { StatusHelperService } from './../services/helpers/status-helper.service';
import { Title } from '@angular/platform-browser';
import { SponsorsService } from './../services/sponsors.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SponsorModalComponent } from './../sponsor-modal/sponsor-modal.component';

@Component({
  selector: 'app-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.scss']
})
export class SponsorComponent implements OnInit {

  selectedPerson: any;
  faPencilAlt = faPencilAlt;
  active = 1;
  personId: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sponsorsService: SponsorsService,
    private title: Title,
    private statusHelperService: StatusHelperService,
    private modalHelper: NgbModal
  ) { }

  statusHelper = this.statusHelperService.getStatus('badge');

  tabs = [
    { title: 'Sponsorship Groups', activeId: 1 },
    { title: 'Promotions', activeId: 2 },
    { title: 'Segments', activeId: 3 },
    { title: 'Attributes', activeId: 4 },
    { title: 'Contacts', activeId: 5 },
    { title: 'Configurations', activeId: 6 },
    { title: 'Promotion Category', activeId: 7 },
    { title: 'Campaigns', activeId: 8 }
  ];

  ngOnInit(): void {
    this.personId = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    if (this.personId ) {
      this.getSponsor(this.personId );
    }
  }

  getSponsor(id: number): void {
    this.sponsorsService.getSponsor(id).subscribe((data) => {
      if (data.success) {
        this.selectedPerson = data.entity;
        this.title.setTitle(this.selectedPerson.name);
      }
    }, () => {
      this.router.navigate(['sponsors']);
    });
  }

  // isSelected(name: string): boolean {
  //   for (const tab of this.tabs) {
  //     if (tab.title === name) {
  //       return tab.active;
  //     }
  //   }
  // }

  // select(name: string): void {
  //   this.tabs.forEach((tab) => {
  //     if (tab.title === name) {
  //       tab.active = true;
  //     } else {
  //       tab.active = false;
  //     }
  //   });
  // }

  parseResponse(data: any): void {
    if (data.success) {
      this.selectedPerson = data.entity;
    }
  }

  edit(id: number): void {
    this.sponsorsService.getSponsor(id).subscribe((data) => {
      if (data.success) {
        const instance = this.modalHelper.open(SponsorModalComponent);
        instance.componentInstance.sponsor = data.entity;
        instance.componentInstance.selectedPersonId = this.selectedPerson.id;
        instance.componentInstance.successEvent.subscribe(() => {
          this.getSponsor(id);
          instance.close();
        });
      }
    });
  }


}
