<div class="panel-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group float-right">
          <a class="btn btn-primary btn-sm" (click)="attachmentModal()"><fa-icon [icon]="faPlus"></fa-icon> New attachment</a>
        </div>
      </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Mime/type</th>
                  <th>
                    <span class="float-right">Action</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="attachments.length < 1">
                  <td>No attachments to show</td>
                </tr>
                <tr *ngFor="let at of attachments">
                  <td>{{at.name}}</td>
                  <td>{{at.mimeType}}</td>
                  <td class="project-actions">
                    <a class="btn btn-default btn-sm" (click)="attachmentModal(at.id)"><fa-icon [icon]="faPencilAlt"></fa-icon> Edit </a>
                    <a class="btn btn-danger btn-sm"  (click)="deleteAttachment(at)"><fa-icon [icon]="faTrash"></fa-icon> Delete </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
    </div>
</div>