<form [formGroup]="reportForm" novalidate="">
    <div class="modal-header">
        <h1>New Report</h1>
    </div>
    <div class="modal-body">
        <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
            {{errorMsg}}
            <a (click)="clearErrorMessage()">
                <span class="float-right close-button">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </span>
            </a>
        </div>
        <div class="form-group">
            <label>Name</label>
            <input type="text" formControlName="name" (change)="checkIsAlphaNumerical($event.target.value)" name="name"
                class="form-control" autofocus>
            <div class="mt-2" [hidden]="reportForm.controls.name.valid">
                <small class="text-danger">Input name</small>
            </div>
            <div class="mt-2" [hidden]="isAlphaNumerical">
                <small class="text-danger">Name must be alphanumerical</small>
            </div>
        </div>
        <div class="form-group">
            <label>Status</label>
            <select class="form-control" formControlName="status">
                <option *ngFor="let option of statuses" value="{{option}}">{{option}}</option>
            </select>
            <div class="mt-2" [hidden]="reportForm.controls.status.valid">
                <small class="text-danger">Select status</small>
            </div>
        </div>
        <div class="form-group">
            <label>Select segments</label>
            <ng-select [items]="filteredSegments" bindLabel="name" [multiple]="true"
                formControlName="segmentsCollection" [hideSelected]="true" [closeOnSelect]="false">
            </ng-select>
            <div class="mt-2" [hidden]="reportForm.controls.segmentsCollection.valid">
                <small class="text-danger">Select segments</small>
            </div>
        </div>
        <div class="form-group">
            <label>Description</label>
            <textarea type="text" formControlName="description" class="form-control vertical-expand"></textarea>
        </div>
        <div class="form-group">
            <label>Query</label>
            <textarea type="text" formControlName="sqlQuery" class="form-control vertical-expand"></textarea>
        </div>
        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <div class="form-group form-check">
                        <input formControlName="interactiveReport" type="checkbox" class="form-check-input">
                        <label for="interactiveReport">
                            Interactive
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <div class="form-group form-check">
                        <input formControlName="dashboardReport" type="checkbox" class="form-check-input">
                        <label for="dashboardReport">
                            Dashboard
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <div class="form-group form-check">
                        <input formControlName="outboundReport" type="checkbox" class="form-check-input">
                        <label for="outboundReport">
                            Outbound
                        </label>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <div class="form-group form-check">
                        <input formControlName="forStaging" type="checkbox" class="form-check-input">
                        <label for="forStaging">
                            Integrations
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="reportForm.controls.dashboardReport.value">
            <div class="col-md-12">
                <div class="form-group">
                    <label>Chart type</label>
                    <select formControlName="chartOpt" class="form-control mb-3">
                        <option *ngFor="let option of chartOpts" value="{{option}}">
                            {{option}}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" [disabled]="!(reportForm.valid && isAlphaNumerical)"
            type="submit" (click)="ok()"> Save </button>
        <button class="btn btn-warning" type="button" (click)="cancel()"> Cancel </button>
    </div>
</form>