<div class="panel-body">
  <div class="row">
    <div class="full-width">
      <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <table [(ngModel)]="attrs" name="attrs" ngDefaultControl
        class="table table-hover table-bordered table-pointable infinite-table" width="100%">
        <thead>
          <tr>
            <th class="width-23">Name</th>
            <th class="width-33">Value</th>
            <th class="width-10-rem">
              <span class="float-left">Action</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <ng-container>

          <!--*ngFor="let attr of attrs | sort: 'attrName' : true ; let i = index">-->
            <tr *ngFor="let attr of attrs">
              <td [attr.title]="attr.attrName">{{attr.attrName}}</td>
              <td [attr.title]="attr.attrValue" class="word-break-break-all">
                {{attr.attrValue}}</td>
              <td class="table-buttons">
                <a class="btn btn-sm float-left btn-primary"
                  *ngIf="!disableActions && allowedToEditAttribs"
                  (click)="partyAttrModal(attr)"
                  [hidden]='disableActions' title="Edit">
                  <fa-icon [icon]="faPencilAlt"></fa-icon>
                </a>
                <a class="btn btn-sm float-left btn-attention"
                   (click)="deleteAttribute(attr.id, attr.attrName)" title="Delete"
                   *ngIf="!disableActions && allowedToEditAttribs"
                   [hidden]="disableActions">
                  <fa-icon [icon]="faTrash"></fa-icon>
                </a>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
