import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'listGroupBy'
})
export class ListGroupByPipe implements PipeTransform {

  transform(array: any[], groupBy: string): any[] {
    return array.reduce((r, a) => {
      r[a[groupBy]] = r[a[groupBy]] || [];
      r[a[groupBy]].push(a);
      return r;
    }, Object.create(null));
  }

}
