<div>
  <form>
    <label>The previous amount Rewarded must be:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="rewardAmountThreshold !== null">
        <div *ngFor="let rewAmtThreshold of rewardAmountThreshold; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeThreshold(rewCntThreshold)" title="Remove Threshold">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="thresholdVal_{{rewAmtThreshold.thresholdValue}}">at least (greater than or equal to):</label>
              <input class="form-control m-b" type="number" id="thresholdVal_{{rewAmtThreshold.thresholdValue}}" name="thresholdValue" [(ngModel)]="rewAmtThreshold.thresholdValue" (ngModelChange)="updateThresholds()" />
            </div>
            <div class="form-group">
              <a
                class="control-label"
                ng-class="{ 'disabled-link': readOnly }"
                (click)="
                  regexSearchModal(
                    'PROMOTIONS',
                    { object: rewAmtThreshold, name: 'promotionCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                title="Search for Promotions"
                >for Promotions (leave blank for current or use a comma separated list of Codes):</a
              >
              <input
                class="form-control form-readonly m-b"
                type="text"
                id="promoCodes_{{ rewAmtThreshold.thresholdValue }}"
                name="promotionCodes"
                [(ngModel)]="rewAmtThreshold.promotionCodes"
                (ngModelChange)="updateThresholds()"
                (click)="
                  regexSearchModal(
                    'PROMOTIONS',
                    { object: rewAmtThreshold, name: 'promotionCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                readonly
              />
            </div>
            <div class="form-group">
              <a
                class="control-label"
                ng-class="{ 'disabled-link': readOnly }"
                (click)="
                  regexSearchModal(
                    'BEHAVIORS',
                    { object: rewAmtThreshold, name: 'behaviorCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                title="Search for Behaviors"
                >on Behaviors with Codes (leave blank for any):</a
              >
              <input
                class="form-control form-readonly m-b"
                type="text"
                id="behCodes_{{ rewAmtThreshold.thresholdValue }}"
                name="behaviorCodes"
                [(ngModel)]="rewAmtThreshold.behaviorCodes"
                (ngModelChange)="updateThresholds()"
                (click)="
                  regexSearchModal(
                    'BEHAVIORS',
                    { object: rewAmtThreshold, name: 'behaviorCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                readonly
              />
            </div>
            <div class="form-group">
              <label class="control-label" for="rewardCodes_{{rewAmtThreshold.thresholdValue}}">for Reward types of (leave blank for any):</label>
              <input class="form-control m-b" type="text" id="rewardCodes_{{rewAmtThreshold.thresholdValue}}" name="rewardCodes" [(ngModel)]="rewAmtThreshold.rewardCodes" (ngModelChange)="updateThresholds()" />
            </div>
            <div class="form-group">
              <label>for the current:</label>
              <select name="thresholdPeriod_{{rewAmtThreshold.thresholdValue}}" class="form-control"
                      [(ngModel)]="rewAmtThreshold.thresholdPeriod.value"
                      (ngModelChange)="!rewAmtThreshold.thresholdPeriod ? (rewAmtThreshold['thresholdPeriod'] = { value: $event, class: 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules$Thresholds$RewardAmountThreshold$ThresholdPeriod'}) : null; updateThresholds()">
                <option *ngFor="let period of periods"
                        [value]="period.value">{{period.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label>Should the total include activities from later in the period than the current activity?</label>
              <select name="inclusion_{{rewAmtThreshold.thresholdValue}}" class="form-control"
                      [(ngModel)]="rewAmtThreshold.thresholdPeriod.inclusion"
                      (ngModelChange)="(!rewAmtThreshold.thresholdPeriod) ? (rewAmtThreshold['thresholdPeriod'] = { inclusion: $event, class: 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules$Thresholds$RewardAmountThreshold$ThresholdPeriod'}) : null; updateThresholds()">
                <option *ngFor="let inclusionVal of inclusionValues"
                        [value]="inclusionVal.value">{{inclusionVal.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="previousPeriodCount_{{rewAmtThreshold.thresholdValue}}">How many previous periods should be included in the time span (leave empty for only the current period):</label>
              <input class="form-control m-b" type="number" id="previousPeriodCount_{{rewAmtThreshold.thresholdValue}}" name="previousPeriodCount" [(ngModel)]="rewAmtThreshold.previousPeriodCount" (ngModelChange)="updateThresholds()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="relationshipName_{{rewAmtThreshold.thresholdValue}}">Include amounts from members related by (leave blank for current member only or use a comma separated list):</label>
              <input class="form-control m-b" type="text" id="relationshipName_{{rewAmtThreshold.thresholdValue}}" name="relationshipName" [(ngModel)]="rewAmtThreshold.relationshipName" (ngModelChange)="updateThresholds()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="friendlyMessage_{{rewAmtThreshold.thresholdValue}}">Message:</label>
              <input class="form-control m-b" type="text" id="friendlyMessage_{{rewAmtThreshold.thresholdValue}}" name="friendlyMessage" [(ngModel)]="rewAmtThreshold.friendlyMessage" (ngModelChange)="updateThresholds()" />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initThresholdObject()"><fa-icon [icon]="faPlus"></fa-icon> Add Reward Amount Threshold</button>
      </div>
    </div>
  </form>
</div>
