import { Component, OnInit, Input } from '@angular/core';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AccessRoleModalComponent } from '../access-role-modal/access-role-modal.component';
import { AccessRoleService } from '../services/access-role.service';
import { faPlus, faUnlockAlt, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Role } from '../data/model';

@Component({
  selector: 'app-access-roles',
  templateUrl: './access-roles.component.html',
  styleUrls: ['./access-roles.component.scss']
})
export class AccessRolesComponent implements OnInit {

  @Input() personId: number;
  accessRoles: Role[];
  faPlus = faPlus;
  faUnlockAlt = faUnlockAlt;
  faTimes = faTimes;

  constructor(private accessRoleService: AccessRoleService,
              private resonseHelper: ResponseHelperService,
              private modalHelper: NgbModal) { }

  ngOnInit(): void {
    this.setAccessRoles();
  }

  roleModal(): void {
    this.accessRoleService.getAllAccessRoles().subscribe((roles: any) => {
      if (roles.success) {
        const instance = this.modalHelper.open(AccessRoleModalComponent);
        instance.componentInstance.personId = this.personId;
        instance.componentInstance.roles = roles.entity.filter((element: Role) => element.roleName !== 'MEMBER');
        instance.componentInstance.successEvent.subscribe((data: any) => {
          if (data) {
            instance.close();
            this.setAccessRoles();
          }
        });
      }
    });
  }

  setAccessRoles(): void {
    this.accessRoleService.getPartyAccessRoles(this.personId).subscribe((roles: any) => {
      if (roles.success) {
        this.accessRoles = roles.entity;
      }
    });
  }

  removeRole(role: Role): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Remove role with name: ' + role.roleName + '?';
    instance.componentInstance.header = 'Remove role';
    instance.componentInstance.successEvent.subscribe(() => {
      this.accessRoleService.removeAccessRole(this.personId, role.roleName).subscribe(() => {
        this.resonseHelper.success('Access role successfully removed', true);
        const roleIndex = this.accessRoles.findIndex((r: any) => role.parentRoleId === r.parentRoleId);
        this.accessRoles.splice(roleIndex, 1);
      }, (data: any) => {
        this.resonseHelper.error(this, data.error.error);
      });
    });
  }

}
