import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faCog, faPencilAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CommunicationStrategyConfigurationModalComponent } from 'src/app/communication-strategy-configuration-modal/communication-strategy-configuration-modal.component';
import { CommunicationStrategyModalComponent } from 'src/app/communication-strategy-modal/communication-strategy-modal.component';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Choice, Filter, SearchFilters } from 'src/app/data/class';
import { CommunicationStrategy, CommunicationStrategyConfiguration } from 'src/app/data/model';
import { parseResponse } from 'src/app/data/parseResponseFunction';
import { CommunicationStrategyService } from 'src/app/services/communications-strategy.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';

@Component({
  selector: 'app-communication-strategy-tab',
  templateUrl: './communication-strategy-tab.component.html',
  styleUrls: ['./communication-strategy-tab.component.scss']
})
export class CommunicationStrategyTabComponent implements OnInit {

  offset = new Date().getTimezoneOffset();
  show: boolean;
  isLoading: boolean;
  limit = 20;
  filter = new Filter();
  searchFilters: SearchFilters;
  statuses: Choice[];
  lock: boolean;
  lengthToCompare: number;
  faPlus = faPlus;
  faTrash = faTrash;
  faPencilAlt = faPencilAlt;
  faCog = faCog;
  filteredStatuses: Filter[] = [];
  communicationStrategies: any[] = [];
  showMoreButton: any = { name: 'Show More', active: true };
  fileNamePlease: any = { name: '', success: false };
  lockedTables: boolean;
  configurations: CommunicationStrategyConfiguration[] = [];
  endpointTypes: any[];
  comm: any[];
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(private tableHelperService: TableHelperService,
              private toastrService: ToastrService,
              private communicationStrategyService: CommunicationStrategyService,
              private modalHelper: NgbModal,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.show = true;
    this.searchFilters = {
      formName: 'communicationStrategySearch',
      searchCallback: () => { this.initNewSearch(); },
      filters: [{
        name: 'search',
        placeholder: 'Keyword...'
      }
      ],
      addEntityButtons: [{
        icon: faPlus,
        buttonText: 'New Communication Strategy',
        if: true,
        callback: () => { this.communicationStrategyModal(); },
        title: 'New Communication Strategy'
      }]
    };
  }

  clearKeyword(): void {
    this.initFilter();
    this.filter.search = '';
    this.getCommunicationStrategy(false);
  }

  initNewSearch(): void {
    this.initFilter();
    this.getCommunicationStrategy(false);
    this.configurations = null;
  }

  clearAllFilters(): void {
    this.filteredStatuses = [];
    this.setStatuses();
    this.filter.search = null;
    this.filter = new Filter();
    this.initFilter();
    this.getCommunicationStrategy(false);
  }

  getCommunicationStrategy(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.communicationStrategyService.getAllCommunicationStrategy(this.filter).subscribe((data: any) => {
      if (concat) {
        this.communicationStrategies = this.communicationStrategies.concat(parseResponse(data));
      } else {
        this.communicationStrategies = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
    }, () => {
      this.lockedTables = false;
      this.lock = false;
      this.isLoading = false;
      this.toastr.error('Error occured');
    });
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  setStatuses(): void {
    this.searchFilters.filters[1].value = this.tableHelperService.extractData(this.filteredStatuses);
  }

  deleteCommunicationStrategy(id: number): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Delete communication strategy with ID: ' + id;
    instance.componentInstance.header = 'Delete communication strategy?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.communicationStrategyService.deleteCommunicationStrategy(id).subscribe(() => {
        this.toastrService.success('Communication strategy removed');
        this.initFilter();
        this.getCommunicationStrategy(false);
        this.configurations = null;
        instance.close();
      }, (data: any) => {
        this.toastr.error(data.error.error);
        instance.close();
      });
    });
  }

  communicationStrategyModal(communicationStrategy?: CommunicationStrategy): void {
    const instance = this.modalHelper.open(CommunicationStrategyModalComponent);
    instance.componentInstance.communicationStrategy = communicationStrategy;
    instance.componentInstance.successEvent.subscribe((data: any) => {
      if (data) {
        this.toastrService.success(`Communication strategy successfully ${instance.componentInstance.isEdit ? 'updated' : 'created'}`);
        this.initNewSearch();
        instance.close();
      }
    });
  }

  selectCommStrategy(event, id: number): void {
    if (event) {
      $('tr.active').removeClass('active');
      $(event.currentTarget).toggleClass('active');
    }
    this.communicationStrategyService.getCommStrategyConfigurations(id).subscribe((configurations: any) => {
      this.configurations = configurations.entity;
    });
  }

  addConfig(id: number): void {
    const instance = this.modalHelper.open(CommunicationStrategyConfigurationModalComponent);
    instance.componentInstance.commStrategyId = id;
    instance.componentInstance.successEvent.subscribe((successEvent: string) => {
      if (successEvent) {
        this.toastrService.success('Configuration successfully created');
        this.selectCommStrategy(null, id);
        instance.close();
      }
    });
  }

  deleteConfiguration(id: number, index: number): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Delete configuration with ID: ' + id;
    instance.componentInstance.header = 'Delete configuration?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.communicationStrategyService.deleteCommStrategyConfiguration(id).subscribe(() => {
        this.toastrService.success('Configuration successfully removed');
        this.configurations.splice(index, 1);
        instance.close();
      }, (data: any) => {
        this.toastr.error(data.error.error);
        instance.close();
      });
    });
  }

  editConfigurationModal(communicationStrategyConfiguration: CommunicationStrategyConfiguration): void {
    this.communicationStrategyService.getCommStrategyConfiguration(communicationStrategyConfiguration.id).subscribe((config: any) => {
      if (config.success) {
        const instance = this.modalHelper.open(CommunicationStrategyConfigurationModalComponent);
        instance.componentInstance.communicationStrategyConfiguration = config.entity;
        instance.componentInstance.commStrategyId = communicationStrategyConfiguration.commStrategyId;
        instance.componentInstance.successEvent.subscribe((response: any) => {
          if (response) {
            this.toastrService.success('Configuration successfully updated');
            this.selectCommStrategy(null, communicationStrategyConfiguration.commStrategyId);
            instance.close();
          }
        });
      }
    });
  }

  createDateFromTime(inputTime: string): Date {
    if (inputTime) {
      const hour = inputTime.split(':')[0];
      const min = inputTime.split(':')[1];
      const date = new Date();
      date.setHours(Number(hour));
      date.setMinutes(Number(min));
      date.setSeconds(0);
      return date;
    }
    return undefined;
  }

  unpackCommStrategyConfig(commStrategyConfig): any | undefined {
    if (commStrategyConfig) {
      const unpacked = Object.assign({}, commStrategyConfig);
      if (commStrategyConfig.blackOutPeriods) {
        unpacked.blackOutPeriods = commStrategyConfig.blackOutPeriods.split(',');
      }
      if (commStrategyConfig.toTime) {
        unpacked.toTime = this.createDateFromTime(commStrategyConfig.toTime);
      }
      if (commStrategyConfig.fromTime) {
        unpacked.fromTime = this.createDateFromTime(commStrategyConfig.fromTime);
      }
      return unpacked;
    }
    return undefined;
  }

}
