<div class="panel-body">
  <div class="row">
    <div class="col-lg-9" *ngIf="show">
        <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
        <div class="row">
          <table class="table table-pointable table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Code</th>
                <th>Name</th>
                <th>Campaign Cap</th>
                <th>Communication Strategy</th>
                <th>Status</th>
                <th>From</th>
                <th>Thru</th>
                <th>Expiry</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let c of campaigns" (click)="showCampaign($event, c)">
                <td>{{c.id}}</td>
                <td [attr.title]="c.code">{{c.code}}</td>
                <td [attr.title]="c.name">{{c.name}}</td>
                <td>{{c.cap}}</td>
                <td>{{c.communicationStrategyName}}</td>
                <td>{{c.status}}</td>
                <td>{{c.fromDate}}</td>
                <td>{{c.thruDate}}</td>
                <td>{{c.expirationDate}}</td>
                <td>
                  <div class="actions float-left inline-table">
                    <a class="btn btn-dark-blue btn-sm edit action" (click)="openCampaignModal(c)" title="Edit"><fa-icon [icon]="faPencilAlt"></fa-icon></a>
                    <button class="btn btn-attention btn-sm delete action" [disabled]="c.status==='DELETED'" (click)="deleteCampaign(c)" title="Delete"><fa-icon [icon]="faTrash"></fa-icon></button>
                  </div>
                </td>
              </tr>
              <tr app-is-there-more [numColumns]="10" [newLengthEvent]="newLengthEvent"></tr>
            </tbody>
          </table>
        </div>
        <app-add-more-data-to-list (onScroll)="getCampaigns(true)" [lock]="lock" [isLoading]="isLoading"></app-add-more-data-to-list>
    </div>
    <div class="col-lg-3">
      <div class="row justify-content-center">
        <h2><span class="light-blue">Promotions:</span></h2>
      </div>
      <span class="row justify-content-center" [hidden]="promotions.length>0">No promotions</span>
        <div  [hidden]="promotions.length===0" class="row integration-details-bar"></div>
          <div [hidden]="promotions.length===0" class="border-card" *ngFor="let prom of promotions; let i=index" [ngClass]="{'margin-top-10':i>0}">
            <a routerLink="/programs/{{prom.program.id}}/promotions">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-4">
                    <span>Name:</span>
                  </div>
                  <div class="col-md-8 text-right">
                    <span>{{prom.name}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <span>Type:</span>
                  </div>
                  <div class="col-md-8 text-right">
                    <span>{{prom.promotionTypeName}}</span>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4">
                    <span>Program:</span>
                  </div>
                  <div class="col-md-8 text-right">
                    <span>{{prom.program.name}}</span>
                  </div>
                </div>
                <div class="row" [hidden]="!prom.promotionCategoryName">
                  <div class="col-md-4">
                    <span>Category:</span>
                  </div>
                  <div class="col-md-8 text-right">
                    <span>{{prom.promotionCategoryName}}</span>
                  </div>
                </div>
                <div class="row" [hidden]="!(prom.promotionCategoryName &amp;&amp; prom.sponsorCap)">
                  <div class="col-md-4">
                    <span>Sponsor Cap:</span>
                  </div>
                  <div class="col-md-8 text-right">
                    <span>{{prom.sponsorCap}}</span>
                  </div>
                </div>
              </div>
            </a>
          </div>
          <div [hidden]="promotions.length===0" class="row integration-details-bar"></div>
          <div class="row member-notes justify-content-center">
            <button [hidden]="!selectedCampaign" [disabled]="!showMoreButton.active" class="btn btn-sm btn-dark-blue"  type="button" (click)="showMore()">{{showMoreButton.name}}</button>
          </div>
    </div>
  </div>
</div>
