<div class="xml-preview">
  <!--<div class="row m-b-sm">
    <button id="toPreview" class="btn btn-dark-blue" type="button" title="Load From Builder"><i class="fa fa-arrow-right"></i></button>
    <small>Click to load the editor from the configuration builder.</small>
  </div>
  <div class="row m-b-sm">
    <button id="preview-xml" class="btn btn-dark-blue" type="button" ng-click="$ctrl.loadXML()" title="Send To Builder"><i class="fa fa-arrow-left"></i></button>
    <small>Click to send the configuration to the configuration builder.</small>
  </div>-->
  <div class="row">
    <pre id="jsonPreview" name="jsonPreview" ngDefaultControl [(ngModel)]="ruleSource" value="ruleSource">{{ruleSource | json}}</pre>
  </div>
</div>
