import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faPencilAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Choice, Filter, SearchFilters } from 'src/app/data/class';
import { PromotionType } from 'src/app/data/model';
import { parseResponse } from 'src/app/data/parseResponseFunction';
import { PromotionTypeModalComponent } from 'src/app/promotion-type-modal/promotion-type-modal.component';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { PromotionTypeService } from 'src/app/services/promotion-type.service';

@Component({
  selector: 'app-promotion-types-tab',
  templateUrl: './promotion-types-tab.component.html',
  styleUrls: ['./promotion-types-tab.component.scss']
})
export class PromotionTypesTabComponent implements OnInit {

  limit = 20;
  isLoading: boolean;
  filter = new Filter();
  searchFilters: SearchFilters;
  statuses: Choice[];
  lock: boolean;
  lengthToCompare: number;
  faPlus = faPlus;
  tabs: any[];
  faTrash = faTrash;
  faPencilAlt = faPencilAlt;
  filteredStatuses: Filter[] = [];
  promotionTypes: any[] = [];
  promotions: any[] = [];
  selectedCampaign: any;

  showMoreButton: any = {name: 'Show More', active: true};
  fileNamePlease: any = {name: '', success: false};
  lockedTables: any = {state: false};
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(private tableHelperService: TableHelperService,
              private toastr: ToastrService,
              private promotionTypeService: PromotionTypeService,
              private modalHelper: NgbModal,
              private title: Title){}

  ngOnInit(): void {
    this.title.setTitle('Programs');
    this.searchFilters = {
      formName: 'campaignSearch',
      searchCallback: () => { this.initNewSearch(); },
      filters: [{
          name: 'search',
          placeholder: 'Keyword...'
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown'
        }
      ],
      addEntityButtons: [{
          icon: faPlus,
          buttonText: 'New Promotion Type',
          if: true,
          callback: () => { this.promotionTypeModal(); },
          title: 'New Promotion Type'
       }]
    };
  }

  clearKeyword(): void {
    this.initFilter();
    this.filter.search = '';
    this.getPromotionType(false);
  }

  initNewSearch(): void {
    this.initFilter();
    this.getPromotionType(false);
  }

  clearAllFilters(): void {
    this.filteredStatuses = [];
    this.setStatuses();
    this.filter.search = null;
    this.filter = new Filter();
    this.initFilter();
    this.getPromotionType(false);
  }

  getPromotionType(concat: boolean): void{
    this.filter = this.searchFilters.getFilters(this.filter);
    this.isLoading = true;
    this.promotionTypeService.getAllPromotionTypes(this.filter).subscribe(data => {
      if (concat) {
        this.promotionTypes = this.promotionTypes.concat(parseResponse(data));
      } else {
        this.promotionTypes = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables.state = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables.state;
      this.isLoading = false;
    }, err => {
      this.lockedTables.state = false;
      this.lock = false;
      this.isLoading = false;
      this.toastr.error('Error occured!');
    });
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  deletePromotionType(promotionType: PromotionType): void{
    const instance = this.modalHelper.open(ConfirmDialogComponent, {size: 'sm'});
    instance.componentInstance.text = 'Delete promotion type with ID: ' + promotionType.id;
    instance.componentInstance.header = 'Delete promotion type?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.promotionTypeService.deletePromotionType(promotionType.id).subscribe(() => {
          this.toastr.success('Promotion type successfully removed');
          this.initFilter();
          this.initNewSearch();
          instance.close();
          promotionType.status = 'DELETED';
      });
    });
  }

  promotionTypeModal(promotionType?: PromotionType): void {
    const instance = this.modalHelper.open(PromotionTypeModalComponent);
    instance.componentInstance.promotionType = promotionType;
    instance.componentInstance.successEvent.subscribe(data => {
      if (data){
        this.toastr.success(`Promotion type  ${instance.componentInstance.isEdit ? 'updated' : 'created'}`);
        this.initNewSearch();
        instance.close();
      }
    });
  }
}
