import { Component, Input, OnInit } from '@angular/core';
import { faFileCode, faPencilAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppDataService } from 'src/app/app-data.service';
import { CommunicationContent, SearchFilters } from 'src/app/data/class';
import { CommunicationsService } from 'src/app/services/communications.service';
import { CommunicationContentModalComponent } from '../communication-content-modal/communication-content-modal.component';
import { CommunicationContentTemplateModalComponent } from '../communication-content-template-modal/communication-content-template-modal.component';
import {LanguageService} from "../../services/language.service";
import {Language} from "../../data/model";

@Component({
  selector: 'app-communication-content-tab',
  templateUrl: './communication-content-tab.component.html',
  styleUrls: ['./communication-content-tab.component.scss']
})
export class CommunicationContentTabComponent implements OnInit {

  @Input() commId: number;
  @Input() endpointType: string;
  commContent: CommunicationContent[];
  language: Map<number, Language>;
  searchFilters: SearchFilters;
  faFileCode = faFileCode;
  faPencilAlt = faPencilAlt;
  faPlus = faPlus;
  faTrash = faTrash;

  constructor(
    private communicationsService: CommunicationsService,
    private appDataService: AppDataService,
    private languageService: LanguageService,
    private modalHelper: NgbModal
  ) {
    this.language = new Map<number, Language>();
    this.languageService.getLanguages().subscribe((res: any) => {
      res.entity.forEach(lang => {
        this.language.set(lang.id, lang);
      });
    });
  }

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'generic',
      buttonsWithText: false,
      showSearchFilters: false,
      filters: [
      ],
      searchCallback: () => { this.getContents(); },
      addEntityButtons: [
        {
          icon: faPlus,
          class: 'form-group',
          title: 'Add Content',
          buttonText: 'Add Content',
          callback: () => { this.contentModal(); },
          if: this.appDataService.isCSRManager
        }
      ]
    };
  }

  getContents(): void {
    this.communicationsService.getCommunicationContents(this.commId).subscribe((res: any) => {
      this.commContent = res.entity;
    }, ((res: any) => {
      console.log(res);
    }));
  }

  templateModal(template: string): void {
    const instance = this.modalHelper.open(CommunicationContentTemplateModalComponent);
    instance.componentInstance.template = template;
  }

  contentModal(content?: any): void {
    const instance = this.modalHelper.open(CommunicationContentModalComponent, { size: 'xl' });
    instance.componentInstance.content = content;
    instance.componentInstance.commId = this.commId;
    instance.componentInstance.endpointType = this.endpointType;
    instance.componentInstance.successEvent.subscribe(() => {
      this.getContents();
      instance.close();
    });
  }

}
