import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';
import { ResponseHelperService } from './helpers/response-helper.service';

@Injectable({
  providedIn: 'root'
})
export class MemberMeasuresService {

  constructor(
    private http: HttpClient,
    private globalData: AppDataService,
    private responseHelperService: ResponseHelperService) { }

    setParams(params: any): any {
      const paramsToSend: any = {};
      paramsToSend.params = params;
      return paramsToSend;
    }

    getMeasures(params: any): any {
      return this.http.get<any>(this.globalData.baseGoatUrl + 'measures', this.setParams(params));
    }
}
