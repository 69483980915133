import { faCheckSquare, faSpinner, faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit, Input } from '@angular/core';
import { AppDataService } from 'src/app/app-data.service';

@Component({
  selector: 'app-filter-header-users',
  templateUrl: './filter-header-users.component.html',
  styleUrls: ['./filter-header-users.component.scss']
})
export class FilterHeaderUsersComponent implements OnInit {

  @Input() searchFilters: any;
  @Input() filterSegments: any;
  @Input() filter: any;
  faCheckSquare = faCheckSquare;
  faSpinner = faSpinner;
  faUserPlus = faUserPlus;

  constructor(
    public appDataService: AppDataService
  ) { }

  ngOnInit(): void {
  }

}
