<div [ngClass]="filter.inputClass" class="search-filter-fields"  *ngIf="section.open">
    <div class="input-group dat font-relative-width">
      <input class="form-control cursor-pointer" [minDate]="minDate" [maxDate]="maxDate" placeholder="{{filter.placeholder}}" name="d" ngbDatepicker readonly [footerTemplate]="footerTemplateDateModel"
        #d="ngbDatepicker" [(ngModel)]="dateModel"
        (click)= "d.toggle()"
        (ngModelChange)="formatDate($event)">
        <ng-template #footerTemplateDateModel>
          <div class="width-100 datePickerTemplate">
          <button class="buttonDateTemplate"  (click)="clearDate(); d.toggle()">Clear</button>
          </div>
        </ng-template>
      <div class="input-group-append">
        <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button">
          <fa-icon [icon]="faCalendar"></fa-icon>
        </button>
      </div>
    </div>
</div>