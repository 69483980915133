import { OutboundModalComponent } from './../outbound-modal/outbound-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OutboundService } from './../../services/outbound.service';
import { ReportsService } from './../../services/reports.service';
import { Filter } from './../../data/class';
import { StatusHelperService } from './../../services/helpers/status-helper.service';
import { TableHelperService } from './../../services/helpers/table-helper.service';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Report, OutboundIntegration } from 'src/app/data/model';
import { faPlus, faCogs, faPencilAlt, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { SearchFilters } from '../../data/class';
import { UserPreferencesService } from 'src/app/services/user-preferences.service';
import { ToastrService } from 'ngx-toastr';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-outbound',
  templateUrl: './outbound.component.html',
  styleUrls: ['./outbound.component.scss']
})
export class OutboundComponent implements OnInit {

  isLoading = false;
  lockedTables = false;
  lock = false;
  filteredIntegrationStatuses: any[] = [];
  filter = new Filter();
  limit = 20;
  statuses: any[];
  reports: Report[];
  outbounds: OutboundIntegration[];
  lengthToCompare: number;
  selectedOutbound: OutboundIntegration;
  statusChoices = this.tableHelperService.getStatuses('ENTITY');
  searchFilters: SearchFilters;
  faPlus = faPlus;
  faCogs = faCogs;
  faPencilAlt = faPencilAlt;
  faFolderOpen = faFolderOpen;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private titleHelper: Title,
    private tableHelperService: TableHelperService,
    private statusHelperService: StatusHelperService,
    private reportsService: ReportsService,
    private outboundService: OutboundService,
    private userPreferences: UserPreferencesService,
    private modalHelper: NgbModal,
    private toastr: ToastrService
  ) { }

  statusHelper = this.statusHelperService.getStatus('badge');

  ngOnInit(): void {
    this.titleHelper.setTitle('Outbound Integrations');

    this.searchFilters = {
      formName: 'outbound_search',
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.statusChoices,
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
          value: this.userPreferences.service.getPreference('outbound.defaultFilterValues.status')
        }
      ],
      searchCallback: () => { this.initNewSearch(); },
    };

    this.initFilter();
    this.setStatuses();
    this.getAllReports();
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  getAllReports(): void {
    this.reportsService.getAllReports().subscribe((data: any) => {
      if (data.success) {
        this.reports = parseResponse(data);
      }
    });
  }

  getOutbound(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.outboundService.getOutbounds(this.filter).subscribe((data: any) => {
      if (concat) {
        this.outbounds = this.outbounds.concat(parseResponse(data));
      } else {
        this.outbounds = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
    }, () => {
      this.lockedTables = false;
      this.lock = false;
      this.isLoading = false;
      this.toastr.error('Error occured!');
    });
  }

  initNewSearch(): void {
    this.initFilter();
    this.getOutbound(false);
  }

  showOutbound(event: any, outbound: OutboundIntegration): void {
    $('tr.active').removeClass('active');
    $(event.currentTarget).toggleClass('active');
    this.selectedOutbound = outbound;
  }

  showIntegration(): boolean {
    if (this.selectedOutbound !== undefined) {
      return true;
    }
    return false;
  }

  outboundModal(id?: number, e?: any): void {
    if (id) {
      this.outboundService.getOutbound(id).subscribe((data: any) => {
        if (data.success) {
          const instance = this.modalHelper.open(OutboundModalComponent);
          instance.componentInstance.integration = parseResponse(data);
          instance.componentInstance.successEvent.subscribe(() => {
            this.initNewSearch();
            instance.close();
          });
        }
      });
    } else {
      const instance = this.modalHelper.open(OutboundModalComponent);
      instance.componentInstance.successEvent.subscribe(() => {
        this.initNewSearch();
        instance.close();
      });
    }
  }
}
