<form #f="ngForm" name="form">
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-md-12 reminder-modal">
        <h3>New Earning Opportunity</h3>
        <div class="custom-group">
          <div class="form-group">
            <label>Number of Days</label>
            <input type="number" min="0" class="form-control" name="daysAfterPromotionStart.days"
              [(ngModel)]="item.communicationReminder.daysAfterPromotionStart.days">
          </div>
          <div class="form-group">
            <label>Promotion Start Date</label>
            <select name="daysAfterPromotionStart.promotionDate"
              [(ngModel)]="item.communicationReminder.daysAfterPromotionStart.promotionDate" class="form-control">
              <option [selected]="item.communicationReminder.daysAfterPromotionStart.promotionDate === option"
                *ngFor="let option of promotionStart" value="{{option}}">{{option}}</option>
            </select>
          </div>
          <div class="form-group">
            <label>How many to send each member during the period</label>
            <input type="number" min="0" class="form-control" name="daysAfterPromotionStart.memberRepeating"
              [(ngModel)]="item.communicationReminder.daysAfterPromotionStart.memberRepeating">
          </div>
        </div>
        <h3>Expiring Earning Opportunity</h3>
        <div class="custom-group">
          <div class="form-group">
            <label>Number of Days</label>
            <input type="number" min="0" class="form-control" name="daysBeforePromotionExpires.days"
              [(ngModel)]="item.communicationReminder.daysBeforePromotionExpires.days">
          </div>
          <div class="form-group">
            <label>Promotion Start Date</label>
            <select name="daysBeforePromotionExpires.promotionDate"
              [(ngModel)]="item.communicationReminder.daysBeforePromotionExpires.promotionDate" class="form-control">
              <option [selected]="item.communicationReminder.daysBeforePromotionExpires.promotionDate === option"
                *ngFor="let option of promotionStart" value="{{option}}">{{option}}</option>
            </select>
          </div>
          <div class="form-group">
            <label>How many to send each member during the period</label>
            <input type="number" min="0" class="form-control" name="daysBeforePromotionExpires.memberRepeating"
              [(ngModel)]="item.communicationReminder.daysBeforePromotionExpires.memberRepeating">
          </div>
        </div>
        <h3>No Activity Received</h3>
        <div class="custom-group">
          <div class="form-group">
            <label>Number of Periods</label>
            <input type="number" min="0" class="form-control" name="noEligibleActivityForPeriod.days"
              [(ngModel)]="item.communicationReminder.noEligibleActivityForPeriod.days">
          </div>
          <div class="form-group">
            <label>Periods</label>
            <select name="noEligibleActivityForPeriod.period"
              [(ngModel)]="item.communicationReminder.noEligibleActivityForPeriod.period" class="form-control">
              <option [selected]="item.communicationReminder.noEligibleActivityForPeriod.period === option"
                *ngFor="let option of periods" value="{{option}}">{{option}}</option>
            </select>
          </div>
          <div class="form-group">
            <label>Promotion Start Date</label>
            <select name="noEligibleActivityForPeriod.promotionDate"
              [(ngModel)]="item.communicationReminder.noEligibleActivityForPeriod.promotionDate" class="form-control">
              <option [selected]="item.communicationReminder.noEligibleActivityForPeriod.promotionDate === option"
                *ngFor="let option of promotionStart" value="{{option}}">{{option}}</option>
            </select>
          </div>
          <div class="form-group">
            <label>How many to send each member during the period</label>
            <input type="number" min="0" class="form-control" name="noEligibleActivityForPeriod.memberRepeating"
              [(ngModel)]="item.communicationReminder.noEligibleActivityForPeriod.memberRepeating">
          </div>
        </div>
        <h3>Single Message Per Period</h3>
        <div class="custom-group">
          <div class="form-group">
            <label>For the Current</label>
            <select name="singleMessagePerPeriod" [(ngModel)]="item.communicationReminder.singleMessagePerPeriod"
              class="form-control">
              <option [selected]="item.communicationReminder.singleMessagePerPeriod === option"
                *ngFor="let option of periods" value="{{option}}">{{option}}</option>
            </select>
          </div>
        </div>
        <h3>Multiple Messages Per Period</h3>
        <div class="custom-group">
          <div class="form-group">
            <label>Number of messages per period</label>
            <input type="number" min="0" class="form-control" name="multipleMessagePerPeriod.numberOfMessages"
              [(ngModel)]="item.communicationReminder.multipleMessagePerPeriod.numberOfMessages">
          </div>
          <div class="form-group">
            <label>For the Current</label>
            <select name="multipleMessagePerPeriod.period"
              [(ngModel)]="item.communicationReminder.multipleMessagePerPeriod.period" class="form-control" required="">
              <option [selected]="item.communicationReminder.multipleMessagePerPeriod.period === option"
                *ngFor="let option of periods" value="{{option}}">{{option}}</option>
            </select>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" (click)="ok()">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>