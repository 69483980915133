<div class="panel-body">
    <div class="row">
        <div class="col-md-9">
            <div class="row table-activities">
                <table class="table table-pointable table-bordered">
                    <thead>
                        <tr>
                            <th>
                                ID
                            </th>
                            <th>
                                Time
                            </th>
                            <th>
                                Comm ID
                            </th>
                            <th>
                                Party ID
                            </th>
                            <th>
                                Protocol ID
                            </th>
                            <th>
                                Message ID
                            </th>
                            <th>
                                Content
                            </th>
                            <th>
                                Job History ID
                            </th>
                            <th>
                                Status
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let endpoint of endpoints" (click)="showInfo(endpoint, $event)">
                            <td>{{endpoint.id}}</td>
                            <td>{{endpoint.time}}</td>
                            <td>{{endpoint.commId}}</td>
                            <td>{{endpoint.partyId}}</td>
                            <td>{{endpoint.protocolId}}</td>
                            <td>{{endpoint.messageId}}</td>
                            <td>{{endpoint.content}}</td>
                            <td>{{endpoint.jobHistoryId}}</td>
                            <td>{{endpoint.status}}</td>
                        </tr>
                        <tr app-is-there-more [numColumns]="9" [newLengthEvent]="newLengthEvent"></tr>
                    </tbody>
                </table>
            </div>
            <app-add-more-data-to-list (onScroll)="getCommStrategyConfigurations(true)" [lock]="lock" [isLoading]="isLoading"></app-add-more-data-to-list>
        </div>
        <div class="col-md-3">
            <div class="text-center">
                <h2><span class="light-blue">Candidate:</span></h2>
            </div>
            <span *ngIf="selectedEndpoint && selectedEndpoint.filters.length === 0">No filters</span>
            <div *ngIf="promotions.length > 0" class="row integration-details-bar">
                <div class="row border-card" *ngFor="let filter of entpoint.filters">
                    <a href="/programs/promotions/{{filter.id}}">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-4">
                                    <span class="float-left">Name:</span>
                                </div>
                                <div class="col-md-8">
                                    <span class="float-right text-right">{{filter.name}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <span class="float-left">Description:</span>
                                </div>
                                <div class="col-md-8">
                                    <span class="float-right text-right">{{filter.description}}</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-4">
                                    <span class="float-left">Sequence:</span>
                                </div>
                                <div class="col-md-8">
                                    <span class="float-right text-right">{{filter.sequence}}</span>
                                </div>
                            </div>
                        </div>
                    </a>
                </div>
                <div class="row">
                    <button *ngIf="selectedProgram" [disabled]="!showMoreButton.active"
                        class="btn btn-sm btn-dark-blue mt-20" type="button"
                        (click)="showMore()">{{showMoreButton.name}}</button>
                </div>
            </div>
        </div>
    </div>
</div>