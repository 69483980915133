import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { faCircle, faCog, faDownload, faList, faPencilAlt, faPlus, faTh, faTrash, faUpload } from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Title } from '@angular/platform-browser';
import { Choice, Filter, SearchFilters } from 'src/app/data/class';
import { Program } from 'src/app/data/model';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { ProgramService } from 'src/app/services/program.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { ProgramModalComponent } from 'src/app/program-modal/program-modal.component';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { ProgramImportModalComponent } from 'src/app/program-import-modal/program-import-modal.component';
import { saveAs } from 'src/app/data/saveAs';
import { parseResponse } from 'src/app/data/parseResponseFunction';



@Component({
  selector: 'app-program-tab',
  templateUrl: './program-tab.component.html',
  styleUrls: ['./program-tab.component.scss']
})
export class ProgramTabComponent implements OnInit {
  limit = 20;
  isLoading: boolean;
  filter = new Filter();
  searchFilters: SearchFilters;
  statuses: Choice[];
  programs: Program[];
  lock: boolean;
  lengthToCompare: number;
  faPlus = faPlus;
  show: string;
  selectedProgram: Program;
  faTrash = faTrash;
  faPencilAlt = faPencilAlt;
  faTh = faTh;
  faList = faList;
  faDownload = faDownload;
  faCog = faCog;
  faCircle = faCircle;
  allIcons = fas;
  showMoreButton = {name : 'Show More', active: true};
  fileNamePlease = {name : '', success : false};
  lockedTables: boolean;
  programIsInUse: Program;
  colors: string[] = [
    'program-card-bottom-yellow',
    'program-card-bottom-red',
    'program-card-bottom-blue',
    'program-card-bottom-green'
  ];
  selectedColor: string;
  colorIndex = -1;
  anthemPromoView: any;
  filteredStatuses: Filter[] = [];
  statusHelper: any;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(private tableHelperService: TableHelperService,
              private programService: ProgramService,
              private toastrService: ToastrService,
              private statusHelperService: StatusHelperService,
              private modalHelper: NgbModal,
              private title: Title,
              private toastr: ToastrService) {}

  ngOnInit(): void {
    this.title.setTitle('Programs');
    this.statusHelper = this.statusHelperService.getStatus('badge');
    this.show = 'CARDS';
    this.searchFilters = {
      formName: 'programSearch',
      searchCallback: () => {this.initNewSearch(); },
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...'
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        }
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'New Program',
          if: true,
          callback: () => { this.programModal(); },
          title: 'New Program'
        },
        {
          icon: faUpload,
          buttonText: 'Import Program',
          if: true,
          callback: () => { this.importProgramModal(); },
          title: 'Import Program'
        }
      ]
    };
  }

  getIcon(program: Program): any {
    if (program.imageUrl){
      const iconName = program.imageUrl.split('-').map((word: string, index: number) => index === 0 ? word
      : word.substring(0, 1).toUpperCase() + word.substring(1)).join('');
      return this.allIcons[iconName] || this.allIcons.faStar;
    }
    return this.allIcons.faStar;
  }

  // TODO:
  initProgramFilter(): void {
    // this.security.anthemPromoView().subscribe(resp=> {
    //   this.anthemPromoView = resp;
    //   this.programFilter['includeCounts'] = this.anthemPromoView ? !this.anthemPromoView : true;
    // });
  }

  exportProgram(id: number): void {
    this.programService.exportProgram(id).subscribe((response: any) => {
      const blob = new Blob([JSON.stringify(response)], {type: 'text/plain;charset=utf-8'});
      saveAs(blob, `Program-${response.program.code}.json`);
    });
  }

  clearKeyword(): void {
    this.initFilter();
    this.filter.search = '';
    this.getPrograms(false);
  }

  clearAllFilters(): void {
    this.filteredStatuses = [];
    this.setStatuses();
    this.filter.search = null;
    this.filter = new Filter();
    this.initFilter();
    this.getPrograms(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  getPrograms(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.programService.getAllPrograms(this.filter).subscribe((data: any) => {
      if (concat) {
        this.programs = this.programs.concat(parseResponse(data));
      } else {
        this.selectedProgram = null;
        this.programs = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
    }, () => {
      this.lockedTables = false;
      this.isLoading = false;
      this.lock = false;
      this.toastr.error('Error occured!');
    });
  }

  initNewSearch(): void {
   this.initFilter();
   this.getPrograms(false);
  }

  getNextColor(): string {
    this.colorIndex++;
    if (this.colorIndex === this.colors.length){
      this.colorIndex = 0;
    }
    return this.colors[this.colorIndex];
  }

  showProgram(e: any, program: Program, index: number): void {
    $('tr.active').removeClass('active');
    $(e.currentTarget).toggleClass('active');
    this.programService.getProgram(program.id, {includeCounts: true}).subscribe((data: any) => {
      if (data.success){
        this.selectedProgram = data.entity;
        this.selectedColor = this.colors[index % 4];
      }
    });
  }

  deleteProgram(id: number): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, {size: 'sm'});
    instance.componentInstance.text = 'Delete program type with ID: ' + id;
    instance.componentInstance.header = 'Delete Program type?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.programService.deleteProgram(id).subscribe(() => {
          this.toastrService.success('Program successfully removed');
          this.initFilter();
          this.getPrograms(false);
          instance.close();
      });
    });
  }

  programModal(program?: Program): void {
      const instance = this.modalHelper.open(ProgramModalComponent);
      if (program){
        if (program.imageUrl){
           instance.componentInstance.icon = this.getIcon(program);
        } else {
          instance.componentInstance.icon = this.allIcons.faHeart;
        }
        instance.componentInstance.program = program;
      }else{
        instance.componentInstance.icon = this.allIcons.faHeart;
      }
      instance.componentInstance.successEvent.subscribe((data: any) => {
          if (data){
            this.toastrService.success(`Program successfully ${instance.componentInstance.isEdit ? 'updated' : 'created'}`);
            this.initNewSearch();
            instance.close();
          }
      });
  }

  importProgramModal(): void {
    const instance = this.modalHelper.open(ProgramImportModalComponent);
    instance.componentInstance.successEvent.subscribe(() => {
      if (instance.componentInstance.fileNamePlease.success){
        this.toastrService.success(`Programs successfully imported`);
        this.initNewSearch();
      }
      instance.close();
    });
  }
}
