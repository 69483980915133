<div class="panel-body">
  <div class="row">
    <div class="full-width">
      <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <small><em>Note: All times are UTC</em></small>
      <table
        [(ngModel)]="attrs"
        name="attrs"
        ngDefaultControl
        class="table table-hover table-bordered table-pointable infinite-table"
        width="100%"
      >
        <thead>
          <tr>
            <th class="width-3"></th>
            <th class="width-23">Name</th>
            <th class="width-33">Value</th>
            <th class="width-12">Effective From</th>
            <th class="width-12">Effective To</th>
            <th class="width-6">Status</th>
            <th class="width-10-rem">
              <span class="float-left">Action</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="attrs.length < 1">
            <td *ngIf="appDataService.isProgramManager" colspan="7">No attributes to show</td>
            <td *ngIf="!appDataService.isProgramManager" colspan="6">No attributes to show</td>
          </tr>
          <ng-container
            *ngFor="let attr of attrs | listGroupBy: 'attrName' | keyvalue | sort: 'attrName':true; let i = index"
          >
            <!--<ng-container
            *ngFor="let attr of attrs | listGroupBy: 'attrName' | keyvalue | sort: 'attrName' : true ; let i = index">-->
            <tr
              class="detail-row"
              *ngIf="getStatus(attr.value[0]) !== 'EXPIRED'"
              [hidden]="
                (filter.effectiveDate ? filter.effectiveDate : (currentDate | date: 'yyyy-MM-dd')) <
                (attr.value[0].effectiveFrom | date: 'yyyy-MM-dd')
              "
            ></tr>
            <tr
              *ngIf="getStatus(attr.value[0]) !== 'EXPIRED'"
              [hidden]="
                (filter.effectiveDate ? filter.effectiveDate : (currentDate | date: 'yyyy-MM-dd')) <
                (attr.value[0].effectiveFrom | date: 'yyyy-MM-dd')
              "
            >
              <td (click)="expandedLookup[i] = !expandedLookup[i]">
                <div>
                  <span
                    *ngIf="!expandedLookup[i]"
                    class="width-50 display-inline-block"
                    title="Expand to see history"
                    [hidden]="!(attr.value.length > 1)"
                  >
                    <fa-icon [icon]="faChevronRight"></fa-icon>
                  </span>
                  <span
                    *ngIf="expandedLookup[i]"
                    class="width-50 display-inline-block"
                    title="Expand to see history"
                    [hidden]="!(attr.value.length > 1)"
                  >
                    <fa-icon [icon]="faChevronDown"></fa-icon>
                  </span>
                </div>
              </td>
              <td [attr.title]="attr.value[0].attrName">{{ attr.value[0].attrName }}</td>
              <td [attr.title]="attr.value[0].attrValue" class="word-break-break-all">
                {{ attr.value[0].attrValue }}
              </td>
              <td>{{ getEffectiveFromDisplay(attr.value[0]) }}</td>
              <td>{{ getEffectiveToDisplay(attr.value[0]) }}</td>
              <td>{{ getStatus(attr.value[0]) }}</td>
              <td class="table-buttons">
                <a
                  class="btn btn-sm float-left btn-primary"
                  *ngIf="!disableActions && allowedToEditAttribs"
                  (click)="partyAttrModal(attr.value[0])"
                  [hidden]="disableActions"
                  title="Edit"
                >
                  <fa-icon [icon]="faPencilAlt"></fa-icon>
                </a>
                <a
                  [hidden]="hideInfoButton"
                  class="btn btn-sm btn-dark-blue float-left"
                  (click)="showDetails(attr.value[0])"
                  title="More Info"
                >
                  <fa-icon [icon]="faInfo"></fa-icon>
                </a>
                <a
                  class="btn btn-sm float-left btn-warning"
                  (click)="expireAttribute(attr.value[0])"
                  title="Expire Now"
                  *ngIf="!disableActions && allowedToEditAttribs"
                  [hidden]="getStatus(attr.value[0]) === 'EXPIRED' || hideExpireButton"
                >
                  <fa-icon [icon]="faStopwatch"></fa-icon>
                </a>
              </td>
            </tr>
            <tr
              class="additional-data DFF4FF-background"
              *ngIf="expandedLookup[i] && getStatus(attr.value[0]) !== 'EXPIRED'"
              [hidden]="
                (filter.effectiveDate ? filter.effectiveDate : (currentDate | date: 'yyyy-MM-dd')) <
                (attr.value[0].effectiveFrom | date: 'yyyy-MM-dd')
              "
            >
              <td colspan="7" class="pb-0 pt-0">
                <table class="table table-pointable no-margin-table" *ngFor="let a of attr.value; let index = index">
                  <tbody *ngIf="index > 0">
                    <tr class="align-text-left">
                      <td class="width-25 border-right-0 border-left-0" title="a.attrValue">
                        <strong>Value:</strong>{{ a.attrValue }}
                      </td>
                      <td
                        class="width-35 border-right-0 border-left-0"
                        title="{{ a.createdOn | date: 'short' + 'OOOO' }} - {{ getEffectiveToDisplay(a) }}"
                      >
                        <strong>Effective Range:</strong>{{ a.effectiveFrom }} -
                        {{ getEffectiveToDisplay(a) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
  </div>
</div>
