import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommunicationsService } from '../services/communications.service';
import { parseResponse } from '../data/parseResponseFunction';


@Component({
  selector: 'app-comm-strategy-history-tab',
  templateUrl: './comm-strategy-history-tab.component.html',
  styleUrls: ['./comm-strategy-history-tab.component.scss']
})
export class CommStrategyHistoryTabComponent implements OnInit {

  constructor(private toastr: ToastrService,
              private communicationsService: CommunicationsService) { }
  activities: any[] = [];
  @Input() commStrategyId: number;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  jon: any = {};
  endpoints: any[] = [];
  filters: any[] = [];
  selectedEndpoint: any;
  lock = false;
  lengthToCompare: number;
  limit: number;
  isLoading = false;
  lockedTables = false;
  offset = 20;
  promotions: any[] = [];

  ngOnInit(): void {
    this.getCommStrategyConfigurations(false);
  }

  getCommStrategyConfigurations(concat: boolean): void {
    this.isLoading = true;
    this.communicationsService.getCommunicationsHistory(this.commStrategyId).subscribe((data: any) => {
      if (concat) {
        this.endpoints = this.endpoints.concat(parseResponse(data));
      } else {
        this.endpoints = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.offset += this.limit;
      this.isLoading = false;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
    }, () => {
      this.lockedTables = false;
      this.lock = false;
      this.isLoading = false;
      this.toastr.error('Error occured!');
    });
  }

  showInfo(endpoint: any, event: any): void {
    $('tr.active').removeClass('active');
    $(event.currentTarget).toggleClass('active');
    this.selectedEndpoint = endpoint;
    this.showMember(event, endpoint.id);
  }


  //TO DO
  showMember(event: any, endpointId: number): void {

  }


}
