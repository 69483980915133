import { CustomAttribute } from './../../data/class';
import { UntypedFormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faSave, faTimes, faPlus } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-manifest-custom-attribute-form',
  templateUrl: './manifest-custom-attribute-form.component.html',
  styleUrls: ['./manifest-custom-attribute-form.component.scss']
})
export class ManifestCustomAttributeFormComponent implements OnInit {

  @Input() index: number;
  @Input() customAttribute: CustomAttribute;
  @Output() public customAttributeEvent: EventEmitter<any> = new EventEmitter();
  customAttributeForm: UntypedFormGroup;
  faTimes = faTimes;
  faSave = faSave;
  faPlus = faPlus;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.index !== null) {
      this.customAttributeForm = this.formBuilder.group({
        attrName: [this.customAttribute.attrName],
        attrNameIndex: [this.customAttribute.attrNameIndex],
        fieldIndex: [this.customAttribute.fieldIndex],
        attrValueDefault: [this.customAttribute.attrValueDefault],
        fieldName: [this.customAttribute.fieldName],
        required: [this.customAttribute.required],
        fieldLength: [this.customAttribute.fieldLength],
        fieldRegex: [this.customAttribute.fieldRegex],
        effectiveDateIndex: [this.customAttribute.effectiveDateIndex],
        expirationDateIndex: [this.customAttribute.expirationDateIndex]
      });
    } else {
      this.customAttributeForm = this.formBuilder.group({
        attrName: [''],
        attrNameIndex: [''],
        fieldIndex: [''],
        attrValueDefault: [''],
        fieldName: [''],
        required: [false],
        fieldLength: [''],
        fieldRegex: [''],
        effectiveDateIndex: [''],
        expirationDateIndex: ['']
      });
    }
  }

  emitEvent(type: string, index?: number): void {
    const value = this.customAttributeForm.value;
    this.customAttributeEvent.emit({type, index, value});
    if (type === 'add') {
      this.customAttributeForm.reset();
    }
  }

}
