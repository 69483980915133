import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { GlobalEventService } from '../services/global-event.service';

@Component({
  selector: 'app-promo-tree',
  templateUrl: './promo-tree.component.html',
  styleUrls: ['./promo-tree.component.scss']
})
export class PromoTreeComponent implements OnInit {

  @Input() public promotion;
  @Input() public selectedPromotion;
  @Input() public selectedPackage;
  @Input() public type: string;
  @Output() public promotionChanged: EventEmitter<any> = new EventEmitter();
  statusHelper: any[];
  faChevronUp = faChevronUp;
  faChevronDown = faChevronDown;
  showChilds = true;

  constructor(private statusHelperService: StatusHelperService, private globalEvent: GlobalEventService) { }

  ngOnInit(): void {
    this.statusHelper = this.statusHelperService.getStatus('badge');
  }

  toggleChildren(): void {
    this.showChilds = !this.showChilds;
  }

  selectPromotion(promo: any, event?: any): void {
    this.globalEvent.sendGlobalEvent({toComponent: 'packaged-sponsorship', data: promo});
    this.promotionChanged.emit(promo);
    if (event) {
      event.stopPropagation();
    }
  }

}