<div>
    <div class="row search-filter-header-top">
      <div class="col-md-6" [hidden]="!searchFilters.title">
        <h2>{{searchFilters.title}}</h2>
      </div>
      <div class="col-md-6 float-right">
        <a class="float-right clear-filter-nrm" (click)="searchFilters.clearAll()"><span class="float-right">Clear all filters</span></a>
      </div>
    </div>
    <!--Segmentless checkbox is typically in the header-->
    <div class="row search-filter-header-bottom m-b">
      <div class="col-md-2">
        <div class="checkbox checkbox-info search-filter-segmentless"
          *ngIf="appDataService.isAdmin &amp;&amp; searchFilters.segmentLessFilter.type === 'segmentless'">
          <input id="{{searchFilters.formName + '-includeSegmentless' | idAttribute}}" [(ngModel)]="searchFilters.segmentLessFilter.value" (change)="filterSegments(null);" type="checkbox">
          <label for="{{searchFilters.formName + '-includeSegmentless' | idAttribute}}">Show Only Segment-less</label>
        </div>
      </div>
      <div class="col-md-10 float-right">
        <button type="submit" class="btn btn-dark-blue btn-sm search-filter-apply float-right" [disabled]="appDataService.isRequestInProgress" title="Apply Filters">
          <fa-icon [hidden]="!appDataService.isRequestInProgress" [icon]="faSpinner" class="rotate fa-fw"></fa-icon>
          <fa-icon [hidden]="!!appDataService.isRequestInProgress" [icon]="faCheckSquare"></fa-icon>
        </button>
        <button class="btn btn-sm btn-dark-blue new-user float-right" (click)="searchFilters.newUser()" title="New User">
          <fa-icon [icon]="faUserPlus"></fa-icon>
        </button>
      </div>
    </div>
</div>
