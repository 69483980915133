<form [formGroup]="promotionCategoryForm" novalidate>
    <div class="modal-header">
      <h1>{{title}}</h1>
    </div>
    <div class="modal-body">
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{errorMsg}}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="form-group">
        <label>Sponsor</label>
        <ng-select [items]="sponsors" bindLabel="name" bindValue="id" formControlName="sponsorPartyId"></ng-select>
          <div class="mt-2" [hidden]="promotionCategoryForm.controls['sponsorPartyId'].value">
            <small class="text-danger">A Category Sponsor is required. Please select a Sponsor.</small>
          </div>
      </div>
      <div class="form-group">
        <label>Name</label>
        <input type="text"  class="form-control" formControlName="name">
        <div class="mt-2" [hidden]="promotionCategoryForm.controls['name'].valid">
          <small class="text-danger">Input a Name</small>
        </div>
      </div>

      <div class="form-group">
        <label>Sort</label>
        <input name="sort" type="number"  class="form-control" formControlName="sort" />
        <div class="mt-2" [hidden]="promotionCategoryForm.controls['sort'].valid">
          <small class="text-danger">Sort order is required. Please add a Sort Order.</small>
        </div>
      </div>

        <div class="form-group">
          <label>Status</label>
          <select formControlName="status" class="form-control" formControlName="status">
            <option *ngFor="let option of statuses" value="{{option}}">{{option}}</option>
          </select>
          <div class="mt-2" [hidden]="promotionCategoryForm.controls['status'].valid">
            <small class="text-danger">Select status</small>
          </div>
        </div>
  
    <div class="modal-footer">
      <button class="btn btn-dark-blue" type="submit" [disabled]="!promotionCategoryForm.valid" (click)="ok()" title="Save">
        <fa-icon [icon]="faSave"></fa-icon>
      </button>
      <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
        <fa-icon [icon]="faTimes"></fa-icon>
      </button>
    </div>
  </div>
</form>
  


 

