<div class="panel-body">
  <div class="row">
    <div class="col-md-12">
      <span *ngIf="show">
          <app-search-filter [searchFilters]="searchFilters"></app-search-filter> 
      </span>          
      <div class="row">
        <table class="table table-pointable table-bordered" width="100%">
          <thead>
            <tr>
              <th>Endpoint Type</th>
              <th>Protocol Name</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let deliveryProtocol of deliveryProtocols; let i=index">
              <td>{{deliveryProtocol.endpointType.name}}</td>
              <td>{{deliveryProtocol.name}}</td>
              <td>
                <div class="float-left actions inline-table">
                  <a class="btn btn-dark-blue btn-sm edit action" (click)="deliveryModal(deliveryProtocol)" title="Edit"><fa-icon [icon]="faPencilAlt"></fa-icon></a>
                  <a title="Edit Delivery Options" type="button" class="btn btn-sm btn-dark-blue edit action"
                     (click)="editOptions(deliveryProtocol)"><fa-icon [icon]="faList"></fa-icon></a>
                </div>
              </td>
            </tr>
            <tr app-is-there-more [numColumns]="3" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>