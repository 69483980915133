<div>
    <label>Restrict the Activity Age to:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="activityAge !== null">
        <div *ngFor="let actAge of activityAge; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeRestriction(actAge)" title="Remove Restriction">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="actAgeValue_{{actAge.value}}">than:</label>
              <input class="form-control m-b" type="number" id="actAgeValue_{{actAge.value}}" name="value" [(ngModel)]="actAge.value" (ngModelChange)="updateRestrictions()" />
            </div>
            <div class="form-group">
              <select name="actAgeUnit_{{actAge.value}}" class="form-control" [(ngModel)]="actAge.units" (ngModelChange)="updateRestrictions()">
                <option *ngFor="let unit of units" [selected]="actAge.units === unit.value" [value]="unit.value">{{unit.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <select name="actAgeDirection_{{actAge.value}}" class="form-control" [(ngModel)]="actAge.direction" (ngModelChange)="updateRestrictions()">
                <option *ngFor="let direction of directions" [selected]="actAge.direction === direction.value" [value]="direction.value">{{direction.text}}</option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <div class="form-group float-right  col-md-12">
        <button class="btn btn-default float-right" (click)="initRestrictionObject()"><fa-icon [icon]="faPlus"></fa-icon> Add Activity Age Restriction</button>
      </div>
    </div>
</div>
