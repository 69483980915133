<div>
  <form>
    <label>Issue an alternate (tracking) reward when the reinforcement schedule is not met:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="trackingReward !== null">
        <div *ngFor="let trackingRew of trackingReward; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeConsequence(trackingRew)" title="Remove Consequence">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="rewardAmt_{{trackingRew.rewardCode}}">Reward Amount</label>
              <input class="form-control m-b" type="number" id="rewardAmt_{{trackingRew.rewardCode}}" name="rewardAmount" [(ngModel)]="trackingRew.rewardAmount" (ngModelChange)="updateConsequences()" />
            </div>
            <div class="form-group">
              <label>Reward Type</label>
              <select name="rewardType_{{trackingRew.rewardCode}}" class="form-control" [(ngModel)]="trackingRew.rewardCode" (ngModelChange)="updateConsequences()">
                <option *ngFor="let reward of rewardTypes" [selected]="trackingRew.rewardCode === reward.code" [value]="reward.code">{{reward.code}}</option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initConsequenceObject()"><fa-icon [icon]="faPlus"></fa-icon> Add a Tracking Reward</button>
      </div>
    </div>
  </form>
</div>
