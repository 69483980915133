<div class="wrapper wrapper-content">
  <div class="row">
    <div [ngClass]="{'col-lg-9': ((!appDataService.isPartner && appDataService.isCSR) || appDataService.isCSRManager) || !stretch,
      'col-lg-12': appDataService.isPartner && !appDataService.isCSRManager,
      'col-lg-11' : stretch
    }" class="content-container">
      <div class="row">
        <div class="col-lg-12">
          <div class="mb-3">
            <h2 class="fullname">{{type === 'user' ? 'User' : 'Member'}}
              Details:
              {{selectedPerson.firstName + ' ' + selectedPerson.middleInitial + ' ' + selectedPerson.lastName}}</h2>
            <button *ngIf="allowEmulate && selectedPerson.status === 'ACTIVE' && !users" type="button" class="btn btn-sm btn-primary" [disabled]="!allowedToEmulateMember && !hasAccessToSensitiveAccounts" (click)="impersonateMember()" title="WARNING: While using the Member Emulation tool, you will be logged into the member portal as the actual member. Any actions you take while using this tool will be reflected in the member's user experience and their account.">
              <fa-icon [icon]="faPlus"></fa-icon>
              Emulate Member
            </button>
          </div>
        </div>
      </div>
      <div class="row ibox-content">
        <div class="col-lg-12">
          <nav mat-tab-nav-bar mat-align-tabs="left" animationDuration="200ms" style="margin-bottom: 10px;">
            <a mat-tab-link label="{{tab.displayName}}" *ngFor="let tab of tabs | callback: tabPredicateRunner"
               (click)="changeTab(tab.idx)"
               [active]="activeTabIdx == tab.idx">{{tab.displayName}}</a>
          </nav>
          <app-member-promotions-tab  *ngIf="activeTabIdx === 0" [personId]="selectedPersonId" [person]="selectedPerson"></app-member-promotions-tab>
          <app-member-activity-tab    *ngIf="activeTabIdx === 1" [personId]="selectedPersonId" [(behaviorToLoad)]="behaviorToLoad" [(activitySearchParams)]="activitySearchParams"></app-member-activity-tab>
          <app-accounts-tab           *ngIf="activeTabIdx === 2" [personId]="selectedPersonId"></app-accounts-tab>
          <app-instruments-tab        *ngIf="activeTabIdx === 3" [personId]="selectedPersonId"></app-instruments-tab>
          <app-contacts-tab           *ngIf="activeTabIdx === 4" [personId]="selectedPersonId"></app-contacts-tab>
          <app-party-attributes-tab         *ngIf="activeTabIdx === 5" [personId]="selectedPersonId"></app-party-attributes-tab>
          <app-segments-tab           *ngIf="activeTabIdx === 6" [personId]="selectedPersonId" [type]="type" [halfSize]="true"></app-segments-tab>
          <app-access-roles           *ngIf="activeTabIdx === 7" [personId]="selectedPersonId"></app-access-roles>
          <app-notes-tab              *ngIf="activeTabIdx === 8" [personId]="selectedPersonId"></app-notes-tab>
          <app-survey-tab             *ngIf="activeTabIdx === 9" [personId]="selectedPersonId" [isAdmin]="appDataService.isAdmin"></app-survey-tab>
          <app-member-document-tab     *ngIf="activeTabIdx === 10" [personId]="selectedPersonId"></app-member-document-tab>
          <app-communications-tab     *ngIf="activeTabIdx === 11" [personId]="selectedPersonId"></app-communications-tab>
          <app-member-events-tab      *ngIf="activeTabIdx === 12" [personId]="selectedPersonId"></app-member-events-tab>
          <app-member-measures-tab    *ngIf="activeTabIdx === 13" [personId]="selectedPersonId"></app-member-measures-tab>
          <app-relationships-tab      *ngIf="activeTabIdx === 14" [personId]="selectedPersonId" [selectedPersonName] = "selectedPerson.firstName"></app-relationships-tab>
        </div>
      </div>
    </div>
    <div *ngIf="((appDataService.isCSR && !appDataService.isPartner) ||
        appDataService.isCSRManager) && showMemberPanel" [ngClass] = "stretch ? 'col-lg-1' : 'col-lg-3'">
    <app-member-details-panel [(stretch)] = "stretch" [type]="type" [selectedPersonId]="selectedPersonId"
                               (navigateEvent)="changeTab($event - 1)"></app-member-details-panel>
    </div>
  </div>
</div>
