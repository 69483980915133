<div class="panel-body">
  <div class="col-md-12">
    <div class="row">
      <app-search-filter class="w-100" [searchFilters]="searchFilters"></app-search-filter>  
    </div>
    <div class="row">
      <table class="table table-pointable table-bordered">
        <thead>
          <tr>
            <th>Name</th>
            <th class="width-10">From Date</th>
            <th class="width-10">To Date</th>
            <th class="width-5"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let pckg of packages">
            <td>{{pckg.packageName}}</td>
            <td>{{pckg.startDate}}</td>
            <td>{{pckg.endDate}}</td>
            <td>                
              <div class="float-left actions inline-table">
                <button class="btn btn-dark-blue btn-sm action" (click)="associatedProgramsModal(pckg.packageName)" title="View Associated Programs"><fa-icon [icon]="faEye"></fa-icon></button>
              </div>
            </td>
          </tr>
          <tr app-is-there-more [numColumns]="4" [newLengthEvent]="newLengthEvent"></tr> 
        </tbody>
      </table>
    </div>
    <app-add-more-data-to-list (onScroll)="getDistinctProgramPackages(true)" [lock]="lock" [isLoading]="isLoading"></app-add-more-data-to-list>
  </div>
</div>