import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';

@Component({
  selector: 'app-system',
  templateUrl: './system.component.html',
  styleUrls: ['./system.component.scss']
})
export class SystemComponent implements OnInit {

  active = 1;

  tabs = [
    { title: 'Reward Types', activeId: 1 },
    { title: 'Instrument Types', activeId: 2},
    { title: 'Behaviors', activeId: 3},
    { title: 'Locations', activeId: 4},
    { title: 'Products', activeId: 5},
    { title: 'World Currencies', activeId: 6},
    { title: 'Job Management', activeId: 7},
    { title: 'Integration Servers', activeId: 8},
    { title: 'Integration Keys', activeId: 9},
    { title: 'Attribute Mappings', activeId: 10},
    { title: 'Contact Reasons', activeId: 11},
    { title: 'Delivery Protocols', activeId: 12},
    { title: 'Filters', activeId: 13},
  ];

  constructor(
    private titleHelper: Title
  ) { }

  ngOnInit(): void {
    this.titleHelper.setTitle('System Configuration');
  }

}
