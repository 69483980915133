<div class="wrapper wrapper-content">
  <div class="ibox">
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="mb-3">
            <h2 class="fullname">Communications</h2>
          </div>
        </div>
      </div>
      <div class="tabs-container">
        <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
          <li [ngbNavItem]="1">
            <a ngbNavLink>Management</a>
            <ng-template ngbNavContent>
              <app-communication-management></app-communication-management>
            </ng-template>
          </li>
          <li [ngbNavItem]="2">
            <a ngbNavLink>Strategy</a>
            <ng-template ngbNavContent>
              <app-communication-strategy-tab></app-communication-strategy-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="3" [hidden]="true">
            <a ngbNavLink>Execution</a>
            <ng-template ngbNavContent>
            </ng-template>
          </li>

          <li [ngbNavItem]="4">
            <a ngbNavLink>Delivery Protocols</a>
            <ng-template ngbNavContent>
              <app-delivery-protocols-tab></app-delivery-protocols-tab>
            </ng-template>
          </li>
          <li [ngbNavItem]="5" [hidden]="true">
            <a ngbNavLink>Jobs & Filters</a>
            <ng-template ngbNavContent>
            </ng-template>
          </li>
          <li [ngbNavItem]="6">
            <a ngbNavLink>Reports Available</a>
            <ng-template ngbNavContent>
              <app-reports-available [isAdmin]="isAdmin"></app-reports-available>
            </ng-template>
          </li>
          <li [ngbNavItem]="7">
            <a ngbNavLink>Reports Results</a>
            <ng-template ngbNavContent>
              <app-reports-results [isAdmin]="isAdmin"></app-reports-results>
            </ng-template>
          </li>
          <li [ngbNavItem]="8">
            <a ngbNavLink>History</a>
            <ng-template ngbNavContent>
              <app-communication-history-tab></app-communication-history-tab>
            </ng-template>
          </li>
        </ul>
        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</div>
