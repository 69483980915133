import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { JobService } from '../services/job.service';
import { CommunicationStrategyService } from '../services/communications-strategy.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-add-filter-dialog',
  templateUrl: './add-filter-dialog.component.html',
  styleUrls: ['./add-filter-dialog.component.scss']
})
export class AddFilterDialogComponent implements OnInit {

  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  @Input() endpointTypeName: string;
  @Input() commStrategyId: number;
  @Input() ordernum: number;
  title: string;
  filterForm: UntypedFormGroup;
  errorMsg = '';
  jobs: any[];
  faTimes = faTimes;

  constructor(
    private modalHelper: NgbModal,
    private formBuilder: FormBuilder,
    private jobService: JobService,
    private communicationStrategyService: CommunicationStrategyService,
    private responseHelper: ResponseHelperService
  ) { }

  ngOnInit(): void {
    this.filterForm = this.formBuilder.group({
      jobInfoId: [null, Validators.required],
    });
    this.jobService.getJobs({ jobSelector: 'F' }).subscribe((data: any) => {
      if (data.success) {
        this.jobs = parseResponse(data);
      }
    });
  }

  ok(): void {
    this.communicationStrategyService.addFilterToStrategy(this.commStrategyId,
      { ...this.filterForm.value, endpointTypeName: this.endpointTypeName, commStrategyId: this.commStrategyId, ordernum: this.ordernum })
        .subscribe((data: any) => {
        this.successEvent.emit(data);
        this.responseHelper.success('Filter added successfully!', true);
      }, (data: any) => {
        this.errorMsg = data.error.error;
      });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}

