<div class="row">
  <div class="col-md-11">
    <div class="row">
      <div class="col-md-4" *ngFor="let adr of addresses">
        <div class="widget style1 navy-bg">
          <div class="row">
            <div class="col-sm-4">
              <span class="fa-stack fa-3x">
                <fa-layers [fixedWidth]="true">
                  <fa-icon [icon]="faHome" class="fa-2x"></fa-icon>
                </fa-layers>
              </span>
            </div>
            <div class="col-sm-8 text-lg-right">
              <div><span class="word-break-break-all">{{adr.address1}} </span></div>
              <div><span class="word-break-break-all">{{adr.address2}} </span></div>
              <h3 title="{{adr.city + ', ' + formatState(adr.state)}}" class="font-bold elipsis">{{adr.city + ', ' + formatState(adr.state)}}</h3>
              <span *ngIf="adr.isPrimary"><fa-icon [icon]="faStar"></fa-icon> Primary</span><span *ngIf="!adr.isPrimary"> </span>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <span *ngIf="adr.isDefaultComm"><fa-icon [icon]="faStar"></fa-icon> Default</span>
            </div>
            <div class="col-md-9" *ngIf="isProgramManager">
              <div class="form-group float-right">
                <button type="button" class="btn btn-warning btn-sm" (click)="addressModal(adr.addressId)"><fa-icon [icon]="faEdit"></fa-icon></button>
                <button [disabled]="addresses.length === 1" type="button" class="btn btn-danger btn-sm" (click)="deletePartyAddress(adr, adr.isPrimary)"> <fa-icon [icon]="faTrash"></fa-icon></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-1" *ngIf="isProgramManager">
    <a class="btn btn-sm btn-primary float-right contact" (click)="addressModal()"><fa-icon [icon]="faHome"></fa-icon> New address</a>
  </div>
</div>
<hr class="shrinked">
<div class="row">
  <div class="col-md-11">
    <div class="row">
      <div class="col-md-4" *ngFor="let em of emails">
        <div class="widget style1 lazur-bg">
          <div class="row">
            <div class="col-sm-4">
              <span class="fa-stack fa-3x">
                <fa-layers [fixedWidth]="true">
                  <fa-icon [icon]="faEnvilope" class="fa-2x"></fa-icon>
                </fa-layers>
              </span>
            </div>
            <div class="col-sm-8 text-lg-right">
              <span *ngIf="em.isPrimary"><fa-icon [icon]="faStar"></fa-icon> Primary</span>
              <h3 title="{{em.emailAddress}}" class="font-bold elipsis" class="word-break-break-all">{{em.emailAddress}}</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <span *ngIf="em.isDefaultCommunication"><fa-icon [icon]="faStar"></fa-icon> Default</span>
            </div>
            <div class="col-md-9" *ngIf="isProgramManager">
              <div class="form-group float-right">
                <button type="button" class="btn btn-warning btn-sm" (click)="emailModal(em.id)"> <fa-icon [icon]="faEdit"></fa-icon></button>
                <button [disabled]="emails.length === 1" type="button" class="btn btn-danger btn-sm" (click)="deletePartyEmail(em, em.isPrimary)"> <fa-icon [icon]="faTrash"></fa-icon></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-1" *ngIf="isProgramManager">
    <a class="btn btn-sm btn-info float-right contact" (click)="emailModal()"><fa-icon [icon]="faAt"></fa-icon> New email</a>
  </div>
</div>
<hr class="shrinked">
<div class="row">
  <div class="col-md-11">
    <div class="row">
      <div class="col-md-4" *ngFor="let ph of phones">
        <div class="widget style1 blue-bg">
          <div class="row">
            <div class="col-sm-4">
              <span class="fa-stack fa-3x">
                <fa-layers [fixedWidth]="true">
                  <fa-icon  [icon]="faPhone" class="fa-2x"></fa-icon>
                </fa-layers>
              </span>
            </div>
            <div class="col-sm-8 text-lg-right">
              <div *ngIf="ph.isPrimary"> <fa-icon [icon]="faStar"></fa-icon> Primary</div>
              <span>{{ph.status}}</span>
              <h3 class="font-bold elipsis" title="{{ph.phoneNumber}}" class="word-break-break-all">{{ph.phoneNumber}}</h3>
            </div>
          </div>
          <div class="row">
            <div class="col-md-3">
              <span *ngIf="ph.isDefaultCommunication"> <fa-icon [icon]="faStar"></fa-icon>  Default</span>
            </div>
            <div class="col-md-9" *ngIf="isProgramManager">
              <div class="form-group float-right">
                <button type="button" class="btn btn-warning btn-sm" (click)="phoneModal(ph.id)"><fa-icon [icon]="faEdit"></fa-icon></button>
                <button [disabled]="phones.length === 1" type="button" class="btn btn-danger btn-sm" (click)="deletePhone(ph, ph.isPrimary)"> <fa-icon [icon]="faTrash"></fa-icon></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-1" *ngIf="isProgramManager">
    <a class="btn btn-sm btn-success float-right contact" (click)="phoneModal()"><fa-icon [icon]="faPhone"></fa-icon> New phone</a>
  </div>
</div>
