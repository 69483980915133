import { AppDataService } from './../app-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(private http: HttpClient, private globalData: AppDataService) { }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getInteractions(params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'member-interactions', this.setParams(params));
  }

  getTicketById(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'member-interactions/' + id);
  }

  createOrUpdateTicket(data: any, id?: number): any {
    if (id) {
      return this.http.put<any>(this.globalData.baseUrl + 'member-interactions/' + id, data);
    }
    return this.http.post<any>(this.globalData.baseUrl + 'member-interactions', data);
  }

  getTicketList(params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'member-interactions/', this.setParams(params));
  }

  createOrUpdateTicketEntry(data: any, id?: number): any {
    if (id) {
      return this.http.put<any>(this.globalData.baseUrl + 'member-interactions/entry/' + id, data);
    }
    return this.http.post<any>(this.globalData.baseUrl + 'member-interactions/entry', data);
  }

  getTicketEnrty(ticketId: number, entryId: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'member-interactions/entry/' + ticketId + '/' + entryId);
  }
}

