<form #f="ngForm" name="form">
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Name</label>
          <input type="text" class="form-control" [(ngModel)]="integration.name" name="name" required>
          <div class="mt-sm" [hidden]="integration.name">
            <small class="text-danger">Input Name</small>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>Status</label>
          <select [(ngModel)]="integration.status" class="form-control" name="status" required>
            <option *ngFor="let option of statuses" value="{{option}}">{{option}}</option>
          </select>
          <div class="mt-sm" [hidden]="integration.status">
            <small class="text-danger">Select status</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Report</label>
          <select [(ngModel)]="integration.reportId" class="form-control" name="reportId" required>
            <option *ngFor="let report of reports" value="{{report.id}}" (click)="addReportName(report)">{{report.name}}</option>
          </select>
          <div class="mt-sm" [hidden]="integration.reportId">
            <small class="text-danger">Select report</small>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <label>Record delimiter</label>
        <input type="text" class="form-control" [(ngModel)]="integration.recordDelimiter" name="recordDelimiter" required>
        <div class="mt-sm" [hidden]="integration.recordDelimiter">
          <small class="text-danger">Input record delimiter</small>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" [disabled]="f.form.invalid" (click)="ok()" title="Save"><fa-icon [icon]="faSave"></fa-icon></button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel"><fa-icon [icon]="faTimes"></fa-icon></button>
  </div>
</form>