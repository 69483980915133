<form [formGroup]="integrationKeyForm" novalidate>
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <label>Name</label>
      <input type="text" class="form-control" formControlName="name" [appTrim]="!integrationKey">
      <div class="mt-1" [hidden]="integrationKeyForm.controls.name.valid">
        <small class="text-danger">Please input name</small>
      </div>
    </div>
    <div class="form-group">
      <label>Status</label>
      <select formControlName="status" class="form-control" formControlName="status">
        <option *ngFor="let option of statuses" value="{{option}}">{{option}}</option>
      </select>
      <div class="mt-1" [hidden]="integrationKeyForm.controls.status.valid">
        <small class="text-danger">Select status</small>
      </div>
    </div>
    <div class="form-group">
      <label>Key Password</label>
      <input type="password" autocomplete="new-password" class="form-control" formControlName="keyPassword" [appTrim]="!integrationKey">
    </div>
    <div class="form-group">
      <label>Key Format</label>
      <select formControlName="keyFormat" class="form-control">
        <option *ngFor="let option of keyFormats" value="{{option}}">{{option}}</option>
      </select>
      <div class="mt-1" [hidden]="integrationKeyForm.controls.keyFormat.valid">
        <small class="text-danger">Select key format</small>
      </div>
    </div> 
    <div class="row" *ngIf="!isEdit">
      <div class="col-sm-12">
        <div class="form-group">
          <input class="upload-input form-control" id="upload-input-1"  type="file" name="file" (change)="changeListener($event, true)" />
          <label class="upload-label btn btn-default btn-sm float-left" for="upload-input-1" title="Upload Public Key">
           <fa-icon [icon]="faUpload"></fa-icon>
              Upload Public Key
          </label>
          <label class="text-muted" [hidden]="!publicKeyFileName" class="key-label">{{ publicKeyFileName }}</label>
          <small class="text-danger" [hidden]="publicKeyFileName">Please attach a public key...</small>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="!isEdit">
      <div class="col-sm-12">
        <div class="form-group">
          <input class="upload-input form-control" id="upload-input-2"  type="file" name="file" (change)="changeListener($event)"/>
          <label class="upload-label btn btn-default btn-sm float-left" for="upload-input-2" title="Upload Private Key">
            <fa-icon [icon]="faUpload"></fa-icon>
              Upload Private Key
          </label>
          <label class="text-muted" class="key-label">{{ privateKeyFileName }}</label>
        </div>
      </div>
    </div> 
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" [disabled]="!integrationKeyForm.valid || (!publicKeyFileName && !isEdit)"  (click)="ok()" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>
  
  
    
  
  
   
  
