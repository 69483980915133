<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-lg-9 content-container">
            <div class="ibox">
                <div class="ibox-title">
                    <div class=" float-right">
                        <a class="btn btn-sm btn-dark-blue new-user float-right" (click)="newCommunication()"
                            title="New Communication">
                            <fa-icon [icon]="faPlus"></fa-icon>
                        </a>
                    </div>
                    <h2>Communications Management</h2>
                </div>
                <div class="ibox-content">
                    <app-search-filter *ngIf="showSearchFilter" [searchFilters]="searchFilters"></app-search-filter>
                    <div class="m-b">
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div>
                                <div class="row table-program">
                                    <table class="table table-pointable table-bordered" width="100%">
                                        <thead>
                                            <tr>
                                                <th width="10%">ID</th>
                                                <th>Endpoint Type</th>
                                                <th>Delivery Protocol</th>
                                                <th width="15%">Name</th>
                                                <th width="10%">Status</th>
                                                <th width="70px" style="text-align: center">Require Pref</th>
                                                <th width="15%">Communication Type</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let c of communications" (click)="showCommunication($event, c)">
                                                <td><a routerLink="/communications/{{c.id}}">{{c.id}}</a></td>
                                                <td>{{c.communicationDeliveryProtocols.length > 0 ? c.communicationDeliveryProtocols[0].deliveryProtocol.endpointType.name : ''}}
                                                </td>
                                                <td>{{c.communicationDeliveryProtocols.length > 0 ? c.communicationDeliveryProtocols[0].deliveryProtocol.name : ''}}
                                                </td>
                                                <td>{{c.name}}</td>
                                                <td>{{c.status}}</td>
                                                <td>
                                                    <div class="checkbox checkbox-only checkbox-success row" style="padding-left: 0px !important;
                                 margin: 0px;">
                                                        <input id="allowPreferencesCell"
                                                            [(ngModel)]="c.requirePreference" type="checkbox"
                                                            [readonly]="" onclick="return false">
                                                        <label class="col-md-6 details-panel-reduced-margin"
                                                            for="allowPreferencesCell" style="margin-left: 3%;"></label>
                                                    </div>
                                                </td>
                                                <td>{{c.communicationType.name}}</td>
                                            </tr>
                                            <tr app-is-there-more [numColumns]="7" [newLengthEvent]="newLengthEvent">
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <app-add-more-data-to-list (onScroll)="getCommunications(true)" [lock]="lock"
                                    [isLoading]="isLoading"></app-add-more-data-to-list>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--MEMBER SELECTED-->
        <div class="col-lg-3" *ngIf="show()">
            <div class="ibox" appScrollWithMe>
                <div class="ibox-content">
                    <div id="contact-1" class="tab-pane active">
                        <div class="row">
                            <div class="col-md-7">
                                <div class="row">
                                    <div class="col-md-12">
                                        <h2>{{selectedComm.name}}</h2>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 form-group">
                                        <span id="status-label" class="badge"
                                            [ngClass]="statusHelper[selectedComm.status]">{{selectedComm.status}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5">
                                <div class="row">
                                    <div class="col-md-12 form-group float-right">
                                        <a class="btn btn-primary btn-sm float-right"
                                            routerLink="/communications/{{selectedComm.id}}">
                                            <fa-icon [icon]="faEye"></fa-icon> View
                                        </a>
                                    </div>
                                    <div class="col-md-12 form-group float-right">
                                        <a class="btn btn-primary btn-sm float-right"
                                            (click)="communicationsModal(selectedComm)">
                                            <fa-icon [icon]=faEdit></fa-icon>Edit
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row justify-center-div">
                            <div class="client-detail">
                                <div>
                                    <div class="col-md-12">
                                        <div class="row">
                                            <div class="col-md-6 form-group details-panel-reduced-margin">
                                                <strong class="float-right">Delivery Protocol</strong>
                                            </div>
                                            <div
                                                class="col-md-6 form-group details-panel-text-overflow details-panel-reduced-margin">
                                                <span
                                                    title="{{selectedComm.communicationDeliveryProtocols.length > 0 ? selectedComm.communicationDeliveryProtocols[0].deliveryProtocol.name : ''}}">
                                                    {{selectedComm.communicationDeliveryProtocols.length > 0 ? selectedComm.communicationDeliveryProtocols[0].deliveryProtocol.name: ''}}<br></span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group details-panel-reduced-margin">
                                                <strong class="float-right">Endpoint Type</strong>
                                            </div>
                                            <div
                                                class="col-md-6 form-group details-panel-text-overflow details-panel-reduced-margin">
                                                <span
                                                    title="{{selectedComm.communicationDeliveryProtocols.length > 0 ? selectedComm.communicationDeliveryProtocols[0].deliveryProtocol.endpointType.name : ''}}">
                                                    {{selectedComm.communicationDeliveryProtocols.length > 0 ? selectedComm.communicationDeliveryProtocols[0].deliveryProtocol.endpointType.name : ''}}<br></span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 form-group details-panel-reduced-margin">
                                                <strong class="float-right">Status</strong>
                                            </div>
                                            <div
                                                class="col-md-6 form-group details-panel-text-overflow details-panel-reduced-margin">
                                                <span title="{{selectedComm.status}}">{{selectedComm.status}}<br></span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-6 details-panel-reduced-margin">
                                                <strong class="float-right">Require Preference</strong>
                                            </div>
                                            <div
                                                class="col-md-6 details-panel-reduced-margin checkbox checkbox-only checkbox-success">
                                                <input id="allowPreferencesDetail"
                                                    [(ngModel)]="selectedComm.requirePreference" type="checkbox"
                                                    [readonly]="" onclick="return false">
                                                <label class="col-md-6 details-panel-reduced-margin"
                                                    for="allowPreferencesDetail"></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!--/CONTAINER-->
</div>
