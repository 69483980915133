import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';
import { InstrumentType } from '../data/model';

@Injectable({
  providedIn: 'root'
})
export class InstrumentTypeService {

  constructor(private http: HttpClient, private globalData: AppDataService) { }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getInstrumentTypes(params):any {
    return this.http.get(this.globalData.baseUrl + 'instruments/typesDT',this.setParams(params));
  }

  getInstrumentType(id: number):any {
    return this.http.get(this.globalData.baseUrl + 'instruments/types/' + id);
  }

  createOdUpdateInstrumentType(id: number, InstrumentType: InstrumentType): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'instruments/types/' + id, InstrumentType);
    }
    return this.http.post(this.globalData.baseUrl + 'instruments/types/', InstrumentType);
  }

  deleteInstrumentType(id: number):any {
    return this.http.delete(this.globalData.baseUrl + 'instruments/types/' + id);
  }

  getInstrumentTypesByRegex(params): any {
    return this.http.get(this.globalData.baseUrl + 'instruments/types/pattern/', this.setParams(params));
  }
}
