import { Component, Input, OnInit } from '@angular/core';
import {MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-document-modal',
  templateUrl: './document-modal.component.html',
  styleUrls: ['./document-modal.component.scss']
})
export class DocumentModalComponent implements OnInit {
  
  @Input() document: any;

  constructor(public dialogRef: MatDialogRef<DocumentModalComponent>) { }

  ngOnInit(): void {
  }
  
  close(): void {
    this.dialogRef.close();
  }

}
