<div class="wrapper wrapper-content">
  <div class="ibox">
    <div class="ibox-title">
        <h2>Segments</h2>
    </div>
    <div class="ibox-content">
      <div class="row">
        <div class="col-md-6">
          <div class="segment-filter">
            <input type="text" placeholder="Type minimum 3 characters to filter" [(ngModel)]="query" class="form-control mb-2"  (ngModelChange)="changeFilter(query)">
            <div class="segment-filter-container">
              <div class="row segment-frame" [ngClass]="getLevel(item)" *ngFor="let item of filtered">
                <div class="col-md-6">
                  <label>{{item.code}}</label>
                </div>
                <div class="col-md-6">
                  <div class="float-right">
                    <a class="btn btn-dark-blue btn-xs" (click)="segmentModal(item, true)">
                      <fa-icon [icon]="faPlus"></fa-icon>
                    </a>
                    <a class="btn btn-dark-blue btn-xs" (click)="segmentModal(item, false)">
                      <fa-icon [icon]="faEdit"></fa-icon>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>