import { Component, Input, OnInit } from '@angular/core';
import { StatusHelperService } from '../../../services/helpers/status-helper.service';
import { InstrumentsService } from '../../../services/instruments.service';
import { ActivityInstrument } from '../../../data/model';
import { ToastrService } from 'ngx-toastr';
import { ResponseHelperService } from '../../../services/helpers/response-helper.service';
import { faIdBadge, faStar, faCreditCard, faUser, faBriefcase, faGlobe, faCalendar, faBookmark, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { AppDataService } from '../../../app-data.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../../../confirm-dialog/confirm-dialog.component';
import { InstrumentTypeService } from '../../../services/instrument-type.service';
import { InstrumentModalComponent } from '../instrument-modal/instrument-modal.component';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-instruments-tab',
  templateUrl: './instruments-tab.component.html',
  styleUrls: ['./instruments-tab.component.scss']
})
export class InstrumentsTabComponent implements OnInit {

  @Input() personId: number;
  instruments: ActivityInstrument[] = [];
  faIdBadge = faIdBadge;
  faStar = faStar;
  faCreditCard = faCreditCard;
  faUser = faUser;
  faBriefcase = faBriefcase;
  faGlobe = faGlobe;
  faCalendar = faCalendar;
  faBookmark = faBookmark;
  faEdit = faEdit;
  faTrash = faTrash;

  constructor(
    private statusHelperService: StatusHelperService,
    private instrumentsService: InstrumentsService,
    private toastr: ToastrService,
    private responseHelperService: ResponseHelperService,
    private globalData: AppDataService,
    private modalHelper: NgbModal,
    private instrumentTypeService: InstrumentTypeService
  ) { }

  isProgramManager = this.globalData.isProgramManager;
  statusHelper = this.statusHelperService.getStatus('badge');

  ngOnInit(): void {
    this.setInstruments();
  }

  setInstruments(): void {
    this.instrumentsService.getPartyInstruments(this.personId).subscribe((data: any) => {
      if (data.success) {
        this.instruments = parseResponse(data);
      }
    });
  }

  instrumentModal(id?: number): void {
    let instrumentTypes: any = [];
    let instrument = {};
    const instrumentTypeFilter: any = {};
    instrumentTypeFilter.statuses = ['ACTIVE', 'PENDING', 'STARTED'];
    this.instrumentTypeService.getInstrumentTypes(instrumentTypeFilter).subscribe((data: any) => {
      instrumentTypes = parseResponse(data);
      if (id) {
        this.instrumentsService.getInstrument(id).subscribe((inst: any) => {
          if (inst.success) {
            instrument = inst.entity;
            const instance = this.modalHelper.open(InstrumentModalComponent);
            instance.componentInstance.instrument = instrument;
            instance.componentInstance.instruments = this.instruments;
            instance.componentInstance.instrumentTypes = instrumentTypes;
            instance.componentInstance.personId = this.personId;
            instance.componentInstance.successEvent.subscribe(() => {
              this.setInstruments();
              instance.close();
            });
          }
        });
      } else {
        const instance = this.modalHelper.open(InstrumentModalComponent);
        instance.componentInstance.instruments = this.instruments;
        instance.componentInstance.instrumentTypes = instrumentTypes;
        instance.componentInstance.personId = this.personId;
        instance.componentInstance.successEvent.subscribe(() => {
          this.setInstruments();
          instance.close();
        });
      }
    });
  }

  deleteInstrument(id: number, isPrimary: boolean, instrumentNumber: number): void {
    if (isPrimary && this.instruments.length > 1) {
      this.toastr.error('You must change primary instrument before deleting it');
    } else {
      const instance = this.modalHelper.open(ConfirmDialogComponent);
      instance.componentInstance.text = 'Delete instrument with number: ' + instrumentNumber + '?';
      instance.componentInstance.header = 'Delete instrument?';
      instance.componentInstance.successEvent.subscribe(() => {
        this.instrumentsService.deleteInstrument(id).subscribe((data: any) => {
          this.responseHelperService.success('Instrument successfully removed', false);
          this.setInstruments();
          instance.close();
        }, (data: any) => {
          this.responseHelperService.error(this, data.error.error);
        });
      });
    }
  }
}
