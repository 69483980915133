import { Component, EventEmitter, Input, OnInit, Output, ViewChild, NgModule } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { ProgramService } from 'src/app/services/program.service';
import { PromotionConfigService } from 'src/app/services/promotion-config.service';
import { PromotionService } from 'src/app/services/promotion.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { ActivatedRoute } from '@angular/router';
import { PromotionTemplate } from 'src/app/data/model';
import { Promotion } from 'src/app/data/model';
import * as $ from 'jquery';
import { Observable, Subject } from 'rxjs';
import { RegexSearchModalComponent } from 'src/app/regex-search-modal/regex-search-modal.component';
import { fromEvent, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'reward-trigger',
  templateUrl: './reward-trigger.component.html',
  styleUrls: ['./reward-trigger.component.scss']
})
export class RewardTriggerComponent implements OnInit {

  @Input() type: string;
  @Input() selectedModel: any;
  @Input() triggers: any;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  //@Output() updateTriggers: EventEmitter<any> = new EventEmitter(true);
  service: any;
  debouncer: Subject<string> = new Subject<string>();
  allowedToUpdate: any;
  @ViewChild(RegexSearchModalComponent) regexSearchModalComponent: RegexSearchModalComponent;

  constructor(private router: ActivatedRoute,
              private formBuilder: FormBuilder,
              private formsModule: FormsModule,
              private statusHelperService: StatusHelperService,
              private programService: ProgramService,
              private modalHelper: NgbModal,
              private responseHelperService: ResponseHelperService,
              private promotionConfigService: PromotionConfigService,
              private configService: ConfigurationService,
              private promotionService: PromotionService) {
      this.debouncer
        .pipe(debounce(() => interval(1000)))
        .subscribe((value) => this.inputChangeEmitter.emit(value));
              }

  ngOnInit(): void {
    if(!this.triggers || this.triggers === null
      || !this.triggers.primaryBehaviorOrRewardedBehavior
      || this.triggers.primaryBehaviorOrRewardedBehavior[0].class === 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Triggers$PrimaryBehavior'
      ) {
      this.initTriggerObj();
    } else {
      // hacky workaround for https://jira.refactoreddigressions.com/browse/RUNCHT-3474
      // the basePromotionCode element keeps disappearing on update (when it is blank).
	  // This probably stems from something else.
     if (this.triggers.primaryBehaviorOrRewardedBehavior[0].basePromotionCode == undefined) {
      this.triggers.primaryBehaviorOrRewardedBehavior[0].basePromotionCode = '';
     }
    }
  }

    regexSearchModal(searchFor: string, item: any, size: string, limitToOne: boolean, setValue: boolean): void {
      this.openRegexSearchModal(searchFor, item, size, limitToOne, setValue);
    }

    openRegexSearchModal(searchFor: string, item: any, size: string, limitToOne: boolean, setValue: boolean): void {
      const instance = this.modalHelper.open(RegexSearchModalComponent);
      instance.componentInstance.searchFor = searchFor;
      instance.componentInstance.item = item;
      instance.componentInstance.size = size;
      instance.componentInstance.limitToOne = limitToOne;
      instance.componentInstance.setValue = setValue;
      instance.componentInstance.successEvent.subscribe((text: string) => {
        console.log(text);
        console.log(item);
        this.triggers.primaryBehaviorOrRewardedBehavior[0][item.name] = text;
        this.updateTriggers();
        instance.close();
      });
    }

    initTriggerObj(): void {
      var tmpObj = {
                     class: 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Triggers$RewardedBehavior',
                     basePromotionCode: '',
                     baseBehaviorCode: '(.*)',
                     locationCode: '(.*)',
                     instrumentTypeCode: '(.*)'
                   };
      this.triggers.primaryBehaviorOrRewardedBehavior = [];
      this.triggers.primaryBehaviorOrRewardedBehavior.push(tmpObj);
      this.updateTriggers();
    };

    updateTriggers() {
      this.debouncer.next(this.triggers)
    }
}
