import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root'
})
export class ForgotPasswordService {
  private urlBase: string = this.globalData.baseUrl + 'users/';

  constructor(
    private globalData: AppDataService,
    private http: HttpClient
  ) { }

  public forgotPassword(email : any) {
    return this.http.post(this.urlBase + 'password/forgotten', email);
  }

  public newPassword(data : any, code : any){
    return this.http.post(this.urlBase + 'new/password/' + code, data);
  }
}
