import { MembersService } from './../../services/members.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators, UntypedFormGroup, ValidatorFn, AbstractControl } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faEye, faSave, faTimes, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { Person } from 'src/app/data/model';

@Component({
  selector: 'app-member-set-password-modal',
  templateUrl: './member-set-password-modal.component.html',
  styleUrls: ['./member-set-password-modal.component.scss']
})
export class MemberSetPasswordModalComponent implements OnInit {
  @Input() person: Person;
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  setPasswordForm: UntypedFormGroup;
  repeatPassword: string;
  errorMsg = '';
  faEye = faEye;
  faSave = faSave;
  faTimes = faTimes;
  faEyeSlash = faEyeSlash;

  constructor(
    private formBuilder: FormBuilder,
    private modalHelper: NgbModal,
    private membersService: MembersService,
    private responseHelper: ResponseHelperService
  ) { }

  ngOnInit(): void {
    this.setPasswordForm = this.formBuilder.group({
      newPassword: ['', Validators.compose([Validators.required, this.patternValidator()])],
      repeatPassword: ['', Validators.compose([Validators.required, this.patternValidator()])]
    }, {
      validator: this.matchValidator
    });
  }

  patternValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const regex = new RegExp('^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?[0-9]).{8,}$');
      return regex.test(control.value) ? null : { invalidPassword: true };
    };
  }

  matchValidator(group: UntypedFormGroup): any {
    const repeatPassword = group.controls.repeatPassword;
    if (group.controls.newPassword.value !== repeatPassword.value && !repeatPassword.errors) {
      repeatPassword.setErrors({ invalidMatch: 'Passwords do not match' });
    } else if (group.controls.newPassword.value === repeatPassword.value && repeatPassword.errors) {
      delete repeatPassword.errors.invalidMatch;
    }
    // even though there was an error, we still return null
    // since the new error state was set on the individual field
    return null;
  }

  toggleViewability(element: any): void {
    element.type = element.type === 'text' ? 'password' : 'text';
  }

  ok(): void {
    const person = {
      firstName: this.person.firstName,
      lastName: this.person.lastName,
      username: this.person.username,
      password: this.setPasswordForm.controls.newPassword.value
    };
    if (this.setPasswordForm.get('newPassword').value !== this.setPasswordForm.get('repeatPassword').value) {
      this.errorMsg = 'Password does not match';
    } else if (this.setPasswordForm.get('newPassword').value === this.setPasswordForm.get('repeatPassword').value) {
      this.membersService.setPassword(this.person.id, person).subscribe(() => {
        this.responseHelper.success('Password successfully changed');
        this.successEvent.emit();
      }, (data: any) => {
        this.responseHelper.error(this, data.error.error);
      });
    }
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
