import { AddCampaignModalComponent } from './../add-campaign-modal/add-campaign-modal.component';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { Filter } from '../data/class';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { TableHelperService } from '../services/helpers/table-helper.service';
import { PromotionService } from '../services/promotion.service';
import { faCalendarPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Campaign } from '../data/model';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-promotion-sponsor-campaign-tab',
  templateUrl: './promotion-sponsor-campaign-tab.component.html',
  styleUrls: ['./promotion-sponsor-campaign-tab.component.scss']
})
export class PromotionSponsorCampaignTabComponent implements OnInit, OnChanges {

  @Input() selectedPromSponsor: any;
  @Input() selectedPromotion: any;
  lockedTables = false;
  filteredStatuses = [];
  filter = new Filter();
  limit = 20;
  campaigns = [];
  lengthToCompare: number;
  lock = false;
  isLoading = false;
  show = false;
  faCalendarPlus = faCalendarPlus;
  faTrash = faTrash;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private tableHelperService: TableHelperService,
    private responseHelperService: ResponseHelperService,
    private promotionService: PromotionService,
    private modalHelper: NgbModal
  ) { }

  statuses = this.tableHelperService.getStatuses('ENTITY');

  ngOnInit(): void {
    this.initFilter();
    this.getCampaigns(false);
    this.show = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedPromSponsor && !changes.selectedPromSponsor.firstChange
        && changes.selectedPromSponsor.currentValue.id !== changes.selectedPromSponsor.previousValue.id) {
      this.campaigns = null;
      this.initFilter();
      this.getCampaigns(false);
    }
  }

  initFilter(): void {
    this.limit = 20;
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getCampaigns(concat: boolean): void {
    this.isLoading = true;
    this.promotionService.getPromotionCampaigns(this.selectedPromSponsor.id, this.selectedPromSponsor.sponsorPartyId)
      .subscribe((data: any) => {
        if (concat) {
          this.campaigns = this.campaigns.concat(parseResponse(data));
        } else {
          this.campaigns = parseResponse(data);
        }
        this.lengthToCompare = parseResponse(data).length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = parseResponse(data).length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      }, () => {
        this.isLoading = false;
        this.lockedTables = false;
        this.lock = false;
        this.responseHelperService.error(this, 'Error occurred!', true);
      });
  }

  setStatuses(): void {
    this.filter.statuses = this.tableHelperService.extractData(this.filteredStatuses);
    this.getCampaigns(false);
  }

  removeCampaign(promSponsorId: number, campaign: Campaign): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent);
    instance.componentInstance.text = 'Remove campaign ' + campaign.name + ' from promotion ' + this.selectedPromotion.name + '?';
    instance.componentInstance.header = 'Remove campaign';
    instance.componentInstance.successEvent.subscribe(() => {
      this.promotionService.removeCampaignFromPromotion(promSponsorId, campaign.id).subscribe((data: any) => {
        this.responseHelperService.success('Campaign successfully removed');
        this.getCampaigns(false);
      }, (data: any) => {
        this.responseHelperService.error(this, data.errorMsg);
      });
      instance.close();
    });
  }

  campaignModal(): void {
    const instance = this.modalHelper.open(AddCampaignModalComponent);
    instance.componentInstance.inputData = {
      promotionSponsorId: this.selectedPromSponsor.id,
      promotionId: this.selectedPromotion.id,
      sponsorId: this.selectedPromSponsor.sponsorPartyId,
    };
    instance.componentInstance.successEvent.subscribe(() => {
      this.getCampaigns(false);
      instance.close();
    });
  }

}
