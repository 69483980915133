import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Subject} from 'rxjs';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-activity-age-restriction',
  templateUrl: './activity-age-restriction.component.html',
  styleUrls: ['./activity-age-restriction.component.scss']
})
export class ActivityAgeRestrictionComponent implements OnInit {
    @Input() activityAge: any;
    directions: any;
    units: any;
    faTimes = faTimes;
    faPlus = faPlus;
    @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
    debouncer: Subject<string> = new Subject<string>();

  constructor(
  ) {
      this.debouncer
        .pipe(debounce(() => interval(1000)))
        .subscribe((value) => this.inputChangeEmitter.emit(value));
    }

  ngOnInit(): void {
       this.units = [
         {'value' : 'YEAR',    'text': 'YEAR'},
         {'value' : 'MONTH','text': 'MONTH'},
         {'value' : 'WEEK',      'text': 'WEEK'},
         {'value' : 'DAY','text': 'DAY'}
       ];

       this.directions = [
          {'value' : 'OLDER',     'text': 'OLDER'},
          {'value' : 'YOUNGER',    'text': 'YOUNGER'}
       ];
       console.log(this.activityAge);
  }

  initRestrictionObject(): void {
    var tmpObj = { class: "com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Restrictions$Behavioral$ActivityAge"};
    if(this.activityAge){
      this.activityAge.push(tmpObj);
    } else {
      this.activityAge = [];
      this.activityAge.push(tmpObj);
    }
 
  }

  updateRestrictions() {
    this.debouncer.next(this.activityAge);
  }

  removeRestriction(restriction) {
    this.activityAge.splice(this.activityAge.indexOf(restriction), 1);
    this.updateRestrictions();
  }
}
