import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'idAttribute'
})
export class IdAttributePipe implements PipeTransform {

  transform(value: string): string {
    return value.replace(/([^\w-]+)/gi, '');
  }

}
