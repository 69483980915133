import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Communication } from '../data/model';
import { CommunicationsService } from '../services/communications.service';
import { ResponseHelperService } from '../services/helpers/response-helper.service';

@Component({
  selector: 'app-communications-test-modal',
  templateUrl: './communications-test-modal.component.html',
  styleUrls: ['./communications-test-modal.component.scss']
})
export class CommunicationsTestModalComponent implements OnInit {

  @Input() communication: Communication;
  title = 'Send a Test Communication';
  commMessage: any;
  partyId: number;
  communicationTestForm: UntypedFormGroup;
  errorMsg: string;
  faPaperPlane = faPaperPlane;
  faTimes = faTimes;

  constructor(
    private formBuilder: FormBuilder,
    private modalHelper: NgbModal,
    private communicationsService: CommunicationsService,
    private responseHelperService: ResponseHelperService
  ) { }

  ngOnInit(): void {
    this.communicationTestForm = this.formBuilder.group({
      partyId: [this.partyId, Validators.required]
    });
  }

  ok(): void {
    let formValue = this.communicationTestForm.value;
    this.commMessage = {};
    this.commMessage.communicationId = this.communication.id;
    this.commMessage.partyId = formValue.partyId;
    this.commMessage.instanceAttributes = [];
    this.commMessage.attachments = [];

    this.communicationsService.sendTestNotification(this.commMessage).subscribe((data: any) => {
      this.responseHelperService.success('Test Notification Sent to CHIRP for Processing...', true);
    }, (data: any) => {
       this.errorMsg = data.error.error;
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

}
