<form [formGroup]="deliveryOptionsForm">
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div formArrayName="options">
      <div class="row" *ngIf="getOptions().controls.length === 0">
        <div class="col-md-5">
          <div class="form-group">
            <label>Option Name</label>
            <input [hidden]="true" type="text" class="form-control">
            <div>Click the button to add a new row.</div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-group">
            <label>Option Value</label>
            <input [hidden]="true" type="text" class="form-control">
          </div>
        </div>
        <div class="col-md-2">
        <button class="btn btn-dark-blue btn-sm float-right"
              (click)="addOption()"><fa-icon [icon]="faPlus"></fa-icon></button>
        </div>
      </div>
      <div class="row" *ngFor="let option of getOptions().controls; let i=index" [formGroupName]="i">
        <div class="col-md-5">
          <div class="form-group">
            <input type="text"  name="optionName" class="form-control" formControlName="optionName" [appTrim]="!option.controls.id" autofocus  /> 
            <div class="mt-xs" [hidden]="option.controls.optionName.valid">
              <small class="text-danger">Input option name</small>
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <input type="text"  name="optionValue" class="form-control" formControlName="optionValue"  [appTrim]="!option.controls.id" /> 
            <div class="mt-xs" [hidden]="option.controls.optionValue.valid">
              <small class="text-danger">Input option value</small>
            </div>
        </div>
        <div class="col-md-2">
          <input type="number" [hidden]="true" name="optionValue" class="form-control" formControlName="id"   /> 
          <div class="form-group float-right">
            <button class="btn btn-dark-blue btn-sm delete float-right"
              (click)="removeOption(i)"><fa-icon [icon]="faTrash"></fa-icon></button>
            <button class="btn btn-dark-blue btn-sm float-right" *ngIf="i == getOptions().controls.length-1"
              (click)="addOption()"><fa-icon [icon]="faPlus"></fa-icon></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" (click)="ok()" [disabled]="!deliveryOptionsForm.valid" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>