import { Component, OnInit, Input } from '@angular/core';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-filter-expander',
  templateUrl: './filter-expander.component.html',
  styleUrls: ['./filter-expander.component.scss']
})
export class FilterExpanderComponent implements OnInit {

  @Input() section: any;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;

  constructor() { }

  ngOnInit(): void {
  }

  toggleOpen(): void {
    this.section.open = !this.section.open;
  }

}
