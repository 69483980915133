<form [formGroup]="roleForm">
    <div class="modal-header">
        <h1>Add access role to user</h1>
    </div>
    <div class="modal-body">
        <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
            {{errorMsg}}
            <a (click)="clearErrorMessage()">
                <span class="float-right close-button">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </span>
            </a>
        </div>
        <div class="form-group">
            <label>Choose access role</label>
            <select class="form-control"  formControlName="roleName">
                <option *ngFor="let role of roles" value="{{role.roleName}}">{{role.roleName}}</option>
            </select>
            <div class="mt-2" [hidden]="roleForm.controls.roleName.valid">
                <small class="text-danger">Select role name</small>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" [disabled]="!roleForm.valid" type="submit" (click)="ok()"> Save </button>
        <button class="btn btn-warning" type="button" (click)="cancel()"> Cancel </button>
    </div>
</form>