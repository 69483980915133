<form [formGroup]="fieldPropertyForm">
    <div class="bordered-container-2px">
        <fieldset>
            <legend>Field Values</legend>
        </fieldset>
        <div class="form-group">
            <div class="row">
                <div class="col-md-3">
                    <label class="float-right control-label">Field Index</label>
                </div>
                <div class="col-md-3">
                    <input type="number" class="form-control" formControlName="fieldIndex">
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-md-3">
                    <label class="float-right control-label">Field Name</label>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" formControlName="fieldName" />
                </div>
            </div>
        </div>
    </div>
    <div class="bordered-container-2px">
        <fieldset>
            <legend>Default values</legend>
        </fieldset>
        <div class="form-group">
            <div class="row">
                <div class="col-md-3">
                    <label class="float-right control-label">Field Default</label>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" formControlName="fieldDefault" />
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-md-3">
                    <label class="float-right control-label">Hash Field</label>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" formControlName="hashField" />
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-md-3">
                    <label class="float-right control-label">Concat Field</label>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" formControlName="concatField">
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-md-3">
                    <label class="float-right control-label">Concat Delimiter</label>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" formControlName="concatDelimiter">
                </div>
            </div>
        </div>
    </div>
    <div class="bordered-container-2px">
        <fieldset>
            <legend>Validation elements</legend>
        </fieldset>
        <div class="form-group">
            <div class="row">
                <div class="col-md-3">
                    <label for="required" class="float-right form-check-label">
                        Required
                    </label>
                </div>
                <div class="col-md-3">
                    <input id="required" type="checkbox" formControlName="required" />
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-md-3">
                    <label class="float-right control-label">Field Length</label>
                </div>
                <div class="col-md-3">
                    <input type="number" class="form-control" formControlName="fieldLength">
                </div>
            </div>
        </div>
        <div class="form-group">
            <div class="row">
                <div class="col-md-3">
                    <label class="float-right control-label">Field Regex</label>
                </div>
                <div class="col-md-3">
                    <input type="text" class="form-control" formControlName="fieldRegex">
                </div>
            </div>
        </div>
    </div>
    <div class="form-group m-t">
        <div class="row">
            <div class="col-md-4 offset-md-3">
                <span *ngIf="index !== null">
                    <button class="btn btn-attention" title="Cancel" (click)="emitEvent('reset', index)">
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </button>
                    <button id="create-field-property" class="btn btn-dark-blue" type="button" (click)="emitEvent('save', index)"
                        title="Add">
                        <fa-icon [icon]="faSave"></fa-icon>
                    </button>
                </span>
                <span *ngIf="index === null">
                    <button class="btn btn-attention" type="reset" title="Cancel">
                        <fa-icon [icon]="faTimes"></fa-icon>
                    </button>
                    <button id="create-field-property" class="btn btn-dark-blue" type="button" (click)="emitEvent('add')"
                        title="Add">
                        <fa-icon [icon]="faPlus"></fa-icon>
                    </button>
                </span>
            </div>
        </div>
    </div>
</form>