<div class="list-wrapper cursor-pointer">
    <div class="row promo-element" (click)="selectPromotion(promotion, $event)"
         [ngClass]="{'faded-promo' : !promotion.hasSelectedPackage && selectedPackage, 'active-promo' : type==='packaged-sponsorship' && selectedPromotion ? (promotion.id === selectedPromotion.id) : (promotion === selectedPromotion) }"
         title="{{type==='packaged-sponsorship' ? promotion.promotionName : promotion.name}}">
      <div class="col-md-1">
        <span *ngIf="type!=='packaged-sponsorship'" class="float-left colored-circle uppercase" [ngClass]="promotion.typeColor" [attr.title]="promotion.typeName">{{promotion.typeName.substring(0, 1)}}</span>
        <span *ngIf="type==='packaged-sponsorship'" class="float-left colored-circle uppercase" [ngClass]="promotion.typeColor" [attr.title]="promotion.promotionName">{{promotion.promotionName.substring(0, 1)}}</span>
      </div>
      <div class="col-md-9 promotion-div uppercase">
        <fa-icon *ngIf="promotion.children" [icon]="showChilds ? faChevronUp : faChevronDown" (click)="toggleChildren()"></fa-icon>
        <span *ngIf="type!=='packaged-sponsorship'">
          {{ promotion.name }}
        </span>
        <span *ngIf="type==='packaged-sponsorship'">
          {{ promotion.promotionName }}
        </span>
      </div>
      <div class="col-md-2">
        <span class="float-right colored-circle" [ngClass]="statusHelper[promotion.status]" [attr.title]="promotion.status">{{promotion.status.substring(0, 1)}}</span>
        <span *ngIf="promotion.children" class="float-right mr-2">
          ({{promotion.children.length}})
        </span>
      </div>
    </div>
    <ul *ngIf="promotion.children && showChilds" class="list-none">
      <li *ngFor="let promo of promotion.children" [ngClass]="'parent-id-' + promo.parentId">
        <app-promo-tree
          [promotion]="promo"
          [selectedPackage]="selectedPackage"
          [selectedPromotion]="selectedPromotion"
          (promotionChanged)="promotionChanged.emit($event)"
          [type]="type">
        </app-promo-tree>
      </li>
    </ul>
</div>
