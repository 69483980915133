<div [ngClass]="filter.inputClass" class="search-filter-fields" *ngIf="section.open">
    <div class="btn-group filter" ngbDropdown #filterDropdown="ngbDropdown" autoClose="outside">
      <button type="button" class="btn btn-md btn- filter-button form-control" id="dropdownSegment" ngbDropdownAnchor 
          (focus)="filterDropdown.open()">
          <span class="float-left">{{ filter.placeholder }} ({{ filter.selectedCount || 0 }})</span>
      </button>
      <div ngbDropdownMenu aria-labelledby="filterDropdown">
        <div *ngFor="let choice of filter.choices">
            <div ngbDropdownItem class="checkbox checkbox-info" style="white-space: nowrap">
              <input id="{{section.key + '-' + 'dropdown-checkbox' + '-' + choice[filter.nameProp]}}"
                [(ngModel)]="choice.state" type="checkbox"
                (ngModelChange)="toggleSelection(choice, filter);"/>
              <label for="{{section.key + '-' + 'dropdown-checkbox' + '-' + choice[filter.nameProp]}}" >
                <span style="overflow: hidden; text-overflow: ellipsis; max-width: 100%;">{{ choice[filter.nameProp] }}</span>
              </label>
            </div>
        </div>
      </div>
  </div>
</div>