<form [formGroup]="addressForm">
    <div class="modal-header">
      <h1>Redemption Address</h1>
    </div>
    <div class="modal-body">
        <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
            {{errorMsg}}
            <a (click)="clearErrorMessage()">
              <span class="float-right close-button">
                <fa-icon [icon]="faTimes"></fa-icon>
              </span>
            </a>
        </div>
      <div class="row">
        <div class="col-md-12 redemption-modal">
          <div class="form-group">
            <label>Address Line 1</label>
            <input type="text" class="form-control" formControlName="address1" autofocus="autofocus"/>
          </div>
          <div class="form-group">
            <label>Address Line 2</label>
            <input type="text" class="form-control" formControlName="address2"/>
          </div>
          <div class="form-group">
            <label>City</label>
            <input type="text" class="form-control" formControlName="city"/>
          </div>
          <div class="form-group">
            <label>State</label>
            <input type="text" class="form-control" formControlName="state"/>
          </div>
          <div class="form-group">
            <label>Zip</label>
            <input type="text" class="form-control" formControlName="zip"/>
          </div>
          <div class="form-group">
            <label>Country</label>
            <input type="text" class="form-control" formControlName="country"/>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-dark-blue" type="submit" (click)="ok()">
        <fa-icon [icon]="faSave"></fa-icon>
      </button>
      <button class="btn btn-attention" type="button" (click)="cancel()">
        <fa-icon [icon]="faTimes"></fa-icon>
      </button>
    </div>
  </form>
  