import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faSave, faSpinner, faTimes, faUpload } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ProgramService } from 'src/app/services/program.service';
import { Observable } from 'rxjs';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';


@Component({
  selector: 'app-program-import-modal',
  templateUrl: './program-import-modal.component.html',
  styleUrls: ['./program-import-modal.component.scss']
})
export class ProgramImportModalComponent implements OnInit {
  isLoading: boolean;
  faSave = faSave;
  faTimes = faTimes;
  errorMsg = '';
  faUpload = faUpload;
  faSpinner = faSpinner;
  fileNamePlease: any = { name: '', success: false };
  job: any = {};
  programIsInUse: any;
  programJSON: any = {};


  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  constructor(private modalHelper: NgbModal,
              private toastrService: ToastrService,
              private programService: ProgramService) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  ok(): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm', windowClass: 'pull-to-front' });
    instance.componentInstance.text = 'This program might contain promotions that are currently being sponsored. Importing could result in existing sponsorships being edited. Would you like to continue?';
    instance.componentInstance.header = 'Import Program?';
    instance.componentInstance.dismissAllOnCancel = false;
    instance.componentInstance.successEvent.subscribe(() => {
      this.programService.importProgram(this.programJSON).subscribe(() => {
        this.fileNamePlease.success = true;
        instance.close();
        this.successEvent.emit('success');
      }, (data: any) => {
        this.errorMsg = data.error.error;
      });
    })
    instance.componentInstance.cancelEvent.subscribe(() => {
      instance.close();
    });
  }

  changeListener($event): void {
    this.readThis($event.target);
  }

  readThis(inputValue: any): void {
    const file: File = inputValue.files[0];
    this.fileNamePlease.name = file.name;
    const myReader: FileReader = new FileReader();
    this.job = inputValue.parentElement;
    this.isLoading = true;
    myReader.onloadend = () => {
      try {
        this.programJSON = JSON.parse(myReader.result as string);
        this.fileNamePlease.success = true;
        this.isLoading = false;
        if(this.programJSON){
          this.programService.findSponsorshipsForImportingProgram(this.programJSON).subscribe((data: any) => {
            this.programIsInUse = data.entity;
          });
        }
      } catch (e) {
        this.toastrService.error('Invalid JSON');
        this.fileNamePlease.success = false;
        this.isLoading = false;
        return;
      }
    };
    myReader.readAsText(file);
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
