import { Component, EventEmitter, Input, OnInit, Output, ViewChild, NgModule } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { ProgramService } from 'src/app/services/program.service';
import { PromotionConfigService } from 'src/app/services/promotion-config.service';
import { PromotionService } from 'src/app/services/promotion.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FormBuilder, UntypedFormGroup, Validators, FormsModule } from '@angular/forms';
import { faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { ActivatedRoute } from '@angular/router';
import { PromotionTemplate } from 'src/app/data/model';
import { Promotion } from 'src/app/data/model';
import * as $ from 'jquery';
import {Observable, Subject} from 'rxjs';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-activity-window-restriction',
  templateUrl: './activity-window-restriction.component.html',
  styleUrls: ['./activity-window-restriction.component.scss']
})
export class ActivityWindowRestrictionComponent implements OnInit {
    @Input() activityWindow: any;
    conditions: any;
    faTimes = faTimes;
    faPlus = faPlus;
    @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
    debouncer: Subject<string> = new Subject<string>();

  constructor(
    private router: ActivatedRoute,
    private formBuilder: FormBuilder,
    private formsModule: FormsModule,
    private modalHelper: NgbModal,
  ) {
      this.debouncer
        .pipe(debounce(() => interval(1000)))
        .subscribe((value) => this.inputChangeEmitter.emit(value));
    }

  ngOnInit(): void {
     this.conditions = [
         {'value' : true,     'text': 'WITHIN'},
         {'value' : false,    'text': 'OUTSIDE OF'}
     ];
  }

  initRestrictionObject(): void {
    var tmpObj = { class: "com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Restrictions$Behavioral$ActivityWindow" };
    if(this.activityWindow){
      this.activityWindow.push(tmpObj);
    } else {
      this.activityWindow = [];
      this.activityWindow.push(tmpObj);
    }
    console.log(this.activityWindow);
  }

  updateRestrictions() {
    this.debouncer.next(this.activityWindow);
  }

  removeRestriction(restriction) {
    this.activityWindow.splice(this.activityWindow.indexOf(restriction), 1);
    this.updateRestrictions();
  }
}
