import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CommunicationStrategyService } from '../services/communications-strategy.service';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-strategy-campaign-tab',
  templateUrl: './strategy-campaign-tab.component.html',
  styleUrls: ['./strategy-campaign-tab.component.scss']
})
export class StrategyCampaignTabComponent implements OnInit {
  campaigns: any[];
  isLoading = false;
  lengthToCompare: number;
  limit = 20;
  offset = 0;
  lockedTables = false;
  lock = false;
  @Input() commStrategyId: number;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  constructor(private toastr: ToastrService,
              private communicationStrategyService: CommunicationStrategyService ) { }

  ngOnInit(): void {
    this.getCampaign(false);
  }

  getCampaign(concat: boolean): void {
    this.isLoading = true;
    this.communicationStrategyService.getCampaigns(this.commStrategyId, {limit: this.limit, offset: this.offset}).subscribe((data: any) => {
      if (concat) {
        this.campaigns = this.campaigns.concat(parseResponse(data));
      } else {
        this.campaigns = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.offset += this.limit;
      this.isLoading = false;
      this.lockedTables = this.lengthToCompare  !== this.limit;
      this.lock = this.lockedTables;
    }, () => {
      this.lockedTables = false;
      this.lock = false;
      this.isLoading = false;
      this.toastr.error('Error occured!');
    });
  }

}
