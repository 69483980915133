import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../app-data.service';
import { User } from '../data/model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(private http: HttpClient, private globalData: AppDataService) { }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getUsers(params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'users/', this.setParams(params));
  }

  getUser(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'users/' + id);
  }

  deleteUser(id: number): any {
    return this.http.delete<any>(this.globalData.baseUrl + 'users/' + id);
  }

  createOrUpdateUser(id: number, data: User): any {
    if (id){
      return this.http.put<any>(this.globalData.baseUrl + 'users/' + id, data);
    }
    return this.http.post<any>(this.globalData.baseUrl + 'users/', data);
  }

  getEntity(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'users/' + id);
  }
}
