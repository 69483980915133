import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faPencilAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Choice, Filter, SearchFilters } from 'src/app/data/class';
import { PromotionCategory } from 'src/app/data/model';
import { parseResponse } from 'src/app/data/parseResponseFunction';
import { PromotionCategoryModalComponent } from 'src/app/promotion-category-modal/promotion-category-modal.component';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { PromotionCategoryService } from 'src/app/services/promotion-category.service';

@Component({
  selector: 'app-promotion-category-tab',
  templateUrl: './promotion-category-tab.component.html',
  styleUrls: ['./promotion-category-tab.component.scss']
})
export class PromotionCategoryTabComponent implements OnInit {

  @Input() sponsor: any;
  @Input() sponsorId: number;
  show: boolean;
  isLoading: boolean;
  limit = 20;
  filter = new Filter();
  searchFilters: SearchFilters;
  statuses: Choice[];
  lock: boolean;
  lengthToCompare: number;
  faPlus = faPlus;
  faTrash = faTrash;
  faPencilAlt = faPencilAlt;
  filteredStatuses: Filter[] = [];
  promotionCategories: any[] = [];
  showMoreButton: any = {name : 'Show More', active: true};
  fileNamePlease: any = {name : '', success : false};
  lockedTables: any = {state: false};
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(private tableHelperService: TableHelperService,
              private toastr: ToastrService,
              private promotionCategoryService: PromotionCategoryService,
              private modalHelper: NgbModal,
              private title: Title){}

  ngOnInit(): void {
    this.title.setTitle('Programs');
    this.show = true;
    this.searchFilters = {
      formName: 'promoCategorySearch',
      searchCallback: () => { this.initNewSearch(); },
      filters: [{
          name: 'search',
          placeholder: 'Keyword...'
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown'
        }
      ],
      addEntityButtons: [{
        icon: faPlus,
        buttonText: 'New Promotion Category',
        if: true,
        callback: () => { this.promotionCategoryModal(); },
        title: 'New Promotion Category'
       }]
    };
  }

  clearKeyword(): void {
    this.initFilter();
    this.filter.search = '';
    this.getPromotionCategories(false);
  }

  initNewSearch(): void {
    this.initFilter();
    this.getPromotionCategories(false);
  }

  clearAllFilters(): void {
    this.filteredStatuses = [];
    this.setStatuses();
    this.filter.search = null;
    this.filter = new Filter();
    this.initFilter();
    this.getPromotionCategories(false);
  }

  getPromotionCategories(concat: boolean): void{
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.promotionCategoryService.getAllPromotionCategories(this.filter).subscribe(data => {
      if (concat) {
        this.promotionCategories = this.promotionCategories.concat(parseResponse(data));
      } else {
        this.promotionCategories = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables.state = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables.state;
      this.isLoading = false;
    }, err => {
      this.lockedTables.state = false;
      this.lock = false;
      this.isLoading = false;
      this.toastr.error('Error occured!');
    });
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
    if (this.sponsorId){
      this.filter.sponsorId = this.sponsorId;
    }
  }

  setStatuses(): void {
    this.searchFilters.filters[1].value  = this.tableHelperService.extractData(this.filteredStatuses);
  }

  deletePromotionCategory(promotionCategory: PromotionCategory): void{
    const instance = this.modalHelper.open(ConfirmDialogComponent, {size: 'sm'});
    instance.componentInstance.text = 'Delete category type with ID: ' + promotionCategory.id;
    instance.componentInstance.header = 'Delete category?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.promotionCategoryService.deletePromotionCategory(promotionCategory.id).subscribe(() => {
          this.toastr.success('Promotion category removed');
          this.initFilter();
          this.getPromotionCategories(false);
          instance.close();
          promotionCategory.status = 'DELETED';
      });
    });
  }

  promotionCategoryModal(promotionCategory?: PromotionCategory): void {
    const instance = this.modalHelper.open(PromotionCategoryModalComponent);
    instance.componentInstance.promotionCategory = promotionCategory;
    instance.componentInstance.sponsorId = this.sponsorId;
    instance.componentInstance.successEvent.subscribe((data: any) => {
      if (data){
        this.toastr.success(`Promotion category successfully ${instance.componentInstance.isEdit ? 'updated' : 'created'}`);
        this.initNewSearch();
        instance.close();
      }
    });
  }
}
