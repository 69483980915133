<div class="panel-body">
  <div class="row">
    <div class="col-md-12">
      <div>
        <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
        <div class="row table-instrument-types m-b">
          <table class="table table-pointable table-bordered" width="100%">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let integrationKey of integrationKeys">
                <td>{{integrationKey.id}}</td>
                <td>{{integrationKey.name}}</td>
                <td>{{integrationKey.status}}</td>
                <td>
                  <div class="actions inline-table float-left">
                    <a class="btn btn-dark-blue btn-sm edit action" (click)="integrationKeyModal(integrationKey.id)"
                      title="Edit">
                      <fa-icon [icon]="faPencilAlt"></fa-icon>
                    </a>
                    <button class="btn btn-attention btn-sm delete action"
                      [disabled]="integrationKey.status==='DELETED'" (click)="deleteIntegrationKey(integrationKey.id)"
                      title="Delete">
                      <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                  </div>
                </td>
              </tr>
              <tr app-is-there-more [numColumns]="4" [newLengthEvent]="newLengthEvent"></tr>
            </tbody>
          </table>
        </div>
        <app-add-more-data-to-list (onScroll)="getIntegrationKeys(true)" [lock]="lock" [isLoading]="isLoading">
        </app-add-more-data-to-list>
      </div>
    </div>
  </div>
</div>