import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { SearchFilters, Filter } from 'src/app/data/class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EventSourcesService } from 'src/app/services/event-sources.service';
import { NewEventSourceModalComponent } from '../new-event-source-modal/new-event-source-modal.component';
import { NewEventSourceMeasureModalComponent } from '../new-event-source-measure-modal/new-event-source-measure-modal.component';
import { TemporalUnitsService } from 'src/app/services/temporal-units.service';
import { UnitService } from 'src/app/services/unit.service';
import { NewMeasureFunctionDialogComponent } from '../new-measure-function-dialog/new-measure-function-dialog.component';
import { RouteConfigLoadStart } from '@angular/router';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-event-sources-tab',
  templateUrl: './event-sources-tab.component.html',
  styleUrls: ['./event-sources-tab.component.scss']
})
export class EventSourcesTabComponent implements OnInit {
  eventSources: any[];
  isLoading: boolean;
  limit = 20;
  filter = new Filter();
  searchFilters: SearchFilters;
  lock: boolean;
  lengthToCompare: number;
  lockedTables = false;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  selectedSource: any;
  selectedSourceMeasureHierarchy: any[];
  measure: any;
  faPlus = faPlus;
  faTrash = faTrash;
  temporalUnits: any[];
  units: any[];
  groupedMeasures: any[] = [];

  constructor(private eventSourcesService: EventSourcesService,
    private modalHelper: NgbModal,
    private temporalUnitsService: TemporalUnitsService,
    private unitService: UnitService,
    private toastr: ToastrService) { }

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'eventSources',
      filters: [
        {
          name: 'keyword',
          placeholder: 'Keyword...'
        }
      ],
      searchCallback: () => { this.initNewSearch(); },
      addEntityButtons: [
        {
          icon: faPlus,
          title: 'New Event Source',
          buttonText: 'New Event Source',
          callback: () => { this.newEventSource(); },
          if: true
        }
      ]
    };
    this.getTemporalUnits();
    this.getUnits();
  }

  getTemporalUnits(): void {
    this.temporalUnitsService.getTemporalUnits().subscribe((data: any) => {
      if (data && data.length) {
        this.temporalUnits = data;
      } else {
        this.toastr.error('Error fetching temporalUnits or no temporalUnits found.');
      }
    });
  }

  getUnits(): void {
    this.unitService.getUnits().subscribe((data: any) => {
      if (data && data.length) {
        this.units = data;
      } else {
        this.toastr.error('Error fetching units or no units found.');
      }
    });
  }

  newSourceMeasure(): void {
    const instance = this.modalHelper.open(NewEventSourceMeasureModalComponent);
    instance.componentInstance.selectedSource = this.selectedSource;
    instance.componentInstance.units = this.units;
    instance.componentInstance.temporalUnits = this.temporalUnits;
    instance.componentInstance.successEvent.subscribe((data: any) => {
      if (data) {
        this.initNewSearch();
        this.getSourceMeasureHierarchy(this.selectedSource)
        instance.close();
      }
    });
  }

  newEventSource(): void {
    const instance = this.modalHelper.open(NewEventSourceModalComponent);
    instance.componentInstance.communication = '';
    instance.componentInstance.successEvent.subscribe((data: any) => {
      if (data) {
        this.initNewSearch();
        instance.close();
      }
    });
  }

  initNewSearch(): void {
    this.initFilter();
    this.getEventSources(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getEventSources(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.eventSourcesService.findEventSources(this.filter).subscribe((data: any) => {
      if (concat) {
        this.eventSources = this.eventSources.concat(parseResponse(data));
      } else {
        this.eventSources = parseResponse(data);
        this.selectedSource = null;
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
    }, () => {
      this.lockedTables = false;
      this.lock = false;
      this.isLoading = false;
      this.eventSources = [{
        code: 1, activityPassthrough: true, description: 'FitBit', measuresOnly: true,
        measuresCollection: [{ measureName: 'CALORIES', functionName: 'IDENT', temporalUnitCode: 'DAY', unit: 'kcal' }]
      }];
      this.toastr.error('Error occured!');
    });
  }

  rowCallback(event: any, eventSrc: any): void {
    $('tr.active').removeClass('active');
    $(event.currentTarget).toggleClass('active');
    this.getSourceMeasureHierarchy(eventSrc);
  }

  private getSourceMeasureHierarchy(eventSrc: any) {
    this.selectedSource = eventSrc;
    this.groupedMeasures = [];
    this.selectedSource.measuresCollection.forEach(measure => {
      var m: any = {};
      m.measureName = measure.measureName;
      m.temporalUnitCode = measure.temporalUnitCode;
      m.functionName = measure.functionName;
      m.unit = measure.unit;
      m.functions = [];
      this.groupedMeasures.push(m);
    });

    this.groupedMeasures = this.groupedMeasures.filter((measure, index, self) => index === self.findIndex((t) => (
      t.measureName === measure.measureName
    ))
    );
    this.groupedMeasures.forEach(measure => {
      this.selectedSource.measuresCollection.forEach(fun => {
        var f: any = {};
        if (measure.measureName === fun.measureName && fun.functionName != 'IDENT') {
          f.measureName = fun.measureName,
            f.temporalUnitCode = fun.temporalUnitCode,
            f.functionName = fun.functionName,
            f.unit = fun.unit,
            measure.functions.push(f);
        }
      });
    });
  }

  removeMeasure(measure: any): void {
    const old = [...this.selectedSource.measuresCollection];
    const measureIndex = this.selectedSource.measuresCollection.findIndex((m: any) => {
      return m.measureName === measure.measureName && m.functionName === measure.functionName
        && m.temporalUnitCode === measure.temporalUnitCode;
    });
    this.selectedSource.measuresCollection.splice(measureIndex, 1);
    this.eventSourcesService.updateEventSource(this.selectedSource).subscribe((data: any) => {
      this.selectedSource = data;
    }, (data: any) => {
      this.selectedSource.measuresCollection = old;
      this.toastr.error(data.error);
    });
    this.getSourceMeasureHierarchy(this.selectedSource)
  }

  newMeasureFunction(measure: any): void {
    const instance = this.modalHelper.open(NewMeasureFunctionDialogComponent);
    instance.componentInstance.selectedSource = this.selectedSource;
    instance.componentInstance.measure = measure;
    instance.componentInstance.functions = this.unitService.functions;
    instance.componentInstance.temporalUnits = this.temporalUnits;
    instance.componentInstance.successEvent.subscribe((data: any) => {
      if (data) {
        this.getSourceMeasureHierarchy(this.selectedSource)
        this.initNewSearch();
        this.toastr.success('Event source successfully created');
        instance.close();
      }
    });
  }
}