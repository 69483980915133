<div>
  <form>
    <label>{{title}}</label>
    <div class="row padded form-group">
      <div class="input-group date">
        <input class="skinny form-control cursor-pointer" name="restrictionDate" ngbDatepicker readonly [footerTemplate]="footerTemplateDateModel"
               #restrictionDate="ngbDatepicker" [(ngModel)]="restrictionDateValue"
               (click)= "restrictionDate.toggle()"
               (ngModelChange)="formatDate($event)" />
        <ng-template #footerTemplateDateModel>
          <div class="width-100 datePickerTemplate">
            <button class="buttonDateTemplate"  (click)="restrictionDate.toggle(); removeRestriction();">Clear</button>
          </div>
        </ng-template>
        <div class="input-group-append">
          <button class="btn btn-outline-secondary calendar" (click)="restrictionDate.toggle()" type="button">
            <fa-icon [icon]="faCalendar"></fa-icon>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
