<div id="panel-container_id" class="panel-container w-100 relative-position" appScrollWithMe>
  <span  id="shrinker" type="button" class="btn btn-sm btn-info shrinker float-right absolute-position mt-0" (click)="shrinkMe()"
    title="Hide or Show the Member Details Panel" *ngIf="type !== 'members' && type !== 'users'">
    <fa-icon *ngIf = "shrinked" [icon]="faCompressAlt"></fa-icon>
    <fa-icon *ngIf = "!shrinked" [icon]="faExpandAlt"></fa-icon>
  </span>
  <div class="ibox" [hidden]="!(!failed && loading)">
    <div class="ibox-content no-padding">
      <div class="tab-pane active aligned-text">
        <div class="row">
          <div class="col-md-12">
            <h2>
              <!-- <span><i class="fa fa-spinner fa-spin"></i> Loading</span> -->
              <span>
                <fa-icon [icon]="faSpinner"></fa-icon> Loading
              </span>
            </h2>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div>
  <div *ngIf="renderDetails" class="ibox" [hidden]="!(!loading && !failed)" >
    <div class="ibox-content no-padding" [@showHide] = "doneAnimation ? 'open': 'close'" (@showHide.done) = "stretchStartEmit()"  (@showHide.start) = "stretchEndEmit()">
      <div id="panel-details" class="tab-pane active">
          <div  [ngClass]="doneAnimation ? '' : 'invisible'">
        <div [ngClass]="{'details-panel-header': allowedToEmulateMember,  'details-panel-header-alert': !allowedToEmulateMember}"  class="row border-card-details-panel details-panel-header">
        <div class="col-md-12">
            <div class="row">
              <div class="col-md-8">
                <h2 class="details-panel-information">{{selectedPerson.firstName}}
                  {{selectedPerson.middleInitial}}
                  {{selectedPerson.lastName}}</h2>
              </div>
              <div class="col-md-4 form-group float-right" [hidden]="!(type === 'members' || type === 'users')">
                <a routerLink="/members/{{selectedPerson.id}}"
                   class="btn btn-primary btn-sm float-right details-panel-information" [hidden]="!(type === 'members')">
                  <fa-icon [icon]="faEye"></fa-icon> View
                </a>
                <a routerLink="/users/{{selectedPerson.id}}"
                   class="btn btn-primary btn-sm float-right details-panel-information" [hidden]="!(type === 'users')">
                  <fa-icon [icon]="faEye"></fa-icon> View
                </a>
              </div>
            </div>
            </div>
          </div>
        </div>
        <div class="details-panel-body" [@expandCollapse] = "shrinked ? 'open': 'close'" (@expandCollapse.done)="animationDone($event)" >
          <div [ngClass]="shrinked ? '' : 'invisible'" >
          <div class="row border-card-details-panel append-top">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-4 form-group details-panel-reduced-margin">
                  <span class="float-right darker-font-weight">Status</span>
                </div>
                <div class="col-md-8 form-group details-panel-reduced-margin">
                  <app-status-select [type]="type" [selectedModel]="selectedPerson" [permission]="globalData.isCSRManager && securityService.allowStatusUpdates"></app-status-select>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 form-group details-panel-reduced-margin">
                  <span class="float-right darker-font-weight">Profile ID</span>
                </div>
                <div class="col-md-8 form-group details-panel-text-overflow  details-panel-reduced-margin">
                  <span title="{{selectedPerson.id}}">{{selectedPerson.id}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 form-group details-panel-reduced-margin">
                  <span class="float-right darker-font-weight">Segment</span>
                </div>
                <div class="col-md-8 form-group details-panel-text-overflow details-panel-reduced-margin">
                  <span title="{{seg.code}}" *ngFor="let seg of selectedPerson.segments">{{seg.code}}<br></span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 form-group details-panel-reduced-margin">
                  <span class="float-right darker-font-weight">Gender</span>
                </div>
                <div class="col-md-8 form-group details-panel-text-overflow details-panel-reduced-margin">
                  <span title="{{selectedPerson.gender?.name}}">{{selectedPerson.gender?.name}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 form-group details-panel-reduced-margin">
                  <span class="float-right darker-font-weight">DOB</span>
                </div>
                <div class="col-md-8 form-group details-panel-text-overflow details-panel-reduced-margin">
                  <span title="{{selectedPerson.dateOfBirth}}">{{selectedPerson.dateOfBirth | date: 'yyyy-MM-dd'}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 form-group details-panel-reduced-margin">
                  <span class="float-right darker-font-weight">Created On</span>
                </div>
                <div class="col-md-8 form-group details-panel-text-overflow details-panel-reduced-margin">
                  <span title="{{selectedPerson.createdOn | date: 'yyyy-MM-dd': '+0000'}}">{{selectedPerson.createdOn |
                    date: 'yyyy-MM-dd': '+0000'}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 form-group details-panel-reduced-margin">
                  <span class="float-right darker-font-weight">Last Login</span>
                </div>
                <div class="col-md-8 form-group details-panel-text-overflow details-panel-reduced-margin">
                  <span title="{{selectedPerson.lastLogin | date: 'yyyy-MM-dd': '+0000'}}">{{selectedPerson.lastLogin |
                    date: 'yyyy-MM-dd': '+0000'}}</span>
                </div>
              </div>
              <div class="row" *ngIf="selectedPerson.loginAttemptCounter > 0 && !checkIsSSOInstance">
                <div class="col-md-4 form-group details-panel-reduced-margin">
                  <span class="float-right darker-font-weight">Login Attempts</span>
                </div>
                <div class="col-md-8 form-group details-panel-text-overflow details-panel-reduced-margin">
                  <span>{{selectedPerson.loginAttemptCounter}}</span>
                </div>
              </div>
              <div class="row" *ngIf="primaryLanguage !==  null">
                <div class="col-md-4 form-group details-panel-reduced-margin">
                  <span class="float-right darker-font-weight">Language</span>
                </div>
                <div class="col-md-8 form-group details-panel-text-overflow details-panel-reduced-margin">
                  <span>{{primaryLanguage}}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="row border-card-details-panel append-top" *ngIf="selectedPerson.activityInstruments">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 form-group">
                  <table class="full-width">
                    <thead>
                    <tr>
                      <th class="darker-font-weight">
                        Identifier Type
                      </th>
                      <th class="darker-font-weight">
                        ID
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                      *ngFor="let instrument of selectedPerson.activityInstruments.filter(instrumentFilter); let isOdd=odd; let isEven=even">
                      <td class="details-panel-text-overflow">
                        {{instrument.instrumentType.code}} <fa-icon [icon]="faStar" *ngIf="instrument.isPrimary">
                      </fa-icon>
                      </td>
                      <td class="details-panel-text-overflow">
                        {{instrument.instrumentNumber}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row border-card-details-panel append-top" *ngIf="!hidePasswords">
            <div class="col-md-12">
              <h5 class="darker-font-weight">Credentials</h5>
              <div class="row">
                <div class="col-md-4 form-group details-panel-reduced-margin">
                  <fa-layers [fixedWidth]="true" class="float-right" title="username">
                    <fa-icon [icon]="faCircle" class="green-icon-color fa-2x"></fa-icon>
                    <fa-icon [inverse]="true" [icon]="faUser"></fa-icon>
                  </fa-layers>
                </div>
                <div class="col-md-8 form-group details-panel-text-overflow details-panel-reduced-margin">
                  <span title="{{selectedPerson.username}}">{{selectedPerson.username}}</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 form-group details-panel-reduced-margin">
                </div>
                <div class="col-md-8 form-group details-panel-reduced-margin">
                  <button class="btn-as-link" (click)="sendPasswordReset()"
                          [disabled]="isPasswordRequestAvailable()">Send Password Reset</button>
                </div>
              </div>
              <div class="row" *ngIf="!hidePasswords">
                <div class="col-md-4 form-group details-panel-reduced-margin">
                </div>
                <div class="col-md-8 form-group details-panel-reduced-margin">
                  <button class="btn-as-link" (click)="setPassword()">Set Password</button>
                </div>
              </div>
            </div>
          </div>
          <div class="row border-card-details-panel append-top" *ngIf="selectedPerson.accounts">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 form-group">
                  <h5 class="darker-font-weight">Account Summary | <a href="" (click)="navigateToTab(3, $event)">accounts</a></h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 form-group">
                  <table class="full-width">
                    <thead>
                    <tr>
                      <th class="darker-font-weight">
                        Reward Code
                      </th>
                      <th class="darker-font-weight">
                        Balance
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let acc of selectedPerson.accounts; let i = index;" [ngClass]="i %2 ? 'odd' : 'even'"
                          title="Account Type:&nbsp;{{acc.accountType}} ;
                          YTD Earnings:&nbsp;{{acc.ytdEarnings ? acc.ytdEarnings : 0}} ;
                          Lifetime Earnings:&nbsp;{{acc.lifeEarnings ? acc.lifeEarnings : 0}}">
                      <td>
                        {{acc.rewardType.code}}
                      </td>
                      <td>
                        {{acc.balance || 0}}
                      </td>
                    </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="row border-card-details-panel append-top">
            <div class="col-md-12">
              <div class="row">
                <div class="col-md-12 form-group">
                  <h5 class="darker-font-weight">Contact | <a href="" (click)="navigateToTab(5, $event)"
                      *ngIf="globalData.isProgramManager">update</a></h5>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 form-group details-panel-reduced-margin">
                  <fa-layers [fixedWidth]="true" class="float-right" title="Email">
                    <fa-icon [icon]="faCircle" class="green-icon-color fa-2x"></fa-icon>
                    <fa-icon [inverse]="true" [icon]="faEnvelope"></fa-icon>
                  </fa-layers>
                </div>
                <div class="col-md-8 form-group details-panel-text-overflow details-panel-reduced-margin">
                  <span [hidden]="!hasAccessToSensitiveAccounts && !allowedToEmulateMember" title="{{selectedPerson.email?.emailAddress || 'n/a'}}">{{selectedPerson.email?.emailAddress || 'n/a'}}</span>
                  <span title="Redacted" [hidden]="hasAccessToSensitiveAccounts || allowedToEmulateMember">REDACTED</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-4 form-group details-panel-reduced-margin">
                  <fa-layers [fixedWidth]="true" class="float-right" title="Primary Address">
                    <fa-icon [icon]="faCircle" class="green-icon-color fa-2x"></fa-icon>
                    <fa-icon [inverse]="true" [icon]="faHome"></fa-icon>
                  </fa-layers>
                </div>
                <div class="col-md-8 form-group details-panel-text-overflow">
                   <span *ngIf="selectedPerson.address" [hidden]="!hasAccessToSensitiveAccounts && !allowedToEmulateMember"
                           title="{{selectedPerson.address.address1}} {{selectedPerson.address.address2}} {{selectedPerson.address.city}}, {{selectedPerson.address.state}} {{selectedPerson.address.postalCode}}">{{selectedPerson.address.address1}}
                       {{selectedPerson.address.address2}} {{selectedPerson.address.city}},
                       {{selectedPerson.address.state}} {{selectedPerson.address.postalCode}}</span> <span title="n/a" [hidden]="!(selectedPerson.address === null) || (!hasAccessToSensitiveAccounts && !allowedToEmulateMember)">n/a</span>
                  <span title="Redacted" [hidden]="hasAccessToSensitiveAccounts || allowedToEmulateMember">REDACTED</span>
                </div>
              </div>
              <div *ngIf="personCellPhone" class="row">
                <div class="col-md-4 form-group details-panel-reduced-margin">
                  <fa-layers [fixedWidth]="true" class="float-right" title="Cell Phone">
                    <fa-icon [icon]="faCircle" class="green-icon-color fa-2x"></fa-icon>
                    <fa-icon [inverse]="true" [icon]="faPhone">c</fa-icon>
                  </fa-layers>
                </div>
                <div class="col-md-8 form-group details-panel-text-overflow details-panel-reduced-margin">
                  <span [hidden]="!hasAccessToSensitiveAccounts && !allowedToEmulateMember" title="{{personCellPhone.phoneNumber || 'n/a'}}">{{personCellPhone.phoneNumber || 'n/a'}}</span>
                  <span title="Redacted" [hidden]="hasAccessToSensitiveAccounts || allowedToEmulateMember">REDACTED</span>
                </div>
              </div>
              <div *ngIf="personOfficePhone" class="row">
                <div class="col-md-4 form-group details-panel-reduced-margin">
                  <fa-layers [fixedWidth]="true" class="float-right" title="Office Phone">
                    <fa-icon [icon]="faCircle" class="green-icon-color fa-2x"></fa-icon>
                    <fa-icon [inverse]="true" [icon]="faPhone">o</fa-icon>
                  </fa-layers>
                </div>
                <div class="col-md-8 form-group details-panel-text-overflow details-panel-reduced-margin">
                  <span [hidden]="!hasAccessToSensitiveAccounts && !allowedToEmulateMember" title="{{personOfficePhone.phoneNumber || 'n/a'}}">{{personOfficePhone.phoneNumber || 'n/a'}}</span>
                  <span title="Redacted" [hidden]="hasAccessToSensitiveAccounts || allowedToEmulateMember">REDACTED</span>
                </div>
              </div>
              <div *ngIf="personHomePhone" class="row">
                <div class="col-md-4 form-group details-panel-reduced-margin">
                  <fa-layers [fixedWidth]="true" class="float-right" title="Home Phone">
                    <fa-icon [icon]="faCircle" class="green-icon-color fa-2x"></fa-icon>
                    <fa-icon [inverse]="true" [icon]="faPhone">h</fa-icon>
                  </fa-layers>
                </div>
                <div class="col-md-8 form-group details-panel-text-overflow details-panel-reduced-margin">
                  <span [hidden]="!hasAccessToSensitiveAccounts && !allowedToEmulateMember" title="{{personHomePhone.phoneNumber || 'n/a'}}">{{personHomePhone.phoneNumber || 'n/a'}}</span>
                  <span title="Redacted" [hidden]="hasAccessToSensitiveAccounts || allowedToEmulateMember">REDACTED</span>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12 form-group details-panel-no-bottom-margin">
                  <a class="btn btn-success btn-sm float-right" (click)="startNote(selectedPerson)">
                    <fa-icon [icon]="faFile"></fa-icon> &nbsp; Add Note
                  </a>
                  <a [hidden]="!(type === 'user' || type === 'users')"
                     class="btn btn-primary btn-sm float-right details-panel-reduced-margin-right"
                     (click)="edit(selectedPerson.id)">
                    <fa-icon [icon]="faPencilAlt"></fa-icon> Edit
                  </a>
                  <a
                    class="btn btn-success btn-sm float-right"
                    href="{{ jiraLink }}"
                    target="_blank"
                    style="color: #fff"
                    *ngIf="jiraLink && jiraLink.length > 0 && allowJiraAccess">
                    JIRA
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>
</div>
