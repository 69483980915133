import { map } from 'rxjs/operators';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { AppDataService } from './../app-data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {

  constructor(
    private http: HttpClient,
    private globalData: AppDataService,
    private responseHelperService: ResponseHelperService
  ) { }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getRewardsForActivity(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'behavior-activities/reward/' + id);
  }

  getActivitiesForMember(memberId: number, params: any): any {
    this.responseHelperService.setRequestInProgress();
    return this.http.get(this.globalData.baseUrl + 'behavior-activities/party/' + memberId, this.setParams(params)).pipe(map((res) => {
      return this.responseHelperService.processResponse(res);
    }, (err) => {
      return this.responseHelperService.processError(err);
    }));
  }

  getAttributesForActivity(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'behavior-activities/' + id + '/attributes');
  }

  insertActivity(activity: any): any {
    return this.http.post<any>(this.globalData.baseUrl + 'behavior-activities/', activity);
  }

  getActivitiesForReprocessingCount(params: any): any {
    return this.http.get(this.globalData.baseUrl + 'behavior-activities/activities-for-reprocessing-count' , this.setParams(params));
  }

  findAndReverseList(params: any): any {
    return this.http.get(this.globalData.baseUrl + 'behavior-activities/reverse' , this.setParams(params));
  }

  findAllForParty(params: any): any {
    return this.http.get(this.globalData.baseUrl +  'behavior-activities', this.setParams(params));
  }
}
