import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { TicketService } from './../../../services/ticket.service';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-interaction-entry-modal',
  templateUrl: './interaction-entry-modal.component.html',
  styleUrls: ['./interaction-entry-modal.component.scss']
})
export class InteractionEntryModalComponent implements OnInit {

  @Input() ticketEnrty: any;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private ticketService: TicketService,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal
  ) { }

  ngOnInit(): void { }

  ok(): void {
    this.ticketService.createOrUpdateTicketEntry(this.ticketEnrty, this.ticketEnrty.id).subscribe(() => {
      this.responseHelperService.success('Interaction successfully updated', true);
      this.successEvent.emit();
    }, (data: any) => {
      this.responseHelperService.error(this, data.error.error, true);
    });
  }
  cancel(): void {
    this.modalHelper.dismissAll();
  }
}
