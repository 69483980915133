import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';
import { HttpClient } from '@angular/common/http';
import { Role } from '../data/model';

@Injectable({
  providedIn: 'root'
})
export class AccessRoleService {

  constructor(private http: HttpClient, private globalData: AppDataService) { }

  getAllAccessRoles(): any {
    return this.http.get(this.globalData.baseUrl + 'access-roles');
  }

  getPartyAccessRoles(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'access-roles/party/' + id);
  }

  insertAccessRole(data: Role): any {
    return this.http.post(this.globalData.baseUrl + 'access-roles/party', data);
  }

  removeAccessRole(partyId: number, roleName: string): any {
    return this.http.delete(this.globalData.baseUrl + 'access-roles/party/' + partyId + '/rolename/' + roleName);
  }
}
