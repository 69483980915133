<form [formGroup]="campaignForm" novalidate="">
    <div class="modal-header">
        <h1>{{title}}</h1>
    </div>
    <div class="modal-body">
        <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
            {{errorMsg}}
            <a (click)="clearErrorMessage()">
                <span class="float-right close-button">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </span>
            </a>
        </div>
        <div class="form-group">
            <label>Select Campaign</label>
            <ng-select [items]="campaigns" bindLabel="name" bindValue="id" formControlName="campaignId" [clearable]="false">
            </ng-select>
            <div class="mt-2" [hidden]="campaignForm.controls.campaignId.valid">
                <small class="text-danger">Select campaign</small>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-primary" [disabled]="!campaignForm.valid" type="submit" (click)="ok()"> Save </button>
        <button class="btn btn-warning" type="button" (click)="cancel()"> Cancel </button>
    </div>
</form>