import { AppDataService } from './../app-data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PromotionConfigService {

  constructor(private http: HttpClient, private globalData: AppDataService) { }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getPromotionTemplate(promotionTemplateId: number, params: any): any {
    return this.http.get(this.globalData.baseUrl + 'promotion-configurations/promotion-template/' + promotionTemplateId, this.setParams(params));
  }

  validatePromotionTemplate(promotionTemplate: any, params: any): any {
    return this.http.post(this.globalData.baseUrl + 'promotion-configurations/promotion-template/valid', promotionTemplate, this.setParams(params));
  }

  savePromotionTemplate(promotionTemplate: any, promotionTemplateId: number, params: any): any {
    if(promotionTemplateId){
      return this.http.put(this.globalData.baseUrl + 'promotion-configurations/promotion-template/' + promotionTemplateId, promotionTemplate, this.setParams(params));
    } else {
      return this.http.post(this.globalData.baseUrl + 'promotion-configurations/promotion-template/', promotionTemplate, this.setParams(params));
    }
  }

  removeCampaignFromPromotion(promotionSponsorId: number, campaignId: number): any {
    return this.http.delete<any>(
      this.globalData.baseUrl + 'promotion-sponsor-campaigns/' + promotionSponsorId + '/campaign/' + campaignId);
  }

  createOdUpdatePromotion(id, data): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'promotions/' + id, data);
    }
    return this.http.post(this.globalData.baseUrl + 'promotions', data);
  }

  getPromotion(id: number): any {
     return this.http.get(this.globalData.baseUrl + 'promotions/' + id);
  }

  getAttributes(promotionId: number): any {
    return this.http.get(this.globalData.baseUrl + 'promotion-attributes/promotion/' + promotionId);
  }

  createOrUpdateAttribute(attrId: number, data: any): any {
    if (attrId) {
      return this.http.put(this.globalData.baseUrl + 'promotion-attributes/' + attrId, data);
    }
    return this.http.post(this.globalData.baseUrl + 'promotion-attributes/', data);
  }

  addCampaignToPromotion(data: any): any {
    return this.http.post(this.globalData.baseUrl + 'promotion-sponsor-campaigns', data);
  }

  getPromotionTemplates(promotionId: number, params: any): any {
    return this.http.get(this.globalData.baseUrl + 'promotion-configurations/promotion-templates/' + promotionId, this.setParams(params));
  }

  deletePromotionTemplate(templateId: number): any {
    return this.http.delete(this.globalData.baseUrl + 'promotion-configurations/promotion-template/' + templateId);
  }

  findTriggeredConfigs(id: number, sameSponsor: boolean): any {
    return this.http.get<any>(this.globalData.baseUrl + 'promotion-configurations/triggered/' + id + '?sameSponsor=' + sameSponsor);
  }

  findTriggeringConfigs(id: number, sameSponsor: boolean): any {
      return this.http.get<any>(this.globalData.baseUrl + 'promotion-configurations/triggering/' + id + '?sameSponsor=' + sameSponsor);
  }

  findThresholdRelatedConfigs(id: number, sameSponsor: boolean): any {
    return this.http.get<any>(this.globalData.baseUrl + 'promotion-configurations/threshold-related/' + id + '?sameSponsor=' + sameSponsor);
  }

  findCapRelatedConfigs(id: number, sameSponsor: boolean): any {
    return this.http.get<any>(this.globalData.baseUrl + 'promotion-configurations/cap-related/' + id + '?sameSponsor=' + sameSponsor);
  }

  findGatekeeperConfigs(id: number, sameSponsor: boolean): any {
    return this.http.get<any>(this.globalData.baseUrl + 'promotion-configurations/gatekeeper/' + id + '?sameSponsor=' + sameSponsor);
  }

  findSponsorsUsingTemplate(id: number): any {
   return this.http.get<any>(this.globalData.baseUrl + 'promotion-configurations/template-related/' + id);
  }

  getPromotionConfiguration(configId: number, params: any): any {
   return this.http.get<any>(this.globalData.baseUrl + 'promotion-configurations/' + configId, this.setParams(params));
  }

  validatePromotionConfig(data: any, params: any): any {
    return this.http.post(this.globalData.baseUrl + 'promotion-configurations/valid/', data, this.setParams(params));
  }

  savePromotionConfig(id: number, data: any, params: any): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'promotion-configurations/' + id, data, this.setParams(params));
    } else {
      return this.http.post(this.globalData.baseUrl + 'promotion-configurations/', data, this.setParams(params));
    }
  }
}
