<div class="panel-body">
    <div class="row">
      <div class="col-md-12" *ngIf="show">
        <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
        <div class="row">
          <table class="table table-pointable table-bordered" width="100%">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Class Name</th>
                <th width="30%">Description</th>
                <th>Status</th>
                <th>Enabled</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let filter of filters">
                <td>{{filter.id}}</td>
                <td>{{filter.name}}</td>
                <td>{{filter.className}}</td>
                <td>{{filter.description}}</td>
                <td>{{filter.status}}</td>
                <td>{{filter.enabled}}</td>
                <td>
                  <div class="float-left actions inline-table">
                    <input class="upload-input form-control" id="upload-input-2"  type="file" name="file" (change)="uploadFile($event, filter.id)"/>
                    <label class="upload-label btn btn-sm float-left btn-dark-blue" for="upload-input-2" title="Upload Private Key">
                      <fa-icon [icon]="faUpload"></fa-icon>
                    </label>                    
                    <a class="btn btn-dark-blue btn-sm edit action" (click)="filterModal(filter.id)">
                      <fa-icon [icon]="faPencilAlt"></fa-icon>
                    </a>
                    <button class="btn btn-attention btn-sm delete action" [disabled]="filter.status==='DELETED'"
                      (click)="deleteFilter(filter.id)">
                      <fa-icon [icon]="faTrashAlt"></fa-icon>
                    </button>
                  </div>
                </td> 
              </tr>
              <tr app-is-there-more [numColumns]="7" [newLengthEvent]="newLengthEvent"></tr>
            </tbody>
          </table>
        </div>
        <app-add-more-data-to-list (onScroll)="getFilters(true)" [lock]="lock" [isLoading]="isLoading"></app-add-more-data-to-list>
      </div>
    </div>
  </div>
