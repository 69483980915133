<div>
  <form>
    <label>The ACTIVITY (rewarded or not rewarded) history for the member must contain:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="behaviorCountThreshold !== null">
        <div *ngFor="let behCntThreshold of behaviorCountThreshold; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeThreshold(behCntThreshold)" title="Remove Threshold">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="thresholdVal_{{behCntThreshold.thresholdValue}}">more than</label>
              <input class="form-control m-b" type="number" id="thresholdVal_{{behCntThreshold.thresholdValue}}" name="thresholdValue" [(ngModel)]="behCntThreshold.thresholdValue" (ngModelChange)="updateThresholds()" />
            </div>
            <div class="form-group">
              <a
                class="control-label"
                ng-class="{ 'disabled-link': readOnly }"
                (click)="
                  regexSearchModal(
                    'BEHAVIORS',
                    { object: behCntThreshold, name: 'behaviorCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                title="Search for Behaviors"
                >previous Behaviors with Codes (comma separated list):</a
              >
              <input
                class="form-control form-readonly m-b"
                type="text"
                id="behCodes_{{ behCntThreshold.thresholdValue }}"
                name="behaviorCodes"
                [(ngModel)]="behCntThreshold.behaviorCodes"
                (ngModelChange)="updateThresholds()"
                (click)="
                  regexSearchModal(
                    'BEHAVIORS',
                    { object: behCntThreshold, name: 'behaviorCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                readonly
              />
            </div>
            <div class="form-group">
              <label>for the current:</label>
              <select name="period_{{behCntThreshold.thresholdValue}}" class="form-control"
                      [(ngModel)]="behCntThreshold.thresholdPeriod.value"
                      (ngModelChange)="updateThresholds()">
                <option *ngFor="let period of periods"
                        [value]="period.value">{{period.text}}</option>
              </select>
            </div>
             <div class="form-group">
               <label>Should the count include activities from later in the period than the current activity?</label>
               <select name="inclusion_{{behCntThreshold.thresholdValue}}" class="form-control"
                       [(ngModel)]="behCntThreshold.thresholdPeriod.inclusion"
                       (ngModelChange)="updateThresholds()">
                 <option *ngFor="let inclusionVal of inclusionValues"
                         [value]="inclusionVal.value">{{inclusionVal.text}}</option>
               </select>
             </div>
             <div class="form-group">
               <label class="control-label" for="previousPeriodCount_{{behCntThreshold.thresholdValue}}">How many previous periods should be included in the time span (leave empty for only the current period):</label>
               <input class="form-control m-b" type="number" id="previousPeriodCount_{{behCntThreshold.thresholdValue}}" name="previousPeriodCount" [(ngModel)]="behCntThreshold.previousPeriodCount" (ngModelChange)="updateThresholds()" />
             </div>
             <div class="form-group">
               <label class="control-label" for="relationshipName_{{behCntThreshold.thresholdValue}}">Include counts from members related by (leave blank for current member only or use a comma separated list):</label>
               <input class="form-control m-b" type="text" id="relationshipName_{{behCntThreshold.thresholdValue}}" name="relationshipName" [(ngModel)]="behCntThreshold.relationshipName" (ngModelChange)="updateThresholds()" />
             </div>
             <div class="form-group">
               <label class="control-label" for="friendlyMessage_{{behCntThreshold.thresholdValue}}">Message:</label>
               <input class="form-control m-b" type="text" id="friendlyMessage_{{behCntThreshold.thresholdValue}}" name="friendlyMessage" [(ngModel)]="behCntThreshold.friendlyMessage" (ngModelChange)="updateThresholds()" />
             </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initThresholdObject()"><fa-icon [icon]="faPlus"></fa-icon> Add Behavior Count Threshold</button>
      </div>
    </div>
  </form>
</div>
