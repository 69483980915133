<div class="panel-body">
    <div class="row">
        <div class="col-md-7">
            <div class="row m-b">
            </div>
            <div class="row table-activities">
                <table class="table table-pointable table-bordered">
                    <thead>
                        <tr>
                            <th width="10%">
                                Endpoint
                            </th>
                            <th width="10%">
                                Status
                            </th>
                            <th width="10%">
                                Run Date
                            </th>
                            <th width="10%">
                                Communication Cap
                            </th>
                            <th width="10%">
                                Delivery Window
                            </th>
                            <th width="10%">
                                Action
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr (click)="showInfo(endpoint, $event)" *ngFor="let endpoint of endpoints">
                            <td>{{endpoint.endpointTypeName}}</td>
                            <td>{{endpoint.status}}</td>
                            <td>{{endpoint.runDate}}</td>
                            <td>
                                <li *ngFor="let cap of endpoint.commCaps" class="list-unstyled">
                                    {{cap.maxCommunicationsNum}} per {{cap.maxCommunicationsPeriod}}
                                </li>
                            </td>
                            <td>{{endpoint.fromTime}} - {{endpoint.toTime}}</td>
                            <td>
                                <button class="btn btn-dark-blue btn-sm float-right" title="Add new filter"
                                    (click)="addNewFilter(endpoint)">
                                    <fa-icon [icon]="faPlus"></fa-icon>
                                </button>
                            </td>
                        </tr>
                        <tr app-is-there-more [numColumns]="6" [newLengthEvent]="newLengthEvent"></tr>
                    </tbody>
                </table>
            </div>
            <app-add-more-data-to-list (onScroll)="getEndpoints(true)" [lock]="lock"
                [isLoading]="isLoading"></app-add-more-data-to-list>
        </div>

        <div class="col-md-5 text-center pb-50">

            <h2><span class="light-blue">Filters:</span></h2>

            <span *ngIf="!filters.length">No filters</span>
            <div *ngIf="filters.length > 0" class="row integration-details-bar"></div>
            <div class="border-card" *ngFor="let filter of filters | sort: 'ordernum'; let i= index;" [ngClass]="{'mt-3': i!==0}">
                <a>
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4">
                                <span class="float-left">Name:</span>
                            </div>
                            <div class="col-md-8">
                                <span class="float-right text-right word-break-break-all">{{filter.name}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <span class="float-left">Description:</span>
                            </div>
                            <div class="col-md-8">
                                <span class="float-right text-right">{{filter.description}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-4">
                                <span class="float-left">Sequence:</span>
                            </div>
                            <div class="col-md-8">
                                <span class="float-right text-right">{{filter.ordernum}}</span>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <label (click)="showParams[i] = !showParams[i]">Parameters
                                    <fa-icon [icon]="showParams[i] ? faAngleDoubleUp : faAngleDoubleDown"></fa-icon>
                                </label>
                                <hr class="mt-0 mb-1" />
                            </div>
                        </div>
                        <div class="row" [hidden]="!showParams[i]">
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Code</label>
                                    
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <label>Value</label>
                                    
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <label>Type</label>
                                </div>
                            </div>
                        </div>
                        <div class="row" [hidden]="!showParams[i]"
                            *ngFor="let param of filter.parameters; let paramIndex = index">
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <input type="text" name="paramCode" [(ngModel)]="param.paramCode"
                                        class="form-control">
                                </div>
                            </div>
                            <div class="col-sm-3">
                                <div class="form-group">
                                    <input type="text" name="paramValue" [(ngModel)]="param.paramValue"
                                        class="form-control">
                                </div>
                            </div>
                            <div class="col-sm-4">
                                <div class="form-group">
                                    <select [(ngModel)]="param.paramType" name="paramType" class="form-control">
                                        <option *ngFor="let option of paramTypes" value="{{option}}">{{option}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-sm-2">
                                <div class="form-group">
                                    <button type="button" title="Remove Parameter"
                                        class="btn btn-sm btn-danger float-right"
                                        (click)="removeParameter(filter, param, paramIndex)">
                                        <fa-icon [icon]="faTrash"></fa-icon>
                                    </button>
                                    <button type="button" title="Add Parameter"
                                        class="btn btn-sm btn-primary float-right" (click)="addParameter(filter)"
                                        *ngIf="paramIndex === filter.parameters.length -1">
                                        <fa-icon [icon]="faPlus"></fa-icon>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="row" *ngIf="showParams">
                            <div class="col-md-12">
                                <button title="Save Parameters" type="button" class="btn btn-sm btn-primary float-right"
                                    (click)="saveParams(filter)">
                                    <fa-icon [icon]="faSave"></fa-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
