import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Segment } from '../../../data/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseHelperService } from '../../../services/helpers/response-helper.service';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../../../app-data.service';

@Component({
  selector: 'app-add-user-to-segment-modal',
  templateUrl: './add-user-to-segment-modal.component.html',
  styleUrls: ['./add-user-to-segment-modal.component.scss']
})
export class AddUserToSegmentModalComponent implements OnInit {

  @Input() segments: Segment[];
  @Input() addToSegment: (id: number, data: any) => any;
  @Input() id: number;
  @Input() type: string;
  @Input() typePretty: string;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  segment: Segment;
  title: string;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;

  constructor(
    private modalHelper: NgbModal,
    private responseHelperService: ResponseHelperService,
    // Don't remove http and globalData dependencies, they look like unused, but they need to be included because of addToSegment forwarded function
    private http: HttpClient,
    private globalData: AppDataService
  ) { }

  ngOnInit(): void {
    this.title = this.type ? 'Add ' + this.typePretty + ' to Segment' : 'Add to Segment';
  }

  ok(): void {
    this.addToSegment(this.id, [this.segment]).subscribe(() => {
      this.responseHelperService.success('Segments successfully updated', true);
      this.successEvent.emit();

    }, (data: any) => {
      this.responseHelperService.error(this, data.error.error);
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
