<form [formGroup]="communicationForm" novalidate>
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <label>Name</label>
      <input type="text" class="form-control" formControlName="name">
      <div class="mt-2" [hidden]="communicationForm.controls.name.valid">
        <small class="text-danger">Input a Name</small>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label>Endpoint Type</label>
          <ng-select [items]="endpointTypes" bindLabel="name" bindValue="name" formControlName="endpointType"></ng-select>
          <div class="mt-2" [hidden]="communicationForm.controls.endpointType.valid">
            <small class="text-danger">Select type</small>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label>Delivery Protocol</label>
          <ng-select [items]="deliveryProtocols" bindLabel="name" bindValue="id" formControlName="deliveryProtocol"></ng-select>
          <div class="mt-2" [hidden]="communicationForm.controls.deliveryProtocol.valid">
            <small class="text-danger">Select protocol</small>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isEdit">
      <label>Select segments</label>
      <ng-select [items]="filteredSegments" bindLabel="name" bindValue="id" [multiple]="true" formControlName="segmentIds"
        [hideselected]="true" [closeonselect]="false">
      </ng-select>
      <div class="mt-2" [hidden]="communicationForm.controls.segmentIds.valid">
        <small class="text-danger">Select segments</small>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div class="form-group">
          <label>Type</label>
          <ng-select [items]="commTypes" bindValue="id" bindLabel="name" formControlName="commTypeId"></ng-select>
          <div class="mt-2" [hidden]="communicationForm.controls.commTypeId.valid">
            <small class="text-danger">Select type</small>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <label>Status</label>
          <select formControlName="status" class="form-control" formControlName="status">
            <option *ngFor="let option of statuses" value="{{option}}">{{option}}</option>
          </select>
          <div class="mt-2" [hidden]="communicationForm.controls.status.valid">
            <small class="text-danger">Select status</small>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group form-check">
      <input type="checkbox" formControlName="requirePreference" id="requirePreference" class="form-check-input" />
      <label for="requirePreference" class="form-check-label">Require preference</label>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" [disabled]="!communicationForm.valid" (click)="ok()" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>