<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-9 content-container">
      <div class="ibox">
        <div class="ibox-title">
          <h2>Event Sources</h2>
        </div>
        <div class="ibox-content">
          <app-search-filter class="w-100" [searchFilters]="searchFilters"></app-search-filter>
          <div class="row">
            <div class="col-md-12">
              <div>
                <div class="row">
                  <table class="table table-pointable table-bordered full-width">
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Description</th>
                        <th>Activity Passthrough</th>
                        <th>Measures Only</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let eventSrc of eventSources" (click)="rowCallback($event, eventSrc)">
                        <td>{{eventSrc.code}}</td>
                        <td>{{eventSrc.description}}</td>
                        <td>{{eventSrc.activityPassthrough}}</td>
                        <td>{{eventSrc.measuresOnly}}</td>
                      </tr>
                      <tr app-is-there-more [numColumns]="4" [newLengthEvent]="newLengthEvent">
                      </tr>
                    </tbody>
                  </table>
                </div>
                <app-add-more-data-to-list (onScroll)="getEventSources(true)" [lock]="lock" [isLoading]="isLoading">
                </app-add-more-data-to-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3">
      <div class="ibox" *ngIf="selectedSource">
        <div class="ibox-content">
          <div class="tab-pane active">
            <div class="row">
              <div class="col-md-12">
                <h2>{{selectedSource.code}}</h2>
                <span>{{selectedSource.description}}</span>
                <div class="margin-20px-0">
                  <span class="measures-header">Measures</span>
                  <a class="btn btn-primary btn-sm float-right" (click)="newSourceMeasure()">
                    <fa-icon [icon]="faPlus"></fa-icon><span class="ml-3">New
                      Measure</span>
                  </a>
                </div>
                <div class="measure-list">
                  <div *ngFor="let measure of groupedMeasures" class="measure-group">
                    <div class="measure">
                      <div class="measure-name"><strong>{{measure.measureName}}</strong></div>
                      <div class="measure-actions"><button class="btn btn-danger btn-sm"
                          [disabled]="measure.functions.length > 0" (click)="removeMeasure(measure)">
                          <fa-icon [icon]="faTrash"></fa-icon>
                        </button></div>
                      <div class="measure-temporal-unit">{{measure.temporalUnitCode}}</div>
                    </div>
                    <h5 class="float-right" *ngIf="measure.functions.length > 0">Functions</h5>
                    <div class="measure measure-function" *ngFor="let function of measure.functions">
                      <div class="measure-name">{{function.functionName}}</div>
                      <div class="measure-actions"><button class="btn btn-danger btn-sm"
                          (click)="removeMeasure(function)">
                          <fa-icon [icon]="faTrash"></fa-icon>
                        </button></div>
                      <div class="measure-temporal-unit">{{function.temporalUnitCode}}</div>
                    </div>
                    <div class="function-controls">
                      <button class="btn btn-primary btn-sm float-right" (click)="newMeasureFunction(measure)">
                        <fa-icon [icon]="faPlus"></fa-icon><span class="margin-left-10px">New
                          Function</span>
                      </button>
                      <div class="clearfix"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>