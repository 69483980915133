import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class UtilityService {

  constructor() { }

  removeNullAndEmptyProperties(params: any): any {
    const modifiedParams: any = {};
    Object.keys(params).forEach((key: string) => {
      if (![null, undefined, '', []].includes(params[key])) {
        modifiedParams[key] = params[key];
      }
    });
    return modifiedParams;
  }

}
