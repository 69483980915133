import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root'
})
export class HierarchySourcesService {

  constructor(private http: HttpClient, private globalData: AppDataService) { }


  getHierarchySources(): any {
    return this.http.get(this.globalData.baseUrl + 'hierarchy-sources');
  }
}
