import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { faTimes, faCalendar } from '@fortawesome/free-solid-svg-icons';
import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { RewardService } from 'src/app/services/reward.service';

@Component({
  selector: 'app-reversal-confirmation-modal',
  templateUrl: './reversal-confirmation-modal.component.html',
  styleUrls: ['./reversal-confirmation-modal.component.scss']
})
export class ReversalConfirmationModalComponent implements OnInit {

  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Output() public cancelEvent: EventEmitter<any> = new EventEmitter();
  @Input() public txnNotes: string;
  @Input() public reversedOn: string;
  @Input() public personId: number;
  @Input() public id: number;
  @Input() public reversedBy: string;
  @Input() public accounts: any[] = [];
  title: string;
  faTimes = faTimes;
  faCalendar = faCalendar;
  reversalForm: UntypedFormGroup;
  errorMsg: string;

  constructor(private formBuilder: FormBuilder,
              private ressponseHelperService: ResponseHelperService,
              private rewardService: RewardService,
              private dateHelperService: DateHelperService,
              private modalHelper: NgbModal) { }

  ngOnInit(): void {
    this.title = 'Reversal Confirmation';
    this.reversalForm = this.formBuilder.group({
      reversedOn: [this.dateHelperService.stringToNgbDate(this.reversedOn), Validators.required],
      txnNotes: [this.txnNotes],
    });
  }

  clearDate(name: string): void {
    this.reversalForm.patchValue({
      [name]: null
    });
  }

  ok(): void {
    let value  = this.reversalForm.value;
    value.id = this.id;
    value.reversedBy = this.reversedBy;
    value.reversedOn = this.dateHelperService.ngbDateToString(value.reversedOn);
    this.rewardService.reverseTransaction(this.id, value).subscribe((data: any) => {
      this.ressponseHelperService.success('Successfully reverted transaction!', true);
      var account = this.accounts.find((el: any) =>{
        return el.id === data.entity.accountId;
      });
      if(account){
        account.balance += data.entity.amount;
      }
      this.successEvent.emit(account);
    }, (data: any) => {
       this.ressponseHelperService.error(this,data.error.error)
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
    this.cancelEvent.emit();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

}
