<div class="panel-body">
  <div class="row">
    <div class="col-md-12">
      <app-search-filter
        *ngIf="showSearch"
        [searchFilters]="searchFilters"
      ></app-search-filter>
    </div>
  </div>
  <div class="row">
    <div class="col-lg-9">
      <div *ngFor="let grp of sponsorshipGroups; let i = index">
        <div class="row" *ngIf="i % 2 === 0">
          <ng-container
            [ngTemplateOutlet]="groupTemplate"
            [ngTemplateOutletContext]="{ i: i, grp: grp }"
          >
          </ng-container>
          <ng-container
            *ngIf="i + 1 < sponsorshipGroups.length"
            [ngTemplateOutlet]="groupTemplate"
            [ngTemplateOutletContext]="{
              i: i + 1,
              grp: sponsorshipGroups[i + 1]
            }"
          >
          </ng-container>
        </div>
      </div>
      <app-add-more-data-to-list
        (onScroll)="getSponsorshipGroups(true)"
        [lock]="lock"
        [isLoading]="isLoading"
      >
      </app-add-more-data-to-list>
    </div>
    <div class="col-lg-3 align-text-center" *ngIf="campaigns">
      <div class="row">
        <h2 class="col-md-10"><span class="light-blue">Campaigns:</span></h2>
      </div>
      <span *ngIf="campaigns.length === 0">No campaigns</span>
      <div
        *ngIf="campaigns.length > 0"
        class="row row border-card"
        [ngClass]="selectedGroup.color"
      ></div>
      <div
        class="border-card"
        *ngFor="let camp of campaigns; let i = index"
        [ngClass]="{ 'mt-3': i !== 0 }"
      >
        <a routerLink="/programs" [queryParams]="{ campaign: camp.name }">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-4">
                <span class="float-left">Name:</span>
              </div>
              <div class="col-md-8">
                <span class="float-right text-right word-break-break-all">{{
                  camp.name
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <span class="float-left">Code:</span>
              </div>
              <div class="col-md-8">
                <span class="float-right text-right word-break-break-all">{{
                  camp.code
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <span class="float-left">Status:</span>
              </div>
              <div class="col-md-8">
                <span class="float-right text-right">{{ camp.status }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <span class="float-left">Cap:</span>
              </div>
              <div class="col-md-8">
                <span class="float-right text-right">{{ camp.cap }}</span>
              </div>
            </div>
            <div class="row" *ngIf="camp.sponsorCap">
              <div class="col-md-4">
                <span class="float-left">Sponsor Cap:</span>
              </div>
              <div class="col-md-8">
                <span class="float-right text-right">{{
                  camp.sponsorCap
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <span class="float-left">From:</span>
              </div>
              <div class="col-md-8">
                <span class="float-right text-right">{{
                  camp.fromDate | date: "yyyy-MM-dd"
                }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <span class="float-left">Thru:</span>
              </div>
              <div class="col-md-8">
                <span class="float-right text-right">{{
                  camp.thruDate | date: "yyyy-MM-dd"
                }}</span>
              </div>
            </div>
          </div>
        </a>
      </div>
      <div
        *ngIf="campaigns.length > 0"
        class="row row border-card"
        [ngClass]="selectedGroup.color"
      ></div>
    </div>
  </div>
</div>
<ng-template #groupTemplate let-i="i" let-grp="grp">
  <div
    class="col-md-6 program-card"
    (click)="
      getSponsorshipGroupCampaigns(personId, sponsorshipGroups[i], $event)
    "
  >
    <div class="row" [ngClass]="sponsorshipGroups[i].color"></div>
    <div class="row gray-bg program-card-header">
      <span
        *ngIf="selectedGroup === sponsorshipGroups[i]"
        class="program-card-selector"
        [ngClass]="selectedGroup.color"
        >SELECTED</span
      >
      <div class="col-md-2 relative-position">
        <div class="absolute-position top-20">
          <span class="fa-stack">
            <fa-layers [fixedWidth]="true">
              <fa-icon
                class="blue-icon-color"
                [icon]="faCircle"
                stackItemSize="2x"
              ></fa-icon>
              <fa-icon
                [inverse]="true"
                [icon]="faObjectGroup"
                transform="shrink-8"
                stackItemSize="2x"
              ></fa-icon>
            </fa-layers>
          </span>
        </div>
      </div>
      <div class="col-md-10">
        <div
          class="text-uppercase program-card-title"
          [attr.title]="sponsorshipGroups[i].sponsorshipTag"
        >
          <strong>{{ sponsorshipGroups[i].sponsorshipTag }}</strong>
        </div>
      </div>
    </div>
    <div class="row program-card-body">
      <div class="col-md-2"></div>
      <div
        class="col-md-10 row"
        *ngIf="grp.packages && grp.packages.length > 0"
      >
        <label>Packages</label>
      </div>
      <div class="col-md-2"></div>
      <div class="col-md-10 row">
        <ul
          *ngFor="let package of sponsorshipGroups[i].packages"
          class="list-group clear-list mb-0"
        >
          <li style="list-style-type: none">
            {{ package.packageName }}
            <span [hidden]="!(package.startDate || package.endDate)"
              >: {{ package.startDate }} - {{ package.endDate }}</span
            >
          </li>
        </ul>
      </div>
    </div>
    <div class="row gray-bg program-card-footer">
      <div class="col-md-2"></div>
      <div class="col-md-4"></div>
      <div class="col-md-6 justify-content-right">
        <button
          class="btn btn-dark-gray btn-md manage action float-right mr-1"
          title="Manage"
          color="primary"
          cdkFocusInitial
          (click)="navigateToGroup(sponsorshipGroups[i].sponsorshipTag)"
        >
          <fa-icon [icon]="faCog"></fa-icon>
        </button>
      </div>
    </div>
    <div class="row" [ngClass]="sponsorshipGroups[i].color"></div>
  </div>
</ng-template>
