import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root'
})
export class TemporalUnitsService {

  constructor(private http: HttpClient,private globalData: AppDataService) { }

  getTemporalUnits(): any {
    return this.http.get(this.globalData.baseGoatUrl+'temporal_units');
  }
}