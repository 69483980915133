import { Component, OnInit } from '@angular/core';
import { AppDataService } from '../app-data.service';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { GlobalEventService } from '../services/global-event.service';

@Component({
  selector: 'app-bread-crumbs',
  templateUrl: './bread-crumbs.component.html',
  styleUrls: ['./bread-crumbs.component.scss']
})
export class BreadCrumbsComponent implements OnInit {

  isCSRManager: boolean;
  breadcrumbs: any[] = [];
  personName: string;

  constructor(
    private globalData: AppDataService,
    private router: Router,
    private globalEventService: GlobalEventService
  ) {
    this.globalEventService.getGlobalEvent().subscribe((response: any) => {
      if (response.toComponent === 'bread-crumbs') {
        this.breadcrumbs = [];
        if (response.data.profileLookup) {
          this.addBreadcrumbs('profile');
        } else if (response.data.personName) {
          this.addBreadcrumbs('/system');
          this.addBreadcrumbs('/users');
          this.breadcrumbs.push({ text: response.data.personName, url: 'users/' + response.data.id });
        } else if (response.data.integrationName) {
          this.addBreadcrumbs('/integrations');
          this.breadcrumbs.push({ text: response.data.integrationName, url: 'integrations/' + response.data.id + '/loads' });
        } else if (response.data.username) {
          this.addBreadcrumbs('/members');
          this.breadcrumbs.push({ text: response.data.username, url: 'members/' + response.data.id });
        } else if (response.data.promotionId) {
          this.breadcrumbs.push({ text: 'Programs', url: 'programs/' });
          this.breadcrumbs.push({ text: 'Promotions ' + response.data.promotionId, url: 'programs/' + response.data.programId + '/promotions ' });
        } else if (response.data.promotionId === '') {
          this.breadcrumbs.push({ text: 'Programs', url: 'programs/' });
          this.breadcrumbs.push({ text: 'Promotions ', url: 'programs/' + response.data.programId + '/promotions ' });
        }
      }
    });
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.createBreadCrumbs(event.url);
    });
  }

  ngOnInit(): void {
    this.isCSRManager = this.globalData.checkPermission('CSR_MANAGER');
    this.createBreadCrumbs(this.router.url);
  }

  createBreadCrumbs(url: string): void {
    this.breadcrumbs = [];
    if (['/segments', '/users', '/partners'].includes(url)) {
      this.addBreadcrumbs('/system');
      this.addBreadcrumbs(url);
    }
    else {
      const urlPaths = url.split('/');
      if (urlPaths.length === 3) {
        if (['reports', 'communications', 'sponsors'].includes(urlPaths[1])) {
          this.addBreadcrumbs('/' + urlPaths[1]);
          this.breadcrumbs.push({
            text: urlPaths[1].substring(0, 1).toLocaleUpperCase() + urlPaths[1].substring(1, urlPaths[1].length - 1) + ' ' + urlPaths[2]
            , url: urlPaths[1] + '/' + urlPaths[2]
          });
        } else if (url.includes('processing-log')) {
          this.breadcrumbs =
            [{ text: 'Processing Logs', url: '/processing-log' }, { text: 'Processing Log ' + urlPaths[2], url }];
        }
        else if (url.includes('members')) {
          this.breadcrumbs = [{ text: 'Members', url: '/members' }, { text: 'party' + urlPaths[2], url }];
        }
      } else if (urlPaths.length === 4) {
        if (urlPaths[1] === 'programs' && this.removeQueryParams(urlPaths[3]) === 'promotions') {
          this.addBreadcrumbs('/programs');
          this.breadcrumbs.push({ text: 'Promotions', url });
        } else if (urlPaths[1] === 'reports' && urlPaths[2] === 'orb') {
          this.addBreadcrumbs('/reports');
          this.breadcrumbs.push({ url: '/reports/orb/' + urlPaths[3], text: 'Orb - Report ' + urlPaths[3] });
        } else if (urlPaths[1] === 'outbounds' && urlPaths[3] === 'loads') {
          this.addBreadcrumbs('/outbounds');
          this.breadcrumbs.push({ text: 'Outbound ' + urlPaths[2], url: 'outbounds/' + urlPaths[2] + '/loads' });
        } else if (urlPaths[1] === 'integrations' && this.removeQueryParams(urlPaths[3]) === 'manifest') {
          this.addBreadcrumbs('/integrations');
          this.breadcrumbs.push({ text: 'Activity', url: '/integrations/' + urlPaths[2] + '/loads' });
          this.breadcrumbs.push({ text: 'Manifest', url });
        }
      }
      else if (urlPaths.length === 5) {
        if (urlPaths[1] === 'sponsors' && urlPaths[3] === 'packaged-sponsorship') {
          this.addBreadcrumbs('/sponsors');
          this.breadcrumbs.push({ text: 'Sponsor ' + urlPaths[2], url: 'sponsors/' + urlPaths[2] });
          this.breadcrumbs.push({ text: 'Sponsorship Group ' + this.removeQueryParams(urlPaths[4]), url });
        } else if (urlPaths[1] === 'integrations' && urlPaths[3] === 'loads') {
          this.addBreadcrumbs('/integrations');
          this.breadcrumbs.push({ text: 'Loads ' + urlPaths[4], url });
        }
      }
      else if (urlPaths.length === 6) {
        if (urlPaths[1] === 'sponsors' && urlPaths[5] === 'configuration') {
          this.breadcrumbs.push({ text: 'Configuration', url });
        }else if (urlPaths[1] === 'programs' && urlPaths[3] === 'promotions' && urlPaths[5] === 'promotion-template') {
          this.addBreadcrumbs('/programs');
          this.breadcrumbs.push({ text: 'Promotions ' + urlPaths[2], url: 'programs/' + urlPaths[2] + '/promotions' });
          this.breadcrumbs.push({ text: 'Promotion Template ' + urlPaths[4], url });
        }
      }
      else if (urlPaths.length === 7) {
        if (urlPaths[1] === 'programs' && urlPaths[3] === 'promotions' && (urlPaths[5] === 'promotion-template' || urlPaths[5] === 'promotion-display-template')) {
          this.addBreadcrumbs('/programs');
          this.breadcrumbs.push({ text: 'Promotions ' + urlPaths[2], url: 'programs/' + urlPaths[2] + '/promotions' });
          this.breadcrumbs.push({ text: 'Promotion Template ' + urlPaths[4], url });
        }
      }
      else if (url.includes('profile')) {
        this.breadcrumbs.push({ text: 'Profile', url });
      }
      else if (!url.includes('login')) {
        let urlText: string = url.substring(1);
        if (urlText.includes('-')) {
          urlText = urlText.split('-')
            .map((urlPath: string) => urlPath.substring(0, 1).toLocaleUpperCase() + urlPath.substring(1)).join(' ');
        } else if (urlText === 'eventSources') {
          urlText = 'Event Sources';
        } else {
          urlText = urlText.substring(0, 1).toLocaleUpperCase() + urlText.substring(1);
        }
        this.breadcrumbs = [{ url, text: urlText }];
      }
    }
  }

  createLabelFromText(text: string): string {
    return text.substring(1, 2).toLocaleUpperCase() + text.substring(2);
  }

  addBreadcrumbs(url: string): void {
    this.breadcrumbs.push({ url, text: this.createLabelFromText(url) });
  }

  removeQueryParams(urlPath: string): string {
    return urlPath.includes('?') ? urlPath.substring(0, urlPath.indexOf('?')) : urlPath;
  }

}
