import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigurationService } from '../services/configuration.service';
import { ForgotPasswordService } from '../services/forgot-password.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  email : string;
  loginBanner = '';

  constructor(private configurationService: ConfigurationService, 
             private router: Router, 
             private toastr: ToastrService, 
             private forgotPasswordServoce : ForgotPasswordService) { }

  ngOnInit(): void {
    
  }

  submit(){
    if (!this.email || this.email.length < 1) {
      this.toastr.info("Input field is empty");
      return;
    }

    let regexp = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
    if(regexp.test(this.email)){
      var splitted = window.location.href.split('/');
      var url = splitted[0] + '/' + splitted[1] + '/' + splitted[2] + '/';
      const data = {
        email:this.email,
        username: url
      }
      this.forgotPasswordServoce.forgotPassword(data).subscribe(res =>
        this.toastr.info("Check your email")
      );
    }else{
      this.toastr.error("Invalid email format")
    }
  }

  login(){
    this.router.navigate(['login']);
  }

}
