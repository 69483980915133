import {Component, OnInit, Input, OnChanges, SimpleChanges} from '@angular/core';
import {Filter, Choice, SearchFilters} from '../../../data/class';
import {TableHelperService} from '../../../services/helpers/table-helper.service';
import {AppDataService} from '../../../app-data.service';
import { Campaign, Person } from '../../../data/model';
import {MembersService} from '../../../services/members.service';
import {SecurityService} from '../../../services/security/security.service';
import {InstrumentsService} from "../../../services/instruments.service";
import {PromotionService} from "../../../services/promotion.service";
import { StandardPromotionsDetail, AnthemMemberPromotionsDetail } from "../../../../ajs/memberPromotionsTab";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-member-promotions-tab',
  templateUrl: './member-promotions-tab.component.html',
  styleUrls: ['./member-promotions-tab.component.scss']
})
export class MemberPromotionsTabComponent implements OnInit, OnChanges {

  @Input() personId: number;
  @Input() person: Person;

  promotionKeyword = '';
  filteredStatuses: any[] = [];
  select: any = {};
  selectedCampaigns: any[] = [];
  filter = new Filter();
  showHidden: any = {};
  showAllCampaigns: any = {};
  promoGroups: any[] = [];
  statuses: Choice[] = [];
  anthemPromoView: boolean;
  campaigns: Campaign[] = [];
  promoList: any[] = [];
  categoryOrders: any[] = [];
  capGroups: any[] = [];
  oldMemberDetailCode: any;
  searchFilters: SearchFilters;
  wteWrapper: {};
  activityDetails: {
    id: number,
    startDate: Date,
    endDate: Date,
    selectedPersonId: number,
    bCodes: {}[],
    regex: string,
    hideLink: boolean
  };
  private hideButton: boolean;
  camp: any;
  primaryInstrumentCode: any;
  promotionPodType: string;
  gettingDetails: boolean = false;

  constructor(
    private tableHelperService: TableHelperService,
    private globalData: AppDataService,
    private membersService: MembersService,
    private securityService: SecurityService,
    private promotionService: PromotionService,
    private instrumentService: InstrumentsService,
    private http: HttpClient
  ) {
    this.http = http;
  }


  isCSRManager = this.globalData.isCSRManager;
  isPartner = this.globalData.isPartner;
  isProgramManager = this.globalData.isProgramManager;

  ngOnInit(): void {

    this.wteWrapper = {};
    this.wteWrapper["getEligibleCampaigns"] = (a, b) => { return this.getEligibleCampaigns(a, b); };
    this.wteWrapper["getPromotions"] = (a, b) => { return this.getPromotions(a, b); };
    this.wteWrapper["getAccountsSummary"] = (a) => { return this.getAccountsSummary(a); };
    this.wteWrapper["getPrimaryInstrumentForParty"] = (a) => { return this.getPrimaryInstrumentForParty(a); };
    this.wteWrapper["getBehaviorCodes"] = (a) => { return this.getBehaviorCodes(a); };
    this.wteWrapper["placeHolder"] = (id: number) => {};

    this.wteWrapper["security"] = {};
    this.wteWrapper["security"]["isCSRManager"] = this.globalData.isCSRManager;
    this.wteWrapper["security"]["isPartner"] = this.globalData.isPartner;
    this.wteWrapper["security"]["isProgramManager"] = this.globalData.isProgramManager;

    this.setStatuses();

    this.searchFilters = {
      formName: "generic",
      buttonsWithText: true,
      waitUntilApply: true, // turned on as a hacky solution since we have a bit of a circular dependency here.
      filters: [
        {
          name: "effectiveDate",
          type: 'date',
          placeholder: "Effective Date",
        },
        {
          name: 'showHidden',
          type: 'boolean',
          label: "Show Hidden Promotions",
        }
      ],
      // searchCallback: this.applyFilter
    };
    this.getOldMemberDetails();
  }

  private getOldMemberDetails() {
    if(this.gettingDetails === false) {
      this.gettingDetails = true;
      this.globalData.promotionPodType.subscribe((result) => {
        if (result === 'anthem-commercial') {
          this.oldMemberDetailCode = new AnthemMemberPromotionsDetail(this.wteWrapper, this.personId, this.person, true, null, this.searchFilters, this.http, this.globalData.baseUrl);
        } else {
          this.oldMemberDetailCode = new StandardPromotionsDetail(this.wteWrapper, this.personId, this.person, true, null, this.searchFilters, this.http, this.globalData.baseUrl);
        }
        this.oldMemberDetailCode.searchFilters = this.searchFilters;
        this.searchFilters.searchCallback = () => {
          this.oldMemberDetailCode.initNewSearch();
        };
        this.promotionPodType = result;
        this.gettingDetails = false;
      });
    }
  };

  ngOnChanges(changes: SimpleChanges) {
    if (changes.personId?.currentValue) {
      this.getPrimaryInstrumentForParty(changes.personId.currentValue);
      this.getOldMemberDetails();
    }
  }

  getNotification(evt) {
    this.searchFilters.applyFilters();
  }

  getPromotions(partyId: number, params: any): void {
    return this.membersService.getPromotions(partyId, params);
  }

  getBehaviorCodes(id: number): void {
    this.promotionService.getBehaviorCodes(id).subscribe((resp: any) => {
      if (resp.success) {
        this.activityDetails.bCodes = resp.entity;
        // this.activityDetails.regex = $filter('filter')(this.activityDetails.bCodes, {behaviorCode: 'regex'})[0].behaviorName;
        this.hideButton = false;
        if (this.activityDetails.regex.indexOf('.*') > 0) {
          this.activityDetails.bCodes = [{
            behaviorCode: 'Multiple Behavior Codes',
            behaviorName: 'Multiple Behavior Codes'
          }];
          this.hideButton = true;
        }
        if (this.activityDetails.regex.indexOf('DOES_NOT_SCORE') > 0) {
          this.activityDetails.bCodes = [{
            behaviorCode: 'Non scoring promotion',
            behaviorName: 'Non scoring promotion'
          }];
          this.activityDetails.hideLink = true;
        } else if (this.activityDetails.regex === '()') {
          this.activityDetails.bCodes = [{
            behaviorCode: 'No Behavior Code Configuration',
            behaviorName: 'No Behavior Code Configuration'
          }];
          this.activityDetails.hideLink = true;
        } else {
          // this.activityDetails.bCodes = $filter('filter')(this.activityDetails.bCodes, {behaviorCode: '!regex'});
        }
        // modalHelper.openModal($uibModal, this, tpl, detailsCtrl, this.activityDetails);
      }
    });
  }

  getPrimaryInstrumentForParty(partyId: number): void {
    return this.instrumentService.getPrimaryInstrumentForParty(partyId);
  }

  getAccountsSummary(partyId: number): void {
    return this.membersService.getAccountsSummary(partyId);
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  getEligibleCampaigns(partyId: number, params: any): void {
    if (params.effectiveDate === null) {
      params = null;
    }
    let res = this.membersService.getEligibleCampaigns(partyId, params);
    return res;
  }

  toggleFullDetails(promo: any): void {
    promo.hideDetails = !promo.hideDetails;
  }
  //
  // applyFilter(): void {
  //   this.filter = this.searchFilters.getFilters(this.filter);
  //   this.oldMemberDetailCode?.applyFilter();
  // }

}
