import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faPencilAlt, faPlus, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppDataService } from 'src/app/app-data.service';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Choice, Filter, SearchFilters } from 'src/app/data/class';
import { InstrumentType } from 'src/app/data/model';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { IntegrationKeyService } from 'src/app/services/integration-key.service';
import { IntegrationKeyModalComponent } from '../integration-key-modal/integration-key-modal.component';
import { ToastrService } from 'ngx-toastr';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-integration-keys-tab',
  templateUrl: './integration-keys-tab.component.html',
  styleUrls: ['./integration-keys-tab.component.scss']
})
export class IntegrationKeysTabComponent implements OnInit {

  limit = 20;
  isLoading: boolean;
  filter = new Filter();
  statuses: Choice[] = [];
  lock: boolean;
  lengthToCompare: number;
  faPlus = faPlus;
  show: boolean;
  faTrash = faTrash;
  faPencilAlt = faPencilAlt;
  integrationKeys: any[] = [];
  promotions: any[] = [];
  selectedIntegrationKey: any;
  lockedTables = false;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  isAdmin: boolean;
  filteredStatuses: Choice[] = [];
  disabled: boolean;
  faTimes = faTimes;
  searchFilters: SearchFilters;

  constructor(private tableHelperService: TableHelperService,
              private responseHelper: ResponseHelperService,
              private integrationKeyService: IntegrationKeyService,
              private modalHelper: NgbModal,
              private appDataService: AppDataService,
              private title: Title,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'integration-keys-search',
      searchCallback: () => { this.initNewSearch(); },
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...'
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        }
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'New Instrument Type',
          if: true,
          callback: () => { this.integrationKeyModal(); },
          title: 'New Instrument Type'
        },
      ],

    };
    this.isAdmin = this.appDataService.isAdmin;
    this.title.setTitle('System Configurations');
    this.show = true;
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  initNewSearch(): void {
    this.initFilter();
    this.getIntegrationKeys(false);
  }

  getIntegrationKeys(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.integrationKeyService.getIntegrationKeys(this.filter).subscribe((data: any) => {
      if (concat) {
        this.integrationKeys = this.integrationKeys.concat(parseResponse(data));
      } else {
        this.integrationKeys = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
    }, err => {
      this.lockedTables = false;
      this.isLoading = false;
      this.lock = false;
      this.toastr.error('Error occured!');
    });
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  deleteIntegrationKey(id: number): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Delete integration key with ID: ' + id;
    instance.componentInstance.header = 'Delete integration key?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.integrationKeyService.deleteIntegrationKey(id).subscribe(() => {
        this.responseHelper.success('Integratio key successfully removed', true);
        this.integrationKeyService.getIntegrationKey(id).subscribe((data: any) => {
          if (data.success) {
            this.integrationKeys[this.integrationKeys.findIndex(e => e.id === id)] = parseResponse(data);
          }
        });
      });
    });
  }

  integrationKeyModal(integrationKeyId?: number): void {
    if (integrationKeyId) {
      this.integrationKeyService.getIntegrationKey(integrationKeyId).subscribe((data: any) => {
        if (data.success) {
          this.openModal(data.entity);
        }
      });
    } else {
      this.openModal();
    }
  }

  openModal(integrationKey?: InstrumentType): void {
    const instance = this.modalHelper.open(IntegrationKeyModalComponent);
    instance.componentInstance.integrationKey = integrationKey;
    instance.componentInstance.successEvent.subscribe(data => {
      if (data.success) {
        this.responseHelper.success(`Integration key successfully ${instance.componentInstance.isEdit ? 'updated' : 'created'}`, true);
        this.initNewSearch();
      }
    });
  }

  setStatuses(): void {
    this.initFilter();
    this.filter.statuses = this.tableHelperService.extractData(this.filteredStatuses);
    if (!this.filter.statuses) {
      delete this.filter.statuses;
    }
    this.getIntegrationKeys(false);
  }

}
