import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-report-params-modal',
  templateUrl: './report-params-modal.component.html',
  styleUrls: ['./report-params-modal.component.scss']
})
export class ReportParamsModalComponent implements OnInit {

  @Input() execution: any;
  @Input() reportParams: any[];

  constructor(private modalHelper: NgbModal) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

}
