import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { faCheckCircle, faChevronDown, faChevronRight, faPlus, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { SearchFilters, Filter, Choice } from 'src/app/data/class';
import { ActivityService } from 'src/app/services/activity.service';
import { MemberEventsService } from 'src/app/services/member-events.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { ProcessingService } from 'src/app/services/processing.service';
import { AppDataService } from 'src/app/app-data.service';
import { DatePipe } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { ToastrService } from 'ngx-toastr';
import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-member-events-tab',
  templateUrl: './member-events-tab.component.html',
  styleUrls: ['./member-events-tab.component.scss'],
  providers: [DatePipe]
})
export class EventsTabComponent implements OnInit {

  @Input() personId: number;
  checkAll: boolean;
  lockedTables: boolean;
  lock: boolean;
  searchFilters: SearchFilters;
  faPlus = faPlus;
  lengthToCompare: number;
  selectedEvents: any = [];
  selectedEventsCount = 0;
  isSelected: boolean[] = [];
  events: any = [];
  statuses: Choice[];
  filter = new Filter();
  faCheckCircle = faCheckCircle;
  limit = 20;
  filteredStatuses: any[] = [];
  isLoading: boolean;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  faSpinner = faSpinner;
  activityFilter: any;
  faChevronRight = faChevronRight;
  expandedLookup = [];
  faChevronDown = faChevronDown;
  activities: any[];
  isCSRMANAGER = this.appDataService.isCSRManager;
  needRange = false;
  rangeFrom: NgbDate;
  rangeTo: NgbDate;

  constructor(
    public appDataService: AppDataService,
    private tableHelperService: TableHelperService,
    private eventsService: MemberEventsService,
    private statusHelperService: StatusHelperService,
    private responseHelperService: ResponseHelperService,
    private activityService: ActivityService,
    private datePipe: DatePipe,
    private dateHelperService: DateHelperService,
    private titleHelper: Title,
    private toastr: ToastrService
  ) { }

  today = new Date();
  startDate
  endDate
  statusHelper = this.statusHelperService.getStatus('badge');


  ngOnInit(): void {
    if (this.personId === null || this.personId === undefined) {
      this.titleHelper.setTitle('Events');
      this.needRange = true;
      this.startDate = new Date().setDate(this.today.getDate() - 1);
      this.endDate = new Date().setDate(this.today.getDate() + 1);
    }
    this.searchFilters = {
      formName: 'eventTabSearch',
      buttonsWithText: true,
      filters: [
        {
          name: 'tag',
          placeholder: 'Tag...',
        },
        {
          name: 'source',
          placeholder: 'Source...',
        },
        {
          name: 'measure',
          placeholder: 'Measure...',
        },
        {
          name: 'fromDate',
          type: 'date',
          placeholder: 'From:',
          value: this.datePipe.transform(this.startDate, 'yyyy-MM-dd')
        },
        {
          name: 'toDate',
          type: 'date',
          placeholder: 'To:',
          value: this.datePipe.transform(this.endDate, 'yyyy-MM-dd')
        }
      ],
      searchCallback: () => { this.initNewSearch(); },
      addEntityButtons: [
        {
          icon: faCheckCircle,
          buttonText: 'Re-submit',
          title: 'Re-submit for processing',
          callback: () => { this.triggerProcessing(); },
          if: this.isCSRMANAGER
        }
      ]
    };
    this.initFilter();
    this.getStatuses();
  }


  getStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('EVENTS');
  }

  setStatuses(): void {
    this.initFilter();
    this.filter.status = this.tableHelperService.extractData(this.filteredStatuses);
    this.getEvents(false);
  }

  initFilter(): void {
    if (this.personId !== undefined) {
      this.filter.partyId = this.personId;
    }
    this.filter.limit = this.limit;
    this.filter.start = 0;
    this.filter.dir = 'desc';
  }

  clearLookup(): void {
    this.expandedLookup = [];
  }

  getRangeDay(): number {
    var Difference_In_Days = 99;
    if (this.filter.fromDate && this.filter.toDate) {
      const dateFrom = new Date(this.filter.fromDate);
      const dateTo = new Date(this.filter.toDate);
      var Difference_In_Time = dateTo.getTime() - dateFrom.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    }
    return Math.floor(Difference_In_Days);
  }

  getEvents(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    if (this.filter.fromDate) {
      this.filter.fromDate = this.datePipe.transform(this.filter.fromDate, 'yyyy-MM-dd') + 'T00:00';
    }
    if (this.filter.toDate) {
      this.filter.toDate = this.datePipe.transform(this.filter.toDate, 'yyyy-MM-dd') + 'T23:59';
    }
    if (!this.needRange) {
      this.getAllEvents(concat);
    } else if (this.needRange && this.getRangeDay() < 31) {
      this.getAllEvents(concat);
    }
  }

  private getAllEvents(concat: boolean) {
    this.isLoading = true;
    this.clearLookup();
    this.eventsService.getEvents(this.filter).subscribe((data: any) => {
      if (concat) {
        this.events = this.events.concat(data.aaData);
        data.aaData.forEach(() => {
          this.isSelected.push(false);
        });
      } else {
        this.events = data.aaData;
        this.isSelected = [];
        this.events.forEach(() => {
          this.isSelected.push(false);
        });
      }
      this.lengthToCompare = data.aaData.length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.start += this.limit;
      this.lockedTables = data.aaData.length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
      if (data.aaData.length !== this.limit) {
        $('#select-all-cb').prop('checked', false);
      }
    }, () => {
      this.lockedTables = false;
      this.isLoading = false;
      this.lock = false;
      this.toastr.error('Error occured!');
    });
  }

  selectAllCb(): void {
    this.isSelected = this.isSelected.map(() => this.checkAll);
    if (this.checkAll === false) {
      this.selectedEvents = [];
    } else if (this.checkAll === true) {
      this.events.forEach(element => {
        this.selectedEvents.push(element.eventRef);
      });
    }
  }

  updateSelectedEvents(event: any, i: number): void {
    if (this.isSelected[i]) {
      this.selectedEvents.push(event.eventRef);
    } else if (!this.isSelected[i]) {
      this.selectedEvents = this.selectedEvents.filter(obj => obj !== event.eventRef);
    }
    this.selectedEventsCount = this.selectedEvents.length;
  }

  triggerProcessing(): void {
    this.eventsService.processList(this.selectedEvents).subscribe(() => {
      this.responseHelperService.success('Event successfully sent for processing');
    }, (data: any) => {
      this.responseHelperService.error(this, data.error.error, true);
    });
    this.checkAll = false;
    this.selectedEvents = [];
  }

  showInfo(event: any): void {
    if (event.activityCount > 0) {
      this.activityFilter = {};
      this.activityFilter.search = event.eventRef + '-' + this.datePipe.transform(event.eventTimestamp, 'yyyy-MM-dd');

      this.activityService.getActivitiesForMember(this.personId, this.activityFilter).then(data => {
        if (data.success) {
          this.activities = parseResponse(data);
        }
      });
    }
  }

  showDetails(index: number): void {
    if (this.expandedLookup[index]) {
      this.expandedLookup = [];
      this.activities = [];
      this.expandedLookup[index] = false;
    } else {
      this.expandedLookup = [];
      this.activities = [];
      this.expandedLookup[index] = true;
    }
  }

  applySearch(): void {
    this.getEvents(false);
  }

  clearAllFilters(): void {
    this.searchFilters.clearAll();
  }

  initNewSearch(): void {
    this.initFilter();
    this.getEvents(false);
  }

  applyFilter(): void {
    this.searchFilters.applyFilters();
  }

}
