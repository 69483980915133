import { SearchFilters } from './../data/class';
import { UserPreferencesService } from './../services/user-preferences.service';
import { PersonsService } from './../services/persons.service';
import { SponsorsService } from './../services/sponsors.service';
import { StatusHelperService } from './../services/helpers/status-helper.service';
import { TableHelperService } from './../services/helpers/table-helper.service';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faUserPlus, faCircle, faHandshake, faUser, faEnvelope, faGlobe, faCalendar, faEye } from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';
import { forkJoin } from 'rxjs';
import { SponsorModalComponent } from './../sponsor-modal/sponsor-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Organization } from '../data/model';
import { ToastrService } from 'ngx-toastr';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-sponsors',
  templateUrl: './sponsors.component.html',
  styleUrls: ['./sponsors.component.scss']
})
export class SponsorsComponent implements OnInit {

  sponsorKeyword = '';
  filteredStatuses = [];
  filter: any = {};
  limit: number;
  statuses: any;
  lock = false;
  lockedTables = false;
  selectedSponsor: Organization;
  sponsors = new Array<Organization>();
  lengthToCompare: number;
  searchFilters: SearchFilters;
  faUserPlus = faUserPlus;
  faCircle = faCircle;
  faHandshake = faHandshake;
  faUser = faUser;
  faEnvelope = faEnvelope;
  faGlobe = faGlobe;
  faCalendar = faCalendar;
  faEye = faEye;
  isLoading = false;

  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private title: Title,
    private tableHelperService: TableHelperService,
    private statusHelperService: StatusHelperService,
    private sponsorsService: SponsorsService,
    private personsService: PersonsService,
    private userPreferences: UserPreferencesService,
    private modalHelper: NgbModal,
    private toastr: ToastrService
  ) { }

  statusHelper = this.statusHelperService.getStatus('badge');
  statusChoices = this.tableHelperService.getStatuses('ENTITY');


  ngOnInit(): void {
    this.searchFilters = {
      formName: 'sponsor_search',
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.statusChoices,
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
          value: this.userPreferences.service.getPreference('sponsors.defaultFilterValues.status')
        }
      ],
      searchCallback: () => { this.initNewSearch(); },
    };

    this.title.setTitle('Sponsors');
    this.initFilter();
    this.setStatuses();
  }

  initFilter(): void {
    this.limit = 20;
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  newSponsor(): void {
    const instance = this.modalHelper.open(SponsorModalComponent);
    instance.componentInstance.successEvent.subscribe(() => {
      this.initNewSearch();
      instance.close();
    });
  }

  selectSponsor(id: number): void {
    this.sponsorsService.getSponsor(id).subscribe((data: any) => {
      let sponsor = new Organization();

      if (data.success) {
        sponsor = data.entity;

        forkJoin([
          // this.PersonsService.getAttributes(id),
          this.personsService.getPersonSegments(id, false),
          this.personsService.getPrimaryPartyEmail(id)
        ]).subscribe((response) => {
          const props = [
            // 'attrs',
            'segments',
            'emails'
          ];
          props.forEach((attr, i) => {
            const res: any = response[i];
            if (res && res.success) {
              sponsor[attr] = res.entity;
            }
          });
          this.selectedSponsor = sponsor;
        });
      }
    });
  }

  hasAttributes(): boolean {
    return this.selectedSponsor.attrs && this.selectedSponsor.attrs.length > 0;
  }

  hasSegments(): boolean {
    return this.selectedSponsor.segments && this.selectedSponsor.segments.length > 0;
  }

  hasEmail(): boolean {
    return this.selectedSponsor.emails;
  }

  hasYearStart(): boolean {
    let returnValue = false;
    if (this.selectedSponsor.yearStart) {
      returnValue = true;
    }
    return returnValue;
  }

  getSponsors(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.isLoading = true;
    this.sponsorsService.getAllSponsors(this.filter).subscribe((data: any) => {
      if (concat) {
        this.sponsors = this.sponsors.concat(parseResponse(data));
      } else {
        this.selectedSponsor = undefined;
        this.sponsors = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
      this.lockedTables = false;
      this.lock = false;
      this.toastr.error('Error occured!');
    });
  }

  initNewSearch(): void {
    this.initFilter();
    this.getSponsors(false);
  }

  showSponsor(e: any, sponsor: any): void {
    $('tr.active').removeClass('active');
    $(e.currentTarget).toggleClass('active');
    this.selectedSponsor = sponsor;
    this.selectSponsor(sponsor.id);
  }

  showPerson(): boolean {
    if (this.selectedSponsor !== undefined) {
      return true;
    }
    return false;
  }
}
