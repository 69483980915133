import { Observable } from 'rxjs';
import { UserPreferencesService } from './../../services/user-preferences.service';
import { SecurityService } from 'src/app/services/security/security.service';
import { UploadIntegrationModalComponent } from './../upload-integration-modal/upload-integration-modal.component';
import { IntegrationModalComponent } from './../integration-modal/integration-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseHelperService } from './../../services/helpers/response-helper.service';
import { HttpClient } from '@angular/common/http';
import { Integration } from './../../data/model';
import { IntegrationsService } from './../../services/integrations.service';
import { TableHelperService } from './../../services/helpers/table-helper.service';
import { Filter, SearchFilters } from './../../data/class';
import { AppDataService } from './../../app-data.service';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { faPlus, faSearchPlus, faSpinner, faUpload, faCogs, faPencilAlt, faExchangeAlt, faEnvelope, faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import * as $ from 'jquery';
import { ToastrService } from 'ngx-toastr';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-integrations',
  templateUrl: './integrations.component.html',
  styleUrls: ['./integrations.component.scss']
})
export class IntegrationsComponent implements OnInit {

  customers: any[];
  filter = new Filter();
  limit = 20;
  isLoading = false;
  types: any = [{
    value: 'Eligibility',
    state: false
  },
  {
    value: 'Transaction',
    state: false
  },
  {
    value: 'Organization',
    state: false
  }];
  integrations: Integration[];
  lengthToCompare: number;
  selectedIntegration: Integration;
  searchFilters: SearchFilters;
  lockedTables = false;
  lock = false;
  faPlus = faPlus;
  faSearchPlus = faSearchPlus;
  faSpinner = faSpinner;
  faUpload = faUpload;
  faCogs = faCogs;
  faPencilAlt = faPencilAlt;
  faExchangeAlt = faExchangeAlt;
  faEnvelope = faEnvelope;
  faFolderOpen = faFolderOpen;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private titleHelper: Title,
    private globalData: AppDataService,
    private tableHelperService: TableHelperService,
    private integrationsService: IntegrationsService,
    private http: HttpClient,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal,
    private securityService: SecurityService,
    private userPreferencesService: UserPreferencesService,
    private statusHelperService: StatusHelperService,
    private toastr: ToastrService
  ) { }

  isProgramManager = this.globalData.isProgramManager;
  isAdmin = this.globalData.isAdmin;
  isAccountManager = this.globalData.isAccountManager;
  statuses = this.tableHelperService.getStatuses('ENTITY');
  statusHelper = this.statusHelperService.getStatus('badge');
  numColumns = this.isAdmin ? 8 : 7;

  getAllCustomers = new Observable((observer: any) => {
    const resultArray: any[] = [];
    this.integrationsService.getAllCustomers().subscribe((data) => {
      if (data.success) {
        parseResponse(data).forEach((name: string, idx: number) => {
          const customer: any = {};
          customer.name = name;
          customer.id = idx;
          resultArray.push(customer);
        });
      }
      observer.next(resultArray);
    });
  });

  ngOnInit(): void {
    this.titleHelper.setTitle('Integrations');

    this.getAllCustomers.subscribe((data: any) => {
      this.customers = data;
      this.searchFilters = {
        formName: 'integrations_search',
        filters: [
          {
            name: 'search',
            placeholder: 'Keyword...',
          },
          {
            name: 'statuses',
            placeholder: 'Status',
            type: 'array',
            choices: this.statuses,
            nameProp: 'value',
            valueProp: 'value',
            inputType: 'dropdown',
            value: this.userPreferencesService.service.getPreference('integrations.defaultFilterValues.status')
          }, {
            name: 'type',
            placeholder: 'Type',
            type: 'array',
            choices: this.types,
            nameProp: 'value',
            valueProp: 'value',
            inputType: 'dropdown',
          },
          {
            name: 'customers',
            type: 'array',
            placeholder: 'Customers...',
            nameProp: 'name',
            valueProp: 'name',
            inputType: 'select',
            multiple: true,
            choices: this.customers
          }
        ],
        searchCallback: () => { this.initNewSearch(true); },
      };
    });

    this.tableHelperService.showTitle();
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getIntegrations(concat: boolean, firstCall: boolean = false): void {
    if(!firstCall) this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);

    this.integrationsService.getIntegrations(this.filter).subscribe((data: any) => {
      if (concat) {
        this.integrations = this.integrations.concat(parseResponse(data));
      } else {
        this.integrations = parseResponse(data);
        this.selectedIntegration = null;
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
    }, () => {
      this.lockedTables = false;
      this.lock = false;
      this.isLoading = false;
      this.toastr.error('Error occured!');
    });
  }

  initNewSearch(firstCall:boolean = false): void {
    this.initFilter();
    this.getIntegrations(false, firstCall);
  }

  uploadTransformer(element): void {
    const fd = new FormData();
    fd.append('file', element.files[0]);
    const id = element.attributes.getNamedItem('id-el').value;
    this.http.post<any>(this.globalData.baseUrl + 'integrations/' + id + '/transformer', fd, {
      withCredentials: true,
      // transformRequest: angular.identity
    }).subscribe(() => {
      this.responseHelperService.success('Successfully uploaded file', false);
    }, (data) => {
      this.responseHelperService.error(this, data.error.error);
    });
  }

  showIntegration(e, integ): void {
    $('tr.active').removeClass('active');
    $(e.currentTarget).toggleClass('active');
    this.selectedIntegration = integ;
  }

  isShownIntegration(): boolean {
    return !!this.selectedIntegration;
  }

  integrationModal(id?: number): void {
    if (id) {
      this.integrationsService.getIntegration(id, null).subscribe((integration) => {
        if (integration.success) {
          const instance = this.modalHelper.open(IntegrationModalComponent);
          instance.componentInstance.integration = integration.entity;
          instance.componentInstance.customers = this.customers;
          instance.componentInstance.filterChoices = this.searchFilters.getFilterRef('customers').choices;
          instance.componentInstance.isAdmin = this.isAdmin;
          instance.componentInstance.successEvent.subscribe(() => {
            this.initNewSearch();
            instance.close();
          });
        }
      });
    } else {
      const instance = this.modalHelper.open(IntegrationModalComponent);
      instance.componentInstance.integration = null;
      instance.componentInstance.customers = this.customers;
      instance.componentInstance.filterChoices = this.searchFilters.getFilterRef('customers').choices;
      instance.componentInstance.successEvent.subscribe(() => {
        this.initNewSearch();
        instance.close();
      });
    }
  }

  // TODO:
  uploadModal(id: number, e): void {
    if (id) {
      this.integrationsService.getIntegration(id, null).subscribe((integration) => {
        if (integration.success) {
          const instance = this.modalHelper.open(UploadIntegrationModalComponent);
          instance.componentInstance.integration = integration.entity;
          // var instance = modalHelper.openModal($uibModal, 'uploadIntegrationModal', integration.entity);
          // instance.result.then(function () {
          //   initNewSearch();
          // }).catch(function (res) {
          //   if (!(res === 'cancel' || res === 'escape key press')) {
          //     throw res;
          //   }
          // });
        }
      });
    } else {
      // var instance = modalHelper.openModal($uibModal, 'uploadIntegrationModal');
      // instance.result.then(function () {
      //   initNewSearch();
      // }).catch(function (res) {
      //   if (!(res === 'cancel' || res === 'escape key press')) {
      //     throw res;
      //   }
      // });
    }
  }
}
