import { AppDataService } from 'src/app/app-data.service';
import { HttpClient } from '@angular/common/http';
import { ReportsService } from './../services/reports.service';
import { Title } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { Report } from '../data/model';
import { faExpandAlt, faSpinner, faChartBar, faChartPie, faChartLine, faCalendar, faCompressAlt, faCircle } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { ChartOptions } from 'chart.js';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  inputFilter = ['INTEGER', 'VARCHAR', 'FLOAT', 'NUMERIC', 'BIGINT'];
  params: any = {};
  autocomplete: any = {};
  autocompleteArray: any = {};
  inputData: any = {};
  dateData: any = {};
  autocompleteData: any = {};
  autocompleteDataArray: any = {};
  views: any = {};
  reports: Report[];
  faCircle = faCircle;
  faExpandAlt = faExpandAlt;
  faSpinner = faSpinner;
  faChartBar = faChartBar;
  faChartPie = faChartPie;
  faChartLine = faChartLine;
  faCalendar = faCalendar;
  faCompressAlt = faCompressAlt;
  reportCardExpanded = false;
  colorCnt = -1;
  colors = [
    'dashboard-card-yellow',
    'dashboard-card-blue',
    'dashboard-card-red',
    'dashboard-card-green'
  ];
  options: ChartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
        position: 'right',
      },
    },
  };

  constructor(
    private title: Title,
    private reportsService: ReportsService,
    private http: HttpClient,
    private globalData: AppDataService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Dashboard');
    this.getReports();
  }

  getReports(): void {
    this.reportsService.getAllReports({
      dashOnly: true
    }).subscribe((data: any) => {
      this.reports = data.entity.aaData;
      this.reports.forEach((report) => {
        report.color = this.getNextColor();
        this.views[report.name] = false;
        report.isLoading = true;
        this.reportsService.generateReport(report.id).subscribe((generatedData: any) => {
          this.generateReport(report, generatedData);
        }, () => {
          report.isLoading = false;
        });
      });
    });
  }

  clearDateInput(name: string): void {
    this.dateData[name] = null;
  }


  getNextColor(): string {
    if (this.colorCnt > 2) {
      this.colorCnt = -1;
    }
    this.colorCnt++;
    return this.colors[this.colorCnt];
  }

  getTableParamAttr(report: Report): any {
    let attrs = [{}];
    if (report.reportAttributes && report.reportAttributes.length !== 0) {
      report.reportAttributes.forEach((attr: any) => {
        if (attr.attrName === 'tableParams') {
          attrs = JSON.parse(attr.attrValue);
        }
      });
    }
    return attrs;
  }

  generateReport(report: Report, generatedData): void {
    report.isLoading = false;
    this.reportsService.getReportParams(report.id).subscribe((data) => {
      report.clientParams = data.entity.clientParams;
      data.entity.reportAttributes = data.entity.reportAttributes.filter((attr) => {
        return attr.attrName !== '_CHARTTYPE';
      });
      report.attributes = this.getTableParamAttr(data.entity);

      report.clientParams.forEach((param) => {
        if ((param.paramType === 'BIGINT' || param.paramType === 'VARCHAR') && param.autocompleteAction !== null) {
          this.http.get<any>(this.globalData.baseUrl + param.autocompleteAction + '/').subscribe((response) => {
            if (response.success) {
              this.autocomplete[param.paramCode] = parseResponse(response);
            } else {
              if (response.data.entity[0] && typeof response.data.entity[0] === 'string') {
                const autocompletePrepared = [];
                response.data.entity.forEach((item) => {
                  const obj: any = {};
                  obj.name = item;
                  autocompletePrepared.push(obj);
                });
                this.autocomplete[param.paramCode] = autocompletePrepared;
              } else {
                this.autocomplete[param.paramCode] = response.data.entity;
              }
            }
          }, (response) => {
            console.log(response);
          });
        } else if (param.paramType === 'ARRAY' && param.autocompleteAction !== null) {
          this.reportsService.getReportArrayParamsData(param.autocompleteAction).subscribe((response) => {
            this.autocompleteArray[param.paramCode] = parseResponse(response);
          });
        }
      });

      let reportLabel: string;
      const reportData = [];
      const reportSeries = [];
      const chartSeries = [];
      let i = 0;
      if (report.attributes) {
        report.attributes.forEach((attr) => {
          if (attr.type === 'DATA') {
            reportData.push({
              name: attr.name,
              index: i
            });
            i++;
          } else if (attr.type === 'COLUMN') {
            reportLabel = attr.name;
          } else if (attr.type === 'ROW') {
            reportSeries.push({
              name: attr.name,
              relatesTo: attr.relatesTo
            });
          }
        });
        report.chartDataSets = [];
        report.chartData = [];
        report.chartLabels = [];
        if (generatedData.length > 0) {
          generatedData.forEach((reportEntry) => {
            reportData.forEach((dataEntry) => {
              if (this.getReportChartType(report) === 'pie') {
                report.chartData.push(reportEntry[dataEntry.name]);
              } else {
                if (!report.chartData[dataEntry.index]) {
                  report.chartData[dataEntry.index] = [];
                }
                report.chartData[dataEntry.index].push(reportEntry[dataEntry.name]);
              }
            });
            if (report.chartLabels.indexOf(reportEntry[reportLabel]) === -1) {
              report.chartLabels.push(reportEntry[reportLabel]);
            }
            reportSeries.forEach((repSeries) => {
              if (chartSeries.indexOf(reportEntry[repSeries.name]) === -1) {
                chartSeries.splice(reportData.findIndex(this.indexOfRelated, repSeries.relatesTo), 0, reportEntry[repSeries.name]);
              }
            });
          });
        }
      }
      if ((this.getReportChartType(report) !== 'pie') && report.chartData.length) {
        for (let index = 0; index < chartSeries.length; index++) {
          report.chartDataSets.push({ data: report.chartData[index], label: chartSeries[index] });
        }
      }
      this.reforgeParamType(report);
    }, () => {
      this.toastr.error('Error occured!');
    });
  }

  fixPropertyName(name: string, reportId: number): string {
    return name.replace('#' + reportId + '#', '');
  }

  applyMe(report: Report): void {
    this.params = this.inputData;
    for (const dateProp in this.dateData) {
      if (true) {
        this.params[this.fixPropertyName(dateProp, report.id)] = this.dateData[dateProp];
      }
    }
    for (const autoProp in this.autocompleteData) {
      if (this.autocompleteData[autoProp].id) {
        this.params[this.fixPropertyName(autoProp, report.id)] = this.autocompleteData[autoProp].id;
      } else {
        this.params[this.fixPropertyName(autoProp, report.id)] = this.autocompleteData[autoProp].name;
      }
    }

    Object.keys(this.autocompleteDataArray).forEach((key) => {
      this.params[this.fixPropertyName(key, report.id)] = [];
      if (this.getArrayType(key) === 'VARCHAR') {
        this.autocompleteDataArray[key].forEach((param: any) => {
          this.params[this.fixPropertyName(key, report.id)].push(param.text);
        });
      } else {
        this.autocompleteDataArray[key].forEach((param: any) => {
          this.params[this.fixPropertyName(key, report.id)].push(param.id);
        });
      }
    });
    report.isLoading = true;
    this.reportsService.generateReport(report.id, this.params).subscribe((response: any) => {
      report.isLoading = false;
      this.generateReport(report, response);
    }, () => {
      report.isLoading = false;
      this.toastr.error('Error in query');
    });
  }

  indexOfRelated(elem, idx, array): boolean {
    return elem.name === this.valueOf();
  }

  isInput(paramName): boolean {
    if (this.inputFilter.indexOf(paramName) !== -1) {
      return true;
    }
    return false;
  }

  reforgeParamType(report: Report): void {
    report.clientParams.forEach((param) => {
      if (param.paramType === 'ARRAY') {
        param.paramType += ' ' + param.arrayElementType;
      }
    });
  }

  getArrayType(paramCode: string): string {
    let type: string;
    this.params.forEach((param) => {
      if (param.paramCode === paramCode) {
        type = param.arrayElementType;
      }
    });
    return type;
  }

  getReportChartType(report: Report): string {
    let type: string;
    report.reportAttributes.forEach((attr) => {
      if (attr.attrName === '_CHARTTYPE' && attr.attrValue) {
        type = attr.attrValue.split(' ')[0].toLowerCase();
      }
    });
    return type;
  }

  capitalizeFirstLetter(word: string): string {
    return word.charAt(0).toUpperCase() + word.slice(1);
  }

  toggleMe(e, reportName): void {
    this.views[reportName] = !this.views[reportName];
    const content = $(e.currentTarget).parents('div.content-container');
    const button = $(e.currentTarget);
    const hide = $(e.currentTarget).children();

    // shring or enlarge elements
    content.toggleClass('col-md-4').toggleClass('col-md-12');

    this.reportCardExpanded = !this.reportCardExpanded;
  }
}
