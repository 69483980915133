import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-filter-header-generic',
  templateUrl: './filter-header-generic.component.html',
  styleUrls: ['./filter-header-generic.component.scss']
})
export class FilterHeaderGenericComponent implements OnInit {

  @Input() searchFilters: any;

  constructor() { }

  ngOnInit(): void {
  }

}
