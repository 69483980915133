import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faPencilAlt, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { CampaignModalComponent } from 'src/app/campaign-modal/campaign-modal.component';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { Choice, Filter, SearchFilters } from 'src/app/data/class';
import { Campaign } from 'src/app/data/model';
import { CampaignService } from 'src/app/services/campaign.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-campaign-tab',
  templateUrl: './campaign-tab.component.html',
  styleUrls: ['./campaign-tab.component.scss']
})
export class CampaignTabComponent implements OnInit {

  @Input() public personId: number;
  limit = 20;
  limitPromotion = 10;
  isLoading: boolean;
  filter = new Filter();
  filterPromotion = new Filter();
  searchFilters: SearchFilters;
  statuses: Choice[];
  lock: boolean;
  lengthToCompare: number;
  faPlus = faPlus;
  show: boolean;
  faTrash = faTrash;
  faPencilAlt = faPencilAlt;
  filteredStatuses: Filter[] = [];
  campaigns: any[] = [];
  promotions: any[] = [];
  selectedCampaign: any;
  showMoreButton: any = { name: 'Show More', active: true };
  fileNamePlease: any = { name: '', success: false };
  lockedTables = false;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private tableHelperService: TableHelperService,
    private toastr: ToastrService,
    private campaignService: CampaignService,
    private modalHelper: NgbModal,
    private utiltyService: UtilityService,
    private title: Title
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Programs');
    this.show = true;
    this.searchFilters = {
      formName: 'campaignSearch',
      searchCallback: () => { this.initNewSearch(); },
      filters: [{
        name: 'search',
        placeholder: 'Keyword...'
      },
      {
        name: 'statuses',
        placeholder: 'Status',
        type: 'array',
        choices: this.tableHelperService.getStatuses('ENTITY'),
        nameProp: 'value',
        valueProp: 'value',
        inputType: 'dropdown'
      }
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'New Campaign',
          if: true,
          callback: () => { this.openCampaignModal(); },
          title: 'New Campaign'
        },
      ]
    };
  }

  initNewSearch(): void {
    this.initPromotionFilter();
    this.initFilter();
    this.getCampaigns(false);
  }

  setStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  getCampaigns(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.isLoading = true;
    let result;
    if (this.personId) {
      result = this.campaignService.getAllCampaignsForSponsor(this.personId, this.utiltyService.removeNullAndEmptyProperties(this.filter))
    } else {
      result = this.campaignService.getAllCampaigns(this.utiltyService.removeNullAndEmptyProperties(this.filter));
    }
    result.subscribe((data: any) => {
      if (concat) {
        this.campaigns = this.campaigns.concat(data.entity.aaData);
      } else {
        this.promotions = [];
        this.campaigns = data.entity.aaData;
      }
      this.lengthToCompare = data.entity.aaData.length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables = data.entity.aaData.length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
    }, () => {
      this.lockedTables = false;
      this.isLoading = false;
      this.lock = false;
      this.toastr.error('Error occured!');
    });
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  initPromotionFilter(): void {
    this.filterPromotion.limit = this.limitPromotion;
    this.filterPromotion.offset = 0;
    this.filter.dir = 'desc';
  }

  deleteCampaign(campaign: Campaign): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Delete campaign with ID: ' + campaign.id;
    instance.componentInstance.header = 'Delete campaign?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.campaignService.deleteCampaign(campaign.id).subscribe(() => {
        this.toastr.success('Campaign successfully removed');
        this.initFilter();
        instance.close();
        campaign.status = 'DELETED';
      });
    });
  }

  openCampaignModal(campaign?: Campaign): void {
    if (campaign && campaign.id) {
      this.campaignService.getCampaign(campaign.id).subscribe(data => {
        if (data.success) {
          campaign.campaignAttribs = data.entity.campaignAttribs;
        }
        this.openModal(campaign);
      });
    } else {
      this.openModal(campaign);
    }
  }

  openModal(campaign): void {
    const instance = this.modalHelper.open(CampaignModalComponent);
    instance.componentInstance.campaign = campaign;
    instance.componentInstance.successEvent.subscribe(data => {
      if (data) {
        this.toastr.success(`Campaign successfully ${instance.componentInstance.isEdit ? 'updated' : 'created'}`);
        this.initNewSearch();
        instance.close();
      }
    });
  }

  setShowMoreButton(response, offset): void {
    if(response.entity.length === 0 && offset === 0){
      this.selectedCampaign = null;
    }else if(response.entity.length < 10 && offset === 0){
      this.selectedCampaign = null;
    }
    else if(response.entity.length < 10 && offset !== 0){
      this.showMoreButton.active = false;
      this.showMoreButton.name = 'No more results';
    }
  }

  showMore(): void {
    this.filterPromotion.offset += this.filterPromotion.limit;
    this.campaignService.getCampaignPromotions(this.selectedCampaign.id, this.personId,
      this.filterPromotion.limit, this.filterPromotion.offset).subscribe(data => {
        if (data.success) {
          this.promotions = this.promotions.concat(data.entity);
          this.setShowMoreButton(data, this.filterPromotion.offset);
        }
      });
  }

  showCampaign(e, campaign): void {
    $('tr.active').removeClass('active');
    $(e.currentTarget).toggleClass('active');
    this.campaignService.getCampaignPromotions(campaign.id, this.personId, this.filterPromotion.limit).subscribe(data => {
      if (data.success) {
        this.promotions = data.entity;
        this.filterPromotion.offset = 0;
        this.selectedCampaign = campaign;
        this.showMoreButton.active = true;
        this.showMoreButton.name = 'Show More';
        this.setShowMoreButton(data, this.filterPromotion.offset);
      }
    });
  }
}
