import { AppDataService } from './../app-data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CommunicationStrategy, CommunicationStrategyConfiguration } from '../data/model';

@Injectable({
  providedIn: 'root'
})
export class CommunicationStrategyService {

  constructor(private http: HttpClient, private globalData: AppDataService) { }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getAllCommunicationStrategy(params?: any): any {
    if (params) {
      return this.http.get<any>(this.globalData.baseUrl + 'communication-strategy/', this.setParams(params));
    }
    return this.http.get<any>(this.globalData.baseUrl + 'communication-strategy/');
  }

  getCommStrategyConfigurations(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'communication-strategy-configuration/' + id);
  }

  createOrUpdateCommunicationStrategy(id: number, communicationStrategy: CommunicationStrategy): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'communication-strategy/' + id, communicationStrategy);
    }
    return this.http.post(this.globalData.baseUrl + 'communication-strategy', communicationStrategy);
  }

  createOrUpdateCommunicationStrategyConfiguration(id: number, communicationStrategyConfiguration: CommunicationStrategyConfiguration): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'communication-strategy-configuration/' + id, communicationStrategyConfiguration);
    }
    return this.http.post(this.globalData.baseUrl + 'communication-strategy-configuration', communicationStrategyConfiguration);
  }

  deleteCommunicationStrategy(id: number): any {
    return this.http.delete(this.globalData.baseUrl + 'communication-strategy/' + id);
  }

  deleteCommStrategyConfiguration(id: number): any {
    return this.http.delete(this.globalData.baseUrl + 'communication-strategy-configuration/' + id);
  }

  getCommStrategyConfiguration(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'communication-strategy-configuration/config/' + id);
  }

  addFilterToStrategy(id: number, data: any): any {
    return this.http.post(this.globalData.baseUrl + 'communication-strategy/' + id + '/filters', data);
  }

  getCommStrategyFilters(id: number, params: any): any {
    return this.http.get(this.globalData.baseUrl + 'communication-strategy/' + id + '/filters', this.setParams(params));
  }

  insertFilterParams(id: number, filterId: number, params: any): any {
    return this.http.post(this.globalData.baseUrl + 'communication-strategy/' + id + '/filters/' + filterId + '/params', params);
  }

  removeFilterParam(id: number, filterId: number, paramId: number): any {
    return this.http.delete(this.globalData.baseUrl + 'communication-strategy/' + id + '/filters/' + filterId + '/params/' + paramId);
  }

  getCampaigns(campaignId: number, params?: any): any {
    if (params) {
      return this.http.get<any>(this.globalData.baseUrl + 'communication-strategy/' + campaignId + '/campaigns', this.setParams(params));
    }
    return this.http.get<any>(this.globalData.baseUrl + 'communication-strategy/' + campaignId + '/campaigns');
  }

  getCommStrategy(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'communication-strategy/' + id);
  }
}
