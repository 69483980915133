import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faCalendar, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { CommunicationSegment, DateObject } from 'src/app/data/class';
import { Segment } from 'src/app/data/model';
import { CommunicationsService } from 'src/app/services/communications.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';

@Component({
  selector: 'app-communication-segment-modal',
  templateUrl: './communication-segment-modal.component.html',
  styleUrls: ['./communication-segment-modal.component.scss']
})
export class CommunicationSegmentModalComponent implements OnInit {

  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() commId: number;
  @Input() segments: Segment[];
  segment: CommunicationSegment = { segment: null, fromDate: null, toDate: null, name: null };
  commSegmentForm: UntypedFormGroup;
  errorMsg: string;
  subject: Subject<any> = new Subject<any>();
  faCalendar = faCalendar;
  faSave = faSave;
  faTimes = faTimes;

  constructor(
    private formBuilder: FormBuilder,
    private modalHelper: NgbModal,
    private responseHelperService: ResponseHelperService,
    private communicationsService: CommunicationsService

  ) { }

  ngOnInit(): void {
    this.commSegmentForm = this.formBuilder.group({
      segment: [this.segment.segment, Validators.required],
      fromDate: [this.segment.fromDate, Validators.required],
      toDate: [this.segment.toDate]
    });
  }

  clearDateInput(name: string): void {
    this.commSegmentForm.patchValue({
      [name]: null
    });
  }

  changeFilter(propName: string, propValue: any): void {
    if (propName === 'fromDate' || propName === 'toDate') {
      propValue = this.formatDate(propValue);
    }
    const item = { name: propName, value: propValue };
    this.subject.next(item);
  }

  formatDate(dateToFormat: any): string {
    let resultDate = '';
    if (dateToFormat !== null) {
      resultDate = dateToFormat.year + '-' + dateToFormat.month + '-' + dateToFormat.day;
    }
    return resultDate;
  }

  dateToString(date: DateObject): string {
    const month = date.month < 10 ? '0' + date.month : date.month;
    const day = date.day < 10 ? '0' + date.day : date.day;
    return date.year + '-' + month + '-' + day;
  }

  ok(): void {
    const params = {
      commId: this.commId,
      segmentId: this.commSegmentForm.get('segment').value,
      startDate: this.dateToString(this.commSegmentForm.get('fromDate').value),
      endDate: this.commSegmentForm.get('toDate').value ? this.dateToString(this.commSegmentForm.get('toDate').value) : null
    };
    this.communicationsService.addSegmentsToCommunication(params).subscribe(() => {
      this.responseHelperService.success('Segments successfully updated', true);
      this.successEvent.emit();
    }, (data: any) => {
      this.responseHelperService.error(this, data.error.error);
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

}
