import { Component, OnInit } from '@angular/core';
import { NutritionService } from '../../services/nutrition.service';
import { NutritionTag } from '../../data/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { ResponseHelperService } from '../../services/helpers/response-helper.service';
import { faTrash, faEdit, faPlus } from '@fortawesome/free-solid-svg-icons';
import { AttributeMappingsGroupModalComponent } from '../attribute-mappings-group-modal/attribute-mappings-group-modal.component';
import { AddAttributeMappingsModalComponent } from '../add-attribute-mappings-modal/add-attribute-mappings-modal.component';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-attribute-mappings-tab',
  templateUrl: './attribute-mappings-tab.component.html',
  styleUrls: ['./attribute-mappings-tab.component.scss']
})
export class AttributeMappingsTabComponent implements OnInit {

  show = false;
  selectedTagId: number;
  tags: NutritionTag[] = [];
  attrMappings: any;
  tagCatogoryColors = {
    'COURSE': 'label-primary',
    'CUISINE': 'label-success',
    'DIET': 'label-info',
    'LIFESTYLE': 'label-warning',
    'DISH_TYPE': 'label-danger',
    'MEAL': 'label-defult'
  };
  faTrash = faTrash;
  faEdit = faEdit;
  faPlus = faPlus;

  constructor(
    private nutritionService: NutritionService,
    private modalHelper: NgbModal,
    private responseHelperService: ResponseHelperService
  ) { }

  ngOnInit(): void {
    this.getNutritionTags();
    this.show = true;
  }

  getNutritionTags(): void {
    this.nutritionService.getNutritionTags().subscribe((data: any) => {
      if (data.success) {
        this.tags = parseResponse(data);
      }
    });
  }

  setActive(e: any, id: number): void {
    this.getGroups(id);
    this.selectedTagId = id;
    $('.attribute-mappings > li.active').removeClass('active');
    $(e.currentTarget).addClass('active');
  }

  getGroups(id: number): void {
    this.nutritionService.getNutritionTagMappings(id).subscribe((data: any) => {
      if (data.success) {
        this.attrMappings = parseResponse(data);
      }
    });
  }

  attributePairModal(groupId: number, mapping?: any): void {
    const attributePair = {
      nutritionTagGroupId: groupId
    }
    const instance = this.modalHelper.open(AddAttributeMappingsModalComponent);
    instance.componentInstance.attributePair = mapping ? mapping : attributePair;
    instance.componentInstance.attrMappings = this.attrMappings;
    instance.componentInstance.successEvent.subscribe(() => {
      this.getGroups(this.selectedTagId);
      instance.close();
    });
  }

  groupDialog(group?: any): void {
    const instance = this.modalHelper.open(AttributeMappingsGroupModalComponent);
    instance.componentInstance.selectedTagId = this.selectedTagId;
    instance.componentInstance.attrMappings = this.attrMappings;
    if (group) {
      instance.componentInstance.group = group;
    }
    instance.componentInstance.successEvent.subscribe(() => {
      this.getGroups(this.selectedTagId);
      instance.close();
    });
  }

  deleteMapping(mapping: any, group: any): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent);
    instance.componentInstance.text = 'Delete mapping with attrribute name expression: ' + mapping.attrNameExpr +
      ' and attrribute value expression: ' + mapping.attrValueExpr;
    instance.componentInstance.header = 'Delete Mapping?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.nutritionService.deleteNutritionTagMappings(mapping.id).subscribe((data: any) => {
        this.responseHelperService.success('Mapping successfully removed', false);
        group.mappings = group.mappings.filter((obj: any) => {
          return obj.id !== mapping.id;
        });
        instance.close();
      }, (data: any) => {
        this.responseHelperService.error(this, data.error.error);
      });
    });
  }

  deleteGroup(group: any): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent);
    instance.componentInstance.text = 'Delete group name: ' + group.groupName;
    instance.componentInstance.header = 'Delete Mapping?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.nutritionService.deleteNutritionTagMappingsGroup(this.selectedTagId, group.id).subscribe((data: any) => {
        this.responseHelperService.success('Group successfully removed', false);
        this.getGroups(this.selectedTagId);
        instance.close();
      }, (data: any) => {
        this.responseHelperService.error(this, data.error.error);
      });
    });
  }

}
