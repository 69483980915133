import { AppDataService } from './../app-data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OutboundIntegration } from '../data/model';

@Injectable({
  providedIn: 'root'
})
export class OutboundService {

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  constructor(private http: HttpClient, private globalData: AppDataService) { }

  getOutbounds(params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'outbound-integrations/', this.setParams(params));
  }

  getOutbound(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'outbound-integrations/' + id);
  }

  createOrUpdateOutbound(data: any, id?: number): any {
    if (id) {
      return this.http.put<any>(this.globalData.baseUrl + 'outbound-integrations/' + id, data);
    } else {
      return this.http.post<any>(this.globalData.baseUrl + 'outbound-integrations/', data);
    }
  }

  getAllOutboundLoad(id: number, params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'outbound-integrations/' + id + '/loads', this.setParams(params));
  }

  getStageExport(outboundId: number, loadId: number, params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'outbound-integrations/' + outboundId + '/loads/' + loadId + '/exports', this.setParams(params));
  }
}
