<div class="tickets-list" [hidden]="!isTicketsOpened">
  <div class="ibox float-e-margins">
    <div class="ibox-title ticket-header border-left-2px">
      <div class="clear-both">
        <h5>Interactions</h5>
      </div>
      <!-- TODO: We don't have jiraLink defined -->
      <!-- <div *ngIf="jiraLink.length > 0 && jiraAccess">
        <h3 class="floar-right"><a href="{{jiraLink}}" target="_blank">JIRA</a></h3>
      </div> -->
      <div class="clear-both">
        <input type="checkbox" [(ngModel)]="filterModel.myTickets" (change)="toggleFilter()" [checked]="true"
          [hidden]="!isCSRManager"> Started By Me
      </div>
      <div class="ibox-tools">
        <a class="collapse-link">
          <fa-icon [icon]="faChevronDown" (click)="toggleTickets()"></fa-icon>
        </a>
      </div>
    </div>
    <div class="ibox-content no-padding border-left-2px tickets-max-height">
      <ul class="list-group">
        <li class="list-group-item" (click)="openTicket(ticket)" *ngFor="let ticket of openedTickets">
          <span class="badge badge-warning float-right" [hidden]="!(ticket.status === 'INACTIVE')">
            <fa-icon [icon]="faPause"></fa-icon>
          </span>
          <span class="badge badge-warning float-right" [hidden]="!(ticket.status === 'ESCALATED')">
            <fa-icon [icon]="faFlag"></fa-icon>
          </span>
          <div class="tickets-subject">{{ticket.created}} - {{ticket.subject}}</div>
          {{ticket.memberFirstName}} {{ticket.memberLastName}} (<a
            routerLink="/members/{{ticket.memberPartyId}}">{{ticket.memberPartyId}}</a>) - {{ticket.contactMethod}}
        </li>
      </ul>
    </div>
  </div>
</div>
<div class="tickets-button" [hidden]="isTicketsOpened">
  <button id="tickets-btn" class="btn btn-info dim" [disabled]="disableTickets" (click)="toggleTickets()" type="button">
    <fa-icon [icon]="faPaste"></fa-icon>
  </button>
</div>
