import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ReportsService } from '../services/reports.service';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../app-data.service';
import { UntypedFormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { SecurityService } from '../services/security/security.service';
import { saveAs } from '../data/saveAs';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-orb',
  templateUrl: './orb.component.html',
  styleUrls: ['./orb.component.scss']
})
export class OrbComponent implements OnInit {
  reportForm: UntypedFormGroup;
  report: any = {};
  inputFilter = ['INTEGER', 'VARCHAR', 'FLOAT', 'NUMERIC', 'BIGINT'];
  params: any[];
  config = {};
  orb: any;
  tableData = [];
  tableFields = [];
  tableRows = [];
  tableColumns = [];
  autocomplete = {};
  autocompleteArray = {};
  inputData = [];
  dateData = {};
  autocompleteData = {};
  autocompleteDataArray = {};
  isLoading = false;
  disableButtons = false;
  showCSV: boolean;
  reportNotes: string;
  faCalendar = faCalendar;

  constructor(private http: HttpClient,
              private formBuilder: FormBuilder,
              public globalData: AppDataService,
              private title: Title,
              private toastr: ToastrService,
              private activatedRoute: ActivatedRoute,
              private securityService: SecurityService,
              private reportsService: ReportsService) { }

  isProgramManager = this.globalData.isProgramManager;

  ngOnInit(): void {
    this.reportForm = this.formBuilder.group({});
    this.title.setTitle('Orb');
    const id = this.activatedRoute.snapshot.params.id;
    this.useCSVReports();
    this.reportsService.getReport(id).subscribe((data: any) => {
      if (data.success) {
        this.report = data.entity;
        this.getReportParams();
      }
    });
  }

  useCSVReports(): void {
    this.securityService.useCSVReports.subscribe((response: any) => {
      this.showCSV = response;
    });
  }

  getReportParams(): void {
    this.reportsService.getReportParams(this.report.id).subscribe((data: any) => {
      this.params = data.entity.clientParams;
      data.entity.reportAttributes = data.entity.reportAttributes.filter((attr: any) => {
        return attr.attrName !== '_CHARTTYPE' && attr.attrName !== 'EXTERNAL_APP_URL';
      });
      this.params.forEach((param: any) => {
        if (param.requiredParam) {
          this.reportForm.addControl(param.paramCode, new FormControl(null, Validators.required));
        } else {
          this.reportForm.addControl(param.paramCode, new FormControl(null));
        }
      });
      if (data.entity.reportAttributes.length > 0) {
        const attrs = JSON.parse(data.entity.reportAttributes[0].attrValue);
        this.tableFields = attrs;
        attrs.forEach((attr: any) => {
          if (attr.type === 'DATA') {
            this.tableData.push(attr.caption);
          }
          if (attr.type === 'COLUMN') {
            this.tableColumns.push(attr.caption);
          }
          if (attr.type === 'ROW') {
            this.tableRows.push(attr.caption);
          }
        });
      }
      this.params.forEach((param: any) => {
        if ((param.paramType === 'BIGINT' || param.paramType === 'VARCHAR') && param.autocompleteAction !== null &&
          param.autocompleteAction !== '') {
          this.http.get('/cheetah/api/' + param.autocompleteAction).subscribe((response: any) => {
            if (response.data.entity.aaData) {
              this.autocomplete[param.paramCode] = response.data.entity.aaData;
            } else {
              if (response.data.entity[0] && typeof response.data.entity[0] === 'string') {
                const autocompletePrepared = [];
                response.data.entity.forEach((item: any) => {
                  autocompletePrepared.push({
                    regParamName: item
                  });
                });
                this.autocomplete[param.paramCode] = autocompletePrepared;
              } else {
                this.autocomplete[param.paramCode] = response.data.entity;
              }
            }
          }, (response: any) => {
            console.log(response);
          });
        } else if (param.paramType === 'ARRAY' && param.autocompleteAction !== null) {
          this.reportsService.getReportArrayParamsData(param.autocompleteAction).subscribe((response: any) => {
            this.autocompleteArray[param.paramCode] = parseResponse(response);
            
            // fill in default params
            if (param.defaultValue) {
              const defaultArray = param.defaultValue.split(',').map((elem: any) => {
                return elem.trim();
              });
              this.autocompleteDataArray[param.paramCode] = [];
              this.autocompleteArray[param.paramCode].forEach((elem: any) => {
                if (defaultArray.indexOf(elem.id) !== -1 || defaultArray.indexOf(elem.text) !== -1) {
                  this.autocompleteDataArray[param.paramCode].push(elem);
                }
              });
            }
          });
        }
      });
    });
  }

  isInput(paramName: string): boolean {
    return this.inputFilter.indexOf(paramName) !== -1;
  }

  applyMe(destination: any): void {
    this.disableButtons = true;
    const params = this.inputData;
    Object.keys(this.dateData).forEach((dateProp: string) => {
      if (true) {
        this.params[dateProp] = this.dateData[dateProp];
      }
    });
    Object.keys(this.autocompleteData).forEach((autoProp: string) => {
      if (this.autocompleteData[autoProp].id) {
        params[autoProp] = this.autocompleteData[autoProp].id;
      } else {
        params[autoProp] = this.autocompleteData[autoProp].regParamName;
      }
    });
    if (this.autocompleteData) {
      Object.keys(this.autocompleteDataArray).forEach((key: string) => {
        params[key] = [];
        if (this.getArrayType(key) === 'VARCHAR') {
          this.autocompleteDataArray[key].forEach((param: any) => {
            params[key].push(param.text ? param.text : param);
          });
        } else {
          this.autocompleteDataArray[key].forEach((param: any) => {
            params[key].push(param.id ? param.id : param);
          });
        }
      });
    }
    if (destination === 'csv') {
      this.isLoading = true;
      this.reportsService.generateCSV(this.report.id, params).subscribe((response: any) => {
        this.isLoading = false;
        const blob = new Blob([response], { type: 'csv' });
        saveAs(blob, this.report.regParamName + new Date() + '.csv')
        this.enableButtons();
      });
    } else if (destination === 'external') {
      this.isLoading = true;
      const reportParams = {
        params
      };
      this.reportsService.triggerExternalExecution(this.report.id, reportParams).subscribe((response: any) => {
        this.isLoading = false;
        this.toastr.success(response.entity);
        this.enableButtons();
      }, (response: any) => {
        this.isLoading = false;
        this.toastr.error(response.errorMsg);
        this.enableButtons();
      });
    } else {
      this.isLoading = true;
      this.reportsService.generateReport(this.report.id, params).subscribe((response: any) => {
        this.isLoading = false;
        // Check if table is already rendered and if it is just reload data
        if ($('.orb-container').length > 0) {
          this.orb.refreshData(response);
        } else {
          this.renderTable(response);
        }
        this.disableButtons = false;
        this.enableButtons();
      }, () => {
        this.isLoading = false;
        this.toastr.error('Error in query');
        this.enableButtons();
      });
    }
  }

  clearDateInput(name: string): void {
    this.reportForm.patchValue({
      [name] : null
    });
  }

  getArrayType(paramCode: string): string | undefined {
    let type;
    this.params.forEach((param: any) => {
      if (param.paramCode === paramCode) {
        type = param.arrayElementType;
      }
    });
    return type;
  }

  enableButtons(): void {
    setTimeout(() => {
      this.disableButtons = false;
    }, 5000);
  }

  renderTable(response: any): void {
    const config = {
      dataSource: response,
      dataHeadersLocation: 'columns',
      theme: 'blue',
      toolbar: {
        visible: true
      },
      grandTotal: {
        rowsvisible: true,
        columnsvisible: true
      },
      subTotal: {
        visible: true,
        collapsed: true
      },
      fields: this.tableFields,
      rows: this.tableRows,
      columns: this.tableColumns,
      data: this.tableData
    };
  }
}
