import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {  Promotion } from '../data/model';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { PromotionService } from '../services/promotion.service';

@Component({
  selector: 'app-promotion-modal',
  templateUrl: './promotion-modal.component.html',
  styleUrls: ['./promotion-modal.component.scss']
})
export class PromotionModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public promotion: Promotion;
  @Input() public icon: any;
  @Input() public promotions: any[];
  @Input() public types: any[];
  @Input() public isEdit: boolean;
  @Input() public programId: number
  title: string;
  promotionForm: UntypedFormGroup;
  errorMsg: string;
  faSave = faSave;
  faTimes = faTimes;

  constructor(private formBuilder: FormBuilder,
              private statusHelperService: StatusHelperService,
              private promotionService: PromotionService,
              private modalHelper: NgbModal) { }

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.isEdit) {
      this.title = 'Edit Promotion';
      this.promotionForm = this.formBuilder.group({
        name: [this.promotion.name, Validators.required],
        code: [this.promotion.code, Validators.required],
        status: [this.promotion.status, Validators.required],
        description: [this.promotion.description],
        typeId: [this.promotion.typeId, Validators.required],
        parentId: [this.promotion.parentId]
      });
    } else {
        this.title = 'New Promotion';
        this.promotionForm = this.formBuilder.group({
          name: ['', Validators.required],
          code: ['', Validators.required],
          status: ['', Validators.required],
          description: [''],
          typeId: [null, Validators.required],
          parentId: []
      });
    }
  }

  ok(): void {
    const promotion = Object.assign({}, this.promotionForm.value);
    promotion.programId = this.programId;
    if (this.isEdit){
      promotion.createdBy = this.promotion.createdBy;
      promotion.createdOn = this.promotion.createdOn;
    }
    this.promotionService.createOdUpdatePromotion(this.promotion && this.promotion.id, promotion).subscribe(() => {
      this.successEvent.emit('success');
    }, (data: any) => {
       this.errorMsg = data.error.error;
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

}
