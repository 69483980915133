import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormsModule } from '@angular/forms';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ActivatedRoute } from '@angular/router';
import { Subject} from 'rxjs';
import { interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-age-restriction',
  templateUrl: './age-restriction.component.html',
  styleUrls: ['./age-restriction.component.scss']
})
export class AgeRestrictionComponent implements OnInit {
    @Input() ageRestriction: any;
    units: any;
    directions: any;
    faTimes = faTimes;
    faPlus = faPlus;
    @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
    debouncer: Subject<string> = new Subject<string>();

  constructor(
    private router: ActivatedRoute,
    private formBuilder: FormBuilder,
    private formsModule: FormsModule,
    private modalHelper: NgbModal,
  ) {
      this.debouncer
        .pipe(debounce(() => interval(1000)))
        .subscribe((value) => this.inputChangeEmitter.emit(value));
    }

  ngOnInit(): void {
       this.units = [
           {'value' : 'DAY',     'text': 'DAY'},
           {'value' : 'WEEK',    'text': 'WEEK'},
           {'value' : 'MONTH',     'text': 'MONTH'},
           {'value' : 'YEAR',    'text': 'YEAR'}
       ];

       this.directions = [
           {'value' : 'OLDER',     'text': 'OLDER'},
           {'value' : 'YOUNGER',    'text': 'YOUNGER'}
       ];

       console.log(this.ageRestriction);
  }

  initRestrictionObject(): void {
    console.log(this.ageRestriction);
    var tmpObj = { class: "com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Restrictions$Eligibility$AgeRestriction" };
    if(this.ageRestriction){
      this.ageRestriction.push(tmpObj);
    } else {
      this.ageRestriction = [];
      this.ageRestriction.push(tmpObj);
    }
    console.log(this.ageRestriction);
  }

  updateRestrictions() {
    this.debouncer.next(this.ageRestriction);
  }

  removeRestriction(restriction) {
    this.ageRestriction.splice(this.ageRestriction.indexOf(restriction), 1);
    this.updateRestrictions();
  }
}
