import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppDataService } from '../app-data.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  constructor(private title: Title,
              public globalData: AppDataService) { }

  isAccountManager = this.globalData.isAccountManager;
  isAdmin = this.globalData.isAdmin;
  activeId = 1;

  ngOnInit(): void {
    this.title.setTitle('Reports');
  }

}
