<div class="list-wrapper cursor-pointer">
  <div class="row" [ngClass]="getPromoState(promotion, 'class')" title="{{promotion.name}}"
    *ngIf="showPromo(promotion)">
    <div class="col-md-9 promotion-div uppercase">
      <fa-icon *ngIf="promotion.children" [icon]="showChild ? faChevronDown : faChevronUp" (click)="toggleChildren($event)">
      </fa-icon>
      {{ promotion.name }}
    </div>
    <div class="col-md-3 flex-pull-right">
      <span class="colored-circle" [ngClass]="statusHelper[promotion.status]">{{promotion.status &&
        promotion.status.substring(0, 1)}}</span>
      <span class="closeBtn" [ngClass]="getPromoState(promotion, 'isSelected') ? 'visible' : 'invisible'" (click)="removePromo(promotion)">
        <fa-icon [icon]="faTimes"></fa-icon>
      </span>
    </div>
  </div>
  <ul *ngIf="promotion.children" class="list-none">
    <li *ngFor="let promo of promotion.children" [ngClass]="'parent-id-' + promo.parentId">
      <app-sponsorship-promo-tree [statusHelper]="statusHelper"
        [selectedPackage]="selectedPackage" [programStructure]="programStructure"
         [promotion]="promo" [selectedPackage]="selectedPackage" [(selectedPromotion)]="selectedPromotion">
      </app-sponsorship-promo-tree>
    </li>
  </ul>
</div>