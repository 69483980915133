<div class="panel-body">
    <div class="row">
      <div class="col-md-12">
        <div class="full-height-scroll" full-scroll>
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                  <app-search-filter [searchFilters]="searchFilters" class="full-width"></app-search-filter>
              </div>
              <div class="row table-comm-content">
                <table class="table table-pointable table-bordered infinite-table" add-more-data-to-list func-to-exec="getCommContent(true)">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Language</th>
                      <th>Subject</th>
                      <th>Template</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let cc of commContent">
                      <td>{{cc.id}}</td>
                      <td>{{language.get(cc.languageId).languageCode + " - " + language.get(cc.languageId).languageName}}</td>
                      <td>{{cc.subject}}</td>
                      <td><button [disabled]="!cc.template" class="btn btn-primary btn-sm" (click)="templateModal(cc.template)"> <fa-icon [icon]="faFileCode"></fa-icon> Preview Template</button></td>
                      <td>
                        <div class="pull-left actions" style="display:inline-table">
                          <a class="btn btn-dark-blue btn-sm edit action" (click)="contentModal(cc)" title="Edit"><fa-icon [icon]="faPencilAlt"></fa-icon></a>
                          <!-- TODO: implement delete, old cheetah is missing this -->
                          <!-- <button class="btn btn-attention btn-sm delete action" [disabled]="cc.status==='DELETED'" (click)="deleteContent(pc.id)" title="Delete"><fa-icon [icon]="faTrash"></fa-icon></button> -->
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>
