import { Component, EventEmitter, Input, OnInit, Output, ViewChild, NgModule } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { ProgramService } from 'src/app/services/program.service';
import { PromotionConfigService } from 'src/app/services/promotion-config.service';
import { PromotionService } from 'src/app/services/promotion.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FormBuilder, UntypedFormGroup, Validators, FormsModule } from '@angular/forms';
import { faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { ActivatedRoute } from '@angular/router';
import { PromotionTemplate } from 'src/app/data/model';
import { Promotion } from 'src/app/data/model';
import * as $ from 'jquery';
import {Observable, Subject} from 'rxjs';
import { RegexSearchModalComponent } from 'src/app/regex-search-modal/regex-search-modal.component';
import { fromEvent, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-activity-attribute-interval-restriction',
  templateUrl: './activity-attribute-interval-restriction.component.html',
  styleUrls: ['./activity-attribute-interval-restriction.component.scss']
})
export class ActivityAttributeIntervalRestrictionComponent implements OnInit {
    @Input() activityAttributeIntervalRestriction: any;
    units: any;
    conditions: any;
    inclusionValues: any;
    faTimes = faTimes;
    faPlus = faPlus;
    @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
    debouncer: Subject<string> = new Subject<string>();

  constructor(
    private router: ActivatedRoute,
    private formBuilder: FormBuilder,
    private formsModule: FormsModule,
    private modalHelper: NgbModal,
  ) {
      this.debouncer
        .pipe(debounce(() => interval(1000)))
        .subscribe((value) => this.inputChangeEmitter.emit(value));
    }

  ngOnInit(): void {
       this.conditions = [
           {'value' : true,     'text': 'true'},
           {'value' : false,    'text': 'false'}
       ];

       this.units = [
           {'value' : 'YEAR',     'text': 'YEAR'},
           {'value' : 'MONTH',    'text': 'MONTH'},
           {'value' : 'WEEK',     'text': 'WEEK'},
           {'value' : 'DAY',    'text': 'DAY'}
       ];

       this.inclusionValues = [
           {'value' : 'strict',     'text': 'strict'},
           {'value' : 'permissive',    'text': 'permissive'}
       ];
  }

  initRestrictionObject(): void {
    console.log(this.activityAttributeIntervalRestriction);
    var tmpObj = { class: "com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Restrictions$Behavioral$ActivityAttributeInterval" };
    if(this.activityAttributeIntervalRestriction){
      this.activityAttributeIntervalRestriction.push(tmpObj);
    } else {
      this.activityAttributeIntervalRestriction = [];
      this.activityAttributeIntervalRestriction.push(tmpObj);
    }
  }

  updateRestrictions() {
    this.debouncer.next(this.activityAttributeIntervalRestriction);
  }

  removeRestriction(restriction) {
    this.activityAttributeIntervalRestriction.splice(this.activityAttributeIntervalRestriction.indexOf(restriction), 1);
    this.updateRestrictions();
  }
}
