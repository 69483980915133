<div>
  <form>
    <label>The gender of the member:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="genderRestriction !== null && genderRestriction !== undefined">
        <button class="btn btn-attention float-right" type="button" (click)="removeRestriction(genderRestriction)" title="Remove Restriction">
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
        <form>
        <div class="form-group">
          <select name="genderRestriction_{{genderRestriction.condition}}" class="form-control" [(ngModel)]="genderRestriction.condition" (ngModelChange)="updateRestrictions()">
            <option *ngFor="let condition of conditions" [selected]="genderRestriction.condition === condition.value" [value]="condition.value">{{condition.text}}</option>
          </select>
        </div>
          <div class="form-group">
            <select name="genderRestriction_{{genderRestriction.value}}" class="form-control" [(ngModel)]="genderRestriction.value" (ngModelChange)="updateRestrictions()">
              <option *ngFor="let value of values" [selected]="genderRestriction.value === value.value" [value]="value.value">{{value.text}}</option>
            </select>
          </div>
        </form>
      </div>
      <div class="form-group float-right col-md-12">
        <button class="btn btn-default float-right" (click)="initRestrictionObject()">
          <fa-icon [icon]="faPlus"></fa-icon> Add Gender Restriction
        </button>
      </div>
    </div>
  </form>
</div>
