import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Filter } from '../data/class';


@Injectable({
  providedIn: 'root'
})
export class SurveyService {

  constructor(
    private apollo: Apollo,
  ) { }

  getServeyQuery = gql`
  query RootQueryType($id: String!) {
    viewer {
      id
      survey(id: $id) {
        id
        allTags
        dateOverride
        decodedId
        displayType
        imageUrl
        name
        post
        pre
        processingMethod
        returnUrl
        tags
        title
        submissions {
          id
          submittedAt
          createdAt
          documents {
            id
            type
          }
          surveyResponse {
            answer
            order
            question
            type
          }
        }
        questions {
          id
          name
          order
          required
          question
          answers {
            id
            label
            order
            value
          }
        }
        interactions {
          id
          parameters
          url
          headers
        }
        transactions {
          id
          behaviorCode
        }
      }
    }
  }
  `;

  getSurveysQuery = gql`
  query RootQueryType {
    viewer {
      surveys {
        id
        allTags
        dateOverride
        displayType
        imageUrl
        name
        returnUrl
        tags
        title
      }
    }
  }
  `;

  getSubmissionsQuery = gql`
  query RootQueryType($partyId: Int!, $first: Int, $after: String) {
    viewer {
      submissions(partyId: $partyId, first: $first, after: $after) {
        edges {
          node {
            id
            partyId
            createdAt
            submittedAt
            surveyId
            surveyData {
              name
              title
            }
            surveyResponse {
              answer
              order
              question
              type
            }
          }
        }
        pageInfo {
          hasNextPage
          hasPreviousPage
          startCursor
          endCursor

        }
      }
    }
  }
  `;

  getSurveys(filter: Filter): any {
    return this.apollo.query({
      query: this.getSurveysQuery,
    });
  }

  getSurveyById(id: string): any {
    return this.apollo.query({
      query: this.getServeyQuery,
      variables: { id }
    });
  }

  getSubmissions(partyId: number, first: number, after: String): any {
    return this.apollo.query({
      query: this.getSubmissionsQuery,
      variables: { partyId, first, after }
    });
  }
}
