import { NotFoundComponent } from './not-found/not-found.component';
import { LoggedOutComponent } from './logged-out/logged-out.component';
import { ProcessingLogsComponent } from './processing-log/processing-logs/processing-logs.component';
import { ProcessingLogComponent } from './processing-log/processing-log/processing-log.component';
import { ManifestComponent } from './manifest/manifest.component';
import { OutboundComponent } from './outbound-batch/outbound/outbound.component';
import { IntegrationStageImportComponent } from './integrations-tab/integration-stage-import/integration-stage-import.component';
import { IntegrationMessagesComponent } from './integrations-tab/integration-messages/integration-messages.component';
import { IntegrationStageLoadComponent } from './integrations-tab/integration-stage-load/integration-stage-load.component';
import { IntegrationsComponent } from './integrations-tab/integrations/integrations.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SponsorComponent } from './sponsor/sponsor.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment, UrlSegmentGroup, Route } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { MembersComponent } from './member/members/members.component';
import { MemberComponent } from './member/member/member.component';
import { SponsorsComponent } from './sponsors/sponsors.component';
import { LoginGuard } from './services/login-guard.service';
import { CommunicationsComponent } from './communications/communications.component';
import { CommunicationComponent } from './communication/communication.component';
import { ProgramsComponent } from './programs/programs.component';
import { PromotionTemplateBuilderV2Component } from './promotion-config-builder/promotion-template-builder-v2/promotion-template-builder-v2.component';
import { PromotionConfigBuilderV2Component } from './promotion-config-builder/promotion-config-builder-v2/promotion-config-builder-v2.component';
import { OutboundStageLoadsComponent } from './outbound-batch/outbound-stage-loads/outbound-stage-loads.component';
import { OutboundStageExportsComponent } from './outbound-batch/outbound-stage-exports/outbound-stage-exports.component';
import { PromotionsComponent } from './promotions/promotions.component';
import { SystemComponent } from './system-tab/system/system.component';
import { PartnersComponent } from './partners/partners.component';
import { PartnerComponent } from './partner/partner.component';
import { ReportsComponent } from './reports/reports.component';
import { UsersComponent } from './users/users.component';
import { SegmentsComponent } from './segments/segments.component';
import { ReportComponent } from './report/report.component';
import { OrbComponent } from './orb/orb.component';
import { EventsTabComponent } from './member/member-tabs/member-events-tab/member-events-tab.component';
import { MemberMeasuresTabComponent } from './member/member-tabs/member-measures-tab/member-measures-tab.component';
import { InteractionsComponent } from './member/interactions/interactions.component';
import { PackagedSponsorshipComponent } from './packaged-sponsorship/packaged-sponsorship.component';
import { ActivitySearchComponent } from './activity-search/activity-search.component';
import { EventSourcesTabComponent } from './integrations-tab/event-sources-tab/event-sources-tab.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NewPasswordComponent } from './new-password/new-password.component';
import { DisplayXmlTabComponent } from './display-xml-tab/display-xml-tab.component';
import { CommStrategyTabComponent } from './comm-strategy-tab/comm-strategy-tab.component';
import { RedemptionDashboardComponent } from './redemption-dashboard/redemption-dashboard.component';
import { ProfileComponent } from "./profile/profile.component";

const routes: Routes = [
  {path: '',  component: HomeComponent, canActivate: [LoginGuard]},
  {path: 'login', component: LoginComponent},
  {path: 'logged-out', component: LoggedOutComponent},
  {path: 'communications', component: CommunicationsComponent, canActivate: [LoginGuard]},
  {path: 'communications/:id', component: CommunicationComponent, canActivate: [LoginGuard]},
  {path: 'communication-strategy/:id', component: CommStrategyTabComponent, canActivate: [LoginGuard]},
  {path: 'members', component: MembersComponent, canActivate: [LoginGuard]},
  {path: 'members/:id', component: MemberComponent, canActivate: [LoginGuard]},
  {path: 'sponsors', component: SponsorsComponent, canActivate: [LoginGuard]},
  {path: 'home', component: HomeComponent, canActivate: [LoginGuard]},
  {path: 'profile', component: ProfileComponent, canActivate: [LoginGuard]},
  {path: 'sponsors/:id', component: SponsorComponent, canActivate: [LoginGuard]},
  {path: 'processing-log', component: ProcessingLogsComponent, canActivate: [LoginGuard]},
  {path: 'processing-log/:id', component: ProcessingLogComponent, canActivate: [LoginGuard]},
  {path: 'dashboard', component: DashboardComponent, canActivate: [LoginGuard]},
  {path: 'redemption-dashboard', component: RedemptionDashboardComponent, canActivate: [LoginGuard]},
  {path: 'integrations', component: IntegrationsComponent, canActivate: [LoginGuard]},
  {path: 'programs', component: ProgramsComponent, canActivate: [LoginGuard]},
  {path: 'programs/:id/promotions', component: PromotionsComponent, canActivate: [LoginGuard]},
  {path: 'programs/:id/promotions/:promotionId/promotion-template/:templateId', component: PromotionTemplateBuilderV2Component,
    canActivate: [LoginGuard]},
    {path: 'programs/:id/promotions/:promotionId/promotion-display-template/:templateId', component: DisplayXmlTabComponent,
    canActivate: [LoginGuard]},
    {path: 'programs/:id/promotions/:promotionId/promotion-display-template', component: DisplayXmlTabComponent,
    canActivate: [LoginGuard]},
  {path: 'programs/:id/promotions/:promotionId/promotion-template/:templateId/clone', component: PromotionTemplateBuilderV2Component,
    canActivate: [LoginGuard]},
  {path: 'programs/:id/promotions/:promotionId/promotion-template', component: PromotionTemplateBuilderV2Component, canActivate: [LoginGuard]},
  {path: 'sponsors/:sponsorId/sponsorship/:promoSponsorId/configuration/:cfgId', component: PromotionConfigBuilderV2Component,
    canActivate: [LoginGuard]},
  {path: 'sponsors/:sponsorId/sponsorship/:promoSponsorId/configuration', component: PromotionConfigBuilderV2Component,
    canActivate: [LoginGuard]},
    {path: 'integrations/:id/loads', component: IntegrationStageLoadComponent, canActivate: [LoginGuard]},
  {path: 'integrations/:id/manifest', component: ManifestComponent, canActivate: [LoginGuard]},
  {path: 'integrations/:id/messages', component: IntegrationMessagesComponent, canActivate: [LoginGuard]},
  {path: 'integrations/:integrationId/loads/:loadId', component: IntegrationStageImportComponent, canActivate: [LoginGuard]},
  {path: 'outbounds', component: OutboundComponent, canActivate: [LoginGuard]},
  {path: 'outbounds/:id/loads', component: OutboundStageLoadsComponent, canActivate: [LoginGuard]},
  {path: 'outbounds/:outboundId/loads/:loadId', component: OutboundStageExportsComponent, canActivate: [LoginGuard]},
  {path: 'system', component: SystemComponent, canActivate: [LoginGuard]},
  {path: 'partners', component: PartnersComponent, canActivate: [LoginGuard]},
  {path: 'partners/:id', component: PartnerComponent, canActivate: [LoginGuard]},
  {path: 'reports', component: ReportsComponent, canActivate: [LoginGuard]},
  {path: 'users/:id', component: MemberComponent, canActivate: [LoginGuard]},
  {path: 'users', component: UsersComponent, canActivate: [LoginGuard]},
  {path: 'segments', component: SegmentsComponent, canActivate: [LoginGuard]},
  {path: 'reports/:id', component: ReportComponent, canActivate: [LoginGuard]},
  {path: 'reports/orb/:id', component: OrbComponent, canActivate: [LoginGuard]},
  {path: 'events', component: EventsTabComponent, canActivate: [LoginGuard]},
  {path: 'measures', component: MemberMeasuresTabComponent, canActivate: [LoginGuard]},
  {path: 'interactions', component: InteractionsComponent, canActivate: [LoginGuard]},
  {path: 'sponsors/:id/packaged-sponsorship/:sponsorshipTag', component: PackagedSponsorshipComponent, canActivate: [LoginGuard]},
  {
    matcher: (segments: UrlSegment[], group: UrlSegmentGroup, route: Route) => {
      if (segments[0].path === 'sponsors' && segments[2].path === 'packaged-sponsorship') {
        const sponsorshipTag = segments.slice(3).map((segment: UrlSegment) => segment.path).join('/');
        return {
          consumed: segments,
          posParams: { id: new UrlSegment(segments[1].path, {}), sponsorshipTag: new UrlSegment(sponsorshipTag, {}) }
        };
      }
      return null;
    }, redirectTo: 'sponsors/:id/packaged-sponsorship/:sponsorshipTag'
  },
  {path: 'activity-search', component: ActivitySearchComponent, canActivate: [LoginGuard]},
  {path: 'eventSources', component: EventSourcesTabComponent, canActivate: [LoginGuard]},
  {path: 'forbidden', component: ForbiddenComponent, canActivate: [LoginGuard]},
  {path: 'forgot-password', component:ForgotPasswordComponent},
  {path: 'new-password/:code', component:NewPasswordComponent},
  {path: '', redirectTo: 'home', pathMatch: 'full'},
  {path: 'not-found', component: NotFoundComponent},
  {path: '**', component: NotFoundComponent}
  ];

@NgModule({
  imports: [RouterModule.forRoot(routes, {  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
