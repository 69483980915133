<form #form="ngForm" name="form">
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row custom-form-body">
      <div class="col-md-6">
        <div class="form-group">
          <label [ngClass]="{'has-error-label': !select.selectedType}">Select {{item.type}}</label>
          <ng-select (change)="selection($event)" [items]="item.data" bindLabel="name" [clearable]="false" required
            [(ngModel)]="select.selectedType" [searchFn]="searchByNameAndCode" name="program"
            placeholder="{{item.data.length > 0 ? 'Select...' : 'No programs available'}}">
            <ng-template ng-label-tmp let-item="item">
              <div>{{item.code}}:{{item.name}}</div>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              {{item.code}}:{{item.name}}
            </ng-template>
          </ng-select>
        </div>
        <div *ngIf="select.selectedType">
          <div class="row">
            <hr class="mt-1 mb-2">
          </div>
          <div class="form-group" [hidden]="select.selectedPromotion">
            <label [ngClass]="{'has-error-label': !select.selectedPackage}">Select Package</label>
            <ng-select (change)="packageSelection()" [items]="item.programPackages " bindLabel="packageName"
              [(ngModel)]="select.selectedPackage" name="package" [clearable]="false"
              placeholder="{{ !item.programPackages || item.programPackages.length === 0 ? 'No packages available for selected program' : 'Select...'}}">
            </ng-select>
          </div>
          <div *ngIf="!select.selectedPromotion && !select.selectedPackage" class="text-center">
            <label>OR</label>
          </div>
          <div class="form-group" [hidden]="select.selectedPackage">
            <label [ngClass]="{'has-error-label': !select.selectedPromotion}">Select Promotion(s)</label>
            <ng-select [items]="item.promotions" bindLabel="name" [multiple]="true" [clearable]="false"
              [compareWith]="compareById" [(ngModel)]="select.selectedPromotion" name="promos"
              placeholder="{{ !item.promotions || item.promotions.length === 0 ? 'No promotions available for selected program' : 'Select...'}}"
              [hideSelected]="true" [closeOnSelect]="false">
            </ng-select>
          </div>
        </div>
        <div class="form-group" *ngIf="select.selectedPackage || select.selectedPromotion">
          <div class="row">
            <hr class="mt-1 mb-2">
          </div>
          <div class="form-group">
            <label [ngClass]="{'has-error-label': !select.selectedCampaign}">Select Campaign</label>
            <ng-select [items]="item.campaigns" bindLabel="name" [(ngModel)]="select.selectedCampaign" name="campaign"
              required [clearable]="false"
              placeholder="{{item.campaigns && item.campaigns.length > 0 ? 'Select...' : 'None available for selected program'}}">
            </ng-select>
          </div>
          <div class="form-group">
            <label [ngClass]="{'has-error-label': !item.promoSponsor.sponsorshipTag}">Sponsorship Tag</label>
            <div class="form-group">
              <input required type="text" [(ngModel)]="item.promoSponsor.sponsorshipTag" class="form-control"
                name="sponsorshipTag">
            </div>
          </div>
          <div class="form-group">
            <label [ngClass]="{'has-error-label': !select.selectedCategory}">Promotion Category</label>
            <ng-select placeholder="{{item.categories.length > 0 ? 'Select...' : 'No categories available'}}" required
              [items]="item.categories" [(ngModel)]="select.selectedCategory" bindLabel="name" name="category">
            </ng-select>
          </div>
          <div class="form-group">
            <label [ngClass]="{'has-error-label': !selectedSegments || selectedSegments.length === 0}">Select
              segments</label>
            <ng-select [items]="filteredSegments" bindLabel="code" [(ngModel)]="selectedSegments" [multiple]="true"
              required [closeOnSelect]="false" [hideSelected]="true" name="segments"
              placeholder="{{ filteredSegments.length === 0 ? 'No Segments': 'Search segments...'}}">
            </ng-select>
          </div>
        </div>
        <div *ngIf="(!select.selectedPackage && !select.selectedPromotion) || !select.selectedType">
          <div class="row">
            <hr class="mt-1 mb-2">
          </div>
          <div class="text-center">
            <label>Make a selection to see more options</label>
          </div>
        </div>
      </div>
      <div class="col-md-6 max-height-60vh" *ngIf="select.selectedPackage || select.selectedPromotion">
        <div class="tabs-container">
          <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs">
            <li [ngbNavItem]="1">
              <a ngbNavLink>Selected Promotions</a>
              <ng-template ngbNavContent>
                <div class="height-55vh" *ngIf="!select.selectedPromotion">
                  <div class="form-group">
                    <ul class="list-none pl-0" *ngIf="programStructure">
                      <li *ngFor="let promo of programStructure.promotions">
                        <app-sponsorship-promo-tree [statusHelper]="statusHelper"
                          [selectedPackage]="select.selectedPackage" [programStructure]="programStructure"
                           [promotion]="promo">
                        </app-sponsorship-promo-tree>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="height-55vh" *ngIf="!select.selectedPackage">
                  <div class="form-group">
                    <ul class="list-none pl-0" *ngIf="programStructure">
                      <li *ngFor="let promo of programStructure.promotions">
                        <app-sponsorship-promo-tree [statusHelper]="statusHelper" [programStructure]="programStructure"
                          [(selectedPromotion)]="select.selectedPromotion" 
                          [promotion]="promo">
                        </app-sponsorship-promo-tree>
                      </li>
                      <li *ngIf="programStructure.promotions.length > 100">
                        <div class="alert alert-info" role="alert">
                          <strong>Note:</strong> Due to the number of promotions in this program, only selected
                          promotions
                          will be shown in this view.
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>Default Details</a>
              <ng-template ngbNavContent>
                <div class="padding-top-20px">
                  <div class="row">
                    <div class="form-group col-lg-6">
                      <label>Published Start Date</label>
                      <div class="input-group date">
                        <input class="form-control cursor-pointer" readonly (click)="publishedStartDate.toggle()"
                          placeholder="yyyy-mm-dd" name="publishedStartDate" ngbDatepicker
                          #publishedStartDate="ngbDatepicker" [(ngModel)]="item.promoSponsor.publishedStartDate"
                          [footerTemplate]="footerTemplatePublishedStartDate">
                        <ng-template #footerTemplatePublishedStartDate>
                          <div class="width-100 datePickerTemplate">
                            <button class="buttonDateTemplate"
                              (click)="clearDate('publishedStartDate'); publishedStartDate.toggle()">Clear</button>
                          </div>
                        </ng-template>
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="publishedStartDate.toggle()"
                            type="button">
                            <fa-icon [icon]="faCalendar"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-lg-6">
                      <label>Published End Date</label>
                      <div class="input-group date">
                        <input class="form-control cursor-pointer" readonly (click)="publishedEndDate.toggle()"
                          placeholder="yyyy-mm-dd" name="publishedEndDate" ngbDatepicker
                          #publishedEndDate="ngbDatepicker" [(ngModel)]="item.promoSponsor.publishedEndDate"
                          [footerTemplate]="footerTemplatePublishedEndDate">
                        <ng-template #footerTemplatePublishedEndDate>
                          <div class="width-100 datePickerTemplate">
                            <button class="buttonDateTemplate"
                              (click)="clearDate('publishedEndDate'); publishedEndDate.toggle()">Clear</button>
                          </div>
                        </ng-template>
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="publishedEndDate.toggle()"
                            type="button">
                            <fa-icon [icon]="faCalendar"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-6">
                      <label>Processing Start Date</label>
                      <div class="input-group date">
                        <input class="form-control cursor-pointer" readonly (click)="processingStartDate.toggle()"
                          placeholder="yyyy-mm-dd" name="processingStartDate" ngbDatepicker
                          #processingStartDate="ngbDatepicker" [(ngModel)]="item.promoSponsor.processingStartDate"
                          [footerTemplate]="footerTemplateProcessingStartDate">
                        <ng-template #footerTemplateProcessingStartDate>
                          <div class="width-100 datePickerTemplate">
                            <button class="buttonDateTemplate"
                              (click)="clearDate('processingStartDate'); processingStartDate.toggle()">Clear</button>
                          </div>
                        </ng-template>
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="processingStartDate.toggle()"
                            type="button">
                            <fa-icon [icon]="faCalendar"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-lg-6">
                      <label>Processing End Date</label>
                      <div class="input-group date">
                        <input class="form-control cursor-pointer" readonly (click)="processingEndDate.toggle()"
                          placeholder="yyyy-mm-dd" name="processingEndDate" ngbDatepicker
                          #processingEndDate="ngbDatepicker" [(ngModel)]="item.promoSponsor.processingEndDate"
                          [footerTemplate]="footerTemplateProcessingEndDate">
                        <ng-template #footerTemplateProcessingEndDate>
                          <div class="width-100 datePickerTemplate">
                            <button class="buttonDateTemplate"
                              (click)="clearDate('processingEndDate'); processingEndDate.toggle()">Clear</button>
                          </div>
                        </ng-template>
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="processingEndDate.toggle()"
                            type="button">
                            <fa-icon [icon]="faCalendar"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="form-group col-lg-6">
                      <label>Score Start Date</label>
                      <div class="input-group date">
                        <input class="form-control cursor-pointer" readonly (click)="scoreStartDate.toggle()"
                          placeholder="yyyy-mm-dd" name="scoreStartDate" ngbDatepicker #scoreStartDate="ngbDatepicker"
                          [(ngModel)]="item.promoSponsor.scoreStartDate"
                          [footerTemplate]="footerTemplateScoreStartDate">
                        <ng-template #footerTemplateScoreStartDate>
                          <div class="width-100 datePickerTemplate">
                            <button class="buttonDateTemplate"
                              (click)="clearDate('scoreStartDate'); scoreStartDate.toggle()">Clear</button>
                          </div>
                        </ng-template>
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="scoreStartDate.toggle()"
                            type="button">
                            <fa-icon [icon]="faCalendar"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-lg-6">
                      <label>Score End Date</label>
                      <div class="input-group date">
                        <input class="form-control" placeholder="yyyy-mm-dd" name="scoreEndDate" ngbDatepicker
                          #scoreEndDate="ngbDatepicker" readonly (click)="scoreEndDate.toggle()"
                          [(ngModel)]="item.promoSponsor.scoreEndDate" [footerTemplate]="footerTemplateScoreEndDate">
                        <ng-template #footerTemplateScoreEndDate>
                          <div class="width-100 datePickerTemplate">
                            <button class="buttonDateTemplate"
                              (click)="clearDate('scoreEndDate'); scoreEndDate.toggle()">Clear</button>
                          </div>
                        </ng-template>
                        <div class="input-group-append">
                          <button class="btn btn-outline-secondary calendar" (click)="scoreEndDate.toggle()"
                            type="button">
                            <fa-icon [icon]="faCalendar"></fa-icon>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="alert alert-primary" role="alert">
                  <strong>Note:</strong> These dates will be applied to each {{select.selectedPromotion ? 'selected promotion.' : 'promotion within the selected package.'}} If needed, they can be overridden at the promotion sponsorship level.
                </div>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
      </div>
      <div class="col-md-6 vertical-align-container filler-gears"
        [hidden]="select.selectedPackage || select.selectedPromotion">
        <fa-icon [icon]="faCogs"></fa-icon>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit"
      [disabled]="hasErrors || (!select.selectedPackage && !select.selectedPromotion) || (select.selectedPackage && !packageHasPromos)"
      (click)="ok()" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>