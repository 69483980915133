import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { SponsorsService } from 'src/app/services/sponsors.service';
import { CommunicationReminder } from './../data/model';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges } from '@angular/core';
import { MessagesPerPeriod, PromotionDays, PromotionSponsorReminder } from '../data/model';
import { faTimes, faSave } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-promotion-sponsor-reminder-modal',
  templateUrl: './promotion-sponsor-reminder-modal.component.html',
  styleUrls: ['./promotion-sponsor-reminder-modal.component.scss']
})
export class PromotionSponsorReminderModalComponent implements OnInit {

  @Input() item: PromotionSponsorReminder;
  @Input() promotionSponsorshipId: number;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string;
  errorMsg = '';
  promotionStart = ['Score Start Date', 'Published Start Date'];
  periods = ['Campaign', 'Year', 'Quarter', 'Month', 'Week', 'Day', 'Plan Year'];

  faTimes = faTimes;
  faSave = faSave;

  constructor(
    private sponsorsService: SponsorsService,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal
  ) { }

  ngOnInit(): void {
    if (this.item) {
      this.title = 'Edit Reminder';
      if (this.item.communicationReminder) {
        if (!this.item.communicationReminder.daysAfterPromotionStart) {
          this.item.communicationReminder.daysAfterPromotionStart = new PromotionDays();
        }
        if (!this.item.communicationReminder.daysBeforePromotionExpires) {
          this.item.communicationReminder.daysBeforePromotionExpires = new PromotionDays();
        }
        if (!this.item.communicationReminder.noEligibleActivityForPeriod) {
          this.item.communicationReminder.noEligibleActivityForPeriod = new PromotionDays();
        }
        if (!this.item.communicationReminder.multipleMessagePerPeriod) {
          this.item.communicationReminder.multipleMessagePerPeriod = new MessagesPerPeriod();
        }
      } else {
        this.item.communicationReminder = new CommunicationReminder();
      }
    } else {
      this.item = new PromotionSponsorReminder();
      this.title = 'Add New Reminder';
    }
    this.item.promotionSponsorId = this.promotionSponsorshipId;
  }

  ok(): void {
    this.sponsorsService.upsertSponsorshipReminders(this.promotionSponsorshipId, this.item.id, this.item).subscribe((data: any) => {
      if (data.success) {
        this.responseHelperService.success('Reminder successfully updated', true);
        this.successEvent.emit();
      }
    }, (data: any) => {
      this.responseHelperService.error(this, data.errorMsg);
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
