<form [formGroup]="memberForm">
    <div class="modal-header">
        <h1>{{title}}</h1>
    </div>
    <div class="modal-body">
        <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
            {{errorMsg}}
            <a (click)="clearErrorMessage()">
                <span class="float-right close-button">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </span>
            </a>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>First Name</label>
                    <input type="text" class="form-control" formControlName="firstName">
                    <div class="mt-sm" [hidden]="memberForm.controls.firstName.valid">
                        <small class="text-danger">Input a First Name</small>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Last Name</label>
                    <input type="text" class="form-control" formControlName="lastName">
                    <div class="mt-sm" [hidden]="memberForm.controls.lastName.valid">
                        <small class="text-danger">Input Last Name</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>Middle Initial</label>
                    <input type="text" class="form-control" formControlName="middleInitial">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Gender</label>
                    <ng-select [items]="genders" bindLabel="name" [clearable]="false" formControlName="gender"
                        [searchable]="false">
                    </ng-select>
                    <div class="mt-sm" [hidden]="memberForm.controls.gender.valid">
                        <small class="text-danger">Select gender</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>Dob</label>
                    <div class="input-group date">
                        <input class="form-control cursor-pointer" (click)="dateOfBirth.toggle()"
                            placeholder="yyyy-mm-dd" name="dateOfBirth" ngbDatepicker
                            [footerTemplate]="footerTemplatedateOfBirth" #dateOfBirth="ngbDatepicker"
                            formControlName="dateOfBirth">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="dateOfBirth.toggle()"
                                type="button">
                                <fa-icon [icon]="faCalendar"></fa-icon>
                            </button>
                        </div>
                        <ng-template #footerTemplatedateOfBirth>
                            <div class="width-100 datePickerTemplate">
                                <button class="buttonDateTemplate"
                                    (click)="clearDateInput(); dateOfBirth.toggle()">Clear</button>
                            </div>
                        </ng-template>
                    </div>
                    <div class="mt-sm" [hidden]="memberForm.controls.dateOfBirth.valid">
                        <small class="text-danger">Select date of birth</small>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Segments</label>
                    <app-segment-filter (filterSegments)="segments= $event" maxWidth = "true"  ngbDropdownClass="w-100" class="w-100" [personId]="personId"></app-segment-filter>
                </div>
            </div>
        </div>
        <div formArrayName="activityInstruments">
            <div class="row mb-2">
                <div class="col-md-5">
                    <label>Instrument Type</label>
                </div>
                <div class="col-md-5">
                    <label>Instrument Number</label>
                </div>
                <div class="col-md-2">
                    <button class="btn btn-dark-blue btn-sm float-right" (click)="addInstrument()" title="Add instrument">
                        <fa-icon [icon]="faPlus"></fa-icon>
                    </button>
                </div>
            </div>
            <div class="row" *ngFor="let instrument of getInstruments().controls; let i=index" [formGroupName]="i">
                <div class="col-md-5">
                    <div class="form-group">
                        <ng-select [items]="instrumentTypes" bindLabel="code"  [clearable]="false"
                            formControlName="instrumentType">
                        </ng-select>
                        <div class="mt-sm" [hidden]="instrument.controls.instrumentType.valid">
                            <small class="text-danger">Select instrument type</small>
                        </div>
                    </div>
                </div>
                <div class="col-md-5">
                    <input type="text" class="form-control" formControlName="instrumentNumber">
                    <div class="mt-sm" [hidden]="instrument.controls.instrumentNumber.valid">
                        <small class="text-danger">Input instrument number</small>
                    </div>
                </div>
                <div class="col-md-2">
                    <input type="number" [hidden]="true" name="optionValue" class="form-control" formControlName="id" />
                    <div *ngIf="getInstruments().controls.length > 1" class="form-group float-right">
                        <button class="btn btn-dark-blue btn-sm delete float-right" (click)="removeInstrument(i)">
                            <fa-icon [icon]="faTrash"></fa-icon>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-dark-blue" type="submit" [disabled]="!memberForm.valid" (click)="ok()" title="Save">
            <fa-icon [icon]="faSave"></fa-icon>
        </button>
        <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
            <fa-icon [icon]="faTimes"></fa-icon>
        </button>
    </div>
</form>
