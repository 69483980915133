<form [formGroup]="userForm">
    <div class="modal-header">
        <h1>{{title}}</h1>
    </div>
    <div class="modal-body">
        <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
            {{errorMsg}}
            <a (click)="clearErrorMessage()">
                <span class="float-right close-button">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </span>
            </a>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>First Name</label>
                    <input type="text" class="form-control" formControlName="firstName">
                    <div class="mt-sm" [hidden]="userForm.controls.firstName.valid">
                        <small class="text-danger">Input a First Name</small>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Last Name</label>
                    <input type="text" class="form-control" formControlName="lastName">
                    <div class="mt-sm" [hidden]="userForm.controls.lastName.valid">
                        <small class="text-danger">Input Last Name</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>Username</label>
                    <input type="text" class="form-control" formControlName="username" (blur)="onBlur($event)" appTrim>
                    <div class="mt-sm" [hidden]="!userForm.controls.username.errors?.required">
                        <small class="text-danger">Input Username</small>
                    </div>
                    <div class="mt-sm" [hidden]="!userForm.controls.username.errors?.minlength">
                        <small class="text-danger">Username is required to be at least 3 characters</small>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Status</label>
                    <select formControlName="status" class="form-control">
                        <option *ngFor="let option of statuses" value="{{option}}">{{option}}</option>
                    </select>
                    <div class="mt-sm" [hidden]="userForm.controls.status.valid">
                        <small class="text-danger">Select status</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="row" *ngIf="!isEdit">
            <div class="col-6">
                <div class="form-group">
                    <label>Password</label>
                    <input type="password" autocomplete="new-password" class="form-control" formControlName="password">
                    <div class="mt-1" [hidden]="!userForm.controls.password.errors?.passwordValidation">
                        <small class="text-danger">{{userForm.controls.password.errors?.passwordValidation}}</small>
                    </div>
                    <div class="mt-1" [hidden]="!userForm.controls.password.errors?.required">
                        <small class="text-danger">Input password</small>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Repeat Password</label>
                    <input type="password" class="form-control" formControlName="repeatPassword">
                    <div class="mt-1" [hidden]="!userForm.controls.repeatPassword.errors?.passwordValidation">
                        <small
                            class="text-danger">{{userForm.controls.repeatPassword.errors?.passwordValidation}}</small>
                    </div>
                    <div class="mt-1"
                        [hidden]="userForm.controls.repeatPassword.errors?.passwordValidation || userForm.controls.repeatPassword.value === userForm.controls.password.value">
                        <small class="text-danger">Passwords do not match</small>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isEdit" class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>Select Role</label>
                    <ng-select [compareWith]="compareFn" [items]="roles" bindLabel="roleName"
                        formControlName="accessRoleCollection" [clearable]="false">
                    </ng-select>
                    <div class="mt-1" [hidden]="userForm.controls.accessRoleCollection.valid">
                        <small class="text-danger">Select role</small>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Email</label>
                    <input type="email" class="form-control" formControlName="email">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>Dob</label>
                    <div class="input-group date">
                        <input class="form-control cursor-pointer" readonly [minDate]="minDate" (click)="dateOfBirth.toggle()" placeholder="yyyy-mm-dd" name="dateOfBirth" ngbDatepicker [footerTemplate]="footerTemplatedateOfBirth"
                            #dateOfBirth="ngbDatepicker" formControlName="dateOfBirth">
                        <div class="input-group-append">
                            <button class="btn btn-outline-secondary calendar" (click)="dateOfBirth.toggle()"
                                type="button">
                                <fa-icon [icon]="faCalendar"></fa-icon>
                            </button>
                        </div>
                        <ng-template #footerTemplatedateOfBirth>
                            <div class="width-100 datePickerTemplate">
                            <button class="buttonDateTemplate"  (click)="clearDateInput(); dateOfBirth.toggle()">Clear</button>
                            </div>
                          </ng-template>
                    </div>
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>Gender</label>
                    <select [compareWith]="compareFn" formControlName="gender" class="form-control">
                        <option *ngFor="let option of genders" [ngValue]="option">{{option.name}}
                        </option>
                    </select>
                    <div class="mt-sm" [hidden]="userForm.controls.gender.valid">
                        <small class="text-danger">Select credential type</small>
                    </div>
                </div>
            </div>
        </div>
        <div *ngIf="!isEdit" class="row">
            <div class="col-sm-12">
                <div class="form-group">
                    <label>Select segments</label>
                    <ng-select [items]="filteredSegments" bindLabel="code" [multiple]="true"
                        formControlName="segmentsCollection" [hideSelected]="true" [closeOnSelect]="false" [clearable]="false">
                    </ng-select>
                    <div class="mt-1" [hidden]="userForm.controls.segmentsCollection.valid">
                        <small class="text-danger">Select segment</small>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label>Notes</label>
            <textarea formControlName="notes" type="text" class="form-control vertical-expand"></textarea>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-dark-blue" type="submit"
            [disabled]="!userForm.valid ||(!isEdit && userForm.controls.repeatPassword.value !== userForm.controls.password.value)"
            (click)="ok()" title="Save">
            <fa-icon [icon]="faSave"></fa-icon>
        </button>
        <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
            <fa-icon [icon]="faTimes"></fa-icon>
        </button>
    </div>
</form>
