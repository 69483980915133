<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-sm-12">
      <div class="ibox">
        <div class="ibox-title">
          <h2>Stage exports</h2>
        </div>
        <div class="ibox-content">
          <div class="row">
            <div class="col-md-12">
              <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
              <div class="row">
                <app-add-more-data-to-list (onScroll)="getStageExport(true)" [lock]="lock" [isLoading]="isLoading">
                </app-add-more-data-to-list>
                <table class="table table-pointable table-bordered infinite-table" width="100%">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Status</th>
                      <th>Created On</th>
                      <th>Export Date</th>
                      <th class="width-70">Raw Data</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let se of stageExport" (click)="showStageExport($event, se)">
                      <td>{{se.id}}</td>
                      <td>{{se.status}}</td>
                      <td>{{se.createdOn | date: 'yyyy-MM-dd HH:mm'}}</td>
                      <td>{{se.exportDate | date: 'yyyy-MM-dd HH:mm'}}</td>
                      <td>{{se.rawData}}</td>
                    </tr>
                    <tr app-is-there-more [numColumns]="5" [newLengthEvent]="newLengthEvent"></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>