<div>
  <form>
    <label>Compare the activity date to an activity attribute value:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="activityAttributeIntervalRestriction !== null">
        <div *ngFor="let activityAttributeInterval of activityAttributeIntervalRestriction; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeRestriction(partyAttributeInterval)" title="Remove Restriction">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="attribName_{{activityAttributeInterval.attrName}}">Attribute Name</label>
              <input class="form-control m-b" type="text" id="attribName_{{activityAttributeInterval.attrName}}" name="attribName" [(ngModel)]="activityAttributeInterval.attrName" (ngModelChange)="updateRestrictions()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="attribValFormat_{{activityAttributeInterval.attrName}}">Value Date Format</label>
              <input class="form-control m-b" type="text" id="attribValFormat_{{activityAttributeInterval.attrName}}" name="attribValFormat" [(ngModel)]="activityAttributeInterval.valueDateFormat" (ngModelChange)="updateRestrictions()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="attribIntLength_{{activityAttributeInterval.attrName}}">Interval Length</label>
              <input class="form-control m-b" type="number" id="attribIntLength_{{activityAttributeInterval.attrName}}" name="attribIntLength" [(ngModel)]="activityAttributeInterval.intervalLength" (ngModelChange)="updateRestrictions()" />
            </div>
            <div class="form-group">
              <label>Interval Unit</label>
              <select name="unit_{{activityAttributeInterval.attrName}}" class="form-control" [(ngModel)]="activityAttributeInterval.intervalUnit" (ngModelChange)="updateRestrictions()">
                <option *ngFor="let unit of units" [selected]="activityAttributeInterval.intervalUnit === unit.value" [value]="unit.value">{{unit.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label>Condition</label>
              <select name="condition_{{activityAttributeInterval.attrName}}" class="form-control" [(ngModel)]="activityAttributeInterval.condition" (ngModelChange)="updateRestrictions()">
                <option *ngFor="let condition of conditions" [selected]="activityAttributeInterval.condition === condition.value" [value]="condition.value">{{condition.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label>Inclusion</label>
              <select name="inclusion_{{activityAttributeInterval.attrName}}" class="form-control" [(ngModel)]="activityAttributeInterval.inclusion" (ngModelChange)="updateRestrictions()">
                <option *ngFor="let inclusion of inclusionValues" [selected]="activityAttributeInterval.inclusion === inclusion.value" [value]="inclusion.value">{{inclusion.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="friendlyMessage_{{activityAttributeInterval.attrName}}">Message:</label>
              <input class="form-control m-b" type="text" id="friendlyMessage_{{activityAttributeInterval.attrName}}" name="friendlyMessage" [(ngModel)]="activityAttributeInterval.friendlyMessage" (ngModelChange)="updateRestrictions()" />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initRestrictionObject()"><fa-icon [icon]="faPlus"></fa-icon> Add Activity Attribute Interval</button>
      </div>
    </div>
  </form>
</div>
