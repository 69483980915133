import { Component, OnInit, Input } from '@angular/core';
import { faLongArrowAltDown, faLongArrowAltUp, faArrowsAltV } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-sort-arrows',
  templateUrl: './sort-arrows.component.html',
  styleUrls: ['./sort-arrows.component.scss']
})
export class SortArrowsComponent implements OnInit {
  faArrowsAltV = faArrowsAltV;
  faLongArrowAltDown = faLongArrowAltDown;
  faLongArrowAltUp = faLongArrowAltUp;
  @Input() public dir: string;
  @Input() public column: number;
  @Input() public selectedColumn: number;

  constructor() { }

  ngOnInit(): void {
  }

}
