
  <div class="modal-header">
    <h2>Upload File</h2>
  </div>
  <div class="modal-body">
    <ngx-dropzone (change)="onSelect($event)" [expandable]="true" [multiple] [maxFileSize]="maxFileSize">
      <ngx-dropzone-label>Click or drag file here to upload</ngx-dropzone-label>
      <ngx-dropzone-preview *ngFor="let f of files" [removable]="true" (removed)="onRemove(f)">
        <ngx-dropzone-label>{{ f.name }} ({{ f.type }})</ngx-dropzone-label>
      </ngx-dropzone-preview>
    </ngx-dropzone>
    <br>
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
  </div>
  <div class="modal-footer">
    <button [disabled]="!valid" class="btn btn-dark-blue" type="submit" (click)="upload()" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="closeModal()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>