import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent implements OnInit {
  @Input() okButton: string;
  @Input() cancelButton: string;
  @Input() text: string;
  @Input() header: string;
  @Input() closeOnCancel = true;
  @Input() dismissAllOnCancel: boolean = true;
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Output() public cancelEvent: EventEmitter<any> = new EventEmitter();
  constructor(private modalHelper: NgbModal) { }

  ngOnInit(): void {
  }

  cancel(): void {
    if (this.closeOnCancel && this.dismissAllOnCancel) {
      this.modalHelper.dismissAll();
    } else if (!this.dismissAllOnCancel){
      this.cancelEvent.emit();
    }
    else {
      this.successEvent.emit('cancel');
    }
  }

  ok(): void {
    this.successEvent.emit('success');
  }

}
