import { AppDataService } from './../app-data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(private http: HttpClient, private globalData: AppDataService) { }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getAllReports(params?: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'reports/', this.setParams(params));
  }

  getReport(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'reports/' + id);
  }

  generateReport(id: number, params?: any): any {
    const paramsToSend = params && (!params.length || (params.length && params.length > 0)) ? this.setParams(params) : {};
    return this.http.post<any>(this.globalData.baseUrl + 'reports/generate/' + id, paramsToSend);
  }

  getReportParams(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'reports/params/' + id);
  }

  getReportArrayParamsData(paramType: any): any {
    const params: any = {};
    params.type = paramType;
    return this.http.get<any>(this.globalData.baseUrl + 'reports/array-params', this.setParams(params));
  }

  getReportSegmentsTree(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'reports/' + id + '/segments/tree');
  }

  getReportSegments(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'reports/' + id + '/segments');
  }

  addSegmentsToReport(id: number, data: any): any {
    return this.http.post<any>(this.globalData.baseUrl + 'reports/' + id + '/segments', data);
  }

  removeReportSegments(sponsorId: number, segmentId: number): any {
    return this.http.delete<any>(this.globalData.baseUrl + 'reports/' + sponsorId + '/segments/' + segmentId);
  }

  createReport(data: any): any {
    return this.http.post<any>(this.globalData.baseUrl + 'reports', data);
  }

  getReportResults(params: any): any {
    return this.http.get(this.globalData.baseUrl + 'reports/results', this.setParams(params));
  }

  getReportExecutionParams(executionId: number): any {
    return this.http.get(this.globalData.baseUrl + 'reports/execution/params/' + executionId);
  }

  removeParam(id: number, paramId: number): any {
    return this.http.delete(this.globalData.baseUrl + 'reports/query-params/' + id + '/' + paramId);
  }

  updateParam(reportId: number, param: any): any {
    return this.http.put(this.globalData.baseUrl + 'reports/query-params/' + reportId + '/' + param.id, param);
  }

  updateAttributes(id: number, data: any): any {
    return this.http.post(this.globalData.baseUrl + 'reports/attribute-params/' + id, data);
  }

  addParam(id: number, data: any): any {
    return this.http.post<any>(this.globalData.baseUrl + 'reports/query-params/' + id, data);
  }

  updateDetails(id: number, data: any): any {
    return this.http.put(this.globalData.baseUrl + 'reports/' + id, data);
  }

  generateCSV(id: number, params: any): any {
    return this.http.post<any>(this.globalData.baseUrl + 'reports/generate/' + id, this.setParams(params));
  }

  triggerExternalExecution(id: number, params: any): any {
    return this.http.post<any>(this.globalData.baseUrl + 'reports/external-exec/' + id, params);
  }
}
