import { Filter, SearchFilters } from './../../data/class';
import { TableHelperService } from './../../services/helpers/table-helper.service';
import { IntegrationsService } from './../../services/integrations.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { faCalendar, faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-integration-messages',
  templateUrl: './integration-messages.component.html',
  styleUrls: ['./integration-messages.component.scss']
})
export class IntegrationMessagesComponent implements OnInit {

  selectedIntegrationId: number;
  selectedIntegrationName: string;
  stageLoadId: number;
  lockedTables = false;
  lock = false;
  filter = new Filter();
  limit = 20;
  integrationMessages: any;
  lengthToCompare: number;
  fromDateValue: any;
  toDateValue: any;
  isLoading = false;
  faCalendar = faCalendar;
  faTimes = faTimes;
  subject: Subject<any> = new Subject<any>();
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  searchFilters: SearchFilters;

  constructor(
    private titleHelper: Title,
    private route: ActivatedRoute,
    private router: Router,
    private integrationsService: IntegrationsService,
    private tableHelperService: TableHelperService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'processing-logs-search',
      filters: [
        {
          name: 'stageLoadId',
          placeholder: 'File Load ID...',
        },
        {
          name: 'from',
          type: 'date',
          placeholder: 'From Date',
        },
        {
          name: 'thru',
          type: 'date',
          placeholder: 'To Date',
        },
      ],
      searchCallback: () => { this.initNewSearch(); },
    };
    this.titleHelper.setTitle('Integration Messages');
  }

  initNewSearch(): void {
    const id = parseInt(this.route.snapshot.paramMap.get('id'), 10);
    if (id) {
      this.getIntegration(id);
    }

    this.initFilter();

    this.tableHelperService.showTitle();

    this.subject.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(item => {
        if (item.value !== '') {
          this.filter[item.name] = item.value;
        } else {
          delete this.filter[item.name];
        }
        this.initFilter();
        this.getIntegrationMessages(false);
      });
  }

  getIntegration(id: number): void {
    this.integrationsService.getIntegration(id, null).subscribe((data: any) => {
      if (data.success) {
        this.selectedIntegrationId = data.entity.id;
        this.selectedIntegrationName = data.entity.name;
        this.getIntegrationMessages(false);
      }
    }, () => {
      this.router.navigate(['integrations']);
    });
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getIntegrationMessages(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.integrationsService.getIntegrationMessages(this.selectedIntegrationId, this.filter).subscribe((data: any) => {
      if (concat) {
        this.integrationMessages = this.integrationMessages.concat(data.entity.aaData);
      } else {
        this.integrationMessages = data.entity.aaData;
      }
      this.lengthToCompare = data.entity.aaData.length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables = data.entity.aaData.length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
    }, (data: any) => {
      this.lockedTables = false;
      this.lock = false;
      this.toastr.error('Error occured!');
      this.isLoading = false;
    });
  }
}
