<form [formGroup]="programForm" novalidate>
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <label>Name</label>
      <input type="text" class="form-control" formControlName="name" autofocus>
      <div class="m-t-xs" [hidden]="programForm.controls['name'].valid">
        <small class="text-danger">Input a Name</small>
      </div>
    </div>
    <div class="form-group">
      <label>Code</label>
      <input type="text" class="form-control" formControlName="code">
      <div class="m-t-xs" [hidden]="programForm.controls['code'].valid">
        <small class="text-danger">Input a Code</small>
      </div>
    </div>

    <div class="form-group">
      <label>Status</label>
      <select formControlName="status" class="form-control" formControlName="status">
        <option *ngFor="let option of statuses" value="{{option}}">{{option}}</option>
      </select>
      <div class="m-t-xs" [hidden]="programForm.controls['status'].valid">
        <small class="text-danger">Select status</small>
      </div>
    </div>
    <div class="form-group">
      <label>Description</label>
      <textarea formControlName="description" type="text" class="form-control vertical-expand"></textarea>
    </div>

    <div class="form-group">
      <label>Icon</label>
      <div class="input-group">
        <input class="form-control" formControlName="imageUrl" />
        <span  class="input-group-addon" placement="right"
          #popOver="ngbPopover" [ngbPopover]="popContent" [popoverTitle]="popTitle" [autoClose]="'outside'">
          <fa-icon *ngIf="icon"  [icon]="icon"></fa-icon>
        </span>
      </div>
    </div>

    <div class="modal-footer">
      <button class="btn btn-dark-blue" type="submit" [disabled]="!programForm.valid" (click)="ok()" title="Save">
        <fa-icon [icon]="faSave"></fa-icon>
      </button>
      <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
        <fa-icon [icon]="faTimes"></fa-icon>
      </button>
    </div>
  </div>
</form>

<ng-template #popTitle><input class="form-control" placeholder="Type to filter"
   (click)="$event.preventDefault()" (keyup)="filterIcons($event.target.value)"></ng-template>

<ng-template #popContent>
  <table class="icon-picker-table">
    <tr *ngFor="let iconKey of everyThirdIconArray; let i = index;">
      <td>
        <button (click)="selectIcon(filterIconKeys[3*i])" type="button" [title]="filterIconKeys[3*i]"
          class="btn btn-light btn-sm">
          <fa-icon class="fa-2x" [icon]="allIcons[filterIconKeys[3*i]]"></fa-icon>
        </button>
      </td>
      <td>
        <button (click)="selectIcon(filterIconKeys[3*i+1])" type="button" [title]="filterIconKeys[3*i+1]"
          class="btn btn-light btn-sm">
          <fa-icon class="fa-2x" [icon]="allIcons[filterIconKeys[3*i+1]]"></fa-icon>
        </button>
      </td>
      <td>
        <button (click)="selectIcon(filterIconKeys[3*i+2])" type="button" [title]="filterIconKeys[3*i+2]"
          class="btn btn-light btn-sm">
          <fa-icon class="fa-2x"  [icon]="allIcons[filterIconKeys[3*i+2]]"></fa-icon>
        </button>
      </td>
    </tr>
    <tr *ngIf="filterIconKeys.length % 3 === 1">
      <td>
        <button (click)="selectIcon(filterIconKeys[filterIconKeys.length-1])" type="button"
          [title]="filterIconKeys[filterIconKeys.length-1]" class="btn btn-sm btn-light">
          <fa-icon class="fa-2x"  [icon]="allIcons[filterIconKeys[filterIconKeys.length-1]]"></fa-icon>
        </button>
      </td>
    </tr>
    <tr *ngIf="filterIconKeys.length % 3 === 2">
      <td>
        <button (click)="selectIcon(filterIconKeys[filterIconKeys.length-2])" type="button"
          [title]="filterIconKeys[filterIconKeys.length-2]" class="btn btn-sm btn-light">
          <fa-icon class="fa-2x"  [icon]="allIcons[filterIconKeys[filterIconKeys.length-2]]"></fa-icon>
        </button>
      </td>
      <td>
        <button (click)="selectIcon(filterIconKeys[filterIconKeys.length-1])" type="button"
          [title]="filterIconKeys[filterIconKeys.length-1]" class="btn btn-sm btn-light">
          <fa-icon class="fa-2x"  [icon]="allIcons[filterIconKeys[filterIconKeys.length-1]]"></fa-icon>
        </button>
      </td>
    </tr>
  </table>
</ng-template>