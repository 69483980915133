import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { Filter, SearchFilters } from 'src/app/data/class';
import { AppDataService } from 'src/app/app-data.service';
import { PersonsService } from 'src/app/services/persons.service';
import { PromotionService } from 'src/app/services/promotion.service';
import { ToastrService } from 'ngx-toastr';
import { Attribute } from 'src/app/data/model';
import { CommunicationAttributeModalComponent } from 'src/app/communication-tabs/communication-attribute-modal/communication-attribute-modal.component';
import {
  faChevronDown,
  faChevronRight,
  faInfo,
  faPencilAlt,
  faStopwatch,
  faTrash,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { CommunicationsService } from 'src/app/services/communications.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';
import { MoreDetailsModalComponent } from 'src/app/more-details-modal/more-details-modal.component';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { SecurityService } from 'src/app/services/security/security.service';
import { UserPreferencesService } from 'src/app/services/user-preferences.service';
import { AttributesDetailsModalComponent } from '../attributes-details-modal/attributes-details-modal.component';

@Component({
  selector: 'app-party-attributes-tab',
  templateUrl: './party-attributes-tab.component.html',
  styleUrls: ['./party-attributes-tab.component.scss'],
  providers: [DatePipe],
})
export class PartyAttributesTabComponent implements OnInit, OnChanges {
  @Input() resource: any;
  @Input() personId: number;
  @Input() hideInfoButton: boolean;
  @Input() hideExpireButton: boolean;
  searchFilters: SearchFilters;
  selectedAttr: Attribute;
  attrs: Attribute[];
  attribProv: Attribute[];
  filter = new Filter();
  faChevronDown = faChevronDown;
  faChevronRight = faChevronRight;
  faInfo = faInfo;
  faPencilAlt = faPencilAlt;
  faStopwatch = faStopwatch;
  faTrash = faTrash;
  expandedLookup = [];
  disableActions: string;
  allowedToEditAttribs: boolean;
  allowedToAddAttribs: boolean;
  currentDate: any;

  constructor(
    public globalData: AppDataService,
    public appDataService: AppDataService,
    private communicationsService: CommunicationsService,
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal,
    private personsService: PersonsService,
    private promotionService: PromotionService,
    private securityService: SecurityService,
    private toastr: ToastrService,
    private userPreferencesService: UserPreferencesService,
    private datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.allowedToEditAttribs = this.securityService.allowedToEditAttribs;
    this.allowedToAddAttribs = this.securityService.allowedToAddAttribs;
    this.currentDate = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    this.attrs = [];
    this.searchFilters = {
      formName: 'generic',
      buttonsWithText: true,
      showSearchFilters: true,
      filters: [
        {
          name: 'effectiveDate',
          type: 'date',
          placeholder: 'As Of Date',
          label: 'Display attributes as of',
        },
      ],
      searchCallback: () => {
        this.setAttributes();
      },
      addEntityButtons: [
        {
          icon: faPlus,
          title: 'Add Attribute',
          buttonText: 'Add Attribute',
          callback: () => {
            this.prepareAttributeModal();
          },
          if: this.appDataService.isProgramManager || this.allowedToAddAttribs,
        },
      ],
    };
  }

  ngOnChanges(changes: SimpleChanges): void {}

  setAttributes(): void {
    if (this.filter) {
      this.filter = this.searchFilters.getFilters(this.filter);
      this.disableActions = this.filter.effectiveDate;
    }
    this.personsService
      .getPartyAttributeHistory(this.personId, this.filter)
      .subscribe((attrs: any) => {
        if (attrs.success) {
          this.attrs = attrs.entity;
          this.setDates(this.attrs);
          this.attrs = this.attrs.sort(
            (a: any, b: any) =>
              new Date(b.effectiveFrom).getTime() -
              new Date(a.effectiveFrom).getTime()
          );
        }
      });
  }

  prepareAttributeModal(attribute?: Attribute): void {
    this.selectedAttr = null;
    this.partyAttrModal(attribute);
  }

  partyAttrModal(attr: Attribute): void {
    this.selectedAttr = attr;
    this.openAttributeModal();
  }

  openAttributeModal(): void {
    const instance = this.modalHelper.open(
      CommunicationAttributeModalComponent
    );
    instance.componentInstance.attr = this.selectedAttr;
    instance.componentInstance.personId = this.personId;
    instance.componentInstance.resourceType = 'party';
    instance.componentInstance.successEvent.subscribe(() => {
      this.setAttributes();
      instance.close();
    });
  }

  showDetails(data: any): void {
    const name = encodeURIComponent(data.attrName);
    this.personsService
      .getAttributeProvenance(this.personId, name)
      .subscribe((attribProvs: any) => {
        if (attribProvs.success) {
          this.attribProv = attribProvs.entity;
          this.setDates(this.attribProv);
          const instance = this.modalHelper.open(
            AttributesDetailsModalComponent,
            { size: 'xl' }
          );
          instance.componentInstance.attributes = this.attribProv;
          instance.componentInstance.personId = this.personId;
          instance.componentInstance.successEvent.subscribe(() => {
            this.setAttributes();
          });
        }
      });
  }

  getStatus(attribute: Attribute): any {
    if (attribute.effectiveTo) {
      const dateToCompare =
        this.filter && this.filter.effectiveDate
          ? new Date(this.filter.effectiveDate)
          : new Date();
      const effectiveTo = new Date(attribute.effectiveTo);
      dateToCompare.setHours(0, 0, 0, 0);
      effectiveTo.setHours(0, 0, 0, 0);
      if (dateToCompare >= effectiveTo) {
        return 'EXPIRED';
      } else {
        return 'ACTIVE';
      }
    } else {
      return 'ACTIVE';
    }
  }

  expireAttribute(attr: Attribute): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, {
      size: 'sm',
    });
    instance.componentInstance.text =
      'Expire attribute with name: ' + attr.attrName + '?';
    instance.componentInstance.header = 'Expire Attribute';
    instance.componentInstance.successEvent.subscribe(() => {
      const date = new Date();
      attr.effectiveTo = date.toUTCString();
      attr.effectiveFrom = date.toUTCString();
      attr.commandType = 'CREATE';
      this.personsService
        .createOrUpdateAttribute(attr.attrValueId, attr)
        .subscribe(
          () => {
            this.responseHelperService.success(
              'Attribute successfully expired',
              true
            );
            this.setAttributes();
          },
          (data: any) => {
            this.responseHelperService.error(this, data.error.error);
          }
        );
    });
  }

  formatDate(date): string {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + (d.getDate() - 1);
    const year = d.getFullYear();

    if (month.length < 2) {
      month = '0' + month;
    }
    if (day.length < 2) {
      day = '0' + day;
    }

    return [year, month, day].join('-');
  }

  getEffectiveToDisplay(attr: Attribute): string {
    const effectiveToDateStr = new Date(attr.effectiveTo);
    if (effectiveToDateStr > new Date('2099-12-31')) {
      return '';
    } else {
      return attr.effectiveTo;
    }
  }

  getEffectiveFromDisplay(attr: Attribute): string {
    const effectiveFromDateStr = new Date(attr.effectiveFrom);
    if (effectiveFromDateStr > new Date('2099-12-31')) {
      return '';
    } else {
      return attr.effectiveFrom;
    }
  }
  initNewSearch(): void {
    this.applyFilter();
  }

  applyFilter(): void {
    // tslint:disable-next-line:max-line-length
    // hide the actions if they are looking at the past to avoid all the confusion with users trying to edit/delete attributes that no longer exist.
    this.disableActions = this.filter.effectiveDate;
    this.setAttributes();
  }

  setDates(attrs: Attribute[]): void {
    attrs.forEach((attr: Attribute) => {
      attr.effectiveFrom = this.datePipe.transform(
        new Date(attr.effectiveFrom),
        'MM/dd/yyyy h:mm a',
        '+0000'
      );
      attr.effectiveTo = attr.effectiveTo
        ? this.datePipe.transform(
            new Date(attr.effectiveTo),
            'MM/dd/yyyy h:mm a',
            '+0000'
          )
        : null;
    });
  }
}
