import { AppDataService } from './../app-data.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class IntegrationsService {
  constructor(private http: HttpClient, private globalData: AppDataService) {}

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getIntegrations(params: any): any {
    return this.http.get<any>(
      this.globalData.baseUrl + 'integrations/',
      this.setParams(params)
    );
  }

  getIntegration(id: number, params: any): any {
    return this.http.get<any>(
      this.globalData.baseUrl + 'integrations/' + id,
      this.setParams(params)
    );
  }

  getAllCustomers(): any {
    return this.http.get<any>(
      this.globalData.baseUrl + 'integrations/customers'
    );
  }

  getLoads(id: number, params: any): any {
    return this.http.get<any>(
      this.globalData.baseUrl + 'integrations/' + id + '/loads',
      this.setParams(params)
    );
  }

  startImport(loadId: number): any {
    return this.http.get<any>(
      this.globalData.baseUrl + 'import/stage/' + loadId
    );
  }

  retryImport(id: number, data?: any): any {
    if (data) {
      return this.http.put<any>(
        this.globalData.baseUrl + 'import/' + id + '/retry?recordList=' + data,
        null
      );
    }
    return this.http.put<any>(
      this.globalData.baseUrl + 'import/' + id + '/retry',
      null
    );
  }

  searchLoads(loadId: number, params: any): any {
    return this.http.get<any>(
      this.globalData.baseUrl + 'integrations/' + loadId + '/search',
      this.setParams(params)
    );
  }

  getIntegrationMessages(id: number, params: any): any {
    return this.http.get<any>(
      this.globalData.baseUrl + 'integrations/' + id + '/messages',
      this.setParams(params)
    );
  }

  getImports(id: number, loadId: number, params: any): any {
    return this.http.get<any>(
      this.globalData.baseUrl +
        'integrations/' +
        id +
        '/loads/' +
        loadId +
        '/imports',
      this.setParams(params)
    );
  }

  createOrUpdateIntegration(data: any, id: number): any {
    if (id) {
      return this.http.put<any>(
        this.globalData.baseUrl + 'integrations/' + id,
        data
      );
    } else {
      return this.http.post<any>(
        this.globalData.baseUrl + 'integrations',
        data
      );
    }
  }

  updateIntegrationManifest(integrationId, manifestJSON, params): any {
    return this.http.put(
      this.globalData.baseUrl + 'integrations/' + integrationId + '/manifest',
      JSON.stringify(manifestJSON),
      { headers: { 'Content-Type': 'application/json' }, params }
    );
  }
  getStageImportMessages(id: number): any {
    return this.http.get<any>(
      this.globalData.baseUrl + 'integrations/' + 'stage-import-messages/' + id
    );
  }
}
