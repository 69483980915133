<form [formGroup]="groupForm">
  <div class="modal-header">
    <h1>Add Group</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <label>Group Name</label>
      <input type="text" class="form-control" formControlName="groupName" [appTrim]="!group">
      <div class="mt-1" [hidden]="groupForm.controls.groupName.valid">
        <small class="text-danger">Input a Code</small>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" [disabled]="!groupForm.valid"  (click)="ok()" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>