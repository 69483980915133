<div class="row col-md-12">
  <div class="{{columnsClass}}">
    <div class="d-flex">
      <span class="m-r-xs"><i>Current Filters:</i></span>
      <div *ngFor="let filter of appliedFilters">
        <a class="btn btn- btn-md filter-tag" *ngIf="helpers.isStringFilter(filter) || helpers.isBooleanFilter(filter)">
          <a (click)="clearErrorMessage()">
            <span class="float-right close-button close-button-tag" (click)="searchFilters.clearSelf(filter);">
              <fa-icon [icon]="faTimes"></fa-icon>
            </span>
          </a>
          <span *ngIf="!filter.label || (helpers.isStringFilter(filter)&& !filter.label)" class="ng-binding ng-scope">{{prepareLabel(filter.placeholder)}}
            {{filter.type === 'date' ? (filter.value | date: 'YYYY-MM-dd') : filter.value}}</span>
          <span *ngIf="filter.label && !helpers.isBooleanFilter(filter)" class="ng-binding ng-scope">{{prepareLabel(filter.placeholder)}}
            {{filter.label + ": " + (filter.value | date: 'YYYY-MM-dd')}}</span>
          <span *ngIf="filter.label && helpers.isBooleanFilter(filter)" class="ng-binding ng-scope">{{prepareLabel(filter.placeholder)}}
            {{filter.label}}</span>
        </a>
        <span *ngIf="helpers.isArrayFilter(filter)">
          <div  *ngIf = "filter.multiple" >
          <span *ngFor="let arrayValue of filter.value">
            <a class="btn btn- btn-md filter-tag">
                <span class="float-right close-button close-button-tag" (click)="searchFilters.clearSelf(filter, arrayValue);">
                  <fa-icon [icon]="faTimes"></fa-icon>
                </span>
                <span class="ng-binding ng-scope">{{prepareLabel(filter.placeholder)}}{{arrayValue}}</span>
            </a>
          </span>
        </div>
        <div *ngIf = "!filter.multiple" >
            <a class="btn btn- btn-md filter-tag">
                <span class="float-right close-button close-button-tag" (click)="searchFilters.clearSelf(filter, filter.value);">
                  <fa-icon [icon]="faTimes"></fa-icon>
                </span>
                <span class="ng-binding ng-scope">{{prepareLabel(filter.placeholder)}}{{filter.value}}</span>
            </a>
        </div>
        </span>
        <a class="btn btn- btn-md filter-tag" *ngIf="helpers.isSimpleArrayFilter(filter)">
            <span class="float-right close-button close-button-tag" (click)="searchFilters.clearSelf(filter);">
              <fa-icon [icon]="faTimes"></fa-icon>
            </span>
          <span class="ng-binding ng-scope">{{prepareLabel(filter.placeholder)}}{{filter.singleLabel + " (" + filter.value.length +
            ")"}}</span>
        </a>
        <a class="btn btn- btn-md filter-tag" ng-repeat-end="" *ngIf="filter.type === 'radio' && !helpers.radio.isDefaultChoiceSelected(filter)">
            <span class="float-right close-button close-button-tag" (click)="searchFilters.clearSelf(filter);">
              <fa-icon [icon]="faTimes"></fa-icon>
            </span>
          <span class="ng-binding ng-scope">{{prepareLabel(filter.placeholder)}}{{helpers.radio.getSelectedChoiceLabel(filter)}}</span>
        </a>
      </div>
      <span [hidden]="!helpers.showNoneSelected()">NONE</span>
    </div>
  </div>
  <div class="{{columnsNotAppliedClass}}">
    <div class="" [hidden]="!someFiltersNotApplied">
      <span class="filter-tags-text"><i>*Some filters not applied</i></span>
    </div>
  </div>
</div>
