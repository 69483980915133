<div [ngClass]="classValue" class="search-filter-fields segment-filter mr-sm ml-sm mt-sm">
    <div class="segment-filter">
        <div [ngClass] = "maxWidth ? 'w-100 btn-group filter' : 'btn-group filter' " ngbDropdown #segmentDropdown="ngbDropdown" autoClose="outside">
            <button type="button" class="btn btn-md btn- filter-button form-control" id="dropdownSegment" ngbDropdownAnchor 
                (focus)="segmentDropdown.open()"  (click)="getAllSegments()">
                <span class="float-left">Segment ({{toFilter.length}})</span>
            </button>
            <div ngbDropdownMenu aria-labelledby="dropdownSegment" class="segment-filter-search-box ">
                <input type="text" placeholder="Type minimum 3 characters to filter" [(ngModel)]="query" class="form-control" style="margin-bottom: 5px;" (ngModelChange)="applyFilter(query)">
                <div>
                    <a class="m-b-xs" (click)="clearAll()">Clear all</a>
                    <span class="float-right" *ngIf="toFilter.length > 0 &amp;&amp; query.length > 0">
                        <i>Some segments may be filtered...</i>
                    </span>
                </div>
                <div class="segment-filter-container">
                    <div class="checkbox checkbox-info" [ngClass]="getClass(item.level)" *ngFor="let item of filtered| sort: 'path'">
                        <input id="seg_{{item.id}}" name="seg_{{item.id}}" [(ngModel)]="item.checked" (ngModelChange)="addSegments(item)" type="checkbox">
                        <label for="seg_{{item.id}}">{{item.code}}</label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>