<form [formGroup]="surveyForm" novalidate>
    <div class="modal-header">
        <h1>{{title}}</h1>
    </div>
    <div class="modal-body">
        <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
            {{errorMsg}}
            <a (click)="clearErrorMessage()">
                <span class="float-right close-button">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </span>
            </a>
        </div>
        <div class="form-group">
            <label>Answers</label>
            <textarea formControlName="answers" type="text" class="form-control vertical-expand"></textarea>
            <div *ngIf="!surveyForm.controls.answers.valid">
                <small class="text-danger">Input answers</small>
            </div>
        </div>

        <div class="modal-footer">
            <button class="btn btn-dark-blue" type="submit" [disabled]="!surveyForm.valid" (click)="ok()" title="Save">
                <fa-icon [icon]="faSave"></fa-icon>
            </button>
            <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
                <fa-icon [icon]="faTimes"></fa-icon>
            </button>
        </div>
    </div>
</form>