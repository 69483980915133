<div class="panel-body">
  <div class="row">
    <div class="col-lg-12" *ngIf="show">
      <div class="row">
        <div class="col-md-12">
          <a class="btn btn-sm btn-dark-blue float-right mb-3" (click)="reminderModal()" title="Add New Reminder">
            <fa-icon [icon]="faPlus"></fa-icon>
          </a>
        </div>
      </div>
      <div class="row">
        <table class="table table-pointable table-bordered infinite-table">
          <thead>
            <tr>
              <th>ID</th>
              <th>Promotion Sponsor ID</th>
              <th>Created On</th>
              <th>Created By</th>
              <th>Updated On</th>
              <th>Updated By</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let reminder of reminders">
              <td>{{reminder.id}}</td>
              <td>{{reminder.promotionSponsorId}}</td>
              <td>{{reminder.createdOn | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
              <td>{{reminder.createdBy}}</td>
              <td>{{reminder.updatedOn | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
              <td>{{reminder.updatedBy}}</td>
              <td>{{reminder.status}}</td>
              <td>
                <div class="float-left actions inline-table">
                  <button type="button" class="btn btn-dark-blue btn-sm edit action"  (click)="reminderModal(reminder)" title="Edit Reminder">
                    <fa-icon [icon]="faEdit"></fa-icon>
                    </button>
                  <button type="button" class="btn btn-attention btn-sm float-right" (click)="removeReminder(reminder.id)">
                    <fa-icon [icon]="faTrash"></fa-icon>
                    </button>
                </div>
              </td>
            </tr>
            <tr app-is-there-more [numColumns]="8" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
      <app-add-more-data-to-list (onScroll)="getReminders(true)" [lock]="lock" [isLoading]="isLoading">
      </app-add-more-data-to-list>
    </div>
  </div>
</div>