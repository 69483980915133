import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter-radio',
  templateUrl: './filter-radio.component.html',
  styleUrls: ['./filter-radio.component.scss']
})
export class FilterRadioComponent implements OnInit {

  @Input() section: any;
  @Input() filter: any;
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

}
