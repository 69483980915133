import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppDataService} from "../../app-data.service";
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {TabSwapEvent, TabControllerService, TabTypes} from "../../services/tab-controller.service";

@Component({
  selector: 'app-promotion-behavior-code-modal',
  templateUrl: './promotion-behavior-code-modal.component.html',
  styleUrls: ['./promotion-behavior-code-modal.component.scss']
})
export class PromotionBehaviorCodeModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() activityDetails;
  @Input() hideButton;
  @Input() title;
  globalData;

  constructor(globalData: AppDataService,
              private modalHelper: NgbModal,
              private tabControllerService: TabControllerService) {
    this.globalData = globalData;
  }

  ngOnInit(): void {
  }

  cancel(action: any): void {
    if (action) {
      if (action.actionName === 'loadBehavior') {
        let tabSwapEvent = new TabSwapEvent();
        tabSwapEvent.params = {
          behaviorToLoad: action.behaviorToLoad
        };
        tabSwapEvent.destination = 'activities';
        tabSwapEvent.tabType = TabTypes.member;

        this.tabControllerService.swapTab(tabSwapEvent);
      } else if (action.actionName === 'searchBehavior') {
        let tabSwapEvent = new TabSwapEvent();
        tabSwapEvent.params = action.params;
        tabSwapEvent.destination = 'activities';
        tabSwapEvent.tabType = TabTypes.member;

        this.tabControllerService.swapTab(tabSwapEvent);
      }
    }
    this.successEvent.emit();
  }

}
