import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root'
})
export class PartyRoleService {

  constructor(private http: HttpClient, private globalData: AppDataService) { }

  getAllRole(): any {
    return this.http.get(this.globalData.baseUrl + 'party-role-types');
  }

  getAccessRole(): any {
    return this.http.get(this.globalData.baseUrl + 'access-roles');
  }
}
