<div class="d-flex button-panel-sq search-filter-flex-row" *ngIf="!searchFilters.buttonsWithText">
    <button type="submit" class="btn btn-dark-blue btn-sm search-filter-apply" [disabled]="appDataService.isRequestInProgress" title="Apply Filters" *ngIf="searchFilters.showSearchFilters">
      <fa-icon [hidden]="!appDataService.isRequestInProgress" [icon]="faSpinner" class="rotate fa-fw"></fa-icon>
      <fa-icon [hidden]="!!appDataService.isRequestInProgress" [icon]="faCheckSquare"></fa-icon>
    </button>
    <span *ngFor="let btn of searchFilters.addEntityButtons">
      <button *ngIf="btn.if === undefined ? true : btn.if" [ngStyle]="{ 'visibility': !btn ? 'hidden' : '' }" class="btn btn-dark-blue btn-sm btn-square" [ngClass]="btn.class" (click)="btn.callback()" [attr.title]="btn.title" [disabled]="btn.disable ? btn.disable : false">
        <fa-icon [icon]="btn.icon"></fa-icon>
      </button>
    </span>
    <a class="ms-auto clear-filter-nrm" (click)="searchFilters.clearAll()" *ngIf="searchFilters.showSearchFilters"><span class="float-right">Clear all filters</span></a>
  </div>
  <div class="form-group search-filter-flex-row search-filter-flex-row-reverse" *ngIf="searchFilters.buttonsWithText">
    <button type="submit" class="btn btn-dark-blue search-filter-apply fa m-b-xs" [disabled]="appDataService.isRequestInProgress" title="Apply Filters" *ngIf="searchFilters.showSearchFilters">
      <fa-icon [hidden]="!appDataService.isRequestInProgress" [icon]="faSpinner" class="rotate fa-fw"></fa-icon>
      <fa-icon [hidden]="!!appDataService.isRequestInProgress" [icon]="faCheckSquare"></fa-icon>&nbsp;Apply
    </button>
    <span *ngFor="let btn of searchFilters.addEntityButtons">
      <button *ngIf="btn.if === undefined ? true : btn.if" [ngStyle]="{ 'visibility': !btn ? 'hidden' : '' }" class="btn btn-primary m-b-xs" [ngClass]="btn.class" (click)="btn.callback()" [attr.title]="btn.title" [disabled]="btn.disable ? btn.disable : false">
        <fa-icon [icon]="btn.icon"></fa-icon>&nbsp;{{btn.buttonText}}
      </button>
    </span>
    <a class="clear-filter-nrm full-width" (click)="searchFilters.clearAll()" *ngIf="searchFilters.showSearchFilters"><span class="float-right">Clear all filters</span></a>
</div>
