<div class="background-white">
    <div class="panel-body">
      <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-sm-3 form-group">
             <ng-select theme="bootstrap" placeholder = "Select Documents Type" [items]="types" [(ngModel)]="selected"  bindValue="name"  bindLabel="name"
                 [closeOnSelect]="true" (change)="filterDocumentsTypes($event)">
              </ng-select>
          </div>
        </div>
        <div class="row mb-3 mt-3">
          <div class="col-md-12">
            <button class="btn btn-default btn-md filter-tag" [hidden]="!filter.type">
              <span>{{filter.type}}</span>
              <span class="m-2 close-button" (click)="clearKeyword()">
                <fa-icon [icon]="faTimes"></fa-icon>
              </span>
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
              <div class="row table-sponsors" width="100%">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>File Name</th>
                      <th>Internal ID</th>
                      <th>Inserted at</th>
                      <th>Type</th>
                      <th>Original File Name</th>
                      <th>Download</th>

                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let doc of documents">
                      <td>{{doc.node.filename}}</td>
                      <td>{{doc.node.internalId}}</td>
                      <td>{{doc.node.insertedAt | date: 'yyyy-MM-dd hh:mm:ss'}}</td>
                      <td>{{doc.node.type}}</td>
                      <td>{{doc.node.originalFilename}}</td>
                      <th class="aligned-text"><button (click) = "download($event,doc.node.internalId )" title="Download Document" type="button" class="btn btn-primary"><fa-icon [icon]="faDownload"></fa-icon></button></th>
                    </tr> 
                    <tr app-is-there-more [newLengthEvent]="newLengthEvent" [memberDocuments] = "true" [numColumns]="8">
                  </tbody>
                </table>
              </div>
              <app-add-more-data-to-list (onScroll)="getDocuments()" [lock]="lock" [isLoading]="isLoading">
              </app-add-more-data-to-list>
            </div>
          </div>
        </div>
      </div>
    </div>