<div class="panel-body">
    <div class="row">
        <div class="col-lg-12">
            <div class="row">
                <table class="table table-pointable table-bordered infinite-table">
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Name</th>
                            <th>Campaign Cap</th>
                            <th>Status</th>
                            <th>From</th>
                            <th>Thru</th>
                            <th>Expiry</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let c of campaigns">
                            <td>{{c.id}}</td>
                            <td title="{{c.name}}">{{c.name}}</td>
                            <td>{{c.cap}}</td>
                            <td>{{c.status}}</td>
                            <td>{{c.fromDate}}</td>
                            <td>{{c.thruDate}}</td>
                            <td>{{c.expirationDate}}</td>
                        </tr>
                        <tr app-is-there-more [numColumns]="7" [newLengthEvent]="newLengthEvent"></tr>
                    </tbody>
                </table>
            </div>
            <app-add-more-data-to-list (onScroll)="getCampaign(true)" [lock]="lock" [isLoading]="isLoading">
            </app-add-more-data-to-list>
        </div>
    </div>
</div>