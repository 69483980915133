<form [formGroup]="campaignForm" novalidate>
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Name</label>
          <input type="text" class="form-control" formControlName="name" autofocus>
          <div class="mt-sm" [hidden]="campaignForm.controls['name'].valid">
            <small class="text-danger">Input a Name</small>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Code</label>
          <input type="text" class="form-control" formControlName="code">
          <div class="mt-sm" [hidden]="campaignForm.controls['code'].valid">
            <small class="text-danger">Input a Code</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="form-group col-md-6">
        <label>Expiration Date</label>
        <div class="input-group date">
          <input class="form-control cursor-pointer" readonly (click)="expirationDate.toggle()" placeholder="yyyy-mm-dd"
            name="expirationDate" ngbDatepicker #expirationDate="ngbDatepicker" formControlName="expirationDate"
            [footerTemplate]="footerTemplateExpirationDate">
          <ng-template #footerTemplateExpirationDate>
            <div class="width-100 datePickerTemplate">
              <button class="buttonDateTemplate"
                (click)="clearDate('expirationDate'); expirationDate.toggle()">Clear</button>
            </div>
          </ng-template>
          <div class="input-group-append">
            <button class="btn btn-outline-secondary calendar" (click)="expirationDate.toggle()" type="button">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </button>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Status</label>
          <select name="status" class="form-control" formControlName="status">
            <option *ngFor="let option of statuses" value="{{option.value}}">{{option.value}}</option>
          </select>
          <div class="mt-sm" [hidden]="campaignForm.controls['status'].valid">
            <small class="text-danger">Select status</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Cap</label>
          <input type="number" class="form-control" formControlName="cap">
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Cap Reward Codes</label>
          <input type="text" class="form-control" formControlName="capRewardCodes">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>From Date</label>
          <div class="input-group date">
            <input class="form-control cursor-pointer" readonly (click)="fromDate.toggle()" placeholder="yyyy-mm-dd"
              name="fromDate" ngbDatepicker #fromDate="ngbDatepicker" formControlName="fromDate"
              [footerTemplate]="footerTemplateFromDate">

            <ng-template #footerTemplateFromDate>
              <div class="width-100 datePickerTemplate">
                <button class="buttonDateTemplate" (click)="clearDate('fromDate'); fromDate.toggle()">Clear</button>
              </div>
            </ng-template>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="fromDate.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
          <div class="mt-sm" [hidden]="campaignForm.controls['fromDate'].value">
            <small class="text-danger">Input a from date</small>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Thru Date</label>
          <div class="input-group date">
            <input class="form-control cursor-pointer" readonly (click)="thruDate.toggle()" placeholder="yyyy-mm-dd"
              name="thruDate" ngbDatepicker #thruDate="ngbDatepicker" formControlName="thruDate"
              [footerTemplate]="footerTemplateThruDate">
            <ng-template #footerTemplateThruDate>
              <div class="width-100 datePickerTemplate">
                <button class="buttonDateTemplate" (click)="clearDate('thruDate'); thruDate.toggle()">Clear</button>
              </div>
            </ng-template>
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="thruDate.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="form-group">
          <label>Rollover Interval</label>
          <select name="interval" class="form-control" formControlName="rolloverInterval">
            <option *ngFor="let int of intervals" value="{{int}}">{{int}}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Communication Strategy</label>
          <ng-select [items]="communications" bindLabel="name" bindValue="id" formControlName="communicationStrategyId">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div>
        <label>Campaign Attributes</label>
        <button class="btn btn-sm btn-dark-blue float-right" type="button" (click)="addAttrib()"
          title="Add New Campaign Attribute">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
        <div class="row" [hidden]="campaignAttribs().controls.length===0">
          <div class="col-md-5">
            <div class="form-group">
              <label>Attribute Name</label>
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <label>Attribute Value</label>
            </div>
          </div>
        </div>
        <div formArrayName="campaignAttribs">
          <div class="row" *ngFor="let attrControl of campaignAttribs().controls; let i=index" [formGroupName]="i">
            <div class="col-md-5">
              <div class="form-group">
                <input type="text" class="form-control" formControlName="attrName">
              </div>
              <div class="mt-sm" [hidden]="attrControl.controls['attrName'].valid">
                <small class="text-danger">Input a Name</small>
              </div>
            </div>
            <div class="col-md-5">
              <div class="form-group">
                <input type="text" class="form-control" formControlName="attrValue">
              </div>
              <div class="mt-sm" [hidden]="attrControl.controls['attrValue'].valid">
                <small class="text-danger">Input a Value</small>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group float-right">
                <button class="btn btn-dark-blue btn-sm delete float-right" (click)="removeAttrib(i)">
                  <fa-icon [icon]="faTrash"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>Description</label>
      <textarea formControlName="description" type="text" class="form-control vertical-expand"></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" [disabled]="!campaignForm.valid" (click)="ok()" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>
