import { Component, OnInit } from '@angular/core';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PartnerModalComponent } from '../partner-modal/partner-modal.component';
import { PartnerService } from '../services/partner.service';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { PartyRoleService } from '../services/party-role.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { Partner } from '../data/model';
import { GlobalEventService } from '../services/global-event.service';

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  faPencilAlt = faPencilAlt;
  selectedPerson: any = new Partner();
  selectedPersonId: number;
  statusHelper: any;
  isPartnerLoaded: boolean;
  activeId = 1;
  roles: any[] = [];

  constructor(private statusHelperService: StatusHelperService,
              private partnerService: PartnerService,
              private responseHelper: ResponseHelperService,
              private partyRoleService: PartyRoleService,
              private activatedRoute: ActivatedRoute,
              private title: Title,
              private globalEventService: GlobalEventService,
              private router: Router,
              private modalHelper: NgbModal) { }

  ngOnInit(): void {
    const id = this.activatedRoute.snapshot.params.id;
    this.statusHelper = this.statusHelperService.getStatus('badge');
    this.getAllRole();
    this.getPartner(id);
  }

  edit(selectedPersonId: number): void {
    this.partnerService.getPartner(selectedPersonId).subscribe((partner: any) => {
      const instance = this.modalHelper.open(PartnerModalComponent);
      instance.componentInstance.partner = partner.entity;
      instance.componentInstance.roles = this.roles;
      instance.componentInstance.successEvent.subscribe((data: any) => {
        if (data.success) {
          this.responseHelper.success('Partner successfully updated', true);
          this.getPartner(selectedPersonId);
        }
      });
    });
  }

  getAllRole(): void {
    this.partyRoleService.getAllRole().subscribe((roles: any) => {
      this.roles = roles.entity.filter((role: any) => role.id !== 1032);
    });
  }

  getPartner(id: number): void {
    this.partnerService.getPartner(id).subscribe((data: any) => {
      if (data.success) {
        this.selectedPerson = data.entity;
        this.selectedPersonId = data.entity.id;
        this.isPartnerLoaded = true;
        this.title.setTitle(data.entity.name);
        const dataToSend: any = {};
        dataToSend.data = {personName: data.entity.name, url: '/partners', id: this.selectedPersonId};
        dataToSend.toComponent = 'bread-crumbs';
        this.globalEventService.sendGlobalEvent(dataToSend);
      }
    }, () => {
      this.router.navigate(['/partners/']);
    });
  }

  parseResponse(data: any): void {
    if (data.success) {
      this.selectedPerson = data.entity;
      this.selectedPersonId = data.entity.id;
    }
  }

}
