<form [formGroup]="attachmentForm">
    <div class="modal-header">
      <h1>{{title}}</h1>
    </div>
    <div class="modal-body">
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{errorMsg}}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="form-group">
        <label>Name</label>
        <input type="text" class="form-control" formControlName="name">
        <div class="m-t-xs" [hidden]="attachmentForm.controls['name'].valid">
          <small class="text-danger">Input attachment name</small>
        </div>
      </div>
      <div class="form-group" *ngIf="resourceType === 'promotion'">
        <label>Mime/Type</label>
        <input type="text"  class="form-control" formControlName="mimeType">
      </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-dark-blue" type="submit" [disabled]="!attachmentForm.valid" (click)="ok()" title="Save">
          <fa-icon [icon]="faSave"></fa-icon>
        </button>
        <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
    </div>
</form>