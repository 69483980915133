<form [formGroup]="packageForm" novalidate>
    <div class="modal-header">
      <h1>{{title}}</h1>
    </div>
    <div class="modal-body">
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{errorMsg}}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="form-group">
        <label>Name</label>
        <input type="text"  class="form-control" formControlName="packageName" autofocus>
        <div class="m-t-xs" [hidden]="packageForm.controls.packageName.valid">
          <small class="text-danger">Input a Name</small>
        </div>
      </div>
      <div class="form-group">
        <label>Default Score Start Date:</label>
        <div class="input-group date">
            <input class="form-control cursor-pointer" (click)="startDate.toggle()"  readonly="readonly" placeholder="yyyy-mm-dd" name="startDate" ngbDatepicker #startDate="ngbDatepicker" formControlName="startDate" [footerTemplate]="footerTemplateStartDate">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="startDate.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
        </div>   
      </div>

    <div class="form-group">
        <label>Default Score End Date:</label>
        <div class="input-group date">
            <input class="form-control cursor-pointer" readonly="readonly" (click)="endDate.toggle()" placeholder="yyyy-mm-dd" name="endDate" ngbDatepicker #endDate="ngbDatepicker" formControlName="endDate" [footerTemplate]="footerTemplateEndDate">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="endDate.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
        </div>   
    </div>       

  
    <div class="modal-footer">
      <button class="btn btn-dark-blue" type="submit" [disabled]="!packageForm.valid" (click)="ok()" title="Save">
        <fa-icon [icon]="faSave"></fa-icon>
      </button>
      <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
        <fa-icon [icon]="faTimes"></fa-icon>
      </button>
    </div>
  </div>
</form>

<ng-template #footerTemplateStartDate>
  <div class="width-100 datePickerTemplate">
  <button class="buttonDateTemplate"  (click)="clearDateInput('startDate'); startDate.toggle()">Clear</button>
  </div>
</ng-template>

<ng-template #footerTemplateEndDate>
  <div class="width-100 datePickerTemplate">
  <button class="buttonDateTemplate"  (click)="clearDateInput('endDate'); endDate.toggle()">Clear</button>
  </div>
</ng-template>
  


 

