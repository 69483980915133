<div class="panel-body">
    <div class="row">
      <div class="col-md-12">
        <div class="row">
          <div class="segment-filter full-width">
            <div class="status" aria-labelledby="single-button">
              <div class="segment-filter-search-box">
                <div class="row">
                  <div class="col-md-4">
                    <input type="text" placeholder="Type minimum 3 characters to filter" [(ngModel)]="query" class="form-control" style="margin-bottom: 5px;" (change)="applyFilter(query)" ng-model-options="{debounce: 300}">
                  </div>
                  <div class="col-md-1">
                    <a class="btn btn-primary btn-sm float-right" (click)="addUserToSegment()" title="Add segment to communication"><fa-icon [icon]="faUserPlus"></fa-icon></a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3" *ngFor="let segmentDTO of filtered">
            <div class="widget lazur-bg p-xl">
              <div class="row">
                <div class="col-md-12">
                  <h2>{{segmentDTO.segment.name}}</h2>
                  <ul class="list-unstyled m-t-md">
                    <li>
                      <fa-icon [icon]="faCalendar" class="m-r-xs"></fa-icon>
                      <!-- <span class="fa fa-calendar m-r-xs"></span> -->
                      <!-- <label>Start:</label> {{segmentDTO.startDate | date'yyyy-MM-dd'}} -->
                      <label>Start:</label> {{segmentDTO.startDate}}
                    </li>
                    <li>
                      <fa-icon [icon]="faCalendar" class="m-r-xs"></fa-icon>  
                      <!-- <span class="fa fa-calendar m-r-xs"></span> -->
                      <!-- <label>End:</label> {{segmentDTO.endDate | date'yyyy-MM-dd'}} -->
                      <label>End:</label> {{segmentDTO.endDate}}
                    </li>
                    <li>
                      <fa-icon [icon]="faUsers" class="m-r-xs"></fa-icon>
                      <!-- <span class="fa fa-users m-r-xs"></span> -->
                      <label>Parent:</label> {{segmentDTO.segment.parent.name}}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="row">
                <div class="col-md-12">
                  <a class="btn btn-danger btn-sm float-right" confirm-settings="{size: 'sm'}" confirm-title="Remove user from segment" 
                    confirm="You are about to delete the last segment for this communication. Remove communication from segment: {{segmentDTO.segment.code}}?" 
                    (click)="removeSegmentFromUser(segmentDTO.segment.id)" title="Remove segments from communication">
                        <fa-icon [icon]="faTrash"></fa-icon>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>