import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root'
})
export class WorldCurrencyService {

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  constructor(private http: HttpClient, private globalData: AppDataService) { }

  getCurrencies(params?: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'currencies/', this.setParams(params));
  }

  deleteCurrency(id: number): any {
    return this.http.delete<any>(this.globalData.baseUrl + 'currencies/' + id);
  }

  getCurrency(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'currencies/' + id);
  }

  createOrUpdateCurrency(id: number, data: any): any {
    if (id) {
      return this.http.put<any>(this.globalData.baseUrl + 'currencies/' + id, data);
    }
    return this.http.post<any>(this.globalData.baseUrl + 'currencies/', data);
  }
}
