import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Filter, SearchFilters } from '../../data/class';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DeliveryProtocol } from '../../data/model';
import { faPlus, faTimes, faPencilAlt, faTrash, faList } from '@fortawesome/free-solid-svg-icons';
import { DeliveryProtocolModalComponent } from '../delivery-protocol-modal/delivery-protocol-modal.component';
import { CommunicationsService } from 'src/app/services/communications.service';
import { DeliveryOptionsModalComponent } from '../delivery-options-modal/delivery-options-modal.component';
import { ToastrService } from 'ngx-toastr';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-delivery-protocols-tab',
  templateUrl: './delivery-protocols-tab.component.html',
  styleUrls: ['./delivery-protocols-tab.component.scss']
})
export class DeliveryProtocolsTabComponent implements OnInit {

  isLoading = false;
  lockedTables = false;
  lock: boolean;
  limit = 20;
  searchFilters: SearchFilters;
  filter: Filter = new Filter();
  lengthToCompare: number;
  deliveryProtocols: DeliveryProtocol[];
  endpointTypes: any[];
  show: boolean;
  faPlus = faPlus;
  faTimes = faTimes;
  faPencilAlt = faPencilAlt;
  faTrash = faTrash;
  faList = faList;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private modalHelper: NgbModal,
    private communicationsService: CommunicationsService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.communicationsService.getAllEndpointTypes().subscribe((response: any) => {
      this.searchFilters = {
        formName: 'contactReasonsSearch',
        searchCallback: () => { this.initNewSearch(); },
        filters: [
          {
            name: 'endpointTypeName',
            placeholder: 'Endpoint',
            type: 'array',
            choices: response.entity,
            nameProp: 'name',
            valueProp: 'name',
            inputType: 'dropdown',
          }
        ],
      };
      this.show = true;
    });
  }

  initFilter(): void {
    this.filter = new Filter();
  }

  initNewSearch(): void {
    this.initFilter();
    this.getDeliveryProtocols();
  }


  deliveryModal(deliveryProtocol: DeliveryProtocol): void {
    const instance = this.modalHelper.open(DeliveryProtocolModalComponent);
    instance.componentInstance.protocol = deliveryProtocol;
    instance.componentInstance.endpointTypes = this.searchFilters.filters[0].choices;
    instance.componentInstance.successEvent.subscribe(() => {
      this.initNewSearch();
    });
  }

  getDeliveryProtocols(): void {
    const endpointTypeNames = this.searchFilters.getFilters(this.filter).endpointTypeName;
    const filter: any = {};
    if (endpointTypeNames && endpointTypeNames.length > 0) {
      filter.endpointTypeName = endpointTypeNames;
    }
    this.communicationsService.getDeliveryProtocols(filter).subscribe((data: any) => {
      this.deliveryProtocols = parseResponse(data);
    }, () => {
      this.toastr.error('Error occured!');
    });
  }

  editOptions(deliveryProtocol: DeliveryProtocol): void {
    const instance = this.modalHelper.open(DeliveryOptionsModalComponent, { windowClass: 'wider-modal-window' });
    instance.componentInstance.delivery = deliveryProtocol;
    instance.componentInstance.successEvent.subscribe(() => {
      this.initNewSearch();
    });
  }
}
