import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DateHelperService } from 'src/app/services/helpers/date-helper.service';

@Component({
  selector: 'app-filter-datepicker',
  templateUrl: './filter-datepicker.component.html',
  styleUrls: ['./filter-datepicker.component.scss']
})
export class FilterDatepickerComponent implements OnInit {

  @Input() section: any;
  @Input() filter: any;
  faCalendar = faCalendar;
  dateModel: NgbDate;
  minDate: NgbDate;
  maxDate: NgbDate;

  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter();

  constructor(private dateHelperService: DateHelperService) { }

  ngOnInit(): void {
    if (this.filter && this.filter.placeholder.toLowerCase() === 'dob') {
      this.minDate = new NgbDate(1925, 1, 1);
      const today = new Date();
      this.maxDate = new NgbDate(this.getYear(today), 12, 31);

    }
    this.dateModel = this.dateHelperService.stringToNgbDate(this.filter.value);
  }


  getYear(date): number {
    const d = new Date(date);
    const year = d.getFullYear();
    return year;
  }

  clearDate(): void {
    this.dateModel = null;
    this.filter.value = null;
  }

  formatDate(dateToFormat: any): void {
    if (dateToFormat !== null) {
      this.filter.value = dateToFormat.year + '-' + dateToFormat.month + '-' + dateToFormat.day;
    } else {
      delete this.filter.value;
    }
    this.inputChangeEmitter.emit();
  }

}
