import { DatePipe, formatDate } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { faCheckCircle, faSpinner, faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { AppDataService } from 'src/app/app-data.service';
import { Filter, SearchFilters } from 'src/app/data/class';
import { ActivityService } from 'src/app/services/activity.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { MemberEventsService } from 'src/app/services/member-events.service';
import { MemberMeasuresService } from 'src/app/services/member-measures.service';
import { ToastrService } from 'ngx-toastr';
import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-member-measures-tab',
  templateUrl: './member-measures-tab.component.html',
  styleUrls: ['./member-measures-tab.component.scss'],
  providers: [DatePipe]
})
export class MemberMeasuresTabComponent implements OnInit {

  @Input() personId: number;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  measures: any;
  lock: boolean;
  lockedTables: any = { state: false };
  statuses: any[];
  filteredStatuses: any[] = [];
  searchFilters: SearchFilters;
  filter = new Filter();
  isLoading: boolean;
  limit = 20;
  faSpinner = faSpinner;
  faCheckCircle = faCheckCircle;
  checkAll: boolean;
  lengthToCompare: number;
  isSelected: boolean[] = [];
  selectedMeasures: any = [];
  selectedMeasuresCount = 0;
  activityFilter: any;
  faChevronRight = faChevronRight;
  expandedLookup = [];
  faChevronDown = faChevronDown;
  isCSRMANAGER = this.appDataService.isCSRManager;
  activities: any[];
  needRange = false;
  rangeFrom: NgbDate;
  rangeTo: NgbDate;
  startDate
  endDate

  constructor(
    public appDataService: AppDataService,
    private responseHelperService: ResponseHelperService,
    private activityService: ActivityService,
    private measureService: MemberMeasuresService,
    private eventsService: MemberEventsService,
    private tableHelperService: TableHelperService,
    private datePipe: DatePipe,
    private titleHelper: Title,
    private dateHelperService: DateHelperService,
    private toastr: ToastrService
  ) { }

  today = new Date();
  ngOnInit(): void {
    if (this.personId === null || this.personId === undefined) {
      this.titleHelper.setTitle('Measures');
      this.needRange = true;
      this.startDate = new Date().setDate(this.today.getDate() - 1);
      this.endDate = new Date().setDate(this.today.getDate() + 1);
    }
    this.searchFilters = {
      formName: 'measuresTabSearch',
      buttonsWithText: true,
      filters: [
        {
          name: 'eventRef',
          placeholder: 'Event Ref...',
        },
        {
          name: 'source',
          placeholder: 'Source...',
        },
        {
          name: 'measure',
          placeholder: 'Measure...',
        },
        {
          name: 'fromDate',
          type: 'date',
          placeholder: 'From:',
          value: this.datePipe.transform(this.startDate, 'yyyy-MM-dd'),
          
        },
        {
          name: 'toDate',
          type: 'date',
          placeholder: 'To:',
          value: this.datePipe.transform(this.endDate, 'yyyy-MM-dd')
        }
      ],
      searchCallback: () => { this.initNewSearch(); },
      addEntityButtons: [
        {
          icon: faCheckCircle,
          buttonText: 'Re-submit',
          if: this.isCSRMANAGER,
          callback: () => { this.triggerProcessing(); },
          title: 'Re-submit for processing'
        }
      ]
    };
    this.initFilter();
    this.getStatuses();
  }

  getStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('EVENTS');
  }

  setStatuses(): void {
    this.initFilter();
    this.filter.status = this.tableHelperService.extractData(this.filteredStatuses);
    this.getMeasures(false);
  }

  initFilter(): void {
    // Temporary solution
    if (this.personId !== undefined) {
      this.filter.partyId = this.personId;
    }
    this.filter.limit = this.limit;
    this.filter.start = 0;
    this.filter.column = 3;
    this.filter.dir = 'DESC';
  }

  clearLookup(): void {
    this.expandedLookup = [];
  }

  getRangeDay(): number {
    var Difference_In_Days = 99;
    if (this.filter.fromDate && this.filter.toDate) {
      const dateFrom = new Date(this.filter.fromDate);
      const dateTo = new Date(this.filter.toDate);
      var Difference_In_Time = dateTo.getTime() - dateFrom.getTime();
      var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    }
    return Math.floor(Difference_In_Days);
  }

  getMeasures(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    if (this.filter.fromDate) {
      this.filter.fromDate = this.datePipe.transform(this.filter.fromDate, 'yyyy-MM-dd') + 'T00:00';
    }
    if (this.filter.toDate) {
      this.filter.toDate = this.datePipe.transform(this.filter.toDate, 'yyyy-MM-dd') + 'T23:59';
    }
    if (!this.needRange) {
      this.getAllMeasures(concat);
    } else if (this.needRange && this.getRangeDay() < 31) {
      this.getAllMeasures(concat);
    }
  }

  private getAllMeasures(concat: boolean) {
    this.isLoading = true;
    this.clearLookup();
    this.measureService.getMeasures(this.filter).subscribe((data: any) => {
      if (concat) {
        this.measures = this.measures.concat(data.aaData);
        data.aaData.forEach(() => {
          this.isSelected.push(false);
        });
      } else {
        this.measures = data.aaData;
        this.isSelected = [];
        this.measures.forEach(() => {
          this.isSelected.push(false);
        });
      }
      this.lengthToCompare = data.aaData.length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.start += this.limit;
      this.lockedTables = data.aaData.length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
      if (data.aaData.length !== this.limit) {
        $('#select-all-cb').prop('checked', false);
      }
    }, () => {
      this.lockedTables = false;
      this.isLoading = false;
      this.lock = false;
      this.toastr.error('Error occured!');
    });
  }

  showDetails(index: number): void {
    if (this.expandedLookup[index]) {
      this.expandedLookup = [];
      this.activities = [];
      this.expandedLookup[index] = false;
    } else {
      this.expandedLookup = [];
      this.activities = [];
      this.expandedLookup[index] = true;
    }
  }

  showInfo(event: any, $event: any): void {
    if (event.activityCount > 0) {
      this.activityFilter = {};
      this.activityFilter.search = event.eventRef + '-' + this.datePipe.transform(event.eventTimestamp, 'yyyy-MM-dd');
      this.activityService.getActivitiesForMember(this.personId, this.activityFilter).then(data => {
        if (data.success) {
          this.activities = parseResponse(data);
        }
      });
    }
  }

  selectAllCb(): void {
    this.isSelected = this.isSelected.map(() => this.checkAll);
    if (this.checkAll === false) {
      this.selectedMeasures = [];
    } else if (this.checkAll === true) {
      this.measures.forEach(element => {
        this.selectedMeasures.push(element.eventRef);
      });
      this.selectedMeasuresCount = this.selectedMeasures.length;
    }
  }

  updateSelectedMeasures(measure: any, i: number): void {
    if (this.isSelected[i]) {
      this.selectedMeasures.push(measure.eventRef);
    } else if (!this.isSelected[i]) {
      this.selectedMeasures = this.selectedMeasures.filter(obj => obj !== measure.eventRef);
    }
    this.selectedMeasuresCount = this.selectedMeasures.length;
  }

  triggerProcessing(): void {
    this.eventsService.processList(this.selectedMeasures).subscribe(() => {
      this.responseHelperService.success('Measure successfully sent for processing');
    }, (data) => {
      this.responseHelperService.error(this, data.error.error, true);
    });
    this.checkAll = false;
    this.selectedMeasures = [];
  }

  initNewSearch(): void {
    this.initFilter();
    this.getMeasures(false);
  }

}
