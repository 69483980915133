import { Filter, SearchFilters } from './../../data/class';
import { Title } from '@angular/platform-browser';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { ProcessingActivityLogService } from 'src/app/services/processing-activity-log.service';
import { faCalendar, faTimes, faExclamationTriangle, faCheckCircle, faFireExtinguisher } from '@fortawesome/free-solid-svg-icons';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { UtilityService } from 'src/app/services/utility.service';
import { ToastrService } from 'ngx-toastr';
import { parseResponse } from 'src/app/data/parseResponseFunction';
import { AppDataService } from 'src/app/app-data.service';

@Component({
  selector: 'app-processing-logs',
  templateUrl: './processing-logs.component.html',
  styleUrls: ['./processing-logs.component.scss']
})
export class ProcessingLogsComponent implements OnInit {

  filteredStatuses = [];
  filter: Filter = new Filter();
  dtInstance: any = {};
  limit = 20;
  processingLogs = [];
  lengthToCompare: number;
  lockedTables: boolean;
  lock: boolean;
  isLoading: boolean;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  faCalendar = faCalendar;
  fromDate: NgbDate;
  toDate: NgbDate;
  faTimes = faTimes;
  searchFilters: SearchFilters;
  faExclamationTriangle = faExclamationTriangle;
  faFireExtinguisher  = faFireExtinguisher;
  checkAll: boolean;
  selectedLogs:number[] = [];
  isSelected: any = {}


  constructor(
    public globalData: AppDataService,
    private tableHelperService: TableHelperService,
    private title: Title,
    private toastr: ToastrService,
    private utilityService: UtilityService,
    private processingActivityLogService: ProcessingActivityLogService,
  ) { }

  tableHelper = this.tableHelperService;
  statuses = this.tableHelperService.getStatuses('PROCESSING_LOG');

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'processing-logs-search',
      buttonsWithText: true,
      sections: [
        {
          key: 'firstRow',
          additionalRow: true
        },
        {
          key: 'secondRow',
          additionalRow: true
        }
      ],
      filters: [
        {
          name: 'processingLogErrors',
          type: 'boolean',
          label: 'View logs with errors',
          section: {key: 'firstRow' }
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('PROCESSING_LOG'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
          section: {key: 'secondRow' }
        },
        {
          name: 'startDate',
          type: 'date',
          placeholder: 'Start Date',
          section: {key: 'secondRow' }
        },
        {
          name: 'endDate',
          type: 'date',
          placeholder: 'End Date',
          section: {key: 'secondRow' }
        },
        {
          name: 'search',
          placeholder: 'Keyword...',
          section: {key: 'secondRow' }
        },
        {
          name: 'stageLoadId',
          placeholder: 'File Load ID',
          section: {key: 'secondRow' }
        }
      ],
      searchCallback: () => { this.initNewSearch(); },
      addEntityButtons: [
        {
          title: 'Resubmit Activities with Errors or those that were rolled back due to errors',
          buttonText: 'Resubmit Errors',
          callback: () => { this.processLogs(); },
          if: this.globalData.isProgramManager,
          icon: faFireExtinguisher,
        }
      ]
    };
    this.title.setTitle('Processing Logs');
    this.initFilter();
  }

  initNewSearch(): void {
    this.initFilter();
    this.getProcessingLogs(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getProcessingLogs(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.processingActivityLogService.getProcessingLogs(this.utilityService.removeNullAndEmptyProperties(this.filter))
      .subscribe((data: any) => {
        if (concat) {
          this.processingLogs = this.processingLogs.concat(parseResponse(data));
        } else {
          this.processingLogs = parseResponse(data);
        }
        this.lengthToCompare = parseResponse(data).length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = parseResponse(data).length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      }, () => {
        this.toastr.error('Error occurred');
        this.lockedTables = false;
        this.lock = false;
        this.isLoading = false;
      });
  }

  processLogs(): void {
    if(this.selectedLogs.length){
      this.processingActivityLogService.processLogs(this.selectedLogs).subscribe(
        () => {
          this.toastr.success('Logs successfully sent for processing');
          this.selectAllLogs(false);
          this.getProcessingLogs(false); // refresh the proc logs
        },
        (data: any) => {
          this.toastr.error(data.error.error);
          this.selectAllLogs(false);
        }
      );
    } else {
      this.toastr.error('Select at least 1 log');
    }
  }

  setStatuses(): void {
    this.initFilter();
    this.filter.statuses = this.tableHelperService.extractData(this.filteredStatuses);
    this.getProcessingLogs(false);
  }

  selectAllLogs(checkAll: boolean): void {
    this.checkAll = checkAll;
    if (checkAll) {
      this.selectedLogs = this.processingLogs.map((a: any) => a.id);
    } else {
      this.selectedLogs = [];
    }
    this.processingLogs.forEach((log: any) => (this.isSelected[log.id] = checkAll));
  }

  selectOneLog(processingLogId: number): void {
    const logIndex = this.selectedLogs.findIndex((id: number) => id === processingLogId);
    if (logIndex > -1) {
        this.selectedLogs.splice(logIndex, 1);
    } else {
      this.selectedLogs.push(processingLogId);
    }
    this.checkAll = this.processingLogs.length === this.selectedLogs.length;

  }
}
