import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { faEye, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Filter, SearchFilters } from 'src/app/data/class';
import { ReportsService } from 'src/app/services/reports.service';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { ReportParamsModalComponent } from '../report-params-modal/report-params-modal.component';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { parseResponse } from 'src/app/data/parseResponseFunction';



@Component({
  selector: 'app-reports-results',
  templateUrl: './reports-results.component.html',
  styleUrls: ['./reports-results.component.scss']
})
export class ReportsResultsComponent implements OnInit {

  @Input() isAdmin: boolean;
  @Input() selectedPersonId: boolean;
  limit = 20;
  isLoading: boolean;
  filter = new Filter();
  searchFilters: SearchFilters;
  lock: boolean;
  lengthToCompare: number;
  reportResults: any[] = [];
  lockedTables: boolean;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  show = true;
  faEye = faEye;
  DAY_IN_MILLIS = 24 * 60 * 60 * 1000;
  filteredStatuses: any[];
  faSpinner = faSpinner;

  constructor(private reportsService: ReportsService,
              private tableHelperService: TableHelperService,
              private statusHelperService: StatusHelperService,
              private dateHelperService: DateHelperService,
              private configurationService: ConfigurationService,
              private http: HttpClient,
              private modalHelper: NgbModal,
              private toastr: ToastrService) { }

              statusHelper = this.statusHelperService.getStatus('badge');
              TODAY = this.dateHelperService.toISOFormat(new Date());
              WEEKS_AGO = this.dateHelperService.toISOFormat(new Date(new Date().getTime() - 100 * this.DAY_IN_MILLIS));

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'reportResultsSearch',
      searchCallback: () => { this.initNewSearch(); },
      buttonsWithText: true,
      filters: [
        {
          name: 'reportName',
          placeholder: 'Report name',
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('REPORTS'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown'
        },
        {
          name: 'reportNotes',
          placeholder: 'Report Notes'
        },
        {
          name: 'from',
          type: 'date',
          placeholder: 'From:',
          value: this.WEEKS_AGO
        },
        {
          name: 'to',
          type: 'date',
          placeholder: 'To:',
          value: this.TODAY
        },
        {
          name: 'requestedBy',
          placeholder: 'Requested By',
          if: this.isAdmin
        }
      ]
    };
  }

  clearKeyword(): void {
    this.initFilter();
    this.filter.search = '';
    this.getReportResults(false);
  }

  initNewSearch(): void {
    this.initFilter();
    this.getReportResults(false);
  }

  applyFilter(): void {
    this.initFilter();
    this.filter.statuses = this.tableHelperService.extractData(this.filteredStatuses);
    this.lock = true;
    this.getReportResults(false);
  }

  getReportResults(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.reportsService.getReportResults(this.filter).subscribe((data: any) => {
      if (concat) {
        this.reportResults = this.reportResults.concat(parseResponse(data));
      } else {
        this.reportResults = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
    }, () => {
      this.lockedTables = false;
      this.isLoading = false;
      this.lock = false;
      this.toastr.error('Error occured!');
    });
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  // TODO
  download(event: any, downloadLink: string): void {
    event.preventDefault();
    // it's friday and I want a damned pipe operator
    const token = JSON.parse(atob(localStorage.getItem('token').split('.')[1])).sessionId;
    this.configurationService.getConfiguration('MEMBER_UI_LOCATION').subscribe((data: any) => {
      if (data.success && parseResponse(data) && parseResponse(data).length > 0) {
        const memberUrl = parseResponse(data)[0].cfgValue;
        this.http.get(memberUrl + downloadLink, {
          headers: {
            Authorization: 'Bearer ' + token
          },
          observe: "response",
          responseType: 'blob'
        }).subscribe(resp => {
          const url = window.URL.createObjectURL(resp.body);
          let anchor = document.createElement("a");
          anchor.download = resp.headers.get('content-disposition').split('; ')[1].split('=')[1].replace(/"/g, '');
          anchor.href = url;
          anchor.click();
        });
      }
    });
  }

  viewReportParams(execution: any): void {
    this.reportsService.getReportExecutionParams(execution.id).subscribe((data: any) => {
      if (data.success){
        const instance = this.modalHelper.open(ReportParamsModalComponent);
        instance.componentInstance.execution = execution;
        instance.componentInstance.reportParams = parseResponse(data);
      }
    });
  }

  changeFilter(filterName: string, value: string): void {
    delete this.filter[filterName];
    if (value !== '') {
      this.filter[filterName] = value;
    }
    this.applyFilter();
  }

}
