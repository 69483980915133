<div>
  <form>
    <label>The member's age is:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="ageRestriction !== null">
        <div *ngFor="let ageRestriction of ageRestriction; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeRestriction(ageRestriction)" title="Remove Restriction">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
          <div class="form-group">
            <select name="ageRest_{{ageRestriction.value}}" class="form-control" [(ngModel)]="ageRestriction.direction" (ngModelChange)="updateRestrictions()">
              <option *ngFor="let direction of directions" [selected]="ageRestriction.direction === direction.value" [value]="direction.value">{{direction.value}}</option>
            </select>
          </div>
          <div class="form-group">
            <label class="control-label" for="ageRest_{{ageRestriction.value}}">than:</label>
            <input class="form-control m-b" type="number" id="ageRest_{{ageRestriction.value}}" name="value" [(ngModel)]="ageRestriction.value" (ngModelChange)="updateRestrictions()" />
          </div>
            <div class="form-group">
              <select name="unit_{{ageRestriction.value}}" class="form-control" [(ngModel)]="ageRestriction.units" (ngModelChange)="updateRestrictions()">
                <option *ngFor="let unit of units" [selected]="ageRestriction.units === unit.value" [value]="unit.value">{{unit.text}}</option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <div class="form-group float-right col-md-12">
        <button class="btn btn-default float-right" (click)="initRestrictionObject()"><fa-icon [icon]="faPlus"></fa-icon> Add Party Age Restriction</button>
      </div>
    </div>
  </form>
</div>
