 <div class="panel-body">
    <div class="col-md-12">
        <app-search-filter [searchFilters]="searchFilters"></app-search-filter>  
        <div class="row">
          <table class="table table-pointable table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let pt of promotionTypes">
                <td>{{pt.id}}</td>
                <td>{{pt.name}}</td>
                <td>{{pt.status}}</td>
                <td>
                  <div class="actions inline-table float-left">
                    <a class="btn btn-dark-blue btn-sm edit action" (click)="promotionTypeModal(pt)" title="Edit"><fa-icon [icon]="faPencilAlt"></fa-icon></a>
                    <button class="btn btn-attention btn-sm delete action" [disabled]="pt.status==='DELETED'" (click)="deletePromotionType(pt)" title="Delete"><fa-icon [icon]="faTrash"></fa-icon></button>
                  </div>
                </td>
              </tr>
              <tr app-is-there-more [numColumns]="4" [newLengthEvent]="newLengthEvent"></tr> 
            </tbody>
          </table>
      </div>
      <app-add-more-data-to-list (onScroll)="getPromotionType(true)" [lock]="lock" [isLoading]="isLoading"></app-add-more-data-to-list>
    </div>
  </div>

