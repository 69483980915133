<div class="panel-body">
  <div class="row">
    <div class="col-md-12 mb-2">
      <div class="float-right">
        <a routerLink="/programs/{{selectedProgramId}}/promotions/{{selectedPersonId}}/promotion-template"
          class="btn btn-dark-blue btn-sm float-right" title="Add Configuration Template" *ngIf="!disableTemplateEdit">
          <fa-icon [icon]="faPlus"></fa-icon>
        </a>
      </div>
    </div>
    <div class="col-md-12">
      <app-search-filter *ngIf="searchFilters" [searchFilters]="searchFilters"></app-search-filter>
    </div>
    <div class="col-md-12" *ngIf="show">
      <div class="row mb-0">
        <table class="table table-pointable table-bordered infinite-table" width="100%">
          <thead>
            <tr>
              <th>ID</th>
              <th>Template Name</th>
              <th>Package Name</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let temp of promotionTemplates">
              <td>{{temp.id}}</td>
              <td>{{temp.name}}</td>
              <td>{{temp.programPackage.packageName}}</td>
              <td>{{temp.programPackage.startDate | date: 'yyyy-MM-dd'}}</td>
              <td>{{temp.programPackage.endDate | date: 'yyyy-MM-dd'}}</td>
              <td>
                <button class="btn btn-dark-blue btn-sm" (click)="confirmEdit(temp)" title="Edit Template">
                  <fa-icon [icon]="faPencilAlt"></fa-icon>
                </button>
                <a class="btn btn-dark-blue btn-sm"
                  routerLink="/programs/{{selectedProgramId}}/promotions/{{selectedPersonId}}/promotion-template/{{temp.id}}/clone"
                  title="Clone Promotion Template" *ngIf="!disableTemplateEdit">
                  <fa-icon [icon]="faClone"></fa-icon>
                </a>
                <button class="btn btn-attention btn-sm delete action" [disabled]="temp.sponsorCount>0"
                  (click)="deleteTemplate(temp.id)" title="Delete" *ngIf="!disableTemplateEdit">
                  <fa-icon [icon]="faTrash"></fa-icon>
                </button>
              </td>
            </tr>
            <tr app-is-there-more waitUntilApply = 'true' [numColumns]="6" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
      <app-add-more-data-to-list (onScroll)="getPromotionTemplates(true)" [lock]="lock" [isLoading]="isLoading">
      </app-add-more-data-to-list>
    </div>
  </div>
</div>
