import { ResponseHelperService } from './../../services/helpers/response-helper.service';
import { Filter, ProcessingLogSearch } from './../../data/class';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { faExclamationTriangle, faCheckCircle, faEdit, faRedo } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { ProcessingActivityLogService } from 'src/app/services/processing-activity-log.service';
import { SearchFilters } from 'src/app/data/class';
import { UserPreferencesService } from 'src/app/services/user-preferences.service';
import { ProcessingLogNotesModalComponent } from '../processing-log-notes-modal/processing-log-notes-modal.component';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-processing-log',
  templateUrl: './processing-log.component.html',
  styleUrls: ['./processing-log.component.scss']
})
export class ProcessingLogComponent implements OnInit {

  msgs: any[]; // TODO: assign type
  search = new ProcessingLogSearch();
  searchFilters: SearchFilters;
  processingLogForActivity: any[];
  processingLogId: number;
  isLoading = false;
  limit = 20;
  lock: boolean;
  filter = new Filter();
  offset = 0;
  showNotes: boolean;
  style: string;
  resolvedStyle: string;
  selectedLogActivityId: number;
  processingLogList = [];
  reformat = false;
  pathActivityId: number;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  subject: Subject<any> = new Subject<any>();

  faExclamationTriangle = faExclamationTriangle;
  faRedo = faRedo;
  faCheckCircle = faCheckCircle;
  faEdit = faEdit;
  filteredSegments = [];

  constructor(
    private processingActivityLogService: ProcessingActivityLogService,
    private router: ActivatedRoute,
    private modalHelper: NgbModal,
    private userPrefs: UserPreferencesService,
    private toastr: ToastrService,
    private title: Title,
    private responseHelperService: ResponseHelperService
  ) { }

  ngOnInit(): void {
    this.title.setTitle('Processing Log');
    this.processingLogId = this.router.snapshot.params.id;
    this.pathActivityId = this.router.snapshot.queryParams.activityId;
    this.showNotes = this[this.userPrefs.service.getPreference('layout.processingLogs.showNotes')] !== undefined ?
      this[this.userPrefs.service.getPreference('layout.processingLogs.showNotes')] : true;

    this.searchFilters = {
      formName: 'processingLogSearch',
      title: 'Processing Log ' + this.processingLogId,
      searchCallback: () => { this.initNewSearch(); },
      inputClass: 'search-filter-fields-wide',
      filters: [
        {
          name: 'partyId',
          type: 'number',
          placeholder: 'Party ID',
        },
        {
          name: 'activityId',
          type: 'number',
          placeholder: 'Activity ID',
          value: this.pathActivityId ? this.pathActivityId : null
        },
        {
          name: 'pSponsorId',
          placeholder: 'Promotion Sponsor ID'
        },
        {
          name: 'search',
          placeholder: 'Keyword...'
        }
      ],
    };
  }

  initNewSearch(): void {
    this.initFilter();
    this.getProcessingLogs(false);
  }

  initFilter(): void {
    this.limit = 20;
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  format(msgs: any[]): void {
    if (msgs.length !== 0) {
      this.msgs = msgs;
      this.search = new ProcessingLogSearch();
    }
  }

  processingLogHasStatus(msgs: any[], status: string): any {
    return msgs && msgs.find((e: any) => e.status === status);
  }

  selectprocessingLogForActivity($event: any, processingLog: any): void {
    $event = $($event.currentTarget);
    $('tr.active').removeClass('active');
    $event.toggleClass('active');

    if (this.selectedLogActivityId && processingLog.activityId !== this.selectedLogActivityId) {
      this.processingLogList[this.selectedLogActivityId] = false;
    }
    this.selectedLogActivityId = processingLog.activityId;
    if (!this.processingLogList[processingLog.activityId]) {
      this.format(processingLog.processingLogForActivity);
      this.processingLogList[processingLog.activityId] = true;
    } else {
      this.processingLogList[processingLog.activityId] = !this.processingLogList[processingLog.activityId];
    }
  }

  getProcessingLogs(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.isLoading = true;
    this.processingActivityLogService.findProcessingLogActivitiesForId(this.processingLogId, this.filter).subscribe((data: any) => {
      if (concat) {
        this.processingLogForActivity = this.processingLogForActivity.concat(parseResponse(data));
      } else {
        this.processingLogForActivity = parseResponse(data);
      }
      this.filter.offset += this.limit;
      this.lock = parseResponse(data).length !== this.limit;
      this.isLoading = false;
      this.newLengthEvent.emit(parseResponse(data).length);
      if (this.reformat) {
        this.processingLogForActivity.filter((processingLog) => {
          if (this.selectedLogActivityId === processingLog.activityId) {
            this.format(processingLog.processingLogForActivity);
            return;
          }
        });
        this.reformat = false;
      }
    }, () => {
      this.isLoading = false;
      this.lock = false;
      this.toastr.error('Error occurred!');
    });
  }

  openNoteModal(procActivityLog): void {
    const instance = this.modalHelper.open(ProcessingLogNotesModalComponent);
    instance.componentInstance.procActivityLog = procActivityLog;
    instance.componentInstance.successEvent.subscribe(() => {
      this.responseHelperService.success('Note successfully updated', true);
      this.reformat = true;
      this.initNewSearch();
    });
  }

  filterActivity(item: any, value: any): any {
    let valid = true;
    Object.keys(value).forEach((key) => {
      if (value[key] && item[key].toLowerCase().indexOf(value[key].toLowerCase()) === -1) {
        valid = false;
      }
    });
    return valid;
  }

  getRowColor(message: any): any {
    return 'ERROR RETRY'.includes(message.status) ? 'yellow' :
           'RESOLVED'.includes(message.status) ? 'lightgreen' : '#dff4ff';
  }

}
