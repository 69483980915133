import { ActivatedRoute } from '@angular/router';
import { TableHelperService } from 'src/app/services/helpers/table-helper.service';
import { ActivityService } from './../../../services/activity.service';
import { ProcessingService } from './../../../services/processing.service';
import { HierarchySourcesService } from 'src/app/services/hierarchy-sources.service';
import { BehaviorsService } from 'src/app/services/behaviors.service';
import { ActivityInstrument, AddressModel, HierarchySource } from './../../../data/model';
import { InstrumentsService } from 'src/app/services/instruments.service';
import {Component, OnInit, Input, Output, EventEmitter, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import { AppDataService } from 'src/app/app-data.service';
import { DatePipe } from '@angular/common';
import { SearchFilters, Filter } from 'src/app/data/class';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { LocationService } from 'src/app/services/location.service';
import { Location, Behavior } from 'src/app/data/model';
import { ProcessingActivityLogService } from 'src/app/services/processing-activity-log.service';
import { faTrophy, faChevronRight, faChevronDown, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MemberActivityModalComponent } from '../member-activity-modal/member-activity-modal.component';
import { AddressService } from 'src/app/services/address.service';
import { ToastrService } from 'ngx-toastr';
import { UserPreferencesService } from 'src/app/services/user-preferences.service';
import { ReversalConfirmationModalComponent } from '../reversal-confirmation-modal/reversal-confirmation-modal.component';
import { SecurityService } from 'src/app/services/security/security.service';
import {MatDialog} from "@angular/material/dialog";
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-member-activity-tab',
  templateUrl: './member-activity-tab.component.html',
  styleUrls: ['./member-activity-tab.component.scss'],
  providers: [DatePipe]
})
export class MemberActivityTabComponent implements OnInit {

  @Input() personId: number;
  @Input() behaviorToLoad: number;
  @Output() behaviorToLoadChange: EventEmitter<any> = new EventEmitter();
  @Input() activitySearchParams: any;
  @Output() activitySearchParamsChange: EventEmitter<any> = new EventEmitter();
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  statuses: any[]; // TODO: assign type
  searchFilters: SearchFilters;
  filter = new Filter();
  limit: number;
  locationsFilter: any; // TODO: assign type
  locations: Location[];
  defaultLocation: any;
  defaultLocationName: string;
  allLocations: Location[];
  instruments: ActivityInstrument[];
  instrument: ActivityInstrument;
  behaviors: Behavior;
  allBehaviors: Behavior;
  hierarchySources: HierarchySource;
  selectedActivities: any = []; // TODO: assign type
  selectedActivitiesCount = 0;
  activities = []; // TODO: assign type
  lockedTables: any = { state: false };
  isLoading: boolean;
  lock: boolean;
  lengthToCompare: number;
  selectedActivityAttributes: any; // TODO: assign type
  expandedLookup = [];
  faTrophy = faTrophy;
  faChevronRight = faChevronRight;
  addresses: AddressModel[];
  faChevronDown = faChevronDown;
  faSpinner = faSpinner;
  activity: any = {};
  rewards = [];
  allActivityToShow = [];
  concatActivity = [];
  checkAll: boolean;
  isSelected: any = {};


  constructor(
    public appDataService: AppDataService,
    private responseHelperService: ResponseHelperService,
    private locationService: LocationService,
    private instrumentsService: InstrumentsService,
    private behaviorsService: BehaviorsService,
    private hierarchySourcesService: HierarchySourcesService,
    private processingService: ProcessingService,
    private activityService: ActivityService,
    private addressService: AddressService,
    private tableHelperService: TableHelperService,
    private processingActivityLogService: ProcessingActivityLogService,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private modalHelper: NgbModal,
    private userPreferences: UserPreferencesService,
    private toastr: ToastrService,
    private globalData: AppDataService,
    public securityService: SecurityService,
    private userPreferencesService: UserPreferencesService,
    private dialog: MatDialog,
    private cdref: ChangeDetectorRef
  ) { }

  canReverse = this.securityService.allowReverse;

  ngOnInit(): void {
    // this.behaviorToLoad = parseInt(this.route.snapshot.queryParamMap.get('behaviorToLoad'));

    this.searchFilters = {
      formName: 'activityTabSearch',
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
          value: this.activitySearchParams?.activityKeyword ? this.activitySearchParams.activityKeyword : null,
        },
        {
          name: 'fromDate',
          type: 'date',
          placeholder: 'From:',
          value: this.activitySearchParams?.fromDate ? this.activitySearchParams.fromDate : null,
        },
        {
          name: 'toDate',
          type: 'date',
          placeholder: 'To:',
          value: this.activitySearchParams?.toDate ? this.activitySearchParams.toDate : null,
        },
        {
          name: 'reward',
          label: 'Reward Status',
          type: 'radio',
          value: null,
          allowEmpty: true,
          onChangeCallback: (value) => {
            console.log(value);
          },
          choices: [
            { label: 'All', value: null, isDefault: true },
            { label: 'Rewarded', value: 1 },
            { label: 'Not Rewarded', value: 0 }
          ],
        }
      ],
      searchCallback: () => { this.initNewSearch(); },
      triggerProcessing: () => { this.triggerProcessing(); },
      addActivity: () => { this.addActivity(); },
      triggerDebugMode: () => {this.triggerDebugMode();}
    };

    this.clearCrossTabSearchParams();

    this.defaultLocationName = this.userPreferencesService.service.getPreference('layout.defaultLocation');

    this.initFilter();

    if (!this.locations) {
      this.setLocations();
    }

    if (!this.instruments) {
      this.setInstruments();
    }

    this.behaviorsService.getBehaviors(0).subscribe((data) => {
      if (data.success) {
        this.allBehaviors = data.entity;
        this.behaviors = data.entity;
      }
    });

    this.hierarchySourcesService.getHierarchySources().subscribe((data) => {
      if (data.success) {
      //  this.hierarchySources = parseResponse(data);
      }
    });

    this.tableHelperService.showTitle();

    if (this.behaviorToLoad){
      this.addActivity();
    }
  }

  clearCrossTabSearchParams() {
    // clear search params;
    this.activitySearchParams = undefined;
    this.activitySearchParamsChange.emit(this.activitySearchParams);
    this.cdref.detectChanges();
  }

  initFilter(): void {
    this.limit = 20;
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.column = 3;
    this.filter.dir = 'DESC';
  }

  confirmReversal(activityIndex: number, id: number, event: any): void {
    event.preventDefault();
    const instance = this.modalHelper.open(ReversalConfirmationModalComponent);
    instance.componentInstance.id = id;
    instance.componentInstance.reversedOn = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    instance.componentInstance.reversedBy = this.globalData.username;
    instance.componentInstance.successEvent.subscribe((data: any) => {
      this.clearLookup();
        const rewardIndex = this.allActivityToShow[activityIndex].rewards.findIndex((reward: any) => reward.id === id);
        if (rewardIndex > -1) {
          this.allActivityToShow[activityIndex].rewards[rewardIndex].reversedBy = this.globalData.username;
        }
        instance.close();
    });
    instance.componentInstance.cancelEvent.subscribe((data: any) => {
      instance.close();
    });
  }

  setLocations(callback?: any): void {
    this.locationsFilter = {};
    this.locationsFilter.statuses = ['ACTIVE'];
    this.locationService.getAllLocations(this.locationsFilter).subscribe((data) => {
      if (data.success) {
        this.allLocations = parseResponse(data);
        this.locations = parseResponse(data);
        if(this.defaultLocationName) {
          for (var location of this.allLocations) {
           if(location.name === this.defaultLocationName) {
            this.defaultLocation = location;
            break;
           }
          }
        }
      }
      if (callback) {
        callback();
      }
    });
  }

  setInstruments(callback?: any): void {
    this.instrumentsService.getPartyInstruments(this.personId).subscribe((instruments) => {
      if (instruments.success) {
        this.instruments = instruments.entity;
        for (let i = this.instruments.length - 1; i >= 0; i--) {
          if(this.instruments[i].isPrimary){
            this.instrument = this.instruments[i];
            break;
          }
        }
      }
      if (callback) {
        callback();
      }
    });
  }

  initNewSearch(): void {
    this.initFilter();
    this.getActivities(false);
    this.selectedActivities = [];
    this.activities.forEach((activity: any) => this.isSelected[activity.id] = false);
    this.checkAll = false;
  }

  triggerProcessing(): void {
    this.processingService.processList(this.selectedActivities).subscribe(() => {
      this.responseHelperService.success('Activity successfully sent for processing');
      this.selectedActivities = [];
      this.activities.forEach((activity: any) => this.isSelected[activity.id] = false);
      this.checkAll = false;
    }, (data) => {
      this.responseHelperService.error(this, data.error.error, true);

    });
  }

  triggerDebugMode(): void {
    this.processingService.processList(this.selectedActivities, { activityInDebugMode: true }).subscribe(() => {
      this.responseHelperService.success('Activity successfully sent for processing');
      this.selectedActivities = [];
      this.activities.forEach((activity: any) => this.isSelected[activity.id] = false);
      this.checkAll = false;
    }, (data) => {
      this.responseHelperService.error(this, data.error.error, true);

    });
  }

  addActivity(id?: number): void {
    this.addressService.getAddressData().subscribe((data) => {
      if (data.success) {
        const instance = this.dialog.open(MemberActivityModalComponent, { width: '40vw', height: 'auto'});
        instance.componentInstance.addressData = parseResponse(data);
        instance.componentInstance.behaviorId = this.behaviorToLoad;
        instance.componentInstance.personId = this.personId;
        instance.componentInstance.instrument = this.instrument;
        instance.componentInstance.defaultLocation = this.defaultLocation;
        instance.componentInstance.successEvent.subscribe(() => {
          this.responseHelperService.success('Activity successfully created', true);
          this.setAddresses();
        });
        instance.afterClosed().subscribe(() => {
          this.behaviorToLoad = undefined;
          this.behaviorToLoadChange.emit(this.behaviorToLoad);
          this.cdref.detectChanges();
        })
      }
    });
  }

  showDetails(activity: any, index: number): void {
    this.processingActivityLogService.getProcessingHistoryForActivity(activity.id).subscribe((procHist) => {
      if (procHist.success) {
        this.allActivityToShow.forEach(a => {
          if (a.id === activity.id) {
            a.activityLog = procHist.entity;
            a.activityLog.forEach((log) => {
              this.processingActivityLogService.getProcessingLogMessages(log.id, activity.id).subscribe((procActivity) => {
                if (procActivity.success) {
                    log.activityLogMessages = procActivity.entity;
                }
              });
            });
          }
        });

        if (procHist.entity.length >= 0) {
          this.activityService.getAttributesForActivity(activity.id).subscribe((attr) => {
            if (attr.success) {
              this.allActivityToShow.forEach(a => {
                if (a.id === activity.id) {
                  a.attributes = attr.entity;
                }
              });
              activity.activityAttributes = this.selectedActivityAttributes;
              this.activityService.getRewardsForActivity(activity.id).subscribe((attrReward) => {
                if (attrReward.success) {
                  this.allActivityToShow.forEach(a => {
                    if (a.id === activity.id) {
                      a.rewards = attrReward.entity;
                    }
                  });
                }
              });
            }
          });
        }
      }
    });
  }

  setAddresses(): void {
    this.addressService.getPartyAddresses(this.personId).subscribe((addresses: any) => {
      if (addresses.success) {
        this.addresses = addresses.entity;
      }
    });
  }

  show(index): void {
    setTimeout(() => {
      this.expandedLookup[index] = !this.expandedLookup[index];
    }, 50);
  }

  clearLookup(): void {
    this.expandedLookup = [];
  }

  getActivities(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.isLoading = true;
    this.activityService.getActivitiesForMember(this.personId, this.filter).subscribe((data) => {
      if (concat) {
        this.activities = this.activities.concat(parseResponse(data));
        this.concatActivity = parseResponse(data).map(p => ({
          id: p.id,
          rewards: [],
          attributes: [],
          activityLog: [],
          description: ''
        }));
        this.allActivityToShow = this.allActivityToShow.concat(this.concatActivity);
      } else {
        this.clearLookup();
        this.activities = parseResponse(data);
        this.allActivityToShow = this.activities.map(p => ({
          id: p.id,
          rewards: [],
          attributes: [],
          activityLog: [],
          activityLogMessages: [],
          description: ''
        }));
        this.isSelected = {};
        this.checkAll = false;
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables.state = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables.state;
      this.selectedActivities = [];
      this.selectedActivitiesCount = 0;
      this.isLoading = false;
    }, () => {
      this.lockedTables.state = false;
      this.isLoading = false;
      this.lock = false;
      this.toastr.error('Error occured!');
    });
  }

  selectAllCb(): void {
    if (this.checkAll) {
      this.selectedActivities = this.activities.map((a: any) => a.id);
    } else {
      this.selectedActivities = [];
    }
    this.activities.forEach((activity: any) => this.isSelected[activity.id] = this.checkAll);
    this.selectedActivitiesCount = this.selectedActivities.length
  }

  selectOneCb(activityId: number): void {
    if (this.isSelected[activityId]) {
      this.selectedActivities.push(activityId);
    } else {
      const activityIndex = this.selectedActivities.findIndex((id: number) => id === activityId);
      if (activityIndex > -1) {
        this.selectedActivities.splice(activityIndex, 1);
      }
    }
    this.checkAll = this.selectedActivities.length > 0;
    this.selectedActivitiesCount = this.selectedActivities.length;
  }

  applyFilter(): void {
    this.searchFilters.applyFilters();
  }

  toggleSort(column: number): void {
    const dir = this.filter.column === column ? this.flipDirection() : 'DESC';
    this.initFilter();
    this.filter.dir = dir;
    this.filter.column = column;
    this.lock = true;
    this.getActivities(false);
  }

  flipDirection(): string {
    if (this.filter.dir === 'DESC') {
      return 'ASC';
    } else {
      return 'DESC';
    }
  }
}
