import { AppDataService } from './../app-data.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PromotionService {

  constructor(private http: HttpClient, private globalData: AppDataService) { }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getPromotionCampaigns(promotionSponsorId: number, partyId: number): any {
    const params: any = {};
    params.partyId = partyId;
    return this.http.get<any>(this.globalData.baseUrl + 'promotions/' + promotionSponsorId + '/campaigns', this.setParams(params));
  }

  removeCampaignFromPromotion(promotionSponsorId: number, campaignId: number): any {
    return this.http.delete<any>(
      this.globalData.baseUrl + 'promotion-sponsor-campaigns/' + promotionSponsorId + '/campaign/' + campaignId);
  }

  createOdUpdatePromotion(id, data): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'promotions/' + id, data);
    }
    return this.http.post(this.globalData.baseUrl + 'promotions', data);
  }

  getPromotion(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'promotions/' + id);
  }

  getAttributes(promotionId: number): any {
    return this.http.get(this.globalData.baseUrl + 'promotion-attributes/promotion/' + promotionId);
  }

  createOrUpdateAttribute(attrValueId: number, data: any): any {
    if (attrValueId) {
      return this.http.put(this.globalData.baseUrl + 'promotion-attributes/' + attrValueId, data);
    }
    return this.http.post(this.globalData.baseUrl + 'promotion-attributes/', data);
  }

  addCampaignToPromotion(data: any): any {
    return this.http.post(this.globalData.baseUrl + 'promotion-sponsor-campaigns', data);
  }

  getPromotionTemplates(promotionId: number, params: any): any {
    return this.http.get(this.globalData.baseUrl + 'promotion-configurations/promotion-templates/' + promotionId, this.setParams(params));
  }

  deletePromotionTemplate(templateId: number): any {
    return this.http.delete(this.globalData.baseUrl + 'promotion-configurations/promotion-template/' + templateId);
  }

  getPromotionDisplayTemplates(promotionId: number, data: any): any {
    return this.http.get(this.globalData.baseUrl +
      'promotion-configurations/promotion-display-templates/' +
      promotionId, this.setParams(data));
  }

  getPromotionDisplayTemplate(id: number, data: any): any {
    return this.http.get(this.globalData.baseUrl +
      'promotion-configurations/promotion-display-template/' +
      id, this.setParams(data));
  }

  deletePromotionDisplayTemplate(id: number): any {
    return this.http.delete(this.globalData.baseUrl + 'promotion-configurations/promotion-display-template/' + id);
  }

  getPromotionsByRegex(params: any): any {
    return this.http.get(this.globalData.baseUrl + 'promotions/promotions/pattern/', this.setParams(params));
  }

  getPromotions(params: any): any {
    return this.http.get(this.globalData.baseUrl + 'promotions', this.setParams(params));
  }

  getChildCompletionPromoData(promotionId: number): any {
    return this.http.get(this.globalData.baseUrl + 'promotions/child-completion-promo-data/' + promotionId);
  }

  getBehaviorCodes(promotionSponsorId: number): any {
    return this.http.get(this.globalData.baseUrl + 'promotions/promotion/' + promotionSponsorId);
  }

  updatePromotionDisplayTemplate(id: number, data: any, format?: string): any {
    if (format) {
      return this.http.put<any>(this.globalData.baseUrl +
        'promotion-configurations/promotion-display-template/' + id + '/?format=' + format, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      });
    } else {
      return this.http.put<any>(this.globalData.baseUrl + 'promotion-configurations/promotion-display-template/' + id, data);
    }
  }

  updatePromotionDisplayTemplateXml(id: number, data: any, format?: string): any {
    if (format) {
      return this.http.put<any>(this.globalData.baseUrl +
        'promotion-configurations/promotion-display-template/' + id + '/display/?format=' + format, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      });
    } else {
      return this.http.put<any>(this.globalData.baseUrl + 'promotion-configurations/promotion-display-template/' + id + '/display', data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      });
    }

  }

  createPromotionDisplayTemplate(data: any, format?: any): any {
    if (format) {
      return this.http.post<any>(this.globalData.baseUrl + 'promotion-configurations/promotion-display-template/?format=' + format, data, {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
        })
      });
    } else {
      return this.http.post<any>(this.globalData.baseUrl + 'promotion-configurations/promotion-display-template/', data);
    }
  }
}
