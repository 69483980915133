import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Filter, Choice, SearchFilters } from '../../data/class';
import { TableHelperService } from '../../services/helpers/table-helper.service';
import { Behavior } from '../../data/model';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faPlus, faTimes, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { BehaviorsService } from 'src/app/services/behaviors.service';
import { BehaviorsModalComponent } from '../behaviors-modal/behaviors-modal.component';
import { HierarchySourcesService } from 'src/app/services/hierarchy-sources.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { ToastrService } from 'ngx-toastr';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-behaviors-tab',
  templateUrl: './behaviors-tab.component.html',
  styleUrls: ['./behaviors-tab.component.scss']
})
export class BehaviorsTabComponent implements OnInit {

  lockedTables = false;
  isLoading: boolean;
  lock: boolean;
  filteredStatuses: any[] = [];
  filter = new Filter();
  limit = 20;
  statuses: Choice[];
  behaviors: Behavior[] = [];
  hierarchySources: any[] = [];
  lengthToCompare: number;
  show: boolean;
  faPlus = faPlus;
  faTimes = faTimes;
  faPencilAlt = faPencilAlt;
  faTrashAlt = faTrashAlt;
  searchFilters: SearchFilters;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private tableHelperService: TableHelperService,
    private hierarchySourcesService: HierarchySourcesService,
    private behaviorsService: BehaviorsService,
    private modalHelper: NgbModal,
    private responseHelper: ResponseHelperService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'contact-reasons-search',
      searchCallback: () => { this.initNewSearch(); },
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...'
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        }
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'New Behavior Type',
          if: true,
          callback: () => { this.behaviorModal(); },
          title: 'New Behavior Type'
        },
      ],
    };
    this.initFilter();
    this.getStatuses();
    this.getHierarchySource();
    this.show = true;
  }

  initNewSearch(): void {
    this.initFilter();
    this.getBehaviors(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  behaviorModal(id?: number): void {
    if (id) {
      this.behaviorsService.getBehavior(id).subscribe((data: any) => {
        if (data.success) {
          this.openModal(data.entity);
        }
      });
    } else {
      this.openModal();
    }
  }

  openModal(behavior?: Behavior): void {
    const instance = this.modalHelper.open(BehaviorsModalComponent);
    instance.componentInstance.behavior = behavior;
    instance.componentInstance.hierarchySources = this.hierarchySources;
    instance.componentInstance.successEvent.subscribe(data => {
      if (data.success) {
        this.responseHelper.success(`Behavior successfully ${instance.componentInstance.isEdit ? 'updated' : 'created'}`, true);
        this.initFilter();
        this.getBehaviors(false);
      }
    });
  }

  getBehaviors(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.behaviorsService.getBehaviors(this.filter).subscribe((data: any) => {
      if (concat) {
        this.behaviors = this.behaviors.concat(parseResponse(data));
      } else {
        this.behaviors = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.isLoading = false;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
    }, () => {
      this.lockedTables = false;
      this.lock = false;
      this.isLoading = false;
      this.toastr.error('Error occured!');
    });
  }

  deleteBehavior(behavior: Behavior): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent);
    instance.componentInstance.text = 'Delete Behavior with ID: ' + behavior.id;
    instance.componentInstance.header = 'Delete Behavior?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.behaviorsService.deleteBehavior(behavior.id).subscribe((data: any) => {
        this.responseHelper.success('Behavior successfully removed', true);
        this.behaviorsService.getBehavior(behavior.id).subscribe((response: any) => {
          if (response.success) {
            this.behaviors[this.behaviors.findIndex((e: Behavior) => e.id === behavior.id)] = response.entity;
          }
        });
      }, (data: any) => {
        this.responseHelper.error(this, data.error.error, true);
      });
    });
  }

  setStatuses(): void {
    this.initFilter();
    this.filter.statuses = this.tableHelperService.extractData(this.filteredStatuses);
    if (!this.filter.statuses) {
      delete this.filter.statuses;
    }
    this.getBehaviors(false);
  }

  findhierarchySource(id: number): any {
    return this.hierarchySources.find(element => element.id === id) || {};
  }

  getHierarchySource(): void {
    this.hierarchySourcesService.getHierarchySources().subscribe((data: any) => {
      if (data.success) {
        this.hierarchySources = parseResponse(data);
      }
    });
  }

}
