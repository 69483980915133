import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';
import { ContactReason } from '../data/model';

@Injectable({
  providedIn: 'root'
})
export class ContactReasonService {

  constructor(private http: HttpClient, private globalData: AppDataService) { }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getContactReasons(params: any): any {
    return this.http.get(this.globalData.baseUrl + 'contact-reasons', this.setParams(params))
  }

  getContactReason(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'contact-reasons/' + id)
  }

  createOrUpdateContactReason(id: number, contactReason: ContactReason): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'contact-reasons/' + id, contactReason)
    }
    return this.http.post(this.globalData.baseUrl + 'contact-reasons', contactReason)
  }

}
