import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { faChevronDown, faChevronLeft, faTimes, faChevronUp, faSpinner, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Filter, SearchFilters } from 'src/app/data/class';
import { CommunicationsService } from 'src/app/services/communications.service';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/services/utility.service';
import { DateHelperService } from 'src/app/services/helpers/date-helper.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Communication } from 'src/app/data/model';
import { ImportChannelHelper } from 'src/app/services/ImportChannelHelper.service';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-communication-history-tab',
  templateUrl: './communication-history-tab.component.html',
  styleUrls: ['./communication-history-tab.component.scss']
})
export class CommunicationHistoryTabComponent implements OnInit {

  commHistory: any[];
  @Input() commId: number;
  partyId: string;
  firstName: string;
  lastName: string;
  partyAttributeName: string;
  partyAttributeValue: string;
  keyword: string;
  activityFromDate: NgbDate;
  activityToDate: NgbDate;
  createdToDate: NgbDate;
  createdFromDate: NgbDate;
  behaviorCode: string;
  pAttributeName: string;
  pAttributeValue: string;
  aAttributeName: string;
  aAttributeValue: string;
  fromDateValue: any;
  toDateValue: any;
  communicationName: string;
  communications: Communication[];
  from: string;
  to: string;
  limit: number;
  filter = new Filter();
  commHistKeyword: string;
  lengthToCompare = 0;
  lockedTables: boolean;
  lock: boolean;
  isLoading: boolean;
  resize = true;
  subject: Subject<any> = new Subject<any>();
  faTimes = faTimes;
  faChevronLeft = faChevronLeft;
  faChevronDown = faChevronDown;
  faChevronUp = faChevronUp;
  faSpinner = faSpinner;
  faCheckSquare = faCheckSquare;
  expandedLookup = [];
  importChannels: any[];
  importChannel: any;
  searchFilters: SearchFilters;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private communicationsService: CommunicationsService,
    private utilityService: UtilityService,
    private toastr: ToastrService,
    private dateHelperService: DateHelperService,
    private importChannelHelper: ImportChannelHelper
  ) { }

  ngOnInit(): void {
    this.importChannels = this.importChannelHelper.getImportChannels();
    this.getComminications();
    this.initNewSearchHistory();
    this.subject.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe(item => {
        if (item.search !== '') {
          this.filter.search = item.search;
        } else {
          delete this.filter.search;
        }
        this.initFilter();
      });
  }

  initNewSearchHistory(): void {
    this.initFilter();
    this.getCommunicationsHistory(false);
  }

  initFilter(): void {
    this.limit = 20;
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.column = 1;
    this.filter.dir = 'desc';
  }

  clearLookup(): void {
    this.expandedLookup = [];
  }

  getComminications(): void {
    this.communicationsService.getCommunications({}).subscribe((data: any) => {
      if (data.success) {
        this.communications = parseResponse(data);
      }
    }, () => {
      this.toastr.error('Error occured');
    });
  }

  getCommunicationsHistory(concat: boolean): void {
    this.isLoading = true;
    this.filter.commId = this.commId;
    this.filter = this.utilityService.removeNullAndEmptyProperties(this.filter);
    this.communicationsService.getCommunicationsHistory(this.filter)
      .subscribe((data: any) => {
        if (concat) {
          this.commHistory = this.commHistory.concat(parseResponse(data));
        } else {
          this.commHistory = parseResponse(data);
        }
        this.lengthToCompare = parseResponse(data).length;
        this.newLengthEvent.emit(this.lengthToCompare);
        this.filter.offset += this.limit;
        this.lockedTables = parseResponse(data).length !== this.limit;
        this.lock = this.lockedTables;
        this.isLoading = false;
      }, () => {
        this.toastr.error('Error occured');
      });
  }

  resizeIbox(): void {
    this.resize = !this.resize;
  }

  setFilter(filterKey: string, filterValue: any, convertFromNgbToString?: boolean): void {
    if (convertFromNgbToString) {
      this[filterKey] = this.dateHelperService.ngbDateToString(filterValue);
      this.filter[filterKey] = this.dateHelperService.ngbDateToString(filterValue);
    } else {
      this[filterKey] = filterValue;
      this.filter[filterKey] = filterValue;
    }
  }

  handleFilterSegments($event: any): any {
    this.filter.segmentIds = $event;
  }

  clearInput(input: any): void {
    this[input] = '';
  }
}
