import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';

@Injectable({
  providedIn: 'root'
})
export class DocumentsService {

  constructor(
    private apollo: Apollo,
  ) { }

  getDocumentsQuery = gql`
  query Docs($first: Int, $type: String, $partyId: Int!,$after: String) {
    viewer {
      id
      documents (first: $first, type: $type, partyId: $partyId, after:$after) {
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
        edges {
          cursor
          node {
            id
            internalId
            insertedAt
            filename
            originalFilename
            type
          }
        }
      }
    }
  }
`
getDocumentsTypeQuery = gql`
query DocumentType {
  viewer {
    documentTypes {
      name
    }
  }
}
`
getDocumentsType(): any{
  return this.apollo.query({
    query: this.getDocumentsTypeQuery,
  });
}

getDocuments(first: number, type: String, partyId: number, after: String): any{
  return this.apollo.query({
    query: this.getDocumentsQuery,
    variables: { partyId, first, after, type }
  });
}

}
