import { faSpinner, faCheckSquare } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit, Input } from '@angular/core';
import { AppDataService } from 'src/app/app-data.service';

@Component({
  selector: 'app-filter-button-panel',
  templateUrl: './filter-button-panel.component.html',
  styleUrls: ['./filter-button-panel.component.scss']
})
export class FilterButtonPanelComponent implements OnInit {

  @Input() searchFilters: any;
  faCheckSquare = faCheckSquare;
  faSpinner = faSpinner;

  constructor(
    public appDataService: AppDataService
  ) { }

  ngOnInit(): void {
  }

}
