import { Component, Input, OnInit, Output } from '@angular/core';
import { Filter, SearchFilters } from 'src/app/data/class';
import { AppDataService } from 'src/app/app-data.service';
import { Apollo, gql } from 'apollo-angular';
import { DocumentModalComponent } from '../../../modals/document-modal/document-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { EventEmitter } from '@angular/core';
import { UsersService } from '../../../services/users.service';
import { ToastrService } from 'ngx-toastr';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { faDownload, faTimes } from '@fortawesome/free-solid-svg-icons';
import { HttpClient } from '@angular/common/http';
import { DocumentsService } from '../../../documents.service';
@Component({
  selector: 'app-member-document-tab',
  templateUrl: './member-document-tab.component.html',
  styleUrls: ['./member-document-tab.component.scss']
})
export class MemberDocumentTabComponent implements OnInit {

  @Input() personId: number;
  isCSRMANAGER = this.appDataService.isCSRManager;
  loading = true;
  error: any;
  userId: number;
  documents: any;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  data: any
  dataDocTypes: any
  viewer: any
  faTimes = faTimes;
  filter = new Filter();
  limit = 30;
  isLoading: boolean;
  lock: boolean;
  lockedTables: any = { state: false };
  lengthToCompare: number;
  types = []
  selected: any
  after = null;
  hasNextPage: boolean;
  faDownload = faDownload;

  constructor(
    public appDataService: AppDataService,
    private apollo: Apollo,
    private dialog: MatDialog,
    private toastr: ToastrService,
    private configurationService: ConfigurationService,
    private documentsService: DocumentsService,

    private http: HttpClient,


  ) {
  }

  ngOnInit() {
    this.initFilter();
    this.getDocuments();
    this.getDocumentTypes();
  }

  getDocumentTypes() {
    this.documentsService.getDocumentsType().subscribe((response: any) => {
      this.types = response.data.viewer.documentTypes
    }, (error: any) => {
      this.toastr.error('there was an error sending the query', error);
    });
  }

  download(event: any, documentId: string): void {
    event.preventDefault();
    const token = JSON.parse(atob(localStorage.getItem('token').split('.')[1])).sessionId;
    this.configurationService.getConfiguration('MEMBER_UI_LOCATION').subscribe((data: any) => {
      if (data.success && data.entity && data.entity.length > 0) {
        const memberUrl = data.entity[0].cfgValue;
        this.http.get(memberUrl + '/documents/' + documentId + '/download', {
          headers: {
            Authorization: 'Bearer ' + token
          },
          observe: 'response',
          responseType: 'blob'
        }).subscribe(resp => {
          const url = window.URL.createObjectURL(resp.body);
          let anchor = document.createElement("a");
          anchor.download = resp.headers.get('content-disposition').split('; ')[1].split('=')[1].replace(/"/g, '');
          anchor.href = url;
          anchor.click();
        });
      }
    });
  }

  getDocuments() {
    this.isLoading = true;
    this.documentsService.getDocuments(this.limit, this.filter.type === '' ? null : this.filter.type, this.personId, this.after).subscribe(({ data }) => {
      this.data = data;
      if (this.after === null) {
        this.documents = this.data.viewer.documents.edges;
      }
      else {
        this.documents = this.documents.concat(this.data.viewer.documents.edges);
      }
      this.limit = 1
      this.after = this.data.viewer.documents.pageInfo.endCursor
      this.hasNextPage = this.data.viewer.documents.pageInfo.hasNextPage
      this.viewer = this.data.viewer;
      this.lengthToCompare = this.data.viewer.documents.edges.length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.lockedTables = this.data.viewer.documents.edges.length !== this.limit;
      this.lock = !this.hasNextPage
      this.isLoading = false;
    }, (error: any) => {
      this.lockedTables = false;
      this.isLoading = false;
      this.lock = false;
      this.toastr.error('there was an error sending the query', error);
    });
  }

  initFilter(): void {
    this.limit = 30
    this.filter.limit = this.limit;
    this.filter.type = ''
    this.after = null
  }

  clearKeyword(): void {
    this.limit = 30
    this.selected = null
    this.after = null
    this.initFilter();
    this.filter.type = '';
    this.filter.limit = this.limit;
    this.getDocuments();
  }

  filterDocumentsTypes(type: any): void {
    this.initFilter();
    if (type) {
      this.filter.type = type.name;
    }
    this.filter.limit = this.limit;
    this.getDocuments();
  }

  showDocumentDetails(document: any): void {
    const instance = this.dialog.open(DocumentModalComponent, { width: '80vw' });
    instance.componentInstance.document = document;
  }
  clearAllFilters(): void {
    this.after = null
    this.filter.type = null;
    this.filter = new Filter();
    this.filter.limit = this.limit;
    this.initFilter();
    this.getDocuments();
  }

}
