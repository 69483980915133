<div class="panel-body">
  <div class="row">
    <div class="col-md-12">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group float-right">
            <a href="/sponsors/{{sponsorId}}/sponsorship/{{promoSponsorId}}/configuration/"
              class="btn btn-dark-blue btn-sm" title="Add Configuration">
              <fa-icon [icon]="faPlus"></fa-icon>
            </a>
          </div>
        </div>
      </div>
      <div *ngIf="searchFilters">
        <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
      </div>
      <div class="row mb-2">
        <table class="table table-pointable table-bordered infinite-table" width="100%">
          <thead>
            <tr>
              <th class="width-10">ID</th>
              <th class="width-20">Configuration Schema Name</th>
              <th class="width-25">Configuration Ref</th>
              <th class="width-20">Template Name</th>
              <th class="width-10">Sponsor ID</th>
              <th class="width-15">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let conf of configurations">
              <td>{{conf.id}}</td>
              <td title="{{conf.configurationSchemaName}}">{{conf.configurationSchemaName}}</td>
              <td title="{{conf.cfgRef}}">{{conf.cfgRef}}</td>
              <td title="{{conf.templateName}}">{{conf.templateName}}</td>
              <td><a
                  href="/sponsors/{{conf.promotionSponsor.sponsorPartyId}}">{{conf.promotionSponsor.sponsorPartyId}}</a>
              </td>
              <td>
                <a class="btn btn-dark-blue btn-sm edit float-right"
                  href="/sponsors/{{sponsorId}}/sponsorship/{{promoSponsorId}}/configuration/{{conf.id}}"
                  title="Edit Configuration">
                  <fa-icon [icon]="faPencilAlt"></fa-icon>
                </a>
              </td>
            </tr>
            <tr app-is-there-more [numColumns]="6" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
      <app-add-more-data-to-list (onScroll)="getConfigurations(true)" [lock]="lock" [isLoading]="isLoading">
      </app-add-more-data-to-list>
    </div>
  </div>
</div>
