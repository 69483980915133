<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-9">
      <div class="ibox">
        <div class="ibox-title">
          <div class="float-right">
            <a class="btn btn-sm btn-dark-blue new-user float-right" (click)="newSponsor()" title="New Sponsor"><fa-icon [icon]="faUserPlus"></fa-icon></a>
          </div>
          <h2>Sponsor Management</h2>
        </div>
        <div class="ibox-content">
          <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
          <div class="row">
            <div class="col-md-12">
              <div>
                <div class="row m-b">
                  <div class="col-md-12">
                    <!-- <button class="btn btn- btn-md filter-tag" [hidden]="!filter.search">
                      <span class="float-right close" (click)="clearSearchFilter()">&nbsp;</span>
                      <span class="ng-binding ng-scope">{{filter.search}}</span>
                    </button> -->
                    <button class="btn btn- btn-md filter-tag" *ngFor="let status of filteredStatuses">
                      <span class="float-right close" (click)="tableHelper.changeFilter(filteredStatuses, statuses, status, dtInstance, $event); setStatuses()">&nbsp;</span>
                      <span>{{status.value}}</span>
                    </button>
                  </div>
                </div>
                <div class="row">
                  <table class="table table-pointable table-bordered"  width="100%">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Status</th>
                        <th>Promotion Count</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let sponsor of sponsors" (click)="showSponsor($event, sponsor)">
                        <td><a routerLink="/sponsors/{{sponsor.id}}">{{sponsor.id}}</a></td>
                        <td>{{sponsor.name}}</td>
                        <td>{{sponsor.status}}</td>
                        <td>{{sponsor.promotionSponsorRecords}}</td>
                      </tr>
                      <tr app-is-there-more [numColumns]="4" [newLengthEvent]="newLengthEvent"></tr>
                    </tbody>
                  </table>
                </div>
                <app-add-more-data-to-list (onScroll)="getSponsors(true)" [lock]="lock" [isLoading]="isLoading"></app-add-more-data-to-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--MEMBER SELECTED-->
    <div class="col-lg-3" *ngIf="showPerson()">
      <div class="ibox ">
        <div class="row integration-details-bar"></div>
        <div class="ibox-content">
          <div id="contact-1" class="tab-pane active">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3 relative-position">
                    <div>
                        <span class="fa-stack fa-3x">
                          <i class="fa fa-circle fa-stack-2x blue-icon-color"></i>
                          <fa-icon [inverse]="true" [icon]="faHandshake" stackItemSize="1x"></fa-icon>
                        </span>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-12 form-group float-right">
                        <span id="status-label" class="badge float-right" [ngClass]="statusHelper[selectedSponsor.status]">{{selectedSponsor.status}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 float-left">
                        <h2>
                          <fa-icon [icon]="faUser"></fa-icon> {{selectedSponsor.username}}
                        </h2>
                        <div *ngIf="selectedSponsor.emails && selectedSponsor.emails.length">
                          <p [hidden]="!selectedSponsor.emails">
                            <fa-icon [icon]="faEnvelope"></fa-icon> {{selectedSponsor.emails.emailAddress}}
                          </p>
                        </div>
                        <p [hidden]="!selectedSponsor.url">
                          <fa-icon [icon]="faGlobe"></fa-icon><a href="{{selectedSponsor.url}}"> {{selectedSponsor.url}}</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-7">
                <div class="row" *ngIf="selectedSponsor.importDates">
                  <div class="col-xs-2">
                    <fa-icon [icon]="faCalendar"></fa-icon>
                  </div>
                  <div class="col-xs-4">
                    <span class="float-left">First Import:</span>
                    <br>
                    <!-- <span class="float-left">{{selectedSponsor.importDates.firstImportDate | date'yyyy-MM-dd'}}</span> -->
                    <span class="float-left">{{selectedSponsor.importDates.firstImportDate}}</span>
                  </div>
                  <div class="col-xs-2">
                    <fa-icon [icon]="faCalendar"></fa-icon>
                  </div>
                  <div class="col-xs-4">
                    <span class="float-left">Last Import:</span>
                    <br>
                    <!-- <span class="float-left">{{selectedSponsor.importDates.lastImportDate | date'yyyy-MM-dd'}}</span> -->
                    <span class="float-left">{{selectedSponsor.importDates.lastImportDate}}</span>
                  </div>
                </div>
              </div>
              <div class="col-md-5">
                <div class="row">
                  <div class="col-md-12 form-group float-right">
                      <a routerLink="/sponsors/{{selectedSponsor.id}}" class="btn btn-dark-blue btn-sm float-right" title="View"><fa-icon [icon]="faEye"></fa-icon></a>
                  </div>
                </div>
              </div>
            </div>
            <div class="client-detail">
              <div>
                <div [hidden]="!hasAttributes()">
                  <strong>Attributes</strong>
                  <ul class="list-group clear-list">
                    <!-- <li *ngFor="let at of selectedSponsor.attrs" class="list-group-item" [ngClass]="{'fist-item' $first}">
                      <span class="float-right"> {{at.attrValue}}</span> {{at.attrName}}
                    </li> -->
                  </ul>
                </div>
                <div [hidden]="!hasSegments()">
                  <strong>Segments</strong>
                  <ul class="list-group clear-list">
                    <!-- <li *ngFor="let sg of selectedSponsor.segments" class="list-group-item" [ngClass]="{'fist-item' $first}"> -->
                    <li *ngFor="let sg of selectedSponsor.segments" class="list-group-item">
                      <span class="float-right"> {{sg.status}}</span> {{sg.code}}
                    </li>
                  </ul>
                </div>
                <div *ngIf="selectedSponsor.notes" class="member-notes">
                  <strong>Notes</strong>
                  <p>
                    {{selectedSponsor.notes}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row integration-details-bar"></div>
      </div>
    </div>
  </div>
</div>
