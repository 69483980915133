import { HttpClient } from '@angular/common/http';
import { ResponseHelperService } from './../../services/helpers/response-helper.service';
import { ConfigurationService } from './../../services/configuration.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Integration } from 'src/app/data/model';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppDataService } from 'src/app/app-data.service';

@Component({
  selector: 'app-upload-integration-modal',
  templateUrl: './upload-integration-modal.component.html',
  styleUrls: ['./upload-integration-modal.component.scss']
})
export class UploadIntegrationModalComponent implements OnInit {

  // TODO: Can't create upload functionality as well. I don't have details to reproduce it, because upload integration on qa1 doesn't work.
  // https://www.npmjs.com/package/ngx-dropzone-wrapper

  @Input() public integration: Integration;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();

  systag = '';
  bartlebyURL: string;
  files: File[] = [];
  maxFileSize = 3000000;
  errorMsg: string;
  faTimes = faTimes;
  faSave = faSave;
  valid = false;

  constructor(
    private configurationService: ConfigurationService,
    private responseHelperService: ResponseHelperService,
    private globalData: AppDataService,
    private http: HttpClient,
    private modalHelper: NgbModal
  ) { }

  ngOnInit(): void {
    this.getSysTag();
    this.getBartlebyURL();
    this.getMaxFileSize();
  }

  getMaxFileSize(): void {
    this.configurationService.getConfiguration('MAX_FILE_SIZE').subscribe((data) => {
      if (data.success && data.entity[0] && data.entity[0].cfgValue) {
        this.maxFileSize = data.entity[0].cfgValue;
      }
    });
  }

  getSysTag(): void {
    this.configurationService.getSysTag().subscribe((data) => {
      this.systag = data.entity;
    }, (data) => {
      this.responseHelperService.error(this, data.error.error);
    });
  }

  getBartlebyURL(): void {
    this.configurationService.getConfiguration('bartleby_url').subscribe((data) => {
      if (data.success && data.entity[0]) {
        this.bartlebyURL = data.entity[0].cfgValue;
      }
    });
  }

  onUploadError(event): void {
 
    if (event[0].size > this.maxFileSize) {
      this.errorMsg = event.rejectedFiles[0].name + 'file is too big. Max file size is ' + this.maxFileSize / 1000000 + ' Mib';
    } else {
      this.errorMsg = event[1];
    }
  }

  onUploadSuccess(event): void {

  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  onSelect(event): void {
    if (event.rejectedFiles.length > 0) {
      if (event.rejectedFiles[0].reason === 'size') {
        this.errorMsg = event.rejectedFiles[0].name + ' file is too big. Max file size is: ' + this.maxFileSize / 1000000 + 'Mib';
      } else {
        this.errorMsg = 'Wrong acceptance type.';
      }
    }

    this.files.push(...event.addedFiles);
    if (this.files.length > 0) {
      this.valid = true;
    }
    const formData = new FormData();
    for (const file of this.files) {
      formData.append('file[]', file);
    }
  }

  onRemove(event): void {
    this.files.splice(this.files.indexOf(event), 1);
    if (this.files.length < 1) {
      this.valid = false;
    }
  }

  closeModal(): void {
    this.modalHelper.dismissAll();
  }

  upload(): void {

    const file = this.files[0];

    const meta = JSON.stringify({
      metatype: 'INBOUND',
      integrationName: this.integration.name,
      customerName: this.integration.customerName,
      importFileName: file.name,
      sysTag: this.systag,
      fileSize: file.size,
      stageLoadId: null
    });

    const regex = new RegExp(this.integration.fileNamePattern);

    if (!file.name.match(regex)) {
      this.responseHelperService.error(this, 'The filename is incorrect', true);
    } else if (file.size > this.maxFileSize) {
      this.responseHelperService.error(this, 'The specified file is larger than the maximum supported file size', true);
    } else if (!this.bartlebyURL || this.bartlebyURL === '') {
      this.responseHelperService.error(this, 'Invalid configuration', true);
    } else {
      const fd = new FormData();
      fd.append('document[files][]', this.files[0]);
      fd.append('document[type]', "INBOUND");
      fd.append('document[metadata]', meta);

      this.http.post<any>(this.bartlebyURL, fd).subscribe((response) => {
        this.responseHelperService.success('File successfully uploaded', true);
        // now execute the job
        if (this.integration.postUploadJobName && this.integration.postUploadJobName !== '') {
          const jobArgs = {
            sysTag: this.systag,
            integrationId: this.integration.id,
            archiveId: response[0].id
          };
          this.http.post<any>(this.globalData.baseUrl + 'jobs/' + this.integration.postUploadJobName + '/execute', jobArgs, {
            withCredentials: true
          }).subscribe(() => {
            this.responseHelperService.success('Successfully launched job', true);
          }, (responseError: any) => {
            this.responseHelperService.error(this, responseError.data.errorMsg);
          });
        }
      }, (response) => {
        this.responseHelperService.error(this, response, true);
      });
    }
    this.closeModal();
  }

}
