import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root'
})
export class ChirpService {

  constructor(
    private http: HttpClient,
    private globalData: AppDataService
  ) { }

  getDeliveryProtocols(params?: any): any { // TODO: test this without params
    return this.http.get(this.globalData.baseUrl + 'chirp/protocols', params ? params : null);
  }

  getPreferedCommunicationsForParty(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'chirp/' + id);
  }

  createOrUpdatePreferedCommunicationsForParty(personId: number, data: any): any {
    return this.http.post(this.globalData.baseUrl + 'chirp/' + personId, data);
  }
}
