<form [formGroup]="setPasswordForm" novalidate>
  <div class="modal-header">
    <h1>Set password</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="form-group">
      <label>New Password</label>
      <input #newPassword type="password" class="form-control" formControlName="newPassword" placeholder="New Password">
      <ng-container *ngIf="newPassword.type === 'password'; else newPasswordEyeSlash">
        <fa-icon [icon]="faEye" (click)="toggleViewability(newPassword)"></fa-icon>
      </ng-container>
      <ng-template #newPasswordEyeSlash>
        <fa-icon [icon]="faEyeSlash" (click)="toggleViewability(newPassword)"></fa-icon>
      </ng-template>
      <div class="mt-2" [hidden]="setPasswordForm.controls.newPassword.valid">
        <span class="text-danger" *ngIf="setPasswordForm.controls.newPassword.errors?.required">
          Input a password
        </span>
        <span class="text-danger" *ngIf="setPasswordForm.controls.newPassword.errors?.invalidPassword">
          Passwords must be at least 8 characters long and include a capital letter, a lowercase letter and a number
        </span>
      </div>
    </div>
    <div class="form-group">
      <label>Repeat Password</label>
      <input #repeatPassword type="password" class="form-control" formControlName="repeatPassword"
        placeholder="Repeat Password">
      <ng-container *ngIf="repeatPassword.type === 'password'; else repeatPasswordEyeSlash">
        <fa-icon [icon]="faEye" (click)="toggleViewability(repeatPassword)"></fa-icon>
      </ng-container>
      <ng-template #repeatPasswordEyeSlash>
        <fa-icon [icon]="faEyeSlash" (click)="toggleViewability(repeatPassword)"></fa-icon>
      </ng-template>
      <div class="mt-2" [hidden]="setPasswordForm.controls.repeatPassword.valid">
        <span class="text-danger"
          *ngIf="!setPasswordForm.controls.repeatPassword.errors?.invalidPassword && setPasswordForm.controls.repeatPassword.errors?.invalidMatch">
          Passwords do not match
        </span>
        <span class="text-danger" *ngIf="setPasswordForm.controls.repeatPassword.errors?.invalidPassword">
          Passwords must be at least 8 characters long and include a capital letter, a lowercase letter and a number
        </span>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" (click)="ok()" title="Save" [disabled]="!setPasswordForm.valid">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>