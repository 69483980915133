import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { faTimes, faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MemberRelationship } from 'src/app/data/class';

@Component({
  selector: 'app-manifest-member-relationship-form',
  templateUrl: './manifest-member-relationship-form.component.html',
  styleUrls: ['./manifest-member-relationship-form.component.scss']
})
export class ManifestMemberRelationshipFormComponent implements OnInit {

  @Input() index: number;
  @Input() memberRelationship: MemberRelationship;
  @Output() public memberRelationshipEvent: EventEmitter<any> = new EventEmitter();
  memberRelationshipForm: UntypedFormGroup;
  faTimes = faTimes;
  faSave = faSave;
  faPlus = faPlus;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.index !== null) {
      this.memberRelationshipForm = this.formBuilder.group({
        relationshipName: [this.memberRelationship.relationshipName],
        bidirectional: [this.memberRelationship.bidirectional],
        fromRoleName: [this.memberRelationship.fromRoleName],
        toRoleName: [this.memberRelationship.toRoleName],
        attrName: [this.memberRelationship.attrName],
        fromDate: [this.memberRelationship.fromDate],
        toDate: [this.memberRelationship.toDate],
        fieldName: [this.memberRelationship.fieldName],
        fieldIndex: [this.memberRelationship.fieldIndex],
        required: [this.memberRelationship.required],
        fieldLength: [this.memberRelationship.fieldLength],
        fieldRegex: [this.memberRelationship.fieldRegex]
      });
    } else {
      this.memberRelationshipForm = this.formBuilder.group({
        relationshipName: [''],
        bidirectional: [false],
        fromRoleName: [''],
        toRoleName: [''],
        attrName: [''],
        fromDate: [''],
        toDate: [''],
        fieldName: [''],
        fieldIndex: [''],
        required: [false],
        fieldLength: [''],
        fieldRegex: ['']
      });
    }
  }

  emitEvent(type: string, index?: number): void {
    const value = this.memberRelationshipForm.value;
    this.memberRelationshipEvent.emit({type, index, value});
    if (type === 'add') {
      this.memberRelationshipForm.reset();
    }
  }

}
