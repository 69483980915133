<div>
  <form>
    <label>Send a Communication to the Member</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="sendCommunication !== null">
        <div *ngFor="let sendComm of sendCommunication; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeConsequence(sendComm)" title="Remove Consequence">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label>Communication Type</label>
              <select name="communicationType_{{sendComm.communicationCode}}" class="form-control" [(ngModel)]="sendComm.communicationType" (ngModelChange)="updateConsequences()">
                <option *ngFor="let commType of communicationTypes" [selected]="sendComm.communicationType === commType.name" [value]="commType.name">{{commType.name}}</option>
              </select>
            </div>
            <div class="form-group">
              <label>Communication Code </label>
              <select name="communicationCode_{{sendComm.communicationCode}}" class="form-control" [(ngModel)]="sendComm.communicationCode" (ngModelChange)="updateConsequences()">
                <option *ngFor="let comm of communications" [selected]="sendComm.communicationCode === comm.name" [value]="comm.name">{{comm.name}}</option>
              </select>
            </div>
            <div class="col-md-12" *ngIf="sendComm.communicationBindings !== null">
              <div *ngFor="let binding of sendComm.communicationBindings; let i=index" class="border-card-padding form-group col-lg-12">
                <button class="btn btn-attention float-right" type="button" (click)="removeBinding(sendComm, binding)" title="Remove Binding">
                  <fa-icon [icon]="faTimes"></fa-icon>
                </button>
                <form>
                  <div class="form-group">
                    <label class="control-label" for="bindingName_{{binding.bindingName}}">Variable Name</label>
                    <input class="form-control m-b" type="text" id="bindingName_{{binding.bindingName}}" name="bindingName" [(ngModel)]="binding.binding.bindingName" (ngModelChange)="updateConsequences()" />
                  </div>
                  <div class="form-group">
                    <label class="control-label" for="bindingValue_{{binding.bindingName}}">Variable Value</label>
                    <input class="form-control m-b" type="text" id="bindingValue_{{binding.bindingName}}" name="bindingValue" [(ngModel)]="binding.binding.bindingValue" (ngModelChange)="updateConsequences()" />
                  </div>
                </form>
              </div>
              <div class="form-group col-md-12">
                <button class="btn btn-default float-right" (click)="initBindingObject(sendComm)"><fa-icon [icon]="faPlus"></fa-icon>Add Template Binding</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12 mt-2">
        <button class="btn btn-default float-right" (click)="initConsequenceObject()"><fa-icon [icon]="faPlus"></fa-icon> Send Communication</button>
      </div>
    </div>
  </form>
</div>
