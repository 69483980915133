import { Component, OnInit, } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { AppDataService } from '../app-data.service';

@Component({
  selector: 'app-communications',
  templateUrl: './communications.component.html',
  styleUrls: ['./communications.component.scss']
})
export class CommunicationsComponent implements OnInit {

  constructor(
    private titleHelper: Title,
    private globalData: AppDataService,
  ) { }

  active = 1;
  isAdmin = this.globalData.isAdmin;

  ngOnInit(): void {
    this.titleHelper.setTitle('Communications');
  }

}
