<div class="background-white">
  <div class="panel-body">
    <div class="wrapper wrapper-content">
      <div class="row">
        <div class="col-md-12">
          <div>
            <app-search-filter [searchFilters]="searchFilters" [helperData]="selectedEventsCount"></app-search-filter>
            <div class="row" *ngIf='needRange'>
              <div *ngIf='getRangeDay() > 30 || getRangeDay() === null' class="col-md-12 rangeDiv">
                <span>Invalid Date Range. Must be less than 30 days.</span>
              </div>
            </div>
            <div class="row table-sponsors" width="100%">
              <table class="table table-pointable table-bordered">
                <thead>
                  <tr>
                    <th class="width-55-px">
                      <div *ngIf="isCSRMANAGER"
                        class="checkbox checkbox-info float-right m-t-none m-b-none inline-block width-50-percentage">
                        <input id="select-all-cb" [(ngModel)]="checkAll" type="checkbox" (change)="selectAllCb()">
                        <label for="select-all-cb"></label>
                      </div>
                    </th>
                    <th>Ref</th>
                    <th>Source</th>
                    <th>Tag</th>
                    <th>Timestamp</th>
                    <th class="width-7-percentage">Utc Offset</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let event of events; let i = index">
                    <tr (click)="showDetails(i)">
                      <td>
                        <span *ngIf="event.activityCount > 0 && !expandedLookup[i]">
                          <fa-icon [icon]="faChevronRight"></fa-icon>
                        </span>
                        <span *ngIf="expandedLookup[i] && event.activityCount > 0 "
                          class="inline-block width-50-percentage">
                          <fa-icon [icon]="faChevronDown"></fa-icon>
                        </span>
                        <div *ngIf="isCSRMANAGER"
                          class="checkbox checkbox-info float-right mt-0 mb-0 inline-block width-50-percentage">
                          <input id="cb-{{i}}" type="checkbox"
                            (change)="$event.stopPropagation(); updateSelectedEvents(event,i)"
                            [(ngModel)]="isSelected[i]">
                          <label for="cb-{{i}}"></label>
                        </div>
                      </td>
                      <td>{{event.eventRef}}</td>
                      <td>{{event.eventSource}}</td>
                      <td>{{event.eventTag}}</td>
                      <td>{{event.eventTimestamp | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
                      <td>{{event.eventUtcOffset}}</td>
                    </tr>
                    <tr class="additional-data" *ngIf="expandedLookup[i] && activities.length > 0">
                      <td colspan="6">
                        <table class="reward-log table table-pointable additional">
                          <tbody>
                            <ng-container *ngFor="let activity of activities; let index=index">
                              <tr class="align-text-left">
                                <td>ID: {{activity.id}}</td>
                                <td>Activity Date: {{activity.activityDate}}</td>
                                <td>Ref Num: {{activity.referenceNumber}}</td>
                                <td>Behavior Code: {{activity.behaviorCode}}</td>
                                <td>Behavior Name: {{activity.behaviorName}}</td>
                                <td>Amt/Quantity: {{activity.tenderAmount}}</td>
                                <td>Reward Amt: {{activity.rewardAmount}}</td>
                            </ng-container>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  </ng-container>
                  <tr app-is-there-more [newLengthEvent]="newLengthEvent" [numColumns]="6">
                  </tr>
                </tbody>
              </table>
            </div>
            <app-add-more-data-to-list (onScroll)="getEvents(true)" [lock]="lock" [isLoading]="isLoading">
            </app-add-more-data-to-list>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>