<form [formGroup]="addressForm" novalidate="" autocomplete="off">
    <div class="modal-header">
      <h1>{{title}}</h1>
    </div>
    <div class="modal-body">
        <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
            {{errorMsg}}
            <a (click)="clearErrorMessage()">
              <span class="float-right close-button">
                <fa-icon [icon]="faTimes"></fa-icon>
              </span>
            </a>
        </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>Country</label>
            <ng-autocomplete
            #contry
            formControlName="country"
            focusFirst="true"
            (selected)='selectCourty($event)'
            searchKeyword="name"
            [data]="addressData.countries"
            [customFilter]="filterByNameAndCode"
            keyProperty="code"
            historyIdentifier="country"
            [itemTemplate]="stateTemplate"
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
            <div class="mt-1" [hidden]="addressForm.controls.country.valid">
              <small class="text-danger">Enter country</small>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label>State, Province, or Territory</label>
            <ng-autocomplete
            #states
            formControlName="state"
            focusFirst="true"
            (selected)='selectState($event)'
            searchKeyword="name"
            [data]="addressData.states"
            historyIdentifier="states"
            [customFilter]="filterByNameAndCode"
            [itemTemplate]="stateTemplate"
            [notFoundTemplate]="notFoundTemplate">
          </ng-autocomplete>
            <div class="mt-1" [hidden]="addressForm.controls.state.valid">
              <small class="text-danger">Enter state, province, or territory</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>City</label>
            <input type="text" name="city" class="form-control" formControlName="city" autocomplete="off">
            <div class="mt-1" [hidden]="addressForm.controls.city.valid">
              <small class="text-danger">Enter city</small>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label>Postal Code</label>
            <input type="text" name="postal_code" formControlName="postalCode" class="form-control"  autocomplete="off">
            <div class="mt-1" [hidden]="addressForm.controls.postalCode.valid">
              <small class="text-danger">Enter postal code</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>Address Line 1</label>
            <input type="text" name="address1" formControlName="address1" class="form-control"  autocomplete="off">
            <div class="mt-1" [hidden]="addressForm.controls.address1.valid">
              <small class="text-danger">Enter street name</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>Address Line 2</label>
            <input type="text" formControlName="address2" class="form-control" autocomplete="off">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>Address Type</label>
            <ng-select [items]="addressData.addressTypes" bindLabel="name" bindValue="name" formControlName="addressType">
            </ng-select>
            <div class="mt-1" [hidden]="addressForm.controls.addressType.valid">
              <small class="text-danger">Select addess type</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group form-check">
            <input type="checkbox" formControlName="isPrimary" id="isPrimary" class="form-check-input" />
            <label for="isPrimary" class="form-check-label">Primary</label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" type="submit" (click)="ok()" [disabled]="!addressForm.valid || !addressForm.dirty"> Save </button>
      <button class="btn btn-warning" type="button" (click)="cancel()"> Cancel </button>
    </div>
</form>

<ng-template #stateTemplate let-item>
  <a [innerHTML]="item.name"></a>
</ng-template>

<ng-template #notFoundTemplate let-notFound>
  <div [innerHTML]="notFound"></div>
</ng-template>
