<div class="wrapper wrapper-content">
  <div class="ibox">
    <div class="ibox-content">
      <div class="col-lg-12">
        <div class="mb-3">
          <h2 class="fullname">Redemptions dashboard</h2>
        </div>
        <div class="col-lg-12 mt-2">
          <div class="ibox">
            <div
              class="display-title border-bottom"
              data-toggle="collapse"
              href="#collapseCostCharts"
              role="button"
              (click)="collapseTotal('cost')"
              aria-expanded="false"
              aria-controls="collapseCostCharts"
            >
              <div class="d-flex">
                <div class="mt-1 ml-2">
                  <fa-icon [icon]="collapseCost ? faChevronUp : faChevronDown"> </fa-icon>
                </div>
                <h4 class="user-select-none ml-3">Total cost of redemptions charts</h4>
              </div>
            </div>
            <div class="mt-2" class="collapse multi-collapse" [ngbCollapse]="!collapseCost">
              <div class="row mt-2">
                <div class="col-md-4 offset-md-2">
                  <app-chart
                    [chartLabels]="chartLabelsDays"
                    charType="line"
                    labelName="Cost"
                    [data]="data7daysCost"
                    title="Total cost of redemptions for each day for the past 7 days"
                    chartTypeName="cost7days"
                  >
                  </app-chart>
                </div>
                <div class="col-md-4">
                  <app-chart
                    title="Total cost of redemptions for each week for the past 6 weeks"
                    charType="line"
                    labelName="Cost"
                    [data]="data6weeksCost"
                    [chartLabels]="weekdays"
                    chartTypeName="cost6weeks"
                  >
                  </app-chart>
                </div>
              </div>
            </div>
          </div>
          <div class="ibox">
            <div
              class="display-title border-bottom"
              data-toggle="collapse"
              href="#collapseCountCharts"
              role="button"
              (click)="collapseTotal('count')"
              aria-expanded="false"
              aria-controls="collapseCountCharts"
            >
              <div class="d-flex">
                <div class="mt-1 ml-2">
                  <fa-icon [icon]="collapseCount ? faChevronUp : faChevronDown"> </fa-icon>
                </div>
                <h4 class="user-select-none ml-3">Total number of redemptions charts</h4>
              </div>
            </div>
            <div class="mt-5" class="collapse multi-collapse" id="collapseCountCharts" [ngbCollapse]="!collapseCount">
              <div class="row mt-2">
                <div class="col-md-4 offset-md-2">
                  <app-chart
                    [chartLabels]="chartLabelsDays"
                    charType="line"
                    labelName="Count"
                    [data]="data7daysCount"
                    title="Number of redemptions for each day for the past 7 days"
                    chartTypeName="count7days"
                  >
                  </app-chart>
                </div>
                <div class="col-md-4">
                  <app-chart
                    title="Number of redemptions each week for the past 6 weeks"
                    charType="line"
                    labelName="Count"
                    [data]="data6weeksCount"
                    [chartLabels]="weekdays"
                    chartTypeName="count6weeks"
                  >
                  </app-chart>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg-12">
          <div *ngIf="show" class="row col-lg-12 ml-2">
            <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div class="row table-sponsors" width="100%">
                <table class="table table-bordered">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Order date</th>
                      <th>Account ID</th>
                      <th>Amount</th>
                      <th>Description</th>
                      <th>Party ID</th>
                      <th>First name</th>
                      <th>Last name</th>
                      <th>Total cost</th>
                      <th>Total points</th>
                      <th>Vendor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let red of redemptions">
                      <td>{{ red.id }}</td>
                      <td>{{ red.orderDate | date : "yyyy-MM-dd" }}</td>
                      <td>{{ red.accountId }}</td>
                      <td>{{ red.amount }}</td>
                      <td>{{ red.description }}</td>
                      <td>{{ red.partyId }}</td>
                      <td>{{ red.firstName }}</td>
                      <td>{{ red.lastName }}</td>
                      <td>{{ red.totalCost }}</td>
                      <td>{{ red.totalPoints }}</td>
                      <td>{{ red.vendor }}</td>
                    </tr>
                    <tr
                      app-is-there-more
                      [newLengthEvent]="newLengthEvent"
                      [memberDocuments]="true"
                      [numColumns]="12"
                    ></tr>
                  </tbody>
                </table>
              </div>
              <app-add-more-data-to-list (onScroll)="getRedemptions(true)" [lock]="lock" [isLoading]="isLoading">
              </app-add-more-data-to-list>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>