import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PromotionCategory } from '../data/model';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { PromotionCategoryService } from '../services/promotion-category.service';
import { SponsorsService } from '../services/sponsors.service';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-promotion-category-modal',
  templateUrl: './promotion-category-modal.component.html',
  styleUrls: ['./promotion-category-modal.component.scss']
})
export class PromotionCategoryModalComponent implements OnInit {
  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public promotionCategory: PromotionCategory;
  @Input() public sponsorId: number;
  title: string;
  isEdit: boolean;
  promotionCategoryForm: UntypedFormGroup;
  errorMsg: string;
  faSave = faSave;
  faTimes = faTimes;
  sponsors: any[] = [];

  constructor(private formBuilder: FormBuilder,
              private statusHelperService: StatusHelperService,
              private promotionCategoryService: PromotionCategoryService,
              private sponsorsService: SponsorsService,
              private modalHelper: NgbModal) { }

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.promotionCategory) {
      this.title = 'Edit Promotion Category';
      this.isEdit = true;
      this.promotionCategoryForm = this.formBuilder.group({
        name: [this.promotionCategory.name, Validators.required],
        status: [this.promotionCategory.status, Validators.required],
        sort: [this.promotionCategory.sort, Validators.required],
        sponsorPartyId: [{ value: this.promotionCategory.sponsorPartyId, disabled: true }, Validators.required],
      });
    } else {
      this.title = 'New Promotion Category';
      this.isEdit = false;
      this.promotionCategoryForm = this.formBuilder.group({
        name: ['', Validators.required],
        status: [ this.sponsorId && 'ACTIVE', Validators.required],
        sort: [0, Validators.required],
        sponsorPartyId: [this.sponsorId, Validators.required],
      });
    }
    this.sponsorsService.getAllSponsors({}).subscribe((data: any) => {
      if (data.success) {
        this.sponsors = parseResponse(data);
      }
    });
  }

  ok(): void {
    const promotionCategory = this.promotionCategoryForm.value;
    if (this.isEdit) {
      promotionCategory.sponsorPartyId = this.promotionCategory.sponsorPartyId;
    }
    this.promotionCategoryService.createOrUpdatePromotionCategory(this.promotionCategory && this.promotionCategory.id, promotionCategory)
    .subscribe(() => {
      this.successEvent.emit('success');
    }, (data: any) => {
      this.errorMsg = data.error.error;
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

}
