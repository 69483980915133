import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-programs',
  templateUrl: './programs.component.html',
  styleUrls: ['./programs.component.scss']
})
export class ProgramsComponent implements OnInit {
  activeId:number=1;
  tabs:any[] = [
    { title: 'Program', active: true  },
    { title: 'Campaign', active: false },
    { title: 'Promotion Type', active: false },
    { title: 'Promotion Category', active: false },
    { title: 'Packages', active: false },
    { title: 'Communication Strategy', active: false }
  ];

  constructor() {
  }
  
  ngOnInit(): void {}

}



