<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-sm-9">
      <div class="ibox">
        <div class="ibox-title">
          <h2>Outbound Load</h2>
        </div>
        <div class="ibox-content">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
              </div>
              <div class="row mt-3">
                <table class="table table-pointable table-bordered infinite-table">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th>Status</th>
                      <th>Outbound Date</th>
                      <th>Report ID</th>
                      <th>File Name</th>
                      <th>Created On</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let o of outbounds" (click)="showOutboundLoad($event, o)">
                      <td><a routerLink="/outbounds/{{selectedOutboundId}}/loads/{{o.id}}">{{o.id}}</a></td>
                      <td>{{o.status}}</td>
                      <td>{{o.outboundDate | date: 'yyyy-MM-dd HH:mm'}}</td>
                      <td>{{o.reportId}}</td>
                      <td>{{o.fileName}}</td>
                      <td>{{o.createdOn | date: 'yyyy-MM-dd HH:mm'}}</td>
                    </tr>
                    <tr app-is-there-more [numColumns]="6" [newLengthEvent]="newLengthEvent"></tr>
                  </tbody>
                </table>
              </div>
              <app-add-more-data-to-list (onScroll)="getOutboundLoad(true)" [lock]="lock" [isLoading]="isLoading">
              </app-add-more-data-to-list>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-3" *ngIf="selectedLoad">
      <div class="ibox responsive-no-scroll" [hidden]="!showLoad()">
        <div class="ibox ">
          <div class="row integration-details-bar"></div>
          <div class="ibox-content">
            <div id="contact-1" class="tab-pane active">
              <div class="row">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-3 relative-position">
                      <fa-icon class="fa-3x blue-icon-color" [icon]="faFolderOpen"></fa-icon>
                    </div>
                    <div class="col-md-9">
                      <div class="row">
                        <div class="col-md-12 form-group float-right">
                          <span id="status-label" class="badge float-right"
                            [ngClass]="statusHelper[selectedLoad.status]">{{selectedLoad.status}}</span>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-12 float-left">
                          <h2 class="word-break-break-all">{{selectedLoad.name}}</h2>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-md-5 offset-md-7">
                      <a class="btn btn-dark-blue btn-sm float-right"
                        routerLink="/outbounds/{{selectedOutboundId}}/loads/{{selectedLoad.id}}/" title="View Exports">
                        <fa-icon class="float-right" [icon]="faCopy"></fa-icon>
                      </a>
                </div>
              </div>
              <div class="client-detail">
                <strong>Details</strong>
                <ul class="list-group clear-list">
                  <li class="list-group-item fist-item">
                    <span class="float-right"> {{selectedLoad.outboundIntegrationId}}</span> Outbound ID
                  </li>
                  <li class="list-group-item">
                    <span class="float-right"> {{selectedLoad.outboundDate | date: 'yyyy-mm-dd'}}</span> Outbound
                    Date
                  </li>
                  <li class="list-group-item">
                    <span class="float-right"> <a
                        routerLink="/reports/{{selectedLoad.reportId}}">{{selectedLoad.reportId}}</a></span> Report ID
                  </li>
                  <li class="list-group-item">
                    <span class="pull-right"> {{selectedLoad.fileName}}</span> File Name
                  </li>
                  <li class="list-group-item">
                    <span class="pull-right"> {{selectedLoad.createdOn | date: 'yyyy-mm-dd'}}</span> Created On
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div class="row integration-details-bar"></div>
        </div>
      </div>
    </div>
  </div>
</div>