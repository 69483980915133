<form [formGroup]="activityForm">
  <h1 mat-dialog-title>{{title}}</h1>
  <mat-dialog-content>
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <mat-form-field appearance="outline" id="activity-modal-behavior-field">
            <mat-label>Search Behaviors</mat-label>
            <input type="text"
                   aria-label="Behavior code"
                   matInput
                   formControlName="behavior"
                   [matAutocomplete]="auto">
            <mat-autocomplete (optionSelected)="genRef()" #auto="matAutocomplete">
              <mat-option *ngFor='let behavior of filteredBehaviors | async' [value]="behavior.code">{{behavior.code}}</mat-option>
            </mat-autocomplete>
          </mat-form-field>
          <div class="mt-1" [hidden]="activityForm.controls.behavior.valid">
            <small class="text-danger">Select a valid behavior</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Activity Date</label>
          <div class="input-group date">
            <input class="form-control cursor-pointer" placeholder="yyyy-mm-dd" name="activityDate"
              (click)="expirationDate.toggle()" readonly="readonly" [maxDate]="maxDate" ngbDatepicker #expirationDate="ngbDatepicker"
              formControlName="activityDate" [footerTemplate]="footerTemplate">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="expirationDate.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
          <div class="mt-1" [hidden]="activityForm.controls.activityDate.valid">
            <small class="text-danger">Select activity date</small>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="col-sm-12">
          <div class="form-group">
            <label>Amount/Quantity</label>
            <input type="number" formControlName="amount" class="form-control">
            <div class="mt-1" [hidden]="activityForm.controls.amount.valid">
              <small class="text-danger">Please input amount/quantity</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Location</label>
          <ng-select placeholder="Search locations..." [items]="locations" bindLabel="codeName" bindValue="codeName"
            formControlName="location">
            <ng-template ng-label-tmp let-item="item">
              <span>{{item.codeName}}</span>
            </ng-template>
          </ng-select>
          <div class="mt-1" [hidden]="activityForm.controls.location.valid">
            <small class="text-danger">Select a valid location
            </small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Instrument</label>
          <ng-select placeholder="Search instruments..." [items]="instruments" bindLabel="instrumentNumber"
            bindValue="instrumentNumber" formControlName="instrument" (change)="genRef()">
            <ng-template ng-label-tmp let-item="item">
              <span>{{ item.instrumentNumber}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
              <div>{{item.instrumentNumber}}</div>
              <small>Type: {{item.instrumentType.code}}</small>
            </ng-template>
          </ng-select>
          <div class="mt-1" [hidden]="activityForm.controls.instrument.valid">
            <small class="text-danger">Select instrument</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Reference Number</label>
          <input type="text" formControlName="referenceNumber" (change)="setManualRef()" class="form-control"
            name="referenceNumber">
          <div class="mt-1" [hidden]="activityForm.controls.referenceNumber.valid">
            <small class="text-danger">Please input reference number</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <label (click)="showAttrs = !showAttrs;">Attributes <fa-icon *ngIf="!showAttrs" [icon]="faAngleDoubleDown">
          </fa-icon>
          <fa-icon *ngIf="showAttrs" [icon]="faAngleDoubleUp"></fa-icon>
        </label>
        <hr class="mb-1 mt-0">
      </div>
    </div>
    <div [hidden]="!showAttrs">
      <div class="row mt-3">
        <div class="col-md-4">
          <div class="form-group">
            <label>Name</label>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group">
            <label>Value</label>
          </div>
        </div>
        <div class="col-md-2">
          <button class="btn btn-sm btn-primary float-right" type="button" (click)="addAttr()"
            title="Add New Attribute">
            <fa-icon [icon]="faPlus"></fa-icon>
          </button>
        </div>
      </div>
      <div formArrayName="attrs">
        <div class="row" *ngFor="let at of getAttr().controls; let i=index" [formGroupName]="i">
          <div class="col-md-4">
            <div class="form-group">
              <input type="text" name="name" class="form-control" formControlName="name" />
            </div>
          </div>
          <div class="col-md-4">
            <input type="text" name="value" class="form-control" formControlName="value" />
          </div>
          <div class="col-md-2">
            <div class="form-group float-right">
              <button class="btn btn-danger btn-sm delete float-right" title="Delete Currect Attribute"
                (click)="removeAttr(i)">
                <fa-icon [icon]="faTrash"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-12">
        <label (click)="showAddress = !showAddress; addAddress()">Address <fa-icon *ngIf="!showAddress" [icon]="faAngleDoubleDown">
          </fa-icon>
          <fa-icon *ngIf="showAddress" [icon]="faAngleDoubleUp"></fa-icon>
        </label>
        <hr class="mb-1 mt-0">
      </div>
    </div>
    <div [hidden]="!showAddress">
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>Address Line 1</label>
            <input type="text" name="address1" formControlName="address1" class="form-control">
            <div class="mt-1" [hidden]="activityForm.controls.address1.valid">
              <small class="text-danger">Enter street name</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>Address Line 2</label>
            <input type="text" formControlName="address2" class="form-control">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>City</label>
            <input type="text" name="city" class="form-control" formControlName="city">
            <div class="mt-1" [hidden]="activityForm.controls.city.valid">
              <small class="text-danger" [hidden]="activityForm.controls.city.valid">Please input city</small>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label>State, Province, or Territory</label>
            <ng-autocomplete #states (inputFocused)="focusStates()" formControlName="state" searchKeyword="code"
              keyProprty="code" [data]="addressData.states" historyIdentifier="states" [itemTemplate]="stateTemplate"
              [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
            <div class="mt-1" [hidden]="activityForm.controls.state.valid">
              <small class="text-danger">Enter state, province, or territory</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>Zip</label>
            <input type="text" name="postal_code" formControlName="zip" class="form-control">
            <div class="mt-1" [hidden]="activityForm.controls.zip.valid">
              <small class="text-danger" [hidden]="activityForm.controls.zip.valid">Please input postalCode</small>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label>Country</label>
            <ng-autocomplete #contry (inputFocused)="focusCountries()" formControlName="country" searchKeyword="name"
              [data]="addressData.countries" keyProprty="name" historyIdentifier="states" [itemTemplate]="stateTemplate"
              [notFoundTemplate]="notFoundTemplate">
            </ng-autocomplete>
            <div class="mt-1" [hidden]="activityForm.controls.country.valid">
              <small class="text-danger">Enter country</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>Address Type</label>
            <ng-select [items]="addressData.addressTypes" bindLabel="name" bindValue="name"
              formControlName="addressType">
            </ng-select>
            <div class="mt-1" [hidden]="activityForm.controls.addressType.valid">
              <small class="text-danger">Select addess type</small>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Description</label>
          <textarea formControlName="description" type="text" class="form-control vertical-expand"></textarea>
        </div>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <mat-checkbox checked formControlName="process" name="isSubmit" id="cb-is-submitted">Submit for processing</mat-checkbox>
    <button mat-flat-button color="primary" cdkFocusInitial (click)="ok()" [disabled]="!activityForm.valid"> Save </button>
    <button mat-flat-button mat-dialog-close color="warn" (click)="cancel()"> Cancel </button>
  </mat-dialog-actions>
</form>

<ng-template #stateTemplate let-item>
  <a [innerHTML]="item.name"></a>
</ng-template>

<ng-template #notFoundTemplate let-notFound>
  <div [innerHTML]="notFound"></div>
</ng-template>

<ng-template #footerTemplate>
  <div class="width-100 datePickerTemplate">
  <button class="buttonDateTemplate"  (click)="clearInput(); expirationDate.toggle()">Clear</button>
  </div>
</ng-template>
