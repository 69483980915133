<form>
  <!--ng-if="selectTrigger === 'rewardedBehavior'" ng-submit="onSubmit(triggerForm)">-->
  <label>Apply these rules to any Reward:</label>
  <div class="form-group">
    <a
      class="control-label"
      ng-class="{ 'disabled-link': readOnly }"
      (click)="
        regexSearchModal(
          'PROMOTIONS',
          { object: triggers.primaryBehaviorOrRewardedBehavior[0], name: 'basePromotionCode' },
          { windowClass: 'wider-modal-window' },
          true,
          false
        )
      "
      title="Search for Promotions"
      >generated by the Promotion with Code(leave blank for any child promotions):</a
    >
    <input
      class="form-control form-readonly m-b"
      type="text"
      id="basePromotionCode"
      name="basePromotionCode"
      [(ngModel)]="triggers.primaryBehaviorOrRewardedBehavior[0].basePromotionCode"
      (ngModelChange)="updateTriggers()"
      (click)="
        regexSearchModal(
          'PROMOTIONS',
          { object: triggers.primaryBehaviorOrRewardedBehavior[0], name: 'basePromotionCode' },
          { windowClass: 'wider-modal-window' },
          true,
          false
        )
      "
      readonly
    />
  </div>
  <div class="form-group">
    <a
      class="control-label"
      ng-class="{ 'disabled-link': readOnly }"
      (click)="
        regexSearchModal(
          'BEHAVIORS',
          { object: triggers.primaryBehaviorOrRewardedBehavior[0], name: 'baseBehaviorCode' },
          { windowClass: 'wider-modal-window' },
          true,
          false
        )
      "
      title="Search for Behaviors"
      >from a Behavior whose Code matches:</a
    >
    <input
      class="form-control form-readonly m-b"
      type="text"
      id="baseBehaviorCode"
      name="baseBehaviorCode"
      [(ngModel)]="triggers.primaryBehaviorOrRewardedBehavior[0].baseBehaviorCode"
      (ngModelChange)="updateTriggers()"
      (click)="
        regexSearchModal(
          'BEHAVIORS',
          { object: triggers.primaryBehaviorOrRewardedBehavior[0], name: 'baseBehaviorCode' },
          { windowClass: 'wider-modal-window' },
          true,
          false
        )
      "
      readonly
    />
  </div>
  <div class="form-group">
    <a
      class="control-label"
      ng-class="{ 'disabled-link': readOnly }"
      (click)="
        regexSearchModal(
          'LOCATIONS',
          { object: triggers.primaryBehaviorOrRewardedBehavior[0], name: 'locationCode' },
          { windowClass: 'wider-modal-window' },
          true,
          false
        )
      "
      title="Search for Location"
      >from a Location or Source of (leave blank for Any):</a
    >
    <input
      class="form-control form-readonly m-b"
      type="text"
      id="locationCode"
      name="locationCode"
      [(ngModel)]="triggers.primaryBehaviorOrRewardedBehavior[0].locationCode"
      (ngModelChange)="updateTriggers()"
      (click)="
        regexSearchModal(
          'LOCATIONS',
          { object: triggers.primaryBehaviorOrRewardedBehavior[0], name: 'locationCode' },
          { windowClass: 'wider-modal-window' },
          true,
          false
        )
      "
      readonly
    />
  </div>
  <div class="form-group">
    <a
      class="control-label"
      ng-class="{ 'disabled-link': readOnly }"
      (click)="
        regexSearchModal(
          'INSTRUMENTTYPES',
          { object: triggers.primaryBehaviorOrRewardedBehavior[0], name: 'instrumentTypeCode' },
          { windowClass: 'wider-modal-window' },
          true,
          false
        )
      "
      title="Search for Identifier Type"
      >submitted on an Identifier of Type (leave blank for Any):</a
    >
    <input
      class="form-control form-readonly m-b"
      type="text"
      id="instrumentType"
      name="instrumentType"
      [(ngModel)]="triggers.primaryBehaviorOrRewardedBehavior[0].instrumentTypeCode"
      (ngModelChange)="updateTriggers()"
      (click)="
        regexSearchModal(
          'INSTRUMENTTYPES',
          { object: triggers.primaryBehaviorOrRewardedBehavior[0], name: 'instrumentTypeCode' },
          { windowClass: 'wider-modal-window' },
          true,
          false
        )
      "
      readonly
    />
  </div>
</form>
