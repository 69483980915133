import {User} from "../data/model";

import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { SecurityService } from './security/security.service';
import {Observable} from "rxjs";
import {map, tap} from "rxjs/operators";
import {AppDataService} from "../app-data.service";

@Injectable()
export class LoginGuard implements CanActivate {

  constructor(private router: Router,
              private globalData: AppDataService,
              private securityService: SecurityService
  ) {
  }

  public canActivate(): Observable<boolean> {
    // this.globalData.loggedIn;
    return new Observable<boolean>(observer => {
      this.securityService.requestCurrentUser.subscribe((user: User) => {
        if (user) {
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }
}

