<div class="panel-body">
  <div class="row">
    <div class="col-md-12" *ngIf="show">
      <div class="row">
        <div class="col-md-12">
        </div>
      </div>
      <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
      <div class="row">
        <table class="table table-pointable table-bordered" width="100%">
          <thead>
            <tr>
              <th>ID</th>
              <th>Code</th>
              <th>Name</th>
              <th>Hierarchy Source</th>
              <th>Description</th>
              <th>Parent</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let location of locations">
              <td>{{location.id}}</td>
              <td>{{location.code}}</td>
              <td>{{location.name}}</td>
              <td>{{findhierarchySource(location.hierarchySourceId).name}}</td>
              <td>{{location.description}}</td>
              <td>{{location.parentCode}}</td>
              <td>{{location.status}}</td>
              <td>
                <div class="float-left actions inline-table">
                  <a class="btn btn-dark-blue btn-sm edit action" (click)="locationModal(location.id)">
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                  </a>
                  <button class="btn btn-attention btn-sm delete action" [disabled]="location.status==='DELETED'"
                    (click)="deleteLocation(location.id)">
                    <fa-icon [icon]="faTrashAlt"></fa-icon>
                  </button>
                </div>
              </td> 
            </tr>
            <tr app-is-there-more [numColumns]="8" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
      <app-add-more-data-to-list (onScroll)="getLocatons(true)" [lock]="lock" [isLoading]="isLoading"></app-add-more-data-to-list>
    </div>
  </div>
</div>
