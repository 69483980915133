import { AppDataService } from './../app-data.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Segment } from '../data/model';

@Injectable({
  providedIn: 'root'
})
export class PersonsService {
  constructor(private http: HttpClient, private globalData: AppDataService) { }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getAttributes(partyId: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'party-attributes/party/' + partyId);
  }

  getProspectiveMember(partyId: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'party-prospective/' + partyId);
  }

  getAttributeProvenance(partyId: number, attrName: string): any {
    return this.http.get<any>(this.globalData.baseUrl + 'party-attributes/' + partyId + '/provenance/' + attrName);
  }

  deleteAttribute(partyId: number, attrValueId: number): any {
    return this.http.put<any>(this.globalData.baseUrl + 'party-attributes/' + partyId + '/delete/' + attrValueId, null);
  }

  getPersonSegments(partyId: number, withChildren: boolean): any {
    const params = {
      children: withChildren
    };
    return this.http.get<any>(this.globalData.baseUrl + 'segments/party/' + partyId, this.setParams(params));
  }

  getPrimaryPartyEmail(partyId: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'party-emails/party/' + partyId);
  }

  getAllSegmentsNodes(partyId?: number, withChildren?: boolean): any {
    if (partyId) {
      return this.getPersonSegments(partyId, withChildren);
    }
    return this.http.get<any>(this.globalData.baseUrl + 'segments/nodes');
  }

  getPartyAttributeHistory(partyId: number, params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'party-attributes/party/' + partyId + '/history', this.setParams(params));
  }

  getPersonSegmentsTree(partyId: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'segments/party/' + partyId + '/tree');
  }

  addSegmentsToParty(id: number, data: any): any {
    return this.http.post<any>(this.globalData.baseUrl + 'members/segments/' + id, data);
  }

  removeSegmentFromUser(memberId: number, segmentId: number): any {
    return this.http.delete<any>(this.globalData.baseUrl + 'members/' + memberId + '/segments/' + segmentId);
  }

  createOrUpdateAttribute(attrValueId: number, data: any): any {
    if (attrValueId) {
      return this.http.put(this.globalData.baseUrl + 'party-attributes/' + attrValueId, data);
    }
    return this.http.post(this.globalData.baseUrl + 'party-attributes/', data);
  }

  getAllGenders(): any {
    return this.http.get<any>(this.globalData.baseUrl + 'members/genders');
  }

  getAllSegmentsTree(params?: any): any {
    if (params) {
      return this.http.get<any>(this.globalData.baseUrl + 'segments/tree', this.setParams(params));
    }
    return this.http.get<any>(this.globalData.baseUrl + 'segments/tree');
  }

  createOrUpdateSegment(id: number, segment: Segment): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'segments/' + id, segment);
    }
    return this.http.post(this.globalData.baseUrl + 'segments', segment);
  }

}
