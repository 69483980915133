import { Component, Input, OnInit } from '@angular/core';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';

@Component({
  selector: 'app-chart',
  templateUrl: './chart.component.html',
  styleUrls: ['./chart.component.scss'],
})
export class ChartComponent implements OnInit {
  @Input() title: string;
  @Input() chartLabels: string[];
  @Input() labelName: string;
  @Input() data: any[];
  @Input() charType: any;
  @Input() chartTypeName: string;
  @Input() borderColor = '#0F3957';
  @Input() backgroundColor = '#93c8488c';

  public chartData: ChartDataset[];
  public chartOptions: ChartOptions;
  public chartColors: any[];
  public chartLegend = true;
  public chartType: ChartType;
  public chartPlugins = [];
  private axisInverted = false;

  constructor() {}

  ngOnInit() {
    this.chartType = this.charType;
    this.chartData = [{ data: this.data, label: this.labelName }];
    this.chartOptions = {
      responsive: true,
      backgroundColor: this.backgroundColor,
      borderColor: this.borderColor,
    };
    if (this.axisInverted) {
      this.chartOptions = { ...this.chartOptions, indexAxis: 'y' };
    }
  }

  changeChartType(e) {
    if (e.target.value === 'horizontalBar') {
      this.charType = 'bar';
      this.axisInverted = true;
    } else {
      this.charType = e.target.value;
      this.axisInverted = false;
    }

    this.ngOnInit();
  }
}