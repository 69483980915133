import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Survey, Answer } from '../data/model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-survey-questions-modal',
  templateUrl: './survey-questions-modal.component.html',
  styleUrls: ['./survey-questions-modal.component.scss']
})
export class SurveyQuestionsModalComponent implements OnInit {

  @Input() survey: any;
  @Input() surveyName: string;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();

  constructor(private modalHelper: NgbModal) { }


  ngOnInit(): void {
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  formatAnswers(answers: Answer[]): string {
    return (answers || []).join('\n');
  }

}
