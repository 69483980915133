import { Component, EventEmitter, Input, OnInit, Output, ViewChild, NgModule } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { ProgramService } from 'src/app/services/program.service';
import { PromotionConfigService } from 'src/app/services/promotion-config.service';
import { PromotionService } from 'src/app/services/promotion.service';
import { RewardTypeService } from 'src/app/services/reward-type.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { ActivatedRoute } from '@angular/router';
import { PromotionTemplate } from 'src/app/data/model';
import { Promotion } from 'src/app/data/model';
import * as $ from 'jquery';
import {Observable, Subject} from 'rxjs';
import { RegexSearchModalComponent } from 'src/app/regex-search-modal/regex-search-modal.component';
import { fromEvent, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-percentage-reward-consequence',
  templateUrl: './percentage-reward-consequence.component.html',
  styleUrls: ['./percentage-reward-consequence.component.scss']
})
export class PercentageRewardConsequenceComponent implements OnInit {
    @Input() percentOfValue: any;
    faTimes = faTimes;
    faPlus = faPlus;
    rewards: any;
    @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
    debouncer: Subject<string> = new Subject<string>();

  constructor(
    private router: ActivatedRoute,
    private formBuilder: FormBuilder,
    private formsModule: FormsModule,
    private modalHelper: NgbModal,
    private rewardTypeService: RewardTypeService
  ) {
      this.debouncer
        .pipe(debounce(() => interval(1000)))
        .subscribe((value) => this.inputChangeEmitter.emit(value));
    }

  ngOnInit(): void {
     this.getRewardTypes();
  }

  getRewardTypes(): void {
    this.rewardTypeService.getRewardTypes().subscribe((data: any) => {
      if (data.success) {
        this.rewards = parseResponse(data);
      }
    }, () => {

    });
  }

  initConsequenceObject(): void {
    var tmpObj = { class: "com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Consequences$PercentOfValue" };
    if(this.percentOfValue){
      this.percentOfValue.push(tmpObj);
    } else {
      this.percentOfValue = [];
      this.percentOfValue.push(tmpObj);
    }
  }

  updateConsequences() {
    this.debouncer.next(this.percentOfValue);
  }

  removeConsequence(consequence) {
    this.percentOfValue.splice(this.percentOfValue.indexOf(consequence), 1);
    this.updateConsequences();
  }
}
