<div class="row">
  <div class="col-md-12 mt-2" *ngIf="show">
    <div>
      <div class="row">
        <div class="col-sm-12">
          <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <table class="table table-pointable table-bordered">
            <thead>
              <tr>
                <th>ID</th>
                <th>Status</th>
                <th>Report Name</th>
                <th>Report Notes</th>
                <th *ngIf="isAdmin">Requested By</th>
                <th>Requested Date</th>
                <th>Format</th>
                <th>Error Message</th>
                <th>Download Link</th>
                <th>Report Parameters</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let reportResult of reportResults">
                <td>{{reportResult.id}}</td>
                <td>{{reportResult.status}}</td>
                <td>{{reportResult.reportName}}</td>
                <td>{{reportResult.notes}}</td>
                <td *ngIf="isAdmin">{{reportResult.userName}}</td>
                <td>{{reportResult.startedOn | date: 'yyyy-MM-dd hh:mm:ss'}}</td>
                <td>{{reportResult.extension}}</td>
                <td>{{reportResult.errorMessage}}</td>
                <td><a [hidden]="!(reportResult.downloadLink)" (click)="download($event, reportResult.downloadLink)"
                    href="{{reportResult.downloadLink}}" class="btn btn-primary btn-sm m-l float-right">Download</a>
                </td>
                <td>
                  <div class="float-left actions" class="inline-table">
                    <button class="btn btn-dark-blue btn-sm action" (click)="viewReportParams(reportResult)"
                      title="View Report Parameters">
                      <fa-icon [icon]="faEye"></fa-icon>
                    </button>
                  </div>
                </td>
              </tr>
              <tr app-is-there-more [numColumns]="isAdmin ? 10 : 9" [newLengthEvent]="newLengthEvent"></tr>
            </tbody>
          </table>
        </div>
      </div>
      <app-add-more-data-to-list (onScroll)="getReportResults(true)" [lock]="lock" [isLoading]="isLoading">
      </app-add-more-data-to-list>
    </div>
  </div>
</div>