<div class="panel-body">
  <div class="row">
    <div class="col-md-12">
      <button type="button" class="btn btn-sm btn-info float-right" (click)="instrumentModal()"
        *ngIf="isProgramManager">
        <fa-icon [icon]="faIdBadge"></fa-icon> New instrument
      </button>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4" *ngFor="let inst of instruments">
      <div class="widget lazur-bg p-xl-4">
        <div class="mb-3">
          <span class="badge float-right instruments" [ngClass]="statusHelper[inst.status]">{{inst.status}}</span>
          <h2>
            <span title="Primary" *ngIf="inst.isPrimary">
              <fa-icon [icon]="faStar"></fa-icon>
            </span>
            {{inst.instrumentType.code}}
          </h2>
        </div>
        <ul class="list-unstyled mt-3">
          <li>
            <span class="mr-2">
              <fa-icon [icon]="faCreditCard"></fa-icon>
            </span>
            <label>No:</label>
            {{inst.instrumentNumber}}
            <span *ngIf="!inst.instrumentNumber">n/a</span>
          </li>
          <li>
            <span class="mr-2">
              <fa-icon [icon]="faUser"></fa-icon>
            </span>
            <label>Holders Name:</label>
            {{inst.holderName}}
            <span *ngIf="!inst.holderName">n/a</span>
          </li>
          <li>
            <span class="mr-2">
              <fa-icon [icon]="faBriefcase"></fa-icon>
            </span>
            <label>Account:</label>
            {{inst.account}}
            <span *ngIf="!inst.account">n/a</span>
          </li>
          <li>
            <span class="mr-2">
              <fa-icon [icon]="faGlobe"></fa-icon>
            </span>
            <label>Country:</label>
            {{inst.country}}
            <span *ngIf="!inst.country">n/a</span>
          </li>
          <li>
            <span class="mr-2">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </span>
            <label>Activation date:</label>
            {{inst.activationDate | date: 'yyyy-mm-dd'}}
            <span *ngIf="!inst.activationDate">n/a</span>
          </li>
          <li>
            <span class="mr-2">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </span>
            <label>Expiry date:</label>
            {{inst.expiryDate | date: 'yyyy-mm-dd'}}
            <span *ngIf="!inst.expiryDate">n/a</span>
          </li>
          <li>
            <span class="mr-2">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </span>
            <label>Cancellation date:</label>
            {{inst.cancelationDate | date: 'yyyy-mm-dd'}}
            <span *ngIf="!inst.cancelationDate">n/a</span>
          </li>
          <li>
            <span class="mr-2">
              <fa-icon [icon]="faCalendar"></fa-icon>
            </span>
            <label>Close date:</label>
            {{inst.closeDate | date: 'yyyy-mm-dd'}}
            <span *ngIf="!inst.closeDate">n/a</span>
          </li>
          <li>
            <span class="mr-2">
              <fa-icon [icon]="faUser"></fa-icon>
            </span>
            <label>Closed by:</label>
            {{inst.closedBy}}
            <span *ngIf="!inst.closedBy">n/a</span>
          </li>
          <li>
            <span class="mr-2">
              <fa-icon [icon]="faBookmark"></fa-icon>
            </span>
            <label>Closed Description:</label>
            {{inst.closeDescription}}
            <span *ngIf="!inst.closeDescription">n/a</span>
          </li>
          <li>
            <span class="mr-2">
              <fa-icon [icon]="faBookmark"></fa-icon>
            </span>
            <label>Notes:</label>
            {{inst.notes}}
            <span *ngIf="!inst.notes">n/a</span>
          </li>
        </ul>
        <div class="row" *ngIf="isProgramManager">
          <div class="col-md-12">
            <div class="form-group float-right">
              <button type="button" class="btn btn-warning btn-sm" (click)="instrumentModal(inst.id)">
                <fa-icon [icon]="faEdit"></fa-icon>
              </button>
              <button [disabled]="(instruments.length < 2 ? true : false) || inst.isPrimary" type="button"
                class="btn btn-danger btn-sm" (click)="deleteInstrument(inst.id, false, inst.instrumentNumber)">
                <fa-icon [icon]="faTrash"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12">
      <small><fa-icon [icon]="faStar"></fa-icon> marks the primary instrument</small>
    </div>
  </div>
</div>