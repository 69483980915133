<fa-icon
  [icon]="faInfoCircle"
  size="lg"
  title="More Info"
  tooltip-trigger
  tooltip-animation="false"
  tooltip-placement="bottom"
  style="color: red"
>
</fa-icon>
