<div class="panel-body">
    <div class="row">
        <div class="col-md-12">
            <a class="btn btn-sm btn-primary float-right contact" (click)="roleModal()">
                <fa-icon [icon]="faPlus"></fa-icon> New access role
            </a>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="row">
                <div class="col-md-4" *ngFor="let acr of accessRoles">
                    <div class="widget style1 navy-bg">
                        <div class="row">
                            <div class="col-sm-3">
                                <fa-icon class="fa-5x" [icon]="faUnlockAlt"></fa-icon>
                            </div>
                            <div class="col-sm-9 text-right">
                                <h3 title="{{acr.roleName}}" class="font-bold elipsis">{{acr.roleName}}</h3>
                                <a class="btn btn-sm btn-primary" (click)="removeRole(acr)">
                                    <fa-icon [icon]="faTimes"></fa-icon>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>