import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppDataService } from '../app-data.service';
import { CommunicationType, EndpointType } from '../data/class';
import { Communication, DeliveryProtocol, Segment } from '../data/model';
import { CommunicationsService } from '../services/communications.service';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { StatusHelperService } from '../services/helpers/status-helper.service';
import { PersonsService } from '../services/persons.service';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-communications-modal',
  templateUrl: './communications-modal.component.html',
  styleUrls: ['./communications-modal.component.scss']
})
export class CommunicationsModalComponent implements OnInit {

  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public communication: Communication;
  title: string;
  isEdit: boolean;
  communicationForm: UntypedFormGroup;
  filteredSegments = new Array<Segment>();
  deliveryProtocols: DeliveryProtocol[];
  commTypes: CommunicationType[];
  endpointTypes: EndpointType[];
  selectedSegments = [];
  errorMsg: string;
  faSave = faSave;
  faTimes = faTimes;

  constructor(
    private formBuilder: FormBuilder,
    private modalHelper: NgbModal,
    private personsService: PersonsService,
    private globalData: AppDataService,
    private communicationsService: CommunicationsService,
    private responseHelperService: ResponseHelperService,
    private statusHelperService: StatusHelperService
  ) { }

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    if (this.communication) {
      const deliveryProtocol = this.communication.communicationDeliveryProtocols[0].deliveryProtocol;
      this.title = 'Edit Communication';
      this.isEdit = true;
      this.communicationForm = this.formBuilder.group({
        name: [this.communication.name, Validators.required],
        endpointType: [deliveryProtocol.endpointType.name, Validators.required],
        deliveryProtocol: [deliveryProtocol.id, Validators.required],
        commTypeId: [this.communication.communicationType && this.communication.communicationType.id, Validators.required],
        status: [this.communication.status, Validators.required],
        requirePreference: [this.communication.requirePreference || false]
      });

      if (this.communication.communicationDeliveryProtocols[0] &&
        this.communication.communicationDeliveryProtocols[0].deliveryProtocol.endpointType.name !== '') {
        this.getDeliveryProtocols(this.communication.communicationDeliveryProtocols[0].deliveryProtocol.endpointType.name);
      }
    } else {
      this.title = 'New Communication';
      this.isEdit = false;
      this.communicationForm = this.formBuilder.group({
        name: ['', Validators.required],
        endpointType: ['', Validators.required],
        deliveryProtocol: ['', Validators.required],
        segmentIds: [[], Validators.required],
        commTypeId: [null, Validators.required],
        status: ['', Validators.required],
        requirePreference: [false]
      });
    }

    this.communicationForm.get('endpointType').valueChanges.subscribe((endpointType: string) => {
      this.communicationForm.patchValue({ deliveryProtocol: null });
      this.getDeliveryProtocols(endpointType);
    });

    this.personsService.getPersonSegments(this.globalData.userId, true).subscribe((data: any) => {
      if (data.success) {
        this.filteredSegments = parseResponse(data);
      }
    });

    this.communicationsService.getCommunicationTypes().subscribe((data: any) => {
      if (data.success) {
        this.commTypes = parseResponse(data);
      }
    });

    this.communicationsService.getAllEndpointTypes().subscribe((data: any) => {
      if (data.success) {
        this.endpointTypes = parseResponse(data);
      }
    });


  }

  getDeliveryProtocols(endpointTypeName: string): void {
    if (endpointTypeName) {
      this.communicationsService.getDeliveryProtocols({ endpointTypeName }).subscribe((data: any) => {
        if (data.success) {
          this.deliveryProtocols = parseResponse(data);
        }
      });
    }
  }

  refreshDeliveryProtocols(): void {
    if (this.communication) {
      this.communication.communicationDeliveryProtocols[0].deliveryProtocol.id = null;
    }
    this.getDeliveryProtocols(this.communicationForm.value.endpointType);
  }

  ok(): void {
    let formValue = this.communicationForm.value;
    if (!formValue.segmentIds) {
      formValue.segmentIds = [];
    }
    if (this.isEdit) {
      this.selectedSegments.forEach((segment: Segment) => {
        formValue.segmentIds.push(segment.id);
      });
      formValue = { ...this.communication, ...formValue };
    }
    formValue.communicationDeliveryProtocols = [{
      deliveryProtocol: {
        endpointType:
          { name: this.communicationForm.controls.endpointType.value },
        id: this.communicationForm.controls.deliveryProtocol.value
      }, isDefault: false
    }];
    delete formValue.endpointType;
    delete formValue.deliveryProtocol;
    this.communicationsService.createOrUpdateCommunications(this.communication && this.communication.id, formValue)
      .subscribe(() => {
        this.responseHelperService.success('Communication successfully updated', true);
        this.successEvent.emit('success');
      }, (data: any) => {
        this.responseHelperService.error(this, data.error.error, false);
      });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

}
