import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { Adjustment } from 'src/app/data/model';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { MembersService } from 'src/app/services/members.service';

@Component({
  selector: 'app-adjustment-modal',
  templateUrl: './adjustment-modal.component.html',
  styleUrls: ['./adjustment-modal.component.scss']
})
export class AdjustmentModalComponent implements OnInit {

  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public adjustment: Adjustment;
  @Input() public personId: number;
  @Input() public additionalAttributes: any = {};
  faTimes = faTimes;
  title: string;
  isEdit: boolean;
  adjustmentForm: UntypedFormGroup;
  errorMsg: string;
  amounts: any[] = [];
  accounts: any[] = [];

  constructor(private formBuilder: FormBuilder,
              private ressponseHelperService: ResponseHelperService,
              private membersService: MembersService,
              private modalHelper: NgbModal) { }


  ngOnInit(): void {
    if (this.adjustment) {
      this.title = 'Edit Adjustment';
      this.isEdit = true;
      this.adjustmentForm = this.formBuilder.group({
        accountId: [this.adjustment.account.id, Validators.required],
        amount: [this.adjustment.amount, Validators.required],
        txnNotes: [this.adjustment.txnNotes],
      });
    } else {
      this.title = 'New Adjustment';
      this.isEdit = false;
      this.adjustmentForm = this.formBuilder.group({
        accountId: ['', Validators.required],
        amount: [null, Validators.required],
        txnNotes: [''],
      });
    }
  }

  ok(): void {
    let value  = this.adjustmentForm.value;
    value.partyId = this.personId;
    value.rewardType = this.additionalAttributes.accounts.find(account => account.id === Number(value.accountId)).rewardType;
    this.membersService.createAdjustmentTxn(this.personId, value).subscribe((data: any) => {
      this.ressponseHelperService.success('Adjustment successfully created', true);
      var account = this.accounts.find((el: any)=> {
        return el.id === data.entity.accountId;
      });
      if(account){
        account.balance += data.entity.amount;
      }
      this.successEvent.emit(account);
    }, (data: any) => {
       this.ressponseHelperService.error(this,data.error.error)
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

}
