<div>
  <form>
    <label>Add Attribute</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="addAttribute !== null">
        <div *ngFor="let addAttr of addAttribute; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeConsequence(addAttr)" title="Remove Consequence">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label class="control-label" for="attrName_{{addAttr.attrName}}">Attribute Name</label>
              <input class="form-control m-b" type="text" id="attrName_{{addAttr.attrName}}" name="attrName" [(ngModel)]="addAttr.attrName" (ngModelChange)="updateConsequences()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="attrValue_{{addAttr.attrName}}">Attribute Value</label>
              <input class="form-control m-b" type="text" id="attrValue_{{addAttr.attrName}}" name="attrValue" [(ngModel)]="addAttr.attrValue" (ngModelChange)="updateConsequences()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="timestampValue_{{addAttr.attrName}}">if the Value represents a Timestamp, use the format:</label>
              <input class="form-control m-b" type="text" id="timestampValue_{{addAttr.attrName}}" name="timestampValue" [(ngModel)]="addAttr.timestampValue" (ngModelChange)="updateConsequences()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="effValue_{{addAttr.attrName}}">With an effective date in:</label>
              <input class="form-control m-b" type="number" id="effValue_{{addAttr.attrName}}" name="effValue" [(ngModel)]="addAttr.effValue" (ngModelChange)="updateConsequences()" />
            </div>
            <div class="form-group">
              <select name="effPeriod_{{addAttr.attrName}}" class="form-control" [(ngModel)]="addAttr.effPeriod" (ngModelChange)="updateConsequences()">
                <option *ngFor="let period of periods" [selected]="addAttr.effPeriod === period.value" [value]="period.value">{{period.value}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="expValue_{{addAttr.attrName}}">and an expiration date in:</label>
              <input class="form-control m-b" type="number" id="expValue_{{addAttr.attrName}}" name="expValue" [(ngModel)]="addAttr.expValue" (ngModelChange)="updateConsequences()" />
            </div>
            <div class="form-group">
              <select name="expPeriod_{{addAttr.attrName}}" class="form-control" [(ngModel)]="addAttr.expPeriod" (ngModelChange)="updateConsequences()">
                <option *ngFor="let period of periods" [selected]="addAttr.expPeriod === period.value" [value]="period.value">{{period.value}}</option>
              </select>
            </div>
            <div class="form-group">
              <label>from:</label>
              <select name="expStart_{{addAttr.attrName}}" class="form-control" [(ngModel)]="addAttr.expStart" (ngModelChange)="updateConsequences()">
                <option *ngFor="let dateOption of dateOptions" [selected]="addAttr.expStart === dateOption.value" [value]="dateOption.value">{{dateOption.text}}</option>
              </select>
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initConsequenceObject()"><fa-icon [icon]="faPlus"></fa-icon> Add Attribute</button>
      </div>
    </div>
  </form>
</div>
