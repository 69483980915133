import { GlobalEventService } from './../../../services/global-event.service';
import { InteractionEntryModalComponent } from './../interaction-entry-modal/interaction-entry-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MemberInteraction } from './../../../data/model';
import { TicketService } from './../../../services/ticket.service';
import { ConfigurationService } from './../../../services/configuration.service';
import { Filter, Choice, SearchFilters } from './../../../data/class';
import { UserPreferencesService } from './../../../services/user-preferences.service';
import { ContactMethodHelperService } from './../../../services/helpers/contact-method-helper.service';
import { TableHelperService } from './../../../services/helpers/table-helper.service';
import { AppDataService } from './../../../app-data.service';
import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { faChevronRight, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { ResponseHelperService } from '../../../services/helpers/response-helper.service';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-notes-tab',
  templateUrl: './notes-tab.component.html',
  styleUrls: ['./notes-tab.component.scss']
})
export class NotesTabComponent implements OnInit {

  @Input() personId: number;
  @Input() openTicket: any;
  filteredStatuses: any[] = [];
  notesKeyword = '';
  createdBy = '';
  jiraLink: string;
  lockedTables = false;
  limit = 20;
  filter = new Filter();
  statuses: Choice[];
  isLoading = false;
  notes: MemberInteraction[] = [];
  lengthToCompare: number;
  lock = false;
  show = false;
  searchFilters = new SearchFilters();
  numColumns: number;
  selectedNoteId = -1;
  isDetailsShowed = false;
  faChevronRight = faChevronRight;
  faChevronDown = faChevronDown;
  selectedTicket: any;
  showDetails = false;
  cannotEdit: boolean;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private globalData: AppDataService,
    private tableHelperService: TableHelperService,
    private contactMethodHelper: ContactMethodHelperService,
    private userPreferencesService: UserPreferencesService,
    private configurationService: ConfigurationService,
    private ticketService: TicketService,
    private toastr: ToastrService,
    private modalHelper: NgbModal,
    private globalEventService: GlobalEventService,
    private responseHelperService: ResponseHelperService
  ) { }

  isCSRManager = this.globalData.isCSRManager;
  contactMethods = this.contactMethodHelper.getContactMethods('name');
  jiraAccess = this.userPreferencesService.service.getPreference('layout.allowJiraAccess');

  ngOnInit(): void {

    this.searchFilters = {
      formName: 'notesSearch',
      filters: [
        {
          name: 'keyword',
          placeholder: 'keyword'
        },
        {
          name: 'startedBy',
          placeholder: 'Created by'
        },
        {
          name: 'status',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('NOTES'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        }
      ],
      searchCallback: () => { this.initNewSearch(); }
    };
    this.initFilter();
    this.getStatuses();
    this.getConfiguration();

    this.show = true;

    this.numColumns = !this.personId ? 11 : 12;

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.personId) {
      this.initNewSearch();
    }
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getConfiguration(): void {
    this.configurationService.getConfiguration('JIRA_SSO').subscribe((data: any) => {
      if (data.success && parseResponse(data).length > 0) {
        this.jiraLink = parseResponse(data)[0].cfgValue;
      }
    });
  }

  getStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('NOTES');
  }

  selectNote(note: any): void {
    if (note.id !== this.selectedNoteId && this.isDetailsShowed === true) {
      this.isDetailsShowed = false;
    }
    this.selectedTicket = null;
    this.selectedNoteId = note.id;
    this.isDetailsShowed = !this.isDetailsShowed;

    if (this.isDetailsShowed) {
      this.ticketService.getTicketById(note.id).subscribe((data: any) => {
        if (data.success) {
          if (data.entity.entryList.length > 0) {
            this.selectedTicket = parseResponse(data);
            this.showDetails = true;
          }
        }
      });
    }
  }

  update(note: any): void {
    const dataToSend: any = {};
    dataToSend.data = { id: note.id };
    dataToSend.toComponent = 'tickets';
    this.globalEventService.sendGlobalEvent(dataToSend);
  }

  setStatuses(): void {
    this.initFilter();
    this.filter.status = this.tableHelperService.extractData(this.filteredStatuses);
    this.getInteractions(false);
  }

  editLog(ticketId: number, entryId: number): void {
    let ticketEnrty: any = {};
    this.ticketService.getTicketEnrty(ticketId, entryId).subscribe((data: any) => {
      if (data.success) {
        ticketEnrty = parseResponse(data);
        const instance = this.modalHelper.open(InteractionEntryModalComponent);
        instance.componentInstance.ticketEnrty = ticketEnrty;
        instance.componentInstance.successEvent.subscribe(() => {
          this.initFilter();
          this.getInteractions(false);
          instance.close();
        });
      }
    });
  }

  getInteractions(concat: boolean): void {
    this.isLoading = true;
    if (typeof this.searchFilters.getFilters === 'function') {
      this.filter = this.searchFilters.getFilters(this.filter);
    }
    if (this.personId !== null && this.personId !== undefined) {
      this.filter.member = this.personId;
    }
    this.ticketService.getInteractions(this.filter).subscribe((data: any) => {
      if (concat) {
        this.notes = this.notes.concat(parseResponse(data));
      } else {
        this.notes = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
    }, () => {
      this.lockedTables = false;
      this.isLoading = false;
      this.lock = false;
      this.responseHelperService.error(this, 'Error occurred!', true);
    });
  }

  resolve(note: any): void {
    const update = note;
    update.status = 'COMPLETED';
    this.ticketService.createOrUpdateTicket(update, update.id).subscribe(() => {
      const dataToSend: any = {};
      dataToSend.data = { id: note.id };
      dataToSend.toComponent = 'tickets';
      dataToSend.action = 'resolve';
      this.globalEventService.sendGlobalEvent(dataToSend);
      this.toastr.success('Successfully resolved!');
    });
  }

  applySearch(): void {
    this.getInteractions(false);
  }

  initNewSearch(): void {
    this.initFilter();
    this.getInteractions(false);
  }

  clearAllFilters(): void {
    this.searchFilters.clearAll();
  }

  applyFilter(): void {
    this.searchFilters.applyFilters();
  }
}
