import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { faUserPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Segment } from 'src/app/data/model';
import { AppDataService } from 'src/app/app-data.service';
import { PersonsService } from 'src/app/services/persons.service';
import { SponsorsService } from 'src/app/services/sponsors.service';
import { ReportsService } from 'src/app/services/reports.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { AddUserToSegmentModalComponent } from 'src/app/member/member-tabs/add-user-to-segment-modal/add-user-to-segment-modal.component';

@Component({
  selector: 'app-segments-tab',
  templateUrl: './segments-tab.component.html',
  styleUrls: ['./segments-tab.component.scss']
})
export class SegmentsTabComponent implements OnInit {

  @Input() personId: number;
  @Input() sponsorId: number;
  @Input() reportId: number;
  @Input() type: string;
  @Input() halfSize: boolean;
  isLastSegment = true;
  typePretty: string;
  filtered: any[] = [];
  allSegments: Segment[] = [];
  id: number;
  faUserPlus = faUserPlus;
  faTimes = faTimes;
  query: any;
  subject: Subject<any> = new Subject<any>();

  constructor(
    private globalData: AppDataService,
    private personsService: PersonsService,
    private sponsorsService: SponsorsService,
    private reportsService: ReportsService,
    private responseHelperService: ResponseHelperService,
    private router: Router,
    // Don't remove http dependency.
    // It looks like unused, but it needs to be included because of way how we handling http segment functions below
    private http: HttpClient,
    private modalHelper: NgbModal
  ) { }

  currentUserId = this.globalData.userId;
  isProgramManager = this.globalData.isProgramManager;

  getSegmentTree = this.personsService.getPersonSegmentsTree;
  getSegment = this.personsService.getPersonSegments;
  addToSegment = this.personsService.addSegmentsToParty;
  removeFromSegment = this.personsService.removeSegmentFromUser;

  ngOnInit(): void {
    this.typePretty = this.type ? this.type.charAt(0).toUpperCase() + this.type.slice(1) : undefined;
    this.id = this.personId;
    if (this.type === 'sponsorship' || this.type === 'packagedSponsorship') {
      this.getSegmentTree = this.sponsorsService.getPromotionSponsorSegmentsTree;
      this.getSegment = this.sponsorsService.getPromotionSponsorSegments;
      this.addToSegment = this.sponsorsService.addSegmentsToPromotionSponsor;
      this.removeFromSegment = this.sponsorsService.removePromotionSponsorSegments;
    } else if (this.type === 'report') {
      this.getSegmentTree = this.reportsService.getReportSegmentsTree;
      this.getSegment = this.reportsService.getReportSegments;
      this.addToSegment = this.reportsService.addSegmentsToReport;
      this.removeFromSegment = this.reportsService.removeReportSegments;
      this.id = this.reportId;
    }

    this.getSegmentTree(this.id).subscribe((segments: any) => {
      if (segments.success) {
        this.allSegments = segments.entity;
        this.filtered = segments.entity;
        this.checkIsLast();
      }
    });

    this.subject.pipe(
      debounceTime(300),
      distinctUntilChanged())
      .subscribe((query: any) => {
        this.applyFilter(query);
      });
  }

  checkIsLast(): void {
    this.isLastSegment = true;
    let numOfActSegs = 0;
    this.filtered.forEach((segment: any) => {
      if (segment.actualSegment) {
        numOfActSegs++;
      }
    });
    if (numOfActSegs > 1) {
      this.isLastSegment = false;
    }
  }

  addUserToSegment(): void {
    this.personsService
      .getPersonSegments(this.type === 'sponsorship' || this.type === 'packagedSponsorship' ? this.sponsorId : this.currentUserId, true)
      .subscribe((data: any) => {
        if (data.success) {
          this.filtered.forEach((existingSegment: any) => {
            data.entity = data.entity.filter((segment: any) => {
              if (existingSegment.code === segment.code) {
                return false;
              } else {
                return true;
              }
            });
          });
          const instance = this.modalHelper.open(AddUserToSegmentModalComponent);
          instance.componentInstance.segments = data.entity;
          instance.componentInstance.addToSegment = this.addToSegment;
          instance.componentInstance.id = this.id;
          instance.componentInstance.type = this.type;
          instance.componentInstance.typePretty = this.typePretty;
          instance.componentInstance.successEvent.subscribe(() => {
            this.getSegmentTree(this.id).subscribe((segments: any) => {
              this.responseHelperService.success('Segment tree refreshed', false);
              this.filtered = segments.entity;
              this.checkIsLast();
            }, (data: any) => {
              this.responseHelperService.error(this, data.error.error);
            });
            instance.close();
          });
        }
      });
  }

  // TODO:
  // Can't remove segment because relation "party_segment_history_seq" doesn't exist in PartySegmentHistoryDAO.java
  removeSegmentFromUser(id: number, segment: Segment): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent);
    instance.componentInstance.text = 'Remove user from segment: ' + segment.code + '?';
    instance.componentInstance.header = 'Remove user from segment';
    instance.componentInstance.successEvent.subscribe(() => {
      this.removeFromSegment(id, segment.id).subscribe(() => {
        this.responseHelperService.success('Segment successfully removed', false);
        this.getSegmentTree(id).subscribe((segments: any) => {
          this.responseHelperService.success('Segment tree refreshed', false);
          this.filtered = segments.entity;
          this.checkIsLast();
          if (segments.entity.length === 0) {
            if (this.type === 'member') {
              this.router.navigate(['members']);
            } else if (this.type === 'user') {
              this.router.navigate(['users']);
            } else if (this.type === 'sponsorship') {
              this.router.navigate(['sponsors']);
            }
          }
          instance.close();
        }, (data: any) => {
          this.responseHelperService.error(this, data.error.error);
        });
      }, (data: any) => {
        this.responseHelperService.error(this, data.error.error);
      });
    });
  }

  applyFilter(query: any): void {
    this.filtered = [];
    if (query && query !== '' && query.length > 2) {
      // Add all that have code as filter
      this.allSegments.forEach((segment) => {
        if (segment.code.toUpperCase().indexOf(query.toUpperCase()) !== -1) {
          this.filtered.push(segment);
        }
      });
      // Add parents
      for (let i = this.allSegments.length - 1; i >= 0; i--) {
        if (this.isParent(this.allSegments[i].id)) {
          const idx = this.getIdx(this.allSegments[i].id);
          this.filtered.splice(idx, 0, this.allSegments[i]);
        }
      }
    } else {
      this.filtered = this.allSegments;
    }
  }

  changeFilter(query: any): void {
    this.subject.next(query);
  }

  getIdx(segmentId: number): number {
    const indexs = [];
    this.filtered.forEach((item: any, idx: number) => {
      if (item.parentId === segmentId) {
        indexs.push(idx);
      }
    });
    return Math.min.apply(null, indexs);
  }

  isParent(id: number): boolean {
    let parent = false;
    let inArray = true;
    this.filtered.forEach((segment: any) => {
      if (segment.parentId === id) {
        parent = true;
      }
      if (segment.id === id) {
        inArray = false;
      }
    });
    return parent && inArray;
  }
}
