<div class="panel-body">
  <div class="row">
    <div [ngClass]="type === 'report'?'col-md-12':'col-md-12'">
      <div class="segment-filter">
        <div class="status">
          <div class="segment-filter-search-box" [ngClass]="{'w-50': halfSize}">
            <div class="row">
              <div class="col-md-8">
                <input type="text" placeholder="Type minimum 3 characters to filter" [(ngModel)]="query"
                  class="form-control" (ngModelChange)="changeFilter(query)">
              </div>
              <div class="col-md-4" *ngIf="isProgramManager">
                <a class="btn btn-dark-blue btn-sm float-right margin-bottom-5" (click)="addUserToSegment()"
                  title="{{type ? 'Add ' + typePretty + ' to Segment':'Add to Segment'}}">
                  <fa-icon [icon]="faUserPlus"></fa-icon>
                </a>
              </div>
            </div>
            <div class="segment-filter-container mt-3">
              <div class="row segment-frame" [ngClass]="'level_' + item.level" *ngFor="let item of filtered">
                <div class="col-md-6">
                  <label for="seg_{{item.id}}">{{item.code}}</label>
                </div>
                <div class="col-md-6">
                  <div [hidden]="!item.actualSegment" class="float-right">
                    <a *ngIf="!isLastSegment && isProgramManager" class="btn btn-danger btn-xs" title="Remove segment"
                      (click)="removeSegmentFromUser(id, item)">
                      <fa-icon [icon]="faTimes"></fa-icon>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>