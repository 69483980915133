<div class="wrapper wrapper-content">
    <div class="ibox">
      <div class="ibox-content" *ngIf="selectedComm">
        <div class="row">
          <div class="col-lg-12">
            <div class="m-b-md">
                <!-- TODO: implement edit and test modals  -->
              <button class="btn btn-primary btn-sm float-right" (click)="edit(selectedComm)"><fa-icon [icon]="faPencilAlt"></fa-icon> Edit</button>
              <button *ngIf="appDataService.isAdmin &amp;&amp; currentUser === 'admin'" class="btn btn-primary btn-sm float-right m-r-sm" (click)="test(selectedComm)"><fa-icon [icon]="faClipboardCheck"></fa-icon> Test</button>
              <h2>{{selectedComm.name}}</h2>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4">
            <dl class="dl-horizontal">
              <div>
                <dt>Status:</dt>
                <dd>
                  <span class="badge" [ngClass]="statusHelper[selectedComm.status]">{{selectedComm.status}}</span>
                </dd>
              </div>
              <div>
                <dt>Type:</dt>
                <dd class="username">{{selectedComm.communicationType.name}}</dd>
              </div>
              <div>
                <dt>Endpoint Type:</dt>
                <dd class="username">{{selectedComm.communicationDeliveryProtocols.length > 0 ? selectedComm.communicationDeliveryProtocols[0].deliveryProtocol.endpointType.name : ''}}</dd>
              </div>
              <div>
                <dt>Protocol:</dt>
                <dd class="username">{{selectedComm.communicationDeliveryProtocols.length > 0 ? selectedComm.communicationDeliveryProtocols[0].deliveryProtocol.name : ''}}</dd>
              </div>
            </dl>
          </div>
          <!-- TODO: implement template -->
          <!-- <div class="col-lg-8" *ngIf="selectedComm.template">
            <strong>Template:</strong>
            <pre class="notes">              <span class="notes pull-left" ng-bind-html="selectedComm.template"></span>
            </pre>
          </div> -->
        </div>
        <div class="tabs-container">
            <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
              <li [ngbNavItem]="1">
                <a ngbNavLink>Segments</a>
                <ng-template ngbNavContent>
                  <app-communication-segments-tab [commId] = "commId"></app-communication-segments-tab>
                </ng-template>
              </li>
              <li [ngbNavItem]="2">
                <a ngbNavLink>Attributes</a>
                <ng-template ngbNavContent>
                  <app-communication-attributes-tab [commId] = "commId"></app-communication-attributes-tab>
                </ng-template>
              </li>
              <li [ngbNavItem]="3">
                <a ngbNavLink>Attachments</a>
                <ng-template ngbNavContent>
                  <app-communication-attachments-tab [commId] = "commId"></app-communication-attachments-tab>
                </ng-template>
              </li>
              <li [ngbNavItem]="4">
                <a ngbNavLink>History</a>
                <ng-template ngbNavContent>
                    <app-communication-history-tab [commId] = "commId"></app-communication-history-tab>
                </ng-template>
              </li>
              <li [ngbNavItem]="5">
                <a ngbNavLink>Content</a>
                <ng-template ngbNavContent>
                  <app-communication-content-tab [commId] = "commId"
                    [endpointType]="selectedComm.communicationDeliveryProtocols.length > 0 ? selectedComm.communicationDeliveryProtocols[0].deliveryProtocol.endpointType.name : ''">
                  </app-communication-content-tab>
                </ng-template>
              </li>
            </ul>
            <div [ngbNavOutlet]="nav" class="mt-2"></div>
          </div>
      </div>
      <!--/CONTAINER-->
    </div>
</div>
