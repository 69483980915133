<div>
  <form>
    <label>The count of the current ACTIVITY type (behavior code) must match the count of:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="countMatchThreshold !== null">
        <div *ngFor="let cntMatchThreshold of countMatchThreshold; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeThreshold(rewCntThreshold)" title="Remove Threshold">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <a
                class="control-label"
                ng-class="{ 'disabled-link': readOnly }"
                (click)="
                  regexSearchModal(
                    'BEHAVIORS',
                    { object: cntMatchThreshold, name: 'matchBehaviorCode' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                title="Search for Behaviors"
                >previous Behaviors with Codes (comma separated list):</a
              >
              <input
                class="form-control form-readonly m-b"
                type="text"
                id="behCodes_{{ cntMatchThreshold.thresholdValue }}"
                name="behaviorCodes"
                [(ngModel)]="cntMatchThreshold.matchBehaviorCode"
                (ngModelChange)="updateThresholds()"
                (click)="
                  regexSearchModal(
                    'BEHAVIORS',
                    { object: cntMatchThreshold, name: 'matchBehaviorCode' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                readonly
              />
            </div>
            <div class="form-group">
              <label>for the current:</label>
              <select name="thresholdPeriod_{{cntMatchThreshold.thresholdValue}}" class="form-control"
                      [(ngModel)]="cntMatchThreshold.thresholdPeriod.value"
                      (ngModelChange)="!cntMatchThreshold.thresholdPeriod ? cntMatchThreshold['thresholdPeriod'] = { value: $event, class: 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules$Thresholds$CountMatchThreshold$ThresholdPeriod'} : null; updateThresholds()">
              <option *ngFor="let period of periods"
                        [value]="period.value">{{period.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label>Should the count include activities from later in the period than the current activity?</label>
              <select name="inclusion_{{cntMatchThreshold.thresholdValue}}" class="form-control"
                      [(ngModel)]="cntMatchThreshold.thresholdPeriod.inclusion"
                      (ngModelChange)="!cntMatchThreshold.thresholdPeriod ? cntMatchThreshold['thresholdPeriod'] = { inclusion: $event, class: 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Schedules$Thresholds$CountMatchThreshold$ThresholdPeriod'} : null; updateThresholds()">
                <option *ngFor="let inclusionVal of inclusionValues"
                        [value]="inclusionVal.value">{{inclusionVal.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="previousPeriodCount_{{cntMatchThreshold.thresholdValue}}">How many previous periods should be included in the time span (leave empty for only the current period):</label>
              <input class="form-control m-b" type="number" id="previousPeriodCount_{{cntMatchThreshold.thresholdValue}}" name="previousPeriodCount" [(ngModel)]="cntMatchThreshold.previousPeriodCount" (ngModelChange)="updateThresholds()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="relationshipName_{{cntMatchThreshold.thresholdValue}}">Include counts from members related by (leave blank for current member only or use a comma separated list):</label>
              <input class="form-control m-b" type="text" id="relationshipName_{{cntMatchThreshold.thresholdValue}}" name="relationshipName" [(ngModel)]="cntMatchThreshold.relationshipName" (ngModelChange)="updateThresholds()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="friendlyMessage_{{cntMatchThreshold.thresholdValue}}">Message:</label>
              <input class="form-control m-b" type="text" id="friendlyMessage_{{cntMatchThreshold.thresholdValue}}" name="friendlyMessage" [(ngModel)]="cntMatchThreshold.friendlyMessage" (ngModelChange)="updateThresholds()" />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initThresholdObject()"><fa-icon [icon]="faPlus"></fa-icon> Add Count Match Threshold</button>
      </div>
    </div>
  </form>
</div>
