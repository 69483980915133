<form #f="ngForm" name="form">
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Instrument Number</label>
          <input type="text" class="form-control" [(ngModel)]="instrument.instrumentNumber" name="instrumentNumber"
            required>
          <div class="mt-sm" [hidden]="instrument.instrumentNumber">
            <small class="text-danger">Input name</small>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>Instrument Type</label>
          <select [(ngModel)]="instrumentTypeCode" (ngModelChange)="setInstrumentType(instrumentTypeCode)"
            class="form-control" name="instrumentType" required>
            <option *ngFor="let type of instrumentTypes" value="{{type.code}}">{{type.code}}</option>
          </select>
          <div class="mt-sm" [hidden]="instrument.instrumentType">
            <small class="text-danger">Select instrument type</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Account</label>
          <input type="text" class="form-control" [(ngModel)]="instrument.account" name="account">
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>Status</label>
          <select [(ngModel)]="instrument.status" class="form-control" name="status" required>
            <option *ngFor="let option of statuses" value="{{option}}">{{option}}</option>
          </select>
          <div class="mt-sm" [hidden]="instrument.status">
            <small class="text-danger">Select status</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Holder Name</label>
          <input type="text" class="form-control" [(ngModel)]="instrument.holderName" name="holderName">
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>Country</label>
          <input type="text" class="form-control" [(ngModel)]="instrument.country" name="country">
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Activation Date</label>
          <div class="input-group date">
            <input class="form-control cursor-pointer" readonly="readonly" (click)="activationDate.toggle()"  name="activationDate" ngbDatepicker #activationDate="ngbDatepicker"
              [footerTemplate]="footerTemplateActivationDate" [(ngModel)]="activationDateValue"
              (ngModelChange)="formatDate('activationDate')">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="activationDate.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>Expiration Date</label>
          <div class="input-group date">
            <input class="form-control cursor-pointer" readonly="readonly" (click)="expiryDate.toggle()" name="expiryDate" ngbDatepicker #expiryDate="ngbDatepicker"
              [footerTemplate]="footerTemplateExpiryDate" [(ngModel)]="expiryDateValue"
              (ngModelChange)="formatDate('expiryDate')">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="expiryDate.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <label>Cancelation Date</label>
          <div class="input-group date">
            <input class="form-control cursor-pointer" readonly="readonly" (click)="cancelationDate.toggle()" name="cancelationDate" ngbDatepicker #cancelationDate="ngbDatepicker"
              [footerTemplate]="footerTemplateCancelationDate" [(ngModel)]="cancelationDateValue"
              (ngModelChange)="formatDate('cancelationDate')">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="cancelationDate.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-sm-6">
        <div class="form-group">
          <label>Close Date</label>
          <div class="input-group date">
            <input class="form-control cursor-pointer" readonly="readonly" (click)="closeDate.toggle()"  name="closeDate" ngbDatepicker #closeDate="ngbDatepicker"
              [footerTemplate]="footerTemplateCloseDate" [(ngModel)]="closeDateValue"
              (ngModelChange)="formatDate('closeDate')">
            <div class="input-group-append">
              <button class="btn btn-outline-secondary calendar" (click)="closeDate.toggle()" type="button">
                <fa-icon [icon]="faCalendar"></fa-icon>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="checkbox checkbox-success">
          <input [(ngModel)]="instrument.isPrimary" id="isPrimary" class="mr-2" type="checkbox" name="isPrimary">
          <label for="isPrimary">
            Primary
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" [disabled]="f.form.invalid" (click)="ok()" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>

<ng-template #footerTemplateActivationDate>
  <div class="width-100 datePickerTemplate">
    <button class="buttonDateTemplate"
      (click)="clearDateInput('activationDateValue'); activationDate.toggle()">Clear</button>
  </div>
</ng-template>

<ng-template #footerTemplateExpiryDate>
  <div class="width-100 datePickerTemplate">
    <button class="buttonDateTemplate" (click)="clearDateInput('expiryDateValue'); expiryDate.toggle()">Clear</button>
  </div>
</ng-template>
<ng-template #footerTemplateCancelationDate>
  <div class="width-100 datePickerTemplate">
    <button class="buttonDateTemplate"
      (click)="clearDateInput('cancelationDateValue'); cancelationDate.toggle()">Clear</button>
  </div>
</ng-template>

<ng-template #footerTemplateCloseDate>
  <div class="width-100 datePickerTemplate">
    <button class="buttonDateTemplate" (click)="clearDateInput('closeDateValue'); closeDate.toggle()">Clear</button>
  </div>
</ng-template>