<div class="wrapper wrapper-content">
  <div class="ibox">
    <div class="ibox-content">
      <div class="row">
        <div class="col-lg-12">
          <div class="mb-3">
            <h2 class="fullname">Program Management</h2>
          </div>
        </div>
      </div>
        <div class="tabs-container">
          <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs">
            <li [ngbNavItem]="1">
              <a ngbNavLink>Programs</a>
              <ng-template ngbNavContent>
                <app-program-tab></app-program-tab>  
              </ng-template>
            </li>
            <li [ngbNavItem]="2">
              <a ngbNavLink>Campaign</a>
              <ng-template ngbNavContent>
                <app-campaign-tab></app-campaign-tab>      
              </ng-template>
            </li>
            <li [ngbNavItem]="3">
              <a ngbNavLink>Promotion Type</a>
              <ng-template ngbNavContent>
                <app-promotion-types-tab></app-promotion-types-tab>
              </ng-template>
            </li>
            <li [ngbNavItem]="4">
              <a ngbNavLink>Promotion Category</a>
              <ng-template ngbNavContent>
                <app-promotion-category-tab></app-promotion-category-tab>
              </ng-template>
            </li>
            <li [ngbNavItem]="5">
              <a ngbNavLink>Packages</a>
              <ng-template ngbNavContent>
                <app-packages-tab></app-packages-tab>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav" class="mt-2"></div>
        </div>
    </div>
  </div>
</div>
