import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-forbidden',
  templateUrl: './forbidden.component.html',
  styleUrls: ['./forbidden.component.scss']
})
export class ForbiddenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(): void {
    window.history.go(-2);
  }

}
