import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AssociatedProgramsModalComponent } from 'src/app/associated-programs-modal/associated-programs-modal.component';
import { Filter, SearchFilters } from 'src/app/data/class';
import { ProgramService } from 'src/app/services/program.service';
import { ToastrService } from 'ngx-toastr';
import { parseResponse } from 'src/app/data/parseResponseFunction';


@Component({
  selector: 'app-packages-tab',
  templateUrl: './packages-tab.component.html',
  styleUrls: ['./packages-tab.component.scss']
})
export class PackagesTabComponent implements OnInit {

  packages: any[];
  isLoading: boolean;
  limit = 20;
  filter = new Filter();
  searchFilters: SearchFilters;
  lock: boolean;
  lengthToCompare: number;
  filteredStatuses: Filter[] = [];
  showMoreButton: any = {name : 'Show More', active: true};
  fileNamePlease: any = {name : '', success : false};
  lockedTables: any = {state: false};
  faEye = faEye;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(private programService: ProgramService,
              private modalHelper: NgbModal,
              private toastr: ToastrService) { }

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'packages_search',
      filters: [
        {
          name: 'search',
          placeholder: 'Package Name...'
        }
      ],
      searchCallback: () => { this.initNewSearch(); },
    };
  }

  associatedProgramsModal(packageName: string): void {
    this.filter = {packageName, offset: 0, name: ''};
    this.programService.getAllPrograms(this.filter).subscribe(data => {
      if (data.success) {
        const instance = this.modalHelper.open(AssociatedProgramsModalComponent);
        instance.componentInstance.programs = parseResponse(data);
        instance.componentInstance.packageName = packageName;
      }
    });
  }

  clearKeyword(): void {
    this.initFilter();
    this.filter.search = '';
    this.getDistinctProgramPackages(false);
  }

  initNewSearch(): void {
    this.initFilter();
    this.getDistinctProgramPackages(false);
  }

  clearAllFilters(): void {
    this.filteredStatuses = [];
    this.filter.search = null;
    this.filter = new Filter();
    this.initFilter();
    this.getDistinctProgramPackages(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getDistinctProgramPackages(concat: boolean): void{
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.programService.getDistinctProgramPackages(this.filter).subscribe(data => {
      if (concat) {
        this.packages = this.packages.concat(parseResponse(data));
      } else {
        this.packages = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables.state = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables.state;
      this.isLoading = false;
    }, err => {
      this.lockedTables.state = false;
      this.lock = false;
      this.isLoading = false;
      this.toastr.error('Error occured!');
    });
  }

}
