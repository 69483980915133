import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators, FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Communication, Segment, EmailModel, AddressModel, Phone } from 'src/app/data/model';
import { CommunicationType, EndpointType } from 'src/app/data/class';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { ChirpService } from 'src/app/services/chirp.service';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-communication-preference-modal',
  templateUrl: './communication-preference-modal.component.html',
  styleUrls: ['./communication-preference-modal.component.scss']
})
export class CommunicationPreferenceModalComponent implements OnInit {

  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() public communication: Communication;
  @Input() public communications: Communication[];
  @Input() public personId: number;
  @Input() public phones: Phone[];
  @Input() public emails: EmailModel[];
  @Input() public addresses: AddressModel[];
  editable: any = {};
  title: string;
  isEdit = false;
  communicationForm: UntypedFormGroup;
  filteredSegments = new Array<Segment>();
  commTypes: CommunicationType[] = [];
  endpointTypes: EndpointType[] = [];
  selectedSegments: [] = [];
  partyEmails: EmailModel[] = [];
  errorMsg: string;
  name: string;
  faTimes = faTimes;

  constructor(
    private formBuilder: FormBuilder,
    private modalHelper: NgbModal,
    private chirpService: ChirpService,
    private responseHelperService: ResponseHelperService,
  ) { }

  ngOnInit(): void {
    if (this.communication) {
      this.title = 'Edit Communication';
      this.isEdit = true;
      this.communicationForm = this.formBuilder.group({
        communicationId: [this.communication.communicationId, Validators.required],
        endpointType: [this.communication.endpointType],
        optOut: [this.communication.optOut],
      });
      const selectedCommunication = this.communications.find((comm: Communication) => comm.id === this.communication.communicationId);
      if (selectedCommunication) {
        this.getSelectedComm(selectedCommunication);
      }
      this.setEndPointType(this.communication.endpointType);
    } else {
      this.title = 'New Communication';
      this.isEdit = false;
      this.communicationForm = this.formBuilder.group({
        communicationId: [null, Validators.required],
        endpointType: [null, Validators.required],
        optOut: [false],
      });
    }
  }

  setEndPointType(endpointType: string): void {
    if (endpointType === 'EMAIL') {
      this.communicationForm.removeControl('phoneId');
      this.communicationForm.removeControl('addressId');
      this.communicationForm.addControl('emailId',
        new FormControl(this.communication && this.communication.partyEmailDTO.id, Validators.required));
    }
    else if (endpointType === 'PHONE') {
      this.communicationForm.removeControl('emailId');
      this.communicationForm.removeControl('addressId');
      this.communicationForm.addControl('phoneId',
        new FormControl(this.communication && this.communication.phone.id, Validators.required));
    }
    else if (endpointType === 'ADDRESS') {
      this.communicationForm.removeControl('phoneId');
      this.communicationForm.removeControl('emailId');
      this.communicationForm.addControl('addressId',
        new FormControl(this.communication && this.communication.jSONAddressDTO.addressId, Validators.required));
    }
  }

  ok(): void {
    const value = this.communicationForm.value;
    value.partyId = this.personId;
    if (value.endpointType === 'EMAIL') {
      value.partyEmailDTO = this.mapToPartyEmailDTO(this.findElementFromArrayByProprty(this.emails, 'id', value.emailId));
    } else if (value.endpointType === 'PHONE') {
      value.phone = this.mapToPhoneDTO(this.findElementFromArrayByProprty(this.phones, 'id', value.phoneId));
    } else if (value.endpointType === 'ADDRESS') {
      value.jSONAddressDTO = this.mapJSONAddressDTO(this.findElementFromArrayByProprty(this.addresses, 'addressId', value.addressId));
    }
    this.chirpService.createOrUpdatePreferedCommunicationsForParty(this.personId, value).subscribe(() => {
      this.responseHelperService.success('Communications preference successfully updated', true);
      this.successEvent.emit('success');
    }, (data: any) => {
      this.responseHelperService.error(this, data.error.error, false);
    });
  }

  findElementFromArrayByProprty(elements: any[], proerty: string, value: any): any {
    return elements.find((el: any) => el[proerty] === value);
  }

  mapToPartyEmailDTO(partyEmailDTO: any): any {
    return partyEmailDTO && {
      emailAddress: partyEmailDTO.emailAddress, id: partyEmailDTO.id, partyId: this.personId
    };
  }

  mapToPhoneDTO(phone: Phone): any {
    return phone && { phoneNumber: phone.phoneNumber, id: phone.id, partyId: this.personId };
  }

  mapJSONAddressDTO(address: AddressModel): any {
    return address && {
      address1: address.address1,
      address2: address.address2,
      addressId: address.addressId,
      addressType: address.addressType,
      city: address.city,
      country: address.country,
      createdOn: address.createdOn,
      defaultComm: address.defaultComm,
      isPrimary: address.isPrimary,
      partyId: this.personId,
      postalCode: address.postalCode,
      state: address.state,
      status: address.status,
    };
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  getSelectedComm(comm: Communication): void {
    this.endpointTypes = [];
    if (comm.communicationDeliveryProtocols.length > 0) {
      comm.communicationDeliveryProtocols.forEach((protocol: any) => {
        if (!this.endpointTypes.find((endpoint: any) => endpoint.name === protocol.deliveryProtocol.endpointType.name)) {
          this.endpointTypes.push(protocol.deliveryProtocol.endpointType);
        }
      });
    }
    if (this.endpointTypes.length === 1) {
      this.communicationForm.patchValue({ endpointType: this.endpointTypes[0].name });
      this.setEndPointType(this.endpointTypes[0].name);
    }
  }

}
