import { Injectable } from '@angular/core';
import { ResponseHelperService } from './helpers/response-helper.service';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../app-data.service';
import { AccountModel } from '../data/model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class MembersService {

  constructor(
    private http: HttpClient,
    private globalData: AppDataService,
    private responseHelperService: ResponseHelperService
  ) { }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getMembers(params: any): any {
    this.responseHelperService.setRequestInProgress();
    return this.http.get<any>(this.globalData.baseUrl + 'members/', this.setParams(params)).pipe(map((res) => {
      return this.responseHelperService.processResponse(res);
    }, (err) => {
      return this.responseHelperService.processError(err);
    }));
  }

  findAccountTxns(params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'members/accounts/history', this.setParams(params));
  }

  getEntity(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'members/' + id);
  }

  getImpersonationToken(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'members/' + id + '/impersonation');
  }

  getAccount(memberId: number, accountId: number): any {
    return this.http.get<any>(this.globalData.baseUrl + '/members/' + memberId + '/accounts/' + accountId);
  }

  getAccounts(memberId: number): any {
    this.responseHelperService.setRequestInProgress();
    return this.http.get<any>(this.globalData.baseUrl + 'members/' + memberId + '/accounts').pipe(map((res) => {
      return this.responseHelperService.processResponse(res);
    }, (err) => {
      return this.responseHelperService.processError(err);
    }));
  }

  createOrUpdateAccount(memberId: number, accountId: number, account: AccountModel): any {
    this.responseHelperService.setRequestInProgress();
    if (accountId) {
      return this.http.put(this.globalData.baseUrl + 'members/' + memberId + '/accounts/' + accountId, account).pipe(map((res) => {
        return this.responseHelperService.processResponse(res);
      }, (err) => {
        return this.responseHelperService.processError(err);
      }));
    }
    return this.http.post(this.globalData.baseUrl + 'members/' + memberId + '/accounts/', account).pipe(map((res) => {
      return this.responseHelperService.processResponse(res);
    }, (err) => {
      return this.responseHelperService.processError(err);
    }));
  }

  deleteAccount(memberId: number, accountId: number): any {
    return this.http.delete(this.globalData.baseUrl + 'members/' + memberId + '/accounts/' + accountId);
  }

  createAdjustmentTxn(id: number, data: any): any {
    return this.http.post(this.globalData.baseUrl + 'members/' + id + '/adjustment', data);
  }

  sendPasswordReset(id: number): any {
    return this.http.post<any>(this.globalData.baseUrl + 'members/passwordreset/' + id, id);
  }

  setPassword(id: number, person: any): any {
    return this.http.put<any>(this.globalData.baseUrl + 'members/' + id + '/password', person);
  }

  getAccountsSummary(id: number): any {
    return this.http.get<any>(this.globalData.baseUrl + 'members/' + id + '/accountsSummary');
  }

  getEligibleCampaigns(partyId: number, params: any): any {
    this.responseHelperService.setRequestInProgress();
    return this.http.get<any>(this.globalData.baseUrl + 'members/' + partyId + '/campaigns', this.setParams(params)).pipe(map((res) => {
      return this.responseHelperService.processResponse(res);
    }, (err) => {
      return this.responseHelperService.processError(err);
    }));
  }

  getPromotions(partyId: number, params: any): any {
    if (!params) {
      params = {
        showHidden: false,
        effectiveDate: null
      }
    }
    this.responseHelperService.setRequestInProgress();
    return this.http.get<any>(this.globalData.baseUrl + 'members/' + partyId + '/promotions', this.setParams(params)).pipe(map((res) => {
      return this.responseHelperService.processResponse(res);
    }, (err) => {
      return this.responseHelperService.processError(err);
    }));
  }
  updateMember(id: number, data: any): any {
    return this.http.put<any>(this.globalData.baseUrl + 'members/' + id, data);
  }

  insertOrUpdateAccount(memberId: number, accountId: number, data: any): any {
    this.responseHelperService.setRequestInProgress(); // indicates that this search should only be run once at a time
    if (accountId) {
      return this.http.put<any>(this.globalData.baseUrl + 'members/' + memberId + '/accounts/' + accountId, data);
    }
    return this.http.post<any>(this.globalData.baseUrl + 'members/' + memberId + '/accounts/', data);
  }

  logEmulate(memberId: number, emulationRequestLog: any): any {
    return this.http.post<any>(this.globalData.baseUrl + 'members/log-emulate/' + memberId, emulationRequestLog);
  }

  createProspectiveMember(data: any): any {
    return this.http.post(this.globalData.baseUrl + 'members/prospective-member', data);
  }

  getMemberIdByInstrument(businessKey: string, instrumentType: string): any {
    return this.http.get(this.globalData.baseUrl + 'members/id/' + businessKey + '/instrument-type/' + instrumentType);
  };
}
