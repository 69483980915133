<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-md-12 content-container">
      <div class="ibox">
        <div class="ibox-content">
          <div class="col-lg-12">
            <div class="ibox border-bottom ibox-borders-without-top">
              <div class="ibox-title collapse-link" (click)="resizeIbox()">
                <h5>Filters</h5>
                <div class="ibox-tools">
                  <a class="">
                    <fa-icon [icon]="resize ? faChevronDown : faChevronUp"></fa-icon>
                  </a>
                </div>
              </div>
              <div class="form-horizontal" *ngIf="resize">
                <div class="ibox-content">
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-4 control-label-left">Party ID</label>
                        <div class="input-group col-sm-8">
                          <input type="text" class="form-control" name="partyId" [(ngModel)]="partyId"
                            (change)="setFilter('partyId', partyId)">
                          <a *ngIf="partyId" class="btn bg-transparent button-inside-input closeBtn"
                            (click)="clearInput('partyId')">
                            <fa-icon [icon]="faTimes"></fa-icon>
                          </a>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label-left">First Name</label>
                        <div class="input-group col-sm-8">
                          <input type="text" class="form-control" name="firstName" [(ngModel)]="firstName"
                            (change)="setFilter('firstName', firstName)">
                          <a *ngIf="firstName" class="btn bg-transparent button-inside-input closeBtn"
                            (click)="clearInput('firstName')">
                            <fa-icon [icon]="faTimes"></fa-icon>
                          </a>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label-left">Last Name</label>
                        <div class="input-group col-sm-8">
                          <input type="text" class="form-control" name="lastName" [(ngModel)]="lastName"
                            (change)="setFilter('lastName', lastName)">
                          <a *ngIf="lastName" class="btn bg-transparent button-inside-input closeBtn"
                            (click)="clearInput('lastName')">
                            <fa-icon [icon]="faTimes"></fa-icon>
                          </a>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label-left">Party Attribute Name</label>
                        <div class="input-group col-sm-8">
                          <input type="text" class="form-control" name="pAttributeName" [(ngModel)]="pAttributeName"
                            (change)="setFilter('pAttributeName', pAttributeName)">
                          <a *ngIf="pAttributeName" class="btn bg-transparent button-inside-input closeBtn"
                            (click)="clearInput('pAttributeName')">
                            <fa-icon [icon]="faTimes"></fa-icon>
                          </a>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label-left">Party Attribute Value</label>
                        <div class="input-group col-sm-8">
                          <input type="text" class="form-control" name="pAttributeValue" [(ngModel)]="pAttributeValue"
                            (change)="setFilter('pAttributeValue', pAttributeValue)">
                          <a *ngIf="pAttributeValue" class="btn bg-transparent button-inside-input closeBtn"
                            (click)="clearInput('pAttributeValue')">
                            <fa-icon [icon]="faTimes"></fa-icon>
                          </a>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label-left">Segments</label>
                        <div class="col-sm-8">
                          <app-segment-filter (filterSegments)="handleFilterSegments($event)"></app-segment-filter>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group row">
                        <label class="col-sm-4 control-label-left">Keyword</label>
                        <div class="input-group col-sm-8">
                          <input type="text" class="form-control" name="keyword" [(ngModel)]="keyword"
                            (change)="setFilter('keyword', keyword)">
                          <a *ngIf="keyword" class="btn bg-transparent button-inside-input closeBtn"
                            (click)="clearInput('keyword')">
                            <fa-icon [icon]="faTimes"></fa-icon>
                          </a>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-4 control-label-left">Outreach Date</label>
                        <div class="col-md-8">
                          <div class="row">
                            <div class="col-md-6">
                              <input class="cursor-pointer form-control" (click)="aFromDate.toggle()"
                                (ngModelChange)="setFilter('activityFromDate', $event, true)" placeholder="From:"
                                name="aFromDate" ngbDatepicker #aFromDate="ngbDatepicker" [(ngModel)]="activityFromDate"
                                [footerTemplate]="aFromTemplate">
                              <ng-template #aFromTemplate>
                                <div class="width-100 datePickerTemplate">
                                  <button class="buttonDateTemplate"
                                    (click)="setFilter('activityFromDate', null); aFromDate.toggle()">Clear</button>
                                </div>
                              </ng-template>
                            </div>
                            <div class="col-md-6">
                              <input class="cursor-pointer form-control" (click)="aToDate.toggle()" placeholder="To:"
                                (ngModelChange)="setFilter('activityToDate', $event, true)" name="aToDate" ngbDatepicker
                                #aToDate="ngbDatepicker" [(ngModel)]="activityToDate" [footerTemplate]="aToTemplate">
                              <ng-template #aToTemplate>
                                <div class="width-100 datePickerTemplate">
                                  <button class="buttonDateTemplate"
                                    (click)="setFilter('activityToDate', null); aToDate.toggle()">Clear</button>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-md-4 control-label-left">Outreach Created On Date</label>
                        <div class="col-md-8">
                          <div class="row">
                            <div class="col-md-6">
                              <input class="cursor-pointer form-control" (click)="cFromDate.toggle()"
                                (ngModelChange)="setFilter('createdFromDate', $event, true)" placeholder="From:"
                                name="cFromDate" ngbDatepicker #cFromDate="ngbDatepicker" [(ngModel)]="createdFromDate"
                                [footerTemplate]="cFromTemplate">
                              <ng-template #cFromTemplate>
                                <div class="width-100 datePickerTemplate">
                                  <button class="buttonDateTemplate"
                                    (click)="setFilter('createdFromDate', null); cFromDate.toggle()">Clear</button>
                                </div>
                              </ng-template>
                            </div>
                            <div class="col-md-6">
                              <input class="cursor-pointer form-control" (click)="cToDate.toggle()" placeholder="To:"
                                (ngModelChange)="setFilter('createdToDate', $event, true)" name="cToDate" ngbDatepicker
                                #cToDate="ngbDatepicker" [(ngModel)]="createdToDate" [footerTemplate]="cThruTemplate">
                              <ng-template #cThruTemplate>
                                <div class="width-100 datePickerTemplate">
                                  <button class="buttonDateTemplate"
                                    (click)="setFilter('createdToDate', null); cToDate.toggle()">Clear</button>
                                </div>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="form-group row">
                        <label class="col-sm-4 control-label-left">Communication Name</label>
                        <div class="col-sm-8">
                          <ng-select [items]="communications" bindLabel="name" bindValue="name" name="communicationName"
                            [(ngModel)]="communicationName" (change)="setFilter('communicationName', communicationName)"
                            theme="bootstrap">
                          </ng-select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-2 offset-md-10">
                      <button type="submit" class="btn btn-primary float-right" [disabled]="isLoading"
                        (click)="initNewSearchHistory()">
                        <fa-icon [icon]="isLoading ? faSpinner : faCheckSquare"></fa-icon>
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- TABLE: -->
          <div class="row">
            <div class="col-md-12">
              <div>
                <!-- <div class="row">
                    <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
                  </div> -->
                <div class="row mt-3">
                  <table class="table table-pointable table-bordered" width="100%">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Communication Date</th>
                        <th>Delivery Protocol</th>
                        <th>Is Delivered</th>
                        <th>Processing Status</th>
                        <th width="3%"></th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngFor="let c of commHistory; let i = index">
                        <tr (click)="expandedLookup[i] = !expandedLookup[i]">
                          <td>{{c.id}}</td>
                          <td>{{c.communicationTimestamp}}</td>
                          <td>{{c.deliveryProtocol}}</td>
                          <td>{{c.delivered}}</td>
                          <td>{{c.processingStatus}}</td>
                          <td>
                            <span *ngIf="!expandedLookup[i]" class="width-50 display-inline-block"
                              title="Expand to see content">
                              <fa-icon [icon]="faChevronLeft"></fa-icon>
                            </span>
                            <span *ngIf="expandedLookup[i]" class="width-50 display-inline-block"
                              title="Expand to see history">
                              <fa-icon [icon]="faChevronDown"></fa-icon>
                            </span>
                          </td>
                        </tr>
                        <tr class="additional-data" *ngIf="expandedLookup[i]">
                          <td colspan="6" class="pb-0 pt-0">
                            <table class="reward-log table table-pointable additional">
                              <tbody>
                                <tr class="align-text-left">
                                  <td><b>Content:</b>{{c.content}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      </ng-container>
                      <tr app-is-there-more [numColumns]="6" [newLengthEvent]="newLengthEvent"></tr>
                    </tbody>
                  </table>
                </div>
                <app-add-more-data-to-list (onScroll)="getCommunicationsHistory(true)" [lock]="lock"
                  [isLoading]="isLoading">
                </app-add-more-data-to-list>
              </div>
            </div>
          </div>

          <ng-template #footerTemplateToDateValue>
            <div class="width-100 datePickerTemplate">
              <button class="buttonDateTemplate" (click)="clearDateInput('toDateValue'); toDate.toggle()">Clear</button>
            </div>
          </ng-template>

          <ng-template #footerTemplateFromDateValue>
            <div class="width-100 datePickerTemplate">
              <button class="buttonDateTemplate"
                (click)="clearDateInput('fromDateValue'); fromDate.toggle()">Clear</button>
            </div>
          </ng-template>

        </div>
      </div>
    </div>
  </div>
</div>