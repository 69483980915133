<div class="wrapper wrapper-content">
    <div class="ibox">
        <div class="ibox-content">
            <div class="row">
                <div class="col-lg-12">
                    <div class="mb-2">
                        <a class="btn btn-dark-blue btn-sm float-right" (click)="edit(selectedPersonId)" title="Edit">
                            <fa-icon [icon]="faPencilAlt">
                                </fa-icon>
                        </a>
                        <h2 class="fullname">{{selectedPerson.name}}</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-3">
                    <dl class="dl-horizontal">
                        <div>
                            <dt>Status:</dt>
                            <dd>
                                <span class="badge"
                                    [ngClass]="statusHelper[selectedPerson.status]">{{selectedPerson.status}}</span>
                            </dd>
                        </div>
                        <div>
                            <dt>Username:</dt>
                            <dd class="username">{{selectedPerson.username}}</dd>
                        </div>
                        <div *ngIf="selectedPerson.primaryEmail?.emailAddress">
                            <dt>Email:</dt>
                            <dd>{{selectedPerson.primaryEmail.emailAddress}}</dd>
                        </div>
                        <div *ngIf="selectedPerson.yearStart">
                            <dt>Year Start:</dt>
                            <dd>{{selectedPerson.yearStart}}</dd>
                        </div>
                        <div *ngIf="selectedPerson.workingHours">
                            <dt>Working Hours:</dt>
                            <dd>{{selectedPerson.workingHours}}</dd>
                        </div>
                        <div *ngIf="selectedPerson.role?.roleName">
                            <dt>Role:</dt>
                            <dd>{{selectedPerson.role.roleName}}</dd>
                        </div>
                    </dl>
                </div>
                <div class="col-lg-9" *ngIf="selectedPerson.notes">
                    <strong>Notes:</strong>
                    <pre class="notes">
                        <span class="notes float-left">{{selectedPerson.notes}}</span>
                    </pre>
                </div>
            </div>
            <div class="tabs-container" *ngIf="isPartnerLoaded">
                <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs">
                    <li [ngbNavItem]="1">
                        <a ngbNavLink>Segments</a>
                        <ng-template ngbNavContent>
                            <app-segments-tab [personId]="selectedPersonId" [halfSize]="true"></app-segments-tab>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="2">
                        <a ngbNavLink>Contacts</a>
                        <ng-template ngbNavContent>
                            <app-contacts-tab [personId]="selectedPersonId"></app-contacts-tab>
                        </ng-template>
                    </li>
                    <li [ngbNavItem]="3">
                        <a ngbNavLink>Attributes</a>
                        <ng-template ngbNavContent>
                            <app-party-attributes-tab [personId]="selectedPersonId"></app-party-attributes-tab>
                        </ng-template>
                    </li>
                </ul>
                <div [ngbNavOutlet]="nav" class="mt-2"></div>
            </div>
        </div>
        <!--/CONTAINER-->
    </div>
</div>
