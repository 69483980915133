import { AddSponsorshipModalComponent } from './../add-sponsorship-modal/add-sponsorship-modal.component';
import { ProgramService } from './../services/program.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SponsorsService } from './../services/sponsors.service';
import { ResponseHelperService } from './../services/helpers/response-helper.service';
import { PromotionService } from './../services/promotion.service';
import { CampaignService } from './../services/campaign.service';
import { Filter, SearchFilters } from './../data/class';
import { TableHelperService } from './../services/helpers/table-helper.service';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Campaign, Promotion } from '../data/model';
import * as $ from 'jquery';
import { faUserPlus, faTimes, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { AddCampaignModalComponent } from '../add-campaign-modal/add-campaign-modal.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-promotions-tab',
  templateUrl: './promotions-tab.component.html',
  styleUrls: ['./promotions-tab.component.scss']
})
export class PromotionsTabComponent implements OnInit {

  @Input() personId: number;
  @Input() sponsorName: number;

  isLoading = false;
  lockedTables = false;
  lock = false;
  filteredStatuses = [];
  select: any = {};
  selectedCampaigns: Campaign[] = [];
  defaultStatusFilter = {};
  defaultStatuses = ['ACTIVE', 'PENDING', 'STARTED'];
  // defaultStatusFilter['statuses'] = defaultStatuses;
  limit = 20;
  filter = new Filter();
  statuses: object[] = [];
  campaignsFilter: any;
  campaigns: Campaign[] = [];
  selectedPromotion: Promotion;
  promotions: Promotion[] = [];
  lengthToCompare: number;
  show = true;
  faUserPlus = faUserPlus;
  faTimes = faTimes;
  faCalendarPlus = faCalendarPlus;
  searchFilters: SearchFilters;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();
  showSearch = false;

  constructor(
    private tableHelper: TableHelperService,
    private campaignService: CampaignService,
    private promotionService: PromotionService,
    private responseHelper: ResponseHelperService,
    private sponsorsService: SponsorsService,
    private modalHelper: NgbModal,
    private programService: ProgramService,
    private tableHelperService: TableHelperService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    const buttonTextAndTitle = this.sponsorName ? 'Add Sponsorship' : 'New Promotion';
    this.searchFilters = {
      formName: 'promotions-search',
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...'
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        },
        {
          name: 'campaigns',
          type: 'array',
          placeholder: 'Campaigns...',
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'select',
          multiple: true
        }
      ],
      addEntityButtons: [
        {
          icon: faUserPlus,
          buttonText: buttonTextAndTitle,
          if: true,
          callback: () => { this.promotionModal({ windowClass: 'wider-modal-window' }, 'program'); },
          title: buttonTextAndTitle
        },
      ],
      searchCallback: () => { this.initNewSearch(true); }
    };
    this.select.campaigns = null;
    this.getAllCampaigns();
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getAllCampaigns(): void {
    this.campaignService.getAllCampaigns().subscribe((data: any) => {
      this.showSearch = true;
      this.campaignsFilter = parseResponse(data).map(p => ({
        value: p.name,
      }));
      this.searchFilters.filters[2].choices = this.campaignsFilter;
    });
  }

  getPromotionCampaigns(promSponsorId: number, partyId: number): void {
    this.promotionService.getPromotionCampaigns(promSponsorId, partyId).subscribe((data: any) => {
      if (data.success) {
        this.campaigns = parseResponse(data);
        this.select.selected = true;
      }
    });
  }

  removeCampaign(promSponsorId: number, camp: Campaign): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Remove campaign ' + camp.name + ' from promotion ' + this.selectedPromotion.name + '?';
    instance.componentInstance.header = 'Remove campaign?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.promotionService.removeCampaignFromPromotion(promSponsorId, camp.id).subscribe(() => {
        this.responseHelper.success('Campaign successfully removed', true);
        this.getPromotionCampaigns(this.selectedPromotion.promotionSponsorId, this.personId);
      }, (data: any) => {
        this.responseHelper.error(this, data.error.error);
      });
    });
  }

  getPromotions(concat: boolean, firstCall: boolean = false): void {
    if(!firstCall) this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.sponsorsService.getSponsorPromotions(this.personId, this.filter).subscribe((data: any) => {
      if (concat) {
        this.promotions = this.promotions.concat(parseResponse(data));
      } else {
        this.campaigns = [];
        this.select.selected = false;
        this.promotions = parseResponse(data);
      }

      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
    }, () => {
      this.lockedTables = false;
      this.lock = false;
      this.isLoading = false;
      this.toastr.error('Error occured!');
    });
  }

  showPromotion(e: any, promotion: Promotion): void {
    $('tr.active').removeClass('active');
    $(e.currentTarget).toggleClass('active');
    this.selectedPromotion = promotion;
    this.getPromotionCampaigns(promotion.promotionSponsorId, this.personId);
  }

  initNewSearch(firstCall:boolean = false): void {
    this.initFilter();
    this.getPromotions(false, firstCall);
  }

  promotionModal(size: any, type: string): void {
    const item: any = {};
    item.type = type;
    const progFilter: any = {};
    progFilter.includeCounts = 'false';
    progFilter.statuses = this.defaultStatuses;

    if (type === 'campaign') {
      this.campaignService.getAllCampaigns(progFilter).subscribe((data) => {
        if (data.success) {
          item.data = parseResponse(data);
          const instance = this.modalHelper.open(AddSponsorshipModalComponent, { size: 'lg' });
          instance.componentInstance.item = item;
          instance.componentInstance.sponsorName = this.sponsorName;
          instance.componentInstance.personId = this.personId;
          instance.componentInstance.filter = this.filter;
          instance.componentInstance.successEvent.subscribe(() => {
            this.initNewSearch();
          });
        }
      });
    } else {
      this.programService.getAllPrograms(progFilter).subscribe((data) => {
        if (data.success) {
          item.data = parseResponse(data);
          const instance = this.modalHelper.open(AddSponsorshipModalComponent, { size: 'lg' });
          instance.componentInstance.item = item;
          instance.componentInstance.sponsorName = this.sponsorName;
          instance.componentInstance.personId = this.personId;
          instance.componentInstance.filter = this.filter;
          instance.componentInstance.successEvent.subscribe(() => {
            this.initNewSearch();
          });
        }
      });
    }
  }


  campaignModal(): void {
    const instance = this.modalHelper.open(AddCampaignModalComponent);
    instance.componentInstance.inputData = {
      sponsorId: this.personId,
      promotionId: this.selectedPromotion.id,
      promotionSponsorId: this.selectedPromotion.promotionSponsorId,
      campaignId: this.select.selectedCampaign && this.select.selectedCampaign.id
    };
    instance.componentInstance.successEvent.subscribe(() => {
      this.getPromotionCampaigns(this.selectedPromotion.id, this.selectedPromotion.sponsorPartyId);
    });
  }

  // TODO: I can't create sponsorship on qa1 to test this
  // scope.$watchCollection('select.campaigns', function (newValue, oldValue) {
  //   if (newValue) {
  //     selectedCampaigns = [];
  //     newValue.forEach(function (camp) {
  //       selectedCampaigns.push(camp.name);
  //     });

  //       scope.filter['campaigns'] = selectedCampaigns;
  //       initFilter();
  //       scope.getPromotions(false);

  //   }
  // });

}
