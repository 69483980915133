import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Attachment } from 'src/app/data/model';
import { CommunicationsService } from 'src/app/services/communications.service';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';

@Component({
  selector: 'app-communication-attachment-modal',
  templateUrl: './communication-attachment-modal.component.html',
  styleUrls: ['./communication-attachment-modal.component.scss']
})
export class CommunicationAttachmentModalComponent implements OnInit {

  @Output() public successEvent: EventEmitter<any> = new EventEmitter();
  @Input() attachment: Attachment;
  @Input() commId: number;
  @Input() resourceType: string;
  title: string;
  attachmentForm: UntypedFormGroup;
  errorMsg: string;
  faSave = faSave;
  faTimes = faTimes;

  constructor(
    private formBuilder: FormBuilder,
    private modalHelper: NgbModal,
    private communicationsService: CommunicationsService,
    private responseHelperService: ResponseHelperService
  ) { }

  ngOnInit(): void {
    if (this.attachment) {
      this.title = 'Edit Attachment';
      this.attachmentForm = this.formBuilder.group({
        name: [this.attachment.name, Validators.required],
        mimeType: [this.attachment.mimeType]
      });
    } else {
      this.title = 'New Attachment';
      this.attachmentForm = this.formBuilder.group({
        name: ['', Validators.required],
        mimeType: ['']
      });
    }
  }

  ok(): void {
    const params = this.attachmentForm.value;
    params.communicationId = this.commId;
    this.communicationsService.createOrUpdateAttachment(this.attachment && this.attachment.id, params).subscribe(() => {
      this.responseHelperService.success('Attachment successfully created', true);
      this.successEvent.emit();
    }, () => {
      this.responseHelperService.error(this, this.errorMsg);
    });
  };

  cancel(): void {
    this.modalHelper.dismissAll();
  };

  clearErrorMessage(): void {
    this.errorMsg = '';
  }
}
