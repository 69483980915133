import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppDataService } from '../app-data.service';
import { Product } from '../data/model';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private http: HttpClient, private globalData: AppDataService) { }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getProducts(params: any): any {
    return this.http.get(this.globalData.baseUrl + 'products/dt', this.setParams(params))
  }

  getProduct(id: number): any {
    return this.http.get(this.globalData.baseUrl + 'products/' + id)
  }

  createOrUpdateProduct(id: number, data: Product): any {
    if (id) {
      return this.http.put(this.globalData.baseUrl + 'products/' + id, data)
    }
    return this.http.post(this.globalData.baseUrl + 'products', data)
  }

  deleteProduct(id: number): any {
    return this.http.delete(this.globalData.baseUrl + 'products/' + id)
  }

  getProductCategories(): any {
    return this.http.get(this.globalData.baseUrl + 'product-categories')
  }
}
