<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-sm-12 content-container">
      <div class="ibox">
        <div class="ibox-content">
          <div class="m-b">
            <h2>Processing Log Search</h2>
          </div>
          <div class="row">
            <div class="col-md-12">
              <div>
                <div class="row">
                  <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
                </div>
                <div class="row">
                  <table class="table table-pointable table-bordered" width="100%">
                    <thead>
                      <tr>
                        <th style="width: 35px;">
                          <div class="checkbox checkbox-info" for="select-all">
                            <input
                              id="select-all"
                              [checked]="checkAll"
                              type="checkbox"
                              [indeterminate]="selectedLogs.length && selectedLogs.length < processingLogs.length"
                              (change)="selectAllLogs($event.target.checked)"
                            />
                          </div>
                        </th>
                        <th>ID</th>
                        <th>Process Code</th>
                        <th>Status</th>
                        <th>Finished On</th>
                        <th>Created On</th>
                        <th>Created By</th>
                        <th>Success No</th>
                        <th>Error No</th>
                        <th>Last No</th>
                        <th>Description</th>
                   
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let processingLog of processingLogs">
                        <td style="width: 35px;">
                          <div class="checkbox checkbox-info">
                            <input  
                            id="cb-{{ processingLog.id }}"
                            type="checkbox"
                            [(ngModel)]="isSelected[processingLog.id]"
                            (change)="selectOneLog(processingLog.id)"
                          />
                          <label for="cb-{{ processingLog.id }}"></label>
                        </div>
                      </td>
                        <td><a routerLink="/processing-log/{{processingLog.id}}">{{processingLog.id}}</a>
                          <fa-icon [icon]="faExclamationTriangle" *ngIf="processingLog.errorRecNum > 0"
                            class="text-danger ng-scope" title="Processed with (errors)"></fa-icon>
                        </td>
                        <td class="word-break-break-all">{{processingLog.procCode}}</td>
                        <td>{{processingLog.status}}
                        </td>
                        <td>{{processingLog.finishedOnStr}}</td>
                        <td>{{processingLog.createdOnStr}}</td>
                        <td>{{processingLog.createdBy}}</td>
                        <td>{{processingLog.successRecNum}}</td>
                        <td>{{processingLog.errorRecNum}}</td>
                        <td>{{processingLog.lastRecNum}}</td>
                        <td class="word-break-break-all">{{processingLog.description}}</td>
                      </tr>
                      <tr app-is-there-more [numColumns]="10" [newLengthEvent]="newLengthEvent"></tr>
                    </tbody>
                  </table>
                </div>
                <app-add-more-data-to-list (onScroll)="getProcessingLogs(true)" [lock]="lock" [isLoading]="isLoading">
                </app-add-more-data-to-list>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>