import { HttpClient } from '@angular/common/http';
import { AppDataService } from 'src/app/app-data.service';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ProcessingService {

  constructor(
    private http: HttpClient,
    private globalData: AppDataService
  ) { }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  processList(data: any, params?: any): any {
    return this.http.put<any>(this.globalData.baseUrl + 'process/activities', data, this.setParams(params));
  }

   updateProcessActivity(id: number): any {
    return this.http.put<any>(this.globalData.baseUrl + 'process/activity/' + id, {});
  }

  findAndProcessList(params: any): any {
    return this.http.get<any>(this.globalData.baseUrl + 'process/activities-by-criteria',  this.setParams(params));
  }

}
