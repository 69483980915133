import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../../app-data.service';
import { ResponseHelperService } from '../../services/helpers/response-helper.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormBuilder, FormArray, Form } from '@angular/forms';
import { faTimes, faSave, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

// https://blog.karmacomputing.co.uk/angular-6-dynamically-add-rows-reactive-forms-how-to/

@Component({
  selector: 'app-job-arguments-modal',
  templateUrl: './job-arguments-modal.component.html',
  styleUrls: ['./job-arguments-modal.component.scss']
})
export class JobArgumentsModalComponent implements OnInit {

  jobArgumentsForm: UntypedFormGroup;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;
  faPlus = faPlus;
  faTrash = faTrash;
  @Input() name: string;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private http: HttpClient,
    private globalData: AppDataService,
    private responseHelperService: ResponseHelperService,
    private toastrService: ToastrService,
    private modalHelper: NgbModal,
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    this.jobArgumentsForm = this.formBuilder.group({
      job_arguments: this.formBuilder.array([this.formBuilder.group({
        name: '',
        value: ''
      })])
    })
  }

  get jobArguments() {
    return this.jobArgumentsForm.get('job_arguments') as FormArray;
  }

  addAttr(): void {
    this.jobArguments.push(this.formBuilder.group({ name: '', value: '' }));
  }

  removeAttr(index: number): void {
    this.jobArguments.removeAt(index);
    if (this.jobArguments.length === 0) {
      this.jobArguments.push(this.formBuilder.group({ name: '', value: '' }));
    }
  }

  ok(): void {
    var result = this.jobArguments.value.reduce((map, obj) => {
      map[obj.name] = obj.value;
      return map;
    }, {});

    this.http.post<any>(this.globalData.baseUrl + 'jobs/' + this.name + '/execute', result).subscribe(() => {
      this.responseHelperService.success('Successfully launched job', false);
      this.successEvent.emit();
    }, (response: any) => {
      // responseHelper.error($scope, response.data.message);
      this.toastrService.success(response.data.message ? response.data.message : response.data.error);
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }
}
