<div class="panel-body">
  <div class="row">
    <div class="col-md-12" *ngIf="show">
      <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
      <div class="row">
        <table class="table table-pointable table-bordered infinite-table" width="100%">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Class Name</th>
              <th width="30%">Description</th>
              <th>Status</th>
              <th>Enabled</th>
              <th width="13%">Last Run</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let job of jobs">
              <td>{{job.id}}</td>
              <td>{{job.name}}</td>
              <td>{{job.className}}</td>
              <td>{{job.description}}</td>
              <td>{{job.status}}</td>
              <td>{{job.enabled}}</td>
              <td *ngIf="job.lastRunStarted && !job.lastRunEnded" class="green-color">Started:
                {{job.lastRunStarted | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
              <td *ngIf="job.lastRunStarted && job.lastRunEnded && job.lastRunError === 0" class="blue-color">Completed
                {{job.lastRunEnded | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
              <td *ngIf="job.lastRunStarted && job.lastRunEnded && job.lastRunError > 0" class="red-color">Error:
                {{job.lastRunEnded | date: 'yyyy-MM-dd HH:mm:ss'}}</td>
              <td *ngIf="!job.lastRunStarted"></td>
              <td>
                <div class="float-left actions inline-table">
                  <input class="upload-input" id="upload-input-{{job.id}}" type="file" name="file" [attr.id-el]="job.id"
                    (change)="uploadFile($event.target)">
                  <label class="btn btn-dark-blue btn-sm mt-2" for="upload-input-{{job.id}}" title="Upload Class file">
                    <fa-icon [icon]="faUpload"></fa-icon>
                  </label>
                  <button class="btn btn-dark-blue btn-sm" (click)="jobArgumentsModal(job.name)"
                    [disabled]="job.lastRunStarted !== null && job.lastRunEnded === null" title="Play">
                    <fa-icon [icon]="faPlay"></fa-icon>
                  </button>
                  <button class="btn btn-dark-blue btn-sm" (click)="jobManagementModal(job.id)" title="Edit">
                    <fa-icon [icon]="faPencilAlt"></fa-icon>
                  </button>
                  <button class="btn btn-attention btn-sm" [disabled]="job.status==='DELETED'"
                    (click)="deleteJob(job.id)" title="delete">
                    <fa-icon [icon]="faTrash"></fa-icon>
                  </button>
                </div>
              </td>
            </tr>
            <tr app-is-there-more [numColumns]="8" [newLengthEvent]="newLengthEvent"></tr>
          <tbody>
        </table>
      </div>
      <app-add-more-data-to-list (onScroll)="getJobs(true)" [lock]="lock" [isLoading]="isLoading">
      </app-add-more-data-to-list>
    </div>
  </div>
</div>