<form [formGroup]="reportForm" autoscroll="true">
    <div class="wrapper wrapper-content">
        <div class="row">
            <div class="col-md-12">
                <div class="ibox">
                    <div class="ibox-title">
                        <a class="btn btn-sm btn-primary float-right" (click)="isCollapsed = !isCollapsed"
                            *ngIf="isProgramManager && report.interactiveReport">Toggle
                            parameters</a>
                        <h2>{{report.name}}</h2>
                    </div>
                    <div class="ibox-content">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="form-group">
                                    <button type="button" class="btn btn-sm btn-primary float-right"
                                        [disabled]="disableButtons || !reportForm.valid" *ngIf="!showCSV"
                                        (click)="applyMe('external')"> Execute</button>
                                    <button type="button" class="btn btn-sm btn-primary float-right"
                                        [disabled]="disableButtons || !reportForm.valid" *ngIf="showCSV"
                                        (click)="applyMe('csv')"> Download CSV</button>
                                    <button type="button" class="btn btn-sm btn-primary float-right mr-1"
                                        [disabled]="disableButtons || !reportForm.valid" *ngIf="isProgramManager"
                                        (click)="applyMe('orb')"> View Interactive</button>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-2 col-md-4" *ngFor="let param of params">
                                <div class="form-group" *ngIf="isInput(param.paramType) && !param.autocompleteAction">
                                    <label>{{param.paramName}}</label>
                                    <input type="text" class="form-control" [name]="param.paramCode"
                                        [formControlName]="param.paramCode"
                                        placeholder="Default: {{param.defaultValue || 'none'}}">
                                    <div class="mt-1">
                                        <small class="text-danger"
                                            [hidden]="reportForm.controls[param.paramCode].valid">{{param.paramName}} is
                                            required</small>
                                    </div>
                                </div>
                                <div class="form-group"
                                    *ngIf="param.autocompleteAction && (param.paramType === 'BIGINT' || param.paramType === 'VARCHAR')">
                                    <label>{{param.paramName}}</label>
                                    <div class="form-group">
                                        <ng-select [items]="autocomplete[param.paramCode]"
                                            [formControlName]="param.paramCode" [hideSelected]="true" [closeOnSelect]="true">
                                        </ng-select>
                                        <div class="mt-1">
                                            <small class="text-danger"
                                                [hidden]="reportForm.controls[param.paramCode].valid">{{param.paramName}} is
                                                required</small>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group" *ngIf="param.autocompleteAction && param.paramType === 'ARRAY'">
                                    <label>{{param.paramName}}</label>
                                    <div class="form-group">
                                        <ng-select [items]="autocompleteArray[param.paramCode]" 
                                            [formControlName]="param.paramCode" [hideSelected]="true"
                                            [closeOnSelect]="true">
                                        </ng-select>
                                        <div class="mt-1">
                                            <small class="text-danger"
                                            [hidden]="reportForm.controls[param.paramCode].valid">{{param.paramName}} is
                                            required</small>
                                        </div>
                                    </div>
                                </div>
                                <!-- DATE PICKER -->
                                <div class="form-group">
                                    <label>{{param.paramName}}</label>
                                    <div class="input-group date">
                                        <input class="form-control cursor-pointer" readonly (click)="date.toggle()"
                                            placeholder="{{param.defaultValue ? param.defaultValue : 'yyyy-mm-dd'}}"
                                            name="date" ngbDatepicker #date="ngbDatepicker" [footerTemplate]="footerTemplateDate" [formControlName]="param.paramCode">
                                        <div class="input-group-append">
                                            <button class="btn btn-outline-secondary calendar" (click)="date.toggle()"
                                                type="button">
                                                <fa-icon [icon]="faCalendar"></fa-icon>
                                            </button>
                                        </div>
                                        <ng-template #footerTemplateDate>
                                            <div class="width-100 datePickerTemplate">
                                              <button class="buttonDateTemplate" (click)="clearDateInput(param.paramCode); date.toggle()">Clear</button>
                                            </div>
                                          </ng-template>
                                    </div>
                                    <div class="mt-1">
                                        <small class="text-danger"
                                        [hidden]="reportForm.controls[param.paramCode].valid">{{param.paramName}} is
                                        required</small>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-4">
                                <div class="form-group">
                                    <label>Report Notes</label>
                                    <input type="text" class="form-control" formControlame="reportNotes"
                                        placeholder="Enter a brief description of the report">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="ibox-content relative-position">
                        <div id="pgrid">
                        </div>
                        <div [hidden]="!(isLoading)" class="loading-overlay">
                            <i class="fa fa-spinner fa-5x fa-spin"></i>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>