import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Filter, Choice, SearchFilters } from '../../data/class';
import { TableHelperService } from '../../services/helpers/table-helper.service';
import { Location } from '../../data/model';
import { ConfirmDialogComponent } from '../../confirm-dialog/confirm-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { faPlus, faTimes, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { LocationModalComponent } from '../location-modal/location-modal.component';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { LocationService } from 'src/app/services/location.service';
import { HierarchySourcesService } from 'src/app/services/hierarchy-sources.service';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-locations-tab',
  templateUrl: './locations-tab.component.html',
  styleUrls: ['./locations-tab.component.scss']
})
export class LocationsTabComponent implements OnInit {

  lockedTables: boolean;
  isLoading: boolean;
  lock: boolean;
  filteredStatuses: any[] = [];
  filter = new Filter();
  limit = 20;
  statuses: Choice[];
  locations: Location[] = [];
  hierarchySources: any[] = [];
  lengthToCompare: number;
  show: boolean;
  faPlus = faPlus;
  faTimes = faTimes;
  faPencilAlt = faPencilAlt;
  faTrashAlt = faTrashAlt;
  searchFilters: SearchFilters;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private tableHelperService: TableHelperService,
    private locationService: LocationService,
    private modalHelper: NgbModal,
    private responseHelperService: ResponseHelperService,
    private hierarchySourcesService: HierarchySourcesService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.searchFilters = {
      formName: 'locations-search',
      searchCallback: () => { this.initNewSearch(); },
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...'
        },
        {
          name: 'statuses',
          placeholder: 'Status',
          type: 'array',
          choices: this.tableHelperService.getStatuses('ENTITY'),
          nameProp: 'value',
          valueProp: 'value',
          inputType: 'dropdown',
        }
      ],
      addEntityButtons: [
        {
          icon: faPlus,
          buttonText: 'New Location',
          if: true,
          callback: () => { this.locationModal(); },
          title: 'New Location'
        },
      ],
    };
    this.getStatuses();
    this.getHierarchySource();
    this.show = true;
  }

  initNewSearch(): void {
    this.initFilter();
    this.getLocatons(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
  }

  getStatuses(): void {
    this.statuses = this.tableHelperService.getStatuses('ENTITY');
  }

  locationModal(id?: number): void {
    if (id) {
      this.locationService.getLocation(id).subscribe((data: any) => {
        if (data.success) {
          this.openModal(data.entity);
        }
      });
    } else {
      this.openModal();
    }
  }

  openModal(location?: Location): void {
    const instance = this.modalHelper.open(LocationModalComponent);
    instance.componentInstance.location = location;
    instance.componentInstance.allLocations = this.locations;
    instance.componentInstance.hierarchySources = this.hierarchySources;
    instance.componentInstance.successEvent.subscribe((data: any) => {
      if (data.success) {
        this.responseHelperService.success(`Location successfully ${instance.componentInstance.isEdit ? 'updated' : 'created'}`, true);
        this.initFilter();
        this.getLocatons(false);
      }
    });
  }

  getLocatons(concat: boolean): void {
    this.isLoading = true;
    this.filter = this.searchFilters.getFilters(this.filter);
    this.locationService.getLocations(this.filter).subscribe((data: any) => {
      if (concat) {
        this.locations = this.locations.concat(parseResponse(data));
      } else {
        this.locations = parseResponse(data);
      }
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.isLoading = false;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
    }, () => {
      this.lockedTables = false;
      this.lock = false;
      this.isLoading = false;
      this.toastr.error('Error occured!');
    });
  }

  deleteLocation(id: number): void {
    const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
    instance.componentInstance.text = 'Delete location with ID: ' + id;
    instance.componentInstance.header = 'Delete location?';
    instance.componentInstance.successEvent.subscribe(() => {
      this.locationService.deleteLocation(id).subscribe(() => {
        this.responseHelperService.success('Location successfully removed', true);
        this.locationService.getLocation(id).subscribe((data: any) => {
          if (data.success) {
            this.locations[this.locations.findIndex(e => e.id === id)] = data.entity;
          }
        });
      }, (data: any) => {
        this.toastr.error(data.error.error);
      });
    });
  }

  setStatuses(): void {
    this.initFilter();
    this.filter.statuses = this.tableHelperService.extractData(this.filteredStatuses);
    if (!this.filter.statuses) {
      delete this.filter.statuses;
    }
    this.getLocatons(false);
  }

  findhierarchySource(id: number): any {
    return this.hierarchySources.find(element => element.id === id) || {};
  }

  getHierarchySource(): void {
    this.hierarchySourcesService.getHierarchySources().subscribe((data: any) => {
      if (data.success) {
        this.hierarchySources = parseResponse(data);
      }
    });
  }

}
