import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ImportChannelHelper {

    constructor() { }

    getImportChannels(): any[] {
        return [
            { name: 'BATCH', value: 1 },
            { name: 'WEB_SERVICE', value: 2 },
            { name: 'REST', value: 3 },
            { name: 'CONSOLE', value: 4 },
            { name: 'TEST', value: 5 },
            { name: 'ADMIN', value: 6 }
        ];
    }
}