<div class="status-dropdown">
    <span class="badge status-under" [ngClass]="statusHelper[selectedModel.status]">{{selectedModel.status}}</span>

    <span title="Click to change" class="status-dropbtn status-under status-dropdown-font-size" (click)="showDropdown()"
        *ngIf="permission">&nbsp;&nbsp;<fa-icon id="edit" [icon]="faPencilAlt"></fa-icon></span>
        
    <div id="{{'myDropdown' + type + selectedModel.id}}" class="status-dropdown-content"
        *ngIf="permission">
      <a *ngFor="let st of dropdownStatuses" (click)="updateStatus(st)" class="status-over status-dropdown-font-size">{{st}}</a>
    </div>
  </div>

