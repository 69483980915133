import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { faTimes, faSave, faCalendar, faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, FormBuilder, Validators, FormArray } from '@angular/forms';
import { PersonsService } from '../services/persons.service';
import { MembersService } from '../services/members.service';
import { ResponseHelperService } from '../services/helpers/response-helper.service';
import { DateHelperService } from '../services/helpers/date-helper.service';
import { InstrumentType, Gender, ProspectiveMember } from '../data/model';
import { InstrumentTypeService } from '../services/instrument-type.service';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-prospective-modal',
  templateUrl: './prospective-modal.component.html',
  styleUrls: ['./prospective-modal.component.scss']
})
export class ProspectiveModalComponent implements OnInit {

  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  member: ProspectiveMember = new ProspectiveMember();
  @Input() personId: number;
  instrumentTypes: InstrumentType[];
  activityInstruments: any;
  faCalendar = faCalendar;
  memberForm: UntypedFormGroup;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;
  faPlus = faPlus;
  faTrash = faTrash;
  genders: any[] = [];
  segments: any[];
  title = 'Prospective Member';

  constructor(private modalHelper: NgbModal,
              private instrumentTypeService: InstrumentTypeService,
              private formBuilder: FormBuilder,
              private personsService: PersonsService,
              private membersService: MembersService,
              private responseHelperService: ResponseHelperService,
              private dateHelperService: DateHelperService) { }

  ngOnInit(): void {
    this.getAllGenders();
    this.getInstrumentTypes();
    this.getProspectiveMember();
    this.memberForm = this.formBuilder.group({
      firstName: [this.member.firstName, Validators.required],
      lastName: [this.member.lastName, Validators.required],
      middleInitial: [this.member.middleInitial],
      dateOfBirth: [this.dateHelperService.stringToNgbDate(this.member.dateOfBirth), Validators.required],
      activityInstruments: this.formBuilder.array([]),
      gender: [this.member.gender, Validators.required]
    });
    this.addInstrument();
  }

  getProspectiveMember(): void {
    if (this.personId) {
      this.personsService.getProspectiveMember(this.personId).subscribe((data: any) => {
        if (data.success) {
          this.member = data.entity;
        }
      });
    }
  }

  getAllGenders(): void {
    this.personsService.getAllGenders().subscribe((data: any) => {
      if (data.success) {
        this.genders = parseResponse(data);
      }
    });
  }

  getInstrumentTypes(): void {
    this.instrumentTypeService.getInstrumentTypes({}).subscribe((data: any) => {
      if (data.success) {
        this.instrumentTypes = parseResponse(data);
      }
    });
  }

  ok(): void {
    this.member = this.memberForm.value;
    this.member.dateOfBirth = this.dateHelperService.ngbDateToString(this.memberForm.value.dateOfBirth);
    this.member.activityInstruments = this.member.activityInstruments.map((instrument: any) => {
      return {
        ...instrument, isPrimary: true, instrSelector: 'select'
      };
    });
    this.membersService.createProspectiveMember(this.member).subscribe((data: any) => {
      if (data.success) {
        this.responseHelperService.success('Prospective member created', true);
        this.successEvent.emit();
      }
    }, (data: any) => {
      this.responseHelperService.error(this, data.errorMsg);
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  clearDateInput(): void {
    this.memberForm.patchValue({
      dateOfBirth: null
    });
  }

  compareFn(gender1: Gender, gender2: Gender): boolean {
    return gender1 && gender2 && gender1.id === gender2.id;
  }

  removeInstrument(i: number): void {
    this.getInstruments().removeAt(i);
  }

  addInstrument(instrumentNumber?: string, instrumentType?: string, id?: number): void {
    this.getInstruments().push(this.newInstrument(instrumentNumber, instrumentType, id));
  }

  newInstrument(instrumentNumber?: string, instrumentType?: string, id?: number): UntypedFormGroup {
    return this.formBuilder.group({
      instrumentNumber: [instrumentNumber, Validators.required],
      instrumentType: [instrumentType, Validators.required],
      id: [id]
    });
  }

  getInstruments(): FormArray {
    return this.memberForm.controls.activityInstruments as FormArray;
  }
}
