import { PromotionCategory } from 'src/app/data/model';
import { ResponseHelperService } from './../services/helpers/response-helper.service';
import { PromotionService } from './../services/promotion.service';
import { Router } from '@angular/router';
import { SecurityService } from './../services/security/security.service';
import { Filter, SearchFilters } from 'src/app/data/class';
import { TableHelperService } from './../services/helpers/table-helper.service';
import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { faPlus, faPencilAlt, faClone, faTrash } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConfirmDialogComponent } from 'src/app/confirm-dialog/confirm-dialog.component';
import { parseResponse } from '../data/parseResponseFunction';

@Component({
  selector: 'app-promotion-templates-tab',
  templateUrl: './promotion-templates-tab.component.html',
  styleUrls: ['./promotion-templates-tab.component.scss']
})

export class PromotionTemplatesTabComponent implements OnInit, OnChanges {

  @Input() selectedProgramId: number;
  @Input() selectedPersonId: number;
  @Input() selectedPackageId: number;
  isLoading: boolean;
  lockedTables = false;
  lock = false;
  filter = new Filter();
  disableTemplateEdit: boolean;
  limit = 20;
  searchFilters: SearchFilters;
  promotionTemplates = new Array<PromotionCategory>();
  lengthToCompare: number;
  show = false;
  faPlus = faPlus;
  faPencilAlt = faPencilAlt;
  faClone = faClone;
  faTrash = faTrash;
  @Output() newLengthEvent: EventEmitter<any> = new EventEmitter();

  constructor(
    private tableHelperService: TableHelperService,
    private securityService: SecurityService,
    private router: Router,
    private promotionService: PromotionService,
    private toastr: ToastrService,
    private modalHelper: NgbModal,
    private responseHelperService: ResponseHelperService
  ) { }

  statuses = this.tableHelperService.getStatuses('ENTITY');

  ngOnInit(): void {
    this.securityService.disableTemplateEdit.subscribe((data: any) => {
      this.disableTemplateEdit = data;
    });

    this.searchFilters = {
      formName: 'promotionTemplateTab_search',
      filters: [
        {
          name: 'search',
          placeholder: 'Keyword...',
        }
      ],
      searchCallback: () => { this.initNewSearch(); },
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.selectedPersonId?.firstChange || !changes.selectedPackageId?.firstChange) {
      this.promotionTemplates = null;
      this.initNewSearch();
    }
  }

  initNewSearch(): void {
    this.initFilter();
    this.getPromotionTemplates(false);
  }

  initFilter(): void {
    this.filter.limit = this.limit;
    this.filter.offset = 0;
    this.filter.dir = 'desc';
    if (this.selectedPackageId) {
      this.filter.packageId = this.selectedPackageId;
    } else {
      delete this.filter.packageId;
    }
    this.filter.includeCount = true;
  }

  confirmEdit(temp: any): void {
    const url = '/programs/' + this.selectedProgramId + '/promotions/' + this.selectedPersonId + '/promotion-template/' + temp.id;
    if (temp.sponsorCount > 0) {
      const instance = this.modalHelper.open(ConfirmDialogComponent, { size: 'sm' });
      instance.componentInstance.text = 'This template is associated with active configurations used by ' + temp.sponsorCount + ' sponsors. Would you like to continue?';
      instance.componentInstance.header = 'Edit Promotion Template';
      instance.componentInstance.successEvent.subscribe(() => {
        instance.close();
        this.router.navigate([url]);
      });
    } else {
      this.router.navigate([url]);
    }
  }

  getPromotionTemplates(concat: boolean): void {
    this.filter = this.searchFilters.getFilters(this.filter);
    this.isLoading = true;
    this.promotionService.getPromotionTemplates(this.selectedPersonId, this.filter).subscribe((data: any) => {
      if (concat) {
        this.promotionTemplates = this.promotionTemplates.concat(parseResponse(data));
      } else {
        this.promotionTemplates = parseResponse(data);
      }
      this.show = true;
      this.lengthToCompare = parseResponse(data).length;
      this.newLengthEvent.emit(this.lengthToCompare);
      this.filter.offset += this.limit;
      this.lockedTables = parseResponse(data).length !== this.limit;
      this.lock = this.lockedTables;
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
      this.lockedTables = false;
      this.lock = false;
      this.toastr.error('Error occured!');
    });
  }

  deleteTemplate(templateId: number): void {
    this.promotionService.deletePromotionTemplate(templateId).subscribe((data: any) => {
      this.responseHelperService.success('Promotion Template deleted');
      this.filter.offset = 0;
      this.getPromotionTemplates(false);
    }, (data: any) => {
      this.responseHelperService.error(this, data.error.error);
    });
  }
}
