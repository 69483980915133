import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { UntypedFormGroup, FormBuilder, Validators } from '@angular/forms';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { ReportsService } from 'src/app/services/reports.service';
import { AppDataService } from 'src/app/app-data.service';
import { PersonsService } from 'src/app/services/persons.service';
import { parseResponse } from 'src/app/data/parseResponseFunction';

@Component({
  selector: 'app-report-modal',
  templateUrl: './report-modal.component.html',
  styleUrls: ['./report-modal.component.scss']
})
export class ReportModalComponent implements OnInit {

  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  errorMsg = '';
  faTimes = faTimes;
  reportForm: UntypedFormGroup;
  isAlphaNumerical = true;
  filteredSegments = [];
  chartOpt = {};
  chartOpts = [
    'BAR CHART',
    'PIE CHART',
    'LINE CHART'
  ];
  selectedSegments = [];
  regex = new RegExp('^[a-zA-Z0-9 _]*$');

  constructor(
    private responseHelperService: ResponseHelperService,
    private modalHelper: NgbModal,
    private statusHelperService: StatusHelperService,
    private formBuilder: FormBuilder,
    private reportService: ReportsService,
    public globalData: AppDataService,
    private personsService: PersonsService
  ) { }

  statuses = this.statusHelperService.getStatus('dropdown');

  ngOnInit(): void {
    this.reportForm = this.formBuilder.group({
      name: ['', Validators.required],
      status: ['', Validators.required],
      description: [''],
      dashboardReport: [false],
      interactiveReport: [false],
      outboundReport: [false],
      reportAttributes: [],
      forStaging: [false],
      segmentsCollection: [null, Validators.required],
      sqlQuery: [],
      chartOpt: []
    });
    this.getPersonSegments();
  }

  ok(): void {
    const report = this.reportForm.value;
    report.reportAttributes = [];
    if (report.chartOpt) {
      report.reportAttributes.push({
        attrValue: report.chartOpt.value,
        attrName: '_CHARTTYPE',
        reportId: report.id
      });
    }
    delete report.chartOpt;
    this.reportService.createReport(report).subscribe((data: any) => {
      this.successEvent.emit(data);
    }, (data: any) => {
      this.responseHelperService.error(this, data.error.error);
    });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

  getPersonSegments(): void {
    this.personsService.getPersonSegments(this.globalData.userId, true).subscribe((data: any) => {
      if (data.success) {
        this.filteredSegments = parseResponse(data);
      }
    });
  }

  checkIsAlphaNumerical(name: string): void {
    this.isAlphaNumerical = this.regex.test(name);
  }

}
