import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { UntypedFormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SurveyService } from 'src/app/services/survey.service';
import { Survey } from '../data/model';

@Component({
  selector: 'app-survey-modal',
  templateUrl: './survey-modal.component.html',
  styleUrls: ['./survey-modal.component.scss']
})
export class SurveyModalComponent implements OnInit {

  @Input() surveyId: string;
  @Input() answer: string;
  @Output() successEvent: EventEmitter<any> = new EventEmitter();
  title: string;
  surveyForm: UntypedFormGroup;
  isEdit: boolean;
  errorMsg = '';
  faTimes = faTimes;
  faSave = faSave;

  constructor(
    private surveyService: SurveyService,
    private modalHelper: NgbModal,
    private formBuilder: FormBuilder,
  ) { }


  ngOnInit(): void {
    this.title = 'Edit Survey';
    this.surveyForm = this.formBuilder.group({
      answers: [this.answer, Validators.required],
    });
  }


  // TODO
  ok(): void {
    // this.surveyService.createOrUpdateSurvey(this.survey.id, this.surveyForm.value).subscribe((data: any) => {
    //   this.successEvent.emit(data);
    // }, (data: any) => {
    //   this.errorMsg = data.error.error;
    // });
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

  clearErrorMessage(): void {
    this.errorMsg = '';
  }

}
