<form [formGroup]="phoneForm" novalidate="">
    <div class="modal-header">
      <h1>{{title}}</h1>
    </div>
    <div class="modal-body">
        <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
            {{errorMsg}}
            <a (click)="clearErrorMessage()">
              <span class="float-right close-button">
                <fa-icon [icon]="faTimes"></fa-icon>
              </span>
            </a>
        </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>Phone Number</label>
            <input type="text" name="phone_number" formControlName="phoneNumber"  class="form-control" autofocus="">
            <div class="mt-1" [hidden]="phoneForm.controls.phoneNumber.valid">
              <small class="text-danger">Input phone number</small>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label>Phone Type</label>
            <ng-select [items]="phoneTypes" bindLabel="typeName" bindValue="id" formControlName="phoneTypeId">
            </ng-select>
            <div class="mt-1" [hidden]="phoneForm.controls.phoneTypeId.valid">
              <small class="text-danger">Select phone type</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-6">
          <div class="form-group">
            <label>Status</label>
            <select class="form-control" formControlName="status">
                <option *ngFor="let option of statuses" value="{{option}}">{{option}}</option>
              </select>
            <div class="mt-1" [hidden]="phoneForm.controls.status.valid">
              <small class="text-danger">Select status</small>
            </div>
          </div>
        </div>
        <div class="col-sm-6">
          <div class="form-group form-check">
            <input type="checkbox" formControlName="isPrimary" id="isPrimary" class="form-check-input" />
            <label for="isPrimary" class="form-check-label">Primary</label>
          </div>
        </div>
      </div>

    </div>
    <div class="modal-footer">
      <button class="btn btn-primary" type="submit" (click)="ok()" [disabled]="!phoneForm.valid || !phoneForm.dirty"> Save </button>
      <button class="btn btn-warning" type="button" (click)="cancel()"> Cancel </button>
    </div>
  </form>
