<form [formGroup]="behaviorsForm">
    <div class="modal-header">
      <h1>{{title}}</h1>
    </div>
    <div class="modal-body">
      <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
        {{errorMsg}}
        <a (click)="clearErrorMessage()">
          <span class="float-right close-button">
            <fa-icon [icon]="faTimes"></fa-icon>
          </span>
        </a>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>Name</label>
            <input type="text" class="form-control" formControlName="name" [appTrim]="!isEdit">
            <div class="mt-sm" [hidden]="behaviorsForm.controls['name'].valid">
              <small class="text-danger">Input name</small>
            </div>
          </div>
        </div>
        <div class="col-sm-12">
          <div class="form-group">
            <label>Code</label>
            <input type="text" class="form-control" formControlName="code" [appTrim]="!isEdit">
            <div class="mt-sm" [hidden]="behaviorsForm.controls['code'].valid">
              <small class="text-danger">Input code</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>Hierarchy Source</label>
            <ng-select [items]="hierarchySources" bindLabel="name" bindValue="id" formControlName="hierarchySourceId">
            </ng-select>
            <div class="mt-sm" [hidden]="behaviorsForm.controls['hierarchySourceId'].valid">
              <small class="text-danger">Select hierarchy source</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>Status</label>
            <select formControlName="status" class="form-control">
              <option *ngFor="let option of statuses" value="{{option}}">{{option}}</option>
            </select>
            <div class="mt-sm" [hidden]="behaviorsForm.controls['status'].valid">
              <small class="text-danger">Select status</small>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-sm-12">
          <div class="form-group">
            <label>Description</label>
            <textarea formControlName="description" class="form-control vertical-expand" [appTrim]="!isEdit"></textarea>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button class="btn btn-dark-blue" type="submit" [disabled]="!behaviorsForm.valid" (click)="ok()" title="Save"><fa-icon [icon]="faSave"></fa-icon></button>
      <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel"><fa-icon [icon]="faTimes"></fa-icon></button>
    </div>
  </form>
