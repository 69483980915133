<div class="wrapper wrapper-content" *ngIf="report">
    <div class="row">
        <div class="col-md-12">
            <h2>Report Management</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-4">
            <div class="wrapper wrapper-content">
                <div class="ibox">
                    <div class="ibox-content">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="mb-3">
                                    <h2 class="fullname">Report Management</h2>
                                </div>
                            </div>
                        </div>
                        <div class="tabs-container">
                            <ul ngbNav #nav="ngbNav" [(activeId)]="activeId" class="nav-tabs">
                                <li [ngbNavItem]="1">
                                    <a ngbNavLink>Report Data</a>
                                    <ng-template ngbNavContent>
                                        <app-report-data [report]="report" [externalAppUrl]="externalAppUrl" [renderForm]="renderForm">
                                        </app-report-data>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="2">
                                    <a ngbNavLink>Segments</a>
                                    <ng-template ngbNavContent>
                                        <app-segments-tab [reportId]="report.id" type="report"></app-segments-tab>
                                    </ng-template>
                                </li>
                            </ul>
                            <div [ngbNavOutlet]="nav" class="mt-2"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-8">
            <div class="row">
                <div class="col-md-12">
                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            <h5>Client Report Parameters <fa-icon
                                    title="These paremeters represent choosable parameters on report or dashboard page"
                                    [icon]="faQuestionCircle"></fa-icon>
                            </h5>
                        </div>
                        <div class="ibox-content">
                            <div class="row" *ngFor="let param of clientParams; let i=index;"
                                [hidden]="!clientParams || clientParams.length === 0">
                                <div class="col-md-11">
                                    <div class="row">
                                        <div class="col-sm-1 width-5">
                                            <div class="form-group">
                                                <label *ngIf="i === 0">Order</label>
                                                <input type="text" [(ngModel)]="param.paramSeqNum" class="form-control"
                                                    name="paramSeqNum" ngDefaultControl>
                                            </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <div class="form-group">
                                                <label *ngIf="i === 0">Code</label>
                                                <input type="text" [(ngModel)]="param.paramCode" class="form-control"
                                                    name="paramCode" ngDefaultControl>
                                            </div>
                                        </div>
                                        <div class="col-sm-2 width-14">
                                            <div class="form-group">
                                                <label *ngIf="i === 0">Name</label>
                                                <input type="text" [(ngModel)]="param.paramName" class="form-control"
                                                    name="paramName" ngDefaultControl>
                                            </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <div class="form-group">
                                                <label *ngIf="i === 0">Type</label>
                                                <select [(ngModel)]="param.paramType" class="form-control"
                                                    name="paramType" ngDefaultControl
                                                    [disabled]="param.autocompleteAction !== ''  && (param.paramType === 'BIGINT' || param.paramType === 'VARCHAR')">
                                                    <option [selected]="param.paramType === option"
                                                        *ngFor="let option of paramTypes" value="{{option}}">{{option}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <label *ngIf="i === 0">Default</label>
                                            <div class="form-group"
                                                *ngIf="param.autocompleteAction && (param.paramType === 'BIGINT' || param.paramType === 'VARCHAR')">
                                                <ng-select [items]="autocompleteData[param.paramCode]" bindLabel="name"
                                                    name="default" name="default" ngDefaultControl bindValue="name"
                                                    [hideSelected]="true" [closeOnSelect]="false"
                                                    [(ngModel)]="autocomplete[param.paramCode]">
                                                </ng-select>
                                            </div>
                                            <div class="form-group"
                                                *ngIf="param.autocompleteAction && (param.paramType === 'ARRAY VARCHAR' || param.paramType === 'ARRAY INTEGER')">
                                                <ng-select [items]="allDataArray[param.paramCode]" bindLabel="text"
                                                    name="default" ngDefaultControl multiple="true" bindValue="text"
                                                    [hideSelected]="true" [closeOnSelect]="true"
                                                    [(ngModel)]="selectedDataArray[param.paramCode]">
                                                </ng-select>
                                            </div>
                                            <div class="form-group" *ngIf="param.paramType === 'TIMESTAMP'">
                                                <select [(ngModel)]="param.defaultValue" class="form-control"
                                                    name="defaultValue" ngDefaultControl>
                                                    <option [selected]="param.defaultValue === option" name="default"
                                                        *ngFor="let option of defaultValues[param.paramType]"
                                                        value="{{option}}">{{option}}</option>
                                                </select>
                                            </div>
                                            <div class="form-group" *ngIf="param.paramType === 'BIGINT'">
                                                <select [(ngModel)]="param.defaultValue" class="form-control"
                                                    name="defaultValue" ngDefaultControl>
                                                    <option [selected]="param.defaultValue === option" name="default"
                                                        *ngFor="let option of defaultValues[param.paramType]"
                                                        value="{{option}}">{{option}}</option>
                                                </select>
                                            </div>
                                            <div class="form-group"
                                                *ngIf="isInput(param.paramType) && !param.autocompleteAction">
                                                <input type="text" name="defaultValue" class="form-control"
                                                    [(ngModel)]="param.defaultValue">
                                            </div>
                                        </div>
                                        <div class="col-sm-2">
                                            <div class="form-group">
                                                <label *ngIf="i === 0">Autocomplete</label>
                                                <select *ngIf="isArray(param)" [(ngModel)]="param.autocompleteAction"
                                                    name="autocompleteAction" ngDefaultControl
                                                    (change)="getDataForParams(param)" class="form-control">
                                                    <option [selected]="param.autocompleteAction === option"
                                                        *ngFor="let option of autocomplete" value="{{option}}">
                                                        {{option}}
                                                    </option>
                                                </select>
                                                <input *ngIf="!isArray(param)" type="text" name="autocompleteAction"
                                                    ngDefaultControl [(ngModel)]="param.autocompleteAction"
                                                    (keyup)="changedAuto(param)" class="form-control">
                                            </div>
                                        </div>
                                        <div>
                                            <div class="col-sm-1 width-3">
                                                <div class="form-group">
                                                    <label *ngIf="i === 0">Req</label>
                                                    <input class="checkbox checkbox-success" type="checkbox"
                                                        [(ngModel)]="param.requiredParam" name="removeParam"
                                                        ngDefaultControl>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-1">
                                    <table class="form-group" [ngClass]="{'pt-2' : i === 0}">
                                        <tr>
                                            <button type="button" class="btn btn-sm btn-dark-blue"
                                                (click)="updateParam(param)">
                                                <fa-icon title="Update" [icon]="faSyncAlt"></fa-icon>
                                            </button>
                                        </tr>
                                        <tr>
                                            <button type="button" class="btn btn-sm btn-attention"
                                                (click)="removeParam(param.id,'ClientParam')" title="Delete">
                                                <fa-icon [icon]="faTrash"></fa-icon>
                                            </button>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="row" *ngIf="!clientParams || clientParams.length === 0">
                                <div class="col-sm-12 text-center">
                                    No client parameters
                                </div>
                            </div>
                        </div>
                        <div class="ibox-footer">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <a class="btn btn-sm btn-dark-blue ml-2 float-right" (click)="addParam(false)"
                                            title=" Add New">
                                            <fa-icon [icon]="faPlus"></fa-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            <h5>System Report Parameters <fa-icon
                                    title="These paremeters represent system parameters that are preselected and not shown anywhere"
                                    [icon]="faQuestionCircle"></fa-icon>
                            </h5>
                        </div>
                        <div class="ibox-content">
                            <div class="row" *ngFor="let sParam of systemParams; let i = index"
                                [hidden]="!systemParams || systemParams.length === 0">
                                <div class="col-sm-2">
                                    <div class="form-group">
                                        <label *ngIf="i === 0">Code</label>
                                        <input type="text" [(ngModel)]="sParam.paramCode" name="paramCode"
                                            class="form-control" ngDefaultControl>
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-group">
                                        <label *ngIf="i === 0">Name</label>
                                        <input type="text" [(ngModel)]="sParam.paramName" name="paramName"
                                            class="form-control" ngDefaultControl>
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-group">
                                        <label *ngIf="i === 0">Type</label>
                                        <select [(ngModel)]="sParam.paramType" name="paramType" class="form-control"
                                            ngDefaultControl [disabled]="sParam.autocompleteAction">
                                            <option [selected]="sParam.paramType === option"
                                                *ngFor="let option of paramTypes" value="{{option}}">{{option}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-group" [ngSwitch]="sParam.paramType">
                                        <label *ngIf="i === 0">Default</label>
                                        <select *ngSwitchCase="'TIMESTAMP'" [(ngModel)]="sParam.defaultValue"
                                            name="defaultValue" ngDefaultControl class="form-control">
                                            <option [selected]="sParam.paramType === option"
                                                *ngFor="let option of defaultValues[sParam.paramType]"
                                                value="{{option}}">{{option}}</option>
                                        </select>
                                        <select *ngSwitchCase="'BIGINT'" [(ngModel)]="sParam.defaultValue"
                                            name="defaultValue" ngDefaultControl class="form-control">
                                            <option [selected]="sParam.paramType === option"
                                                *ngFor="let option of defaultValues[sParam.paramType]"
                                                value="{{option}}">{{option}}</option>
                                        </select>
                                        <input *ngSwitchDefault type="text" [(ngModel)]="sParam.defaultValue"
                                            name="defaultValue" ngDefaultControl class="form-control">
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="form-group float-right" [ngClass]="{'pt-2' : i === 0}">
                                        <button type="button" class="btn btn-sm btn-dark-blue"
                                            (click)="updateParam(sParam)" title="Update">
                                            <fa-icon [icon]="faSyncAlt"></fa-icon>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-attention"
                                            (click)="removeParam(sParam.id, 'SystemParam')" title="Delete">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row" *ngIf="!systemParams || systemParams.length === 0">
                                <div class="col-sm-12 text-center">
                                    No system parameters
                                </div>
                            </div>
                        </div>
                        <div class="ibox-footer">
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <a class="btn btn-sm btn-dark-blue ml-2 float-right" (click)="addParam(true)"
                                            title="Add New">
                                            <fa-icon [icon]="faPlus"></fa-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="ibox float-e-margins">
                        <div class="ibox-title">
                            <h5>Report Attributes</h5>
                        </div>
                        <div class="ibox-content" *ngIf="showAttributes()">
                            <div class="row" *ngFor="let attr of attributes ; let i = index;">
                                <div class="col-sm-2">
                                    <div class="form-group">
                                        <label *ngIf="i === 0">Name</label>
                                        <input type="text" [(ngModel)]="attr.name" class="form-control" name="name"
                                            ngDefaultControl (change)="updateDropdown()">
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-group">
                                        <label *ngIf="i === 0">Type</label>
                                        <select [(ngModel)]="attr.type" class="form-control" name="type"
                                            ngDefaultControl>
                                            <option [selected]="attr.type === option" *ngFor="let option of attrTypes"
                                                value="{{option}}">{{option}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-group">
                                        <label *ngIf="i === 0">Relates to</label>
                                        <select [(ngModel)]="attr.relatesTo" class="form-control" name="relatesTo"
                                            ngDefaultControl [hidden]="!(attr.type === 'ROW')"
                                            title="This represents grouping of data">
                                            <option [selected]="attr.relatesTo === option"
                                                *ngFor="let option of relatesTo" value="{{option}}">
                                                {{option}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-group">
                                        <label *ngIf="i === 0">Caption</label>
                                        <input type="text" [(ngModel)]="attr.caption" class="form-control"
                                            name="caption" ngDefaultControl>
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-group">
                                        <label *ngIf="i === 0">Aggregation</label>
                                        <select [(ngModel)]="attr.dataSettings.aggregateFunc" class="form-control"
                                            name="aggregateFunc" ngDefaultControl [disabled]="attr.type !== 'DATA'">
                                            <option [selected]="attr.dataSettings.aggregateFunc === option"
                                                *ngFor="let option of aggtegation">{{option}}</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-sm-2">
                                    <div class="form-group float-right" [ngClass]="{'pt-2' : i === 0}">
                                        <button type="button" class="btn btn-sm btn-attention"
                                            (click)="removeAttr('attributes', i)" title="Delete">
                                            <fa-icon [icon]="faTrash"></fa-icon>
                                        </button>
                                        <button type="button" class="btn btn-sm btn-dark-blue"
                                            (click)="addAttr('attributes')" [hidden]="i !== attributes.length - 1"
                                            title="Add">
                                            <fa-icon [icon]="faPlus"></fa-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="ibox-footer">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <a class="btn btn-sm btn-dark-blue ml-2 float-right"
                                            (click)="updateAttributes()" title="Update">
                                            <fa-icon [icon]="faSyncAlt"></fa-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
