import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Program } from '../data/model';

@Component({
  selector: 'app-associated-programs-modal',
  templateUrl: './associated-programs-modal.component.html',
  styleUrls: ['./associated-programs-modal.component.scss']
})
export class AssociatedProgramsModalComponent implements OnInit {

  @Input() public programs: Program[];
  @Input() public packageName: string;

  constructor(private modalHelper: NgbModal) { }

  ngOnInit(): void {
  }

  cancel(): void {
    this.modalHelper.dismissAll();
  }

}
