<form #f="ngForm" name="form">
  <div class="modal-header">
    <h1>Edit entry</h1>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>Message</label>
      <textarea type="text" name="logEntry" [(ngModel)]="ticketEnrty.logEntry" class="form-control vertical-expand" autofocus></textarea>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="submit" (click)="ok()"> Save </button>
    <button class="btn btn-warning" type="button" (click)="cancel()"> Cancel </button>
  </div>
</form> 