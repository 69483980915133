<div class="panel-body row overflow-y-auto">
  <div class="col-lg-9" *ngIf="show">
    <app-search-filter *ngIf="showSearch" [searchFilters]="searchFilters"></app-search-filter>
    <div class="row mt-4">
      <table class="table table-pointable table-bordered w-100" dt-instance="dtInstance">
        <thead>
        <tr>
          <th>ID</th>
          <th>Code</th>
          <th>Name</th>
          <th>Sponsorship Tag</th>
          <th>Category</th>
          <th>Type</th>
          <th>Program</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let promotion of promotions" (click)="showPromotion($event, promotion)">
          <td><a
            routerLink="/sponsors/{{personId}}/packaged-sponsorship/{{promotion.sponsorshipTag}}"
            [queryParams]="{ 'promoSponsorId': promotion.promotionSponsorId }">{{promotion.promotionSponsorId}}</a>
          </td>
          <td><a routerLink="/programs/{{promotion.program.id}}/promotions" [queryParams]="{ 'promoId': promotion.id }">{{promotion.code}}</a>
          </td>
          <td>{{promotion.name}}</td>
          <td [attr.title]="promotion.sponsorshipTag" class="break-all"><a
            routerLink="/sponsors/{{personId}}/packaged-sponsorship/{{promotion.sponsorshipTag}}">{{promotion.sponsorshipTag}}</a>
          </td>
          <td>{{promotion.promotionCategoryName}}</td>
          <td>{{promotion.promotionTypeName}}</td>
          <td><a routerLink="/programs/{{promotion.program.id}}/promotions">{{promotion.program.name}}</a></td>
        </tr>
        <tr app-is-there-more [numColumns]="7" [newLengthEvent]="newLengthEvent"></tr>
        </tbody>
      </table>
    </div>
  </div>
  <div class="col-lg-3 text-center">
    <div class="row">
      <h2 class="col-md-10"><span class="light-blue">Campaigns:</span></h2>
      <div class="col-md-2 float-right" [hidden]="!select.selected">
        <button type="button" class="btn btn-sm btn-dark-blue float-right" (click)="campaignModal()"
                title="Adds existing promotion to campaign">
          <fa-icon [icon]="faCalendarPlus"></fa-icon>
        </button>
      </div>
    </div>
    <span [hidden]="campaigns.length">No campaigns</span>
    <div *ngIf="campaigns.length > 0" class="row integration-details-bar"></div>
    <div class="row border-card" *ngFor="let camp of campaigns; let i = index" [ngClass]="{'mt-3': i!==0}">
      <div class="col-sm-12">
        <button type="button" class="btn btn-attention btn-sm float-right"
                (click)="removeCampaign(selectedPromotion.promotionSponsorId, camp)">
          <fa-icon [icon]="faTimes"></fa-icon>
        </button>
      </div>
      <a class="col-sm-12" routerLink="/programs" [queryParams]="{ 'campaign': camp.name }">
        <div class="row">
        </div>
        <div class="row">
          <div class="col-sm-4">
            <span class="float-left">Name:</span>
          </div>
          <div class="col-sm-8">
            <span class="float-right text-right word-break-break-all mt-2">{{camp.name}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <span class="float-left">Code:</span>
          </div>
          <div class="col-sm-8">
            <span class="float-right text-right word-break-break-all">{{camp.code}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <span class="float-left">Status:</span>
          </div>
          <div class="col-sm-8">
            <span class="float-right text-right">{{camp.status}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <span class="float-left">Cap:</span>
          </div>
          <div class="col-sm-8">
            <span class="float-right text-right">{{camp.cap}}</span>
          </div>
        </div>
        <div class="row" *ngIf="camp.sponsorCap">
          <div class="col-sm-4">
            <span class="float-left">Sponsor Cap:</span>
          </div>
          <div class="col-sm-8">
            <span class="float-right text-right">{{camp.sponsorCap}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <span class="float-left">From:</span>
          </div>
          <div class="col-sm-8">
            <span class="float-right text-right">{{camp.fromDate | date: 'yyyy-MM-dd'}}</span>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-4">
            <span class="float-left">Thru:</span>
          </div>
          <div class="col-sm-8">
            <span class="float-right text-right">{{camp.thruDate | date: 'yyyy-MM-dd'}}</span>
          </div>
        </div>
      </a>
    </div>
    <div *ngIf="campaigns.length > 0" class="row integration-details-bar"></div>
  </div>
</div>
<app-add-more-data-to-list (onScroll)="getPromotions(true)" [lock]="lock" [isLoading]="isLoading">
</app-add-more-data-to-list>
