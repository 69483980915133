<form [formGroup]="logNoteForm">
    <div class="modal-header">
        <h1>Processing log note</h1>
    </div>
    <div class="modal-body">
        <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
            {{errorMsg}}
            <a (click)="clearErrorMessage()">
                <span class="float-right close-button">
                    <fa-icon [icon]="faTimes"></fa-icon>
                </span>
            </a>
        </div>
        <div class="form-group">
            <textarea formControlName="notes" class="form-control vertical-expand">
            </textarea>
        </div>
    </div>
    <div class="modal-footer">
        <button class="btn btn-dark-blue" type="submit" (click)="ok()" title="Save">
            <fa-icon [icon]="faSave"></fa-icon>
        </button>
        <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
            <fa-icon [icon]="faTimes"></fa-icon>
        </button>
    </div>
</form>