import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { ProgramService } from 'src/app/services/program.service';
import { PromotionConfigService } from 'src/app/services/promotion-config.service';
import { PromotionService } from 'src/app/services/promotion.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FormBuilder, UntypedFormGroup, Validators, FormsModule } from '@angular/forms';
import { faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { ActivatedRoute } from '@angular/router';
import { PromotionTemplate } from 'src/app/data/model';
import { Promotion } from 'src/app/data/model';
import * as $ from 'jquery';
import {Observable, Subject} from 'rxjs';
import { RegexSearchModalComponent } from 'src/app/regex-search-modal/regex-search-modal.component';
import { fromEvent, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'activity-trigger',
  templateUrl: './activity-trigger.component.html',
  styleUrls: ['./activity-trigger.component.scss']
})
export class ActivityTriggerComponent implements OnInit {

  @Input() type: string;
  @Input() selectedModel: any;
  @Input() triggers: any;
  //@Output() updateTriggers : EventEmitter<any> = new EventEmitter(true);
  @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
  debouncer: Subject<string> = new Subject<string>();
  service: any;
  allowedToUpdate: any;
  @ViewChild(RegexSearchModalComponent) regexSearchModalComponent: RegexSearchModalComponent;


  constructor(private router: ActivatedRoute,
              private formBuilder: FormBuilder,
              private formsModule: FormsModule,
              private statusHelperService: StatusHelperService,
              private programService: ProgramService,
              private modalHelper: NgbModal,
              private responseHelperService: ResponseHelperService,
              private promotionConfigService: PromotionConfigService,
              private configService: ConfigurationService,
              private promotionService: PromotionService) {
    this.debouncer
      .pipe(debounce(() => interval(1000)))
      .subscribe((value) => this.inputChangeEmitter.emit(value));
  }

  ngOnInit(): void {
    if(!this.triggers || this.triggers === null
      || !this.triggers.primaryBehaviorOrRewardedBehavior
      || this.triggers.primaryBehaviorOrRewardedBehavior[0].class === 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Triggers$RewardedBehavior'
     ) {
      this.initTriggerObj();
    }
  }

    regexSearchModal(searchFor: string, item: any, size: string, limitToOne: boolean, setValue: boolean): void {
      this.openRegexSearchModal(searchFor, item, size, limitToOne, setValue);
    }

    openRegexSearchModal(searchFor: string, item: any, size: string, limitToOne: boolean, setValue: boolean): void {
      const instance = this.modalHelper.open(RegexSearchModalComponent);
      instance.componentInstance.searchFor = searchFor;
      instance.componentInstance.item = item;
      instance.componentInstance.size = size;
      instance.componentInstance.limitToOne = limitToOne;
      instance.componentInstance.setValue = setValue;
      //instance.componentInstance.model = model;
      instance.componentInstance.successEvent.subscribe((text: any) => {
        console.log(text);
        console.log(item);
        this.triggers.primaryBehaviorOrRewardedBehavior[0][item.name] = text;
        this.updateTriggers();
        instance.close();
      });
    }

    initTriggerObj(): void {
      var tmpObj = {
                     class: 'com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Triggers$PrimaryBehavior',
                     behaviorCode: '',
                     locationCode: '(.*)',
                     instrumentTypeCode: '(.*)'};
      this.triggers.primaryBehaviorOrRewardedBehavior = [];
      this.triggers.primaryBehaviorOrRewardedBehavior.push(tmpObj);
      this.updateTriggers();
    };

    updateTriggers() {
      this.debouncer.next(this.triggers);
    }
}
