<form [formGroup]="communicationStrategyConfigurationForm" novalidate>
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="form-group">
          <label>Delivery Window</label>
          <small><em> All times are UTC</em></small>
          <div class="row">
            <div class="col-sm-5">
              <ng-select [items]="timeOptions" bindValue="value" formControlName="fromTime" [clearable]="false" [searchable]="false"
                placeholder="--:-- --">
                <ng-template ng-label-tmp let-item="item">
                  <div class="select-align-center">{{item.label | date: 'hh:mm a'}}</div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <div class="select-align-center">{{item.label | date: 'hh:mm a'}} </div>
                </ng-template>
              </ng-select>
            </div>
            <div class="col-sm-2 hotizontal-line">
              <hr class="width-40">
            </div>
            <div class="col-sm-5">
              <ng-select [items]="timeOptions" bindValue="value" formControlName="toTime" [clearable]="false" [searchable]="false"
                placeholder="--:-- --">
                <ng-template ng-label-tmp let-item="item">
                  <div class="select-align-center">{{item.label | date: 'hh:mm a'}}</div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                 <div class="select-align-center">{{item.label | date: 'hh:mm a'}} </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
          <div class="input-daterange input-group">
          </div>
          <div class="mt-2" [hidden]="communicationStrategyConfigurationForm.controls.fromTime.valid
             && communicationStrategyConfigurationForm.controls.toTime.valid">
            <small class="text-danger">Input delivery window</small>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <label>Black Out Periods</label>
      <ng-select [items]="days" [multiple]="true" [hideSelected]="true" [closeOnSelect]="false" [clearable]="false"
        formControlName="blackOutPeriods">
      </ng-select>
    </div>
    <div class="form-group">
      <label>Opt-In/Opt-Out</label>
      <ng-select [items]="optInOptOutOptions" bindLabel="text" bindValue="value" formControlName="optInOptOut"
        [hideSelected]="true" [clearable]="false">
      </ng-select>
      <div class="mt-2" [hidden]="communicationStrategyConfigurationForm.controls.optInOptOut.valid">
        <small class="text-danger">Select Option</small>
      </div>
    </div>
    <div class="form-group">
      <label>Endpoint Type</label>
      <ng-select [items]="endpointTypes" bindLabel="name" bindValue="name" formControlName="endpointTypeName"
        [hideSelected]="true" [clearable]="false">
      </ng-select>
      <div class="mt-2" [hidden]="communicationStrategyConfigurationForm.controls.endpointTypeName.valid">
        <small class="text-danger">Select type</small>
      </div>
    </div>
    <div class="form-group">
      <div>
        <label>Communication Caps</label>
        <button class="btn btn-sm btn-dark-blue float-right" type="button" (click)="addCommCap()"
          title="Add New Campaign Attribute">
          <fa-icon [icon]="faPlus"></fa-icon>
        </button>
        <div class="row" [hidden]="getCommCaps().controls.length === 0">
          <div class="col-md-5">
            <div class="form-group">
              <label>Cap</label>
            </div>
          </div>
          <div class="col-md-5">
            <div class="form-group">
              <label>Per Period</label>
            </div>
          </div>
        </div>
        <div formArrayName="commCaps">
          <div class="row" *ngFor="let commCap of getCommCaps().controls; let i=index" [formGroupName]="i">
            <div class="col-md-5">
              <div class="form-group">
                <input type="number" name="maxCommunicationsNum" class="form-control"
                  formControlName="maxCommunicationsNum"/>
                <div class="mt-2" [hidden]="commCap.controls.maxCommunicationsNum.valid">
                  <small class="text-danger">Input communication cap</small>
                </div>
              </div>
            </div>
            <div class="col-md-5">
              <select class="form-control" formControlName="maxCommunicationsPeriod">
                <option [selected]="commCap.maxCommunicationsPeriod === option" *ngFor="let option of period"
                  value="{{option}}">{{option}}</option>
              </select>
              <div class="mt-2" [hidden]="commCap.controls.maxCommunicationsPeriod.valid">
                <small class="text-danger">Input communication period</small>
              </div>
            </div>
            <div class="col-md-2">
              <div class="form-group float-right">
                <button class="btn btn-dark-blue btn-sm delete float-right" (click)="removeCommCap(i)">
                  <fa-icon [icon]="faTrash"></fa-icon>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-dark-blue" type="submit" [disabled]="!communicationStrategyConfigurationForm.valid"
      (click)="ok()" title="Save">
      <fa-icon [icon]="faSave"></fa-icon>
    </button>
    <button class="btn btn-attention" type="button" (click)="cancel()" title="Cancel">
      <fa-icon [icon]="faTimes"></fa-icon>
    </button>
  </div>
</form>