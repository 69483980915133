<div>
  <form>
    <label>The number of Candidate Communications:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="commCandidateCap !== null">
        <div *ngFor="let commCandCap of commCandidateCap; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeCap(commCandCap)" title="Remove Cap">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label>for the current:</label>
              <select name="period_{{commCandCap.capValue}}" class="form-control" [(ngModel)]="commCandCap.capPeriod" (ngModelChange)="updateCaps()">
                <option *ngFor="let period of periods" [selected]="commCandCap.capPeriod === period.value" [value]="period.value">{{period.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="thresholdVal_{{commCandCap.capValue}}">caps at (maximum number):</label>
              <input class="form-control m-b" type="number" id="thresholdVal_{{commCandCap.capValue}}" name="thresholdValue" [(ngModel)]="commCandCap.capValue" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <a
                class="control-label"
                ng-class="{ 'disabled-link': readOnly }"
                (click)="
                  regexSearchModal(
                    'COMMUNICATIONS',
                    { object: commCandCap, name: 'communicationNames' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                title="Search for Communications"
                >Across Communications with Names (comma separated list):</a
              >
              <input
                class="form-control form-readonly m-b"
                type="text"
                id="communicationNames_{{ commCandCap.capValue }}"
                name="communicationNames"
                [(ngModel)]="commCandCap.communicationNames"
                (ngModelChange)="updateCaps()"
                (click)="
                  regexSearchModal(
                    'COMMUNICATIONS',
                    { object: commCandCap, name: 'communicationNames' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                readonly
              />
            </div>
            <div class="form-group">
              <label class="control-label" for="previousPeriodCount_{{commCandCap.capValue}}">How many previous periods should be included in the time span (leave empty for only the current period):</label>
              <input class="form-control m-b" type="number" id="previousPeriodCount_{{commCandCap.capValue}}" name="previousPeriodCount" [(ngModel)]="commCandCap.previousPeriodCount" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="relationshipName_{{commCandCap.capValue}}">Include counts from members related by (leave blank for current member only or use a comma separated list):</label>
              <input class="form-control m-b" type="text" id="relationshipName_{{commCandCap.capValue}}" name="relationshipName" [(ngModel)]="commCandCap.relationshipName" (ngModelChange)="updateCaps()" />
            </div>
            <div class="form-group">
              <label class="control-label" for="friendlyMessage_{{commCandCap.capValue}}">Message:</label>
              <input class="form-control m-b" type="text" id="friendlyMessage_{{commCandCap.capValue}}" name="friendlyMessage" [(ngModel)]="commCandCap.friendlyMessage" (ngModelChange)="updateCaps()" />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initCapObject()"><fa-icon [icon]="faPlus"></fa-icon>  Add Communication Candidate Cap</button>
      </div>
    </div>
  </form>
</div>
