<div class="panel-body">
  <div class="col-md-12">
      <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
      <div class="row">
        <table class="table table-pointable table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>Sponsor</th>
              <th>Name</th>
              <th>Sort</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let pc of promotionCategories">
              <td>{{pc.id}}</td>
              <td><a routerLink="/sponsors/{{pc.sponsorPartyId}}">{{pc.sponsorName}}</a></td>
              <td>{{pc.name}}</td>
              <td>{{pc.sort}}</td>
              <td>{{pc.status}}</td>
              <td>
                <div class="actions inline-table float-left">
                  <a class="btn btn-dark-blue btn-sm edit action" (click)="promotionCategoryModal(pc)" title="Edit"><fa-icon [icon]="faPencilAlt"></fa-icon></a>
                  <button class="btn btn-attention btn-sm delete action" [disabled]="pc.status==='DELETED'" (click)="deletePromotionCategory(pc)" title="Delete"><fa-icon [icon]="faTrash"></fa-icon></button>
                </div>
              </td>
            </tr>
            <tr app-is-there-more [numColumns]="6" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
    </div>
    <app-add-more-data-to-list (onScroll)="getPromotionCategories(true)" [lock]="lock" [isLoading]="isLoading"></app-add-more-data-to-list>
  </div>
</div>

