import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { InMemoryCache } from '@apollo/client/core';
import { ConfigurationService } from './configuration.service';
import { HttpLink } from 'apollo-angular/http';

@Injectable({
  providedIn: 'root',
})
export class ApolloService {
  constructor(private httpLink: HttpLink, private configurationService: ConfigurationService, private apollo: Apollo) {}

  public async createClient() {
    const token = localStorage.getItem('token');
    if (token) {
      await new Promise((resolve) => {
        this.configurationService.getConfiguration('MEMBER_UI_LOCATION').subscribe((data: any) => {
          this.apollo.removeClient();
          this.apollo.create({
            link: this.httpLink.create({ uri: data.entity[0].cfgValue + '/admin/graphql' }),
            cache: new InMemoryCache(),
            headers: {
              Authorization: `JWT ${token}`,
            },
          });
          resolve(this.apollo);
        });
      });
    }
  }
}