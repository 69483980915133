<div class="middle-box text-center loginscreen">
  <div>
    <h3>Welcome to Cheetah 3</h3>
    <h2>{{loginBanner}}</h2>
    <form class="mt-2" role="form" ng-submit="submit()">
      <div class="form-group">
        <input type="text" id="username" class="form-control" placeholder="Username" required="" [(ngModel)]="username" name="username">
      </div>
      <div class="form-group">
        <input type="password" id="password" class="form-control" placeholder="Password" required="" [(ngModel)]="password" name="password">
      </div>
      <!-- <uib-alert ng-repeat="alert in alerts" type="{{alert.type}}" close="closeAlert($index)">{{alert.msg}}</uib-alert> -->
      <button type="submit" class="btn btn-primary block full-width mb-2" (click)="submit()">Login</button>
      <a (click)="forgotPassword()"><small>Forgot password?</small></a>
    </form>
  </div>
</div>
