import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppDataService } from '../app-data.service';

@Injectable({
  providedIn: 'root'
})
export class EventSourcesService {
  constructor(private http: HttpClient,private globalData: AppDataService) { 
    
  }

  setParams(params: any): any {
    const paramsToSend: any = {};
    paramsToSend.params = params;
    return paramsToSend;
  }

  getEventSources(params: any): any {
    return this.http.get<any>(this.globalData.baseGoatUrl + 'events/source' + this.setParams(params));
  }

  findEventSources(params: any): any {
    return this.http.get<any>(this.globalData.baseGoatUrl + 'events/source/search' , this.setParams(params));
  }

  updateEventSource(data: any): any {
    return this.http.put<any>(this.globalData.baseGoatUrl + 'events/source', data);
  }
}