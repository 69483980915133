<div class="wrapper wrapper-content">
    <div class="row">
        <div class="col-sm-12 content-container">
            <div class="ibox">
                <div class="ibox-content">
                    <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
                    <div class="row">
                        <table class="table table-pointable table-bordered" width="100%">
                            <thead>
                                <tr>
                                    <th>Activity ID</th>
                                    <th>Party ID</th>
                                    <th>Ref Num</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let processingLog of processingLogForActivity">
                                    <tr (click)="selectprocessingLogForActivity($event, processingLog)">
                                        <td>{{processingLog.activityId}}
                                            <icon *ngIf="processingLogHasStatus(processingLog.processingLogForActivity, 'ERROR')"
                                                [icon]="faExclamationTriangle" class="text-danger" title="ERROR"></icon>
                                            <icon *ngIf="processingLogHasStatus(processingLog.processingLogForActivity, 'RETRY')"
                                                [icon]="faRedo" class="text-primary" title="RETRY"></icon>
                                            <icon *ngIf="processingLogHasStatus(processingLog.processingLogForActivity, 'RESOLVED')"
                                                [icon]="faCheckCircle" class="text-success" title="RESOLVED">
                                            </icon>
                                            <a class="btn-sm"></a>
                                        </td>
                                        <td><a routerLink="/members/{{processingLog.partyId}}">{{processingLog.partyId}}</a>
                                        </td>
                                        <td>{{processingLog.refNum}}</td>
                                    </tr>
                                    <tr [hidden]="!processingLogList[processingLog.activityId]" class="additional-data">
                                        <td colspan="3">
                                            <table class="table processing-details">
                                                <thead>
                                                    <tr>
                                                        <th class="width-8">Created On</th>
                                                        <th class="width-32">Message</th>
                                                        <th class="width-8">Reject Code</th>
                                                        <th class="width-8">Promotion Sponsor</th>
                                                        <th class="width-8">Campaign</th>
                                                        <th class="width-8">Program</th>
                                                        <th class="width-8">Account Txn</th>
                                                        <th class="width-8">Amount</th>
                                                        <th *ngIf="showNotes" class="width-8">Notes</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr class="filter">
                                                        <td><input type="text" class="form-control" [(ngModel)]="search.createdOn" />
                                                        </td>
                                                        <td><input type="text" class="form-control" [(ngModel)]="search.message" />
                                                        </td>
                                                        <td><input type="text" class="form-control" [(ngModel)]="search.rejectCode" />
                                                        </td>
                                                        <td><input type="text" class="form-control" [(ngModel)]="search.promotionSponsorId" />
                                                        </td>
                                                        <td><input type="text" class="form-control" [(ngModel)]="search.campaignId" />
                                                        </td>
                                                        <td><input type="text" class="form-control" [(ngModel)]="search.programId" />
                                                        </td>
                                                        <td><input type="text" class="form-control" [(ngModel)]="search.accountTxnId" />
                                                        </td>
                                                        <td><input type="text" class="form-control" [(ngModel)]="search.amount" />
                                                        </td>
                                                        <td *ngIf="showNotes"></td>
                                                    </tr>
                                                    <!-- <tr *ngFor="let msg of msgs | filter:search"> -->
                                                    <tr *ngFor="let msg of msgs | callback: filterActivity : search" [ngStyle]="{ 'background-color': getRowColor(msg) }" >
                                                        <td>{{msg.createdOn | date: "yyyy-MM-dd HH:mm:ss"}}</td>
                                                        <!-- TODO: we might want to add a way to set the status to RESOLVED manually -->
                                                        <td>
                                                            <fa-icon *ngIf="msg.status === 'ERROR'" [icon]="faExclamationTriangle"
                                                                class="text-danger" title="ERROR Status"></fa-icon>
                                                            <span *ngIf="msg.status === 'ERROR'">ERROR</span>
                                                            <fa-icon *ngIf="msg.status === 'RETRY'" [icon]="faRedo"
                                                                class="text-primary" title="RETRY Status"></fa-icon>
                                                            <span *ngIf="msg.status === 'RETRY'">RETRY</span>
                                                            <fa-icon *ngIf="msg.status === 'RESOLVED'" [icon]="faCheckCircle"
                                                                class="text-success" title="RESOLVED Status"></fa-icon>
                                                            <span *ngIf="msg.status === 'RESOLVED'">RESOLVED</span>
                                                            <br *ngIf="'ERROR RESOLVED RETRY'.indexOf(msg.status) !== -1">
                                                            <span [ngStyle]="{'text-decoration-line: line-through !important':
                                                            'RESOLVED'.indexOf(msg.status) !== -1}">
                                                              {{msg.message}}
                                                            </span>
                                                            <a class="btn-sm"></a>
                                                        </td>
                                                        <td>
                                                            {{msg.rejectCode}}
                                                        </td>
                                                        <td>
                                                            {{msg.promotionSponsorId}}
                                                        </td>
                                                        <td>
                                                            {{msg.campaignId}}
                                                        </td>
                                                        <td>
                                                            {{msg.programId}}
                                                        </td>
                                                        <td>
                                                            {{msg.accountTxnId}}
                                                        </td>
                                                        <td>
                                                            {{msg.amount}}
                                                        </td>
                                                        <td *ngIf="showNotes">
                                                        <button [ngClass]="{'btn-primary': !msg.notes, 'btn-warning': msg.notes}"
                                                            class="btn btn-sm float-right" (click)="openNoteModal(msg)">
                                                            <fa-icon [icon]="faEdit"></fa-icon>
                                                        </button>
                                                    </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                </ng-container>
                                <tr app-is-there-more [numColumns]="3" [newLengthEvent]="newLengthEvent">
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <app-add-more-data-to-list (onScroll)="getProcessingLogs(true)" [lock]="lock" [isLoading]="isLoading"></app-add-more-data-to-list>
</div>
