import { faTimes, faSave, faPlus } from '@fortawesome/free-solid-svg-icons';
import { UntypedFormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SegmentMapping } from 'src/app/data/class';

@Component({
  selector: 'app-manifest-segment-mapping-form',
  templateUrl: './manifest-segment-mapping-form.component.html',
  styleUrls: ['./manifest-segment-mapping-form.component.scss']
})
export class ManifestSegmentMappingFormComponent implements OnInit {

  @Input() index: number;
  @Input() segmentMapping: SegmentMapping;
  @Output() public segmentMappingEvent: EventEmitter<any> = new EventEmitter();
  segmentMappingForm: UntypedFormGroup;
  faTimes = faTimes;
  faSave = faSave;
  faPlus = faPlus;

  constructor(
    private formBuilder: FormBuilder
  ) { }

  ngOnInit(): void {
    if (this.index !== null) {
      this.segmentMappingForm = this.formBuilder.group({
        fieldName: [this.segmentMapping.fieldName],
        fieldIndex: [this.segmentMapping.fieldIndex],
        fieldDefault: [this.segmentMapping.fieldDefault],
        required: [this.segmentMapping.required],
        fieldLength: [this.segmentMapping.fieldLength],
        fieldRegex: [this.segmentMapping.fieldRegex],
        concatField: [this.segmentMapping.concatField],
        concatDelimiter: [this.segmentMapping.concatDelimiter],
        effectiveDateIndex: [this.segmentMapping.effectiveDateIndex],
        replace: [this.segmentMapping.replace]
      });
    } else {
      this.segmentMappingForm = this.formBuilder.group({
        fieldName: [''],
        fieldIndex: [''],
        fieldDefault: [''],
        required: [false],
        fieldLength: [''],
        fieldRegex: [''],
        concatField: [''],
        concatDelimiter: [''],
        effectiveDateIndex: [''],
        replace: [false]
      });
    }
  }

  emitEvent(type: string, index?: number): void {
    const value = this.segmentMappingForm.value;
    this.segmentMappingEvent.emit({type, index, value});
    if (type === 'add') {
      this.segmentMappingForm.reset();
    }
  }

}
