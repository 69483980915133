import { Component, EventEmitter, Input, OnInit, Output, ViewChild, NgModule } from '@angular/core';
import { NgbModal, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { StatusHelperService } from 'src/app/services/helpers/status-helper.service';
import { ProgramService } from 'src/app/services/program.service';
import { PromotionConfigService } from 'src/app/services/promotion-config.service';
import { PromotionService } from 'src/app/services/promotion.service';
import { ConfigurationService } from 'src/app/services/configuration.service';
import { FormBuilder, FormGroup, Validators, FormsModule } from '@angular/forms';
import { faPlus, faSave, faTimes } from '@fortawesome/free-solid-svg-icons';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { ResponseHelperService } from 'src/app/services/helpers/response-helper.service';
import { ActivatedRoute } from '@angular/router';
import { PromotionTemplate } from 'src/app/data/model';
import { Promotion } from 'src/app/data/model';
import * as $ from 'jquery';
import {Observable, Subject} from 'rxjs';
import { RegexSearchModalComponent } from 'src/app/regex-search-modal/regex-search-modal.component';
import { fromEvent, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';

@Component({
  selector: 'app-location-attribute-restriction',
  templateUrl: './location-attribute-restriction.component.html',
  styleUrls: ['./location-attribute-restriction.component.scss']
})
export class LocationAttributeRestrictionComponent implements OnInit {
    @Input() locationAttributeRestriction: any;
    attribConditions: any;
    trueFalse: any;
    faTimes = faTimes;
    faPlus = faPlus;
    @Output() public inputChangeEmitter: EventEmitter<any> = new EventEmitter(true);
    debouncer: Subject<string> = new Subject<string>();

  constructor(
    private router: ActivatedRoute,
    private formBuilder: FormBuilder,
    private formsModule: FormsModule,
    private modalHelper: NgbModal,
  ) {
      this.debouncer
        .pipe(debounce(() => interval(1000)))
        .subscribe((value) => this.inputChangeEmitter.emit(value));
    }

  ngOnInit(): void {
    console.log(this.locationAttributeRestriction);
       this.attribConditions = [
           {'value' : true,     'text': 'HAS'},
           {'value' : false,    'text': 'DOES NOT HAVE'}
       ];

       this.trueFalse = [
           {'value' : true,     'text': 'true'},
           {'value' : false,    'text': 'false'}
       ];
  }

  initRestrictionObject(): void {
    console.log(this.locationAttributeRestriction);
    var tmpObj = { attrValue: '(.*)',
                   class: "com.chiprewards.cheetah.business.rules.config.behavior.v2.BehaviorConfiguration$Restrictions$Behavioral$LocationAttributeRestriction" };
    if(this.locationAttributeRestriction){
      this.locationAttributeRestriction.push(tmpObj);
    } else {
      this.locationAttributeRestriction = [];
      this.locationAttributeRestriction.push(tmpObj);
    }
    console.log(this.locationAttributeRestriction);
  }

  updateRestrictions() {
    this.debouncer.next(this.locationAttributeRestriction);
  }

  removeRestriction(restriction) {
    this.locationAttributeRestriction.splice(this.locationAttributeRestriction.indexOf(restriction), 1);
    this.updateRestrictions();
  }
}
