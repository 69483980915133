import { AppDataService } from './../../app-data.service';
import { Component, OnInit, Input } from '@angular/core';
import { faRunning, faSpinner, faCheckCircle, faBug } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-filter-header-activity-tab',
  templateUrl: './filter-header-activity-tab.component.html',
  styleUrls: ['./filter-header-activity-tab.component.scss']
})
export class FilterHeaderActivityTabComponent implements OnInit {

  @Input() searchFilters: any;
  @Input() selectedActivitiesCount: any;
  faRunning = faRunning;
  faSpinner = faSpinner;
  faCheckCircle = faCheckCircle;
  faBug = faBug;

  constructor(
    public globalData: AppDataService
  ) { }

  ngOnInit(): void {
  }

}
