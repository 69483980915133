<div class="panel-body row">
  <div class="col-lg-9" *ngIf="show">
    <div class="full-height-scroll">
      <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
      <div class="table-communication-strategy">
        <table class="table table-pointable table-bordered">
          <thead>
            <tr>
              <th>ID</th>
              <th>Name</th>
              <th>Expiring promotions</th>
              <th>New promotions</th>
              <th>No activity</th>
              <th>Activity Reward</th>
              <th>Priority</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let cs of communicationStrategies" (click)="selectCommStrategy($event,cs.id)">
              <td><a routerLink="/communication-strategy/{{cs.id}}">{{cs.id}}</a></td>
              <td>{{cs.name}}</td>
              <td><a routerLink="/communications/{{cs.expiringPromotionsId}}">{{cs.expiringPromotionsName}}</a></td>
              <td><a routerLink="/communications/{{cs.newPromotionsId}}">{{cs.newPromotionsName}}</a></td>
              <td><a routerLink="/communications/{{cs.noActivityId}}">{{cs.noActivityName}}</a></td>
              <td><a routerLink="/communications/{{cs.activityRewardId}}">{{cs.activityRewardName}}</a></td>
              <td>{{cs.priority}}</td>
              <td>
                <div class="float-left actions inline-table">
                  <a class="btn btn-dark-blue btn-sm edit action" (click)="communicationStrategyModal(cs)" title="Edit"><fa-icon [icon]="faPencilAlt"></fa-icon></a>
                  <button class="btn btn-attention btn-sm delete action" [disabled]="cs.status==='DELETED'" (click)="deleteCommunicationStrategy(cs.id)" title="Delete"><fa-icon [icon]="faTrash"></fa-icon></button>
                  <button class="btn btn-dark-blue btn-sm float-right" (click)="addConfig(cs.id)" title="Add configuration">
                    <fa-icon [icon]="faCog"></fa-icon>
                  </button>
                </div>
              </td>
            </tr>
            <tr app-is-there-more [numColumns]="8" [newLengthEvent]="newLengthEvent"></tr>
          </tbody>
        </table>
      </div>
      <app-add-more-data-to-list (onScroll)="getCommunicationStrategy(true)" [lock]="lock" [isLoading]="isLoading"></app-add-more-data-to-list>
    </div>
  </div>
  <div class="col-sm-3">
    <div class="row justify-content-center mb-30">
      <h2><span class="light-blue">Configuration:</span></h2>
    </div>
    <div id="contact-1" class="tab-pane active"  [hidden]="!configurations || configurations.length===0">
      <div class="border-card margin-top-10" *ngFor="let conf of configurations; let i = index;">
        <div class="row integration-details-bar"></div>
        <div class="border-card top-margin-border-0">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-6">
                <span class="float-left"><b>ID:</b></span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{conf.id}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left"><b>Send in period</b></span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right">{{createDateFromTime(conf.fromTime) | date: 'hh:mm a'}} - {{createDateFromTime(conf.toTime) | date: 'hh:mm a'}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span><b>Black out periods: </b></span>
              </div>
              <div class="col-md-6">
                <span class="float-right text-right word-break-break-all">{{conf.blackOutPeriods}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span><b>Communications cap:</b></span>
              </div>
              <div class="col-md-6">
                <ul class="list-none">
                  <li *ngFor="let cap of conf.commCaps; let i=index">
                  <span class="panel-container text-right" *ngIf="cap.maxCommunicationsNum && cap.maxCommunicationsPeriod">{{cap.maxCommunicationsNum}} per
                    {{cap.maxCommunicationsPeriod}}</span>
                  </li>
                </ul>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span class="float-left"><b>Opt-In/Opt-Out</b></span>
              </div>
              <div class="col-md-6">
                <span class="panel-container text-right">{{conf.optInOptOut}}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <span><b>Endpoint type:</b></span>
              </div>
              <div class="col-md-6">
                <span class="panel-container text-right">{{conf.endpointTypeName}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row border-card mt-0">
          <div class="col-md-2">
          </div>
          <div class="col-md-4">
          </div>
          <div class="col-md-6 panel-container">
            <button class="btn mr-1 btn-dark-gray btn-xs delete action float-right" (click)="deleteConfiguration(conf.id,i)" title="Delete"><fa-icon [icon]="faTrash"></fa-icon></button>
            <button class="btn mr-1 btn-dark-gray btn-xs edit action float-right " (click)="editConfigurationModal(conf)" title="Edit"><fa-icon [icon]="faPencilAlt"></fa-icon></button>
          </div>
        </div>
        <div class="row integration-details-bar"></div>
      </div>
    </div>
  </div>
</div>



