<div class="wrapper wrapper-content">
  <div class="row">
    <div class="col-lg-9">
      <div class="ibox">
        <div class="ibox-title">
          <div class="float-right" [hidden]="!isProgramManager">
            <a class="btn btn-sm btn-dark-blue" (click)="integrationModal()" title="New Integration">
              <fa-icon [icon]="faPlus"></fa-icon>
            </a>
          </div>
          <h2>Integrations Management</h2>
        </div>
        <div class="ibox-content">
          <div class="row">
            <div class="col-md-12">
                <div class="row" [hidden]="!isProgramManager">
                  <div *ngIf="searchFilters">
                    <app-search-filter [searchFilters]="searchFilters"></app-search-filter>
                  </div>
                </div>
                <div class="row">
                  <table class="table table-pointable table-bordered infinite-table">
                    <thead>
                      <tr>
                        <th>ID</th>
                        <th>Status</th>
                        <th>Name</th>
                        <th>Customer Name</th>
                        <th>Import Type</th>
                        <th>File Type</th>
                        <th *ngIf="isAdmin">Transform Class</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let integ of integrations" (click)="showIntegration($event, integ)">
                        <td><a routerLink="/integrations/{{integ.id}}/loads/">{{integ.id}}</a></td>
                        <td>{{integ.status}}</td>
                        <td>{{integ.name}}</td>
                        <td>{{integ.customerName}}</td>
                        <td>{{integ.importType}}</td>
                        <td>{{integ.fileType}}</td>
                        <td *ngIf="isAdmin">
                          <input class="upload-input" id="upload-input-{{integ.id}}" type="file" name="file"
                            (change)="uploadTransformer($event.target)" [attr.id-el]="integ.id" />
                          <label class="btn btn-dark-blue btn-sm" for="upload-input-{{integ.id}}"
                            title="Upload Class file">
                            <fa-icon [icon]="faUpload"></fa-icon>
                          </label>
                          &nbsp;{{integ.recordTransformerName}}
                        </td>
                        <td>
                          <a *ngIf="integ.acceptUploads==true" class="btn btn-sm btn-dark-blue upload-file"
                            title="Upload" (click)="uploadModal(integ.id)">
                            <fa-icon [icon]="faUpload" class="mr-1"></fa-icon>Upload
                          </a>
                        </td>
                      </tr>
                      <tr app-is-there-more [numColumns]="numColumns" [newLengthEvent]="newLengthEvent"></tr>
                    </tbody>
                  </table>
                </div>
                <app-add-more-data-to-list (onScroll)="getIntegrations(true)" [lock]="lock" [isLoading]="isLoading">
                </app-add-more-data-to-list>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-lg-3" *ngIf="isShownIntegration()">
      <div class="ibox" appScrollWithMe>
        <div class="row integration-details-bar"></div>
        <div class="ibox-content">
          <div id="contact-1" class="tab-pane active">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-md-3 relative-position">
                    <div>
                      <span class="fa-stack fa-3x">
                        <fa-icon class="blue-icon-color" [icon]="faCogs"></fa-icon>
                      </span>
                    </div>
                  </div>
                  <div class="col-md-9">
                    <div class="row">
                      <div class="col-md-12 form-group float-right">
                        <span id="status-label" class="badge float-right"
                          [ngClass]="statusHelper[selectedIntegration.status]">{{selectedIntegration.status}}</span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-12 float-left">
                        <h2 class="elipsis" [attr.title]="selectedIntegration.name">{{selectedIntegration.name}}</h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 float-right">
                <a class="btn btn-dark-blue btn-sm float-right" (click)="integrationModal(selectedIntegration.id)"
                  title="Edit Integration" *ngIf="securityService.allowAdvancedIntegrationFeatures">
                  <fa-icon [icon]="faPencilAlt"></fa-icon>
                </a>
                <a class="btn btn-dark-blue btn-sm float-right" routerLink="/integrations/{{selectedIntegration.id}}/manifest"
                  title="Edit Manifest" *ngIf="securityService.allowAdvancedIntegrationFeatures">
                  <fa-icon [icon]="faExchangeAlt"></fa-icon>
                </a>
                <a class="btn btn-dark-blue btn-sm float-right" routerLink="/integrations/{{selectedIntegration.id}}/messages"
                  title="View Messages" *ngIf="securityService.allowAdvancedIntegrationFeatures">
                  <fa-icon [icon]="faEnvelope"></fa-icon>
                </a>
                <a class="btn btn-dark-blue btn-sm float-right" routerLink="/integrations/{{selectedIntegration.id}}/loads"
                  title="View Files" *ngIf="isAdmin">
                  <fa-icon [icon]="faFolderOpen"></fa-icon>
                </a>
                <a class="btn btn-dark-blue btn-sm float-right" routerLink="/integrations/{{selectedIntegration.id}}/loads"
                  title="View Files" *ngIf="!isAdmin">
                  <fa-icon [icon]="faFolderOpen">View Files</fa-icon>
                </a>
              </div>
            </div>
            <div class="client-detail">
              <div>
                <strong>Details</strong>
                <table class="table table-pointable">
                  <thead>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Version</td>
                      <td class="text-right">{{selectedIntegration.integrationVersion}}</td>
                    </tr>
                    <tr>
                      <td>Customer Name</td>
                      <td class="text-right">{{selectedIntegration.customerName}}</td>
                    </tr>
                    <tr>
                      <td>Import Type</td>
                      <td class="text-right">{{selectedIntegration.importType}}</td>
                    </tr>
                    <tr>
                      <td>File Type</td>
                      <td class="text-right">{{selectedIntegration.fileType}}</td>
                    </tr>
                    <tr>
                      <td>File Layout version</td>
                      <td class="text-right">{{selectedIntegration.fileLayoutVersion}}</td>
                    </tr>
                    <tr>
                      <td>File Layout Schema</td>
                      <td class="text-right">{{selectedIntegration.fileLayoutSchema}}</td>
                    </tr>
                    <tr>
                      <td>File Name Pattern</td>
                      <td class="text-right word-break-break-all">{{selectedIntegration.fileNamePattern}}</td>
                    </tr>
                    <tr>
                      <td>Record Delimiter</td>
                      <td class="text-right">{{selectedIntegration.recordDelimiter}}</td>
                    </tr>
                    <tr>
                      <td>Date Format</td>
                      <td class="text-right">{{selectedIntegration.dateFormat}}</td>
                    </tr>
                    <tr>
                      <td>Field Count</td>
                      <td class="text-right">{{selectedIntegration.fieldCount}}</td>
                    </tr>
                    <tr *ngIf="isProgramManager">
                      <td>Created On</td>
                      <td class="text-right">{{selectedIntegration.createdOnStr}}</td>
                    </tr>
                    <tr *ngIf="isProgramManager">
                      <td>Created By</td>
                      <td class="text-right">{{selectedIntegration.createdByStr}}</td>
                    </tr>
                    <tr *ngIf="isProgramManager">
                      <td>Updated On</td>
                      <td class="text-right">{{selectedIntegration.updatedOnStr}}</td>
                    </tr>
                    <tr *ngIf="isProgramManager">
                      <td>Updated By</td>
                      <td class="text-right">{{selectedIntegration.updatedByStr}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="row integration-details-bar"></div>
      </div>
    </div>
  </div>
</div>
