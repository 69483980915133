<form [formGroup]="communicationForm" novalidate>
  <div class="modal-header">
    <h1>{{title}}</h1>
  </div>
  <div class="modal-body">
    <div class="alert alert-danger alert-dismissable" *ngIf="errorMsg">
      {{errorMsg}}
      <a (click)="clearErrorMessage()">
        <span class="float-right close-button">
          <fa-icon [icon]="faTimes"></fa-icon>
        </span>
      </a>
    </div>
    <div class="row">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Communication</label>
          <ng-select [items]="communications" [clearable]="false" bindLabel="name" bindValue="id" formControlName="communicationId" (change)="getSelectedComm($event)">
            <ng-template ng-notfound-tmp>
              <strong class="red-color">No eligible communications</strong>
            </ng-template>
          </ng-select>
          <div class="mt-1" [hidden]="communicationForm.controls.communicationId.valid">
            <small class="text-danger">Choose communication</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="endpointTypes.length > 0 || isEdit">
      <div class="col-sm-12">
        <div class="form-group">
          <label>Endpoint Type</label>
          <ng-select [items]="endpointTypes" [clearable]="false" bindLabel="name" bindValue="name" formControlName="endpointType" (change)="setEndPointType($event.name)">
          </ng-select>
          <div class="mt-1" [hidden]="communicationForm.controls.endpointType.valid">
            <small class="text-danger">Select type</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-12" *ngIf="communicationForm.controls.endpointType.value === 'EMAIL' && endpointTypes.length > 0">
        <div class="form-group">
          <label>Email</label>
          <ng-select [items]="emails" [clearable]="false" bindLabel="emailAddress" bindValue="id" formControlName="emailId">
            <ng-template ng-option-tmp let-item="item">
              <div>{{ item.emailAddress }}</div>
              <small>Nickname: {{ item.addressName }}</small>
            </ng-template>
          </ng-select>
          <div class="mt-1" [hidden]="communicationForm.controls.emailId.valid">
            <small class="text-danger">Choose email</small>
          </div>
        </div>
      </div>
      <div class="col-sm-12" *ngIf="communicationForm.controls.endpointType.value === 'PHONE' && endpointTypes.length > 0">
        <div class="form-group">
          <label>Phone</label>
          <ng-select [items]="phones" [clearable]="false" bindLabel="phoneNumber" bindValue="id" formControlName="phoneId">
            <ng-template ng-option-tmp let-item="item">
              <div>{{ item.phoneNumber }}</div>
              <small>{{ item.phoneTypeName }}</small>
            </ng-template>
          </ng-select>
          <div class="mt-1" [hidden]="communicationForm.controls.phoneId.valid">
            <small class="text-danger">Select phone type</small>
          </div>
        </div>
      </div>
      <div class="col-sm-12" *ngIf="communicationForm.controls.endpointType.value === 'ADDRESS' && endpointTypes.length > 0">
        <div class="form-group">
          <label>Address</label>
          <ng-select [items]="addresses" [clearable]="false" bindLabel="address1" bindValue="addressId" formControlName="addressId">
            <ng-template ng-label-tmp let-item="item">
              <span>{{ item.address1}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item">
              <div>{{item.address1}}</div>
              <small>{{item.city}}, {{item.state}} {{item.postalCode}}</small>
            </ng-template>
          </ng-select>
          <div class="mt-1" [hidden]="communicationForm.controls.addressId.valid">
            <small class="text-danger">Select address</small>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-sm-6">
        <div class="form-group">
          <div class="form-group form-check">
            <input id="credential" type="checkbox" class="form-check-input" formControlName="optOut" />
            <label for="credential" class="form-check-label">
              Opt Out
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button class="btn btn-primary" type="submit" (click)="ok()" [disabled]="!communicationForm.valid || !communicationForm.dirty"> Save </button>
    <button class="btn btn-warning" type="button" (click)="cancel()"> Cancel </button>
  </div>
</form>
