import { Injectable } from '@angular/core';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class DateHelperService {

  constructor() { }

  convertToUTC(date: Date): string {
    const result = new Date(date);
    if (result.getTimezoneOffset() > 0) {
      result.setMinutes(result.getMinutes() + result.getTimezoneOffset());
    } else {
      result.setMinutes(result.getMinutes() - result.getTimezoneOffset());
    }
    return result.toISOString().slice(0, 10);
  }

  toISOFormat(date: Date): string {
    const local = new Date(date);
    return local.toISOString().slice(0, 10);
  }

  ngbDateToString(datePickerValue: NgbDate): string {
    if (datePickerValue && datePickerValue.hasOwnProperty('year') && datePickerValue.hasOwnProperty('month') && datePickerValue.hasOwnProperty('day')) {
      return datePickerValue.year + '-' + this.format(datePickerValue.month) + '-' + this.format(datePickerValue.day);
    }
    return null;
  }

  stringToNgbDate(date: string): NgbDate {
    if (date) {
      const dateParts = date.split('-');
      return new NgbDate(Number(dateParts[0]), Number(dateParts[1]), Number(dateParts[2]));
    }
    return null;
  }

    stringDateTimeToNgbDate(date: string): NgbDate {
      if (date) {
        const dateParts = date.split('-');
        const day = dateParts[2].split('T');
        return new NgbDate(Number(dateParts[0]), Number(dateParts[1]), Number(day[0]));
      }
      return null;
    }

  format(number: number): any {
    return number > 9 ? number : '0' + number;
  }

  convrtUtcTimeToLocaleTime(utcDate: string): string {
    let totlaMinutes = Number(utcDate.split(':')[0]) * 60 + Number(utcDate.split(':')[1]) - new Date().getTimezoneOffset();
    const minutesInOneDay = 24 * 60;
    if (totlaMinutes >= minutesInOneDay) {
      totlaMinutes -= minutesInOneDay;
    } else if (totlaMinutes < 0) {
      totlaMinutes += minutesInOneDay;
    }
    return this.format(Math.floor(totlaMinutes / 60)) + ':' + this.format(totlaMinutes % 60) + ':' + '00';
  }
}
