<div>
  <form>
    <label>The total amount earned:</label>
    <div class="row padded form-group">
      <div class="col-md-12" *ngIf="earnCap !== null">
        <div *ngFor="let ernCap of earnCap; let i=index" class="border-card-padding form-group col-lg-12">
          <button class="btn btn-attention float-right" type="button" (click)="removeCap(cntCap)" title="Remove Cap">
            <fa-icon [icon]="faTimes"></fa-icon>
          </button>
          <form>
            <div class="form-group">
              <label>for the current:</label>
              <select name="period_{{ernCap.capValue}}" class="form-control" [(ngModel)]="ernCap.capPeriod" (ngModelChange)="updateCaps(ernCap)">
                <option *ngFor="let period of periods" [selected]="ernCap.capPeriod === period.value" [value]="period.value">{{period.text}}</option>
              </select>
            </div>
            <div class="form-group">
              <label class="control-label" for="capValue_{{ernCap.capValue}}">caps at (maximum number):</label>
              <input class="form-control m-b" type="number" id="capValue_{{ernCap.capValue}}" name="capValue" [(ngModel)]="ernCap.capValue" (ngModelChange)="updateCaps(ernCap)" />
            </div>
            <div class="form-group">
              <a
                class="control-label"
                ng-class="{ 'disabled-link': readOnly }"
                (click)="
                  regexSearchModal(
                    'PROMOTIONS',
                    { object: ernCap, name: 'promotionCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                title="Search for Promotions"
                >across Promotions (leave blank for the current promotion or use a comma separated list):</a
              >
              <input
                class="form-control form-readonly m-b"
                type="text"
                id="promoCodes_{{ ernCap.capValue }}"
                name="promotionCodes"
                [(ngModel)]="ernCap.promotionCodes"
                (ngModelChange)="updateCaps(ernCap)"
                (click)="
                  regexSearchModal(
                    'PROMOTIONS',
                    { object: ernCap, name: 'promotionCodes' },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                readonly
              />
            </div>
            <div class="form-group">
              <a
                class="control-label"
                ng-class="{ 'disabled-link': readOnly }"
                (click)="
                  regexSearchModal(
                    'BEHAVIORS',
                    {
                      object: ernCap,
                      objectOfObject: ernCap.behaviorCodes,
                      objectOfObjectName: 'behaviorCodes',
                      name: 'value'
                    },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                title="Search for Behaviors"
                >limited to Behaviors with Codes (leave blank for any or use a comma separated list):</a
              >
              <input
                class="form-control form-readonly m-b"
                type="text"
                id="behCodes_{{ ernCap.capValue }}"
                name="behaviorCodes"
                [ngModel]="ernCap.behaviorCodes && ernCap.behaviorCodes.value"
                (ngModelChange)="updateBehCodes(i, $event)"
                (click)="
                  regexSearchModal(
                    'BEHAVIORS',
                    {
                      object: ernCap,
                      objectOfObject: ernCap.behaviorCodes,
                      objectOfObjectName: 'behaviorCodes',
                      name: 'value'
                    },
                    { windowClass: 'wider-modal-window' },
                    false,
                    false,
                    i
                  )
                "
                readonly
              />
            </div>
            <div class="form-group">
              <label class="control-label" for="rewardCodes_{{ernCap.capValue}}">or Reward types of (leave blank for any):</label>
              <input class="form-control m-b" type="text" id="rewardCodes_{{ernCap.capValue}}" name="rewardCodes" [(ngModel)]="ernCap.rewardCodes" (ngModelChange)="updateCaps(ernCap)" />
            </div>
            <div class="form-group">
              <label class="control-label" for="previousPeriodCount_{{ernCap.capValue}}">How many previous periods should be included in the time span (leave empty for only the current period):</label>
              <input class="form-control m-b" type="number" id="previousPeriodCount_{{ernCap.capValue}}" name="previousPeriodCount" [(ngModel)]="ernCap.previousPeriodCount" (ngModelChange)="updateCaps(ernCap)" />
            </div>
            <div class="form-group">
              <label class="control-label" for="relationshipName_{{ernCap.capValue}}">Include counts from members related by (leave blank for current member only or use a comma separated list):</label>
              <input class="form-control m-b" type="text" id="relationshipName_{{ernCap.capValue}}" name="relationshipName" [(ngModel)]="ernCap.relationshipName" (ngModelChange)="updateCaps(ernCap)" />
            </div>
            <div class="form-group">
              <label class="control-label" for="friendlyMessage_{{ernCap.capValue}}">Message:</label>
              <input class="form-control m-b" type="text" id="friendlyMessage_{{ernCap.capValue}}" name="friendlyMessage" [(ngModel)]="ernCap.friendlyMessage" (ngModelChange)="updateCaps(ernCap)" />
            </div>
          </form>
        </div>
      </div>
      <div class="form-group col-md-12">
        <button class="btn btn-default float-right" (click)="initCapObject()"><fa-icon [icon]="faPlus"></fa-icon> Add Reward Earn Cap</button>
      </div>
    </div>
  </form>
</div>
